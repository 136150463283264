import { Flex, Tab, TabList, Tabs } from "@chakra-ui/react";

import { Link as ScrollLink } from "react-scroll";
import { useState } from "react";

// Example array of Tab data:

// const anchorMenuTabs = [
//   {
//     name: 'Whatever the tab label is',
//     linkName: 'basic-info',
//   },
//    ...
// ]

// Example component to scroll to:

// <Element name="basic-info">
//   <WhateverCompenentYouWantToScrollTo />
// </Element>

type TabData = {
  name: string;
  linkName: string;
};

type AnchorMenuProps = {
  tabs: TabData[];
  alignment: "left" | "center";
};

export default function HorizontalAnchorMenu({
  tabs,
  alignment,
}: AnchorMenuProps) {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <Flex
      height="45px"
      justifyContent={alignment}
      alignItems="center"
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      backgroundColor="white"
      marginTop={2}
      padding={6}
      borderRadius="xl"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      position="sticky"
    >
      <Tabs
        variant="soft-rounded"
        size="sm"
        color="brand.primary"
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((tab: TabData) => (
            <Tab key={tab.name} fontWeight="500">
              <ScrollLink
                activeClass="active"
                color="brand.grey.600"
                to={tab.linkName}
                smooth
                spy
                // duration="500"
                offset={-50}
              >
                {tab.name}
              </ScrollLink>
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Flex>
  );
}
