import { useMutation, useQuery } from "@tanstack/react-query";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import AuthoringGraphqlService from "@/client/services/api/admin/content-authoring/AuthoringGraphqlService";
import AuthoringScormService from "@/client/services/api/admin/content-authoring/AuthoringScormService";
import type { AxiosError } from "axios";
import type { ScormFormData } from "@/client/routes/admin/create/content/EditScorm";
import type { ScormSubmissionData } from "@/client/types/admin/content-authoring/scorm/ScormSubmissionData";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useAuthStore } from "@/client/services/state/authStore";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useAuthorScorm = (scormId: string | undefined) => {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setToast } = useToastStore();

  const { watch } = useFormContext<ScormFormData>();

  const getScormForEditor = useQuery({
    enabled: !!scormId,
    queryKey: ["scorm-for-editor", scormId, user._id],
    queryFn: () => AuthoringGraphqlService.getScormForEditor(scormId, user._id),
  });

  const submitScormPayload: Omit<
    ScormSubmissionData,
    "approval_required" | "approved" | "draft"
  > = {
    _id: watch("id"),
    user: watch("creator"),
    title: watch("title"),
    author: watch("author")!,
    // TODO: Fix this circular type dependency
    // @ts-ignore
    tags: watch("tags"),
    surveys: watch("surveys")?.map((survey) => ({ survey_id: survey.id })),
    rating_configuration: {
      allow_edits: watch("ratingConfiguration.allowEdits"),
      allow_feedback: watch("ratingConfiguration.allowFeedback"),
      allow_rating: watch("ratingConfiguration.allowRating"),
      messaging: {
        individuals: watch("ratingConfiguration.messaging.individuals")?.map(
          (individual) => individual.id
        ),
        slack_channels: watch(
          "ratingConfiguration.messaging.slackChannels"
        )?.map((channel) => channel.id),
      },
      notify_feedback_only: watch("ratingConfiguration.notifyFeedbackOnly"),
      show_users_rating: watch("ratingConfiguration.showUsersRating"),
      total_value: watch("ratingConfiguration.totalValue"),
    },
    private: watch("private"),
    linked_category: watch("linkedCategory")?.map((category) => category.id),
    privacy_collaborators: watch("privacyCollaborators")?.map(
      (collaborator) => collaborator.id
    ),
    privacy_locations: watch("privacyLocations")?.map(
      (location) => location.id
    ),
    privacy_departments: watch("privacyDepartments")?.map(
      (department) => department.id
    ),
    privacy_teams: watch("privacyTeams")?.map((team) => team.id),
    privacy_org_levels: watch("privacyOrgLevels")?.map(
      (orgLevel) => orgLevel.id
    ),
    privacy_groups: watch("privacyGroups")?.map((group) => group.id),
    privacy_grades: watch("privacyGrades")?.map((grade) => grade.id),
    partner_permissions: watch("partnerPermissions"),
    image: watch("image"),
    banner_image: watch("bannerImage"),
    explore_hide: !watch("exploreHide"),
    allow_comments: watch("allowComments"),
    duration: watch("duration"),
    description: watch("description"),
    certificate: watch("certificate"),
    images: {}, // TODO: Format images for SCORM
    ssla_config: {
      open_sco_in_new_window: watch("sslaConfig.openScoInNewWindow"),
      sco_window_width: watch("sslaConfig.scoWindowWidth"),
      sco_window_height: watch("sslaConfig.scoWindowHeight"),
      sco_window_try_fullscreen: watch("sslaConfig.scoWindowTryFullscreen"),
    },
    certificate_data: watch('certificateData'),
  };

  type SubmitScormMutationVariables = Pick<
    ScormSubmissionData,
    "approval_required" | "approved" | "draft"
  >;

  const submitScormMutation = useMutation({
    mutationFn: (variables: SubmitScormMutationVariables) =>
      AuthoringScormService.save(submitScormPayload._id, {
        ...submitScormPayload,
        ...variables,
      }),
    onSuccess: (successData) => {
      AnalyzeService.recordContentModifications({
        document: successData._id,
        type: "scorm-content",
      });

      trackEvent("scorm_content_updated", {
        content: successData._id,
        type: "scorm-content",
        title: successData.title,
      });
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      console.error("Error Creating Article", error.response?.data?.message);

      setToast({
        show: true,
        status: "error",
        title: error.response?.data?.message || t("authoring.scorm.save.error"),
      });
    },
  });

  return {
    submitScormMutation,
    getScormForEditor,
  };
};
