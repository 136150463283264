import { useEffect } from "react";
import { BarChart } from "../BarChart";
import { useGetReportData } from "@/client/services/hooks/admin/useGetReportData";
import { useTranslation } from "react-i18next";

export default function AssignmentsOnTime() {
  const {
    dateRange,
    setDateRange,
    getDataBasedOnDateRange,
    getAssignmentsOnTimeData,
  } = useGetReportData();
  const { data, refetch } = getAssignmentsOnTimeData;
  const { t } = useTranslation();

  useEffect(() => {
    getDataBasedOnDateRange(refetch);
  }, [dateRange]);

  const labels = {
    open: t("partners.manager.dashboard.assignmentsOnTime.open"),
    overdue: t("partners.manager.dashboard.assignmentsOnTime.overdue"),
    completed_on_time: t(
      "partners.manager.dashboard.assignmentsOnTime.completedOnTime"
    ),
    completed_overdue: t(
      "partners.manager.dashboard.assignmentsOnTime.completedOverdue"
    ),
  };

  return (
    <BarChart
      data={data || []}
      labels={labels}
      colSpan={1}
      rowSpan={1}
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={t("partners.manager.dashboard.assignmentCompletions")}
      tooltip={t("partners.manager.dashboard.assignmentCompletions.help")}
    />
  );
}
