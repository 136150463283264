import "./Carousel.scss";

import { AngleLeft, AngleRight } from "@/client/components/icons/ContinuIcons";
import { Center, Spinner } from "@chakra-ui/react";

import CarouselItem from "./CarouselItem";
import Slider from "react-slick";
import { useLazyLoadStylesheet } from "@/client/services/hooks/useLazyLoad";

interface CarouselProps {
  items: string[];
  isLoading: boolean;
  selectedAttempt: string;
  setSelectedAttempt: (attempt: string) => void;
}

export default function Carousel({
  items,
  isLoading,
  selectedAttempt,
  setSelectedAttempt,
}: CarouselProps) {
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <AngleRight />,
    prevArrow: <AngleLeft />,
  };

  useLazyLoadStylesheet(
    "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
  );
  useLazyLoadStylesheet(
    "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
  );

  return (
    <Slider {...settings}>
      {isLoading && (
        <Center height="full">
          <Spinner />
        </Center>
      )}

      {items.map((item, index) => (
        <CarouselItem
          item={item}
          attemptNumber={items.length - index}
          selectedAttempt={selectedAttempt}
          setSelectedAttempt={setSelectedAttempt}
        />
      ))}
    </Slider>
  );
}
