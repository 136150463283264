import { useEffect, useState } from "react";

import ExploreService from "@/client/services/api/ExploreService";
import { useExploreStore } from "@/client/services/state/routeState/exploreStore";
import { useMultipleSelection } from "downshift";

interface ContentType {
  value: string;
  label: string;
  type: "textInput" | "contentType";
}

const contentTypes: ContentType[] = [
  { type: "contentType", value: "articles", label: "Articles" },
  { type: "contentType", value: "workshops", label: "Workshops" },
  { type: "contentType", value: "videos", label: "Videos" },
  { type: "contentType", value: "files", label: "Files" },
  { type: "contentType", value: "tracks", label: "Learning Tracks" },
  { type: "contentType", value: "courses", label: "Courses" },
  { type: "contentType", value: "scorm", label: "Scorm" },
  { type: "contentType", value: "assessments", label: "Assessments" },
];

export const usePersistedSearch = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pickerItems, setPickerItems] = useState(contentTypes);

  const {
    persistedSelectedItems,
    setPersistedSelectedItems,
    setPersistedSearchTerm,
    setPersistedSearchQuery,
    setPersistedTypeRestriction,
  } = useExploreStore();

  useEffect(() => {
    let queryString = "";

    if (persistedSelectedItems.length === 0) {
      setIsFiltering(false);
      setIsSearching(false);
      setPersistedSearchTerm("");
      setPersistedSearchQuery("");
      setPersistedTypeRestriction("");
      return;
    }

    if (
      persistedSelectedItems.length === 1 &&
      persistedSelectedItems[0].type === "contentType"
    ) {
      setIsFiltering(true);
      setIsSearching(false);
      setPersistedTypeRestriction(persistedSelectedItems[0].value);
    } else if (
      persistedSelectedItems.length === 1 &&
      persistedSelectedItems[0].type === "textInput"
    ) {
      setSearchTerm(persistedSelectedItems[0].value);
      setPersistedSearchTerm(persistedSelectedItems[0].value);
      setIsSearching(true);
      setPersistedSearchQuery(`&searchTerm=${persistedSelectedItems[0].value}`);
    } else if (persistedSelectedItems.length === 2) {
      const textInput = persistedSelectedItems.find(
        (item) => item.type === "textInput"
      );

      const contentType = persistedSelectedItems.find(
        (item) => item.type === "contentType"
      );

      if (textInput && contentType) {
        setSearchTerm(textInput.value);
        setPersistedSearchTerm(textInput.value);
        queryString += `&restricted_types=${contentType.value}&searchTerm=${textInput.value}`;
        setIsSearching(true);
        setPersistedSearchQuery(queryString);
      }
    }
  }, [persistedSelectedItems]);

  const handleCreateItem = (item: ContentType) => {
    setPickerItems((current) => [...current, item]);

    // @ts-ignore
    setPersistedSelectedItems((current) => [...current, item]);
  };

  const handleClearAll = () => {
    setPersistedSelectedItems([]);
  };

  const handleRecordSearchTermAndContentSelected = (
    contentSelectedId: string,
    contentSelectedType: string
  ) => {
    const searchAnalyticsType =
      contentSelectedType === "scorm"
        ? "scorm-content"
        : contentSelectedType === "multi_session_workshop"
        ? "workshop"
        : contentSelectedType;
    ExploreService.registerSearchTermAndSelectedContent(
      searchTerm,
      contentSelectedId,
      searchAnalyticsType
    );
  };

  const handleSelectedItemsChange = (
    items: ContentType[],
    changeType: string
  ) => {
    if (items.length > 0) {
      //! '__dropdown_keydown_backspace__'
      if (
        changeType ===
        useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace
      ) {
        setPersistedSelectedItems(items);
        return;
      }

      //! '__function_remove_selected_item__'
      if (
        changeType ===
        useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem
      ) {
        setPersistedSelectedItems(items);
        return;
      }

      const newItem = items[items.length - 1];

      const match = persistedSelectedItems.find(
        (item) => item.type === newItem.type
      );

      if (match) {
        const existingIndex = persistedSelectedItems.findIndex(
          (item) => item.type === newItem.type
        );

        const newArr = [...persistedSelectedItems];

        newArr[existingIndex] = newItem;

        setPersistedSelectedItems(newArr);
      } else {
        setPersistedSelectedItems(items);
      }
    } else {
      setPersistedSelectedItems(items);
    }
  };

  return {
    isSearching,
    setIsSearching,
    isFiltering,
    searchTerm,
    pickerItems,
    handleCreateItem,
    handleClearAll,
    handleRecordSearchTermAndContentSelected,
    handleSelectedItemsChange,
  };
};
