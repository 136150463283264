/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Tag,
  TagCloseButton,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";

import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import type { Content } from "@/client/services/api/graphql/gql/graphql";
import NoSearchResultsListItem from "@/client/components/admin/create/content/lists/NoSearchResultsListItem";
import { useCombobox } from "downshift";
import { useDebounce } from "usehooks-ts";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// TODO: Refactor this combobox for use across all authoring user searches

export default function AuthoringFeedbackContactsSearch() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<Content>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const selectedContacts = watch("ratingConfiguration.messaging.individuals");

  const { isFetching, data } = useQuery({
    enabled: !!debouncedSearchTerm,
    queryKey: ["individual-feedback-contacts", debouncedSearchTerm],
    queryFn: () =>
      AdminUsersService.searchAdminUsers(debouncedSearchTerm, false),
    select: (selectData) => [
      {
        title: t(
          "authoring.settings.enableRatings.feedback.contacts.individual"
        ),
        options: selectData.map((user) => ({
          id: user._id,
          name: user.full_name,
        })),
      },
    ],
  });

  const flattenGroupOptions = (options: { title: string; options: any[] }[]) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        if (!inputValue) {
          setSearchTerm("");
          return;
        }

        setSearchTerm(inputValue);
      },
      items: data ? flattenGroupOptions(data) : [],
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }

        const alreadySelected = selectedContacts.some(
          (collaborator) => collaborator.id === selectedItem.id
        );

        if (alreadySelected) {
          setValue(
            "ratingConfiguration.messaging.individuals",
            selectedContacts.filter(
              (selectedContact) => selectedContact.id !== selectedItem.id
            )
          );

          return;
        }

        setValue("ratingConfiguration.messaging.individuals", [
          ...selectedContacts,
          selectedItem,
        ]);
      },
      selectedItem: null,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
              highlightedIndex: state.highlightedIndex,
              inputValue: "", // don't add the item string as input value at selection.
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: "", // don't add the item string as input value at selection.
            };
          default:
            return changes;
        }
      },
    });

  return (
    <Stack>
      <Text variant="createLabel">
        {t("authoring.settings.enableRatings.feedback.contacts")}
      </Text>

      <InputGroup>
        <Input
          variant="create"
          placeholder={t("authoring.feedback.searchIndividualContacts")}
          {...getInputProps()}
        />

        <InputRightElement>
          {isFetching && <Spinner size="sm" color="brand.gold.100" />}
        </InputRightElement>
      </InputGroup>

      <List
        flexDirection="column"
        border={data ? "1px solid" : "none"}
        borderColor="brand.gold.100"
        borderRadius="md"
        background="white"
        overflow="scroll"
        paddingX={6}
        zIndex={9999}
        maxH={400}
        width="inherit"
        {...getMenuProps()}
      >
        {data && data[0].options.length === 0 && <NoSearchResultsListItem />}

        {data &&
          data[0].options.length > 0 &&
          data.reduce(
            (results: any, section: any, sectionIndex: any) => {
              results.sections.push(
                <Box
                  key={`results_section_${sectionIndex + 1}`}
                  paddingY={4}
                  borderBottom="1px solid"
                  borderColor="warmNeutral.100"
                >
                  {section.options.length > 0 && (
                    <Text variant="createLabel" marginY={2}>
                      {section.title}
                    </Text>
                  )}

                  {section.options.length > 0 &&
                    section.options.map((option: any, optionIndex: any) => {
                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      const resultIndex = results.itemIndex++;

                      const isSelected = selectedContacts.some(
                        (selectedContact) => selectedContact.id === option.id
                      );

                      return (
                        <ListItem
                          key={`option_index_${optionIndex + 1}`}
                          paddingX={6}
                          paddingY={2}
                          _hover={{
                            cursor: "pointer",
                          }}
                          {...getItemProps({
                            item: option,
                            index: resultIndex,
                            "aria-selected": isSelected,
                          })}
                          backgroundColor={
                            highlightedIndex === resultIndex
                              ? "warmNeutral.0"
                              : "white"
                          }
                          border={
                            highlightedIndex === resultIndex
                              ? "1px solid"
                              : "none"
                          }
                          borderColor="warmNeutral.400"
                          borderRadius="6px"
                        >
                          <Flex
                            alignItems="center"
                            width="full"
                            justifyContent="space-between"
                          >
                            <Avatar
                              size="sm"
                              name={option.full_name}
                              src={option.image}
                              opacity={isSelected ? 0.4 : 1}
                            />

                            <Box flexGrow={1} textAlign="left" paddingLeft={4}>
                              <Text
                                fontSize="sm"
                                fontWeight={500}
                                color={
                                  isSelected
                                    ? "brand.legibleBlack.50"
                                    : "brand.legibleBlack.100"
                                }
                              >
                                {option.name}
                              </Text>
                            </Box>

                            <Icon
                              boxSize={8}
                              as={isSelected ? CiCircleCheck : CiCirclePlus}
                              color={
                                isSelected ? "brand.gold.100" : "brand.grey.40"
                              }
                            />
                          </Flex>
                        </ListItem>
                      );
                    })}
                </Box>
              );

              return results;
            },
            { sections: [], itemIndex: 0 }
          ).sections}
      </List>

      {selectedContacts.length > 0 && (
        <HStack flexWrap="wrap">
          {selectedContacts.map((contact) => (
            <Tag key={contact.id} variant="create" marginBottom={0}>
              {contact.name}

              <TagCloseButton
                onClick={() =>
                  setValue(
                    "ratingConfiguration.messaging.individuals",
                    selectedContacts.filter(
                      (selectedContact) => selectedContact.id !== contact.id
                    )
                  )
                }
              />
            </Tag>
          ))}
        </HStack>
      )}
    </Stack>
  );
}
