/* eslint-disable arrow-body-style */

import { AbsoluteCenter, Box, Button, Divider, Stack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

import type { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import { GoPlusCircle } from "react-icons/go";
import type { QuestionData } from "@/client/services/api/graphql/gql/graphql";
import TextResponseQuestion from "@/client/components/admin/create/content/forms/assessments/question-builders/text-response-assessment/TextResponseQuestion";
import { autoScrollWindowForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { generatePageBreak } from "@/client/utils/admin/create/assessments/generatePageBreak";
import { makeId } from "@/client/utils/admin/create/makeId";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useAssessmentStore } from "@/client/services/state/admin/create/assessmentStore";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useValidateTextAssessmentQuestionBuilder } from "@/client/services/hooks/admin/authoring/assessments/useValidateTextAssessmentQuestionBuilder";

export type TQuestion = QuestionData;

const questionDataKey = Symbol("text-response-question");

export type TQuestionData = { [questionDataKey]: true; id: TQuestion["id"] };

export function getQuestionData(question: TQuestion): TQuestionData {
  return { [questionDataKey]: true, id: question.id };
}

export function isQuestionData(
  data: Record<string | symbol, unknown>
): data is TQuestionData {
  return data[questionDataKey] === true;
}

const defaultQuestion = {
  format: "multiple_choice",
  question: "",
  rubricGrading: false,
  rubricOptions: [],
  choiceOptions: [
    {
      id: makeId(),
      copy: "",
      image: null,
      points: 0,
      position: 0,
    },
    {
      id: makeId(),
      copy: "",
      image: null,
      points: 0,
      position: 1,
    },
    {
      id: makeId(),
      copy: "",
      image: null,
      points: 0,
      position: 2,
    },
  ],
  questionOptions: [
    {
      name: "randomize_choice_order",
      value: false,
    },
    {
      name: "number_of_selections",
      value: 1,
    },
    {
      name: "multiple_selections",
      value: false,
    },
    {
      name: "manual_grade",
      value: false,
    },
    {
      name: "manual_points",
      value: 1,
    },
    {
      name: "grader_instructions",
      value: "",
    },
    {
      name: "word_limit",
      value: 5000,
    },
    {
      name: "word_minimum",
      value: 0,
    },
    {
      name: "max_value_is_total",
      value: true,
    },
  ],
};

export default function TextResponseQuestionBuilder() {
  const { t } = useTranslation();
  const { watch, setValue, setError } = useFormContext<AssessmentFormData>();
  const { validateTextAssessmentQuestionBuilder } =
    useValidateTextAssessmentQuestionBuilder();
  const { selectedEditQuestion, setSelectedEditQuestion, uploadStatus } =
    useAssessmentStore();
  const ref = useRef<HTMLDivElement | null>(null);

  const { questions } = watch();

  const selectedEditQuestionIndex = questions.findIndex(
    (q) => q.id === selectedEditQuestion?.id
  );

  const currentQuestion = questions[selectedEditQuestionIndex];

  useEffect(() => {
    validateTextAssessmentQuestionBuilder(true);
  }, [currentQuestion, currentQuestion?.question]);

  const validateSelectedEditQuestion = () => {
    if (!currentQuestion) return true;

    if (!currentQuestion?.question) {
      setError(
        `questions[${selectedEditQuestionIndex}].question` as keyof AssessmentFormData,
        {
          type: "validateSelectedEditQuestion",
          message: "Please select a question to edit.",
        }
      );
    }

    if (currentQuestion?.format === "long_input") {
      return true;
    }

    if (currentQuestion?.format === "short_input") {
      const useManualGrade = currentQuestion?.questionOptions?.find(
        (option) => option.name === "manual_grade"
      )?.value;

      if (useManualGrade && currentQuestion.question) return true;
    }

    const choiceOptionCopyErrors = [];
    const choiceOptionPointsErrors = [];

    currentQuestion?.choiceOptions?.forEach((option) => {
      if (!option.copy) {
        choiceOptionCopyErrors.push(option);

        setError(
          `questions[${selectedEditQuestionIndex}].choiceOptions[${option.position}].copy` as keyof AssessmentFormData,
          {
            type: "validateSelectedEditQuestion",
            message: `${t("assessment.error.pleaseEnterCopy")}`,
          }
        );
      }

      if (option.points === 0) {
        choiceOptionPointsErrors.push(option);

        setError(
          `questions[${selectedEditQuestionIndex}].choiceOptions[${option.position}].points` as keyof AssessmentFormData,
          {
            type: "validateSelectedEditQuestion",
            message: "Please enter a point value for this option (required).",
          }
        );
      }
    });

    if (
      choiceOptionCopyErrors.length > 0 ||
      choiceOptionPointsErrors.length ===
        currentQuestion?.choiceOptions?.length ||
      !currentQuestion.question
    ) {
      return false;
    }

    return true;
  };

  const handleAddNewQuestion = () => {
    const isValid = validateSelectedEditQuestion();

    if (!isValid) return;

    const questionIndex = questions.length;
    const page = questions
      .slice(0, questionIndex)
      .filter((q) => q.format === "page_break").length;
    const question = {
      id: makeId(),
      position: questionIndex,
      page,
      ...defaultQuestion,
    };

    setValue("questions", [...questions, question]);

    setSelectedEditQuestion(question);
  };

  const handleAddPageBreak = () => {
    setValue("questions", [...questions, generatePageBreak() as QuestionData]);
  };

  useEffect(() => {
    if (questions && questions.length === 0) {
      const question = {
        id: makeId(),
        position: 0,
        page: 0,
        ...defaultQuestion,
      };

      setValue("questions", [question]);
      setSelectedEditQuestion(question);
    }
  }, [questions]);

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isQuestionData(source.data);
      },
      onDrop({ location, source }) {
        if (!questions) return;

        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (!isQuestionData(sourceData) || !isQuestionData(targetData)) {
          return;
        }

        const indexOfSource = questions.findIndex(
          (field: QuestionData) => field.id === sourceData.id
        );
        const indexOfTarget = questions.findIndex(
          (field: QuestionData) => field.id === targetData.id
        );

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        function swapArrayElements(
          array: any[],
          index1: number,
          index2: number
        ) {
          if (
            index1 < 0 ||
            index2 < 0 ||
            index1 >= array.length ||
            index2 >= array.length
          ) {
            return array;
          }

          const newArray = [...array];
          const [removedElement] = newArray.splice(index1, 1);
          newArray.splice(index2, 0, removedElement);

          return newArray;
        }

        setValue(
          "questions",
          swapArrayElements(questions, indexOfSource, indexOfTarget)
        );
      },
    });
  }, [questions]);

  useEffect(() => {
    return autoScrollWindowForElements();
  });

  return (
    <Stack ref={ref} spacing={6}>
      {questions?.map((question, index) => (
        <TextResponseQuestion
          key={question.id}
          question={question as QuestionData}
          validateCurrentEditQuestion={validateSelectedEditQuestion}
          isFirst={index === 0}
        />
      ))}

      <Button
        variant="warmNeutralDashedBorder"
        leftIcon={<GoPlusCircle />}
        onClick={() => handleAddNewQuestion()}
        isDisabled={uploadStatus === "loading"}
      >
        {t("assessment.textResponse.newQuestion")}
      </Button>

      <Box position="relative" padding="10">
        <Divider borderColor="neutral.500" />

        <AbsoluteCenter bg="neutral.0" px="4">
          <Button
            size="sm"
            borderRadius="full"
            backgroundColor="neutral.100"
            color="neutral.1000"
            fontSize="14px"
            _hover={{
              backgroundColor: "neutral.200",
            }}
            onClick={() => handleAddPageBreak()}
            isDisabled={uploadStatus === "loading"}
          >
            {t("assessment.textResponse.addQuestionBreak")}
          </Button>
        </AbsoluteCenter>
      </Box>
    </Stack>
  );
}
