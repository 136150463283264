import { Box, Icon } from "@chakra-ui/react";
import {
  ScreenVoice,
  WebcamRecording,
} from "@/client/components/icons/ContinuIcons";

import { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import AssessmentRadioGroup from "../../../input/AssessmentRadioGroup";
import ContentFormTextGroup from "../../../text/ContentFormTextGroup";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function VideoResponseTypeForm() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AssessmentFormData>();

  const questions = watch("questions");

  const responseType =
    (questions &&
      (questions[0]?.questionOptions?.find(
        (option) => option.name === "recording_type"
      )?.value as string)) ||
    undefined;

  const handleResponseTypeChange = (value: string) => {
    const question = questions[0];

    const updatedQuestion = {
      ...question,
      questionOptions: question?.questionOptions?.map((option) => {
        if (option.name === "recording_type") {
          return { ...option, value };
        }

        return option;
      }),
    };

    // Add recording_type option if it doesn't exist
    if (
      !updatedQuestion.questionOptions?.find(
        (option) => option.name === "recording_type"
      )
    ) {
      updatedQuestion.questionOptions?.push({ name: "recording_type", value });
    }

    // @ts-ignore
    setValue("questions", [updatedQuestion]);
  };

  return (
    <Box paddingY={8}>
      <ContentFormTextGroup
        label={t("authoring.assessment.videoResponseType.label")}
      />

      <AssessmentRadioGroup
        name="videoResponseType"
        defaultValue={responseType}
        label=""
        onHandleChange={handleResponseTypeChange}
        options={[
          {
            value: "web_cam_video",
            label: t("authoring.assessment.videoResponseType.webcam"),
            icon: <Icon boxSize={12} as={WebcamRecording} />,
          },
          {
            value: "screen_voice",
            label: t("authoring.assessment.videoResponseType.screenVoice"),
            icon: <Icon boxSize={12} as={ScreenVoice} />,
          },
        ]}
      />
    </Box>
  );
}
