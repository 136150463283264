/* eslint-disable react/jsx-props-no-spreading */
import { useConfigStore } from "@/client/services/state/configStore";
import { useAdminStore } from "@/client/services/state/admin/adminStore";
import { useConnect } from "@/client/services/hooks/admin/connect/useConnect";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useVirtualizer, type VirtualItem } from "@tanstack/react-virtual";
import ReactDOM from "react-dom";
import invariant from "tiny-invariant";

import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import mergeRefs from "@atlaskit/ds-lib/merge-refs";

import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import * as liveRegion from "@atlaskit/pragmatic-drag-and-drop-live-region";
import { DragHandleButton } from "@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
  type ElementDragPayload,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import {
  Box,
  Grid,
  Text,
  Flex,
  ButtonGroup,
  useToken,
  Input,
} from "@chakra-ui/react";
import { token } from "@atlaskit/tokens";
import type { Category } from "@/client/types/Category";
import {
  useConnectStore,
  Language,
} from "@/client/services/state/admin/connectStore";
import IconButtonWithTooltip from "../../buttons/IconButtonWithTooltip";
import { useTranslation } from "react-i18next";
import {
  Check,
  Close,
  Delete,
  EditSimple,
  People,
  ViewOutline,
  ViewSlash,
} from "@/client/components/icons/ContinuIcons";
import { useToastStore } from "@/client/services/state/toastStore";
import { useMutation } from "@tanstack/react-query";
import ConnectService from "@/client/services/api/admin/connect/ConnectService";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import ConnectInput from "@/client/components/admin/connect/ConnectInput";
import { useLanguage } from "@/client/services/hooks/language/useLanguage";

type ItemPosition = "first" | "last" | "middle" | "only";

type CleanupFn = () => void;

type ItemEntry = { itemId: string; element: HTMLElement };

type ListContextValue = {
  getListLength: () => number;
  registerItem: (entry: ItemEntry) => CleanupFn;
  reorderItem: (args: {
    startIndex: number;
    indexOfTarget: number;
    closestEdgeOfTarget: Edge | null;
  }) => void;
  instanceId: symbol;
};

const ListContext = createContext<ListContextValue | null>(null);

function useListContext() {
  const listContext = useContext(ListContext);
  invariant(listContext !== null);
  return listContext;
}

const itemKey = Symbol("item");
type ItemData = {
  [itemKey]: true;
  item: Category;
  index: number;
  instanceId: symbol;
};

function getItemData({
  item,
  index,
  instanceId,
}: {
  item: Category;
  index: number;
  instanceId: symbol;
}): ItemData {
  return {
    [itemKey]: true,
    item,
    index,
    instanceId,
  };
}

function isItemData(data: Record<string | symbol, unknown>): data is ItemData {
  return data[itemKey] === true;
}

function getItemPosition({
  index,
  items,
}: {
  index: number;
  items: Category[];
}): ItemPosition {
  if (items.length === 1) {
    return "only";
  }

  if (index === 1) {
    return "first";
  }

  if (index === items.length - 1) {
    return "last";
  }

  return "middle";
}

const sizes = {
  item: {
    outer: 46,
    inner: 40,
  },
};

const itemOuterStyles = {
  padding: 1,

  borderBottom: "1px solid",
  borderBottomColor: "rgba(178, 178, 178, .9)",
};

const listItemInnerStyles = {
  width: "100%",
  flexShrink: "0", // locking the size
  height: `${sizes.item.inner}px`,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: 2,
  position: "relative", // for drop indicator placement
};

const listItemDisabledStyles = { opacity: 0.4 };

type ItemState =
  | { type: "idle" }
  | { type: "preview"; container: HTMLElement }
  | { type: "dragging" }
  | { type: "is-over"; closestEdge: Edge | null };

const idleState: ItemState = { type: "idle" };
const draggingState: ItemState = { type: "dragging" };

const listItemPreviewStyles = {
  paddingBlock: 2,
  paddingInline: 2,
  borderRadius: "md",
  backgroundColor: "white",
  maxWidth: "360px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function DropDownContent({
  position,
  index,
  level,
}: {
  position: ItemPosition;
  index: number;
  level: number;
}) {
  const { reorderItem, getListLength } = useListContext();

  const isMoveUpDisabled =
    level === 1 ? index === 1 : index === 0 || position === "only";
  const isMoveDownDisabled = position === "last" || position === "only";

  const moveToTop = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: level === 1 ? 1 : 0,
      closestEdgeOfTarget: null,
    });
  }, [index, reorderItem]);

  const moveUp = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: index - 1,
      closestEdgeOfTarget: null,
    });
  }, [index, reorderItem]);

  const moveDown = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: index + 1,
      closestEdgeOfTarget: null,
    });
  }, [index, reorderItem]);

  const moveToBottom = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: getListLength() - 1,
      closestEdgeOfTarget: null,
    });
  }, [index, getListLength, reorderItem]);

  return (
    <DropdownItemGroup>
      <DropdownItem onClick={moveToTop} isDisabled={isMoveUpDisabled}>
        Move to top
      </DropdownItem>
      <DropdownItem onClick={moveUp} isDisabled={isMoveUpDisabled}>
        Move up
      </DropdownItem>
      <DropdownItem onClick={moveDown} isDisabled={isMoveDownDisabled}>
        Move down
      </DropdownItem>
      <DropdownItem onClick={moveToBottom} isDisabled={isMoveDownDisabled}>
        Move to bottom
      </DropdownItem>
    </DropdownItemGroup>
  );
}

const ListItem = memo(
  ({
    item,
    index,
    virtualItem,
    position,
    handleSelectItem,
    selectedLanguage,
    editItem,
    level,
  }: {
    item: Category;
    index: number;
    virtualItem: VirtualItem;
    position: ItemPosition;
    handleSelectItem: (item: Category) => void;
    selectedLanguage: Language;
    editItem: any;
    level: number;
  }) => {
    const { t } = useTranslation();
    const { registerItem, instanceId } = useListContext();
    const { config } = useConfigStore();
    const {
      type,
      showExternalOption,
      selectedEditItem,
      setSelectedEditItem,
      activeMainCategory,
      activeSubCategory,
      activeSubSubCategory,
      isSearching,
    } = useConnectStore();
    const { setDeleteModal, setPartnerPermissionModal } = useAdminStore();
    const canDrag = item.level !== 10 && !isSearching && type === "category";
    const [showExternal, setShowExternal] = useState(item.show_external);
    const [brandHighlight, brandButton] = useToken("colors", [
      "brand.highlight",
      "brand.button",
    ]);
    const { setToast } = useToastStore();

    const [value, setValue] = useState<string>(item.renderedName);

    useEffect(() => {
      setValue(item.renderedName);
    }, [item.renderedName]);

    const innerRef = useRef<HTMLDivElement>(null);

    const dragHandleRef = useRef<HTMLButtonElement>(null);

    const [state, setState] = useState<ItemState>(idleState);

    const showSuccessToast = () =>
      setToast({
        show: true,
        status: "success",
        title: t("admin.connect.categoryUpdateSuccess"),
      });

    const showErrorToast = () =>
      setToast({
        show: true,
        status: "error",
        title: t("admin.connect.categoryUpdateError"),
      });

    const showExternalMutation = useMutation({
      mutationFn: () =>
        ConnectService.setShowExternal(item._id, item.name, true),
      onSuccess: () => {
        setShowExternal(!showExternal);
        showSuccessToast();
      },
      onError: () => showErrorToast(),
    });

    const hideExternalMutation = useMutation({
      mutationFn: () => ConnectService.hideExternal(item._id),
      onSuccess: () => {
        setShowExternal(!showExternal);
        showSuccessToast();
      },
      onError: () => showErrorToast(),
    });

    const handleEditItem = () => {
      if (selectedLanguage.abr === "en") {
        editItem.mutateAsync({
          itemId: item._id,
          name: value,
          localizedNames: item.localized_names,
          showExternal,
          level: item.level,
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        item.localizedNamesObj[selectedLanguage.abr] = value;

        const str = Object.entries(item.localizedNamesObj)
          .map(([k, v]) => `${k}:${v}`)
          .join(",");

        editItem.mutateAsync({
          itemId: item._id,
          name: item.name,
          localizedNames: str,
          showExternal,
          level: item.level,
        });
      }
    };

    const partnerPermissionSave = async (category: Category) => {
      await editItem.mutateAsync({
        itemId: category._id,
        name: category.name,
        localizedNames: category.localized_names,
        partnerPermissions: category.partner_permissions,
        level: category.level,
      });
    };

    useEffect(() => {
      if (!canDrag) return;

      const element = innerRef.current;
      invariant(element);
      invariant(dragHandleRef.current);

      function predicate({ source }: { source: ElementDragPayload }): boolean {
        return (
          isItemData(source.data) &&
          source.data.item.level !== 10 &&
          source.data.instanceId === instanceId &&
          source.data.item._id !== item._id
        );
      }

      const data = getItemData({ item, index, instanceId });

      return combine(
        registerItem({ itemId: item._id, element }),
        draggable({
          element,
          dragHandle: dragHandleRef.current,
          getInitialData: () => data,
          onGenerateDragPreview({ nativeSetDragImage }) {
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: pointerOutsideOfPreview({
                x: token("space.200", "16px"),
                y: token("space.100", "8px"),
              }),
              render({ container }) {
                setState({ type: "preview", container });

                return () => setState(draggingState);
              },
            });
          },
          onDragStart() {
            setState(draggingState);
          },
          onDrop() {
            setState(idleState);
          },
        }),
        dropTargetForElements({
          element,
          canDrop: predicate,
          getIsSticky: () => true,
          getData({ input }) {
            return attachClosestEdge(data, {
              element,
              input,
              allowedEdges: ["top", "bottom"],
            });
          },
          onDrag({ self }) {
            const closestEdge = extractClosestEdge(self.data);

            setState((current) => {
              if (
                current.type === "is-over" &&
                current.closestEdge === closestEdge
              ) {
                return current;
              }
              return { type: "is-over", closestEdge };
            });
          },
          onDragLeave() {
            setState(idleState);
          },
          onDrop() {
            setState(idleState);
          },
        })
      );
    }, [instanceId, item, index, registerItem]);

    return (
      <>
        <Box
          _hover={{ backgroundColor: hexToRGBA(brandHighlight, 0.05) }}
          backgroundColor={
            activeMainCategory?._id === item._id ||
            activeSubCategory?._id === item._id ||
            activeSubSubCategory?._id === item._id
              ? hexToRGBA(brandHighlight, 0.2)
              : "white"
          }
          sx={itemOuterStyles}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: `${virtualItem.size}px`,
            boxSizing: "border-box",
            transform: `translateY(${virtualItem.start}px)`,
            // Our items are pressed up next to each other, and are
            // positioned using `position:absolute`.
            // The drop indicator of one item needs to sit partially
            // on top of the other item.
            zIndex:
              state.type === "is-over" && state.closestEdge ? 1 : undefined,
          }}
        >
          {/**
           * Positioning the drop indicator as a sibling so that it's `position:absolute` does
           * not need to take into account the borders of the item
           * https://codesandbox.io/p/sandbox/position-relative-and-borders-rjs9gr?file=%2Fsrc%2Fstyles.css%3A15%2C2
           */}
          <Box
            style={{
              position: "relative",
              height: sizes.item.inner,
            }}
          >
            <Grid
              ref={innerRef}
              alignItems="center"
              columnGap="space.100"
              templateColumns="auto 1fr auto"
              sx={
                state.type === "dragging"
                  ? {
                      ...listItemInnerStyles,
                      /**
                       * We are applying the disabled effect to the inner element so that
                       * the border and drop indicator are not affected.
                       */
                      ...listItemDisabledStyles,
                    }
                  : listItemInnerStyles
              }
            >
              {canDrag && (
                <DropdownMenu
                  // eslint-disable-next-line react/no-unstable-nested-components
                  trigger={({ triggerRef, ...triggerProps }) => (
                    <DragHandleButton
                      ref={mergeRefs([dragHandleRef, triggerRef])}
                      {...triggerProps}
                      label={`Reorder ${item._id}`}
                      style={{
                        pointerEvents:
                          state.type !== "idle" ? "none" : undefined,
                        marginRight: "8px",
                      }}
                    />
                  )}
                >
                  <DropdownItemGroup>
                    <DropDownContent
                      position={position}
                      index={index}
                      level={level}
                    />
                  </DropdownItemGroup>
                </DropdownMenu>
              )}

              {selectedEditItem !== item && (
                <Flex width="full">
                  <Flex
                    paddingY={3}
                    alignItems="center"
                    flex={1}
                    flexGrow={1}
                    _hover={{ cursor: "pointer" }}
                    onClick={() => handleSelectItem(item)}
                  >
                    <Text fontWeight="medium" fontSize="14px">
                      {item.renderedName}
                    </Text>
                  </Flex>

                  <ButtonGroup
                    color={brandButton}
                    alignItems="center"
                    isAttached
                  >
                    {showExternalOption && (
                      <IconButtonWithTooltip
                        tooltipCopy={
                          showExternal
                            ? t("categoryInput.hide_external")
                            : t("categoryInput.show_external")
                        }
                        icon={showExternal ? <ViewOutline /> : <ViewSlash />}
                        ariaLabel={
                          showExternal
                            ? t("categoryInput.hide_external")
                            : t("categoryInput.show_external")
                        }
                        onClick={() =>
                          showExternal
                            ? hideExternalMutation.mutateAsync()
                            : showExternalMutation.mutateAsync()
                        }
                      />
                    )}

                    <IconButtonWithTooltip
                      tooltipCopy={t("global.actions.edit")}
                      ariaLabel={t("global.actions.edit")}
                      icon={<EditSimple />}
                      onClick={() => {
                        setSelectedEditItem(item);
                      }}
                    />
                  </ButtonGroup>
                </Flex>
              )}

              {selectedEditItem === item && (
                <Flex width="full">
                  <Flex paddingY={3} alignItems="center" flex={1} flexGrow={1}>
                    <Input
                      variant="flushed"
                      size="xs"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </Flex>

                  <ButtonGroup isAttached alignItems="center">
                    <IconButtonWithTooltip
                      tooltipCopy={t("global.action.saveChange")}
                      ariaLabel={t("global.action.saveChange")}
                      icon={<Check color="green" />}
                      onClick={() => {
                        handleEditItem();
                      }}
                    />

                    <IconButtonWithTooltip
                      tooltipCopy={t("global.actions.cancel")}
                      ariaLabel={t("global.actions.cancel")}
                      icon={<Close color="red" />}
                      onClick={() => {
                        setSelectedEditItem(null);
                        setValue(item.renderedName);
                      }}
                    />

                    {config.features.enable_partners && (
                      <IconButtonWithTooltip
                        tooltipCopy={t("content.edit.partners.label")}
                        ariaLabel={t("content.edit.partners.label")}
                        icon={<People color="blue" />}
                        onClick={() => {
                          setPartnerPermissionModal({
                            isOpen: true,
                            value: item.partner_permissions || "tenant",
                            selectedItem: item,
                            isControlled: false,
                            handleSubmit: partnerPermissionSave,
                          });
                        }}
                      />
                    )}

                    {item.level !== 10 && (
                      <IconButtonWithTooltip
                        tooltipCopy={t("global.action.remove")}
                        ariaLabel={t("global.action.remove")}
                        icon={<Delete color="red" />}
                        onClick={() => {
                          setDeleteModal({
                            isOpen: true,
                            selectedItem: item,
                          });
                        }}
                      />
                    )}
                  </ButtonGroup>
                </Flex>
              )}
            </Grid>

            {state.type === "is-over" && state.closestEdge && (
              <DropIndicator
                edge={state.closestEdge}
                gap={`${sizes.item.outer - sizes.item.inner}px`}
              />
            )}
          </Box>
        </Box>

        {state.type === "preview" &&
          ReactDOM.createPortal(
            <Box sx={listItemPreviewStyles}>{item.renderedName}</Box>,
            state.container
          )}
      </>
    );
  }
);

function getItemRegistry() {
  const registry = new Map<string, HTMLElement>();

  function register({ itemId, element }: ItemEntry) {
    registry.set(itemId, element);

    return function unregister() {
      // Due to how the virtualizer works,
      // a new item can be mounted before an old
      // item is removed.
      // We don't want `unregister` to remove
      // a new registration
      if (registry.get(itemId) === element) {
        registry.delete(itemId);
      }
    };
  }

  function getElement(itemId: string): HTMLElement | null {
    return registry.get(itemId) ?? null;
  }

  return { register, getElement };
}

type ListState = {
  items: Category[];
  lastCardMoved: {
    item: Category;
    previousIndex: number;
    currentIndex: number;
    numberOfItems: number;
  } | null;
};

interface ThreeColumnListNewProps {
  categories: Category[];
  title: string;
  parentId: string | null | undefined;
  level: number;
  showInput: boolean;
  noItemsText: string;
  noSearchResultsCopy: string;
}

export default function ThreeColumnListNew({
  categories,
  title,
  parentId,
  level,
  showInput,
  noItemsText,
  noSearchResultsCopy,
}: ThreeColumnListNewProps) {
  const {
    handleSelectItem,
    editItem,
    levelOneQuery,
    levelTwoQuery,
    levelThreeQuery,
    updateSortOrder,
  } = useConnect();
  const {
    isSearching,
    setIsSearching,
    setActiveMainCategory,
    setActiveSubCategory,
    setActiveSubSubCategory,
    searchTerm,
    type,
    setSubItems,
    setSubSubItems,
  } = useConnectStore();
  const { selectedLanguage } = useLanguage();
  const [{ items, lastCardMoved }, setListState] = useState<ListState>(() => ({
    items: categories,
    lastCardMoved: null,
  }));

  useEffect(() => {
    if (!searchTerm) {
      setSubItems([]);
      setSubSubItems([]);
      setIsSearching(false);
      setActiveMainCategory(null);
      setActiveSubCategory(null);
      setActiveSubSubCategory(null);
    }

    if (searchTerm) {
      setIsSearching(true);
      setActiveMainCategory(null);
      setActiveSubCategory(null);
      setActiveSubSubCategory(null);
    }
  }, [searchTerm]);

  const previousItems = useRef<Category[]>(items);

  const [registry] = useState(getItemRegistry);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const { hasNextPage, isFetchingNextPage, fetchNextPage, refetch } =
    level === 1 ? levelOneQuery : level === 2 ? levelTwoQuery : levelThreeQuery;

  const arraysInSameOrder = (arr1: Category[], arr2: Category[]) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i]._id !== arr2[i]._id) {
        return false;
      }
    }

    return true;
  };

  // Handle updating sort index
  useEffect(() => {
    if (type !== "category" || isSearching || items.length === 0) return;

    // const areItemsEqual = isEqual(items, previousItems.current);
    const areItemsEqual = arraysInSameOrder(items, previousItems.current);

    if (!areItemsEqual && previousItems.current.length > 0) {
      updateSortOrder
        .mutateAsync({
          ids: items.map((item) => item._id),
          parentId,
          level,
        })
        .then(() => refetch());
    }

    previousItems.current = items;
  }, [items]);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => sizes.item.outer,
    overscan: 5,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= items.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    items.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ]);

  // Isolated instances of this component from one another
  const [instanceId] = useState(() => Symbol("instance-id"));

  const reorderItem = useCallback(
    ({
      startIndex,
      indexOfTarget,
      closestEdgeOfTarget,
    }: {
      startIndex: number;
      indexOfTarget: number;
      closestEdgeOfTarget: Edge | null;
    }) => {
      const finishIndex = getReorderDestinationIndex({
        startIndex,
        closestEdgeOfTarget,
        indexOfTarget,
        axis: "vertical",
      });

      if (finishIndex === startIndex) {
        // If there would be no change, we skip the update
        return;
      }

      setListState((listState) => {
        const item = listState.items[startIndex];

        return {
          items: reorder({
            list: listState.items,
            startIndex,
            finishIndex,
          }),
          lastCardMoved: {
            item,
            previousIndex: startIndex,
            currentIndex: finishIndex,
            numberOfItems: listState.items.length,
          },
        };
      });
    },
    []
  );

  useEffect(() => {
    setListState((listState) => ({
      items: categories,
      lastCardMoved: listState.lastCardMoved,
    }));
  }, [categories]);

  useEffect(() => {
    if (lastCardMoved === null) {
      return;
    }

    const { item, currentIndex } = lastCardMoved;

    // Not sure why, but can only scroll to the updated index after a timeout
    setTimeout(() => {
      // need to make the item visible before it will appear in our registry
      rowVirtualizer.scrollToIndex(currentIndex);

      // Now waiting for new elements to be registered before we trigger a flash
      // Unfortunately, there is no great way to wait for the virualizer to update
      // `options.onChange` works at the top level.
      // If anybody has suggestions on how to do this better, please let us know!
      setTimeout(() => {
        const element = registry.getElement(item._id);
        if (element) {
          triggerPostMoveFlash(element);
        }
      }, 100);
    });
  }, [lastCardMoved, rowVirtualizer, registry]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    invariant(scrollContainer);

    function canRespond({ source }: { source: ElementDragPayload }) {
      return isItemData(source.data) && source.data.instanceId === instanceId;
    }

    return combine(
      monitorForElements({
        canMonitor: canRespond,
        onDrop({ location, source }) {
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }

          const sourceData = source.data;
          const targetData = target.data;
          if (!isItemData(sourceData) || !isItemData(targetData)) {
            return;
          }

          const indexOfTarget = items.findIndex(
            (item) => item._id === targetData.item._id
          );
          if (indexOfTarget < 0) {
            return;
          }

          const closestEdgeOfTarget = extractClosestEdge(targetData);

          reorderItem({
            startIndex: sourceData.index,
            indexOfTarget,
            closestEdgeOfTarget,
          });
        },
      }),
      autoScrollForElements({
        canScroll: canRespond,
        element: scrollContainer,
      })
    );
  }, [instanceId, items, reorderItem]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return function cleanup() {
      liveRegion.cleanup();
    };
  }, []);

  const getListLength = useCallback(() => items.length, [items.length]);

  // eslint-disable-next-line arrow-body-style
  const contextValue: ListContextValue = useMemo(() => {
    return {
      registerItem: registry.register,
      reorderItem,
      instanceId,
      getListLength,
    };
  }, [registry.register, reorderItem, instanceId, getListLength]);

  return (
    <>
      <Text
        fontSize="12px"
        fontWeight="medium"
        color="brand.grey.50"
        marginBottom={2}
      >
        {title}
      </Text>

      <Flex
        direction="column"
        minHeight="75vh"
        maxHeight="75vh"
        backgroundColor="white"
        paddingY={4}
        paddingX={8}
        borderRadius="10px"
      >
        {showInput && <ConnectInput level={level} parentId={parentId} />}

        <ListContext.Provider value={contextValue}>
          <Box
            ref={scrollContainerRef}
            flex={1}
            overflow="auto"
            paddingBottom={12}
          >
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {isSearching && rowVirtualizer.getVirtualItems().length === 0 && (
                <Text align="center" padding={14} fontSize="14px">
                  {noSearchResultsCopy}
                </Text>
              )}

              {!isSearching &&
                rowVirtualizer.getVirtualItems().length === 0 && (
                  <Text align="center" padding={14} fontSize="14px">
                    {noItemsText}
                  </Text>
                )}

              {rowVirtualizer.getVirtualItems().length > 0 &&
                rowVirtualizer
                  .getVirtualItems()
                  .map((virtualItem) => (
                    <ListItem
                      level={level}
                      editItem={editItem}
                      selectedLanguage={selectedLanguage}
                      key={virtualItem.key}
                      item={items[virtualItem.index]}
                      virtualItem={virtualItem}
                      index={virtualItem.index}
                      position={getItemPosition({
                        index: virtualItem.index,
                        items,
                      })}
                      handleSelectItem={handleSelectItem}
                    />
                  ))}
            </div>
          </Box>
        </ListContext.Provider>
      </Flex>
    </>
  );
}
