import "react-datepicker/dist/react-datepicker.css";

import { Controller, useFormContext } from "react-hook-form";
import { Flex, FormControl, FormLabel } from "@chakra-ui/react";

import AdminElevatedBox from "../../layout/AdminElevatedBox";
import DatePicker from "react-datepicker";
import HeadingWithTooltip from "../HeadingWithTooltip";
import { useAuthStore } from "@/client/services/state/authStore";
import { useTranslation } from "react-i18next";

// TODO: Replace datepicker here with `components/admin/input/datepicker/Datepicker.tsx`

// TODO: Fix Clipping in Accordion
// TODO: Get Custom Calendar Icon

export default function AccountDetails() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { date_display: dateDisplay } = company;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const dateFormat = dateDisplay === "australia" ? "dd/MM/yyyy" : "MM/dd/yyyy";

  return (
    <AdminElevatedBox>
      <HeadingWithTooltip
        title={t("edit.useradd.tab.accountDetails")}
        helpText={t("registrationForms.tooltip.segmentation")}
      />

      <Flex direction="row" gap={1}>
        <FormControl>
          <FormLabel fontSize="14px" fontWeight="bold">
            {t("modules.usersearch.filter_hiredon")}
          </FormLabel>

          <Controller
            name="hired"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                dateFormat={dateFormat}
                selected={value ? new Date(value) : undefined}
                onChange={onChange}
                placeholderText="00/00/00"
                popperProps={{ strategy: "fixed" }}
                showIcon
              />
            )}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="14px" fontWeight="bold">
            {t("overview.workflows.manager_createdOn")}
          </FormLabel>

          <Controller
            name="createdAt"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                dateFormat={dateFormat}
                selected={value ? new Date(value) : undefined}
                onChange={onChange}
                placeholderText="00/00/00"
                popperProps={{ strategy: "fixed" }}
                showIcon
                disabled
              />
            )}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="14px" fontWeight="bold">
            {t("edit.useradd.lastLogin")}
          </FormLabel>

          <Controller
            name="last_login"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                dateFormat={dateFormat}
                selected={value ? new Date(value) : undefined}
                onChange={onChange}
                placeholderText="00/00/00"
                popperProps={{ strategy: "fixed" }}
                showIcon
                disabled
              />
            )}
          />
        </FormControl>
      </Flex>
    </AdminElevatedBox>
  );
}
