import EcommerceService from "@/client/services/api/graphql/EcommerceService";
import { useToastStore } from "@/client/services/state/toastStore";
import { Stack, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function StripeSetupSuccess() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { setToast } = useToastStore();

  const accountParam = searchParams.get("account");
  const avtParam = searchParams.get("avt");
  if (!accountParam) {
    return (
      <Stack spacing={4} width="100%">
        <Text as="h4">{t("stripeSettings.setupAccountRequired")}</Text>
      </Stack>
    );
  }
  if (!avtParam) {
    return (
      <Stack spacing={4} width="100%">
        <Text as="h4">
          {t("stripeSettings.setupVerificationTokenRequired")}
        </Text>
      </Stack>
    );
  }

  const onSetupStripeMutation = useMutation({
    mutationFn: () =>
      EcommerceService.linkStripeAccount(accountParam, avtParam),
    onSuccess: () => {
      setToast({
        title: t("stripeSettings.setupSuccess"),
        status: "success",
        show: true,
      });
    },
    onError: () => {
      setToast({
        title: t("stripeSettings.setupFailed"),
        status: "error",
        show: true,
      });
    },
  });

  // Gross, but what we have to do so we can do it here vs. on the server (which would require exposing the API URL in the browser).
  useEffect(() => {
    if (onSetupStripeMutation.isIdle) {
      onSetupStripeMutation.mutate();
    }
  }, []);

  return (
    <Stack spacing={4} width="100%">
      <Text as="h4">{t("stripeSettings.setupComplete")}</Text>
    </Stack>
  );
}
