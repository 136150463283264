import { learnApiClient } from "../../clients/learnApiClient";

const getCompany = async () => {
  const response = await learnApiClient.get("/companies");
  return response.data;
};

const setThemeCards = async (type: "list" | "card") => {
  const response = await learnApiClient.post("companies/theme-cards", {
    type,
  });
  return response.data;
};

interface ColorOverrides {
  anchors: string;
  buttons: string;
  headers: string;
  highLights: string;
  links: string;
  formInputs: string;
}

const setThemeColorOverrides = async (colorOverrides: ColorOverrides) => {
  const response = await learnApiClient.post(
    "companies/theme-color-overrides",
    {
      colorOverrides,
    }
  );

  return response.data;
};

const ThemeService = { getCompany, setThemeCards, setThemeColorOverrides };

export default ThemeService;
