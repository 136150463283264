import { Badge } from "@/client/types/Badge";
import { learnApiClient } from "./clients/learnApiClient";

const getUserBadgesInfinite = async (
  id: string,
  pageParam: number,
  perPage: number
) => {
  const response = await learnApiClient.get(
    `badges/awarded/user/${id}?page=${pageParam}&perPage=${perPage}&sort=-1`
  );

  return response.data;
};

// TODO: Update this query once nested resolvers for badges exist in GraphQL
const getUserBadgesPaginated = async (
  options: { pageIndex: number; pageSize: number },
  userId: string | undefined,
  totalBadgeCount?: number | undefined
): Promise<{ rows: Badge[]; pageCount: number }> => {
  const response = await learnApiClient.get(
    `badges/awarded/user/${userId}?page=${options.pageIndex}&perPage=${options.pageSize}&sort=-1`
  );

  return {
    rows: response.data,
    pageCount: totalBadgeCount
      ? Math.ceil(totalBadgeCount / options.pageSize)
      : 1,
  };
};

const BadgeService = {
  getUserBadgesInfinite,
  getUserBadgesPaginated,
};

export default BadgeService;
