import { Category } from "@/client/types/Category";
import { ProfileForm } from "@/client/types/admin/profiles/ProfileForm";
import SegmentationSearch from "@/client/components/admin/input/search/SegmentationSearch";
import { Stack } from "@chakra-ui/react";
import SwitchList from "@/client/routes/admin/profiles/forms/SwitchList";
import SwitchWithTooltip from "@/client/components/admin/forms/SwitchWithTooltip";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ContentSegmentation() {
  const { watch, setValue } = useFormContext<ProfileForm>();
  const { t } = useTranslation();

  const restrictSegmentationOptions = watch(
    "permissions.admin.content.restrict_segmentation_options"
  );

  const requireSegmentation = watch(
    "permissions.admin.content.require_segmentation"
  );
  const restrictContentCategories = watch(
    "permissions.admin.content.restrict_category_options"
  );

  const contentSegmentationSelections = watch(
    "permissions.admin.content.segmentation_group"
  );
  const categorySegmentationSelections = watch(
    "permissions.admin.content.allowed_categories"
  );

  useEffect(() => {
    if (!restrictSegmentationOptions) {
      const segmentationGroup = {
        departments: [],
        locations: [],
        teams: [],
        org_levels: [],
        grades: [],
      };

      setValue(
        "permissions.admin.content.segmentation_group",
        segmentationGroup
      );
    }
  }, [restrictSegmentationOptions]);

  const options = [
    {
      name: "permissions.admin.content.archive_content",
      label: t("profiles.edit.allowArchiving"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.unarchive_content",
      label: t("profiles.edit.allowUnarchiving"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.approve_content",
      label: t("profiles.edit.allowApproval"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.assessment_reset",
      label: t("profiles.edit.allowAssessmentReset"),
      enabled: true,
    },
  ];

  const handleContentSegmentationSelection = (
    option: Category,
    action: "add" | "remove"
  ) => {
    if (option.department) {
      action === "add"
        ? setValue("permissions.admin.content.segmentation_group.departments", [
            ...contentSegmentationSelections.departments,
            option,
          ])
        : setValue(
            "permissions.admin.content.segmentation_group.departments",
            contentSegmentationSelections.departments.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.location) {
      action === "add"
        ? setValue("permissions.admin.content.segmentation_group.locations", [
            ...contentSegmentationSelections.locations,
            option,
          ])
        : setValue(
            "permissions.admin.content.segmentation_group.locations",
            contentSegmentationSelections.locations.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.team) {
      action === "add"
        ? setValue("permissions.admin.content.segmentation_group.teams", [
            ...contentSegmentationSelections.teams,
            option,
          ])
        : setValue(
            "permissions.admin.content.segmentation_group.teams",
            contentSegmentationSelections.teams.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.org_level) {
      action === "add"
        ? setValue("permissions.admin.content.segmentation_group.org_levels", [
            ...contentSegmentationSelections.org_levels,
            option,
          ])
        : setValue(
            "permissions.admin.content.segmentation_group.org_levels",
            contentSegmentationSelections.org_levels.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.grade) {
      action === "add"
        ? setValue("permissions.admin.content.segmentation_group.grades", [
            ...contentSegmentationSelections.grades,
            option,
          ])
        : setValue(
            "permissions.admin.content.segmentation_group.grades",
            contentSegmentationSelections.grades.filter(
              (d) => d._id !== option._id
            )
          );
    }
  };

  const handleCategorySegmentationSelection = (
    option: Category,
    action: "add" | "remove"
  ) => {
    action === "add"
      ? setValue("permissions.admin.content.allowed_categories", [
          ...categorySegmentationSelections,
          option,
        ])
      : setValue(
          "permissions.admin.content.allowed_categories",
          categorySegmentationSelections.filter((d) => d._id !== option._id)
        );
  };

  return (
    <Stack spacing={4}>
      <SwitchWithTooltip
        name="permissions.admin.content.restrict_segmentation_options"
        label={t("profiles.edit.restrictSegmentationOptions")}
        tooltipText={t("profiles.edit.limitsVecOnly")}
        isDisabled={requireSegmentation}
      />

      <SwitchWithTooltip
        name="permissions.admin.content.require_segmentation"
        label={t("profiles.edit.requireSegmentation")}
        tooltipText={t("profiles.edit.limitsVecAnd")}
        isDisabled={restrictSegmentationOptions}
      />

      {(requireSegmentation || restrictSegmentationOptions) && (
        <SegmentationSearch
          label={t("profiles.edit.segmentationReactions")}
          inputPlaceholder="Search Fields"
          tooltipText=""
          segmentationSelections={contentSegmentationSelections}
          updateSelections={handleContentSegmentationSelection}
        />
      )}

      <SwitchWithTooltip
        name="permissions.admin.content.restrict_category_options"
        label={t("profiles.edit.restrictCategoryOptions")}
        tooltipText={t("profiles.edit.limitAddCat")}
      />

      {restrictContentCategories && (
        <SegmentationSearch
          label={t("profiles.edit.segmentationReactions")}
          inputPlaceholder="Search Fields"
          tooltipText=""
          categoriesOnly
          segmentationSelections={categorySegmentationSelections}
          updateSelections={handleCategorySegmentationSelection}
        />
      )}

      <SwitchList enableSelectAll={false} options={options} />
    </Stack>
  );
}
