import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Workshop, WorkshopInstance } from "@/client/types/content/Workshop";
import { format, isBefore } from "date-fns";
import { useEffect, useState } from "react";

import { Play } from "@/client/components/icons/ContinuIcons";
import { UseMutationResult } from "@tanstack/react-query";
import { UserWorkshop } from "@/client/types/content/workshops/UserWorkshop";
import WorkshopInstanceList from "./WorkshopInstanceList";
import { useTranslation } from "react-i18next";

interface WorkshopHeaderProps {
  workshop: Workshop;
  futureInstances: WorkshopInstance[];
  selectedInstance: WorkshopInstance | undefined;
  setSelectedInstance: (instance: WorkshopInstance) => void;
  setVideoModalOpen: (videoModalOpen: boolean) => void;
  isRegistered: boolean;
  registeredInstances: UserWorkshop[];
  waitingInstances: UserWorkshop[];
  register: UseMutationResult<void, unknown, void, unknown>;
  unregister: any;
  unregisterWithNewRegister: any;
  joinWaitlist: UseMutationResult<void, unknown, void, unknown>;
  leaveWaitlist: UseMutationResult<void, unknown, void, unknown>;
  availableSeats: number;
  waitlistSeats: number;
  hasRecordings: boolean;
  allowReregistration: boolean;
  requireAttendanceForReregistration: boolean;
  attendedInstances: UserWorkshop[];
}

export default function WorkshopHeader({
  workshop,
  futureInstances,
  selectedInstance,
  setSelectedInstance,
  setVideoModalOpen,
  isRegistered,
  registeredInstances,
  waitingInstances,
  register,
  unregister,
  unregisterWithNewRegister,
  joinWaitlist,
  leaveWaitlist,
  availableSeats,
  waitlistSeats,
  hasRecordings,
  allowReregistration,
  requireAttendanceForReregistration,
  attendedInstances,
}: WorkshopHeaderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [isPastDate, setIsPastDate] = useState(false);
  const [registeredForCurrentInstance, setRegisteredForCurrentInstance] =
    useState(false);
  const [showInstanceList, setShowInstanceList] = useState(false);
  const [hasAttendedSelectedInstance, setHasAttendedSelectedInstance] =
    useState(false);
  const [isWaitingForSelectedInstance, setIsWaitingForSelectedInstance] =
    useState(false);

  const checkIfPriorDate = (instance: WorkshopInstance) => {
    const today = new Date(Date.now() - 86400000);
    const instanceDate = new Date(instance.start_date);

    const before = isBefore(instanceDate, today);

    if (before && isRegistered) {
      setIsPastDate(true);
    } else {
      setIsPastDate(false);
    }
  };

  useEffect(() => {
    if (selectedInstance) {
      const attendedSelectedInstance =
        attendedInstances.findIndex(
          (instance) => instance.workshop_instance === selectedInstance._id
        ) !== -1;

      setHasAttendedSelectedInstance(attendedSelectedInstance);

      const waitingForSelectedInstance =
        waitingInstances.findIndex(
          (instance) => instance.workshop_instance === selectedInstance._id
        ) !== -1;

      setIsWaitingForSelectedInstance(waitingForSelectedInstance);

      checkIfPriorDate(selectedInstance);

      setRegisteredForCurrentInstance(
        registeredInstances.findIndex(
          (instance) => instance.workshop_instance === selectedInstance._id
        ) !== -1
      );
    }
  }, [
    selectedInstance,
    registeredInstances,
    attendedInstances,
    waitingInstances,
  ]);

  useEffect(() => {
    if (futureInstances.length === 1) {
      if (allowReregistration) {
        setShowInstanceList(true);
      } else {
        setShowInstanceList(false);
      }
      return;
    }

    if (futureInstances.length > 1) {
      if (allowReregistration) {
        setShowInstanceList(true);
        return;
      }

      if (!registeredForCurrentInstance && !isWaitingForSelectedInstance) {
        setShowInstanceList(true);
      } else {
        setShowInstanceList(false);
      }
    }
  }, [
    registeredForCurrentInstance,
    isWaitingForSelectedInstance,
    hasAttendedSelectedInstance,
  ]);

  return (
    <>
      <Box minHeight="375px" position="relative" backgroundColor="#191919">
        <Box
          position="absolute"
          top="0"
          left="0"
          width="full"
          height="full"
          overflow="hidden"
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            height="full"
            width="full"
            backgroundImage={workshop.banner_image}
            backgroundSize="cover"
            filter="blur(7px)"
            transform="scale(1.07)"
            opacity={0.3}
          />
        </Box>

        <Container
          maxW="1100px"
          height="full"
          minHeight="375px"
          position="relative"
          backgroundColor="white"
          paddingLeft={0}
          paddingRight={0}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            height="full"
            minHeight="375px"
          >
            <Flex
              flexBasis="67%"
              backgroundImage={workshop.banner_image}
              backgroundSize="cover"
              backgroundPosition="top center"
              position="relative"
            >
              {hasRecordings && (
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%,-50%)"
                >
                  <Play
                    height="150px"
                    width="150px"
                    color="brand.primary"
                    _hover={{ cursor: "pointer" }}
                    onClick={() => setVideoModalOpen(true)}
                  />
                </Box>
              )}
            </Flex>

            <Flex
              direction="column"
              width={{ base: "full", md: "50%" }}
              backgroundColor="blackAlpha.50"
              padding={6}
            >
              {selectedInstance && (
                <Box color="brand.primary" marginTop={2}>
                  <Text textTransform="uppercase" fontSize="lg">
                    {format(new Date(selectedInstance.start_date), "MMM")}
                  </Text>

                  <Text fontSize="3xl" marginTop={-2}>
                    {format(new Date(selectedInstance.start_date), "dd")}
                  </Text>
                </Box>
              )}

              {futureInstances.length > 1 && (
                <Text fontSize="xs" color="blackAlpha.700">
                  {t("workshopviewer.other_dates_available", {
                    count: futureInstances.length - 1,
                  })}
                </Text>
              )}

              <Text variant="heroheader" marginY={4}>
                {workshop.title}
              </Text>

              {isPastDate && (
                <Text fontSize="xs" marginY={4}>
                  This workshop instance date is in the past. You can no longer
                  register or unregister. Please contact your administrator.
                </Text>
              )}

              <>
                {futureInstances.length === 0 && (
                  <Text fontSize="sm" color="blackAlpha.500">
                    {t("workshopviewer.no_available_dates")}
                  </Text>
                )}

                {selectedInstance && (
                  <Stack spacing={2}>
                    <Text as="i" color="blackAlpha.500">
                      {selectedInstance.host.name}
                    </Text>

                    {(!hasAttendedSelectedInstance ||
                      (hasAttendedSelectedInstance && allowReregistration)) && (
                      <>
                        {availableSeats > 0 && (
                          <Text fontSize="xs">
                            {t("workshopviewer.spots_left", {
                              count: availableSeats,
                            })}
                          </Text>
                        )}

                        {availableSeats === 0 &&
                          selectedInstance.allow_waitlist &&
                          (waitlistSeats || waitlistSeats === 0) && (
                            <Text fontSize="xs">
                              {t("workshopviewer.waitlist_spots", {
                                count: waitlistSeats,
                              })}
                            </Text>
                          )}

                        {registeredForCurrentInstance && (
                          <Button
                            isLoading={unregister.isLoading}
                            isDisabled={isPastDate}
                            onClick={() => unregister.mutate()}
                          >
                            {t("workshopviewer.leave_workshop")}
                          </Button>
                        )}

                        {!requireAttendanceForReregistration &&
                          !hasAttendedSelectedInstance &&
                          !registeredForCurrentInstance &&
                          !isWaitingForSelectedInstance &&
                          availableSeats > 0 && (
                            <Button
                              isLoading={register.isLoading}
                              isDisabled={isPastDate}
                              onClick={() => register.mutate()}
                            >
                              {t("workshopviewer.join_workshop")}
                            </Button>
                          )}

                        {requireAttendanceForReregistration &&
                          !hasAttendedSelectedInstance &&
                          !registeredForCurrentInstance &&
                          availableSeats > 0 && (
                            <Button
                              isLoading={register.isLoading}
                              isDisabled={isPastDate}
                              onClick={
                                registeredInstances.length > 0
                                  ? onOpen
                                  : () => register.mutate()
                              }
                            >
                              {t("workshopviewer.join_workshop")}
                            </Button>
                          )}

                        {!isRegistered &&
                          !isWaitingForSelectedInstance &&
                          availableSeats === 0 &&
                          selectedInstance.allow_waitlist && (
                            <Button
                              isLoading={joinWaitlist.isLoading}
                              isDisabled={isPastDate}
                              onClick={() => joinWaitlist.mutate()}
                            >
                              {t("workshopviewer.join_waitlist")}
                            </Button>
                          )}

                        {isWaitingForSelectedInstance && (
                          <Button
                            isLoading={leaveWaitlist.isLoading}
                            isDisabled={isPastDate}
                            onClick={() => leaveWaitlist.mutate()}
                          >
                            {t("workshopviewer.leave_waitlist")}
                          </Button>
                        )}

                        {hasAttendedSelectedInstance && (
                          <Button isDisabled>
                            {t("workshopviewer.attended")}
                          </Button>
                        )}

                        {showInstanceList && (
                          <WorkshopInstanceList
                            instances={futureInstances}
                            setSelectedInstance={setSelectedInstance}
                          />
                        )}
                      </>
                    )}
                  </Stack>
                )}
              </>
            </Flex>
          </Flex>
        </Container>
      </Box>

      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent padding={6}>
          <ModalBody>
            <VStack>
              <Text as="h4">Join New Workshop Date & Time</Text>

              <Text>
                Joining this new date and time will remove you from your current
                registered spot.
              </Text>

              <Text>Do you want to proceed?</Text>

              <ButtonGroup paddingTop={4}>
                <Button
                  variant="solid"
                  isLoading={unregisterWithNewRegister.isLoading}
                  onClick={() =>
                    unregisterWithNewRegister
                      .mutateAsync()
                      .then(() => onClose())
                  }
                >
                  Join
                </Button>

                <Button variant="cancel" onClick={onClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
