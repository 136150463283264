import { graphql } from "@/client/services/api/graphql/gql/gql";
// IMPORTANT: Build gql after ANY changes to queries (client OR api)

export const getUserContentStatsQuery: any = graphql(`
  query UserContentStats($id: String!) {
    user(id: $id) {
      contentStats(id: $id) {
        assignmentNotifications
        assignments
        awardedBadges
        contentViews
        learningTracks
        workshopInstances
      }
    }
  }
`);
