import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { User as AuthConfigUser } from "@/client/types/AuthConfig";
import LogoutTimerModal from "../overlay/LogoutTimerModal";
import MainNav from "@/client/components/navigation/MainNav";
import SideNav from "@/client/components/navigation/SideNav";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useIdleTimer } from "react-idle-timer";
import { useLocalStorage } from "usehooks-ts";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import { useQuery } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import v3ApiService from "@/client/services/api/clients/v3ApiClient";

export default function LoggedInLayout() {
  const { config } = useConfigStore();
  const navigate = useNavigate();
  const {
    authConfig,
    setAuthConfig,
    bumpActive,
    isLastActiveNearExpiration,
    logout,
  } = useAuthStore();
  const { partner } = usePartnerStore();
  const location = useLocation();
  const [, setRedirectLocation] = useLocalStorage<string | null>(
    "redirectLocation",
    null
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const showSideNav = useBreakpointValue({ base: false, lg: true });
  const { setToast } = useToastStore();

  const {
    data: userRefreshData,
    isLoading: isUserLoading,
    refetch: refetchUser,
    isFetched: isUserFetched,
  } = useQuery({
    enabled: false,
    queryKey: ["user", authConfig.user?._id],
    queryFn: () => v3ApiService.me(),
  });

  useEffect(() => {
    /*  
      Clear any existing Angular redirect locations when logged in user changes routes
      or routes back into React app from Angular.
    */

    localStorage.removeItem("preRedirect");

    if (!authConfig.authtoken) {
      setRedirectLocation(`${location.pathname}${location.search}`);
    }
  }, [location]);

  useEffect(() => {
    if (!authConfig.company?._id) return;
    if (!authConfig.user?._id) return;
    refetchUser();
  }, [location]);

  useEffect(() => {
    if (!isUserLoading && isUserFetched && userRefreshData) {
      const userData: AuthConfigUser = userRefreshData.user;
      setAuthConfig({
        ...authConfig,
        company: userRefreshData.company,
        user: userData,
      });

      // Verify the user's login is still appropriate for this organization.
      // I didn't extract this into its own function because it's critical this only runs
      // after we're sure we've got the latest user, company and partner data.
      if (userData.suspended) {
        setToast({
          show: true,
          status: "error",
          title:
            "Your user has been suspended, please contact your administrator.",
        });
        logout();
      } else if (userData.partner?._id !== partner?._id) {
        setToast({
          show: true,
          status: "error",
          title:
            "A problem has occurred with your account, please contact your administrator.",
        });
        logout();
      } else if (
        partner?._id &&
        (!partner.active || !userData.partner?.active)
      ) {
        setToast({
          show: true,
          status: "error",
          title:
            "Your Extend Account has been disabled, please contact your administrator.",
        });
        logout();
      }
    }
  }, [isUserFetched, userRefreshData]);

  const beginLoggingOut = () => {
    const scormIframe: HTMLElement | null =
      document.getElementById("playeriframe");
    if (scormIframe) {
      // @ts-ignore
      scormIframe.contentWindow?.timeoutFinishScorm();
    }

    setRedirectLocation(location.pathname);
    logout();
  };

  useEffect(() => {
    if (!authConfig.authtoken) {
      navigate("/login");
    }
  }, [authConfig.authtoken]);

  useEffect(() => {
    const checkAlternateLogoutInterval = setInterval(() => {
      // We can't check for the authtoken in the state because if it's updated outside the application,
      // we won't know about it.  We are intentionally piercing the veil for this localstorage check.
      // TODO Should probably isolate this to a service layer.
      try {
        if (!JSON.parse(localStorage.authStore).state?.authConfig?.authtoken) {
          beginLoggingOut();
        }
      } catch (e) {
        beginLoggingOut();
      }
    }, 420);
    return () => {
      clearInterval(checkAlternateLogoutInterval);
    };
  });

  useEffect(() => {
    // Whenever we refresh this view, check last active time and see if we need to log out.
    if (isLastActiveNearExpiration(0)) {
      beginLoggingOut();
    }
  });

  const onIdle = () => {
    beginLoggingOut();
  };

  const onAction = () => {
    bumpActive();
  };

  const onActive = () => {
    bumpActive();
  };

  const onPrompt = () => {
    // TODO Before we can prompt, we need to check and see if learn-app updated the last active status.
    //   If it's been extended elsewhere, then we need to reset the idle timer here and not show the modal.
    setModalOpen(true);
  };

  const { activate, getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    onActive,
    onPrompt,
    promptBeforeIdle: 120 * 1000,
    timeout: config.learnAppActivityTTL,
    throttle: 500,
    crossTab: true,
  });

  if (authConfig.authtoken) {
    return (
      <>
        <MainNav />

        <Box paddingTop="50px" />

        {showSideNav && <SideNav />}

        <Box paddingLeft={showSideNav ? "50px" : "0px"}>
          <Outlet />
        </Box>

        <LogoutTimerModal
          activate={activate}
          getRemainingTime={getRemainingTime}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </>
    );
  }
  return null;
}
