/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box,
  Collapse,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Tag,
  TagCloseButton,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";

import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import {
  ContentTypes,
  type Content,
} from "@/client/services/api/graphql/gql/graphql";
import type { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "@/client/components/admin/create/shared/layout/CreateSwitchContainer";
import NoSearchResultsListItem from "@/client/components/admin/create/content/lists/NoSearchResultsListItem";
import { useCombobox } from "downshift";
import { useDebounce } from "usehooks-ts";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// TODO: Refactor this combobox for use across all authoring user searches

export default function AuthoringCollaboratorSearch() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<Content & ContentFormValues>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const enableCollaborators = watch("enable_collaborators");
  const selectedCollaborators = watch("privacyCollaborators") || [];
  const contentType = watch("contentType");

  const { isFetching, data } = useQuery({
    enabled: !!debouncedSearchTerm,
    queryKey: ["collaborators", debouncedSearchTerm],
    queryFn: () =>
      AdminUsersService.searchAdminUsers(debouncedSearchTerm, false),
    select: (selectData) => [
      {
        title: t("authoring.settings.collaborators"),
        options: selectData.map((collaborator) => ({
          id: collaborator._id,
          name: collaborator.full_name,
        })),
      },
    ],
  });

  const flattenGroupOptions = (options: { title: string; options: any[] }[]) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        if (!inputValue) {
          setSearchTerm("");
          return;
        }

        setSearchTerm(inputValue);
      },
      items: data ? flattenGroupOptions(data) : [],
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }

        const alreadySelected = selectedCollaborators.some(
          (collaborator) => collaborator.id === selectedItem.id
        );

        if (alreadySelected) {
          setValue(
            "privacyCollaborators",
            selectedCollaborators.filter((i) => i.id !== selectedItem.id)
          );

          return;
        }

        setValue("privacyCollaborators", [
          ...selectedCollaborators,
          selectedItem,
        ]);
      },
      selectedItem: null,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
              highlightedIndex: state.highlightedIndex,
              inputValue: "", // don't add the item string as input value at selection.
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: "", // don't add the item string as input value at selection.
            };
          default:
            return changes;
        }
      },
    });

  return (
    <CreateFormGroup renderBorder={contentType !== ContentTypes.Assessment}>
      <Stack>
        <CreateSwitchContainer
          name="enable_collaborators"
          label={t("authoring.settings.collaborators.enableCollaborators")}
          helpText={t(
            "authoring.settings.collaborators.enableCollaborators.helpText"
          )}
        />

        <Collapse in={enableCollaborators} animateOpacity>
          <InputGroup>
            <Input
              variant="create"
              placeholder={`${t("authoring.searchCollaborators")}`}
              {...getInputProps()}
            />

            <InputRightElement>
              {isFetching && <Spinner size="sm" color="brand.gold.100" />}
            </InputRightElement>
          </InputGroup>

          <List
            marginTop={2}
            flexDirection="column"
            border={data ? "1px solid" : "none"}
            borderColor="brand.gold.100"
            borderRadius="md"
            background="white"
            overflow="scroll"
            paddingX={6}
            zIndex={9999}
            maxH={400}
            width="inherit"
            {...getMenuProps()}
          >
            {data && data[0].options.length === 0 && (
              <NoSearchResultsListItem />
            )}

            {data &&
              data[0].options.length > 0 &&
              data.reduce(
                (results: any, section: any, sectionIndex: any) => {
                  results.sections.push(
                    <Box
                      key={`results_section_${sectionIndex + 1}`}
                      paddingY={4}
                      borderBottom="1px solid"
                      borderColor="brand.gold.100"
                    >
                      {section.options.length > 0 && (
                        <Text variant="createLabel" marginY={2}>
                          {section.title}
                        </Text>
                      )}

                      {section.options.length > 0 &&
                        section.options.map((option: any, optionIndex: any) => {
                          // eslint-disable-next-line no-plusplus, no-param-reassign
                          const resultIndex = results.itemIndex++;

                          const isSelected = selectedCollaborators.some(
                            (collaborator) => collaborator.id === option.id
                          );

                          return (
                            <ListItem
                              key={`option_index_${optionIndex + 1}`}
                              paddingX={6}
                              paddingY={2}
                              _hover={{
                                cursor: "pointer",
                              }}
                              {...getItemProps({
                                item: option,
                                index: resultIndex,
                                "aria-selected": isSelected,
                              })}
                              backgroundColor={
                                highlightedIndex === resultIndex
                                  ? "warmNeutral.0"
                                  : "white"
                              }
                              border={
                                highlightedIndex === resultIndex
                                  ? "1px solid"
                                  : "none"
                              }
                              borderColor="warmNeutral.400"
                              borderRadius="6px"
                            >
                              <Flex
                                alignItems="center"
                                width="full"
                                justifyContent="space-between"
                              >
                                <Avatar
                                  size="sm"
                                  name={option.name}
                                  src={option.image}
                                  opacity={isSelected ? 0.4 : 1}
                                />

                                <Box
                                  flexGrow={1}
                                  textAlign="left"
                                  paddingLeft={4}
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight={500}
                                    color={
                                      isSelected
                                        ? "brand.legibleBlack.50"
                                        : "brand.legibleBlack.100"
                                    }
                                  >
                                    {option.name}
                                  </Text>
                                </Box>

                                <Icon
                                  boxSize={8}
                                  as={isSelected ? CiCircleCheck : CiCirclePlus}
                                  color={
                                    isSelected
                                      ? "brand.gold.100"
                                      : "brand.grey.40"
                                  }
                                />
                              </Flex>
                            </ListItem>
                          );
                        })}
                    </Box>
                  );

                  return results;
                },
                { sections: [], itemIndex: 0 }
              ).sections}
          </List>

          {selectedCollaborators.length > 0 && (
            <HStack flexWrap="wrap">
              {selectedCollaborators.map((collaborator) => (
                <Tag key={collaborator.id} variant="create">
                  {collaborator.name}

                  <TagCloseButton
                    onClick={() =>
                      setValue(
                        "privacyCollaborators",
                        selectedCollaborators.filter(
                          (i) => i.id !== collaborator.id
                        )
                      )
                    }
                  />
                </Tag>
              ))}
            </HStack>
          )}
        </Collapse>
      </Stack>
    </CreateFormGroup>
  );
}
