import { Box, Flex, Stack, Text } from "@chakra-ui/react";

import SolidButton from "@/client/components/shared/buttons/SolidButton";
import { useTranslation } from "react-i18next";

interface ImportedContentSearchCardProps {
  onClick: () => void;
  backgroundImage?: string;
  title?: string;
  author?: string;
  duration?: string;
  description?: string;
  isLoading?: boolean;
}

export default function ImportedContentSearchCard({
  onClick,
  backgroundImage = undefined,
  title = undefined,
  author = undefined,
  duration = undefined,
  description = undefined,
  isLoading = false,
}: ImportedContentSearchCardProps) {
  const { t } = useTranslation();

  return (
    <Flex
      paddingY={10}
      borderBottom="1px solid"
      borderBottomColor="neutral.200"
    >
      <Box
        minWidth={273}
        maxWidth={273}
        height="182px"
        borderRadius="md"
        backgroundImage={backgroundImage}
        marginRight={6}
        flexGrow={1}
      />

      <Stack spacing={4}>
        <Stack spacing={0}>
          {title && (
            <Text fontSize="24px" fontWeight={600}>
              {title}
            </Text>
          )}

          <Text fontSize="14px" color="neutral.600" fontWeight={600}>
            {author || ""} {duration || ""}
          </Text>
        </Stack>

        {description && (
          <Text fontSize="14px" fontWeight={400}>
            {description}
          </Text>
        )}

        <SolidButton
          maxWidth="fit-content"
          isLoading={isLoading}
          onClick={onClick}
        >
          {t("authoring.courses.importCourse.label")}
        </SolidButton>
      </Stack>
    </Flex>
  );
}
