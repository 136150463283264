import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  CircleSlash,
  Close,
  Redo,
  WarningTriangle,
} from "@/client/components/icons/ContinuIcons";
import SkillsList, { Skill } from "./SkillsList";
import { useEffect, useState } from "react";

import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import AdminSecondaryHeader from "@/client/components/admin/layout/AdminSecondaryHeader";
import ConnectInput from "@/client/components/admin/connect/ConnectInput";
import { useConnectStore } from "@/client/services/state/admin/connectStore";
import { useSkillService } from "@/client/services/hooks/admin/connect/useSkillService";
import { useSkillsStore } from "@/client/services/state/admin/create/skillsStore";
import { useTranslation } from "react-i18next";

export default function Skills() {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [blocked, setBlocked] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedEditItem, setSelectedEditItem] = useState<Skill | null>(null);
  const { blockSkills, deleteSkills, unblockSkills } = useSkillService(blocked);
  const { setType, setColumnCopy, clearStore, setShowExternalOption } =
    useConnectStore();
  const {
    items,
    searchItems,
    setSearchTerm,
    sortOrder,
    setSortOrder,
    isSearching,
  } = useSkillsStore();

  const singular = t("admin.connect.skills.singular");

  const columnCopy = {
    singular,
  };

  useEffect(() => {
    clearStore();
    setType("skill");
    setShowExternalOption(false);
    setColumnCopy(columnCopy);
  }, []);

  const handleSelectItem = (id: string) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds?.(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds?.([...(selectedIds ?? []), id]);
    }
  };

  const toggleBlocked = (isBlocked: boolean) => {
    setSelectedEditItem(null);
    setSelectedIds([]);
    setBlocked(isBlocked);
  };

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t("admin.connect.searchPlaceholder", {
          type: t("admin.connect.skills.singular"),
        })}
        showSortSelector
        title={`${t("admin.connect.skills.plural")}`}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isSearching={isSearching}
      />

      <AdminSecondaryHeader>
        <>
          <ButtonGroup size="xs">
            <Button
              variant={!blocked ? "adminBrandPrimary" : "adminGhost"}
              onClick={() => toggleBlocked(false)}
            >
              {t("admin.connect.skills.plural")}
            </Button>

            <Button
              variant={blocked ? "adminBrandPrimary" : "adminGhost"}
              onClick={() => toggleBlocked(true)}
            >
              {t("skillsManager.blocklist")}
            </Button>
          </ButtonGroup>

          {selectedIds.length > 0 && (
            <ButtonGroup fontWeight="medium" size="xs" spacing={4}>
              {!blocked && (
                <Button
                  variant="adminWarning"
                  leftIcon={<CircleSlash />}
                  onClick={() =>
                    blockSkills
                      .mutateAsync({
                        existingSkillIds: selectedIds,
                        newSkillIds: [],
                      })
                      .then(() => setSelectedIds([]))
                  }
                >
                  {t("global.action.block")}
                </Button>
              )}

              {blocked && (
                <Button
                  variant="adminPrimary"
                  leftIcon={<Redo />}
                  onClick={() =>
                    unblockSkills
                      .mutateAsync({ skillIds: selectedIds })
                      .then(() => setSelectedIds([]))
                  }
                >
                  {t("global.action.unblock")}
                </Button>
              )}

              <Button
                variant="adminError"
                leftIcon={<Close />}
                onClick={() =>
                  deleteSkills
                    .mutateAsync({ skillIds: selectedIds })
                    .then(() => setSelectedIds([]))
                }
              >
                {t("global.actions.delete")}
              </Button>
            </ButtonGroup>
          )}
        </>
      </AdminSecondaryHeader>

      <Box
        backgroundColor="white"
        paddingX={6}
        paddingY={2}
        marginX={4}
        marginY={2}
      >
        {!blocked && !isSearching && <ConnectInput level={1} parentId={null} />}

        <SkillsList
          skills={isSearching ? searchItems : items}
          selectedIds={selectedIds}
          handleSelectItem={handleSelectItem}
          selectedEditItem={selectedEditItem}
          setSelectedEditItem={setSelectedEditItem}
          setModalIsOpen={setModalIsOpen}
        />
      </Box>

      <Modal
        size="xl"
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            <WarningTriangle color="brand.warning" marginRight={4} />
            {selectedIds.length > 1
              ? t("admin.connect.skills.deletePlural")
              : t("admin.connect.skills.deleteSingular")}
          </ModalHeader>

          <ModalBody>
            <Text as="p">{t("admin.proceed")}</Text>
          </ModalBody>

          <ModalFooter
            width="full"
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="adminCancel"
              mr={3}
              onClick={() => {
                setSelectedIds([]);
                setModalIsOpen(false);
              }}
            >
              {t("global.actions.cancel")}
            </Button>

            <Button
              variant="adminError"
              onClick={() =>
                deleteSkills
                  .mutateAsync({ skillIds: selectedIds })
                  .then(() => setModalIsOpen(false))
              }
            >
              {t("global.actions.delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
