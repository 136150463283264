import { Descendant, Node } from "slate";

export const serialize = (value: Descendant[]) => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map((n) => `<p>${Node.string(n)}</p>`)
      .join("")
  );
};
