export default {
  "&multiLine": {
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      color: "#fff",
    },
    input: {
      padding: 9,
      border: "1px solid silver",
      outline: "0",
      "&focused": {
        outline: "0",
      },
    },
  },
  suggestions: {
    transform: "translate(-3rem, 1.2rem)",
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      maxHeight: "17em",
      overflowY: "scroll",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#DFDFDF",
      },
    },
  },
};
