import { Button, ButtonGroup } from "@chakra-ui/react";

interface ToggleButtonProps {
  leftLabel: string;
  rightLabel: string;
  checked: boolean;
  setIsChecked: any;
}

export default function ToggleButton({
  leftLabel,
  rightLabel,
  checked,
  setIsChecked,
}: ToggleButtonProps) {
  return (
    <ButtonGroup isAttached size="xs" marginBottom={4}>
      <Button
        variant={checked ? "cancel" : "solid"}
        onClick={() => setIsChecked(!checked)}
      >
        {leftLabel}
      </Button>

      <Button
        variant={checked ? "solid" : "cancel"}
        onClick={() => setIsChecked(!checked)}
      >
        {rightLabel}
      </Button>
    </ButtonGroup>
  );
}
