/* eslint-disable react/jsx-props-no-spreading */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Progress,
  Stack,
  Text,
  css,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useRef, useState } from "react";

import { DefaultTFuncReturn } from "i18next";
import { Editor } from "@tinymce/tinymce-react";
import OpenAiService from "@/client/services/api/OpenAiService";
import type { Editor as TinyMCEEditor } from "tinymce";
import axios from "axios";
import { buildFroalaStyles } from "./buildFroalaStyles";
import { serializeStyles } from "@emotion/serialize";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useLazyLoadStylesheet } from "@/client/services/hooks/useLazyLoad";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import v3ApiService from "@/client/services/api/clients/v3ApiClient";

// ! Available toolbar buttons: https://www.tiny.cloud/docs/tinymce/6/available-toolbar-buttons/

const toCSSString = (styles: any, theme: any) =>
  serializeStyles([css(styles)(theme)]).styles;

interface TinyMceEditorProps {
  placeholder?: string | DefaultTFuncReturn;
  onFocus?: () => void;
  popoverRef?: any;
  formName: string;
  isRequired: boolean;
  label: string | DefaultTFuncReturn;
  useMenu?: boolean;
  toolbarOpts?: string | boolean | undefined;
  customStyles?: string;
  minHeight?: number | undefined;
  inline?: boolean;
}

export default function TinyMceEditor({
  placeholder = "Start typing here...",
  onFocus = undefined,
  popoverRef = null,
  formName,
  isRequired,
  label,
  useMenu = true,
  toolbarOpts = undefined,
  customStyles = "/public/styles/tinymce/tinymce-styles.css",
  minHeight = undefined,
  inline = false,
}: Readonly<TinyMceEditorProps>) {
  useLazyLoadStylesheet(customStyles);
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const enableAi = company.feature_flags.enable_tinymce_ai;
  const { config } = useConfigStore();
  const { setToast } = useToastStore();
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const cancelRef = useRef<any>();
  const theme = useTheme();
  const [editorTheme, setEditorTheme] = useState<string>("");
  const [videoUploadProgress, setVideoUploadProgress] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setTinyMceInFullscreenMode } = useCreateStore();
  const { control, formState } = useFormContext();

  const { errors } = formState;
  const hasError = !!errors[formName];

  const handleImageUpload = async (blobInfo: any) => {
    const file = new File([blobInfo.blob()], blobInfo.name());

    let cloudfrontLink = "";

    await v3ApiService
      .getS3ImagePolicy(blobInfo.blob().type, blobInfo.name())
      .then(async (policy) => {
        const formData = new FormData();

        formData.append("key", policy.Key);
        formData.append("Content-Type", policy.ContentType);
        formData.append("AWSAccessKeyId", policy.AWSAccessKeyId);
        formData.append("success_action_status", "201");
        formData.append("policy", policy.S3Policy);
        formData.append("signature", policy.S3Signature);
        formData.append("file", file);

        await axios
          .post("https://" + policy.Bucket + ".s3.amazonaws.com/", formData)
          .then(() => {
            if (!policy.CloudFrontLink) return;

            cloudfrontLink = policy.CloudFrontLink;
          });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          show: true,
          status: "error",
          title: "Error uploading  image",
        });
      });

    return cloudfrontLink;
  };

  const handleVideoUpload = async (file: File) => {
    let cloudfrontLink = "";

    await v3ApiService
      .getS3VideoPolicy(file.type, file.name)
      .then(async (policy) => {
        const formData = new FormData();

        formData.append("key", policy.Key);
        formData.append("Content-Type", policy.ContentType);
        formData.append("AWSAccessKeyId", policy.AWSAccessKeyId);
        formData.append("success_action_status", "201");
        formData.append("policy", policy.S3Policy);
        formData.append("signature", policy.S3Signature);
        formData.append("file", file);

        await axios
          .post("https://" + policy.Bucket + ".s3.amazonaws.com/", formData, {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              setVideoUploadProgress(percentCompleted);
            },
          })
          .then(() => {
            if (!policy.CloudFrontLink) return;

            cloudfrontLink = policy.CloudFrontLink;
          })
          .catch((err) => {
            console.log("Error uploading video: ", err);
          });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          show: true,
          status: "error",
          title: "Error uploading video",
        });
      });

    return cloudfrontLink;
  };

  useEffect(() => {
    if (theme.colors.brand) {
      setEditorTheme(toCSSString(buildFroalaStyles(theme), theme));
    }

    if (customStyles.includes("track")) {
      setEditorTheme(editorTheme + "::placeholder { color: neutral.100; }");
    }

    if (inline) {
      setEditorTheme(editorTheme + " .mce-edit-focus { outline: 0; }");
    }
  }, [theme]);

  useEffect(() => {
    if (popoverRef) {
      // eslint-disable-next-line no-param-reassign
      popoverRef.current = editorRef.current;
    }
  }, [popoverRef]);

  let plugins = [
    // ! Open Source Plugins
    "advlist",
    "autolink",
    "anchor",
    "autoresize",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "media",
    "table",
    "help",
    "wordcount",
    "emoticons",
    "importcss",
    // ! Premium Plugins
    // 'ai',
    "powerpaste",
    "tinymcespellchecker",
  ];

  let toolbar =
    "undo redo | blocks | fontfamily fontsize | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons";

  if (enableAi) {
    plugins = [...plugins, "ai"];
    toolbar = `${toolbar} | aidialog aishortcuts`;
  }

  const aiShortcuts = [
    {
      title: t("authoring.tinymce.aishortcut.summarize"),
      prompt:
        "Provide the key points and concepts in this content in a succinct summary.",
      selection: true,
    },
    {
      title: t("authoring.tinymce.aishortcut.improve"),
      prompt:
        "Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning.",
      selection: true,
    },
    {
      title: t("authoring.tinymce.aishortcut.simplify"),
      prompt:
        "Rewrite this content with simplified language and reduce the complexity of the writing, so that the content is easier to understand.",
      selection: true,
    },
    {
      title: t("authoring.tinymce.aishortcut.expand"),
      prompt:
        "Expand upon this content with descriptive language and more detailed explanations, to make the writing easier to understand and increase the length of the content.",
      selection: true,
    },
    {
      title: t("authoring.tinymce.aishortcut.trim"),
      prompt:
        "Remove any repetitive, redundant, or non-essential writing in this content without changing the meaning or losing any key information.",
      selection: true,
    },
    {
      title: t("authoring.tinymce.aishortcut.changeTone"),
      subprompts: [
        {
          title: t("authoring.tinymce.aishortcut.tone.professional"),
          prompt:
            "Rewrite this content using polished, formal, and respectful language to convey professional expertise and competence.",
          selection: true,
        },
        {
          title: t("authoring.tinymce.aishortcut.tone.casual"),
          prompt:
            "Rewrite this content with casual, informal language to convey a casual conversation with a real person.",
          selection: true,
        },
        {
          title: t("authoring.tinymce.aishortcut.tone.direct"),
          prompt:
            "Rewrite this content with direct language using only the essential information.",
          selection: true,
        },
        {
          title: t("authoring.tinymce.aishortcut.tone.confident"),
          prompt:
            "Rewrite this content using compelling, optimistic language to convey confidence in the writing.",
          selection: true,
        },
        {
          title: t("authoring.tinymce.aishortcut.tone.friendly"),
          prompt:
            "Rewrite this content using friendly, comforting language, to convey understanding and empathy.",
          selection: true,
        },
      ],
    },
  ];

  if (editorTheme === "") return <Box />;

  return (
    <Box>
      <FormControl isInvalid={hasError} _focusVisible={{ border: "none" }}>
        <Stack spacing={2}>
          {label && (
            <Flex justifyContent="flex-start">
              <Text variant="createLabel">{label}</Text>
            </Flex>
          )}

          <Box
            border={hasError ? "1px solid" : "none"}
            borderColor="red"
            borderRadius="xl"
          >
            <Controller
              control={control}
              name={formName}
              rules={{
                required: {
                  value: isRequired || false,
                  message: t("authoring.fieldRequired"),
                },
              }}
              render={({ field: { onChange, ...field } }) => (
                <Editor
                  {...field}
                  apiKey={config.tinyMce.apiKey}
                  // @ts-ignore
                  // eslint-disable-next-line no-return-assign
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  onFocus={onFocus}
                  onEditorChange={onChange}
                  init={{
                    inline,
                    setup: (editor) => {
                      editor.on("FullscreenStateChanged", (e) => {
                        console.log("FullscreenStateChanged event", e);

                        setTinyMceInFullscreenMode(e.state);
                      });
                    },
                    help_tabs: ["shortcuts", "keyboardnav", "versions"],
                    statusbar: false,
                    editor_css: "/public/styles/tinymce/tinymce-styles.css",
                    toolbar_sticky: !toolbarOpts,
                    toolbar_sticky_offset: 50,
                    toolbar_mode: "sliding",
                    min_height:
                      minHeight ||
                      (window.visualViewport
                        ? window.visualViewport.height - 500
                        : 300),
                    placeholder: placeholder as string,
                    body_class: "tinymce-editor fr-view",
                    font_size_formats:
                      "8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
                    powerpaste_googledocs_import: "merge",
                    powerpaste_word_import: "merge",
                    powerpaste_html_import: "merge",
                    powerpaste_allow_local_images: true,
                    content_style: editorTheme,
                    images_upload_handler: (blobInfo: any) =>
                      handleImageUpload(blobInfo),
                    file_picker_types: "media",
                    file_picker_callback: async (callback) => {
                      const input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "video/*");
                      input.onchange = async (event: any) => {
                        const file = event?.target?.files[0];

                        onOpen();

                        const cloudfrontLink = await handleVideoUpload(file);

                        onClose();

                        callback(cloudfrontLink);
                      };

                      input.click();
                    },

                    plugins,
                    menubar: useMenu
                      ? "file edit view insert format tools table help"
                      : false,
                    toolbar: toolbarOpts ?? toolbar,
                    ai_request: enableAi ? OpenAiService.sendAiRequest : null,
                    ai_shortcuts: enableAi ? aiShortcuts : [],
                    font_family_formats:
                      "Inter=Inter,Helvetica,Arial,sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                  }}
                />
              )}
            />
          </Box>

          <Flex justifyContent="flex-end">
            {hasError && errors[formName]?.message && (
              <FormErrorMessage>
                {errors[formName]?.message as string}
              </FormErrorMessage>
            )}
          </Flex>
        </Stack>
      </FormControl>

      <AlertDialog
        closeOnOverlayClick={false}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Uploading Video
            </AlertDialogHeader>

            <AlertDialogBody>
              <Progress variant="create" value={videoUploadProgress} />
              <Text>{videoUploadProgress}%</Text>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
