import { Bar } from "@nivo/bar";
import AutoSizer from "react-virtualized-auto-sizer";
import { ChartWrapper } from "./ChartWrapper";
// import { useAuthStore } from '@/client/services/state/authStore';
import { Range } from "react-calendar/dist/cjs/shared/types";

type BarChartProps = {
  data: any;
  labels: { [key: string]: string };
  colSpan: number;
  rowSpan: number;
  dateRange: string | Date | Range<any>;
  setDateRange: any;
  title: string;
  tooltip: string;
};

export function BarChart({
  data,
  labels,
  colSpan,
  rowSpan,
  dateRange,
  setDateRange,
  title,
  tooltip,
}: BarChartProps) {
  // TODO: Fix locale
  // const { authConfig } = useAuthStore();
  // const { company } = authConfig;

  return (
    <ChartWrapper
      showDatePicker
      title={title}
      tooltip={tooltip}
      dateRange={dateRange}
      colSpan={colSpan}
      rowSpan={rowSpan}
      setDateRange={setDateRange}
      dateLocale={{ format: "en_US" }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Bar
            data={data}
            width={width}
            height={height}
            layout="horizontal"
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
            ]}
            enableGridX
            enableGridY={false}
            keys={Object.keys(labels)}
            indexBy="time_range"
            margin={{ left: 70, bottom: 20, top: 40, right: 30 }}
            padding={0.5}
            innerPadding={1}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={["#FEA58A", "#BEA7EC", "#4AD3DC", "#F8C98D", "#EA91AD"]}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Days Since Assigned",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Completion Statuses",
              legendPosition: "middle",
              legendOffset: -60,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            legendLabel={(datum) => `${labels[datum.id]}`}
            tooltipLabel={(datum) =>
              `${labels[datum.id]} - ${datum.indexValue}`
            }
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 70,
                itemsSpacing: 0,
                itemWidth: 110,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                toggleSerie: true,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            theme={{
              axis: {
                domain: {
                  // line: { stroke: '#77777777', strokeWidth: 0.5 }
                },
                legend: {
                  text: { fill: "#999", fontSize: 10, fontWeight: "bold" },
                },
                ticks: {
                  line: { stroke: "#77777722", strokeWidth: 0.5 },
                  text: { fill: "#999", fontSize: 10 },
                },
              },
              grid: {
                line: {
                  stroke: "#77777722",
                  strokeWidth: 0.5,
                },
              },
              labels: {
                text: { fontSize: 10, fontWeight: "bold" },
              },
              legends: {
                text: { fontSize: 10, fontWeight: "bold" },
              },
            }}
          />
        )}
      </AutoSizer>
    </ChartWrapper>
  );
}
