/* eslint-disable react/no-unstable-nested-components */

import { Badge, Box, Center, Flex } from "@chakra-ui/react";
import {
  DateFormatGroupKey,
  dateFormatTenant,
} from "@/client/utils/dateFormat";
import { useState, useMemo } from "react";

import { Form, RegistrationFormType } from "@/client/types/RegistrationForms";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import TableActionsMenu from "@/client/components/admin/menus/TableActionsMenu";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import Loading from "@/client/components/media/Loading";
import RegistrationFormsService from "@/client/services/api/RegistrationFormsService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { WIDTH_ACTION_CELL } from "@/client/components/admin/tables/cells/CellSizes";

type ListProps = {
  type: RegistrationFormType;
  editPath: string;
  pageUrl: string;
};

export default function RegistrationFormsList({
  type,
  editPath,
  pageUrl,
}: ListProps) {
  // const [selected, setSelected] = useState<Selected[]>([]);
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { t } = useTranslation();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const getUrlFromCode = (code: string) =>
    `${company.domain}${pageUrl}/${code}`;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["registration-forms", fetchDataOptions, type],
    queryFn: () =>
      RegistrationFormsService.getAllForms(fetchDataOptions, { type }),
  });

  // const handleSelect = (value: string, activated: boolean) => {
  //   const selectedCopy = selected.slice();
  //   const foundSelectedForm = selectedCopy.find((form: Selected) => form._id === value);
  //   if (foundSelectedForm) {
  //     selectedCopy.splice(selectedCopy.indexOf(foundSelectedForm), 1);
  //     setSelected(selectedCopy);
  //     return;
  //   }
  //   selectedCopy.push({ _id: value, activated });
  //   setSelected(selectedCopy);
  // };

  // const handleSelectAll = (checked: boolean) => {
  //   if (checked) {
  //     const allSelected = data.map((form: Form) => ({ _id: form._id, activated: form.active }));
  //     setSelected(allSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const allChecked = selected?.length === data?.length;

  const handleActivation = async (id: string) => {
    await RegistrationFormsService.updateForm(id, { active: true });
    refetch();
  };

  // const handleActivateMultiple = async (ids: string[]) => {
  //   const updates: any[] = [];
  //   for (let i = 0; i < ids.length; i += 1) {
  //     updates.push(RegistrationFormsService.updateForm(ids[i], { active: true }));
  //   }
  //   await Promise.allSettled(updates);
  //   refetch();
  //   setSelected([]);
  // };

  const handleDeactivation = async (id: string) => {
    await RegistrationFormsService.updateForm(id, { active: false });
    refetch();
  };

  const columnHelper = createColumnHelper<Form>();
  const regFormColumns = useMemo<ColumnDefBase<Form, any>[]>(
    () => [
      columnHelper.accessor("title", {
        cell: (info) => (
          <Flex alignItems="center" marginLeft={9}>
            {info.getValue()}
          </Flex>
        ),
        header: () => (
          <Box as="span" marginLeft={9}>
            {t("registrationForms.label.formName")}
          </Box>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("createdAt", {
        cell: (info) => (
          <span>
            {info.getValue()
              ? dateFormatTenant(
                  new Date(info.getValue()),
                  company.date_display as DateFormatGroupKey,
                  "base"
                )
              : "-----"}
          </span>
        ),
        header: () => <span>{t("registrationForms.label.createdDate")}</span>,
        footer: (info) => info.column.id,
        size: 2,
      }),
      columnHelper.accessor("active", {
        cell: (info) => (
          <Badge
            borderRadius="5px"
            variant={info.getValue() ? "active" : "inactive"}
            width="70%"
            padding={1}
            textAlign="center"
          >
            {info.getValue() ? "Active" : "Inactive"}
          </Badge>
        ),
        header: () => <span>{t("global.forms.labels_status")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("code", {
        cell: (info) => <span>{getUrlFromCode(info.getValue())}</span>,
        header: () => <span>{t("registrationForms.label.url")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => (
          <TableActionsMenu
            key={`actions_${info.row.original._id}`}
            onEdit={() => navigate(`${editPath}${info.row.original._id}`)}
            enabledStatus={info.row.original.active}
            onEnableToggle={() => {
              if (info.row.original.active) {
                handleDeactivation(info.row.original._id);
              }
              if (!info.row.original.active) {
                handleActivation(info.row.original._id);
              }
            }}
          />
        ),
        size: WIDTH_ACTION_CELL,
      }),
    ],
    []
  );

  // const handleDeactivateMultiple = async (ids: string[]) => {
  //   const updates: any[] = [];
  //   for (let i = 0; i < ids.length; i += 1) {
  //     updates.push(RegistrationFormsService.updateForm(ids[i], { active: false }));
  //   }
  //   await Promise.allSettled(updates);
  //   refetch();
  //   setSelected([]);
  // };

  // const allowActivate = () => selected.some((form: Selected) => !form.activated);
  // const allowDeactivate = () => selected.some((form: Selected) => form.activated);

  if (isLoading) return <Loading />;

  return (
    <>
      {!data?.rows.length && (
        <Center marginY={6}>{t("registrationForms.list.noForms")}</Center>
      )}
      {data?.rows.length && (
        <ManuallyPaginatedTable
          columns={regFormColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </>
  );
}
