import { Box, Flex, Link, Stack, Text } from "@chakra-ui/react";
import {
  CalendarWeek,
  Clock,
  Globe,
  Star,
} from "@/client/components/icons/ContinuIcons";
import { Workshop, WorkshopInstance } from "@/client/types/content/Workshop";

import CalendarLink from "./CalendarLink";
import ContentRatingGroup from "@/client/components/data-display/ratings/ContentRatingGroup";
import { format } from "date-fns";
import { useAuthStore } from "@/client/services/state/authStore";
import { useTranslation } from "react-i18next";
import { urlProtocol } from "../../../utils/urlProtocol";

interface WorkshopInstanceDetailsProps {
  workshop: Workshop;
  selectedInstance: WorkshopInstance;
  isRegistered: boolean;
  hasAttended: boolean;
}

export default function WorkshopInstanceDetails({
  workshop,
  selectedInstance,
  isRegistered,
  hasAttended,
}: WorkshopInstanceDetailsProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  return (
    <Stack spacing={14}>
      <Box>
        <Flex alignItems="center" marginBottom={2}>
          <Clock marginRight={2} color="brand.primary" />

          <Text fontSize="sm">{t("workshopviewer.date_and_time")}</Text>
        </Flex>

        {selectedInstance && (
          <Text fontSize="xs" marginLeft={6}>
            {format(new Date(selectedInstance.start_date), "MMMM do, yyyy ")}

            {format(new Date(selectedInstance.start_date), "h:mm a")}

            {" - "}

            {format(new Date(selectedInstance.end_date), "h:mm a")}
          </Text>
        )}
      </Box>

      {selectedInstance && !hasAttended && (
        <>
          <Box>
            <Flex alignItems="center" marginBottom={2}>
              <Globe marginRight={2} color="brand.primary" />

              <Text fontSize="sm">
                {selectedInstance.online
                  ? t("workshopviewer.attend_online")
                  : t("workshopviewer.attend_in_person")}
              </Text>
            </Flex>

            {!selectedInstance.online ? (
              <Stack fontSize="xs" marginLeft={6} spacing={0}>
                <Text>{selectedInstance.location.venue}</Text>
                <Text>{selectedInstance.location.address_1}</Text>
                <Text>{selectedInstance.location.address_2}</Text>
                <Text>{selectedInstance.location.city_state}</Text>
                <Text>{selectedInstance.location.country}</Text>
              </Stack>
            ) : !isRegistered ? (
              <Text fontSize="xs" marginLeft={6}>
                {t("view.workshop.urlWillBeProvided")}
              </Text>
            ) : (
              <Link
                isExternal
                href={urlProtocol(selectedInstance.online_location)}
                fontSize="xs"
                marginLeft={6}
              >
                {selectedInstance.online_location_label}
              </Link>
            )}
          </Box>

          {company.feature_flags.show_calendar_links && (
            <Box>
              <Flex alignItems="center" marginBottom={2}>
                <CalendarWeek marginRight={2} color="brand.primary" />

                <Text fontSize="sm">Add to Calendar</Text>
              </Flex>

              <Stack direction="row" spacing={4} marginLeft={6}>
                <CalendarLink
                  workshop={workshop}
                  workshopInstance={selectedInstance}
                  type="iCal"
                />
                <CalendarLink
                  workshop={workshop}
                  workshopInstance={selectedInstance}
                  type="outlook"
                />
              </Stack>
            </Box>
          )}
        </>
      )}

      {hasAttended &&
        workshop.rating_configuration &&
        workshop.rating_configuration.allow_rating && (
          <Box>
            <Flex alignItems="center" marginBottom={2}>
              <Star marginRight={2} color="brand.primary" />

              <Text fontSize="sm">Average Rating</Text>
            </Flex>

            <Box marginLeft={6}>
              <ContentRatingGroup
                averageRating={workshop.average_rating}
                ratingConfiguration={workshop.rating_configuration}
              />
            </Box>
          </Box>
        )}
    </Stack>
  );
}
