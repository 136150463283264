import { AngleLeft, AngleRight } from "@/client/components/icons/ContinuIcons";
import {
  Box,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Recording, WorkshopInstance } from "@/client/types/content/Workshop";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import ContentHeader from "../../components/layout/ContentHeader";
import ContentRatingInput from "@/client/components/data-display/ratings/ContentRatingInput";
import ContentService from "@/client/services/api/ContentService";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import Loading from "@/client/components/media/Loading";
import NotAuthorized from "@/client/components/data-display/content/NotAuthorized";
import PopupSurveyLayout from "@/client/components/overlay/PopupSurvey";
import RelatedWorkshopsList from "@/client/components/content/workshop/RelatedWorkshopsList";
import VideoPlayer from "../../components/media/video/VideoPlayer";
import ViewActions from "@/client/components/overlay/ViewActions";
import WorkshopHeader from "../../components/content/workshop/WorkshopHeader";
import WorkshopInstanceDetails from "@/client/components/content/workshop/WorkshopInstanceDetails";
import WorkshopInstanceHostDetails from "@/client/components/content/workshop/WorkshopInstanceHostDetails";
import WorkshopService from "@/client/services/api/content/workshops/WorkshopService";
import WorkshopTabs from "../../components/content/workshop/WorkshopTabs";
import { isBefore } from "date-fns";
import { parseRecordings } from "@/client/utils/workshops/handleRecordings";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCheckContentAuth } from "@/client/services/hooks/content/useCheckContentAuth";
import { useCheckSurveyCompletion } from "@/client/services/hooks/useCheckSurveyCompletion";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { useGetAvailableSeats } from "@/client/services/hooks/content/workshop/useGetAvailableSeats";
import { useGetWorkshop } from "@/client/services/hooks/content/workshop/useGetWorkshop";
import { useSessionTracking } from "@/client/services/hooks/useSessionTracking";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface WorkshopProps {
  inJourney?: boolean;
  workshopId?: string;
}

export default function Workshop({ inJourney, workshopId }: WorkshopProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { sendSessionTime } = useSessionTracking(id, "workshop");
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { allowView } = useCheckContentAuth();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();

  const { setToast } = useToastStore();
  const queryClient = useQueryClient();
  const [selectedInstance, setSelectedInstance] = useState<
    WorkshopInstance | undefined
  >(undefined);
  const [availableSeats, setAvailableSeats] = useState<number>(0);
  const [waitlistSeats, setWaitlistSeats] = useState<number>(0);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [selectedRecordingIndex, setSelectedRecordingIndex] =
    useState<number>(0);
  const [ratingModalOpen, setRatingModalOpen] = useState<boolean>(false);
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [previouslyRegisteredInstanceId, setPreviousltRegisteredInstanceId] =
    useState("");

  const contentId = workshopId || id;

  const { isLoading, isError, data, refetch } = useGetWorkshop(
    user._id,
    contentId
  );

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data.workshop);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  const { data: seats, refetch: refetchSeats } = useGetAvailableSeats(
    contentId,
    selectedInstance
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return () => {
      sendSessionTime();
    };
  }, []);

  useEffect(() => {
    if (isAllowed) {
      AnalyzeService.postViews(contentId, "workshop");
      ContentService.postViews(contentId, "workshop");
    }
  }, [isAllowed]);

  useDocumentTitle(data ? data.workshop.title : "Workshop", true);

  const throwWorkshopError = (message: string) => {
    setToast({
      show: true,
      status: "error",
      title: message,
    });
  };

  const register = useMutation({
    mutationFn: () =>
      WorkshopService.register(user._id, contentId, selectedInstance?._id),
    onSuccess: () => {
      refetch();
      refetchSeats();
      setToast({
        show: true,
        status: "success",
        title: "Registered for workshop",
      });
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || t("workshopviewer.unable_to_join");
      throwWorkshopError(errorMessage);
    },
  });

  const unregisterFunc = async () => {
    const selectedRegisteredInstanceId = data?.registeredInstances.find(
      (instance) => instance.workshop_instance === selectedInstance?._id
    )?._id;

    await WorkshopService.unregister(user._id, selectedRegisteredInstanceId);
  };

  const unregister = useMutation({
    mutationFn: () => unregisterFunc(),
    onSuccess: () => {
      refetch();
      refetchSeats();
      setToast({
        show: true,
        status: "success",
        title: t("workshopviewer.you_have_left"),
      });
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || "Error unregistering from workshop";
      throwWorkshopError(errorMessage);
    },
  });

  const unregisterWithNewRegister = useMutation({
    mutationFn: () =>
      WorkshopService.unregisterWithNewRegister(
        user._id,
        contentId,
        previouslyRegisteredInstanceId,
        selectedInstance?._id
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["workshop", contentId]);
      queryClient.invalidateQueries([
        "available-seats",
        contentId,
        selectedInstance?._id,
      ]);
      refetchSeats();
      setToast({
        show: true,
        status: "success",
        title: "Registered for workshop",
      });
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || t("workshopviewer.unable_to_join");
      throwWorkshopError(errorMessage);
    },
  });

  const joinWaitlist = useMutation({
    mutationFn: () =>
      WorkshopService.joinWaitlist(user._id, contentId, selectedInstance?._id),
    onSuccess: () => {
      queryClient.invalidateQueries(["workshop", contentId]);
      queryClient.invalidateQueries([
        "available-seats",
        contentId,
        selectedInstance?._id,
      ]);
      refetchSeats();
      setToast({
        show: true,
        status: "success",
        title: t("workshopviewer.you_have_joined"),
      });
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message ||
        t("workshopviewer.unable_to_join_waitlist");
      throwWorkshopError(errorMessage);
    },
  });

  const leaveWaitlistFunc = async () => {
    const selectedWaitingInstanceId = data?.waitingInstances.find(
      (instance) => instance.workshop_instance === selectedInstance?._id
    )?._id;

    WorkshopService.leaveWaitlist(user._id, selectedWaitingInstanceId);
  };

  const leaveWaitlist = useMutation({
    mutationFn: () => leaveWaitlistFunc(),
    onSuccess: () => {
      queryClient.invalidateQueries(["workshop", contentId]);
      queryClient.invalidateQueries([
        "available-seats",
        contentId,
        selectedInstance?._id,
      ]);
      refetchSeats();
      setToast({
        show: true,
        status: "success",
        title: t("workshopviewer.you_have_left"),
      });
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || "Unable to leave waitlist";
      throwWorkshopError(errorMessage);
    },
  });

  useEffect(() => {
    const linkedInstanceId = searchParams.get("workshopInstanceId");

    if (data) {
      if (data.triggerRating && data.workshop.rating_configuration) {
        if (data.workshop.rating_configuration.allow_rating) {
          setRatingModalOpen(true);
        } else {
          setRatingModalOpen(false);
        }
      }

      checkShowPopupSurvey(data.workshop, data.hasAttended, inJourney);

      if (linkedInstanceId) {
        setSelectedInstance(
          data.workshopInstances.find(
            (instance) => instance._id === linkedInstanceId
          )
        );

        return;
      }

      if (data.attendedInstances.length > 0) {
        const attendedInstance = data.attendedInstances.reverse()[0];

        setSelectedInstance(
          data.workshopInstances.find(
            (instance) => instance._id === attendedInstance.workshop_instance
          )
        );

        return;
      }

      if (!data.workshop.allow_reregistration) {
        if (data.waitingInstances.length > 0) {
          const waitingInstance = data.waitingInstances.reverse()[0];

          setSelectedInstance(
            data.workshopInstances.find(
              (instance) => instance._id === waitingInstance.workshop_instance
            )
          );

          return;
        }
      }

      if (data.registeredInstances.length > 0) {
        const registeredInstance = data.registeredInstances.reverse()[0];
        const fullRegisteredInstance = data.workshopInstances.find(
          (instance) => instance._id === registeredInstance.workshop_instance
        );

        let before = false;

        if (fullRegisteredInstance) {
          const today = new Date(Date.now() - 86400000);
          const instanceDate = new Date(fullRegisteredInstance.start_date);

          before = isBefore(instanceDate, today);

          if (before) {
            if (
              data.workshop.allow_reregistration &&
              data.workshop.require_attendance_for_reregistration
            ) {
              setPreviousltRegisteredInstanceId(registeredInstance._id);
            }

            setSelectedInstance(data.futureInstances[0]);
          } else {
            setSelectedInstance(fullRegisteredInstance);
          }
        }
      } else {
        setSelectedInstance(data.futureInstances[0]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (selectedInstance) {
      const hasAttended =
        data?.attendedInstances.findIndex(
          (instance) => instance.workshop_instance === selectedInstance._id
        ) !== -1;

      if (
        hasAttended &&
        selectedInstance.recordings &&
        selectedInstance.recordings.length > 0
      ) {
        setRecordings(parseRecordings(selectedInstance.recordings));
      }
    }
  }, [selectedInstance]);

  useEffect(() => {
    if (seats) {
      setAvailableSeats(seats.availableSeats);
      setWaitlistSeats(seats.availableWaitlistSeats);
    }
  }, [seats]);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get workshop" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <Box marginTop={50}>
      {isAllowed && (
        <>
          {!inJourney && (
            <ContentHeader
              contentType="workshop"
              contentTitle={data.workshop.title}
              contentId={data.workshop._id}
              withBorder
              allowComments={data.workshop.allow_comments}
              goToComments={() => setActiveTabIndex(1)}
            />
          )}

          <WorkshopHeader
            workshop={data.workshop}
            futureInstances={data.futureInstances}
            selectedInstance={selectedInstance}
            setSelectedInstance={setSelectedInstance}
            setVideoModalOpen={setVideoModalOpen}
            isRegistered={data.isRegistered}
            registeredInstances={data.registeredInstances}
            waitingInstances={data.waitingInstances}
            register={register}
            unregister={unregister}
            unregisterWithNewRegister={unregisterWithNewRegister}
            joinWaitlist={joinWaitlist}
            leaveWaitlist={leaveWaitlist}
            availableSeats={availableSeats}
            waitlistSeats={waitlistSeats}
            hasRecordings={!!recordings.length}
            allowReregistration={data.workshop.allow_reregistration}
            requireAttendanceForReregistration={
              data.workshop.require_attendance_for_reregistration
            }
            attendedInstances={data.attendedInstances}
          />

          <Container
            maxW="container.lg"
            zIndex="3"
            position="relative"
            marginBottom={24}
          >
            <Flex direction={{ base: "column", md: "row" }} padding={6}>
              <Flex
                flexBasis={{ base: "100%", md: "67%" }}
                paddingRight={{ base: 0, md: 14 }}
              >
                <WorkshopTabs
                  workshop={data.workshop}
                  activeTabIndex={activeTabIndex}
                  setActiveTabIndex={setActiveTabIndex}
                />
              </Flex>

              <Flex>
                {selectedInstance && (
                  <WorkshopInstanceDetails
                    workshop={data.workshop}
                    selectedInstance={selectedInstance}
                    isRegistered={data.isRegistered}
                    hasAttended={data.hasAttended}
                  />
                )}
              </Flex>
            </Flex>

            {selectedInstance && (
              <WorkshopInstanceHostDetails instance={selectedInstance} />
            )}

            {data.relatedWorkshops && data.relatedWorkshops.length > 0 && (
              <RelatedWorkshopsList relatedWorkshops={data.relatedWorkshops} />
            )}
          </Container>

          <Modal
            isOpen={videoModalOpen}
            isCentered
            size="3xl"
            onClose={() => setVideoModalOpen(false)}
          >
            <ModalOverlay />

            <ModalContent>
              <ModalCloseButton />

              <ModalBody display="flex">
                <AngleLeft
                  width="10%"
                  marginTop="36"
                  _hover={{ cursor: "pointer" }}
                  onClick={() =>
                    selectedRecordingIndex > 0 &&
                    setSelectedRecordingIndex(selectedRecordingIndex - 1)
                  }
                />

                <VideoPlayer
                  url={recordings[selectedRecordingIndex]?.link || ""}
                  setRenderRecommendations={() => {}}
                />

                <AngleRight
                  width="10%"
                  marginTop="36"
                  _hover={{ cursor: "pointer" }}
                  onClick={() =>
                    selectedRecordingIndex < recordings.length - 1 &&
                    setSelectedRecordingIndex(selectedRecordingIndex + 1)
                  }
                />
              </ModalBody>
            </ModalContent>
          </Modal>

          {data.workshop.rating_configuration &&
            data.workshop.rating_configuration.allow_rating && (
              <Modal
                isOpen={ratingModalOpen}
                isCentered
                size="3xl"
                onClose={() => setRatingModalOpen(false)}
              >
                <ModalOverlay />

                <ModalContent>
                  <ModalCloseButton />

                  <ModalBody>
                    <ContentRatingInput
                      contentId={data.workshop._id}
                      contentType="Workshop"
                      ratingConfiguration={data.workshop.rating_configuration}
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}

          <ViewActions
            buttons={["gototop"]}
            bottomOffset={showPopup ? 10 : 0}
          />

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.workshop.surveys[0].survey_id}
              contentId={data.workshop._id}
              contentType="workshop"
            />
          )}
        </>
      )}
    </Box>
  );
}

Workshop.defaultProps = {
  inJourney: false,
  workshopId: "",
};
