import { Box, useToken } from "@chakra-ui/react";

import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useDrop } from "react-dnd";

export default function DraggableFieldAddLanding() {
  const [brandHighlight] = useToken("colors", ["brand.highlight"]);

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: "FIELD",
    drop: () => ({ name: "FieldAdd" }),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <Box
      ref={drop}
      marginRight={4}
      marginBottom={0}
      height={14}
      backgroundColor={hexToRGBA(brandHighlight, 0.2)}
      borderRadius="10px"
      borderColor={hexToRGBA(brandHighlight, 0.8)}
      borderStyle="dashed"
      borderWidth="3px"
    >
      <Box
        flex="1"
        textAlign="center"
        color="gray.700"
        fontWeight="500"
        marginTop={3}
      >
        Drag and Drop a field into the form
      </Box>
    </Box>
  );
}
