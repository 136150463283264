import { graphql } from "../gql/gql";
// IMPORTANT: Build gql after ANY changes to queries (client OR api)

export const listPartners: any = graphql(`
  query PartnerContainer(
    $includeTotalCount: Boolean!
    $name: StringQueryOperatorArgs
    $ordering: [OrderBy!]
    $page: Int!
    $pageSize: Int!
  ) {
    partners(
      includeTotalCount: $includeTotalCount
      name: $name
      ordering: $ordering
      page: $page
      pageSize: $pageSize
    ) {
      edges {
        node {
          active
          branding {
            backgroundImageUrls
            colorPrimary
            logoUrl
          }
          creator
          id
          name
          seats
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
    }
  }
`);
