import CategoriesService from "../../CategoriesService";
import { Category } from "@/client/types/Category";
import { Person } from "@/client/types/Person";
import { learnApiClient } from "../../clients/learnApiClient";
import { saveAs } from "file-saver";

export interface GroupData {
  departments: string[];
  grades: string[];
  locations: string[];
  org_levels: string[];
  teams: string[];
  name: string;
  description: string;
  user: string;
  external_id: string;
}

export interface Group {
  _id: string;
  user: User;
  name: string;
  description: string;
  managers: number;
  users: number;
}

interface User {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  full_name: string;
}

interface UserCount {
  group: string;
  users: number;
  managers: number;
}

export interface FullGroupUser extends User {
  image: string;
  linked_locations: Category[];
  linked_departments: Category[];
  linked_teams: string[];
  linked_org_level: string;
  linked_grade: string;
  groups: string[];
}

const setSortOrder = (sortOrder: string) => {
  let sort;

  switch (sortOrder) {
    case "newest":
      sort = `createdAt,-1`;
      break;
    case "oldest":
      sort = `createdAt,1`;
      break;
    case "asc":
      sort = `name,1`;
      break;
    case "desc":
      sort = `name,-1`;
      break;
    default:
      sort = `createdAt,-1`;
  }

  return sort;
};

const getGroupsCount = async (fields: string) => {
  const response = await learnApiClient.get(`groups/count?fields=${fields}`);

  return response.data;
};

const getGroupUserCounts = async (groupIds: string[]) => {
  const response = await learnApiClient.get<UserCount[]>(
    `groups/user-counts?groups=${groupIds}`
  );

  return response.data;
};

const getGroup = async (
  groupId: string | undefined
): Promise<{ group: GroupData; categories: Category[] }> => {
  const groupResponse = await learnApiClient.get(
    `groups/${groupId}?fields=company,user,name,external_id,description,departments,locations,teams,org_levels,grades`
  );

  const g = groupResponse.data;

  const groupCategoriesResponse = await CategoriesService.getCategoriesById([
    ...g.departments,
    ...g.locations,
    ...g.teams,
    ...g.org_levels,
    ...g.grades,
  ]);

  return {
    group: g,
    categories: groupCategoriesResponse,
  };
};

const getGroups = async (
  options: { pageIndex: number; pageSize: number },
  sortOrder: "asc" | "desc" | "oldest" | "newest",
  isSearching?: boolean,
  searchTerm?: string | null
) => {
  const fields =
    isSearching && searchTerm
      ? `name,description,user,createdAt&name=$regex,2,${searchTerm},i&page=${
          options.pageIndex + 1
        }&per_page=${
          options.pageSize
        }0&user-populate=first_name,last_name,full_name,email`
      : `name,description,user,createdAt&page=${
          options.pageIndex + 1
        }&per_page=${
          options.pageSize
        }&user-populate=first_name,last_name,full_name,email`;

  const groupsResponse = await learnApiClient.get<Group[]>(
    `groups?fields=${fields}&sort=${setSortOrder(sortOrder)}`
  );

  const userCountResponse = await getGroupUserCounts(
    groupsResponse.data.map((group) => group._id)
  );

  const groups = groupsResponse.data.map((group) => ({
    ...group,
    users:
      userCountResponse.find((count) => count.group === group._id)?.users || 0,
    managers:
      userCountResponse.find((count) => count.group === group._id)?.managers ||
      0,
  }));

  const totalGroupCount = await getGroupsCount(fields);

  return {
    rows: groups,
    pageCount: Math.ceil(totalGroupCount.count / options.pageSize),
  };
};

const getAllGroups = async () => {
  const allGroups = await learnApiClient.get<Group[]>(
    "groups?fields=name,description,user,createdAt&user-populate=first_name,last_name,full_name,email"
  );
  return allGroups.data;
};

const search = async (
  searchTerm: string,
  options: { pageIndex: number; pageSize: number }
) => {
  const groupsResponse = await learnApiClient.get<Group[]>(
    `groups?fields=name,description,user,createdAt&name=$regex,2,${searchTerm},i&page=${options.pageIndex}&per_page=${options.pageSize}&user-populate=first_name,last_name,full_name,email`
  );

  const userCountResponse = await getGroupUserCounts(
    groupsResponse.data.map((group) => group._id)
  );

  const groups = groupsResponse.data.map((group) => ({
    ...group,
    users:
      userCountResponse.find((count) => count.group === group._id)?.users || 0,
    managers:
      userCountResponse.find((count) => count.group === group._id)?.managers ||
      0,
  }));

  return {
    rows: groups,
    pageCount: 10, // TODO: Get this from the API
  };
};

const simpleSearch = async (
  searchTerm: string,
  options: { pageIndex: number; pageSize: number }
) => {
  const response = await learnApiClient.get<Group[]>(
    `groups?name=$regex,2,${searchTerm},i&page=${options.pageIndex}&per_page=${options.pageSize}`
  );

  return response.data;
};

const saveGroup = async (groupId: string | undefined, groupData: GroupData) => {
  const url = groupId ? `groups/${groupId}` : "groups";

  const response = await learnApiClient.post<Group>(url, groupData);

  return response.data;
};

const getGroupUsers = async (
  options: { pageIndex: number; pageSize: number },
  groupId: string | undefined
): Promise<{ rows: Person[]; pageCount: number }> => {
  const response = await learnApiClient.get<Person[]>(
    `users?fields=_id,first_name,last_name,full_name,email,image,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,groups&groups=${groupId}&linked_departments-populate=name&linked_locations-populate=name&page=${
      options.pageIndex + 1
    }&per_page=${options.pageSize}`
  );

  const count = await learnApiClient.get<{ count: number }>(
    `users/count?fields=_id&groups=${groupId}`
  );

  return {
    rows: response.data,
    pageCount: Math.ceil(count.data.count / options.pageSize) || -1,
  };
};

const getGroupManagers = async (
  options: { pageIndex: number; pageSize: number },
  groupId: string | undefined
): Promise<{ rows: Person[]; pageCount: number }> => {
  const response = await learnApiClient.get<Person[]>(
    `users?fields=_id,first_name,last_name,full_name,email,image,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,managing_groups&linked_departments-populate=name&linked_locations-populate=name&managing_groups=${groupId}&page=${
      options.pageIndex + 1
    }&per_page=${options.pageSize}`
  );

  const count = await learnApiClient.get<{ count: number }>(
    `users/count?fields=_id&managing_groups=${groupId}`
  );

  return {
    rows: response.data,
    pageCount: Math.ceil(count.data.count / options.pageSize) || -1,
  };
};

const getGroupNameById = async (
  groupId: string | undefined
): Promise<{ _id: string; name: string }> => {
  const response = await learnApiClient.get<{ _id: string; name: string }>(
    `groups/${groupId}?fields=_id,name`
  );

  return response.data;
};

const deleteGroupUsers = async (
  groupId: string | undefined,
  userIds: string[],
  removeManagers: boolean
) => {
  const response = await learnApiClient.post(
    `groups/${groupId}/remove?remove_managers=${removeManagers}`,
    {
      users: userIds,
    }
  );

  return response.data;
};

const deleteGroup = async (groupId: string | null) => {
  const response = await learnApiClient.delete(`groups/${groupId}`);

  return response.data;
};

const addUsersToGroup = async (
  groupId: string | undefined,
  userIds: string[],
  managers: boolean
) => {
  const response = await learnApiClient.post(
    `groups/${groupId}/addition?set_managers=${managers}`,
    {
      users: {
        queries: [],
        in: userIds,
        nin: [],
      },
    }
  );

  return response.data;
};

const downloadCsv = async (groupId: string | undefined) => {
  const response = await learnApiClient.get<string>(
    `groups/${groupId}/csv-download`
  );
  const fileName =
    response.headers["content-disposition"].split("filename=")[1] ||
    "group-report.csv";
  const blob = new Blob([response.data], { type: "application/csv" });
  saveAs(blob, fileName);
};

const GroupsService = {
  getGroup,
  getGroups,
  getAllGroups,
  search,
  simpleSearch,
  saveGroup,
  getGroupUsers,
  getGroupManagers,
  getGroupNameById,
  deleteGroupUsers,
  deleteGroup,
  addUsersToGroup,
  downloadCsv,
};

export default GroupsService;
