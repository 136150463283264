/**
 * First chars or chars after any non-letter (or non-diacritic-letter) char will be capitalized
 *
 * Examples:
 * 'name' => 'Name'
 * 'anna-may' => 'Anna-May'
 * 'àndré the giant' => 'Àndré The Giant'
 *
 * regex from: https://stackoverflow.com/questions/2991901/regular-expression-any-character-that-is-not-a-letter-or-number#:~:text=%C3%A9%20you%20could%20try-,this,-%3A
 */
export function capCase(str: string) {
  let casedString: string = "";
  const regex = /[^\wÀ-úÀ-ÿ]/g;
  for (let i = str.length - 1; i >= 0; i -= 1) {
    const char: string = str[i];
    const nextChar: string = str[i - 1];
    if (i === 0 || regex.test(nextChar)) {
      casedString = char.toUpperCase() + casedString;
    } else if (i !== 0 && !regex.test(nextChar)) {
      casedString = char + casedString;
    }
  }
  return casedString;
}
