import PreAuthService from "../../api/PreAuthService";
import { useConfigStore } from "@/client/services/state/configStore";
import { useQuery } from "@tanstack/react-query";

export const useAuthInviteSetup = (key: string, email: string) => {
  const { config } = useConfigStore();

  return useQuery({
    queryKey: ["accept-invite-setup", config.host, key, email],
    queryFn: async () =>
      PreAuthService.acceptInviteSetup(config.host, key, email),
    refetchOnWindowFocus: false,
    onError: (err: any) => console.log("Error", err.response?.data || err),
    enabled: !!key && !!email,
  });
};
