import TabControls from "@/client/components/admin/create/shared/tabs/TabControls";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useParams } from "react-router-dom";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";

interface ContentCreateControlsProps {
  tabOptionsLength: number;
  onProceed: () => void;
  canPublish: boolean;
  handlePublish: () => void;
  renderCoursePreviewButton?: boolean | null | undefined;
  renderAssessmentPreviewButton?: boolean | null | undefined;
}

export default function ContentCreateTabControls({
  tabOptionsLength,
  onProceed,
  handlePublish,
  canPublish,
  renderCoursePreviewButton = false,
  renderAssessmentPreviewButton = false,
}: ContentCreateControlsProps) {
  const { id } = useParams();
  const { trackState } = useTrackStateStore();
  const {
    setCoursePublishModalIsOpen,
    tabIndex,
    contentType,
    setCoursePreviewModalIsOpen,
    setAssessmentPreviewModalIsOpen,
  } = useCreateStore();

  const isTrack = contentType === "track";
  const isImportedContent =
    contentType === "linkedin" ||
    contentType === "udemy" ||
    contentType === "masterclass";
  const isCourse = isTrack || isImportedContent;
  const isAssessment = contentType === "assessment";

  return (
    <TabControls
      tabOptionsLength={tabOptionsLength}
      onProceed={onProceed}
      canPublish={canPublish}
      handlePublish={handlePublish}
      discardRoute={
        trackState && trackState.track
          ? `/admin/edit/course/track/${trackState.track}`
          : "/pvt/#/overview/content"
      }
      onPublishButtonClickOverride={
        isCourse && !id ? () => setCoursePublishModalIsOpen(true) : undefined
      }
      shouldRenderErrorsOnDisabledNextMouseOver={isTrack && tabIndex === 1}
      onPreviewButtonClick={
        isCourse
          ? () => setCoursePreviewModalIsOpen(true)
          : isAssessment
          ? () => setAssessmentPreviewModalIsOpen(true)
          : undefined
      }
      renderCoursePreviewButton={renderCoursePreviewButton}
      renderAssessmentPreviewButton={renderAssessmentPreviewButton}
    />
  );
}
