import { Center, Link, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { dateFormatTenant } from "@/client/utils/dateFormat";

interface AlreadyPurchasedProps {
  purchasedDate: Date;
}

export default function AlreadyPurchased({
  purchasedDate,
}: AlreadyPurchasedProps) {
  const { t } = useTranslation();

  return (
    <Center minHeight="100vh" backgroundColor="blue.400">
      <VStack spacing={6}>
        <Text as="h1" fontSize="5xl" color="white">
          {t("ecommerce.alreadyPurchased.header")}
        </Text>

        <Text color="white" fontSize="2xl">
          {t("ecommerce.alreadyPurchased.accessOn")}{" "}
          {dateFormatTenant(purchasedDate)}
        </Text>
        <Text color="white">
          {t("ecommerce.alreadyPurchased.goToManage")}
          <Link
            href="/dashboard"
            isExternal={false}
            color="white"
            _hover={{ textDecoration: "underline" }}
          >
            {t("ecommerce.alreadyPurchased.goToManage_link")}
          </Link>
        </Text>
      </VStack>
    </Center>
  );
}
