import { HiDownload } from "react-icons/hi";
import { IconButton } from "@chakra-ui/react";

interface GhostDownloadButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

export default function GhostDownloadButton({
  ariaLabel,
  onClick,
}: GhostDownloadButtonProps) {
  return (
    <IconButton
      size="lg"
      variant="ghost"
      icon={<HiDownload />}
      aria-label={ariaLabel}
      onClick={onClick}
    />
  );
}
