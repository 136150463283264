import {
  CLIENT_CREATION_KEY_API,
  registerClientCreation,
} from "./clientRegister";
import axios, { AxiosInstance } from "axios";

import { AuthConfig } from "@/client/types/AuthConfig";
import { Notification } from "../../../types/Notification";
import { NotificationPreferences } from "../../../types/Notifications";
import { S3Policy } from "@/client/types/aws/s3Policy";

export let v3ApiClient: AxiosInstance;

const create = (baseURL: string) => {
  v3ApiClient = axios.create({
    baseURL: `${baseURL}/v3/`,
  });

  v3ApiClient.interceptors.request.use(
    async (config) => {
      const value = localStorage.getItem("authStore");

      let keys;

      if (value) {
        keys = JSON.parse(value);

        config.headers = {
          authorization: `AuthToken token=${keys.state.authConfig.authtoken}`,
        };
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

registerClientCreation(CLIENT_CREATION_KEY_API, create);

const getUserNotifications = async (id: string | undefined) => {
  const response = await v3ApiClient.get<Notification[]>(
    `notifications?archived=false&fields=user,company,message,user_from,archived,viewed,createdAt,no_app&no_app=$ne,true&page=1&per_page=200&sort=createdAt,-1&user=${id}&user_from-populate=first_name,last_name,full_name,email,image`
  );

  return response.data;
};

const getUserNotificationPreferences = async (id: string | undefined) => {
  const response = await v3ApiClient.get<NotificationPreferences>(
    `preferences/users/${id}`
  );
  return response.data;
};

const archiveNotification = async (id: string) => {
  return v3ApiClient.post(`notifications/${id}`, {
    viewed: true,
    archived: true,
    viewed_at: new Date(),
  });
};

const updateUserNotificationPreferences = async (
  id: string,
  email: boolean,
  app: boolean
) =>
  v3ApiClient.post(`preferences/users/${id}`, {
    notifications: {
      email,
      app,
    },
  });

const getAuthToken = async () => {
  const response = await v3ApiClient.get<AuthConfig>("authtoken");

  return response.data;
};

const getS3ImagePolicy = async (contentType: string, fileName: string) => {
  const response = await v3ApiClient.get<S3Policy>(
    `s3-uploads/image-policy?contentType=${contentType}&fileName=${fileName}`
  );

  return response.data;
};

const getS3PartnerImagePolicy = async (
  contentType: string,
  fileName: string,
  partnerId: string
) => {
  const response = await v3ApiClient.get<S3Policy>(
    `s3-uploads/partner-image-policy?contentType=${contentType}&fileName=${fileName}&partner=${partnerId}`
  );

  return response.data;
};

const getS3FilePolicy = async (contentType: string, fileName: string) => {
  const response = await v3ApiClient.get<S3Policy>(
    `s3-uploads/file-policy?contentType=${contentType}&fileName=${fileName}`
  );

  return response.data;
};

const getS3VideoPolicy = async (contentType: string, fileName: string) => {
  const response = await v3ApiClient.get<S3Policy>(
    `s3-uploads/video-policy?contentType=${contentType}&fileName=${fileName}`
  );

  return response.data;
};

const updateUserPassword = async (id: string, password: string) =>
  v3ApiClient.post(`password/users/${id}`, { password });

const me = async () => {
  const response = await v3ApiClient.get(`me`);
  return response.data;
};

const v3ApiService = {
  getUserNotifications,
  getUserNotificationPreferences,
  archiveNotification,
  updateUserNotificationPreferences,
  getAuthToken,
  getS3ImagePolicy,
  getS3FilePolicy,
  getS3VideoPolicy,
  getS3PartnerImagePolicy,
  updateUserPassword,
  me,
};

export default v3ApiService;
