import "./Celebration.scss";

import {
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

import { Close } from "@/client/components/icons/ContinuIcons";
import Confetti from "react-confetti";
import { useState } from "react";

interface CelebrationProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: string;
  title: string;
}

export default function Celebration({
  isOpen,
  setIsOpen,
  user,
  title,
}: CelebrationProps) {
  const { t } = useTranslation();
  const [showConfetti, setShowConfetti] = useState(false);

  const handleCloseCelebration = () => {
    setShowConfetti(false);
    setIsOpen(false);
  };

  const celebrate = () => {
    setShowConfetti(true);

    setTimeout(() => {
      handleCloseCelebration();
    }, 5000);
  };

  return (
    <Modal size="full" isOpen={isOpen} onClose={handleCloseCelebration}>
      <ModalOverlay />

      <ModalContent backgroundColor="brand.primary">
        <ModalHeader display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="Close"
            icon={<Close color="white" />}
            onClick={handleCloseCelebration}
          />
        </ModalHeader>

        <ModalBody margin="8rem auto">
          <Flex flexDirection="column" alignItems="center">
            <Heading id="celebration_hands">&#128588;</Heading>

            <Text variant="contentheader" color="white">
              {`${t("modules.notifications.track_congratulations")} ${user}!`}
            </Text>

            <Text color="white" fontSize="md" marginTop={5} fontWeight="500">
              <Trans
                shouldUnescape
                i18nKey="modules.notifications.track_trackCompleted"
                values={{ title }}
              />
            </Text>

            <Button variant="celebrate" onClick={() => celebrate()}>
              {t("track.lets_celebrate")}
            </Button>
          </Flex>
        </ModalBody>

        {showConfetti && <Confetti />}
      </ModalContent>
    </Modal>
  );
}
