import WorkshopService from "@/client/services/api/content/workshops/WorkshopService";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useQuery } from "@tanstack/react-query";

export const useGetWorkshop = (
  userId: string,
  workshopId: string | undefined
) =>
  useQuery({
    enabled: !!workshopId,
    queryKey: ["workshop", workshopId, userId],
    queryFn: () => WorkshopService.getWorkshop(userId, workshopId),
    onSuccess: (data) => {
      document.title = data.workshop.title;

      trackEvent("workshop_viewed", {
        content: data.workshop._id,
        content_type: "workshop",
        title: data.workshop.title,
      });
    },
    onError: (error) => console.error("Error getting workshop: ", error),
  });
