import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/react";

import AssessmentCounter from "../../input/AssessmentCounter";
import { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import AssessmentSwitchContainer from "../../input/AssessmentSwitchContainer";
import ContentFormTextGroup from "../../text/ContentFormTextGroup";
import { learnApiClient } from "@/client/services/api/clients/learnApiClient";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const getCompanyVideoAssessmentLimits = async (): Promise<{
  video_assessment_limits: {
    max_duration: number;
    max_attempts: number;
  };
  _id: string;
}> => {
  const response = await learnApiClient.get(
    "companies?fields=video_assessment_limits"
  );

  return response.data;
};

export default function VideoQuestionSettingsForm() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AssessmentFormData>();

  const { data: limitData } = useQuery({
    queryKey: ["video-assessment-limits"],
    queryFn: getCompanyVideoAssessmentLimits,
  });

  const questions = watch("questions");

  const allowUpload =
    questions[0]?.questionOptions?.find(
      (option) => option.name === "allow_upload"
    )?.value || false;

  const showAttempt =
    questions[0]?.questionOptions?.find(
      (option) => option.name === "choose_attempt"
    )?.value || false;

  const recordingLimitIndex = questions[0]?.questionOptions?.findIndex(
    (data) => data.name === "recording_limit"
  );

  const maxAttemptsIndex = questions[0]?.questionOptions?.findIndex(
    (data) => data.name === "max_attempts"
  );

  const handleSwitchChange = (isChecked: boolean, value: string) => {
    const updatedQuestion = {
      ...questions[0],
      questionOptions: questions[0]?.questionOptions?.map((option) => {
        if (value === option.name) {
          return { ...option, value: isChecked };
        }
        return option;
      }),
    };

    // Add the option if it doesn't exist
    if (
      !updatedQuestion.questionOptions?.find((option) => option.name === value)
    ) {
      updatedQuestion.questionOptions?.push({ name: value, value: isChecked });
    }

    // @ts-ignore
    setValue("questions", [updatedQuestion]);
  };

  const handleCounterChange = (value: number, option: string) => {
    const updatedQuestion = {
      ...questions[0],
      questionOptions: questions[0]?.questionOptions?.map((questionOption) => {
        if (questionOption.name === option) {
          return { ...questionOption, value };
        }
        return questionOption;
      }),
    };

    // Add the option if it doesn't exist
    if (
      !updatedQuestion.questionOptions?.find(
        (questionOption) => questionOption.name === option
      )
    ) {
      updatedQuestion.questionOptions?.push({ name: option, value });
    }

    // @ts-ignore
    setValue("questions", [updatedQuestion]);
  };

  return (
    <Stack>
      <Box marginBottom={4}>
        <ContentFormTextGroup
          label={t("assessment.publishPreview.responseSettings")}
        />
      </Box>

      <Flex alignItems="center" justifyContent="space-between">
        <Stack spacing={0.5}>
          <Text variant="createLabel">
            {t("authoring.assessment.questionSettings.allowVideoUpload.label")}
          </Text>

          <Box>
            <Text variant="createHelpText">
              {t(
                "authoring.assessment.questionSettings.allowVideoUpload.helpText1"
              )}
            </Text>

            <Text variant="createHelpText" fontWeight={500}>
              {t(
                "authoring.assessment.questionSettings.allowVideoUpload.helpText2"
              )}
            </Text>
          </Box>
        </Stack>

        <AssessmentSwitchContainer
          name="questions"
          value="allow_upload"
          onHandleChange={handleSwitchChange}
          isChecked={allowUpload as boolean}
        />
      </Flex>

      <Divider marginY={4} borderColor="neutral.100" />

      <Flex width="full" justifyContent="space-between">
        <Stack spacing={0.5}>
          <Text variant="createLabel">
            {t("authoring.assessment.questionSettings.timeLimit.label")}
          </Text>

          <Text variant="createHelpText">
            {t("authoring.assessment.questionSettings.timeLimit.helpText")}
          </Text>
        </Stack>

        <Flex justifyContent="flex-end">
          <AssessmentCounter
            name={`questions.0.questionOptions.${recordingLimitIndex}.value`}
            counterMin={1}
            counterMax={limitData?.video_assessment_limits?.max_duration || 15}
            onHandleChange={handleCounterChange}
            option="recording_limit"
            label={t("authoring.assessment.questionSettings.timeLimit.minutes")}
          />
        </Flex>
      </Flex>

      <Divider marginY={4} borderColor="neutral.100" />

      <Flex width="full" justifyContent="space-between">
        <Stack spacing={0.5} mr={10}>
          <Text variant="createLabel">
            {t("authoring.assessment.questionSettings.maxAttempts.label")}
          </Text>

          <Text variant="createHelpText">
            {t("authoring.assessment.questionSettings.maxAttempts.helpText")}
          </Text>
        </Stack>

        <Flex justifyContent="flex-end">
          <AssessmentCounter
            name={`questions.0.questionOptions.${maxAttemptsIndex}.value`}
            counterMin={1}
            counterMax={limitData?.video_assessment_limits?.max_attempts || 8}
            onHandleChange={handleCounterChange}
            option="max_attempts"
          />
        </Flex>
      </Flex>

      <Divider marginY={4} borderColor="neutral.100" />

      <Flex alignItems="center" justifyContent="space-between">
        <Stack spacing={0.5} mr={20}>
          <Text variant="createLabel">
            {t("authoring.assessment.questionSettings.showAttempts.label")}
          </Text>

          <Box>
            <Text variant="createHelpText">
              {t("authoring.assessment.questionSettings.showAttempts.helpText")}
            </Text>
          </Box>
        </Stack>

        <AssessmentSwitchContainer
          name="questions"
          value="choose_attempt"
          onHandleChange={handleSwitchChange}
          isChecked={showAttempt as boolean}
        />
      </Flex>
    </Stack>
  );
}
