import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const createError = defineStyle({
  container: {
    marginY: 8,
    backgroundColor: "#E4626F",
    borderRadius: 4,
  },
  icon: {
    boxSize: 6,
    color: "white",
  },
  description: {
    color: "white",
    fontSize: "12px",
  },
});

export const alertTheme = defineStyleConfig({
  variants: { createError },
});
