import { Box, Stack, Text } from "@chakra-ui/react";
import { useComponentSize } from "react-use-size";

import BaseProductTag from "./BaseProductTag";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Product } from "@/client/services/api/graphql/gql/graphql";
import CreateFormGroup from "../../create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "../../create/shared/layout/CreateSwitchContainer";
import ProductTagSearch from "@/client/routes/products/ProductTagSearch";

export default function EditRelatedProductsForm() {
  const { t } = useTranslation();
  const { watch, getValues } = useFormContext<Product>();
  const { ref: containerRef } = useComponentSize();

  return (
    <Stack>
      <Box ref={containerRef}>
        <Box overflowY="auto">
          <Stack spacing={6}>
            {getValues("default") && <BaseProductTag />}
            <Stack spacing={2}>
              <Text fontSize="md" fontWeight="600">
                {t("ecommerce.create.relatedProducts.market")}
              </Text>
              <Text fontSize="sm">
                {t("ecommerce.create.relatedProducts.marketHelpText")}
              </Text>
            </Stack>
            <CreateFormGroup>
              <CreateSwitchContainer
                name="allowRelatedProducts"
                label={t("ecommerce.create.relatedProducts.enableLabel")}
                helpText={t(
                  "ecommerce.create.relatedProducts.enableRelatedProducts"
                )}
              />
            </CreateFormGroup>
            {watch("allowRelatedProducts") && (
              <Stack>
                <Text variant="createLabel">
                  {t("ecommerce.create.relatedProducts.select")}
                </Text>
                <Text variant="createHelpText">
                  {t("ecommerce.create.relatedProducts.selectHelpText")}
                </Text>
                <ProductTagSearch
                  fieldName="relatedProducts"
                  placeholder={t("ecommerce.searchProducts")}
                />
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}
