import { ExploreCategory } from "@/client/types/ExploreContent";
import i18n from "i18next";

export const localizeCategories = (categories: ExploreCategory[]) => {
  if (!categories) {
    return categories;
  }

  let language = i18n.language;

  if (language === "en") {
    return categories;
  }

  language = language.toLowerCase();
  language = language + ":";

  for (let i = 0, len = categories.length; i < len; i++) {
    if (
      !categories[i].localized_names ||
      categories[i].localized_names.indexOf(language) === -1
    ) {
      continue;
    }

    let items = categories[i].localized_names.split(language);

    if (!items[1]) {
      continue;
    }

    let secondary = items[1].split(",");

    if (!secondary[0]) {
      continue;
    }

    categories[i].name = secondary[0];
    categories[i].label = secondary[0];
  }

  return categories;
};
