import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import ChangeSubscriptionDescription from "./ChangeSubscriptionDescription";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "@/client/services/state/authStore";
import { useToastStore } from "@/client/services/state/toastStore";
import { useQuery, useMutation } from "@tanstack/react-query";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import EcommerceService from "@/client/services/api/graphql/EcommerceService";
import OutlineButton from "@/client/components/shared/buttons/OutlineButton";

export default function CancelSubscription() {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const { subscriptionId } = useParams();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;

  const { data: subscription } = useQuery({
    queryKey: ["active-purchase", user._id, subscriptionId],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { in: [user._id] },
        id: { eq: subscriptionId! },
        subscriptionActive: true,
        page: 0,
        pageSize: 10,
        includeTotalCount: true,
      }),
  });

  const cancelSubscription = useMutation({
    mutationFn: async (productToCancelId: string) =>
      EcommerceService.cancelSubscription(productToCancelId),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("ecommerce.subscriptionManagement.changeSubscriptionSuccess"),
      });
      navigate(`/dashboard`);
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: t("ecommerce.subscriptionManagement.changeSubscriptionError"),
      });
      navigate(`/dashboard`);
    },
  });

  const durationToDisplay: { [key: string]: string } = {
    MONTHLY: "monthly",
    YEARLY: "annually",
  };

  const getSubscriptionEndDate = (
    subscriptionDuration: string,
    subscriptionIntiatedDate: string | Date
  ) => {
    const subscriptionEndDate = new Date(subscriptionIntiatedDate);
    if (subscriptionDuration === "MONTHLY") {
      subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + 1);
    } else if (subscriptionDuration === "YEARLY") {
      subscriptionEndDate.setFullYear(subscriptionEndDate.getFullYear() + 1);
    }
    return subscriptionEndDate.toString();
  };

  return (
    <Box padding={10}>
      <OutlineButton
        onClick={() => navigate(`/manage-subscription/${subscriptionId}`)}
      >
        {t("ecommerce.subscriptionManagement.headline")}
      </OutlineButton>
      <Flex
        direction="column"
        h="100%"
        borderColor="neutral.200"
        border="1px solid"
        borderRadius={10}
        marginY={10}
        marginX="20%"
        paddingY={10}
        paddingX={16}
      >
        <Text fontWeight="600" textAlign="left" marginBottom={4}>
          {t("ecommerce.checkout.summary")}
        </Text>
        <Grid templateColumns="30% 70%">
          {/* Cancelled Subscription Block */}
          <GridItem colStart={1} colSpan={2} rowStart={1} rowSpan={1}>
            <ChangeSubscriptionDescription
              headline={t(
                "ecommerce.subscriptionManagement.currentSubscription"
              )}
              title={subscription?.purchases[0].productDetails.title || ""}
              price={subscription?.purchases[0].productDetails.price || 0}
              action={`${t("ecommerce.subscriptionManagement.actionCancel")} `}
              duration={
                durationToDisplay[
                  subscription?.purchases[0].productDetails
                    .subscriptionDuration!
                ] || ""
              }
            />
          </GridItem>
          <GridItem colStart={1} colSpan={2} rowStart={4} rowSpan={1}>
            <Flex direction="row" gap="5%">
              <Text
                fontSize="sm"
                fontWeight="500"
                textAlign="left"
                width="150px"
              >
                {t("ecommerce.checkout.howItWorks")}:
              </Text>
              <Flex direction="column" gap={2} width="70%">
                <Text fontSize="sm" textAlign="left">
                  {t("ecommerce.cancelSubscription.howItWorks1", {
                    previousPayment:
                      subscription?.purchases[0].productDetails.price,
                    subscriptionName:
                      subscription?.purchases[0].productDetails.title,
                    subscriptionEndDate: getSubscriptionEndDate(
                      subscription?.purchases[0].productDetails
                        .subscriptionDuration!,
                      subscription?.purchases[0].purchaseInitiatedDate
                    ),
                  })}
                </Text>
                <Text fontSize="sm" textAlign="left">
                  {t("ecommerce.cancelSubscription.howItWorks2", {
                    subscriptionEndDate: getSubscriptionEndDate(
                      subscription?.purchases[0].productDetails
                        .subscriptionDuration!,
                      subscription?.purchases[0].purchaseInitiatedDate
                    ),
                  })}
                </Text>
              </Flex>
            </Flex>
            <Divider marginY={6} borderTop=".25px solid" opacity="20%" />
          </GridItem>
        </Grid>
        <Flex direction="row" justifyContent="space-between">
          <OutlineButton
            width="40%"
            marginTop={8}
            type="submit"
            onClick={() => navigate(`/manage-subscription/${subscriptionId}`)}
          >
            {t("ecommerce.cancelSubscription.keepActive")}
          </OutlineButton>
          <Button
            width="40%"
            marginTop={8}
            colorScheme="blue"
            type="submit"
            onClick={() =>
              cancelSubscription.mutate(
                subscription?.purchases[0].productDetails.id!
              )
            }
          >
            {t("ecommerce.cancelSubscription.cancelSubscription")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
