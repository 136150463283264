/* eslint-disable react/jsx-props-no-spreading */
import { Fade, Stack, Text } from "@chakra-ui/react";

import AuthoringCollaboratorSearch from "@/client/components/admin/create/content/forms/settings/AuthoringCollaboratorSearch";
import AuthoringDurationForm from "@/client/components/admin/create/content/forms/settings/AuthoringDurationForm";
import AuthoringLearnItemInput from "../../input/AuthoringLearnItemInput";
import AuthoringSurveySearch from "@/client/components/admin/create/content/forms/settings/AuthoringSurveySearch";
import AuthoringTagInput from "@/client/components/admin/create/content/input/AuthoringTagInput";
import ContentCertificateForm from "./ContentCertificateForm";
import ContentRatingForm from "@/client/components/admin/create/content/forms/settings/ContentRatingForm";
import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "@/client/components/admin/create/shared/layout/CreateSwitchContainer";
import ScormPlayerConfigurationForm from "../scorm/ScormPlayerConfigurationForm";
import TrackSettingsForm from "@/client/components/admin/create/content/courses/forms/TrackSettingsForm";
import VttUploadForm from "@/client/components/admin/create/content/forms/videos/VttUploadForm";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useFormContext } from "react-hook-form";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";
import { useTranslation } from "react-i18next";

interface ContentSettingsFormProps {
  isScorm?: boolean;
  isFile?: boolean;
  isVideo?: boolean;
  isCourse?: boolean;
}

export default function ContentSettingsForm({
  isScorm,
  isFile,
  isVideo,
  isCourse,
}: ContentSettingsFormProps) {
  const { trackState } = useTrackStateStore();
  const { watch } = useFormContext();
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company, user } = authConfig;
  const { isAllowed } = useAccessHelper();
  const { contentType } = useCreateStore();

  const isTrack = contentType === "track";

  const isEmbed = watch("embed");
  const videoSource = watch("source");

  const allowance = {
    add_survey:
      company.feature_flags.enable_surveys &&
      isAllowed(["admin", "surveys"], null, ["admin"]),
  };

  return (
    <Fade in delay={0.1}>
      <Stack spacing={6}>
        <Text variant="createHeading">
          {t("authoring.settings.contentSettings")}
        </Text>

        {isTrack && <TrackSettingsForm />}

        {isCourse && <AuthoringLearnItemInput />}

        <ContentRatingForm />

        <CreateFormGroup>
          <CreateSwitchContainer
            name="allowComments"
            label={t("authoring.settings.enableComments")}
            helpText={t("authoring.settings.enableComments.helpText")}
          />
        </CreateFormGroup>

        {!user.is_collaborator && <AuthoringCollaboratorSearch />}

        {allowance.add_survey && <AuthoringSurveySearch />}

        {isFile && !isEmbed && (
          <CreateFormGroup>
            <CreateSwitchContainer
              name="downloadable"
              label="Allow file to be downloaded"
              helpText="By selecting this option, a download link will be provided when viewing the file."
            />
          </CreateFormGroup>
        )}

        {(isScorm || isTrack) && (
          <>
            <ContentCertificateForm />

            {isScorm && <ScormPlayerConfigurationForm />}
          </>
        )}

        <AuthoringDurationForm />

        {!trackState && <AuthoringTagInput />}

        {isVideo && videoSource === "custom" && <VttUploadForm />}
      </Stack>
    </Fade>
  );
}
