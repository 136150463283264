import { useEffect, useState } from "react";

import ThemeService from "@/client/services/api/admin/theme/ThemeService";
import { isEqual } from "lodash";
import { useConfigStore } from "@/client/services/state/configStore";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import ColorOverridesForm from "./ColorOverridesForm";
import { ColorOverrides } from "@/client/types/Theme";

export default function ThemeColorOverrides() {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { config, setConfig } = useConfigStore();
  const [canSubmit, setCanSubmit] = useState(false);
  const [colors, setColors] = useState<ColorOverrides>({
    anchors: "",
    buttons: "",
    headers: "",
    highLights: "",
    links: "",
    formInputs: "",
  });

  useEffect(() => {
    if (config.theme.colors.overrides) {
      setColors(config.theme.colors.overrides);
    }
  }, [config]);

  useEffect(() => {
    if (!isEqual(colors, config.theme.colors.overrides)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [colors, config]);

  interface MutationVariables {
    colorOverrides: ColorOverrides;
  }

  const setThemeColorOverrides = useMutation({
    mutationFn: ({ colorOverrides }: MutationVariables) =>
      ThemeService.setThemeColorOverrides(colorOverrides),
    onSuccess: (data, variables) => {
      setToast({
        show: true,
        status: "success",
        title: t("admin.theme.updateSuccess"),
      });

      setConfig({
        ...config,
        theme: {
          ...config.theme,
          colors: {
            ...config.theme.colors,
            overrides: variables.colorOverrides,
          },
        },
      });

      setCanSubmit(false);
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: t("admin.theme.updateError"),
      });
    },
  });

  return (
    <ColorOverridesForm
      colors={colors}
      setColors={setColors}
      resetOverrides={() =>
        setThemeColorOverrides.mutateAsync({
          colorOverrides: {
            anchors: "",
            buttons: "",
            headers: "",
            highLights: "",
            links: "",
            formInputs: "",
          },
        })
      }
      saveOverrides={() =>
        setThemeColorOverrides.mutateAsync({ colorOverrides: colors })
      }
      isLoading={setThemeColorOverrides.isLoading}
      canSubmit={canSubmit}
      saveInForm
    />
  );
}
