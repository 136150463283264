import { Box, Flex, Tag, TagLabel, TagRightIcon, Text } from "@chakra-ui/react";

import { Category } from "@/client/types/Category";
import { CloseIcon } from "@chakra-ui/icons";
import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import { useTranslation } from "react-i18next";

interface SelectedTagListProps {
  selectedItems: any[];
  label?: any;
  removeItem: (item: Category | any, action: "add" | "remove") => void;
}

export default function SelectedTagList({
  selectedItems,
  label,
  removeItem,
}: SelectedTagListProps) {
  const { t } = useTranslation();

  return (
    <Box>
      {label && <FormLabelWithTooltip label={label} />}

      {label && selectedItems && selectedItems.length === 0 && (
        <Text as="i" fontSize="sm" color="brand.gray.500">
          {t("global.forms.labels_no")} {label.toLowerCase()}
        </Text>
      )}

      {selectedItems && selectedItems.length > 0 && (
        <Flex wrap="wrap">
          {selectedItems.map((item) => (
            <Tag variant="admin" key={item._id || item.id}>
              <TagLabel>
                {item.legacyName ||
                  item.name ||
                  item.full_name ||
                  item.label ||
                  item.title}
              </TagLabel>

              <TagRightIcon
                boxSize={3}
                as={CloseIcon}
                _hover={{ cursor: "pointer" }}
                onClick={() => removeItem(item, "remove")}
              />
            </Tag>
          ))}
        </Flex>
      )}
    </Box>
  );
}

SelectedTagList.defaultProps = {
  label: null,
};
