import {
  FaCcVisa,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
} from "react-icons/fa";
import { FaCcAmex } from "react-icons/fa6";

export const cardBrandIcons: Record<string, JSX.Element> = {
  american_express: <FaCcAmex size="2em" />,
  visa: <FaCcVisa size="2em" />,
  diners_club: <FaCcDinersClub size="2em" />,
  discover: <FaCcDiscover size="2em" />,
  jcb: <FaCcJcb size="2em" />,
  mastercard: <FaCcMastercard size="2em" />,
};
