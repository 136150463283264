/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/no-unstable-nested-components: 0 */

import { AsyncSelect, OptionBase, chakraComponents } from "chakra-react-select";
import { Avatar, Flex, Stack } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { EditSimple } from "../icons/ContinuIcons";
import FormLabelWithTooltip from "../admin/forms/FormLabelWithTooltip";

// TODO: Debounce this search

interface Option extends OptionBase {
  readonly value: string;
  readonly label: string;
  readonly image?: string;
}

interface InputProps {
  label: string;
  name: string;
  tooltipText?: string;
  isMultiple?: boolean;
  isRequired?: boolean;
  includeAvatar?: boolean;
  loadOptions: (inputValue: string) => void;
  defaultValue: Option | Option[] | undefined;
}

export default function SearchSelect({
  label,
  name,
  tooltipText,
  isMultiple,
  loadOptions,
  defaultValue,
  isRequired,
  includeAvatar,
}: InputProps) {
  const { control } = useFormContext();

  const components = {
    SingleValue: (props: any) => (
      <chakraComponents.SingleValue {...props}>
        <Flex alignItems="center">
          {includeAvatar && (
            <Avatar src={props.data.image} size="sm" marginRight={3} />
          )}

          {props.data.label}
        </Flex>
      </chakraComponents.SingleValue>
    ),
    DropdownIndicator: (props: any) => (
      <chakraComponents.DropdownIndicator {...props}>
        <EditSimple color="brand.primary" />
      </chakraComponents.DropdownIndicator>
    ),
  };

  return (
    <Stack spacing={2}>
      <FormLabelWithTooltip
        label={label}
        tooltipText={tooltipText}
        isRequired={isRequired}
      />

      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field: { onChange } }) => (
          <AsyncSelect
            variant="filled"
            loadOptions={loadOptions}
            defaultOptions
            isMulti={isMultiple}
            components={components}
            value={defaultValue}
            /* @ts-ignore */
            onChange={(newValue: Option | Option[]) =>
              isMultiple
                ? /* @ts-ignore */
                  onChange(newValue.map((option: Option) => option.value))
                : /* @ts-ignore */
                  onChange(newValue.value)
            }
          />
        )}
      />
    </Stack>
  );
}

SearchSelect.defaultProps = {
  isRequired: false,
  isMultiple: false,
  includeAvatar: false,
  tooltipText: null,
};
