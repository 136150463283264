import { Box, Flex } from "@chakra-ui/react";

import { Navigate } from "react-router-dom";
import { useAuthStore } from "../services/state/authStore";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function Qooper() {
  useDocumentTitle("Qooper");
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  if (!company.feature_flags.enable_qooper)
    return <Navigate to="/explore" replace />;

  return (
    <Flex alignItems="center">
      <Box width="100vw" height="100vh">
        <iframe
          title="Qooper Mentoring & Learning Software"
          width="100%"
          height="100%"
          src="https://mentoring.qooper.io/mentoring"
        />
      </Box>
    </Flex>
  );
}
