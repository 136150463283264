/* eslint-disable react/no-array-index-key */
import { Link, Text } from "@chakra-ui/react";
import { admin, manager } from "@/client/utils/routeLookup";

import { Link as RouterLink } from "react-router-dom";

type NotificationMessageProps = {
  message: string;
  onClose: () => void;
};

// We're getting both old style learn-app content types as well as new learners content types within links from API
const contentTypeMap: { [key: string]: string } = {
  article: "article",
  articles: "article",
  file: "file",
  files: "file",
  video: "video",
  videos: "video",
  scorm: "scorm",
  track: "track",
  tracks: "track",
  "imported-content": "imported-content",
  assessment: "assessment",
  assessments: "assessment",
  workshop: "workshop",
  workshops: "workshop",
  "multi-session-workshop": "multi-session-workshop",
  "multi-session-workshops": "multi-session-workshop",
};

export default function NotificationMesssage({
  message,
  onClose,
}: NotificationMessageProps) {
  if (!message.includes("<"))
    return (
      <Text flex={2} fontSize="xs" paddingLeft={4} paddingRight={12}>
        {message}
      </Text>
    );

  const bracketArray: any[] = [];
  let bracketObj: any = {};

  for (let i = 0; i < message.length; i += 1) {
    if (message[i] === "<") {
      bracketObj.open = i;
    } else if (message[i] === ">") {
      bracketObj.close = i;
      bracketArray.push(bracketObj);
      bracketObj = {};
    }
  }

  const messageArray: any[] = [];

  const checkIsLink = (str: string) => {
    if (str.includes("<")) {
      const replaced = str.replace(/[<>]/g, "");
      const split = replaced.split("|");
      const routeSplit = split[0].split("/");

      const type = routeSplit.slice(-2)[0];
      const id = routeSplit.slice(-2)[1];

      let route;
      if (type === "dashboard") {
        route = `/dashboard`;
      } else if (id && id === "dashboard?activeTab=assignments") {
        route = "dashboard?activeTab=assignments";
      } else if (id && id.includes("manager")) {
        route = "//manager";
      } else {
        route = `/${contentTypeMap[type]}/${id}`;
      }

      return {
        link: true,
        route,
        text: split[1],
      };
    }
    return {
      link: false,
      text: str,
    };
  };

  // Is there a more reliable solution?
  const checkLinkIsExternal = (link: string) => link.indexOf("//") === 0;

  const buildExternalLink = (route: string) => {
    let string = route;

    if (route.indexOf("manager") > -1) {
      string = manager();
    } else if (route.indexOf("admin") > -1) {
      string = admin();
    }

    return string;
  };

  let segment = "";
  bracketArray.forEach((obj, index) => {
    if (index === 0) {
      segment = message.slice(0, obj.open);

      messageArray.push(checkIsLink(segment));
    } else {
      segment = message.slice(bracketArray[index - 1].close + 1, obj.open);
      messageArray.push(checkIsLink(segment));
    }

    segment = message.slice(obj.open, obj.close + 1);
    messageArray.push(checkIsLink(segment));
  });

  return (
    <Text flex={2} fontSize="xs" paddingLeft={4} paddingRight={12}>
      {messageArray.map((obj, index) => {
        if (obj.link && checkLinkIsExternal(obj.route)) {
          return (
            <Link key={index} href={buildExternalLink(obj.route)} isExternal>
              {obj.text}
            </Link>
          );
        }

        if (obj.link && !checkLinkIsExternal(obj.route)) {
          return (
            <Link
              key={index}
              as={RouterLink}
              to={obj.route}
              onClick={() => onClose()}
            >
              {obj.text}
            </Link>
          );
        }
        return obj.text;
      })}
    </Text>
  );
}
