import { BorderAll, Columns } from "@/client/components/icons/ContinuIcons";
import { Box, Center, Flex, Spinner, Tooltip } from "@chakra-ui/react";

import Loading from "@/client/components/media/Loading";
import { Navigate } from "react-router-dom";
import UserCards from "../components/people/UserCards";
import UserSearch from "@/client/components/input/search/UserSearch";
import UserTable from "../components/people/UserTable";
import { useAuthStore } from "../services/state/authStore";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useEffect } from "react";
import { useGetOrganizationMembers } from "@/client/services/hooks/useGetOrganizationMembers";
import { useInView } from "react-intersection-observer";
import { usePeopleStore } from "@/client/services/state/routeState/peopleStore";
import { useUserSearch } from "../services/hooks/shared/search/useUserSearch";

// TODO: Standalone user search component for this route

export default function People() {
  useDocumentTitle("People");
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { ref, inView } = useInView();
  const { isListView, setIsListView } = usePeopleStore();

  const {
    searchWithParamsInfinite: searchWithParams,
    handleCreateItem,
    handleSelectedItemsChange,
    selectedItems,
    setSearchValue,
    searchValue,
    searchActive,
    filteredCategories,
  } = useUserSearch("learners");

  const { data, fetchNextPage, isFetchingNextPage, status } =
    useGetOrganizationMembers();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  if (!company.feature_flags.enable_explore_search)
    return <Navigate to="/explore" replace />;

  if (status === "loading") return <Loading />;

  if (status === "error") return <Box>Error...</Box>;

  return (
    <>
      <Flex padding={4} paddingBottom={0} alignItems="center">
        <Box
          flex={{
            base: 0,
            lg: 1,
          }}
          marginRight={{
            base: 4,
            lg: 0,
          }}
          transition="0.3s ease all"
        >
          <Tooltip
            label={isListView ? "Card View" : "List View"}
            placement="bottom-start"
          >
            <Box color="gray.400" onClick={() => setIsListView(!isListView)}>
              {isListView ? (
                <BorderAll _hover={{ cursor: "pointer" }} />
              ) : (
                <Columns _hover={{ cursor: "pointer" }} />
              )}
            </Box>
          </Tooltip>
        </Box>

        <UserSearch
          variant="explore"
          onCreateItem={handleCreateItem}
          items={filteredCategories}
          selectedItems={selectedItems}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          onSelectedItemsChange={(changes: any) => {
            handleSelectedItemsChange(changes.selectedItems, changes.type);
          }}
        />
      </Flex>

      {isListView && (
        <UserTable people={searchActive ? searchWithParams.data : data} />
      )}

      {!isListView && (
        <UserCards people={searchActive ? searchWithParams.data : data} />
      )}

      <Center ref={ref} margin={10}>
        {isFetchingNextPage && <Spinner />}
      </Center>
    </>
  );
}
