import { Category } from "@/client/types/Category";
import { ProfileForm } from "@/client/types/admin/profiles/ProfileForm";
import SegmentationSearch from "@/client/components/admin/input/search/SegmentationSearch";
import { Stack } from "@chakra-ui/react";
import SwitchList from "./SwitchList";
import SwitchWithTooltip from "@/client/components/admin/forms/SwitchWithTooltip";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function UserSegmentation() {
  const { watch, setValue } = useFormContext<ProfileForm>();
  const { t } = useTranslation();

  const restrictUserAccess = watch("permissions.admin.users.restricted_access");

  const segmentationSelections = watch("permissions.admin.users.user_group");
  const userSegmentationSelections = watch(
    "permissions.admin.users.user_group"
  );

  const viewUsers = watch("permissions.admin.users.view");

  const options = [
    {
      name: "permissions.admin.users.bulk_add",
      label: t("profiles.edit.addAnyWithBulk"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.add",
      label: t("profiles.edit.addASingleUser"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.view",
      label: t("profiles.edit.viewUsers"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.view_user_activity",
      label: t("profiles.edit.viewUserActivity"),
      enabled: viewUsers,
    },
    {
      name: "permissions.admin.users.edit",
      label: t("profiles.edit.editUsers"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.password_reset",
      label: t("profiles.edit.passwordResetUser"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.suspend",
      label: t("profiles.edit.suspendUsers"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.admin_set",
      label: t("profiles.edit.allowAdminRoleSelection"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.creator_set",
      label: t("profiles.edit.allowCreatorRoleSelection"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.buddy_set",
      label: t("profiles.edit.allowBuddyRoleSelection"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.manager_set",
      label: t("profiles.edit.allowManagerRoleSelection"),
      enabled: true,
    },
    {
      name: "permissions.admin.users.profile_set",
      label: t("profiles.edit.allowProfileRoleSelection"),
      enabled: true,
    },
  ];

  const handleContentSegmentationSelection = (
    option: Category,
    action: "add" | "remove"
  ) => {
    if (option.department) {
      action === "add"
        ? setValue("permissions.admin.users.user_group.departments", [
            ...userSegmentationSelections.departments,
            option,
          ])
        : setValue(
            "permissions.admin.users.user_group.departments",
            userSegmentationSelections.departments.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.location) {
      action === "add"
        ? setValue("permissions.admin.users.user_group.locations", [
            ...userSegmentationSelections.locations,
            option,
          ])
        : setValue(
            "permissions.admin.users.user_group.locations",
            userSegmentationSelections.locations.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.team) {
      action === "add"
        ? setValue("permissions.admin.users.user_group.teams", [
            ...userSegmentationSelections.teams,
            option,
          ])
        : setValue(
            "permissions.admin.users.user_group.teams",
            userSegmentationSelections.teams.filter((d) => d._id !== option._id)
          );
    }

    if (option.org_level) {
      action === "add"
        ? setValue("permissions.admin.users.user_group.org_levels", [
            ...userSegmentationSelections.org_levels,
            option,
          ])
        : setValue(
            "permissions.admin.users.user_group.org_levels",
            userSegmentationSelections.org_levels.filter(
              (d) => d._id !== option._id
            )
          );
    }

    if (option.grade) {
      action === "add"
        ? setValue("permissions.admin.users.user_group.grades", [
            ...userSegmentationSelections.grades,
            option,
          ])
        : setValue(
            "permissions.admin.users.user_group.grades",
            userSegmentationSelections.grades.filter(
              (d) => d._id !== option._id
            )
          );
    }
  };

  return (
    <Stack spacing={4}>
      <SwitchWithTooltip
        name="permissions.admin.users.restricted_access"
        label={t("profiles.edit.restrictUserAccess")}
        tooltipText={t("profiles.edit.restrictUserAccessHelpText")}
      />

      {restrictUserAccess && (
        <>
          <SwitchWithTooltip
            name="permissions.admin.users.external_users"
            label={t("profiles.edit.accessToExternalUsers")}
            tooltipText=""
          />

          <SegmentationSearch
            label={t("profiles.edit.segmentationReactions")}
            inputPlaceholder="Search Fields"
            tooltipText=""
            segmentationSelections={segmentationSelections}
            updateSelections={handleContentSegmentationSelection}
          />
        </>
      )}

      <SwitchList enableSelectAll={false} options={options} />
    </Stack>
  );
}
