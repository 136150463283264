import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useBlocker } from "react-router-dom";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";
import { useTranslation } from "react-i18next";

interface NavigationBlockerProps {
  enabled: boolean;
}

export default function NavigationBlocker({ enabled }: NavigationBlockerProps) {
  const { t } = useTranslation();
  const { trackState, setTrackState } = useTrackStateStore();
  const [isOpen, setIsOpen] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      enabled && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") setIsOpen(true);
  }, [blocker.state]);

  const handleLeavePage = () => {
    if (blocker && blocker.proceed) {
      if (trackState) {
        setTrackState(null);
        localStorage.removeItem("trackState");
      }

      blocker.proceed();
      setIsOpen(false);
    }
  };

  const handleStayOnPage = () => {
    if (blocker && blocker.reset) {
      blocker.reset();
      setIsOpen(false);
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleStayOnPage}>
      <ModalOverlay />

      <ModalContent>
        <ModalBody padding={8}>
          <VStack spacing={6}>
            <Text fontSize="2xl" fontWeight="medium">
              {t("edit.general.exitModal_header")}
            </Text>

            <Text>{t("edit.general.exitModal_content")}</Text>

            <ButtonGroup spacing={6}>
              <Button
                size="sm"
                variant="adminCancel"
                onClick={handleStayOnPage}
              >
                {t("edit.general.exitModal_cancel")}
              </Button>

              <Button
                size="sm"
                variant="adminPrimary"
                onClick={handleLeavePage}
              >
                {t("edit.general.exitModal_confirm")}
              </Button>
            </ButtonGroup>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
