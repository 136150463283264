import {
  meetsExistsWithUserType,
  meetsPermissionLinkType,
  meetsUninstallType,
} from "@/client/types/MeetsService";
import { v3ApiClient } from "../clients/v3ApiClient";

// not sure how to suss this one out
const submitCode = async (code: string): Promise<string> => {
  const response = await v3ApiClient.post<string>(`google-meets/code/`, {
    code: code,
  });

  return response.data;
};

const uninstall = async (): Promise<meetsUninstallType> => {
  const response = await v3ApiClient.post<meetsUninstallType>(
    `google-meets/uninstall/`
  );

  return response.data;
};

const meetsPermissionLink = async (): Promise<meetsPermissionLinkType> => {
  const response = await v3ApiClient.get<meetsPermissionLinkType>(
    `google-meets/permission/`
  );

  return response.data;
};

const existsWithUser = async (): Promise<meetsExistsWithUserType> => {
  const response = await v3ApiClient.get<meetsExistsWithUserType>(
    `google-meets/exists/`
  );

  return response.data;
};

const MeetsService = {
  meetsPermissionLink,
  existsWithUser,
  uninstall,
  submitCode,
};

export default MeetsService;
