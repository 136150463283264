import { Box, Button, Divider, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import PlaceholderImage from "@/client/components/media/images/PlaceholderImage";
import { Purchase } from "@/client/services/api/graphql/gql/graphql";
import ProductCard from "../../data-display/cards/ProductCard";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import { useNavigate } from "react-router-dom";
import ProductBrowseList from "../ecommerce/ProductBrowseList";

export default function DashboardPurchasesList({ userId }: { userId: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["dashboard-puchases", userId],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { in: [userId] },
        subscriptionActive: true,
        page: 0,
        pageSize: 10,
        includeTotalCount: true,
      }),
  });

  return (
    <>
      {!data?.purchases.length && (
        <>
          <Text
            padding={2}
            textAlign="left"
            fontSize="md"
            fontWeight="600"
            color="neutral.1500"
          >
            {t("ecommerce.purchases.noActiveSubscriptions")}
          </Text>
          <Text
            paddingX={2}
            paddingTop={0}
            paddingBottom={2}
            textAlign="left"
            fontSize="sm"
            color="neutral.1500"
          >
            {t("ecommerce.purchases.getStartedBrowsing")}
          </Text>
          <Divider
            border=".5px solid"
            borderColor="neutral.1000"
            marginX={2}
            marginBottom={6}
          />
          <ProductBrowseList />
        </>
      )}

      {data?.purchases && data.purchases.length > 0 && (
        <Stack spacing={4}>
          {data.purchases.map((purchase: Purchase) => {
            const formattedProductDetails = {
              title: purchase.productDetails.title,
              description: purchase.productDetails.description,
              price: purchase.productDetails.price,
              subscription_duration:
                purchase.productDetails.subscriptionDuration,
              _id: purchase.product,
              images: purchase.productDetails.images,
            };

            return (
              <ProductCard
                product={formattedProductDetails}
                managementButton={
                  <Button
                    width={200}
                    variant="adminPrimary"
                    borderRadius={10}
                    _hover={{
                      filter: "brightness(0.9)",
                    }}
                    onClick={() =>
                      navigate(`/manage-subscription/${purchase.id}`)
                    }
                  >
                    {t("ecommerce.productCard.manageSubscription")}
                  </Button>
                }
                onViewPayments={() => navigate("/payment-history")}
                nextBillingDate={purchase.nextScheduledPayment}
                isPurchased
              />
            );
          })}
        </Stack>
      )}
    </>
  );
}
