import { Table, Tbody, Td, Th, Text, Tr } from "@chakra-ui/react";

type CertificateCustomDataProps = {
  metadata: Record<string, string>;
  title: string;
};

export default function CertificateCustomData({
  title,
  metadata,
}: CertificateCustomDataProps) {
  let renderedTableRows: Array<any> = [];
  Object.keys(metadata).map((key) => {
    if (metadata[key]) {
      renderedTableRows.push(
        <Tr key={key}>
          <Th>{key}</Th>
          <Td>{metadata[key].toString()}</Td>
        </Tr>
      );
    }
  });
  return (
    <>
      <Text as="h4">{title}</Text>
      <Table size="sm">
        <Tbody>
          {Object.keys(metadata).map((key) => {
            return (
              <Tr key={key}>
                <Th>{key}</Th>
                <Td>{metadata[key]?.toString()}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}
