import { IoCheckmarkSharp } from "react-icons/io5";
import { getCurrencySign } from "@/client/services/api/graphql/gql-utils";
import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ExploreCardProductDetails } from "@/client/types/ExploreContent";
import { ProductLicensingType } from "@/client/services/api/graphql/gql/graphql";

export default function ProductCardTag({
  price,
  currency,
  purchased,
  duration,
  licensingType,
}: ExploreCardProductDetails) {
  const { t } = useTranslation();

  const subType = ProductLicensingType.Subscription.toLowerCase();
  const permType = ProductLicensingType.Permanent.toLowerCase();

  const getSubscriptionCopy = (dur: string | undefined): string => {
    const periodToTranslation: any = {
      daily: t("products.edit.subscriptionDuration.daily"),
      weekly: t("products.edit.subscriptionDuration.weekly"),
      monthly: t("products.edit.subscriptionDuration.monthly"),
      yearly: t("products.edit.subscriptionDuration.yearly"),
    };
    if (!dur || !periodToTranslation[dur]) return "period";
    return periodToTranslation[dur];
  };

  const buildPrice = () => {
    const currencySign = getCurrencySign(currency);
    if (licensingType === permType) {
      return `${currencySign}${price}`;
    }
    if (licensingType === subType) {
      return `${currencySign}${price} / ${getSubscriptionCopy(duration)}`;
    }
  };

  const getTagCopy = () => {
    if (purchased) {
      let copy = t("products.purchased");
      if (licensingType === subType) {
        copy = t("products.purchased.subscription");
      }
      return copy;
    }
    return buildPrice();
  };

  return (
    <Tag
      size="sm"
      key={price}
      margin="auto 0 auto 0"
      variant={purchased ? "productCardPurchased" : "productCardUnpurchased"}
    >
      {purchased && <TagLeftIcon as={IoCheckmarkSharp} />}
      <TagLabel>{getTagCopy()}</TagLabel>
    </Tag>
  );
}
