import { Question, Page, QuestionJson } from "@/client/types/Surveys";

const addIdToElement = (elementObject: any, idString: string) => {
  return { ...elementObject, _id: idString };
};

// TODO: create question type, choices type, element type, pages type
export const getQuestionsByName = (pages: Page[], questions: Question[]) => {
  let elementIndex = 0;
  const questionsByName: any = {};
  const mapElements = (elements: QuestionJson[]) => {
    for (const element of elements) {
      if (element.elements) {
        mapElements(element.elements);
      } else {
        questionsByName[element.name] = addIdToElement(
          element,
          questions[elementIndex]._id
        );
        elementIndex++;
      }
    }
  };
  for (const page of pages) {
    mapElements(page.elements);
  }
  return questionsByName;
};

export const constructSubmissionResults = (
  responses: any,
  questionsByName: any
) => {
  const results = [];
  const otherResponseQuestions = [];
  for (const questionName in responses) {
    if (questionName.length > 8 && questionName.slice(-8) === "-Comment") {
      otherResponseQuestions.push({
        id: questionsByName[questionName.slice(0, -8)]._id,
        answer: responses[questionName],
      });
      continue;
    }
    results.push({
      question: questionsByName[questionName]._id,
      answer: responses[questionName],
    });
  }
  for (const otherResponseQuestion of otherResponseQuestions) {
    for (const result of results) {
      if (result.question === otherResponseQuestion.id) {
        const otherAnswer = `Other - ${otherResponseQuestion.answer}`;
        if (Array.isArray(result.answer)) {
          result.answer.splice(result.answer.indexOf("other"), 1, otherAnswer);
        } else {
          result.answer = otherAnswer;
        }
      }
    }
  }

  return results;
};
