export const truncateText = (
  str: string,
  minLength: number,
  maxLength: number
) => {
  if (str.length < minLength) return str;

  const truncated = `${str.substring(0, maxLength)}...`;

  return truncated;
};
