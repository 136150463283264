import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ReactElement } from "react";
import { useLanguage } from "@/client/services/hooks/language/useLanguage";
import { useTranslation } from "react-i18next";
import TableSearch from "../input/search/TableSearch";
import TableSearchSelect from "../input/search/TableSearchSelect";

interface AdminTableWrapperProps {
  selectSearch?: boolean;
  selectedItems?: any[];
  setSelected?: (value: any) => void;
  showLanguageSelector?: boolean;
  showSortSelector?: boolean;
  tooltipText?: string;
  rightElement?: ReactElement | null;
  showSearch?: boolean;
  setSearchTerm?: (value: string) => void;
  searchTerm?: string;
  searchData?: any[];
  sortOrder?: "asc" | "desc" | "oldest" | "newest";
  setSortOrder?: (value: "asc" | "desc" | "oldest" | "newest") => void;
  isSearching?: boolean;
  children: ReactElement;
}

export default function AdminTableWrapper({
  selectSearch = false,
  selectedItems = [],
  setSelected = () => {},
  showLanguageSelector = false,
  showSortSelector = false,
  rightElement = null,
  tooltipText = undefined,
  showSearch = true,
  setSearchTerm = undefined,
  searchTerm = "",
  searchData = [],
  sortOrder = "asc",
  setSortOrder = undefined,
  isSearching = false,
  children,
}: AdminTableWrapperProps) {
  const { t } = useTranslation();
  const {
    getAvailableLanguages,
    setSelectedLanguage,
    selectedLanguage,
    defaultLanguage,
  } = useLanguage();

  // If we provide default props, does it not handle undefined values?
  return (
    <Box>
      <Flex
        direction="row"
        mb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        {selectSearch ? (
          <TableSearchSelect
            setSearchTerm={setSearchTerm}
            setSelected={setSelected}
            searchTerm={searchTerm}
            data={searchData}
            selectedItems={selectedItems}
            isSearching={isSearching}
            tooltipText={tooltipText}
          />
        ) : (
          <TableSearch
            showSortSelector={showSortSelector}
            tooltipText={tooltipText}
            setSearchTerm={setSearchTerm}
            showSearch={showSearch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            isSearching={isSearching}
          />
        )}
        <Flex justifyContent="end" width="30%">
          {showLanguageSelector && (
            <Menu variant="admin">
              <MenuButton
                backgroundColor="transparent"
                size="sm"
                color="black"
                textAlign="left"
                fontSize="12px"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                _hover={{ backgroundColor: "brand.grey.10" }}
                _active={{ backgroundColor: "brand.grey.10" }}
              >
                {selectedLanguage
                  ? t(`${selectedLanguage.key}`)
                  : t(`${defaultLanguage.key}`)}
              </MenuButton>

              <MenuList>
                {getAvailableLanguages().map((language) => (
                  <MenuItem
                    key={language.abr}
                    onClick={() => setSelectedLanguage(language)}
                  >
                    {t(`${language.name}`)}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}

          {rightElement}
        </Flex>
      </Flex>
      {children}
    </Box>
  );
}
