/* eslint react/no-unstable-nested-components: 0 */

import {
  Badge,
  Box,
  Button,
  IconButton,
  MenuList,
  Menu,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";
import {
  VerticalEllipsis,
  EditSimple,
  Close,
  Check,
  PlusCircle,
} from "@/client/components/icons/ContinuIcons";
import { Partner } from "@/client/types/Partner";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";

import PartnersService from "@/client/services/api/admin/PartnersService";
import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import {
  DateFormatGroupKey,
  dateFormatTenant,
} from "@/client/utils/dateFormat";
import { useAuthStore } from "@/client/services/state/authStore";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import { useConfigStore } from "@/client/services/state/configStore";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import {
  WIDTH_ACTION_CELL,
  WIDTH_DATE_CELL,
} from "@/client/components/admin/tables/cells/CellSizes";

type MenuProps = {
  partnerId: string;
  active: boolean;
  handleActivation: (id: string) => void;
  handleDeactivation: (id: string) => void;
};

function TableMenu({
  partnerId,
  active,
  handleActivation,
  handleDeactivation,
}: MenuProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Menu variant="adminTableActions">
      <MenuButton
        variant="ghost"
        as={IconButton}
        aria-label="Options"
        icon={<VerticalEllipsis color="brand.teal.100" />}
      />
      <MenuList>
        <MenuItem
          icon={<EditSimple color="brand.teal.100" boxSize={4} />}
          onClick={() => {
            navigate(`/admin/extend/edit/${partnerId}`);
          }}
        >
          {t("global.actions.edit")}
        </MenuItem>
        {active && (
          <MenuItem
            onClick={() => handleDeactivation(partnerId)}
            icon={<Close color="brand.red" boxSize={4} />}
          >
            {t("overview.workflows.manager_deactivate")}
          </MenuItem>
        )}
        {!active && (
          <MenuItem
            onClick={() => handleActivation(partnerId)}
            icon={<Check color="brand.green" boxSize={4} />}
          >
            {t("overview.workflows.manager_activate")}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

function AddPartnerButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const addNewPartner = () => {
    navigate("/admin/extend/create");
  };
  return (
    <Button
      variant="adminPrimary"
      size="xs"
      leftIcon={<PlusCircle />}
      onClick={addNewPartner}
    >
      {t("partners.admin.createNewPartner")}
    </Button>
  );
}

export default function PartnersList() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  useDocumentTitle(t("partners.admin.title"));

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data, refetch } = useQuery({
    queryKey: ["partners-search", searchTerm, fetchDataOptions],
    queryFn: () =>
      PartnersService.searchPartners({
        name: { contains: searchTerm },
        includeTotalCount: true,
        page: fetchDataOptions.pageIndex,
        pageSize: fetchDataOptions.pageSize,
      }),
  });

  const handleActivation = async (id: string) => {
    await PartnersService.updatePartner(id, { active: true });
    refetch();
  };

  const handleDeactivation = async (id: string) => {
    await PartnersService.updatePartner(id, { active: false });
    refetch();
  };

  const columnHelper = createColumnHelper<Partner>();
  const partnersColumns = useMemo<ColumnDefBase<Partner, any>[]>(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Box as="span" marginLeft={9}>
            {info.getValue()}
          </Box>
        ),
        header: () => (
          <Box as="span" marginLeft={9}>
            {t("global.forms.labels_name")}
          </Box>
        ),
        footer: (info) => info.column.id,
        meta: {
          ellipsis: true,
        },
      }),
      columnHelper.accessor("seats", {
        cell: (info) => info.getValue(),
        header: () => <span>{t("userLimits.availableSeats")}</span>,
        footer: (info) => info.column.id,
        size: 140,
      }),
      columnHelper.accessor("active", {
        cell: (info) => (
          <Badge
            borderRadius="5px"
            variant={info.getValue() ? "active" : "inactive"}
            width="70%"
            padding={1}
            textAlign="center"
          >
            {info.getValue() ? "Active" : "Inactive"}
          </Badge>
        ),
        header: () => <span>{t("global.forms.labels_status")}</span>,
        footer: (info) => info.column.id,
        size: 180,
      }),
      columnHelper.accessor("createdAt", {
        cell: (info) => (
          <span>
            {dateFormatTenant(
              new Date(info.getValue()),
              company.date_display as DateFormatGroupKey,
              "base"
            )}
          </span>
        ),

        header: () => <span>Created</span>,
        footer: (info) => info.column.id,
        size: WIDTH_DATE_CELL,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => (
          <TableMenu
            handleActivation={handleActivation}
            handleDeactivation={handleDeactivation}
            partnerId={info.row.original.id}
            active={info.row.original.active}
          />
        ),
        size: WIDTH_ACTION_CELL,
      }),
    ],
    []
  );

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <AdminPrimaryHeader
        title={`${t("overview.label.partners")}`}
        // TODO: Get copy for tooltip from product
        tooltipText={`${t("overview.label.partners")}`}
        showSearch
        setSearchTerm={setSearchTerm}
        rightElement={<AddPartnerButton />}
      />
      <AdminElevatedBox>
        <ManuallyPaginatedTable
          columns={partnersColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
          useTableLayoutFixed
        />
      </AdminElevatedBox>
    </Box>
  );
}
