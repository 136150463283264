import AdminElevatedBox from "../../layout/AdminElevatedBox";
import AdminUsersGroupSearch from "./AdminUsersGroupSearch";
import AdminUsersTeamsSearch from "./AdminUsersTeamsSearch";
import HeadingWithTooltip from "../HeadingWithTooltip";
import { Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ControlledMultiSegmentationFormField from "@/client/components/forms/ControlledMultiSegmentationFormField";
import ControlledSegmentationFormField from "@/client/components/forms/ControlledSegmentationFormField";

export default function Segmentation() {
  const { t } = useTranslation();

  return (
    <AdminElevatedBox>
      <Stack spacing={2}>
        <HeadingWithTooltip
          title={t("edit.general.headlines_segmentation")}
          helpText={t("registrationForms.tooltip.segmentation")}
        />

        <ControlledMultiSegmentationFormField
          fieldName="linked_locations"
          fieldLabel={t("global.forms.labels_location")}
          helpLabel={t("global.forms.labels_location")}
          segmentationType="location"
        />

        <ControlledMultiSegmentationFormField
          fieldName="linked_departments"
          fieldLabel={t("global.forms.labels_department")}
          helpLabel={t("global.forms.labels_department")}
          segmentationType="department"
        />

        <ControlledSegmentationFormField
          fieldName="linked_grade"
          fieldLabel={t("manage.label_grade")}
          helpLabel={t("manage.label_grade")}
          segmentationType="grade"
        />

        <ControlledSegmentationFormField
          fieldName="linked_org_level"
          fieldLabel={t("manage.label_org_level")}
          helpLabel={t("manage.label_org_level")}
          segmentationType="org_level"
        />

        <AdminUsersTeamsSearch />

        <AdminUsersGroupSearch />
      </Stack>
    </AdminElevatedBox>
  );
}
