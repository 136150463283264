import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import {
  getCardBackgroundUrl,
  setCoverImageJustification,
} from "@/client/utils/content/handleCardBackgroundImage";
import { useEffect, useReducer, useState } from "react";

import AuthoringImageActionsButtonGroup from "@/client/components/admin/create/content/buttons/AuthoringImageActionsButtonGroup";
import AuthoringImageDropzone from "@/client/components/admin/create/content/images/AuthoringImageDropzone";
import AuthoringImageEditor from "@/client/components/admin/create/content/images/AuthoringImageEditor";
import type { DefaultTFuncReturn } from "i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { truncateText } from "@/client/utils/text/truncateText";
import { useConfigStore } from "@/client/services/state/configStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useFormContext } from "react-hook-form";
import { useRenderContentIcon } from "@/client/services/hooks/useRenderContentIcon";

interface AuthoringThumbnailUploadProps {
  containerWidth: number | null;
  contentType: "article" | "video" | "file" | "scorm";
  setRenderThumbnailButton: (value: boolean) => void;
  placeholder?: string | DefaultTFuncReturn;
}

export default function AuthoringThumbnailUpload({
  containerWidth,
  contentType,
  setRenderThumbnailButton,
  placeholder = "Title of Content",
}: AuthoringThumbnailUploadProps) {
  const { config } = useConfigStore();
  const cardType = config.features.explore_in_list_view ? "list" : "card";
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { setValue, watch } = useFormContext();
  const { tabIndex, previewFullscreen } = useCreateStore();
  const [editCover, setEditCover] = useState<string>("");
  const [renderImageEditActions, setRenderImageEditActions] =
    useState<boolean>(false);
  const coverImage = watch("image", "");
  const coverImageJustification = watch("cover_justification", "");
  const title = watch("title", "");

  // ! if we end up with more than 4 tabs we'll need to store length in createStore
  const renderCoverImage = (coverImage && coverImage !== "") || tabIndex === 3;

  // ! For whatever reason PDF files aren't triggering an update on the thumbnail. Review this later.
  useEffect(() => {
    if (contentType === "file") {
      setTimeout(() => {
        forceUpdate();
      }, 700);
    }
  }, []);

  const onMouseEnter = () => {
    if (tabIndex === 3) return;

    setRenderImageEditActions(true);
  };

  const onMouseLeave = () => {
    if (tabIndex === 3) return;

    setRenderImageEditActions(false);
  };

  return (
    <Box
      position="fixed"
      zIndex={99}
      bottom="16px"
      left={
        containerWidth
          ? previewFullscreen
            ? containerWidth - 220
            : containerWidth - 185
          : 60
      }
      onMouseEnter={() => setRenderThumbnailButton(true)}
      onMouseLeave={() => setRenderThumbnailButton(false)}
    >
      {cardType === "card" && (
        <Box
          boxShadow="xl"
          padding={4}
          height="300px"
          width="250px"
          background={`linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${
            coverImage
              ? `url('${getCardBackgroundUrl(coverImage)}')`
              : `url(/public/images/cardPlaceholder.png)`
          }`}
          backgroundSize="cover"
          backgroundPosition={`${setCoverImageJustification(
            coverImageJustification
          )} top`}
          zIndex={1}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Flex height="full" direction="column" justifyContent="space-between">
            <Stack zIndex={3} color="white">
              <Box
                width="40px"
                height="40px"
                borderRadius="60px"
                border="2px solid white"
                alignItems="center"
                display="flex"
                justifyContent="center"
              >
                {useRenderContentIcon(contentType)}
              </Box>

              <Text fontSize="18px" fontWeight={600} color="white">
                {truncateText(title, 42, 39) || placeholder}
              </Text>
            </Stack>

            {!renderCoverImage && (
              <DndProvider backend={HTML5Backend}>
                <AuthoringImageDropzone
                  type="thumbnail-card"
                  setImage={setEditCover}
                />
              </DndProvider>
            )}

            {coverImage && renderImageEditActions && (
              <AuthoringImageActionsButtonGroup
                onEdit={() => setEditCover(coverImage)}
                onRemove={() => setValue("image", "")}
              />
            )}
          </Flex>
        </Box>
      )}

      {cardType === "list" && (
        <Box
          height={250}
          width={250}
          borderRadius="lg"
          boxShadow="xl"
          backgroundColor="white"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {renderCoverImage && (
            <Box
              borderTopRadius="lg"
              height={125}
              width="full"
              background={`linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${
                coverImage
                  ? `url('${getCardBackgroundUrl(coverImage)}')`
                  : `url(/public/images/cardPlaceholder.png)`
              }`}
              backgroundSize="cover"
              backgroundPosition={`${setCoverImageJustification(
                coverImageJustification
              )} top`}
            >
              <Flex
                height="full"
                width="full"
                alignItems="center"
                justifyContent="center"
              >
                {renderImageEditActions && (
                  <AuthoringImageActionsButtonGroup
                    onEdit={() => setEditCover(coverImage)}
                    onRemove={() => setValue("image", "")}
                  />
                )}
              </Flex>
            </Box>
          )}

          {!renderCoverImage && (
            <Box paddingTop={4}>
              <DndProvider backend={HTML5Backend}>
                <AuthoringImageDropzone
                  type="thumbnail-list"
                  setImage={setEditCover}
                />
              </DndProvider>
            </Box>
          )}

          <Stack marginTop={2} padding={4}>
            <Box
              width="25px"
              height="25px"
              borderRadius="60px"
              border="1px solid"
              borderColor="brand.legibleBlack.100"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              {useRenderContentIcon(contentType)}
            </Box>

            <Box>
              <Text
                fontSize="16px"
                fontWeight={600}
                color="brand.legibleBlack.100"
              >
                {truncateText(title, 42, 39) || placeholder}
              </Text>
            </Box>
          </Stack>
        </Box>
      )}

      <AuthoringImageEditor
        image={editCover}
        type="cover"
        setImage={setEditCover}
        setFormValue={(cloudfrontLink: string) =>
          setValue("image", cloudfrontLink)
        }
      />
    </Box>
  );
}
