import { Box, Tag } from "@chakra-ui/react";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactElement, useMemo, useState } from "react";

import EmptyTable from "@/client/components/data-display/emptyTable/EmptyTable";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import TableActionsMenu from "@/client/components/admin/menus/TableActionsMenu";
import { WebhooksIntegrationsArchiveTypes } from "@/client/types/Integrations";
import { format } from "date-fns";
import { useIntegrationService } from "@/client/services/hooks/admin/connect/useIntegrationService";
import { useTranslation } from "react-i18next";

const renderTableOptions = (unArchiveWebhook: any, info: any) => (
  <TableActionsMenu
    onUnArchive={() => {
      unArchiveWebhook(info.row.original._id);
    }}
  />
);

export default function WebhooksIntegrationsList({
  getWebhooks,
}: WebhooksIntegrationsArchiveTypes) {
  const { t } = useTranslation();
  const { unArchiveWebhook } = useIntegrationService();
  const [rowSelection, setRowSelection] = useState<any>();

  const setTagBgColor = (status: string) => {
    if (status === "Inactive") return "orange";
    if (status === "Active") return "green";
    if (status === "Invalid") return "red";
    if (status === "Errors") return "red";
    return "gray.500";
  };

  const renderStatusTag = (enabled: boolean, valid: boolean) => {
    let status = "Unavailable";
    if (!enabled && valid) status = "Inactive";
    if (enabled && valid) status = "Active";
    if (!enabled && !valid) status = "Invalid";
    if (enabled && !valid) status = "Errors";
    return (
      <Tag
        backgroundColor={setTagBgColor(status)}
        fontWeight="bold"
        color="white"
        padding={1}
        width="100%"
        textAlign="center"
        display="inline-block"
      >
        {status}
      </Tag>
    );
  };

  interface WebhookRegistrationType {
    status: string;
    _id: string;
    name: string;
    url: string;
    enabled: boolean;
    updatedAt: string;
    valid: boolean;
  }

  const columnHelper = createColumnHelper<WebhookRegistrationType>();

  const columns = useMemo<ColumnDefBase<WebhookRegistrationType, any>[]>(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: () => t("webhooks.name"),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("url", {
        cell: (info) => info.getValue(),
        header: () => t("webhooks.url"),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("enabled", {
        cell: (info) =>
          (
            <Box>
              {renderStatusTag(info.getValue(), info.row.original.valid)}
            </Box>
          ) as ReactElement,

        header: () => (<span>{t("webhooks.status")}</span>) as ReactElement,
        footer: (info) => info.column.id,
        // size: 0,
        // minSize: 120,
      }),
      columnHelper.accessor("updatedAt", {
        cell: (info) => format(new Date(info.getValue()), "MM-dd-yyyy"),
        header: () => t("webhooks.updatedAt"),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => renderTableOptions(unArchiveWebhook, info),
        size: 0,
        minSize: 0,
      }),
    ],
    []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const dataQuery: any = getWebhooks(true);

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    // @ts-ignore
    data: dataQuery.data?.rows ?? defaultData,
    // @ts-ignore
    columns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  return (
    <>
      {table.getRowModel().rows.length === 0 && (
        <EmptyTable label={t("webhooks.empty")} />
      )}
      {table.getRowModel().rows.length > 0 && (
        <Box borderRadius={8}>
          <ManuallyPaginatedTable
            columns={columns}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            queryData={dataQuery.data}
            pagination={pagination}
            setPagination={setPagination}
            showCheckbox={false}
            hidePagination
          />
        </Box>
      )}
    </>
  );
}
