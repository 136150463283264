/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */

import {
  Avatar,
  Box,
  Center,
  Flex,
  Link,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { Fragment } from "react";
import { InfiniteData } from "@tanstack/react-query";
import { Person } from "@/client/types/Person";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

type UserListProps = {
  people: InfiniteData<Person[]> | undefined;
};

export default function UserList({ people }: UserListProps) {
  const { t } = useTranslation();

  if (!people || people.pages[0].length === 0)
    return (
      <Center marginTop={8}>{t("explore.peoplesearch.no_results")}</Center>
    );

  return (
    <Box paddingX={4}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th
                display={{ base: "block", lg: "table-cell" }}
                width={{ base: "100%", lg: "auto" }}
                textTransform="capitalize"
              >
                Name
              </Th>
              <Th
                display={{ base: "block", lg: "table-cell" }}
                width={{ base: "100%", lg: "auto" }}
                textTransform="capitalize"
              >
                Job Title
              </Th>
              <Th
                display={{ base: "block", lg: "table-cell" }}
                width={{ base: "100%", lg: "auto" }}
                textTransform="capitalize"
              >
                Department
              </Th>
              <Th
                display={{ base: "block", lg: "table-cell" }}
                width={{ base: "100%", lg: "auto" }}
                textTransform="capitalize"
              >
                Location
              </Th>
              <Th
                display={{ base: "block", lg: "table-cell" }}
                width={{ base: "100%", lg: "auto" }}
                borderColor={{ base: "gray.400", lg: "gray.100" }}
                textTransform="capitalize"
              >
                Skills
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {people?.pages.map((page, i) => (
              <Fragment key={i}>
                {page.map((person) => (
                  <Tr key={person._id}>
                    <Td
                      display={{ base: "block", lg: "table-cell" }}
                      width={{ base: "100%", lg: "auto" }}
                    >
                      <Flex alignItems="center">
                        <Avatar
                          name={person.full_name}
                          src={person.image}
                          size="sm"
                          marginRight={4}
                          color="white"
                          backgroundColor="blackAlpha.300"
                        />
                        <Link as={RouterLink} to={`/profile/${person._id}`}>
                          {person.full_name}
                        </Link>
                      </Flex>
                    </Td>

                    <Td
                      display={{ base: "block", lg: "table-cell" }}
                      width={{ base: "100%", lg: "auto" }}
                    >
                      {person.job_title}
                    </Td>

                    <Td
                      display={{ base: "block", lg: "table-cell" }}
                      width={{ base: "100%", lg: "auto" }}
                    >
                      {person.linked_departments?.length > 0 &&
                        person.linked_departments[0].name}
                    </Td>

                    <Td
                      display={{ base: "block", lg: "table-cell" }}
                      width={{ base: "100%", lg: "auto" }}
                    >
                      {person.linked_locations?.length > 0 &&
                        person.linked_locations[0].name}
                    </Td>

                    <Td
                      display={{ base: "block", lg: "table-cell" }}
                      width={{ base: "100%", lg: "auto" }}
                      borderColor={{ base: "gray.400", lg: "gray.100" }}
                    >
                      {person.skills &&
                        person.skills.length > 0 &&
                        person.skills.map((skill: any, index) => {
                          if (index < 2) {
                            return (
                              <Tag variant="explore" key={skill._id}>
                                <TagLabel>{skill.name}</TagLabel>
                              </Tag>
                            );
                          }
                        })}

                      {person.skills && person.skills.length > 1 && (
                        <Tag variant="explore">
                          <TagLabel>+ {person.skills.length - 2}</TagLabel>{" "}
                        </Tag>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Fragment>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
