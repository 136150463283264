/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Textarea,
} from "@chakra-ui/react";

import ContentFormTextGroup from "../create/content/text/ContentFormTextGroup";
import { DefaultTFuncReturn } from "i18next";

interface ContentTextAreaGroupProps {
  label: string;
  helpText?: string | DefaultTFuncReturn;
  formName: string;
  isRequired?: boolean;
  placeholder?: string | DefaultTFuncReturn;
}

export default function ContentTextAreaGroup({
  label,
  helpText = undefined,
  formName,
  isRequired = false,
  placeholder = "Add a description...",
}: ContentTextAreaGroupProps) {
  const { control, formState, setValue } = useFormContext();

  const { errors } = formState;
  const hasError = !!errors[formName];

  return (
    <Stack spacing={6} marginY={8}>
      <ContentFormTextGroup label={label} helpText={helpText} />

      <Controller
        name={formName}
        control={control}
        rules={{
          required: {
            value: isRequired || false,
            message: "This field is required!!!",
          },
        }}
        render={({ field }) => (
          <FormControl isInvalid={hasError}>
            <Textarea
              {...field}
              onChange={(e) => setValue(formName, e.target.value)}
              placeholder={placeholder as string}
              isRequired={isRequired}
              variant="create"
            />

            {hasError && errors[formName]?.message && (
              <Flex width="full" justifyContent="right">
                <FormErrorMessage>
                  {errors[formName]?.message as string}
                </FormErrorMessage>
              </Flex>
            )}
          </FormControl>
        )}
      />
    </Stack>
  );
}
