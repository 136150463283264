import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  useToken,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Loading from "@/client/components/media/Loading";
import SlateEditor from "../../input/SlateEditor";
import User from "@/client/services/api/User";
import { getSupportedLanguages } from "@/client/utils/getSupportedLanguages";
import { useAuthStore } from "@/client/services/state/authStore";
import { useGetAuthToken } from "@/client/services/hooks/useGetAuthToken";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export default function UserDetailsForm() {
  const { authConfig, setAuthConfig } = useAuthStore();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const { company } = authConfig;
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "language.english",
    key: "language.english",
    abr: "en",
    value: "en",
  });
  const [about, setAbout] = useState<string>("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userId, setUserId] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.abr);
  }, [selectedLanguage]);

  const supportedLanguages = getSupportedLanguages();

  const bioPlaceholder = t("global.forms.labels_includeBio");

  // TODO: Replace this with authConfig
  const {
    isLoading: isAuthLoading,
    isError: isAuthError,
    data: authData,
  } = useGetAuthToken();

  const inputStyle = {
    marginTop: useToken("sizes", [0])[0],
  };

  const updateUserDetails = useMutation({
    mutationFn: () =>
      User.updateDetails(
        userId,
        about,
        firstName,
        selectedLanguage.value,
        lastName
      ),
    onSuccess: (data) => {
      setAuthConfig({
        ...authConfig,
        user: {
          ...authConfig.user,
          first_name: data.first_name,
          last_name: data.last_name,
          language: data.language,
          about: data.about,
        },
      });
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.profile_updated"),
      });
    },
    onError: (error) => {
      console.error("Error updating user details: ", error);

      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdateProfile"),
      });
    },
  });

  const sanitizeLanguage = (lang: string) => {
    if (lang === "eng") {
      return "en";
    }
    if (lang === "spa") {
      return "es_419";
    }
    return lang;
  };

  useEffect(() => {
    if (authData) {
      const lang = supportedLanguages.find(
        (l) => l.value === sanitizeLanguage(authData.user.language)
      );
      if (lang) setSelectedLanguage(lang);

      if (authData.user.about) {
        setAbout(authData.user.about);
      } else {
        setAbout("<p><br /></p>");
      }

      setFirstName(authData.user.first_name);
      setLastName(authData.user.last_name);
      setUserId(authData.user._id);
      setShowEmail(!authData.user.email.startsWith("noemail__"));
    }
  }, [authData]);

  useEffect(() => {
    if (authData) {
      if (
        authData.user.language !== selectedLanguage.value ||
        authData.user.about !== about ||
        authData.user.first_name !== firstName ||
        authData.user.last_name !== lastName
      ) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  }, [selectedLanguage, about, firstName, lastName]);

  if (isAuthLoading) return <Loading />;

  if (isAuthError) return <div>Error loading user details</div>;

  return (
    <>
      <Heading
        variant="h3"
        fontSize="sm"
        textTransform="uppercase"
        marginTop="0"
      >
        {t("userProfileEdit.yourDetails")}
      </Heading>

      <Flex direction={{ base: "column", md: "row" }} paddingTop={2}>
        <Box width={{ base: "100%", md: "50%" }} paddingRight={6}>
          <Stack spacing={4}>
            <>
              <Text variant="formlabel">
                {t("global.forms.labels_firstName")}
              </Text>
              <Input
                style={inputStyle}
                disabled={!company.feature_flags.enable_user_name_edit}
                variant="flushed"
                placeholder={authData.user.first_name}
                _disabled={{
                  opacity: 0.7,
                  color: "blackAlpha.500",
                  fontWeight: "500",
                  cursor: "not-allowed",
                }}
                name="firstName"
                onChange={(event) => setFirstName(event.target.value)}
              />

              <Text variant="formlabel">
                {t("global.forms.labels_lastName")}
              </Text>
              <Input
                style={inputStyle}
                disabled={!company.feature_flags.enable_user_name_edit}
                variant="flushed"
                placeholder={authData.user.last_name}
                _disabled={{
                  opacity: 0.7,
                  color: "blackAlpha.500",
                  fontWeight: "500",
                  cursor: "not-allowed",
                }}
                name="lastName"
                onChange={(event) => setLastName(event.target.value)}
              />
            </>

            {showEmail && (
              <>
                <Text variant="formlabel">
                  {t("global.forms.labels_emailAddress")}
                </Text>
                <Input
                  style={inputStyle}
                  disabled
                  _disabled={{
                    opacity: 0.7,
                    color: "blackAlpha.500",
                    fontWeight: "500",
                    cursor: "not-allowed",
                  }}
                  variant="flushed"
                  placeholder={authData.user.email}
                />
              </>
            )}
            <>
              <Text variant="formlabel">{t("manage.users.language")}</Text>
              <Select
                variant="flushed"
                onChange={(e) => {
                  const lang = supportedLanguages.find(
                    (l) => l.value === e.target.value
                  );
                  if (lang) setSelectedLanguage(lang);
                }}
                value={selectedLanguage.value}
              >
                <option>{t("manage.users.language")}</option>

                {authData.company.available_languages.map((language) => {
                  const lang = supportedLanguages.find(
                    (l) => l.value === sanitizeLanguage(language)
                  );

                  if (lang) {
                    return (
                      <option key={lang.value} value={lang.value}>
                        {t(lang.key)}
                      </option>
                    );
                  }
                  return null;
                })}
              </Select>
            </>

            {showButton && (
              <Button
                isLoading={updateUserDetails.isLoading}
                onClick={() => updateUserDetails.mutate()}
              >
                Update Details
              </Button>
            )}
          </Stack>
        </Box>

        <Box
          width={{ base: "100%", md: "50%" }}
          paddingLeft={{ base: 0, lg: 6 }}
          paddingTop={{ base: 6, lg: 0 }}
        >
          {about && (
            <>
              <Text variant="formlabel">{t("userProfileEdit.aboutYou")}</Text>

              <Box
                border="1px"
                borderColor="blackAlpha.500"
                padding={4}
                borderRadius="4px"
                minHeight="150px"
                marginTop={2}
              >
                <SlateEditor
                  value={about}
                  setAbout={setAbout}
                  placeholder={bioPlaceholder}
                />
              </Box>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
}
