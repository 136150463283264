import {
  CLIENT_CREATION_KEY_ANALYZE,
  CLIENT_CREATION_KEY_API,
  CLIENT_CREATION_KEY_GRAPHQL,
  CLIENT_CREATION_KEY_REPORTS,
  CLIENT_CREATION_KEY_OPENAI,
  updateClients,
} from "./clients/clientRegister";

import { configApiClient } from "./clients/configApiClient";
import { setLearnAppActivityTTL } from "../state/authStore";

const getConfig = async (hostname: string) => {
  const response = await configApiClient.get(`/v3/config`);

  updateClients(CLIENT_CREATION_KEY_ANALYZE, response.data.analyzeUrl);
  updateClients(CLIENT_CREATION_KEY_API, response.data.apiUrl);
  updateClients(CLIENT_CREATION_KEY_GRAPHQL, response.data.apiUrl);
  updateClients(CLIENT_CREATION_KEY_REPORTS, response.data.reportsUrl);
  updateClients(CLIENT_CREATION_KEY_OPENAI, response.data.apiUrl);
  setLearnAppActivityTTL(response.data.learnAppActivityTTL);

  return {
    ...response.data,
  };
};

const ConfigService = {
  getConfig,
};

export default ConfigService;
