import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  VStack,
  useToken,
} from "@chakra-ui/react";
import {
  ArrowLeft,
  Minus,
  Plus,
  QuestionCircle,
  Upload,
  View,
} from "@/client/components/icons/ContinuIcons";
import { ComponentType, useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import {
  FieldName,
  FormField,
  SegmentationField,
  UpdateFormBody,
  UserType,
  ValidateFieldBody,
} from "@/client/types/RegistrationForms";
import type { Identifier, XYCoord } from "dnd-core";
import { useNavigate, useParams } from "react-router-dom";

import { AxiosError } from "axios";
import DraggableField from "@/client/components/forms/registration-forms/DraggableField";
import DraggableFieldAddLanding from "@/client/components/forms/registration-forms/DraggableFieldAddLanding";
import { HTML5Backend } from "react-dnd-html5-backend";
import Preview from "@/client/components/forms/registration-forms/RegistrationFormPreview";
import RegistrationFormsHeader from "@/client/components/layout/RegistrationFormsHeader";
import RegistrationFormsService from "@/client/services/api/RegistrationFormsService";
import SegmentationForm from "@/client/components/forms/registration-forms/SegmentationForm";
import ValidateRequiredForm from "@/client/components/forms/registration-forms/ValidateRequiredFieldForm";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import update from "immutability-helper";
import { useAuthStore } from "@/client/services/state/authStore";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

type InputFieldProps = {
  fieldName: string;
  fieldData?: FormField | SegmentationField;
  FieldForm: ComponentType<any>;
  index: number;
  removeField?: (identifyingFieldName: FieldName) => void;
  updateField?: (
    updateBody: ValidateFieldBody,
    identifyingFieldName: FieldName
  ) => void;
  moveField?: (dragIndex: number, hoverIndex: number) => void;
};

type SegmentationFieldProps = {
  initialData: SegmentationField;
  updateSegmentation: (value: SegmentationField) => void;
  externalUsersEnabled: boolean;
  userType: UserType;
  updateUserType: (userType: UserType) => void;
  selectedPartner: string | undefined;
  setSelectedPartner: (partner: string) => void;
};

type RequiredFieldProps = {
  fieldName: string;
  helpText: string;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function FormDropdown({
  fieldName,
  fieldData,
  FieldForm,
  index,
  removeField,
  updateField,
  moveField,
}: InputFieldProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "ACCORDIONFIELD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveField!(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "ACCORDIONFIELD",
    // TODO Typeguard this correctly.
    item: () => ({ id: (fieldData as FormField).name, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <AccordionItem
      ref={ref}
      key={fieldName}
      marginRight={4}
      backgroundColor="brand.hightlight"
      borderRadius="10px"
      marginBottom={1}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <Box flex="1" textAlign="left" color="white" fontWeight="600">
              {fieldName}
            </Box>
            {isExpanded ? <Minus color="white" /> : <Plus color="white" />}
          </AccordionButton>
          <AccordionPanel
            pb={4}
            backgroundColor="white"
            borderBottomRadius="10px"
          >
            <FieldForm
              updateField={updateField}
              fieldData={fieldData}
              removeField={removeField}
            />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

function SegmentationDropdown({
  initialData,
  updateSegmentation,
  externalUsersEnabled,
  userType,
  updateUserType,
  selectedPartner,
  setSelectedPartner,
}: SegmentationFieldProps) {
  const { t } = useTranslation();

  const overrideUpdateUserType = (newUserType: UserType) => {
    updateUserType(newUserType);
    if (newUserType === "external") {
      setSelectedPartner("");
    }
  };

  return (
    <AccordionItem
      marginRight={4}
      backgroundColor="brand.highlight"
      borderRadius="10px"
      marginBottom={3}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <Box flex="1" textAlign="left" color="white" fontWeight="600">
              {externalUsersEnabled
                ? t("registrationForms.label.userTypeAndSegmentation")
                : t("edit.general.headlines_segmentation")}
              <Tooltip
                hasArrow
                label={
                  externalUsersEnabled
                    ? t("registrationForms.tooltip.userTypeAndSegmentation")
                    : t("registrationForms.tooltip.segmentation")
                }
              >
                <QuestionCircle marginLeft={1} />
              </Tooltip>
            </Box>
            {isExpanded ? <Minus color="white" /> : <Plus color="white" />}
          </AccordionButton>
          <AccordionPanel
            pb={4}
            backgroundColor="white"
            borderBottomRadius="10px"
          >
            <SegmentationForm
              updateField={updateSegmentation}
              fieldData={initialData}
              externalUsersEnabled={externalUsersEnabled}
              userType={userType}
              updateUserType={overrideUpdateUserType}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

function RequiredField({ fieldName, helpText }: RequiredFieldProps) {
  const [brandHighlight] = useToken("colors", ["brand.highlight"]);

  return (
    <AccordionItem
      isDisabled
      marginRight={4}
      marginBottom={1}
      backgroundColor={hexToRGBA(brandHighlight, 0.9)}
      borderRadius="10px"
    >
      <AccordionButton _disabled={{ opacity: "0.8" }}>
        <Box flex="1" textAlign="left" color="white" fontWeight="600">
          {fieldName}
          <Tooltip hasArrow label={helpText}>
            <QuestionCircle marginLeft={1} />
          </Tooltip>
        </Box>
      </AccordionButton>
    </AccordionItem>
  );
}

const getDefaultSegmentationValues = () => ({
  location: [],
  department: [],
  team: [],
  level: "",
  grade: "",
  group: [],
});

export default function RegistrationFormsEditor() {
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const routeParams = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (routeParams.id) {
    useDocumentTitle("Edit Registration Form");
  } else {
    useDocumentTitle("Create Registration Form");
  }

  const [formName, setFormName] = useState<string>("");
  const [formCode, setFormCode] = useState<string>("");
  const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false);
  const [addedFields, setAddedFields] = useState<FormField[]>([]);
  const [canPublish, setCanPublish] = useState<boolean>(false);
  const [cantPublishReasons, setCantPublishReasons] = useState<string[]>([]);
  const [userType, setUserType] = useState<UserType>("user");
  const [selectedPartner, setSelectedPartner] = useState<string | undefined>(
    undefined
  );
  const [segmentation, setSegmentation] = useState<SegmentationField>(
    getDefaultSegmentationValues()
  );

  const requiredFields: FormField[] = [
    {
      name: "first_name",
      title: "first name",
      input_type: "text",
      description: "First Name",
      validations: [
        {
          validation: "required",
        },
      ],
      maps_to_write_type: "user_core",
      maps_to_field_name: "first_name",
    },
    {
      name: "last_name",
      title: "last name",
      input_type: "text",
      description: "Last Name",
      validations: [
        {
          validation: "required",
        },
      ],
      maps_to_write_type: "user_core",
      maps_to_field_name: "last_name",
    },
    {
      name: "email",
      title: "email",
      input_type: "text",
      description: "Email",
      validations: [
        {
          validation: "required",
        },
      ],
      maps_to_write_type: "user_core",
      maps_to_field_name: "email",
    },
  ];

  const draggableFieldOptions: FormField[] = [
    {
      name: "job_title",
      title: "job title",
      input_type: "text",
      description: "Job Title",
      maps_to_write_type: "user_core",
      maps_to_field_name: "job_title",
    },
    {
      name: "language",
      title: "language",
      input_type: "dropdown",
      description: "Language",
      maps_to_write_type: "user_core",
      maps_to_field_name: "language",
    },
    {
      name: "userid",
      title: "user id",
      input_type: "text",
      description: "User ID",
      maps_to_write_type: "user_core",
      maps_to_field_name: "userid",
    },
  ];

  const { isLoading, data: formData } = useQuery({
    queryKey: ["registration-forms", routeParams.id],
    queryFn: () => RegistrationFormsService.getForm(routeParams.id!),
    enabled: !!routeParams.id,
  });

  // Checks returned fields against required fields to see if there are additional optional fields, returns empty array if not
  const getExistingOptionalFields = (fields: FormField[]) =>
    fields.filter(
      (field) =>
        !requiredFields.find(
          (requiredField) => requiredField.name === field.name
        )
    );

  useEffect(() => {
    if (formData) {
      setFormName(formData.title);
      setFormCode(formData.code);
      setUserType(formData.role);
      setSelectedPartner(formData.partner);
      formData.segmentations && setSegmentation(formData.segmentations);
      const existingOptionalFields = getExistingOptionalFields(formData.fields);
      if (existingOptionalFields.length) {
        setAddedFields(existingOptionalFields);
      }
    }
  }, [formData]);

  const validateForm = () => {
    let isValid = true;
    const reasons = [];
    if (!formName) {
      isValid = false;
      reasons.push(t("Please set a form name"));
    }
    if (!formCode) {
      isValid = false;
      reasons.push(t("Please set a form code"));
    }
    setCanPublish(isValid);
    setCantPublishReasons(reasons);
  };

  useEffect(() => {
    validateForm();
  }, [formName, formCode]);

  const validateAndSetFormCode = (code: string) => {
    const lowercaseCode = code.toLowerCase();
    setFormCode(lowercaseCode);
    validateForm();
  };

  const createForm = async () => {
    const createBody = {
      title: formName,
      code: formCode,
      role: userType,
      active: true,
      archived: false,
      segmentations: segmentation,
      fields: [...requiredFields, ...addedFields],
      description: "description",
      partner: selectedPartner,
      // TODO: Change with refactor
      type: "registration_form",
    };
    // Extend Accounts don't have external users.
    if (createBody.role === "external") {
      createBody.partner = undefined;
    }
    // Extend Accounts don't have custom segmentation.
    if (selectedPartner) {
      createBody.segmentations = getDefaultSegmentationValues();
    }
    try {
      // @ts-ignore
      await RegistrationFormsService.createForm(createBody);
      setToast({
        show: true,
        status: "success",
        title: t("registrationForms.publish_success"),
      });
      navigate(`/admin/connect/registration-forms`);
    } catch (e) {
      const error = e as AxiosError<{ message?: string }>;
      if (error.response?.data?.message === "Cannot reuse form code") {
        setToast({
          show: true,
          status: "error",
          title: t("registrationForms.publish_error_duplicate_code"),
        });
      } else if (error.response?.data?.message === "Cannot reuse form title") {
        setToast({
          show: true,
          status: "error",
          title: t("registrationForms.publish_error_duplicate_title"),
        });
      } else {
        setToast({
          show: true,
          status: "error",
          title: t("registrationForms.publish_error"),
        });
      }
    }
  };

  const updateForm = async () => {
    const updateBody: UpdateFormBody = {
      title: formName,
      segmentations: segmentation,
      code: formCode,
      role: userType,
      fields: [...requiredFields, ...addedFields],
      partner: selectedPartner,
    };

    try {
      // Extend Accounts don't have external users.
      if (updateBody.role === "external") {
        updateBody.partner = undefined;
      }
      // Extend Accounts don't have custom segmentation.
      if (selectedPartner) {
        updateBody.segmentations = getDefaultSegmentationValues();
      }
      await RegistrationFormsService.updateForm(routeParams.id!, updateBody);
      setToast({
        show: true,
        status: "success",
        title: t("registrationForms.publish_success"),
      });
    } catch (e) {
      const error = e as AxiosError<{ message?: string }>;
      if (error.response?.data?.message === "Cannot reuse form code") {
        setToast({
          show: true,
          status: "error",
          title: t("registrationForms.publish_error_duplicate_code"),
        });
      } else if (error.response?.data?.message === "Cannot reuse form title") {
        setToast({
          show: true,
          status: "error",
          title: t("registrationForms.publish_error_duplicate_title"),
        });
      } else {
        setToast({
          show: true,
          status: "error",
          title: t("registrationForms.publish_error"),
        });
      }
    }
  };

  const updateValidationField = (
    updateBody: ValidateFieldBody,
    identifyingFieldName: string
  ) => {
    // Creates Copy of addedFields
    const addedFieldsCopy = addedFields.slice();

    // Finds selected field
    const currentField = addedFieldsCopy.find(
      (field) => field.name === identifyingFieldName
    );
    if (!currentField) {
      return;
    }

    // If update is adding new validation, either add it to existing or create validation field
    if (updateBody.addValidation) {
      if (currentField.validations) {
        currentField.validations.push({ validation: updateBody.validation });
      } else {
        currentField.validations = [{ validation: updateBody.validation }];
      }
      setAddedFields(addedFieldsCopy);
      return;
    }

    // If removing existing validation, finds validation and removes from array
    if (currentField.validations) {
      const currentFieldValidation = currentField.validations.find(
        (validation) => validation.validation === updateBody.validation
      );
      if (!currentFieldValidation) {
        return;
      }
      currentField.validations.splice(
        currentField.validations.indexOf(currentFieldValidation),
        1
      );
    }
    setAddedFields(addedFieldsCopy);
  };

  const addNewField = (field: FormField) => {
    setAddedFields((previousAddedFields: FormField[]) => {
      const addedFieldsCopy = addedFields.slice();

      addedFieldsCopy.push(field);

      return [...previousAddedFields, field];
    });
  };

  const removeField = (identifyingFieldName: string) => {
    setAddedFields((previousAddedFields: FormField[]) => {
      const addedFieldsCopy = previousAddedFields.slice();
      const selectedFieldToRemove = addedFieldsCopy.find(
        (field) => field.name === identifyingFieldName
      );

      if (!selectedFieldToRemove) {
        return [...addedFieldsCopy];
      }

      addedFieldsCopy.splice(addedFieldsCopy.indexOf(selectedFieldToRemove), 1);
      return [...addedFieldsCopy];
    });
  };

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = addedFields[dragIndex];
      setAddedFields(
        update(addedFields, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [addedFields]
  );

  if (isLoading && routeParams.id) {
    return <div>Loading...</div>;
  }

  return (
    <Box paddingTop={4} backgroundColor="brand.mist">
      <RegistrationFormsHeader />
      <DndProvider backend={HTML5Backend}>
        <Box
          paddingY={4}
          paddingX={6}
          backgroundColor="white"
          margin={4}
          marginY={4}
          borderRadius="10px"
          boxShadow="0 2px 2px rgba(0,0,0,0.1)"
        >
          <Flex
            direction={{ base: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="adminPrimary"
              padding={3}
              height={6}
              width="fit-content"
              borderRadius="12px"
              fontSize="sm"
              // TODO: Add Modal to check for save on leaving
              onClick={() => {
                navigate(`/admin/connect/registration-forms`);
              }}
            >
              <ArrowLeft marginRight={2} />
              {t("global.actions.back")}
            </Button>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="225px"
            >
              <Button
                variant="adminPrimary"
                padding={3}
                height={6}
                width="fit-content"
                borderRadius="12px"
                fontSize="sm"
                // TODO: Add preview
                onClick={() => setPreviewIsOpen(true)}
              >
                <View marginRight={2} boxSize={5} />
                {t("edit.general.sidebar_preview")}
              </Button>
              <Button
                variant="adminPrimary"
                padding={3}
                height={6}
                width="fit-content"
                borderRadius="12px"
                fontSize="sm"
                backgroundColor="green"
                _hover={{ background: "" }}
                isDisabled={!canPublish}
                title={cantPublishReasons.join(", ")}
                onClick={routeParams.id ? updateForm : createForm}
              >
                <Upload marginRight={2} />
                {t("global.actions.publish")}
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Flex
          direction={{ base: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <Flex
            direction="column"
            style={{
              top: "60px",
              position: "sticky",
              alignSelf: "flex-start",
              overflowY: "auto",
              height: "calc(100vh - 60px)",
            }}
          >
            <Box
              padding={6}
              backgroundColor="white"
              margin={4}
              marginTop="0"
              borderRadius="10px"
              height="197px"
              width="233px"
            >
              <Text
                fontSize="md"
                lineHeight="1.2"
                fontWeight="600"
                marginBottom={2}
              >
                {t("registrationForms.formSettings")}
              </Text>
              <Text mb={1} fontSize="sm" fontWeight="500">
                {t("registrationForms.label.formName")}
              </Text>
              <InputGroup>
                <Input
                  placeholder="Ex. Form 1"
                  size="sm"
                  border="none"
                  background="#F9F9F9"
                  borderRadius="5px"
                  color="#1A1A1A"
                  _placeholder={{
                    color: "#1A1A1A99",
                  }}
                  value={formName}
                  onChange={(e) => setFormName(e.target.value)}
                />
                <InputRightElement height={8}>
                  <Tooltip
                    hasArrow
                    label={t("registrationForms.tooltip.formName")}
                  >
                    <QuestionCircle color="#1A1A1A80" />
                  </Tooltip>
                </InputRightElement>
              </InputGroup>
              <Text marginY={1} fontSize="sm" fontWeight="500">
                {t("registrationForms.label.code")}
              </Text>
              <InputGroup>
                <Input
                  placeholder="Ex. CODE12345678"
                  size="sm"
                  border="none"
                  background="#F9F9F9"
                  borderRadius="5px"
                  color="#1A1A1A"
                  _placeholder={{
                    color: "#1A1A1A99",
                  }}
                  value={formCode}
                  onChange={(e) => validateAndSetFormCode(e.target.value)}
                />
                <InputRightElement height={8}>
                  <Tooltip hasArrow label={t("registrationForms.tooltip.code")}>
                    <QuestionCircle color="#1A1A1A80" />
                  </Tooltip>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box
              padding={6}
              backgroundColor="white"
              margin={4}
              marginY="0"
              borderRadius="10px"
              height="238px"
              width="233px"
            >
              <Text
                fontSize="md"
                lineHeight="1.2"
                fontWeight="600"
                marginBottom={3}
              >
                {t("registrationForms.visibleFields")}
              </Text>
              <Flex alignItems="center" marginBottom={3}>
                <Text
                  fontSize="sm"
                  lineHeight="1.2"
                  fontWeight="500"
                  marginRight={1}
                >
                  {t("registrationForms.commonFields")}
                </Text>
                <Tooltip
                  hasArrow
                  label={t("registrationForms.tooltip.commonFields")}
                >
                  <QuestionCircle verticalAlign="inherit" color="#1A1A1A80" />
                </Tooltip>
              </Flex>

              <VStack color="white" fontWeight="600">
                {draggableFieldOptions.map((field: FormField) => (
                  <DraggableField
                    key={"draggable_" + field.name}
                    fieldName={`${t(
                      `registrationForms.fieldTitle.${field.name}`
                    )}`}
                    fieldData={field}
                    addField={addNewField}
                    isInForm={
                      !!addedFields.find(
                        (optionalField) => optionalField.name === field.name
                      )
                    }
                  />
                ))}
              </VStack>
            </Box>
          </Flex>

          <Accordion
            allowMultiple
            width="80%"
            flex="1"
            style={{ paddingBottom: "1rem" }}
            defaultIndex={[0]}
          >
            <SegmentationDropdown
              initialData={
                formData?.segmentations ?? getDefaultSegmentationValues()
              }
              // fieldData={segmentation}
              updateSegmentation={setSegmentation}
              externalUsersEnabled={company.external_users}
              userType={userType}
              updateUserType={setUserType}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
            <Divider
              marginBottom={3}
              width="auto"
              borderColor="blackAlpha.500"
              marginRight={6}
            />
            {/* Add Fields Section */}
            <RequiredField
              fieldName="First Name and Last Name"
              helpText={t("registrationForms.tooltip.name")}
            />
            <RequiredField
              fieldName="Email Address"
              helpText={t("registrationForms.tooltip.email")}
            />
            {/* Loop over optional fields */}
            {addedFields.map((field: FormField, addedFieldIndex: number) => (
              <FormDropdown
                key={"formDropdown_" + field.name}
                fieldName={t(`registrationForms.fieldTitle.${field.name}`)}
                fieldData={field}
                FieldForm={ValidateRequiredForm}
                index={addedFieldIndex}
                removeField={removeField}
                updateField={updateValidationField}
                moveField={moveField}
              />
            ))}
            {addedFields.length < draggableFieldOptions.length && (
              <DraggableFieldAddLanding />
            )}
          </Accordion>
        </Flex>
      </DndProvider>

      <Preview
        isOpen={previewIsOpen}
        setIsOpen={setPreviewIsOpen}
        fields={[...requiredFields, ...addedFields]}
      />
    </Box>
  );
}

FormDropdown.defaultProps = {
  fieldData: {},
  removeField: () => {},
  updateField: () => {},
  moveField: () => {},
};
