export const Checkbox = {
  baseStyle: {
    control: {
      _checked: {
        color: "white",
        backgroundColor: "brand.formInput",
        borderColor: "brand.formInput",
        _hover: {
          backgroundColor: "brand.formInput",
          borderColor: "brand.formInput",
        },
      },
    },
  },
  variants: {
    admin: {
      control: {
        backgroundColor: "white",
        _checked: {
          color: "white",
          backgroundColor: "brand.formInput",
          borderColor: "brand.formInput",
          _hover: {
            backgroundColor: "brand.formInput",
            borderColor: "brand.formInput",
          },
        },
      },
    },
    form: {
      control: {
        backgroundColor: "white",
        width: 5,
        height: 5,
        borderColor: "brand.gray.100",
        _checked: {
          backgroundColor: "brand.formInput",
          borderColor: "brand.gray.100",
        },
        _hover: {
          borderColor: "brand.gray.200",
        },
      },
    },
    adminBrand: {
      control: {
        backgroundColor: "white",
        borderRadius: "5px",
        _checked: {
          color: "white",
          background: "brand.formInput",
          borderColor: "brand.formInput",
          opacity: "0.5",
          _hover: {
            backgroundColor: "brand.formInput",
            borderColor: "brand.formInput",
            opacity: "0.5",
          },
        },
      },
    },
    create: {
      container: {
        backgroundColor: "white",
        padding: 4,
        border: "1px solid",
        borderColor: "warmNeutral.200",
        borderRadius: "6px",
        _hover: {
          borderColor: "warmNeutral.700",
        },
        _checked: {
          borderColor: "warmNeutral.700",
          backgroundColor: "warmNeutral.0",
        },
      },
      control: {
        borderColor: "warmNeutral.200",
        _checked: {
          backgroundColor: "warmNeutral.700",
          borderColor: "warmNeutral.700",
          _hover: {
            backgroundColor: "warmNeutral.700",
            borderColor: "warmNeutral.700",
          },
        },
      },
      label: {
        fontSize: "14px",
        fontWeight: 500,
        color: "baseBlack",
        _checked: {
          color: "baseBlack",
        },
      },
    },
    createSimple: {
      control: {
        _checked: {
          backgroundColor: "brand.gold.100",
          borderColor: "brand.gold.100",
        },
      },
    },
    assessment: {
      control: {
        borderColor: "warmNeutral.600",
        _checked: {
          backgroundColor: "warmNeutral.600",
          borderColor: "warmNeutral.600",
          _hover: {
            backgroundColor: "warmNeutral.600",
            borderColor: "warmNeutral.600",
          },
        },
        _hover: {
          borderColor: "warmNeutral.600",
        },
        _focus: {
          borderColor: "warmNeutral.600",
        },
      },
      label: {
        fontSize: "sm",
        fontWeight: 500,
        color: "baseBlack",
        _checked: {
          color: "BaseBlack",
        },
      },
    },
  },
};
