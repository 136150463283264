import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import IntegrationService from "@/client/services/api/admin/connect/IntegrationService";
import { useToast } from "@chakra-ui/react";
import { useValidationStore } from "@/client/services/state/webhooks/validationStore";

export const useIntegrationService = () => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    validating,
    setValidating,
    returnedValidation,
    setReturnedValidation,
  } = useValidationStore();

  useEffect(() => {
    if (!searchTerm) {
      setIsSearching(false);
    }
    if (searchTerm) {
      setIsSearching(true);
    }
  }, [searchTerm]);

  const getApiIntegrations = (fetchDataOptions: {
    pageIndex: number;
    pageSize: number;
  }) =>
    useQuery({
      queryKey: ["integrations", fetchDataOptions, isSearching, searchTerm],
      queryFn: () =>
        IntegrationService.getApiIntegrations({
          fetchDataOptions,
          isSearching,
          searchTerm,
        }),
    });

  type CreateMutationTypes = {
    details: any;
  };

  const createMutation = useMutation({
    mutationFn: ({ details }: CreateMutationTypes) =>
      IntegrationService.createApiIntegration(details),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["integrations"],
      });
    },
  });

  const createApiIntegration = (details: {
    application_name: string;
    application_info: {
      name: string;
      value: string;
    };
  }) => {
    const resp = createMutation.mutate({ details });
    return resp;
  };

  type RemoveMutationTypes = {
    id: string;
  };

  const removeMutation = useMutation({
    mutationFn: (id: string) => IntegrationService.removeApiIntegration(id),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["integrations"],
      });
    },
  });

  const removeApiIntegration = (id: string) => {
    const resp = removeMutation.mutate(id);

    return resp;
  };

  type ExposeApiIntegrationType = {
    id: string;
  };

  const exposeApiIntegration = ({ id }: ExposeApiIntegrationType) =>
    useQuery({
      queryKey: ["exposeIntegration", id],
      queryFn: () => IntegrationService.exposeApiIntegration({ id }),
    });

  const getWebhooks = (getArchived: boolean) =>
    useQuery({
      queryKey: ["getWebhooks", getArchived],
      queryFn: () => IntegrationService.getWebhooks(getArchived),
    });

  const createWebhookMutation = useMutation({
    mutationFn: ({ details }: any) => IntegrationService.createWebhook(details),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWebhooks"],
      });
    },
  });

  const createWebhook = (details: any) => {
    const resp = createWebhookMutation.mutate({ details });

    return resp;
  };

  type RemoveWebhookMutationTypes = {
    id: any;
  };

  const removeWebhookMutation = useMutation({
    mutationFn: ({ id }: RemoveWebhookMutationTypes) =>
      IntegrationService.removeWebhook({ id }),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWebhooks"],
      });
    },
  });
  type RemoveWebhookType = {
    id: string;
  };
  const removeWebhook = ({ id }: RemoveWebhookType) => {
    const resp = removeWebhookMutation.mutate({ id });

    return resp;
  };

  const getWebhookDetails = (id: string | undefined) =>
    useQuery({
      queryKey: ["getWebhookDetails", id],
      queryFn: () => IntegrationService.getWebhookDetails({ id }),
    });

  const validateWebhookMutation = useMutation({
    mutationFn: ({ id }: RemoveWebhookMutationTypes) =>
      IntegrationService.validateWebhook(id),
    onError: () => {
      toast({
        title: "Could Not Validate Webhook",
        description: "There was a problem validating your webhook.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setValidating(false);
      setReturnedValidation("failed");
    },
    onSuccess: () => {
      toast({
        title: "Webhook Validated",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setValidating(false);
      setReturnedValidation("passed");
    },
  });

  type ValidateWebhookType = {
    id: string;
  };

  const validateWebhook = ({ id }: ValidateWebhookType) => {
    validateWebhookMutation.mutate(
      { id },
      {
        onSuccess: () => {
          return "SUCCESS";
        },
        onError: () => {
          return "ERROR";
        },
      }
    );
  };

  const updateWebhookMutation = useMutation({
    mutationFn: ({
      details,
    }: {
      details: {
        name: string;
        url: string;
        customizations: {
          headers: [];
          body: [];
          filters: [];
        };
        events: string[];
      };
    }) => IntegrationService.updateWebhook(details),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWebhooks"],
      });
    },
  });

  const updateWebhook = (details: any) => {
    updateWebhookMutation.mutate({ details });
  };

  const archiveWebhookMutation = useMutation({
    mutationFn: (id: any) => IntegrationService.archiveWebhook(id),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWebhooks"],
      });
    },
  });

  const archiveWebhook = (id: string) => {
    archiveWebhookMutation.mutate(id);
  };

  const unArchiveWebhookMutation = useMutation({
    mutationFn: (id: any) => IntegrationService.unArchiveWebhook(id),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWebhooks"],
      });
    },
  });

  const unArchiveWebhook = (id: string) => {
    unArchiveWebhookMutation.mutate(id);
  };

  const getWebhookActivity = (
    fetchDataOptions: { pageIndex: number; pageSize: number },
    id: string
  ) =>
    useQuery({
      queryKey: ["getWebhookActivity", fetchDataOptions, id],
      queryFn: () =>
        IntegrationService.getWebhookActivity(fetchDataOptions, id),
    });

  const enableWebhookMutation = useMutation({
    mutationFn: (id: any) => IntegrationService.enableWebhook(id),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWebhooks"],
      });
    },
  });

  const enableWebhook = (id: string) => {
    enableWebhookMutation.mutate(id);
  };

  const disableWebhookMutation = useMutation({
    mutationFn: (id: any) => IntegrationService.disableWebhook(id),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {},
  });

  const disableWebhook = (id: string) => {
    disableWebhookMutation.mutate(id);
  };

  return {
    setSearchTerm,
    isSearching,
    setIsSearching,
    getApiIntegrations,
    createApiIntegration,
    removeApiIntegration,
    exposeApiIntegration,
    getWebhooks,
    createWebhook,
    getWebhookDetails,
    removeWebhook,
    validateWebhook,
    updateWebhook,
    archiveWebhook,
    unArchiveWebhook,
    getWebhookActivity,
    searchTerm,
    enableWebhook,
    disableWebhook,
    removeMutation,
  };
};
