import { Box, Button, Flex, Link, Tooltip } from "@chakra-ui/react";
import {
  Link as LinkIcon,
  MicrosoftTeams,
} from "@/client/components/icons/ContinuIcons";
import { useMutation, useQuery } from "@tanstack/react-query";

import TeamsService from "@/client/services/api/integrations/TeamsService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export default function TeamsActivationButton() {
  const { setToast } = useToastStore();
  const { config } = useConfigStore();
  const formattedLink = config.microsoftTeamsLink.replace(
    "{STATE}",
    config.host === "staging.continu.co" ? "learn.continu.co" : config.host
  );
  const { authConfig } = useAuthStore();
  const user = authConfig.user;
  const { t } = useTranslation();

  const { refetch, isLoading, isError, data, error } = useQuery(
    ["teams-exists"],
    async () => await TeamsService.checkExists(user._id),
    { onError: (e) => console.log(e) }
  );

  const uninstallMutation = useMutation({
    mutationFn: () => TeamsService.removeIntegration(),
    onSuccess: (res: any) => {
      refetch();
      setToast({
        show: true,
        status: "success",
        title: "Removed Microsoft Teams Integration",
      });
    },
    onError: (err) => {
      console.log(err);
      setToast({
        show: true,
        status: "error",
        title: "Error removing Microsoft Teams Integration",
      });
    },
  });

  const clickHandler = () => {
    if (data && data.microsoftTeamsExists) {
      uninstallMutation.mutate();
    } else {
      window.location.href = formattedLink;
      refetch();
    }
  };

  return (
    <>
      <Flex alignItems="center">
        {data && (
          <Button
            onClick={clickHandler}
            variant="integrations"
            leftIcon={<MicrosoftTeams boxSize={5} />}
            flex="1"
          >
            {data.microsoftTeamsExists
              ? "Remove Microsoft Teams Integration"
              : "Integrate Microsoft Teams"}
          </Button>
        )}
        {data && data.microsoftTeamsExists && (
          <Tooltip
            label="This removes your authentication from Continu's records. Click here to go to your Microsoft Apps page and completely revoke permissions for Continu."
            placement="bottom-end"
            padding={4}
          >
            <Link href="https://myapplications.microsoft.com/" target="_blank">
              <Box
                background="brand.primary"
                color="white"
                padding={1}
                borderRadius={20}
                marginLeft={2}
              >
                <LinkIcon />
              </Box>
            </Link>
          </Tooltip>
        )}
      </Flex>
    </>
  );
}
