import { Flex, Text } from "@chakra-ui/react";

import CountUp from "react-countup";

type CircleStatProps = {
  count: number;
  color: string;
  fontWeight: "bold" | "medium" | "normal" | "light" | number;
  borderWidth: "4px" | "2px";
};

export default function CircleStat({
  count,
  color,
  fontWeight,
  borderWidth,
}: CircleStatProps) {
  return (
    <Flex
      border={borderWidth}
      borderColor={color}
      borderRadius="100%"
      height={{ base: "60px", md: "70px" }}
      width={{ base: "60px", md: "70px" }}
      minWidth={{ base: "60px", md: "70px" }}
      alignItems="center"
      justifyContent="center"
      marginBottom={2}
    >
      <Text fontSize="lg" color={color} fontWeight={fontWeight}>
        <CountUp end={count} delay={0.5} duration={2.75} />
      </Text>
    </Flex>
  );
}
