import AdminImportedContentService from "@/client/services/api/admin/content-authoring/AdminImportedContentService";
import AuthoringGraphqlService from "@/client/services/api/admin/content-authoring/AuthoringGraphqlService";
import type { CourseFormData } from "@/client/routes/admin/create/content/tracks/EditCourse";
import type { ImportedContentSubmissionData } from "@/client/types/admin/content-authoring/courses/imported-content/ImportedContentSubmissionData";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCheckContentEditable } from "../../useCheckContentEditable";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const useAuthorImportedContent = () => {
  const { id } = useParams();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { checkContentEditable } = useCheckContentEditable();
  const { watch, setValue } = useFormContext<CourseFormData>();

  const getImportedContentForEditor = useMutation({
    mutationFn: () =>
      AuthoringGraphqlService.getCourseForEditor(
        id,
        "imported-content",
        user._id
      ),
    onSuccess: (successData) => {
      if (!successData) return;

      checkContentEditable(successData);
    },
  });

  const importedContentPayload: Omit<
    ImportedContentSubmissionData,
    "published" | "approval_required" | "approved"
  > = {
    user: watch("creator") || user._id,
    // TODO: Fix this circular type dependency from getOne
    // @ts-ignore
    tags: watch("tags"),
    title: watch("title"),
    description: watch("description"),
    instructor_ids: watch("instructors")?.map((instructor) => instructor.id),
    learn_items: watch("learnItems")?.filter((item) => item !== "") || [],
    allow_comments: watch("allowComments"),
    duration: watch("duration"),
    linked_category: watch("linkedCategory")?.map((category) => category.id),
    privacy_collaborators: watch("privacyCollaborators")?.map(
      (collaborator) => collaborator.id
    ),
    privacy_locations: watch("privacyLocations")?.map(
      (location) => location.id
    ),
    privacy_departments: watch("privacyDepartments")?.map(
      (department) => department.id
    ),
    privacy_teams: watch("privacyTeams")?.map((team) => team.id),
    privacy_org_levels: watch("privacyOrgLevels")?.map(
      (orgLevel) => orgLevel.id
    ),
    privacy_groups: watch("privacyGroups")?.map((group) => group.id),
    privacy_grades: watch("privacyGrades")?.map((grade) => grade.id),
    image: watch("image"),
    banner_image: watch("bannerImage"),
    // @ts-ignore
    lil_structure: watch("lilStructure"),
    rating_configuration: {
      allow_edits: watch("ratingConfiguration.allowEdits"),
      allow_feedback: watch("ratingConfiguration.allowFeedback"),
      allow_rating: watch("ratingConfiguration.allowRating"),
      messaging: {
        individuals: watch("ratingConfiguration.messaging.individuals").map(
          (individual) => individual.id
        ),
        slack_channels: watch(
          "ratingConfiguration.messaging.slackChannels"
        ).map((channel) => channel.id),
      },
      notify_feedback_only: watch("ratingConfiguration.notifyFeedbackOnly"),
      show_users_rating: watch("ratingConfiguration.showUsersRating"),
      total_value: watch("ratingConfiguration.totalValue"),
    },
    surveys: watch("surveys")?.map((survey) => ({ survey_id: survey.id })),
    partner_permissions: watch("partnerPermissions"),
    vendor: watch("vendor"),
    vendor_content_id: watch("vendorContentId"),
    vendor_full_link: watch("vendorFullLink"),
    vendor_link: watch("vendorLink"),
  };

  type SubmitImportedContentMutationVariables = Pick<
    ImportedContentSubmissionData,
    "published" | "approved" | "approval_required"
  >;

  const submitImportedContentMutation = useMutation({
    mutationFn: (variables: SubmitImportedContentMutationVariables) =>
      AdminImportedContentService.saveImportedContent(
        {
          ...importedContentPayload,
          ...variables,
        },
        // TODO: Fix this circular type dependency
        // @ts-ignore
        watch("id")
      ),
    onSuccess: (successData) => {
      // TODO: Analyze calls

      setValue("id", successData._id);
    },
  });

  return {
    getImportedContentForEditor,
    submitImportedContentMutation,
    importedContentPayload,
  };
};
