import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div
      style={{
        fontFamily: "sans-serif",
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00BCF1",
        margin: "-0.5rem",
      }}
    >
      <div
        style={{
          padding: "2rem",
          fontWeight: "normal",
          color: "white",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontSize: "8rem", marginBottom: "1rem" }}>Oops!</h1>
        <p style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
          Sorry, an unexpected error has occurred.
        </p>
        <p
          style={{
            backgroundColor: "#fff",
            marginBottom: "1rem",
            padding: "0.5rem 1rem",
            borderRadius: "14px",
            color: "#888888",
          }}
        >
          <i>{error.statusText || error.message}</i>
        </p>
        <p style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
          Go{" "}
          <Link to={"/explore/"} style={{ fontWeight: "bold", color: "white" }}>
            back home
          </Link>
          &nbsp;or{" "}
          <a
            href="mailto:info@continu.co"
            style={{ fontWeight: "bold", color: "white" }}
          >
            contact us
          </a>{" "}
          for help
        </p>
      </div>
    </div>
  );
}
