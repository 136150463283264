import { AngleLeft, AngleRight } from "@/client/components/icons/ContinuIcons";
import { Flex, Text } from "@chakra-ui/react";
import { addMonths, format, subMonths } from "date-fns";

interface HeaderProps {
  activeDate: Date;
  setActiveDate: (activeDate: Date) => void;
}

export default function CalendarHeader({
  activeDate,
  setActiveDate,
}: HeaderProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-around"
      height="40px"
      style={{ userSelect: "none" }}
    >
      <AngleLeft
        width="25%"
        color="#B2B2B2"
        _hover={{ cursor: "pointer" }}
        onClick={() => setActiveDate(subMonths(activeDate, 1))}
      />

      <Text fontWeight="700" textAlign="center" width="50%">
        {format(activeDate, "MMMM yyyy")}
      </Text>

      <AngleRight
        width="25%"
        color="#B2B2B2"
        _hover={{ cursor: "pointer" }}
        onClick={() => setActiveDate(addMonths(activeDate, 1))}
      />
    </Flex>
  );
}
