import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

type AdminElevatedBoxProps = {
  children: ReactNode;
  useBoxShadow?: boolean;
};

export default function AdminElevatedBox({
  children,
  useBoxShadow,
}: AdminElevatedBoxProps) {
  return (
    <Box
      backgroundColor="white"
      marginTop={2}
      marginX={4}
      padding={6}
      borderRadius="xl"
      boxShadow={useBoxShadow ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none"}
      height="fit-content"
    >
      {children}
    </Box>
  );
}

AdminElevatedBox.defaultProps = {
  useBoxShadow: false,
};
