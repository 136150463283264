/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Stack } from "@chakra-ui/react";

import { Controller } from "react-hook-form";
import FormLabelWithTooltip from "./FormLabelWithTooltip";

// TODO: Refactor this component to use control from form context instead of passing it as a prop

interface CheckboxWithTooltipProps {
  name: string;
  label: string;
  tooltipText: string;
  control: any;
}

export default function CheckboxWithTooltip({
  name,
  label,
  tooltipText,
  control,
}: CheckboxWithTooltipProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      alignContent="center"
      spacing={6}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox isChecked={field.value} variant="admin" {...field}>
            <FormLabelWithTooltip
              label={label}
              tooltipText={tooltipText}
              marginBottom={0}
            />
          </Checkbox>
        )}
      />
    </Stack>
  );
}
