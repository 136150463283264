import type { QuestionData } from "@/client/services/api/graphql/gql/graphql";
import { create } from "zustand";

interface AssessmentState {
  selectedEditQuestion: QuestionData | null;
  setSelectedEditQuestion: (selectedEditQuestion: QuestionData | null) => void;
  uploadStatus: "idle" | "loading" | "success" | "error";
  setUploadStatus: (
    uploadStatus: "idle" | "loading" | "success" | "error"
  ) => void;
}

export const useAssessmentStore = create<AssessmentState>((set) => ({
  selectedEditQuestion: null,
  setSelectedEditQuestion: (selectedEditQuestion) =>
    set({ selectedEditQuestion }),
  uploadStatus: "idle",
  setUploadStatus: (uploadStatus) => set({ uploadStatus }),
}));
