import {
  Content,
  ContentTypes,
  PartnerPermissionTypes,
} from "@/client/services/api/graphql/gql/graphql";

import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateRadioGroup from "@/client/components/admin/create/shared/input/CreateRadioGroup";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ContentExtendAccessForm() {
  const { t } = useTranslation();
  const { watch } = useFormContext<Content>();
  const contentType = watch("contentType");

  const partnerPermissions = watch("partnerPermissions");

  return (
    <CreateFormGroup renderBorder={false}>
      <CreateRadioGroup
        labelVariant={
          contentType === ContentTypes.Assessment
            ? "createLabel"
            : "createHeading"
        }
        name="partnerPermissions"
        defaultValue={partnerPermissions || PartnerPermissionTypes.Tenant}
        label={t("authoring.audience.extendAccess")}
        helpText={t("authoring.audience.extendAccess.helpText")}
        options={[
          {
            value: PartnerPermissionTypes.Tenant,
            label: t("authoring.audience.extendAccess.tenant"),
          },
          {
            value: PartnerPermissionTypes.Partner,
            label: t("authoring.audience.extendAccess.partner"),
          },
          {
            value: PartnerPermissionTypes.PartnerAndTenant,
            label: t("authoring.audience.extendAccess.partnerAndTenant"),
          },
        ]}
      />
    </CreateFormGroup>
  );
}
