import { Box } from "@chakra-ui/react";

import RegistrationFormsHeader from "@/client/components/layout/RegistrationFormsHeader";
import RegistrationFormsList from "@/client/components/lists/RegistrationFormsList";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useNavigate } from "react-router-dom";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";

export default function RegistrationFormsTable() {
  useDocumentTitle("Registration Forms");
  const navigate = useNavigate();

  const addNewForm = () => {
    navigate("/admin/connect/registration-forms/create");
  };

  return (
    <AdminElevatedBox>
      <RegistrationFormsHeader addForm={addNewForm} />
      <Box
        padding={6}
        backgroundColor="white"
        margin={2}
        marginX={4}
        borderRadius="10px"
        boxShadow="0 2px 2px rgba(0,0,0,0.1)"
      >
        <RegistrationFormsList
          type="registration_form"
          editPath="/admin/connect/registration-forms/"
          pageUrl="/registration"
        />
      </Box>
    </AdminElevatedBox>
  );
}
