/* eslint react/no-unstable-nested-components: 0 */
/* eslint import/extensions: 0 */

import {
  Box,
  Circle,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Close, Download } from "@/client/components/icons/ContinuIcons";

import CertificateCustomData from "./CertificateCustomData";
import CertificateUrls from "../../../assets/certificate.json";
import { ContentCertificateData } from "@/client/types/content/ContentCertificateData";
import Logo from "@/client/components/media/images/Logo";
import ReactToPrint from "react-to-print";
import { format } from "date-fns";
import { t } from "i18next";
import { useRef } from "react";
import { useAuthStore } from "@/client/services/state/authStore";

type CertificateProps = {
  contentTitle: string;
  userFullName: string;
  completedDate: Date | string;
  userMetadata: Record<string, string>;
  contentMetadata: Record<string, string>;
  certificateData: ContentCertificateData;
  onClose: () => void;
};

export default function Certificate({
  contentTitle,
  userFullName,
  completedDate,
  userMetadata,
  contentMetadata,
  certificateData,
  onClose,
}: CertificateProps) {
  const printableRef = useRef<HTMLDivElement>(null);
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  const certificateDataMap = certificateData.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {} as Record<string, string>);
  const displayTitle = certificateDataMap.alt_title || contentTitle;
  // Keep this .toString() in case there's any booleans in the database for this value.
  const hideDate = certificateDataMap.hide_completion?.toString() === "true";

  const filteredUserMetadata: Record<string, any> = {};
  company.user_metadata_fields.forEach((umf) => {
    if (umf.visibility.certificates === "readonly") {
      filteredUserMetadata[umf.name] = userMetadata?.[umf.name];
    }
  });

  return (
    <Box id="certificate" position="absolute" top="0" left="0" zIndex="9999">
      <HStack position="fixed" padding={4} top="50" right="0" zIndex="10">
        <HStack>
          <Box>
            <ReactToPrint
              trigger={() => (
                <Circle
                  size="40px"
                  bg="brand.primary"
                  color="gray.200"
                  onClick={() => alert("Download")}
                  _hover={{ cursor: "pointer", color: "white" }}
                >
                  <Download />
                </Circle>
              )}
              content={() => printableRef.current}
            />
          </Box>

          <Circle
            size="40px"
            bg="brand.primary"
            color="gray.200"
            onClick={onClose}
            _hover={{ cursor: "pointer", color: "white" }}
          >
            <Close />
          </Circle>
        </HStack>
      </HStack>

      <Flex
        position="fixed"
        top="0"
        left="0"
        width="full"
        height="full"
        background="white"
        ref={printableRef}
        flexDirection="column"
        textAlign="center"
      >
        <Box
          backgroundImage={CertificateUrls.certTop}
          backgroundSize="100% 100%"
          backgroundRepeat="no-repeat"
          backgroundPosition="top center"
          minHeight="150px"
          height="150px"
        />

        <VStack spacing="0" flex="1" display="flex" justifyContent="center">
          <Box marginY={8}>
            <Logo variant="login" />

            <Heading
              fontWeight="bold"
              color="brand.primary"
              textTransform="uppercase"
            >
              {displayTitle}
            </Heading>

            <Heading color="brand.primary">{userFullName}</Heading>

            {!hideDate && (
              <Text>
                {t("certificate.completed_on_prefix")}{" "}
                <Text as="span" color="brand.primary">
                  {format(new Date(completedDate), "MMMM do, yyyy ")}
                </Text>
              </Text>
            )}

            {userMetadata && Object.keys(filteredUserMetadata).length > 0 && (
              <CertificateCustomData
                title={t("certificate.user_custom_fields")}
                metadata={filteredUserMetadata}
              />
            )}

            {contentMetadata && Object.keys(contentMetadata).length > 0 && (
              <CertificateCustomData
                title={t("certificate.content_custom_fields")}
                metadata={contentMetadata}
              />
            )}
          </Box>

          <Box
            backgroundImage={CertificateUrls.logoBottom}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            height="50px"
            width="100px"
          />
        </VStack>

        <Box
          backgroundImage={CertificateUrls.certBottom}
          backgroundSize="100% 100%"
          backgroundRepeat="no-repeat"
          backgroundPosition="bottom center"
          minHeight="150px"
          height="150px"
        />
      </Flex>
    </Box>
  );
}
