import { GraphQLClient } from "graphql-request";

import {
  CLIENT_CREATION_KEY_GRAPHQL,
  registerClientCreation,
} from "./clientRegister";

// eslint-disable-next-line import/no-mutable-exports
export let graphQLClient: GraphQLClient;

const create = (apiUrl: string) => {
  graphQLClient = new GraphQLClient(`${apiUrl}/graphql`, {
    headers: () => {
      const headers: { authorization?: string } = {};
      const value = localStorage.getItem("authStore");
      let keys;

      if (value) {
        keys = JSON.parse(value);

        headers.authorization = `AuthToken token=${keys.state.authConfig.authtoken}`;
      }
      return headers;
    },
  });
};

registerClientCreation(CLIENT_CREATION_KEY_GRAPHQL, create);
