import { Flex, Grid, GridItem, Text, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useGetProduct } from "@/client/services/hooks/content/products/useGetProduct";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "usehooks-ts";
import { useAuthStore } from "@/client/services/state/authStore";
import EcommerceService from "@/client/services/api/graphql/EcommerceService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";
import { useCheckIfProductIsActive } from "@/client/services/hooks/content/products/useGetPurchaseFromProduct";

export default function PaymentSetupSuccess() {
  useDocumentTitle("Manage Payment Methods");
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;

  const { id: productId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { data: productData } = useGetProduct(productId);

  const setupIntentId = searchParams.get("setupIntentId");

  const createSubscription = useMutation({
    mutationFn: async () =>
      EcommerceService.createSubscription(productId!, setupIntentId!),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("ecommerce.submitPayment.success"),
      });
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: t("ecommerce.submitPayment.error"),
      });
      navigate("/explore");
    },
  });

  const { status } = createSubscription;

  useEffect(() => {
    if (setupIntentId && productData && productData.price) {
      createSubscription.mutate();
    }
  }, [setupIntentId, productData]);

  // Polling for subscription activated
  const { data: isActive, refetch: refetchProductActive } =
    useCheckIfProductIsActive(user._id, productId!, 1000);

  useEffect(() => {
    if (status === "success") {
      if (isActive) {
        navigate("/explore");
      }
      refetchProductActive();
    }
  }, [status, isActive]);

  return (
    <Flex direction="column" h="100%" paddingTop="100px" paddingX="170px">
      <Grid
        templateColumns="10% 1fr 1fr"
        templateRows="repeat(2, 1fr)"
        w="50%"
        margin="0 auto"
      >
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={2}>
          <Spinner size="xl" color="brand.primary" />
        </GridItem>
        <GridItem colStart={2} colSpan={2} rowStart={1} rowSpan={1}>
          <Text fontSize="xl">{t("ecommerce.submitPayment.processing")}</Text>
        </GridItem>
        <GridItem colStart={2} colSpan={2} rowStart={2} rowSpan={1}>
          <Text fontSize="md">
            {t("ecommerce.submitPayment.dontClosePage")}
          </Text>
        </GridItem>
      </Grid>
    </Flex>
  );
}
