import type { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useValidateMediaAssessmentQuestionBuilder = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { setTabIndex, tabs, setTabs } = useCreateStore();

  const { watch } = useFormContext<AssessmentFormData>();

  const { questions } = watch();

  const validateMediaAssessmentQuestionBuilder = (
    realtimeCheck: boolean = false,
    callback?: any
  ) => {
    const question = questions[0];
    let noPrompt = false;
    let incompleteAnswer = false;

    if (!question.question || question.question === "") {
      noPrompt = true;
    }

    if (question.rubricGrading && question.rubricOptions) {
      question.rubricOptions.forEach((option) => {
        if (option.name === "" || option.description === "") {
          incompleteAnswer = true;
        }
      });
    }

    if (!realtimeCheck) {
      if (noPrompt) {
        setToast({
          show: true,
          status: "error",
          title: t("assessment.validation.mediaResponse.includePrompt"),
        });

        return;
      }

      if (incompleteAnswer) {
        setToast({
          show: true,
          status: "error",
          title: t("assessment.validation.mediaResponse.invalidRubric"),
        });

        return;
      }
    }

    if (!noPrompt && !incompleteAnswer) {
      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        isDisabled: false,
      }));

      setTabs(updatedTabs);
    } else {
      const updatedTabs = tabs.map((tab, index) => ({
        ...tab,
        isDisabled: index !== 0 && index !== 1,
      }));

      setTabs(updatedTabs);
    }

    if (!realtimeCheck) {
      setTabIndex(2);
    }

    if (callback) {
      callback();
    }
  };

  return { validateMediaAssessmentQuestionBuilder };
};
