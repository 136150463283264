import {
  AngleLeft,
  AngleRight,
  Comment,
} from "@/client/components/icons/ContinuIcons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Article from "./Article";
import AssessmentView from "./assessments/AssessmentView";
import CommentList from "@/client/components/lists/CommentList";
import ContentFile from "./ContentFile";
import ContentWrapper from "@/client/components/content/ContentWrapper";
import { Course } from "@/client/types/content/learning-tracks/LearningTrack";
import HeaderCloseIcon from "@/client/components/icons/HeaderCloseIcon";
import HeaderSuggestionsIcon from "@/client/components/icons/HeaderSuggestionsIcon";
import Scorm from "./Scorm";
import TrackContentList from "../../components/lists/track/TrackContentList";
import TrackService from "@/client/services/api/TrackService";
import { UserTrack } from "@/client/types/content/learning-tracks/UserTrack";
import Video from "./Video";
import { useGetCommentCount } from "@/client/services/hooks/comments/useGetCommentCount";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import { useTranslation } from "react-i18next";

type TrackViewerProps = {
  allowComments: boolean;
  selectedCourse: Course;
  trackName: string;
  sections: any[];
  lastViewedContent: string;
  trackId: string;
  userTrackId: string | undefined;
  userTrack: UserTrack | undefined;
  journeyId?: string | undefined;
  handleGoToCourse: (course: Course) => void;
  backToTrack: () => void;
  handleContentCompletion: () => void;
};

export default function TrackViewer({
  allowComments,
  selectedCourse,
  trackName,
  sections,
  lastViewedContent,
  trackId,
  userTrackId,
  userTrack,
  journeyId = undefined,
  handleGoToCourse,
  backToTrack,
  handleContentCompletion,
}: TrackViewerProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { partner } = usePartnerStore();
  const [contentListIsOpen, setContentListIsOpen] = useState(false);
  const [commentsDrawerIsOpen, setCommentsDrawerIsOpen] = useState(false);

  const { contentType } = selectedCourse;

  const { isLoading: commentCountLoading, data: commentCount } =
    useGetCommentCount(contentType, selectedCourse.contentId, true, trackId);

  interface UpdateLastViewedVariables {
    userTrackId: string | undefined;
    contentId: string | undefined;
  }

  const updateLastViewedContent = useMutation({
    mutationFn: async ({ userTrackId, contentId }: UpdateLastViewedVariables) =>
      TrackService.setUserTrackLastViewed(userTrackId, contentId),
    onSuccess: () =>
      queryClient.invalidateQueries(["learning-track", { trackId }]),
  });

  useEffect(() => {
    if (selectedCourse.contentId) {
      setContentListIsOpen(false);

      updateLastViewedContent.mutate({
        userTrackId,
        contentId: selectedCourse.contentId,
      });
    }
  }, [selectedCourse]);

  let contentToRender;
  switch (contentType) {
    case "article":
      contentToRender = Article;
      break;
    case "video":
      contentToRender = Video;
      break;
    case "file":
      contentToRender = ContentFile;
      break;
    case "assessment":
      contentToRender = AssessmentView;
      break;
    case "scorm":
      contentToRender = Scorm;
      break;
    default:
      throw new Error(`Unsupported content type ${contentType}`);
  }

  return (
    <Box paddingBottom={24}>
      <Flex
        marginBottom={6}
        padding={4}
        justifyContent="space-between"
        top="0"
        left="0"
        width="100%"
        zIndex="10"
        backgroundColor={journeyId ? "transparent" : "white"}
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Button
          leftIcon={<AngleRight />}
          variant="ghost"
          _hover={{ backgroundColor: "transparent", color: "brand.primary" }}
          onClick={() => setContentListIsOpen(true)}
        >
          {t("contentViews.tracks.view_showContents")}
        </Button>

        {!journeyId && (
          <HStack spacing={6}>
            {allowComments && (
              <Flex alignItems="center">
                <IconButton
                  variant="ghost"
                  marginRight={1}
                  aria-label="Open Comments"
                  icon={<Comment boxSize="26px" />}
                  _hover={{ color: "brand.primary", cursor: "pointer" }}
                  onClick={() => setCommentsDrawerIsOpen(true)}
                />

                {!commentCountLoading && commentCount && (
                  <Text>{commentCount.count}</Text>
                )}
              </Flex>
            )}

            {contentType && (
              <HeaderSuggestionsIcon
                contentType={contentType}
                contentId={selectedCourse.contentId}
                contentTitle={selectedCourse.contentTitle}
              />
            )}

            <HeaderCloseIcon navigateAction={() => backToTrack()} />
          </HStack>
        )}
      </Flex>

      {selectedCourse && selectedCourse.contentId && (
        <ContentWrapper
          key={selectedCourse.contentId}
          contentType={contentType}
          content={contentToRender}
          contentId={selectedCourse.contentId}
          trackId={trackId}
          journeyId={journeyId}
          onCompleteContent={handleContentCompletion}
          selectorDisabled
          parentContextId={userTrackId}
        />
      )}

      <Drawer
        size="lg"
        placement="left"
        onClose={() => setContentListIsOpen(false)}
        isOpen={contentListIsOpen}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                onClick={() => backToTrack()}
                _hover={{ color: "brand.primary", cursor: "pointer" }}
              >
                {trackName}
              </Text>

              <Button
                leftIcon={<AngleLeft />}
                variant="ghost"
                _hover={{
                  backgroundColor: "transparent",
                  color: "brand.primary",
                }}
                onClick={() => setContentListIsOpen(false)}
              >
                {t("global.actions.hide")}
              </Button>
            </Flex>
          </DrawerHeader>

          <DrawerBody paddingLeft={4}>
            <TrackContentList
              sections={sections}
              lastViewedContent={lastViewedContent}
              handleGoToCourse={handleGoToCourse}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {!partner._id && (
        <Drawer
          size="lg"
          placement="right"
          onClose={() => setCommentsDrawerIsOpen(false)}
          isOpen={commentsDrawerIsOpen}
        >
          <DrawerOverlay />

          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              <Flex alignItems="center" justifyContent="space-between">
                <Text>{t("trackinnerviewer.discussion")}</Text>

                <Button
                  leftIcon={<AngleRight />}
                  variant="ghost"
                  _hover={{
                    backgroundColor: "transparent",
                    color: "brand.primary",
                  }}
                  onClick={() => setCommentsDrawerIsOpen(false)}
                >
                  {t("global.actions.hide")}
                </Button>
              </Flex>
            </DrawerHeader>

            <DrawerBody>
              <CommentList
                contentId={trackId}
                contentType="track"
                contentTitle={trackName}
                inTrack
                trackId={trackId}
                trackContentType={contentType}
                trackContentId={selectedCourse.contentId}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
}
