import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import DeleteItemModal from "@/client/components/admin/overlay/DeleteItemModal";
import ThreeColumnLayout from "@/client/components/layout/admin/ThreeColumnLayout";
import { useConnect } from "@/client/services/hooks/admin/connect/useConnect";
import { useConnectStore } from "@/client/services/state/admin/connectStore";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Categories() {
  const { t } = useTranslation();
  const { deleteItem } = useConnect();
  const {
    setType,
    setShowExternalOption,
    setColumnCopy,
    clearStore,
    setSortOrder,
    setSearchTerm,
    isSearching,
    sortOrder,
  } = useConnectStore();

  const columnOneTitle = t("admin.connect.categories.columnOneTitle");
  const columnTwoTitle = t("admin.connect.categories.columnTwoTitle");
  const columnThreeTitle = t("admin.connect.categories.columnThreeTitle");
  const plural = t("admin.connect.categories.plural");
  const singular = t("admin.connect.categories.singular");
  const subItemsPlural = t("admin.connect.categories.columnTwoTitlePlural");
  const subSubItemsPlural = t(
    "admin.connect.categories.columnThreeTitlePlural"
  );

  const columnCopy = {
    plural,
    singular,
    columnOneTitle,
    columnTwoTitle,
    columnThreeTitle,
    noSubItemsText: t("admin.connect.noSubItems", {
      subType: columnTwoTitle,
      mainType: columnOneTitle,
    }),
    noSubSubItemsText: t("admin.connect.noSubSubItems", {
      subSubType: columnThreeTitle,
      subType: columnTwoTitle,
    }),
    noResultsMain: t("admin.connect.noSearchResultsMain", { type: plural }),
    noResultsSub: t("admin.connect.noSearchResultsSub", {
      subType: subItemsPlural,
      mainType: columnOneTitle,
    }),
    noResultsSubSub: t("admin.connect.noSearchResultsSubSub", {
      subSubType: subSubItemsPlural,
      subType: columnTwoTitle,
    }),
  };

  useEffect(() => {
    clearStore();
    setType("category");
    setShowExternalOption(true);
    setColumnCopy(columnCopy);
    setSortOrder("oldest");
  }, []);

  return (
    <>
      <AdminPrimaryHeader
        showLanguageSelector
        tooltipText={t("admin.connect.searchPlaceholder", { type: singular })}
        title={plural}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isSearching={isSearching}
      />

      <ThreeColumnLayout />

      <DeleteItemModal
        subType={subItemsPlural}
        subSubType={subSubItemsPlural}
        deleteItem={deleteItem}
      />
    </>
  );
}
