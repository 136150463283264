import type { ArticleFormData } from "@/client/routes/admin/create/content/EditArticle";
import ContentAudienceForm from "@/client/components/admin/create/content/forms/audience/ContentAudienceForm";
import ContentGeneralDetailsForm from "@/client/components/admin/create/content/forms/ContentGeneralDetailsForm";
import ContentPublishForm from "@/client/components/admin/create/content/forms/ContentPublishForm";
import ContentSettingsForm from "@/client/components/admin/create/content/forms/settings/ContentSettingsForm";
import CreateTabs from "@/client/components/admin/create/shared/tabs/CreateTabs";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import Loading from "@/client/components/media/Loading";
import { useAuthStore } from "@/client/services/state/authStore";
import { useAuthorArticle } from "@/client/services/hooks/admin/authoring/useAuthorArticle";
import { useCheckContentEditable } from "@/client/services/hooks/admin/authoring/useCheckContentEditable";
import { useCreateNavigationBlocker } from "@/client/services/hooks/admin/authoring/useCreateNavigationBlocker";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentCreateTabControls from "@/client/components/admin/create/content/tabs/ContentCreateTabControls";

export default function EditArticleForm() {
  useCreateNavigationBlocker();
  const { id } = useParams();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { t } = useTranslation();
  const { watch, formState } = useFormContext<ArticleFormData>();
  const { isValid } = formState;
  const { checkContentEditable } = useCheckContentEditable();
  const {
    setCanAdvance,
    tabIndex,
    setTabIndex,
    setNoImageModalIsOpen,
    setContentType,
    setContentTitle,
  } = useCreateStore();
  const { submitArticleMutation, getArticleForEditor } = useAuthorArticle(id);

  useEffect(() => {
    setContentType("article");
    setTabIndex(0);
  }, []);

  const description = watch("description");
  const title = watch("title");
  const author = watch("author");
  const bannerImage = watch("bannerImage");
  const cardImage = watch("image");

  useEffect(() => {
    setContentTitle(title as string);
  }, [title]);

  useEffect(() => {
    if (description !== "" && title !== "" && author !== "") {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [title, author, description]);

  const tabOptions = [
    {
      label: t("authoring.tabs.article"),
      content: <ContentGeneralDetailsForm />,
      isDisabled: false,
    },
    {
      label: t("authoring.tabs.audience"),
      content: <ContentAudienceForm />,
      isDisabled: !isValid,
    },
    {
      label: t("authoring.tabs.settings"),
      content: <ContentSettingsForm />,
      isDisabled: !isValid,
    },
    {
      label: id
        ? t("authoring.update")
        : user.is_collaborator
        ? t("authoring.tabs.save")
        : t("authoring.tabs.publish"),
      content: <ContentPublishForm />,
      isDisabled: !isValid,
    },
  ];

  const { data, isFetching, isError, error } = getArticleForEditor;

  useEffect(() => checkContentEditable(data), [data]);

  const handleProceed = () => {
    if (tabIndex === 0 && !cardImage && !bannerImage) {
      setNoImageModalIsOpen(true);
      return;
    }

    setTabIndex(tabIndex + 1);
  };

  const handlePublish = () =>
    submitArticleMutation.mutateAsync({
      approval_required: user.is_collaborator,
      approved: !user.is_collaborator,
      draft: false,
    });

  if (id && isFetching) return <Loading />;

  if (isError) {
    console.error("Error Fetching Article", error);

    return (
      <ErrorAlert
        title="There was an error fetching the article"
        backAction={{
          to: "/pvt/#/overview/content",
          label: "Back to content overview",
          isAngular: true,
        }}
      />
    );
  }

  return (
    <CreateTabs
      tabOptions={tabOptions}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      controls={
        <ContentCreateTabControls
          tabOptionsLength={tabOptions.length}
          onProceed={handleProceed}
          canPublish={formState.isValid}
          handlePublish={handlePublish}
        />
      }
    />
  );
}
