import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import BulkAddImportTab from "./BulkAddImportTab";
import BulkImportPreview from "@/client/routes/admin/users/BulkImportPreview";
import BulkImportReview from "./BulkImportReview";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { format } from "date-fns";
import { useAdminUsers } from "@/client/services/hooks/admin/users/useAdminUsers";
import { useAuthStore } from "@/client/services/state/authStore";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useTranslation } from "react-i18next";

export default function AdminUserBulkAdd() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  useDocumentTitle("Add Users");

  const { csv, setCsv, csvValidationMutation, csvFinalMutation } =
    useAdminUsers();

  const { data: csvValidationData } = csvValidationMutation;

  const { data: csvFinalData } = csvFinalMutation;

  const availableHeadings = {
    first_name: t("edit.useradd.first_name"),
    last_name: t("edit.useradd.last_name"),
    email: t("edit.useradd.email"),
    userid: t("edit.useradd.userid"),
    role: t("edit.useradd.role"),
    job_title: t("edit.useradd.job_title"),
    image: t("edit.useradd.image"),
    location_1: t("edit.useradd.location_1"),
    location_2: t("edit.useradd.location_2"),
    location_3: t("edit.useradd.location_3"),
    department_1: t("edit.useradd.department_1"),
    department_2: t("edit.useradd.department_2"),
    department_3: t("edit.useradd.department_3"),
    team: t("edit.useradd.team"),
    org_level: t("edit.useradd.org_level"),
    grade: t("edit.useradd.grade"),
    hired: t("edit.useradd.hired"),
    buddy_email: t("edit.useradd.buddy_email"),
    manager_email: t("edit.useradd.manager_email"),
    is_manager: t("edit.useradd.is_manager"),
    is_collaborator: t("edit.useradd.is_collaborator"),
    social_link: t("edit.useradd.social_link"),
    provisioning_status: t("edit.useradd.provisioning_status"),
    disable_assignments: t("edit.useradd.disable_assignments"),
    disable_assignment_completion: t(
      "edit.useradd.disable_assignment_completion"
    ),
    disable_assignment_removal: t("edit.useradd.disable_assignment_removal"),
    disable_assignment_edit: t("edit.useradd.disable_assignment_edit"),
    allow_user_creation: t("edit.useradd.allow_user_creation"),
    allow_user_edit: t("edit.useradd.allow_user_edit"),
    allow_password_reset: t("edit.useradd.allow_password_reset"),
    restricted_user_edit: t("edit.useradd.restricted_user_edit"),
    restricted_editable_fields: t("edit.useradd.restricted_editable_fields"),
    allow_adding_group_manager: t("edit.useradd.allow_adding_group_manager"),
    segmentation_required: t("edit.useradd.segmentation_required"),
    segmentation_subset: t("edit.useradd.segmentation_subset"),
    hide_options: t("edit.useradd.hide_options"),
    hide_non_managing_groups: t("edit.useradd.hide_non_managing_groups"),
    new_email: t("edit.useradd.new_email"),
    new_userid: t("edit.useradd.new_userid"),
    allow_direct_report_suspension: t(
      "edit.useradd.allow_direct_report_suspension"
    ),
  };

  if (company.groups) {
    // @ts-ignore
    availableHeadings.groups = "Groups";
    // @ts-ignore
    availableHeadings.managing_groups = "Managing Groups";
  }

  const downloadCsv = (items: any, name: string) => {
    // items = items || self.preview.errors;
    // name = name || 'errored';
    const headings = Object.keys(availableHeadings);

    const data = [headings];

    for (let i = 0; i < items.length; i += 1) {
      const item = [];

      for (let j = 0; j < headings.length; j += 1) {
        let value = items[i][headings[j]] || "";

        if (value && typeof value === "string" && value.indexOf(",") !== -1) {
          value = '"' + value + '"';
        }

        item.push(value);
      }
      data.push(item);
    }

    let csvContent = "data:text/csv;charset=utf-8,";

    data.forEach((line, index) => {
      const csvLine = line.join(",");
      csvContent += index < data.length ? csvLine + "\n" : csvLine;
    });

    const encodedUri = encodeURI(csvContent);
    const linkedAnchor = document.createElement("a");

    if (linkedAnchor) {
      linkedAnchor.setAttribute("href", encodedUri);
      linkedAnchor.setAttribute(
        "download",
        name + "-users-" + format(new Date(), "yyymmdd") + ".csv"
      );
      document.body.appendChild(linkedAnchor);

      linkedAnchor.click();
      linkedAnchor.parentNode?.removeChild(linkedAnchor);
    }
  };

  return (
    <>
      <AdminElevatedBox>
        <DndProvider backend={HTML5Backend}>
          <BulkAddImportTab csv={csv} setCsv={setCsv} />
        </DndProvider>
      </AdminElevatedBox>

      {csvValidationData && (
        <BulkImportPreview
          csvValidationData={csvValidationData}
          onImportUsers={csvFinalMutation}
          downloadCsv={downloadCsv}
        />
      )}

      {csvFinalData && (
        <BulkImportReview
          csvFinalData={csvFinalData}
          downloadCsv={downloadCsv}
        />
      )}
    </>
  );
}
