const routeToLearnApp: Record<string, boolean> = {
  admin: true,
  explore: false,
  manager: true,
};

// Keep this in sync with AngularJS app.
const viewContentTypesToAngularPath: Record<string, string> = {
  article: "articles",
  assessment: "assessments",
  file: "files",
  "imported-content": "imported-content",
  imported_content: "imported-content",
  journey: "journey",
  "multi-session-workshop": "multi-session-workshops",
  presentation: "presentations",
  scorm: "scorm",
  survey: "survey",
  track: "tracks",
  workshop: "workshops",
  video: "videos",
};

// Keep this in sync with AngularJS app.
const viewContentTypesToLearnerPath: Record<string, string> = {
  article: "article",
  assessment: "assessment",
  file: "file",
  "imported-content": "imported-content",
  journey: "journey",
  "multi-session-workshop": "multi-session-workshop",
  presentation: "presentation",
  scorm: "scorm",
  survey: "survey",
  track: "track",
  workshop: "workshop",
  video: "video",
};

// When we need to configure in-app routing based on per-company attributes,
// call this from the company loader.
export const setupRouteLookupType = (typ: string, shouldRoute: boolean) => {
  routeToLearnApp[typ] = shouldRoute;
};

export const admin = () => {
  if (routeToLearnApp.admin) {
    return "/pvt/#/overview/admin";
  }
  return "/TODO/admin";
};

export const editContentType = (contentType: string, id: string) => {
  if (
    contentType === "article" ||
    contentType === "file" ||
    contentType === "video" ||
    contentType === "scorm"
  ) {
    return `/admin/edit/${contentType}/${id}`;
  }

  if (contentType === "track") {
    return `/admin/edit/course/track/${id}`;
  }

  if (contentType === "imported_content") {
    return `/admin/edit/course/${id}`;
  }

  return `/pvt/#/edit/${viewContentTypesToAngularPath[contentType]}/${id}`;
};

export const hostContentType = (contentType: string, id: string) => {
  if (routeToLearnApp.admin) {
    return `/pvt/#/host/${viewContentTypesToAngularPath[contentType]}/${id}`;
  }
  return `/TODO/${viewContentTypesToLearnerPath[contentType]}/${id}`;
};

export const manager = () => {
  if (routeToLearnApp.manager) {
    return "/pvt/#/manager";
  }
  return "/TODO/manager";
};
