/* eslint react/no-unstable-nested-components: 0 */

import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Link,
  useToken,
} from "@chakra-ui/react";

import { PaginationState, createColumnHelper } from "@tanstack/react-table";
import { Person } from "@/client/types/Person";

import Loading from "@/client/components/media/Loading";
import PartnersManagerHeader from "@/client/components/layout/PartnersManagerHeader";
import PartnersService from "@/client/services/api/admin/PartnersService";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import {
  DateFormatGroupKey,
  dateFormatTenant,
} from "@/client/utils/dateFormat";
import { useAuthStore } from "@/client/services/state/authStore";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import { PlusCircle } from "@/client/components/icons/ContinuIcons";
import { Link as RouterLink, useParams } from "react-router-dom";
import ContentService from "@/client/services/api/ContentService";
import { useToastStore } from "@/client/services/state/toastStore";
import AssignmentsService from "@/client/services/api/AssignmentsService";
import TrackService from "@/client/services/api/TrackService";
import { ChevronRightIcon } from "@chakra-ui/icons";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";

export default function PartnerManagerContentAssign() {
  const { partner } = usePartnerStore();
  const { authConfig } = useAuthStore();
  const { setToast } = useToastStore();
  const { id: contentId, contentType } = useParams();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { t } = useTranslation();
  useDocumentTitle(t("partners.manager.contentAssignTitle"));

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data: content, isLoading: isContentLoading } = useQuery({
    queryKey: ["content", contentType, contentId],
    queryFn: () => {
      if (!contentId) {
        return null;
      }
      if (contentType === "article") {
        return ContentService.getArticle(contentId);
      }
      if (contentType === "file") {
        return ContentService.getFile(contentId);
      }
      if (contentType === "video") {
        return ContentService.getVideo(contentId);
      }
      if (contentType === "track") {
        return TrackService.getLearningTrack(contentId);
      }
    },
    enabled: !!contentId,
    keepPreviousData: true,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["partner-users", fetchDataOptions, partner._id, searchTerm],
    queryFn: () =>
      PartnersService.getAllPartnerUsers(fetchDataOptions, partner._id, {
        term: searchTerm,
      }),
    enabled: !!partner._id,
    keepPreviousData: true,
  });

  const addSelectedUser = (userId: string) => {
    setSelectedUsers([...selectedUsers, userId]);
  };

  const removeSelectedUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter((id) => id !== userId));
  };

  const updateSelectedUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userId = e.target.value;
    if (e.target.checked) {
      addSelectedUser(userId);
    } else {
      removeSelectedUser(userId);
    }
  };

  const createNewAssignment = async () => {
    const body = {
      assignees: {
        queries: [],
        assignee_in: selectedUsers,
        assignee_nin: [],
      },
      assigner: authConfig.user._id,
      content: [{ type: contentType, id: contentId }],
      reminder_messages: [],
      non_reminder_messages: [],
      overdue_messages: [],
      escalation_contacts: [],
      escalate_manager: false,
      escalate_buddy: false,
      due_from_hired: false,
      due_days_after_hired: 0,
      require_recompletion: false,
    };

    await AssignmentsService.queryCreate(body);

    setToast({
      show: true,
      status: "success",
      title: "Assignments created.",
    });
  };

  const columnHelper = createColumnHelper<Person>();
  const usersColumns = [
    columnHelper.display({
      id: "selectUsers",
      cell: (info) => (
        <Flex alignItems="center" justifyContent="center">
          <input
            type="checkbox"
            value={info.row.original._id}
            defaultChecked={selectedUsers.includes(info.row.original._id)}
            onChange={updateSelectedUser}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor("full_name", {
      cell: (info) => (
        <Flex alignItems="center" marginLeft={9}>
          <Avatar
            marginRight={2}
            backgroundColor="brand.primary"
            color="white"
            size="xs"
            src={info.row.original.image}
            name={info.getValue()}
          />
          {info.getValue()}
        </Flex>
      ),
      header: () => (
        <Box as="span" marginLeft={9}>
          {t("global.forms.labels_name")}
        </Box>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => <span>{t("global.forms.labels_email")}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("last_login", {
      cell: (info) => (
        <span>
          {info.getValue()
            ? dateFormatTenant(
                new Date(info.getValue()),
                company.date_display as DateFormatGroupKey,
                "base"
              )
            : "-----"}
        </span>
      ),
      header: () => <span>{t("manage.users.lastLogin")}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("linked_departments", {
      cell: (info) => (
        <span>{info.getValue()[0] ? info.getValue()[0].name : "-----"}</span>
      ),

      header: () => <span>{t("manage.users.department")}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("linked_locations", {
      cell: (info) => (
        <span>{info.getValue()[0] ? info.getValue()[0].name : "-----"}</span>
      ),
      header: () => <span>{t("manage.users.location")}</span>,
      footer: (info) => info.column.id,
    }),
  ];
  //    [],
  //   );

  if (isLoading || isContentLoading) return <Loading />;

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <PartnersManagerHeader currentTab="content" />

      <AdminElevatedBox>
        <Flex direction="row" justifyContent="space-between" marginBottom={2}>
          <Flex alignItems="left" gap={2}>
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/extend/manager">
                  Partners
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/extend/manager/content">
                  Content
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                {t("partners.manager.assignContentToUsers")} for{" "}
                <em>{content.name ?? content.title}</em>
              </BreadcrumbItem>
            </Breadcrumb>
          </Flex>
          {selectedUsers.length > 0 && (
            <Button
              variant="adminPrimary"
              size="xs"
              leftIcon={<PlusCircle />}
              onClick={() => createNewAssignment()}
            >
              {t("partners.manager.createNewAssignment")}
            </Button>
          )}
        </Flex>
        <ManuallyPaginatedTable
          columns={usersColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      </AdminElevatedBox>
    </Box>
  );
}
