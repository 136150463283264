import { Box, Circle, Flex, Icon, useToken } from "@chakra-ui/react";

import { hexToRGBA } from "@/client/utils/hexToRGBA";

interface BulkImportValidationGroupProps {
  type: "error" | "unchanged" | "warning" | "success";
  children: any;
  icon: any;
}

export default function BulkImportValidationGroup({
  type,
  children,
  icon,
}: BulkImportValidationGroupProps) {
  const [errorColor, unchangedColor, warningColor, successColor] = useToken(
    "colors",
    ["brand.red", "brand.gray.60", "brand.orange", "brand.green"]
  );

  const setColorValue = () => {
    if (type === "error") {
      return errorColor;
    }

    if (type === "unchanged") {
      return unchangedColor;
    }

    if (type === "warning") {
      return warningColor;
    }

    return successColor;
  };

  return (
    <Box
      paddingY={6}
      width="full"
      borderRadius="md"
      border="2px dashed"
      borderColor={setColorValue()}
      backgroundColor={hexToRGBA(setColorValue(), 0.2)}
      fontWeight="500"
    >
      <Flex>
        <Flex direction="column" justifyContent="flex-start" paddingX={6}>
          <Circle size="50px" border="1px solid" borderColor={setColorValue()}>
            <Icon as={icon} boxSize={6} color={setColorValue()} />
          </Circle>
        </Flex>

        <Flex>{children}</Flex>
      </Flex>
    </Box>
  );
}
