import {
  Box,
  Flex,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { editContentType, hostContentType } from "@/client/utils/routeLookup";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Cog } from "@/client/components/icons/ContinuIcons";
import HeaderCloseIcon from "../icons/HeaderCloseIcon";
import HeaderCommentsIcon from "../icons/HeaderCommentsIcon";
import HeaderRecommendationsIcon from "../icons/HeaderRecommendationsIcon";
import HeaderSharesIcon from "../icons/HeaderSharesIcon";
import HeaderSuggestionsIcon from "../icons/HeaderSuggestionsIcon";
import { useAuthStore } from "@/client/services/state/authStore";
import { useRecommendationsStore } from "@/client/services/state/recommendations/recommendationsStore";
import { useTranslation } from "react-i18next";
import { usePartnerStore } from "@/client/services/state/partnerStore";

type ContentHeaderProps = {
  contentType: string;
  contentTitle?: string;
  contentId: string | undefined;
  withBorder?: boolean;
  allowComments?: boolean;
  goToComments?: (() => void) | undefined;
  heroRef?: any;
  navigateAction?: (() => void) | null;
  goToRecommendations?: (() => void) | undefined;
};

export default function ContentHeader({
  contentType,
  contentTitle = "",
  contentId,
  withBorder = false,
  allowComments = false,
  heroRef = null,
  goToComments = undefined,
  navigateAction = null,
  goToRecommendations = undefined,
}: ContentHeaderProps) {
  const { recommendationsLoaded, setRecommendationsLoaded } =
    useRecommendationsStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const navigate = useNavigate();
  const { partner } = usePartnerStore();
  const transparentSettings = ["track", "imported_content", "journey"];
  const [colorScheme, setColorScheme] = useState<string>("solid");
  const { t } = useTranslation();
  const location = useLocation();
  const showSideNav = useBreakpointValue({ base: false, lg: true });

  const borderStyle = {
    borderBottom: "1px solid",
    borderBottomColor: "gray.100",
  };

  const socialContentType =
    contentType === "multi-session-workshop" ? "workshop" : contentType;

  const setSolidFloat = () =>
    window.scrollY + 30 > heroRef.current.clientHeight
      ? setColorScheme("solid")
      : setColorScheme("float");

  useEffect(() => {
    setRecommendationsLoaded(false);

    if (transparentSettings.includes(contentType)) setColorScheme("float");

    window.addEventListener("scroll", () => {
      if (heroRef?.current?.clientHeight) {
        setSolidFloat();
      }
    });

    return () => window.removeEventListener("scroll", () => {});
  }, []);

  const handleGoBack = () => {
    if (navigateAction) {
      navigateAction();
    } else if (location.state && location.state.originRoute) {
      navigate(location.state.originRoute);
    } else {
      document.referrer.includes("pvt") ? navigate(-1) : navigate("/explore");
    }
  };

  return (
    <Box
      sx={withBorder ? borderStyle : {}}
      position="fixed"
      top="50px"
      left={showSideNav ? "50px" : "0px"}
      width="100%"
      backgroundColor={colorScheme === "float" ? "transparent" : "white"}
      zIndex="10"
    >
      <Flex
        padding={4}
        flex="1"
        background={colorScheme === "float" ? "transparent" : "white"}
        height={50}
      >
        <Box
          display={{ base: "none", md: "flex" }}
          width="50%"
          alignItems="center"
        >
          {colorScheme !== "float" && (
            <Text
              fontWeight="bold"
              maxWidth="full"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color={colorScheme === "float" ? "white" : "black"}
            >
              {contentType !== "imported_content" ? contentTitle : ""}
            </Text>
          )}
        </Box>

        <Flex
          width={{ base: "100%", md: "50%" }}
          height="full"
          justifyContent="flex-end"
          alignItems="center"
        >
          <HStack spacing={6} marginRight={showSideNav ? "50px" : "0px"}>
            {recommendationsLoaded && (
              <HeaderRecommendationsIcon
                lightIcons={colorScheme === "float"}
                goToRecommendations={goToRecommendations}
              />
            )}

            {allowComments && contentType !== "journey" && !partner._id && (
              <HeaderCommentsIcon
                contentType={socialContentType}
                contentId={contentId}
                lightIcons={colorScheme === "float"}
                goToComments={goToComments}
              />
            )}

            {!company.feature_flags.disable_share &&
              contentType !== "journey" && (
                <HeaderSharesIcon
                  contentType={socialContentType}
                  contentId={contentId}
                  lightIcons={colorScheme === "float"}
                />
              )}
            <HeaderSuggestionsIcon
              contentType={socialContentType}
              contentId={contentId}
              contentTitle={contentTitle}
              lightIcons={colorScheme === "float"}
            />

            {user.role === "admin" && contentType !== "journey" && (
              <Menu>
                <MenuButton
                  aria-label="Article Admin Options"
                  _hover={{ color: "brand.button" }}
                >
                  <Cog
                    color={colorScheme === "float" ? "white" : "brand.iconGrey"}
                    boxSize={6}
                    _hover={{ color: "brand.button" }}
                  />
                </MenuButton>

                <MenuList>
                  <MenuItem
                    as={Link}
                    variant="menuLink"
                    href={editContentType(contentType, contentId!)}
                  >
                    {t("global.actions.edit")}{" "}
                    {t(
                      `global.contentTypes.${socialContentType.toLowerCase()}`
                    )}
                  </MenuItem>
                  <MenuItem
                    as={Link}
                    variant="menuLink"
                    href={hostContentType(contentType, contentId!)}
                  >
                    {t("viewArticle.viewAnalytics")}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}

            <HeaderCloseIcon
              closeColor={colorScheme === "float" ? "white" : "brand.iconGrey"}
              navigateAction={handleGoBack}
            />
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
}
