/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, CheckboxGroup, Stack } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

interface ControlledCheckboxGroupProps {
  formValueName: string;
  options: { label: string; value: string; enabled: boolean }[];
}

export default function ControlledCheckboxGroup({
  formValueName,
  options,
}: ControlledCheckboxGroupProps) {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      name={formValueName}
      control={control}
      render={({ field }) => (
        <CheckboxGroup
          {...field}
          variant="admin"
          defaultValue={getValues(formValueName)}
        >
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            {options.map((option) => {
              if (!option.enabled) return;

              return (
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              );
            })}
          </Stack>
        </CheckboxGroup>
      )}
    />
  );
}
