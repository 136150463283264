import {
  Box,
  Grid,
  GridItem,
  Icon,
  ScaleFade,
  Stack,
  Text,
} from "@chakra-ui/react";

import ContentCard from "@/client/components/data-display/cards/ContentCard";
import { ExploreContent } from "@/client/types/ExploreContent";
import { IoSparklesSharp } from "react-icons/io5";
import RecommendationsService from "@/client/services/api/recommendations/RecommendationsService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRecommendationsStore } from "@/client/services/state/recommendations/recommendationsStore";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";

interface RecommendationsProps {
  contentId: string | undefined;
  width?: any;
}

export default function Recommendations({
  contentId,
  width,
}: RecommendationsProps) {
  const { setRecommendationsLoaded } = useRecommendationsStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { allowance } = useAccessHelper();
  const enabled = !!allowance.recommendations;

  // TODO: Pull this out into a util for use in Workshop/Explore cards
  const setContentImageAlignment = (
    content: ExploreContent,
    isFirst: boolean
  ) => {
    let topPos = "";
    if (!isFirst && content.cover_justification === "left") {
      topPos = "north";
    }
    if (!isFirst && content.cover_justification === "right") {
      topPos = "south";
    }
    if (isFirst && content.featured_justification === "left") {
      topPos = "north";
    }
    if (isFirst && content.featured_justification === "right") {
      topPos = "south";
    }
    return `${topPos}`;
  };

  const { isLoading, isError, data } = useQuery({
    enabled: !!user._id && !!contentId && enabled,
    queryKey: ["recommendations", contentId],
    queryFn: () => RecommendationsService.getRecommendations(contentId!),
  });

  useEffect(() => {
    if (data && data.content.length > 0) {
      setRecommendationsLoaded(true);
    }
  }, [data]);

  if (isLoading || isError || data.content.length === 0) return null;

  return (
    <>
      {enabled && (
        <ScaleFade id="recommendations-container" initialScale={0.9} in>
          <Box
            marginTop={12}
            backgroundColor="brand.mist"
            padding={4}
            borderRadius="xl"
            boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
            width={width}
          >
            <Stack direction="row" alignItems="center" marginBottom={4}>
              <Text fontSize="lg" fontWeight="semibold">
                Recommend
              </Text>

              <Icon as={IoSparklesSharp} />
            </Stack>

            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap={4}
            >
              {data.content.slice(0, 3).map((item: ExploreContent) => (
                <GridItem key={item._id}>
                  <ContentCard
                    id={item._id}
                    title={item.title || item.name}
                    type={item.type}
                    imageUrl={item.images ? item.images.explore : item.image}
                    cardType="list"
                    imageAlignment={setContentImageAlignment(item, false)}
                    isRecommendation
                  />
                </GridItem>
              ))}
            </Grid>
          </Box>
        </ScaleFade>
      )}
    </>
  );
}

Recommendations.defaultProps = {
  width: "100%",
};
