import { useMutation, useQuery } from "@tanstack/react-query";

import { Profile } from "@/client/types/admin/profiles/Profile";
import { ProfileForm } from "@/client/types/admin/profiles/ProfileForm";
import ProfilesService from "@/client/services/api/admin/profiles/ProfilesService";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useProfilesService = (profileId?: string | undefined) => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);

  const getProfiles = (fetchDataOptions: {
    pageIndex: number;
    pageSize: number;
  }) =>
    useQuery({
      queryKey: ["profiles", fetchDataOptions, searchTerm],
      queryFn: () => ProfilesService.getProfiles(fetchDataOptions, searchTerm),
    });

  const getProfile = useQuery({
    enabled: !!profileId,
    queryKey: ["profile", profileId],
    queryFn: () => ProfilesService.getProfile(profileId),
  });

  interface CreateProfileVariables {
    profileData: ProfileForm;
  }

  const createProfile = useMutation({
    mutationFn: ({ profileData }: CreateProfileVariables) =>
      ProfilesService.createProfile(profileData),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("edit.profiles.create.success"),
      });
      navigate("/admin/profiles");
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("edit.profiles.create.error"),
      }),
  });

  const updateProfile = useMutation({
    mutationFn: ({ profileData }: CreateProfileVariables) =>
      ProfilesService.updateProfile(profileData),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("edit.profiles.edit.success"),
      });
      navigate("/admin/profiles");
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("edit.profiles.edit.success"),
      }),
  });

  const deleteProfile = useMutation({
    mutationFn: () => ProfilesService.deleteProfile(deleteItemId),
    onSuccess: () => {
      setDeleteItemId(null);
      //   refetch();
      setToast({
        show: true,
        title: t("admin.profiles.delete.success"),
        status: "success",
      });
    },
    onError: () =>
      setToast({
        show: true,
        title: t("admin.profiles.delete.error"),
        status: "error",
      }),
  });

  interface AssignProfileUsersVariables {
    userIds: string[];
  }

  const assignProfileUsers = useMutation({
    mutationFn: (variables: AssignProfileUsersVariables) =>
      ProfilesService.assignProfileUsers(
        selectedProfile?._id,
        variables.userIds
      ),
  });

  return {
    searchTerm,
    setSearchTerm,
    deleteItemId,
    setDeleteItemId,
    selectedProfile,
    setSelectedProfile,
    getProfiles,
    getProfile,
    createProfile,
    updateProfile,
    deleteProfile,
    assignProfileUsers,
  };
};
