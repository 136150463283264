import { Country } from "../types/preauth/Preauth";
import Loading from "@/client/components/media/Loading";
import { Navigate } from "react-router-dom";
import PreAuthService from "@/client/services/api/PreAuthService";
import RegionCodeForm from "@/client/components/forms/authentication/email/registration/RegionCodeForm";
import RegistrationDetailsForm from "../components/forms/authentication/email/registration/RegistrationDetailsForm";
import { useConfigStore } from "@/client/services/state/configStore";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export default function Register() {
  useDocumentTitle("Register");
  const { config } = useConfigStore();
  const domain = config.host;
  const [step, setStep] = useState<number>(0);
  const [countries, setCountries] = useState<Country[]>([]);

  const { isLoading, isError, data } = useQuery({
    queryKey: ["checkRegistrationActive"],
    queryFn: () => PreAuthService.checkRegistrationActive(domain),
  });

  if (isLoading) return <Loading />;

  if (isError || !data || data.status !== "active")
    return <Navigate to="/login" />;

  return (
    <>
      {step === 0 && (
        <RegionCodeForm
          setStep={setStep}
          domain={domain}
          setCountries={setCountries}
        />
      )}

      {step === 1 && <RegistrationDetailsForm countries={countries} />}
    </>
  );
}
