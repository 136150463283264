import type { ContentCollaborator } from "@/client/services/api/graphql/gql/graphql";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";
import { useTranslation } from "react-i18next";

//* This hook is used to check if the content is editable by the current user
//* If the content is not editable, a toast notification is shown and the user is redirected to the explore page
//* If the content is editable, the form is reset with the content data

export const useCheckContentEditable = () => {
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { reset } = useFormContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contentType } = useCreateStore();
  const { trackState } = useTrackStateStore();

  const translationMap = {
    article: "modules.notifications.articleNotEditable",
    file: "modules.notifications.fileNotEditable",
    video: "modules.notifications.videoNotEditable",
    scorm: "modules.notifications.scormNotEditable",
    track: "modules.notifications.trackNotEditable",
    udemy: "Imported Content not editable",
    linkedin: "Imported Content not editable",
    masterclass: "Imported Content not editable",
    product: "modules.notifications.productNotEditable",
    assessment: "This assessment is not available to edit",
  };

  // TODO: GraphQL 'extras' types make this diffuclt to type without including the entire content schema
  const checkContentEditable = (data: any) => {
    if (!data) return;

    if (
      data.company !== company._id ||
      (user.is_collaborator &&
        data.privacyCollaborators?.filter(
          (collaborator: ContentCollaborator) => collaborator.id === user._id
        ).length === 0)
    ) {
      setToast({
        show: true,
        status: "error",
        title: contentType
          ? t(translationMap[contentType])
          : "This content is not editable",
      });

      if (trackState) {
        setTimeout(() => {
          navigate(`/admin/edit/course/track/${trackState.track}`, {
            replace: true,
          });
        }, 500);

        return;
      }

      navigate("/explore", { replace: true });

      return;
    }

    reset(data);
  };

  return { checkContentEditable };
};
