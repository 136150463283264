import { Select } from "@chakra-ui/react";
import GroupsService, {
  Group,
} from "@/client/services/api/admin/connect/GroupsService";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

type SegmentationFormFieldGroupProps = {
  fieldLabel: string;
  type: string;
  initialId: string;
  updateSelectedData: (type: string, groupId: string) => void;
};

export default function SegmentationFormFieldGroup({
  fieldLabel,
  type,
  initialId,
  updateSelectedData,
}: SegmentationFormFieldGroupProps) {
  const defaultHolder = useRef<string>(initialId);

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  const { data: groups } = useQuery({
    queryKey: ["registration-forms-segmentation-" + type],
    queryFn: () => GroupsService.getAllGroups(),
  });

  useEffect(() => {
    if (groups) {
      const foundGroup = groups.find(
        (group) => group._id === defaultHolder.current
      );
      if (foundGroup) {
        setSelectedGroup(foundGroup);
      }
    }
  }, [groups]);

  const selectGroup = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!groups) {
      return;
    }
    if (event.target.value === "") {
      setSelectedGroup(null);
      updateSelectedData("group", "");
      return;
    }
    const foundGroup = groups.find((group) => group._id === event.target.value);
    if (foundGroup) {
      setSelectedGroup(foundGroup);
      updateSelectedData("group", foundGroup._id);
    }
  };

  return (
    <Select
      flex="0.33"
      placeholder={`Please select a ${fieldLabel}`}
      onChange={selectGroup}
      value={selectedGroup?._id}
    >
      {groups &&
        groups.map((group) => (
          <option key={"option_" + group._id} value={group._id}>
            {group.name}
          </option>
        ))}
    </Select>
  );
}
