import { ArrowLeft, ArrowRight } from "@/client/components/icons/ContinuIcons";
import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import ArchiveModal from "@/client/components/admin/create/content/overlay/ArchiveModal";
import { BiExpand } from "react-icons/bi";
import CollaboratorSaveModal from "@/client/components/admin/create/content/overlay/CollaboratorSaveModal";
import CreatePublishButton from "@/client/components/admin/create/shared/buttons/CreatePublishButton";
import DiscardModal from "@/client/components/admin/create/shared/overlay/DiscardModal";
import DuplicateModal from "@/client/components/admin/create/content/overlay/DuplicateModal";
import { IoEllipsisHorizontal } from "react-icons/io5";
import NoImageModal from "@/client/components/admin/create/content/overlay/NoImageModal";
import OutlineButton from "@/client/components/shared/buttons/OutlineButton";
import OutlineIconButton from "@/client/components/shared/buttons/OutlineIconButton";
import PublishModal from "@/client/components/admin/create/shared/overlay/PublishModal";
import SolidButton from "@/client/components/shared/buttons/SolidButton";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface TabControlsProps {
  tabOptionsLength: number;
  onProceed: () => void;
  canPublish: boolean;
  handlePublish: () => void;
  discardRoute: string;
  shouldRenderErrorsOnDisabledNextMouseOver?: boolean;
  onPublishButtonClickOverride?: () => void | null | undefined;
  onPreviewButtonClick?: () => void | null | undefined;
  renderCoursePreviewButton?: boolean | null | undefined;
  renderAssessmentPreviewButton?: boolean | null | undefined;
}

export default function TabControls({
  tabOptionsLength,
  onProceed,
  handlePublish,
  canPublish,
  discardRoute,
  onPublishButtonClickOverride,
  onPreviewButtonClick,
  shouldRenderErrorsOnDisabledNextMouseOver = false,
  renderCoursePreviewButton = false,
  renderAssessmentPreviewButton = false,
}: TabControlsProps) {
  const { id } = useParams();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { t } = useTranslation();
  const { watch, trigger, formState } = useFormContext();
  const navigate = useNavigate();
  const {
    setPublishModalIsOpen,
    tabIndex,
    setTabIndex,
    setDuplicateModalIsOpen,
    setArchiveModalIsOpen,
    setNoImageModalIsOpen,
    setCollaboratorSaveModalIsOpen,
    setCanLeave,
    contentType,
    canAdvance,
    setRenderErrors,
  } = useCreateStore();
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);

  const approvalRequired = watch("approvalRequired");
  const approved = watch("approved");
  const title = watch("title");

  const currentTabIsLastTab = tabIndex === tabOptionsLength - 1;

  const renderPublishButton =
    (!user.is_collaborator && currentTabIsLastTab) ||
    (!user.is_collaborator && !!id);

  const renderCollaboratorSaveButton =
    (user.is_collaborator && currentTabIsLastTab) ||
    (user.is_collaborator && !!id);

  const renderApprovalButton =
    (currentTabIsLastTab &&
      approvalRequired &&
      !approved &&
      !user.is_collaborator) ||
    (!!id && approvalRequired && !approved && !user.is_collaborator);

  const menuOptions = [
    {
      label: t("authoring.archive"),
      onClick: () => setArchiveModalIsOpen(true),
      enabled: true,
    },
    {
      label: t("authoring.duplicate"),
      onClick: () => setDuplicateModalIsOpen(true),
      enabled: contentType !== "scorm",
    },
    {
      label: t("authoring.discardChanges"),
      onClick: () => setDiscardModalIsOpen(true),
      enabled: true,
    },
  ];

  const onDisabledNextMouseOver = () => {
    if (!canAdvance) {
      if (shouldRenderErrorsOnDisabledNextMouseOver) {
        setRenderErrors(true);
      }

      trigger();

      const errors = Object.entries(formState.errors);

      // @ts-ignore
      const errorElementName = errors[0][1]?.ref?.name;

      const element = document.getElementsByName(errorElementName)[0];

      trigger();

      if (element && element.offsetTop) {
        window.scrollTo({
          top: element.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      {!id && (
        <Button
          variant="createCancel"
          onClick={() => {
            setCanLeave(true);
            setDiscardModalIsOpen(true);
          }}
        >
          {t("authoring.cancel")}
        </Button>
      )}

      {!!id && (
        <Menu variant="create">
          <MenuButton
            as={IconButton}
            icon={<IoEllipsisHorizontal />}
            borderRadius="full"
            border="2px solid"
            borderColor="neutral.200"
            backgroundColor="white"
            color="neutral.400"
            _hover={{ backgroundColor: "neutral.100" }}
          />

          <MenuList>
            {menuOptions.map((option, index) => {
              if (!option.enabled) return null;

              return (
                <MenuItem
                  key={option.label}
                  onClick={option.onClick}
                  borderBottom={
                    index !== menuOptions.length - 1 ? "1px solid" : "none"
                  }
                  borderBottomColor="warmNeutral.200"
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}

      <ButtonGroup gap={2}>
        {onPreviewButtonClick &&
          (renderCoursePreviewButton || renderAssessmentPreviewButton) && (
            <Button
              variant="createCancel"
              leftIcon={<Icon as={BiExpand} />}
              onClick={onPreviewButtonClick}
            >
              {t("authoring.livePreview.preview")}
            </Button>
          )}

        {!id && (
          <OutlineButton
            isDisabled={tabIndex === 0}
            onClick={() => setTabIndex(tabIndex - 1)}
          >
            {t("authoring.previous")}
          </OutlineButton>
        )}

        {!!id && (
          <OutlineIconButton
            icon={<ArrowLeft marginX={6} />}
            aria-label="Previous"
            isDisabled={tabIndex === 0}
            onClick={() => {
              setTabIndex(tabIndex - 1);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        )}

        {!currentTabIsLastTab && !id && (
          <SolidButton
            rightIcon={<ArrowRight />}
            isDisabled={!canAdvance}
            onMouseOver={onDisabledNextMouseOver}
            onClick={() => {
              onProceed();
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            {t("authoring.next")}
          </SolidButton>
        )}

        {!currentTabIsLastTab && !!id && (
          <OutlineIconButton
            icon={<ArrowRight marginX={6} />}
            aria-label="Next"
            isDisabled={!canAdvance}
            onMouseOver={onDisabledNextMouseOver}
            onClick={() => {
              onProceed();
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        )}

        {renderPublishButton && (
          <CreatePublishButton
            isDisabled={!canPublish}
            onClick={onPublishButtonClickOverride || (() => setPublishModalIsOpen(true))}
            onMouseOver={onDisabledNextMouseOver}
          />
        )}

        {renderCollaboratorSaveButton && (
          <SolidButton
            isDisabled={!canPublish}
            onClick={() => setCollaboratorSaveModalIsOpen(true)}
          >
            {t("authoring.save")}
          </SolidButton>
        )}

        {renderApprovalButton && (
          <SolidButton
            isDisabled={!canPublish}
            onClick={() => setPublishModalIsOpen(true)}
          >
            {t("authoring.approve")}
          </SolidButton>
        )}
      </ButtonGroup>

      <DuplicateModal contentTitle={title} />

      <DiscardModal
        isOpen={discardModalIsOpen}
        onClose={() => {
          setCanLeave(false);
          setDiscardModalIsOpen(false);
        }}
        onDiscard={() =>
          discardRoute.includes("pvt/#")
            ? window.location.replace(discardRoute)
            : navigate(discardRoute)
        }
      />

      <ArchiveModal />

      <NoImageModal
        onProceed={() => {
          setTabIndex(tabIndex + 1);
          setNoImageModalIsOpen(false);
        }}
      />

      <PublishModal
        onPublish={() => {
          setCanLeave(true);

          handlePublish();

          setPublishModalIsOpen(false);
        }}
      />

      <CollaboratorSaveModal
        onSave={() => {
          setCanLeave(true);

          handlePublish();

          setCollaboratorSaveModalIsOpen(false);
        }}
      />
    </>
  );
}
