import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToken,
} from "@chakra-ui/react";

import { SketchPicker } from "react-color";

interface ColorPickerProps {
  color: string;
  defaultColor?: string;
  setColor: (prev: any) => void;
}

export default function ColorPicker({
  color,
  defaultColor,
  setColor,
}: ColorPickerProps) {
  const [primaryColor] = useToken("colors", ["brand.primary"]);

  return (
    <Popover placement="left-end">
      <PopoverTrigger>
        <Button
          width={24}
          backgroundColor={color || defaultColor || "brand.primary"}
          _hover={{ color: color || defaultColor || "brand.primary" }}
        />
      </PopoverTrigger>

      <PopoverContent
        backgroundColor="transparent"
        border="none"
        justifyContent="center"
        alignContent="center"
      >
        <PopoverBody display="flex" justifyContent="center">
          <SketchPicker
            disableAlpha
            color={color}
            presetColors={[defaultColor || primaryColor]}
            onChangeComplete={(pickerColor) => {
              setColor(pickerColor.hex);
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

ColorPicker.defaultProps = {
  defaultColor: null,
};
