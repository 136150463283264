import { Box, Image, Text } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";

interface CarouselItemProps {
  item: string;
  attemptNumber: number;
  selectedAttempt: string;
  setSelectedAttempt: (attempt: string) => void;
}

export default function CarouselItem({
  item,
  attemptNumber,
  selectedAttempt,
  setSelectedAttempt,
}: CarouselItemProps) {
  const [thumbnail, setThumbnail] = useState<string>("");

  const generateThumbnail = (link: string) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    let dataURI = "";

    video.addEventListener(
      "loadeddata",
      () => {
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        video.pause();

        dataURI = canvas.toDataURL("image/jpeg");

        setThumbnail(dataURI);
      },
      false
    );

    video.addEventListener(
      "error",
      (error) => {
        setTimeout(() => {
          generateThumbnail(link);
        }, 3500);
      },
      false
    );

    video.preload = "metadata";
    video.muted = true;
    video.playsInline = true;
    video.setAttribute("crossOrigin", "anonymous");
    video.src = link;
    video.play();
  };

  useEffect(() => {
    generateThumbnail(item);
  }, []);

  return (
    <Fragment key={item}>
      <Box
        position="relative"
        borderRadius="lg"
        boxShadow={item === selectedAttempt ? "0 0 0 4px #00bcf1" : ""}
        onClick={() => setSelectedAttempt(item)}
        width="150px"
        height="75px"
      >
        <Image
          backgroundColor="brand.primary"
          src={thumbnail}
          borderRadius="lg"
        />

        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          borderRadius="lg"
          transition=".3s ease all"
          color="transparent"
          background="rgba(0,188,241,0)"
          zIndex={3}
          _hover={{
            background: "rgba(0,188,241,0.5)",
            color: "white",
            cursor: "pointer",
            transform: "scale(1)",
          }}
        />
      </Box>

      <Text align="center" fontSize="xs" marginTop={2}>
        Attempt {attemptNumber}
      </Text>
    </Fragment>
  );
}
