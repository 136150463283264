import { Box, Button, Checkbox, Heading, Stack } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Loading from "@/client/components/media/Loading";
import { useAuthStore } from "@/client/services/state/authStore";
import { useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import v3ApiService from "../../../services/api/clients/v3ApiClient";

export default function NotificationsForm() {
  const { authConfig } = useAuthStore();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const user = authConfig.user;
  const queryClient = useQueryClient();
  const [email, setEmail] = useState(false);
  const [app, setApp] = useState(false);

  const updateNotificationsPreferences = useMutation({
    mutationFn: () =>
      v3ApiService.updateUserNotificationPreferences(user._id, email, app),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["notifications-preferences"] });
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.haveBeenUpdated"),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdateNotifications"),
      }),
  });

  const { isLoading, isError, data, error } = useQuery(
    ["notifications-preferences"],
    async () => await v3ApiService.getUserNotificationPreferences(user._id),
    {
      onSuccess: (data) => {
        setEmail(data.notifications.email);
        setApp(data.notifications.app);
      },
    }
  );

  if (isLoading) return <Loading />;

  if (isError) return <div>Error loading notification preferences </div>;

  return (
    <Box marginTop={12}>
      <Heading as="h3" fontSize="sm" textTransform="uppercase" marginBottom={6}>
        {t("dashboard.settings.notifications_headline")}
      </Heading>

      <Stack spacing={4} marginTop={0}>
        <Checkbox isChecked={email} onChange={() => setEmail(!email)}>
          {t("dashboard.settings.notifications_email")}
        </Checkbox>

        <Checkbox isChecked={app} onChange={() => setApp(!app)}>
          {t("dashboard.settings.notifications_inApp")}
        </Checkbox>

        {(email !== data.notifications.email ||
          app !== data.notifications.app) && (
          <Button onClick={() => updateNotificationsPreferences.mutate()}>
            {t("dashboard.settings.notifications_update")}
          </Button>
        )}
      </Stack>
    </Box>
  );
}
