import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useSuspendUsers = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const suspendUsersMutation = useMutation({
    mutationFn: (rowSelection: { [key: string]: boolean }) =>
      AdminUsersService.suspendUsers(Object.keys(rowSelection)),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("userManager.requested_accounts_suspended"),
      });
    },
    onError: (err) => {
      console.error("Error suspending users: ", err);

      setToast({
        show: true,
        status: "error",
        title: t("userManager.requested_accounts_suspended.error"),
      });
    },
  });

  return {
    suspendUsersMutation,
  };
};
