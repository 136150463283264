import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useToken,
} from "@chakra-ui/react";
import {
  Admin,
  Connect,
  Create,
  Dashboard,
  Explore,
  Manager,
  NavShare,
  Search,
  Workshop,
} from "@/client/components/icons/ContinuIcons";
import { admin, manager } from "@/client/utils/routeLookup";
import { useEffect, useState } from "react";

import ChildAngularLink from "./navLinks/ChildAngularLink";
import ChildReactLink from "./navLinks/ChildReactLink";
import ParentAngularLink from "./navLinks/ParentAngularLink";
import ParentReactLink from "./navLinks/ParentReactLink";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";
import { useLocation } from "react-router-dom";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import { useTranslation } from "react-i18next";

interface SideNavProps {
  initialOpen?: boolean;
}

export default function SideNav({ initialOpen = false }: SideNavProps) {
  const { authConfig } = useAuthStore();
  const { company, user } = authConfig;
  const { partner } = usePartnerStore();
  const { t } = useTranslation();
  const { isManager, isGrader, isPartnerPrincipal, allowance } =
    useAccessHelper();
  const { pathname } = useLocation();
  const [navOpen, setNavOpen] = useState(initialOpen);
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<
    undefined | number[] | number
  >(undefined);
  const [brandColor] = useToken("colors", ["brand.primary"]);
  const [originalLinks, setOriginalLinks] = useState<any[]>([]);
  const [links, setLinks] = useState<any[]>([]);

  useEffect(() => {
    if (isSearching) {
      setCurrentIndex(0);
    } else if (!navOpen) {
      setCurrentIndex(99);
    } else {
      setCurrentIndex(undefined);
    }
  }, [isSearching, navOpen]);

  useEffect(() => {
    const navLinks = [
      {
        name: t("global.vertNavbar.explore"),
        icon: <Explore />,
        route: "/explore",
        enabled: true,
        children: null,
        isAngular: false,
      },
      {
        name: t("global.vertNavbar.dashboard"),
        icon: <Dashboard />,
        route: "/dashboard",
        enabled: true,
        children: null,
        isAngular: false,
      },
      {
        name: t("global.vertNavbar.managers"),
        icon: <Manager />,
        route: manager(),
        enabled: (isManager() || isGrader()) && !partner._id,
        children: null,
        isAngular: true,
      },
      {
        name: t("global.actions.create"),
        icon: <Create boxSize="16px" />,
        enabled:
          allowance.content ||
          allowance.workshops ||
          allowance.assessments ||
          allowance.badges ||
          allowance.surveys,
        children: [
          {
            name: t("global.contentTypes.generic"),
            route: "/pvt/#/overview/content",
            enabled: allowance.content,
            isAngular: true,
          },
          {
            name: t("global.contentTypes.assessments"),
            route: "/pvt/#/overview/assessments",
            enabled: allowance.assessments,
            isAngular: true,
          },
          {
            name: t("dashboard.badges"),
            route: "/pvt/#/overview/badges",
            enabled: allowance.badges,
            isAngular: true,
          },
          {
            name: t("overviewBadges.surveys"),
            route: "/pvt/#/overview/surveys",
            enabled: allowance.surveys,
            isAngular: true,
          },
          {
            name: t("global.contentTypes.workshops"),
            route: "/pvt/#/overview/workshops",
            enabled: allowance.workshops,
            isAngular: true,
          },
        ],
      },
      {
        name: t("global.interactions.share"),
        icon: <NavShare />,
        enabled:
          allowance.assignments || allowance.journeys || allowance.workflows,
        children: [
          {
            name: t("global.contentTypes.assignments"),
            route: "/pvt/#/overview/assignments",
            enabled: allowance.assignments,
            isAngular: true,
          },
          {
            name: t("global.contentTypes.journeys"),
            route: "/pvt/#/overview/journeys",
            enabled: allowance.journeys,
            isAngular: true,
          },
          {
            name: t("global.contentTypes.workflows"),
            route: "/pvt/#/overview/workflows",
            enabled: allowance.workflows,
            isAngular: true,
          },
        ],
      },
      {
        name: "Connect",
        icon: <Connect boxSize="16px" />,
        enabled:
          allowance.connectCategories ||
          allowance.connectLocations ||
          allowance.connectDepartments ||
          allowance.connectTeams ||
          allowance.connectLevels ||
          allowance.connectGrades ||
          allowance.connectGroups ||
          allowance.connectSkills,
        children: [
          {
            name: t("edit.general.sidebar_categories"),
            route: "/admin/connect/categories",
            enabled: allowance.connectCategories,
            isAngular: false,
          },
          {
            name: t("global.label.locations"),
            route: "/admin/connect/locations",
            enabled: allowance.connectLocations,
            isAngular: false,
          },
          {
            name: t("overview.settings.tabs_departments"),
            route: "/admin/connect/departments",
            enabled: allowance.connectDepartments,
            isAngular: false,
          },
          {
            name: t("global.label.teams"),
            route: "/admin/connect/teams",
            enabled: allowance.connectTeams,
            isAngular: false,
          },
          {
            name: t("overview.settings.tabs_org_levels"),
            route: "/admin/connect/levels",
            enabled: allowance.connectLevels,
            isAngular: false,
          },
          {
            name: t("overview.settings.tabs_grades"),
            route: "/admin/connect/grades",
            enabled: allowance.connectGrades,
            isAngular: false,
          },
          {
            name: t("reports.fields.groups"),
            route: "/admin/connect/groups",
            enabled: company.groups && allowance.connectGroups,
            isAngular: false,
          },
          {
            name: t("overview.settings.tabs_skills"),
            route: "/admin/connect/skills",
            enabled: allowance.connectSkills,
            isAngular: false,
          },
        ],
      },
      {
        name: t("global.vertNavbar.overview"),
        icon: <Admin boxSize="16px" />,
        enabled:
          allowance.overview ||
          allowance.apiApplications ||
          allowance.settings ||
          allowance.profiles ||
          allowance.users ||
          allowance.theme,
        children: [
          {
            name: t("overview.admin.headline"),
            route: admin(),
            enabled: allowance.overview,
            isAngular: true,
          },
          {
            name: t("global.developerTools"),
            route: allowance.apiApplications
              ? "admin/developer-tools/api"
              : "admin/developer-tools/webhooks",
            enabled: allowance.apiApplications || allowance.webhooks,
            isAngular: false,
          },
          {
            name: t("overview.settings.integrations"),
            route: "/pvt/#/overview/settings/integrations",
            enabled: allowance.settings,
            isAngular: true,
          },
          {
            name: t("overview.settings.access_profiles"),
            route: "/admin/profiles",
            enabled: allowance.profiles,
            isAngular: false,
          },
          {
            name: t("overviewUsers.stats.users"),
            route: "/admin/users",
            enabled: allowance.users,
            isAngular: false,
          },
          {
            name: t("overview.settings.registration_forms"),
            route: "/admin/connect/registration-forms",
            enabled: allowance.registrationForms,
            isAngular: false,
          },
          {
            name: t("overview.settings.customFields"),
            route: "/admin/users/custom-fields",
            enabled: allowance.metadata,
            isAngular: false,
          },
          {
            name: "Reports",
            route: "/admin/reports",
            enabled: allowance.reports,
            isAngular: true,
          },
          {
            name: t("advancedReports.header"),
            route: "/admin/reports/advanced",
            enabled: allowance.advancedReports,
            isAngular: false,
          },
          {
            name: t("overview.label.partners"),
            route: "/admin/extend",
            enabled: allowance.partners,
            isAngular: false,
          },
          {
            name: t("overviewProducts.products"),
            route: "/overview/products",
            enabled: allowance.ecommerce,
            isAngular: false,
          },
          {
            name: "Theme",
            route: "/admin/theme",
            enabled: allowance.theme,
            isAngular: true,
          },
        ],
      },
      {
        // Name TBD
        name: t("overview.label.partnerManager"),
        icon: <Manager />,
        route: "/extend/manager",
        enabled: allowance.inPartner && isPartnerPrincipal(),
        isAngular: false,
        children: null,
      },
      {
        divider: true,
        enabled: company.feature_flags.enable_dedicated_workshops,
      },
      {
        name: t("global.contentTypes.workshops"),
        icon: <Workshop boxSize="16px" />,
        enabled:
          company.feature_flags.enable_dedicated_workshops &&
          !allowance.inPartner &&
          !user.is_purchaser,
        route: "/workshops",
        children: null,
        isAngular: false,
      },
    ];

    setOriginalLinks(navLinks);
    setLinks(navLinks);
  }, []);

  const onSearchChange = (value: string) => {
    setIsSearching(true);
    setSearchValue(value);

    if (value === "") {
      setLinks(originalLinks);
      setIsSearching(false);
      return;
    }

    const matchedLinks: any[] = [];

    originalLinks.forEach((link) => {
      if (link.children) {
        const matchedChildren = link.children.filter((child: any) =>
          child.name.toLowerCase().includes(value.toLowerCase())
        );

        if (matchedChildren.length > 0) {
          matchedLinks.push({ ...link, children: matchedChildren });
        }
      } else if (
        link.name &&
        link.name.toLowerCase().includes(value.toLowerCase())
      ) {
        matchedLinks.push(link);
      }
    });

    setLinks(matchedLinks || []);
  };

  return (
    <Box
      position="fixed"
      top="50px"
      bottom={0}
      zIndex={1000}
      width={initialOpen ? "180px" : "50px"}
      backgroundColor="white"
      transition=".2s ease all"
      transform="translate(0)"
      overflowY="auto"
      boxShadow={{ base: "none", lg: "lg" }}
      _hover={{ width: "180px" }}
      onMouseEnter={() => setNavOpen(true)}
      onMouseLeave={() => {
        if (!initialOpen) {
          setNavOpen(false);
        }

        setIsSearching(false);
        setSearchValue("");
        setLinks(originalLinks);
      }}
    >
      <Stack spacing={4} paddingX={2} paddingTop={4} overflow="hidden">
        <Flex height="32px" paddingY={1}>
          {navOpen ? (
            <Flex>
              <InputGroup size="sm" width="full">
                <InputLeftElement>
                  <Search color="rgba(26, 26, 26, 0.6)" />
                </InputLeftElement>

                <Input
                  id={
                    "menuSidebarSearch" + Math.floor(Math.random() * 1000000.0)
                  }
                  name={
                    "menuSidebarSearch" + Math.floor(Math.random() * 1000000.0)
                  }
                  color="rgba(26, 26, 26, 0.6)"
                  border="none"
                  backgroundColor="#F9F9F9"
                  placeholder={`${t("global.leftnav.searchmenu")}`}
                  value={searchValue}
                  onChange={(e) => onSearchChange(e.target.value)}
                  borderRadius="5px"
                  autoComplete="off"
                />
              </InputGroup>
            </Flex>
          ) : (
            <Box paddingX={2}>
              <Search color={hexToRGBA(brandColor, 0.6)} />
            </Box>
          )}
        </Flex>

        <Accordion
          index={!navOpen ? 99 : isSearching ? [0, 1, 2, 3, 4] : currentIndex} //! Handles closing the accordion when the nav is closed
          allowMultiple={isSearching}
          allowToggle
          width="full"
        >
          {links.map((link) => {
            if (!link.enabled) return null;

            if (link.divider)
              return (
                <Divider
                  key={"divider_" + link.name}
                  width={!navOpen ? "40%" : "82%"}
                  margin={!navOpen ? "0 24%" : "0 6%"}
                  marginBottom={4}
                  borderWidth={1}
                  borderColor={hexToRGBA(brandColor, 0.2)}
                />
              );

            return (
              <HStack key={link.name} width="full" marginBottom={4}>
                {link.children ? (
                  <AccordionItem border="none" width="full">
                    {({ isExpanded }) => (
                      <>
                        <h6>
                          <AccordionButton
                            paddingY={1}
                            paddingX={0}
                            fontSize="sm"
                            borderRadius="lg"
                            backgroundColor={
                              isExpanded ? hexToRGBA(brandColor, 0.1) : "white"
                            }
                            fontWeight="medium"
                            _hover={{
                              backgroundColor: hexToRGBA(brandColor, 0.05),
                              fontWeight: "bold",
                            }}
                          >
                            <Flex flex="1" alignItems="center">
                              <Flex
                                color={hexToRGBA(brandColor, 0.6)}
                                alignItems="center"
                                justifyContent="center"
                                paddingX={2}
                                paddingY={1}
                                borderRadius="lg"
                                backgroundColor={
                                  link.route?.includes(pathname) && !navOpen
                                    ? hexToRGBA(brandColor, 0.1)
                                    : "transparent"
                                }
                              >
                                {link.icon}
                              </Flex>

                              {navOpen && <Text>{link.name}</Text>}
                            </Flex>

                            {navOpen && (
                              <AccordionIcon
                                marginRight={4}
                                color={hexToRGBA(brandColor, 0.5)}
                              />
                            )}
                          </AccordionButton>
                        </h6>

                        <AccordionPanel
                          marginTop={1}
                          backgroundColor={hexToRGBA(brandColor, 0.1)}
                          borderRadius="lg"
                          padding={3}
                        >
                          <Stack
                            height={
                              link.children.filter(
                                (child: any) => child.enabled
                              ).length * 25
                            }
                            spacing={2}
                          >
                            {link.children.map((child: any) => {
                              if (!child.enabled) return;

                              return !child.isAngular ? (
                                <ChildReactLink
                                  key={"childreactlink_" + child.name}
                                  child={child}
                                />
                              ) : (
                                <ChildAngularLink
                                  key={"childangularlink_" + child.name}
                                  child={child}
                                />
                              );
                            })}
                          </Stack>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ) : !link.isAngular ? (
                  <ParentReactLink
                    link={link}
                    pathname={pathname}
                    brandColor={brandColor}
                    navOpen={navOpen}
                  />
                ) : (
                  <ParentAngularLink
                    link={link}
                    pathname={pathname}
                    brandColor={brandColor}
                    navOpen={navOpen}
                  />
                )}
              </HStack>
            );
          })}
        </Accordion>
      </Stack>
    </Box>
  );
}
