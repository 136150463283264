import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BsPencil, BsPlusCircle } from "react-icons/bs";
import { Fragment, useState } from "react";

import { BiErrorCircle } from "react-icons/bi";
import ContentAuthoringBox from "@/client/components/admin/layout/ContentAuthoringBox";
import type { CourseFormData } from "@/client/routes/admin/create/content/tracks/EditCourse";
import { FiTrash } from "react-icons/fi";
import type { Instructor } from "@/client/services/api/graphql/gql/graphql";
import InstructorEditModal from "../overlay/InstructorEditModal";
import InstructorSearchDrawer from "../overlay/InstructorSearchDrawer";
import _ from "lodash";
import { useDebounceValue } from "usehooks-ts";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import HtmlRenderer from "@/client/components/html/HtmlRenderer";

export default function CourseInstructorsForm() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEditInstructor, setSelectedEditInstructor] = useState<Omit<
    Instructor,
    "archived"
  > | null>(null);
  const [searchTerm, setSearchTerm] = useDebounceValue("", 300);
  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<CourseFormData>();

  const instructors = watch("instructors");

  const iconButtonStyles = {
    border: "2px solid",
    borderColor: "neutral.100",
    borderRadius: "full",
    height: "40px",
    width: "40px",
    backgroundColor: "transparent",
    _hover: {
      backgroundColor: "neutral.100",
    },
  };

  return (
    <>
      <ContentAuthoringBox>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="createHeading">
            {t("authoring.courses.instructors.add")}
          </Text>
        </Flex>

        {instructors.length === 0 && errors.instructors && (
          <Alert variant="createError">
            <AlertIcon as={BiErrorCircle} />

            <AlertDescription>
              {errors.instructors.message as string}
            </AlertDescription>
          </Alert>
        )}

        {instructors && instructors.length > 0 && (
          <Stack>
            {instructors.map((instructor: any) => (
              <Fragment key={instructor.id}>
                <Grid templateColumns="repeat(6, 1fr)" gap={8} padding={8}>
                  <GridItem colSpan={1}>
                    <Avatar
                      backgroundColor="neutral.100"
                      color="neutral.400"
                      name={instructor.name}
                      src={instructor.image}
                    />
                  </GridItem>

                  <GridItem colSpan={5}>
                    <Flex height={6} width="full" justifyContent="flex-end">
                      <ButtonGroup>
                        <IconButton
                          sx={iconButtonStyles}
                          icon={<Icon as={BsPencil} color="neutral.400" />}
                          aria-label={t("authoring.courses.instructors.edit")}
                          onClick={() => {
                            setSelectedEditInstructor(instructor);
                            setModalIsOpen(true);
                          }}
                        />

                        <IconButton
                          sx={iconButtonStyles}
                          icon={<Icon as={FiTrash} color="red" />}
                          aria-label={t("authoring.courses.instructors.remove")}
                          onClick={() =>
                            setValue(
                              "instructors",
                              _.without(instructors, instructor)
                            )
                          }
                        />
                      </ButtonGroup>
                    </Flex>

                    <Stack spacing={8}>
                      <Stack>
                        <Text variant="createHeading">{instructor.name}</Text>

                        {instructor.email && (
                          <Text fontSize="14px">{instructor.email}</Text>
                        )}
                      </Stack>

                      {instructor.bio && (
                        <Stack>
                          <Text variant="createHeading">
                            {t("authoring.courses.instructors.bio")}
                          </Text>

                          <Box className="description" fontSize="14px">
                            <HtmlRenderer html={instructor.bio} />
                          </Box>
                        </Stack>
                      )}

                      {instructor.notes && (
                        <Stack>
                          <Text variant="createHeading">
                            {t("authoring.courses.instructors.notes")}
                          </Text>

                          <Box className="description" fontSize="14px">
                            <HtmlRenderer html={instructor.notes} />
                          </Box>
                        </Stack>
                      )}

                      {instructor.socials &&
                        Object.entries(instructor.socials).filter(
                          ([k, v]) => v !== "" && v !== null
                        ).length > 0 && (
                          <Stack>
                            <Text variant="createHeading">
                              {t("authoring.courses.instructors.socialMedia")}
                            </Text>

                            {Object.entries(instructor.socials).map(
                              ([key, value]) => {
                                if (!value) return null;

                                return (
                                  <Text fontSize="14px" key={key}>
                                    {value as string}
                                  </Text>
                                );
                              }
                            )}
                          </Stack>
                        )}
                    </Stack>
                  </GridItem>
                </Grid>

                <Divider borderColor="neutral.100" />
              </Fragment>
            ))}
          </Stack>
        )}

        <ButtonGroup width="full" marginTop={8}>
          <Button
            variant="warmNeutralDashedBorder"
            width="full"
            leftIcon={<BsPlusCircle />}
            onClick={() => setModalIsOpen(true)}
          >
            {t("authoring.courses.instructors.addNew")}
          </Button>

          <Button
            variant="warmNeutralDashedBorder"
            width="full"
            leftIcon={<BsPlusCircle />}
            onClick={() => setDrawerIsOpen(true)}
          >
            {t("authoring.courses.instructors.addSaved")}
          </Button>
        </ButtonGroup>
      </ContentAuthoringBox>

      <InstructorEditModal
        instructorData={selectedEditInstructor}
        isOpen={modalIsOpen}
        onClose={() => {
          trigger();
          setSelectedEditInstructor(null);
          setModalIsOpen(false);
          queryClient.invalidateQueries(["instructors", searchTerm]);
        }}
      />

      <InstructorSearchDrawer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
        onClose={() => {
          trigger();
          setDrawerIsOpen(false);
        }}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setEditInstructor={setSelectedEditInstructor}
        setEditModalIsOpen={setModalIsOpen}
      />
    </>
  );
}
