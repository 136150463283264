import { PointProps } from "@/client/types/Charts";

export function Diamond({ size, color, borderWidth, borderColor }: PointProps) {
  const svgPoints = [
    `${-size / 2},0`,
    `0,${size / 2}`,
    `${size / 2},0`,
    `0,${-size / 2}`,
  ];
  return (
    <g>
      <polygon
        points={svgPoints.join(" ")}
        fill={color}
        stroke={borderColor}
        strokeWidth={borderWidth}
        style={{ pointerEvents: "none" }}
      />
    </g>
  );
}
