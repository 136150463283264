export const urlProtocol = (url: string) => {
  if (url.startsWith("http")) {
    return url;
  }

  if (url.startsWith("://")) {
    return `https${url}`;
  }

  if (url.startsWith("//")) {
    return `https:${url}`;
  }

  return `https://${url}`;
};
