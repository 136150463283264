import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import DeleteItemModal from "@/client/components/admin/overlay/DeleteItemModal";
import ThreeColumnLayout from "@/client/components/layout/admin/ThreeColumnLayout";
import { useConnect } from "@/client/services/hooks/admin/connect/useConnect";
import { useConnectStore } from "@/client/services/state/admin/connectStore";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Departments() {
  const { t } = useTranslation();
  const { deleteItem } = useConnect();
  const {
    setType,
    setShowExternalOption,
    setColumnCopy,
    clearStore,
    setSortOrder,
    setSearchTerm,
    isSearching,
    sortOrder,
  } = useConnectStore();

  const columnOneTitle = t("admin.connect.departments.columnOneTitle");
  const columnTwoTitle = t("admin.connect.departments.columnTwoTitle");
  const columnThreeTitle = t("admin.connect.departments.columnThreeTitle");
  const plural = t("admin.connect.departments.plural");
  const singular = t("admin.connect.departments.singular");
  const subDepartmentsPlural = t(
    "admin.connect.departments.columnTwoTitlePlural"
  );
  const subSubDepartmentsPlural = t(
    "admin.connect.departments.columnThreeTitlePlural"
  );

  const columnCopy = {
    plural,
    singular,
    columnOneTitle,
    columnTwoTitle,
    columnThreeTitle,
    noSubItemsText: t("admin.connect.noSubItems", {
      subType: columnTwoTitle,
      mainType: columnOneTitle,
    }),
    noSubSubItemsText: t("admin.connect.noSubSubItems", {
      subSubType: columnThreeTitle,
      subType: columnTwoTitle,
    }),
    noResultsMain: t("admin.connect.noSearchResultsMain", { type: plural }),
    noResultsSub: t("admin.connect.noSearchResultsSub", {
      subType: subDepartmentsPlural,
      mainType: columnOneTitle,
    }),
    noResultsSubSub: t("admin.connect.noSearchResultsSubSub", {
      subSubType: subSubDepartmentsPlural,
      subType: columnTwoTitle,
    }),
  };

  useEffect(() => {
    clearStore();
    setType("department");
    setShowExternalOption(false);
    setColumnCopy(columnCopy);
  }, []);

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t("admin.connect.searchPlaceholder", { type: singular })}
        showSortSelector
        title={plural}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isSearching={isSearching}
      />

      <ThreeColumnLayout />

      <DeleteItemModal
        subType={subDepartmentsPlural}
        subSubType={subSubDepartmentsPlural}
        deleteItem={deleteItem}
      />
    </>
  );
}
