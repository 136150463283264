/* eslint-disable no-script-url */

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Collapse,
  Container,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { AngleDown, AngleUp } from "@/client/components/icons/ContinuIcons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import Certificate from "@/client/components/data-display/certificates/Certificate";
import CommentList from "@/client/components/lists/CommentList";
import ContentHeader from "../../components/layout/ContentHeader";
import ContentLength from "../../components/data-display/content/ContentLength";
import ContentRatingGroup from "@/client/components/data-display/ratings/ContentRatingGroup";
import ContentRatingInput from "@/client/components/data-display/ratings/ContentRatingInput";
import ContentService from "@/client/services/api/ContentService";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import HtmlRenderer from "@/client/components/html/HtmlRenderer";
import Loading from "@/client/components/media/Loading";
import NotAuthorized from "@/client/components/data-display/content/NotAuthorized";
import PopupSurveyLayout from "@/client/components/overlay/PopupSurvey";
import Recommendations from "@/client/components/layout/Recommendations";
import ScormService from "@/client/services/api/ScormService";
import ViewActions from "@/client/components/overlay/ViewActions";
import axios from "axios";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCheckContentAuth } from "@/client/services/hooks/content/useCheckContentAuth";
import { useCheckSurveyCompletion } from "@/client/services/hooks/useCheckSurveyCompletion";
import { useConfigStore } from "@/client/services/state/configStore";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { useGetScorm } from "@/client/services/hooks/content/scorm/useGetScorm";
import { useMutation } from "@tanstack/react-query";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface ScormProps {
  journeyId?: string;
  trackId?: string;
  runCompletionSideEffects?: Function;
  contentId: string;
  contextId?: string;
  needsContextSelection?: boolean;
  isPreview?: boolean;
}

export default function Scorm({
  journeyId,
  trackId,
  runCompletionSideEffects,
  contentId,
  contextId,
  needsContextSelection,
  isPreview = false,
}: ScormProps) {
  const location = useLocation();
  const { config } = useConfigStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { t } = useTranslation();
  const { allowView } = useCheckContentAuth();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const { partner } = usePartnerStore();
  const scormEnabled = authConfig.company.scorm;
  const scormLimit = authConfig.company.scorm_limit;
  const scormEnforcementType =
    authConfig.company.scorm_limit_enforcement || "soft";
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const [markedCompleted, setMarkedCompleted] = useState(false);
  const [certificateDate, setCertificateDate] = useState<Date | string>("");
  const [showCertificate, setShowCertificate] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isIframePopulated, setIsIframePopulated] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const [canGoBack, setCanGoBack] = useState(false);
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [renderRecommendations, setRenderRecommendations] =
    useState<boolean>(false);

  if (!scormEnabled) {
    setToast({
      show: true,
      status: "error",
      title: "SCORM Content is unavailable for the company.",
    });
    navigate("/explore");
  }

  useEffect(() => {
    if (!needsContextSelection && isAllowed && !isPreview) {
      AnalyzeService.postViews(contentId, "scorm");
      ContentService.postViews(contentId, "scorm");
    }

    // TODO: End content session tracking here
    return () => console.log("Leaving Scorm...");
  }, [needsContextSelection, isAllowed]);

  useEffect(() => {
    const handleScormEvents = (event: any) => {
      // On Close
      if (event && event.data && event.data === "postFinish") {
        if (canGoBack) {
          if (trackId) {
            return;
          }

          if (journeyId) {
            // console.log('Completion events finished');
            // setSelectedContent(null);
            // navigate(`/journey/${journeyId}`);
          }
        }
        if (trackId && runCompletionSideEffects && markedCompleted) {
          // TODO replace with custom scorm event aftere set-data call is completed
          runCompletionSideEffects();
        }
      }

      // On Complete
      if (
        event &&
        event.data &&
        event.data === "statusCompleted" &&
        !markedCompleted
      ) {
        setMarkedCompleted(true);
        setCertificateDate(new Date());
        setShowCertificate(true);

        if (trackId && runCompletionSideEffects) {
          // TODO replace with custom scorm event aftere set-data call is completed
          setTimeout(runCompletionSideEffects, 6000);
        }
      }
    };

    window.addEventListener(
      "message",
      (event) => handleScormEvents(event),
      false
    );

    return () => {
      window.removeEventListener("message", handleScormEvents, false);
    };
  }, []);

  const showArchivedMessage = () => {
    setToast({
      show: true,
      status: "error",
      title: "SCORM Content has been archived and is no longer available",
    });
    navigate("/explore");
  };

  const {
    isLoading,
    isError,
    data,
    refetch: refetchScorm,
  } = useGetScorm(
    contentId,
    contextId,
    scormLimit,
    scormEnforcementType,
    showArchivedMessage,
    isPreview
  );

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data.scorm);

        setIsAllowed(allowed);

        if (markedCompleted && !trackId && !journeyId && !isPreview) {
          if (data.userRegistration.registration.completed_date) {
            const completedDate =
              data.userRegistration.registration.completed_date;

            setCertificateDate(completedDate.toString().slice(0, -1));
            setShowCertificate(true);
          }
        }
      }
    })();
  }, [data, markedCompleted]);

  const register = useMutation({
    mutationFn: () => ScormService.setRegistrationDataUrl(contentId, user._id),
    onSuccess: () => refetchScorm(),
    onError: (err) => console.log("err: ", err),
  });

  const setIframe = (frameData: string) => {
    const iframe = document.querySelector<HTMLIFrameElement>("#playeriframe");
    if (iframe && iframe.contentWindow && !isIframePopulated) {
      setIsIframePopulated(true);

      try {
        iframe.contentWindow.contents = frameData;
        if (
          iframe.contentWindow.localStorage.getItem("i18nextLng") === "es_419"
        ) {
          iframe.contentWindow.localStorage.setItem("i18nextLng", "es");
        }
        iframe.src = 'javascript:window["contents"]';
        setIsViewing(true);
        setCanGoBack(true);
      } catch (error) {
        console.log("FAILED SETTING IFRAME error: ", error);
      }
    }
  };

  const createPlayerUrl = () => {
    if (isPreview) {
      setIsViewing(true);
    }
    window.scormPreview = isPreview;
    window.sdht = authConfig.authtoken;
    window.sdad = config.apiUrl;

    const courseDirectory = user.company + "/" + contentId;
    window.courseDirectory = "/scorm-package/" + courseDirectory + "/";
    window.courseId = contentId;
    window.studentName = user.last_name + "," + user.first_name;
    window.studentId = user._id;
    window.userContentId = contextId;

    axios
      .post(
        "/api/cloudfront-cookie/scorm",
        {
          host: window.location.hostname,
        },
        {
          headers: {
            Authorization: "AuthToken token=" + authConfig.authtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        axios
          .get("/api/player")
          .then((res) => {
            setIframe(res.data);
          })
          .catch((err) => console.log("err: ", err));
      })
      .catch((err) => {
        // If they don't get a cookie, they probably won't be able to view the content.
        // We should consider if we want to make a visible error on this.  We don't when
        // the player fails to load, so for consistency's sake we aren't here.
        console.log("Error at /api/cloudfront-cookie/scorm: ", err);
      });
  };

  useDocumentTitle(data ? data.scorm.title : "SCORM", true);

  useEffect(() => {
    if (data) {
      if (isPreview) {
        window.sslaConfigSetup = data.scorm.ssla_config;
        createPlayerUrl();
      }

      if (data.userRegistration) {
        window.sslaConfigSetup = data.scorm.ssla_config;
        checkShowPopupSurvey(
          data.scorm,
          data.userRegistration.registration.status === "passed" ||
            data.userRegistration.registration.status === "completed",
          !!trackId || !!journeyId
        );

        if (
          data.userRegistration.registration.status === "passed" ||
          (data.userRegistration.registration.status === "completed" &&
            !trackId &&
            !journeyId)
        ) {
          setRenderRecommendations(true);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (!needsContextSelection && data?.userRegistration) {
      createPlayerUrl();
    }
  }, [needsContextSelection, data?.userRegistration]);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get SCORM" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <>
      {isAllowed && (
        <>
          <Box>
            {!trackId && !journeyId && !isPreview && (
              <ContentHeader
                contentType="scorm"
                contentTitle={data.scorm.title}
                contentId={data.scorm._id}
                withBorder
                allowComments={data.scorm.allow_comments}
                navigateAction={
                  location.state?.originRoute
                    ? () => navigate(location.state.originRoute)
                    : () => navigate("/explore")
                }
              />
            )}

            {((isPreview && isViewing) || data.userRegistration) && (
              <Center marginTop="2.5rem">
                <iframe
                  id="playeriframe"
                  title="playeriframe"
                  style={{
                    backgroundColor: "#f7f7f7",
                    maxWidth: "2440px",
                    width: "100vw",
                    height: "70vh",
                    display: "block",
                  }}
                  src="about:blank"
                />
              </Center>
            )}

            {!data.userRegistration && !isPreview && (
              <Center
                backgroundColor="brand.primary"
                minH="400px"
                backgroundImage={`url(${data.scorm.banner_image})`}
                backgroundSize="cover"
                backgroundPosition="center center"
                pos="relative"
                paddingY="180px"
              >
                <Box
                  w="100%"
                  h="100%"
                  background="black"
                  position="absolute"
                  top="0"
                  left="0"
                  zIndex="3"
                  opacity="0.5"
                />

                <VStack spacing={4} zIndex="5">
                  <Text
                    as="h1"
                    color="white"
                    marginY="0"
                    fontWeight="300"
                    fontSize="34px"
                  >
                    {data.scorm.title}
                  </Text>

                  {data.scorm.duration && (
                    <ContentLength
                      duration={data.scorm.duration}
                      color="white"
                      size="lg"
                    />
                  )}

                  {data.scorm.rating_configuration &&
                    data.scorm.rating_configuration.allow_rating &&
                    data.scorm.average_rating && (
                      <ContentRatingGroup
                        averageRating={data.scorm.average_rating}
                        ratingConfiguration={data.scorm.rating_configuration}
                      />
                    )}

                  {!data.scormLimitEnforced && (
                    <Button onClick={() => register.mutate()}>
                      {t("scormviewer.begin_course")}
                    </Button>
                  )}

                  {data.scormLimitEnforced && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertTitle>
                        {t("scormviewer.scorm_limit_reached")}
                      </AlertTitle>
                      <AlertDescription>
                        {t("scormviewer.acct_limit_reached")}
                      </AlertDescription>
                    </Alert>
                  )}
                </VStack>
              </Center>
            )}

            <Container maxWidth="container.md" marginTop={12}>
              {!isViewing && (
                <HtmlRenderer html={data.scorm.description} width="700px" />
              )}

              {isViewing && (
                <>
                  <Button
                    size="xs"
                    variant="ghost"
                    leftIcon={isOpen ? <AngleUp /> : <AngleDown />}
                    marginBottom={4}
                    onClick={onToggle}
                  >
                    {isOpen
                      ? `${t("scormviewer.show")} ${t("scormviewer.details")}`
                      : `${t("scormviewer.hide")} ${t("scormviewer.details")}`}
                  </Button>

                  <Collapse in={isOpen}>
                    {data.scorm.description ? (
                      <HtmlRenderer html={data.scorm.description} />
                    ) : (
                      t("scormviewer.no_text")
                    )}
                  </Collapse>
                </>
              )}

              {renderRecommendations && (
                <Recommendations contentId={contentId} />
              )}

              {data.scorm.rating_configuration &&
                data.scorm.rating_configuration.allow_rating && (
                  <ContentRatingInput
                    contentId={data.scorm._id}
                    contentType="ScormContent"
                    ratingConfiguration={data.scorm.rating_configuration}
                  />
                )}

              {data.scorm.allow_comments && isViewing && !partner._id && (
                <CommentList
                  contentId={contentId}
                  contentType="scorm"
                  contentTitle={data.scorm.title}
                />
              )}

              {!trackId && <ViewActions buttons={["gototop"]} />}
            </Container>
          </Box>

          {!trackId && (
            <ViewActions
              buttons={["gototop"]}
              bottomOffset={showPopup ? 10 : 0}
            />
          )}

          {showCertificate && data.scorm.certificate && (
            <Certificate
              contentTitle={data.scorm.title}
              completedDate={certificateDate}
              userFullName={user.full_name}
              userMetadata={user.metadata}
              contentMetadata={data.scorm.metadata}
              certificateData={data.scorm.certificate_data}
              onClose={() => setShowCertificate(false)}
            />
          )}

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.scorm.surveys[0].survey_id}
              contentId={data.scorm._id}
              contentType="scorm"
            />
          )}
        </>
      )}

      <Box />
    </>
  );
}
