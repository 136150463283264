import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";

import ControlledRadioGroup from "../../forms/ControlledRadioGroup";
import { PartnerPermissions } from "@/client/types/Partner";
import { useAdminStore } from "@/client/services/state/admin/adminStore";
import { useConnectStore } from "@/client/services/state/admin/connectStore";
import { useTranslation } from "react-i18next";

export default function PartnerPermissionModal() {
  const { t } = useTranslation();
  const { partnerPermissionModal, setPartnerPermissionModal } = useAdminStore();
  const { setSelectedEditItem, selectedEditItem } = useConnectStore();

  const onClose = () =>
    setPartnerPermissionModal({
      isOpen: false,
      isControlled: false,
      selectedItem: null,
      value: "tenant",
      handleSubmit: null,
    });

  const permissionOptions = [
    {
      label: t("content.edit.partners.tenant"),
      value: "tenant",
    },
    {
      label: t("content.edit.partners.allPartners"),
      value: "partner",
    },
    {
      label: t("content.edit.partners.partnerAndTenant"),
      value: "partner_and_tenant",
    },
  ];

  return (
    <Modal size="xl" isOpen={partnerPermissionModal.isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <Stack>
            <Text size="2xl">{t("content.edit.partners.label")}</Text>
            <Text fontSize="xs" fontWeight={500} align="left">
              {t("content.edit.partners.canBeSeenBy")}
            </Text>
          </Stack>
        </ModalHeader>

        <ModalBody>
          {partnerPermissionModal.isControlled && (
            <ControlledRadioGroup
              options={permissionOptions}
              name="partnerPermissions"
            />
          )}
          {!partnerPermissionModal.isControlled && (
            <RadioGroup
              variant="admin"
              value={partnerPermissionModal.value}
              onChange={(v: PartnerPermissions) => {
                setSelectedEditItem({
                  ...partnerPermissionModal.selectedItem,
                  partner_permissions: v,
                });
                setPartnerPermissionModal({
                  ...partnerPermissionModal,
                  value: v,
                });
              }}
            >
              <Stack spacing={4}>
                {permissionOptions.map((option) => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        </ModalBody>

        <ModalFooter width="full" display="flex" justifyContent="flex-end">
          <Button variant="adminCancel" mr={3} onClick={onClose}>
            {t("global.actions.cancel")}
          </Button>

          <Button
            variant="adminPrimary"
            onClick={() => {
              partnerPermissionModal.handleSubmit &&
                partnerPermissionModal.handleSubmit(selectedEditItem);
              onClose();
            }}
          >
            {t("global.actions.save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
