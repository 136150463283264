import { useEffect, useState } from "react";

import DiscardModal from "@/client/components/admin/create/shared/overlay/DiscardModal";
import { useBlocker } from "react-router-dom";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";

interface CreateNavigationBlockerProps {
  enabled: boolean;
}

export default function CreateNavigationBlocker({
  enabled,
}: CreateNavigationBlockerProps) {
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const { trackState, setTrackState } = useTrackStateStore();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      enabled && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") setDiscardModalIsOpen(true);
  }, [blocker.state]);

  const handleLeavePage = () => {
    if (blocker && blocker.proceed) {
      if (trackState) {
        setTrackState(null);
        localStorage.removeItem("trackState");
      }

      blocker.proceed();
      setDiscardModalIsOpen(false);
    }
  };

  const handleStayOnPage = () => {
    if (blocker && blocker.reset) {
      blocker.reset();
      setDiscardModalIsOpen(false);
    }
  };

  return (
    <DiscardModal
      isOpen={discardModalIsOpen}
      onClose={handleStayOnPage}
      onDiscard={handleLeavePage}
    />
  );
}
