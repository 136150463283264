import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useResetUserPasswords = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const resetPasswordsMutation = useMutation({
    mutationFn: (rowSelection: { [key: string]: boolean }) =>
      AdminUsersService.resetUserPasswords(Object.keys(rowSelection)),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("userManager.requested_accounts_reset"),
      });
    },

    onError: (err) => {
      console.error("Error resetting user passwords: ", err);

      setToast({
        show: true,
        status: "error",
        title: t("userManager.requested_accounts_reset.error"),
      });
    },
  });

  return {
    resetPasswordsMutation,
  };
};
