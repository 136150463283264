/* eslint react/jsx-no-useless-fragment: 0 */

import { useMutation, useQuery } from "@tanstack/react-query";

import { Button } from "@chakra-ui/react";
import { Google } from "@/client/components/icons/ContinuIcons";
import MeetsService from "@/client/services/api/integrations/MeetsService";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export default function MeetsActivationButton() {
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  const { refetch, data } = useQuery({
    queryKey: ["meets-permissionlink"],
    queryFn: () => MeetsService.meetsPermissionLink(),
    onError: (e) => {
      console.log(e);
    },
  });

  const {
    refetch: refetchExists,

    isError: existsIsError,
    data: existsData,
  } = useQuery({
    queryKey: ["meets-exists-with-user"],
    queryFn: () => MeetsService.existsWithUser(),
    onError: (e) => {
      console.log(e);
    },
  });

  const uninstallMutation = useMutation({
    mutationFn: () => MeetsService.uninstall(),
    onSuccess: (res: any) => {
      if (res.success === true) {
        refetch();
        refetchExists();
        setToast({
          show: true,
          status: "success",
          title: "You have removed Google access.",
        });
      }
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const clickHandler = () => {
    if (existsData && existsData.exists) uninstallMutation.mutate();
    else if (data) window.location.href = data.redirect_url;
  };

  return (
    <>
      {!existsIsError && existsData && data && (
        <Button
          onClick={clickHandler}
          variant="integrations"
          leftIcon={<Google boxSize={5} />}
        >
          {existsData.exists
            ? t("profileEdit.removeGoogleMeetsIntegration")
            : "Enable Google Meet"}
        </Button>
      )}
    </>
  );
}
