/* eslint-disable react/jsx-props-no-spreading */
import { BaseEditor, Descendant, createEditor } from "slate";
import { Controller, useFormContext } from "react-hook-form";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";

import FormLabelWithTooltip from "./FormLabelWithTooltip";
import { IoPricetagsOutline } from "react-icons/io5";
import { deserialize } from "@/client//utils/content/slate/deserialize";
import { serialize } from "@/client/utils/content/slate/serialize";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// TODO: Style this component

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type SlateEditorProps = {
  name: string;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  insertOptions?: Record<string, string>;
};

export default function ControlledSlateEditor({
  name,
  label,
  placeholder,
  isRequired,
  insertOptions,
}: SlateEditorProps) {
  const [editor] = useState(() => withReact(createEditor()));
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const { insertText } = editor;

  const value = watch(name);

  const placeholderText = t("global.forms.labels_typeYourText");

  const formatInitialValue = (initialValue: string) => {
    const document = new DOMParser().parseFromString(initialValue, "text/html");

    return deserialize(document.body);
  };

  const initialValue: Descendant[] =
    value && value !== "<p><br /></p>"
      ? formatInitialValue(value)
      : [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ];

  const onHandleChange = (changedValue: Descendant[]) => {
    // eslint-disable-next-line yoda
    const isAstChange = editor.operations.some(
      (operation) => "set_selection" !== operation.type
    );

    if (isAstChange) {
      setValue(name, serialize(changedValue));
    }
  };

  return (
    <Stack spacing={2}>
      <FormLabelWithTooltip label={label} isRequired={isRequired} />

      {insertOptions && (
        <Menu>
          <MenuButton
            size="sm"
            as={IconButton}
            variant="ghost"
            width={6}
            aria-label="Predefined Insert Options"
            icon={<IoPricetagsOutline />}
          />
          <MenuList>
            {Object.entries(insertOptions).map(([k, v]) => (
              <MenuItem key={k} onClick={() => insertText(` {${k}}`)}>
                {v}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}

      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: isRequired || false,
            message: "This field is required",
          },
        }}
        render={({ field }) => (
          <Slate
            {...field}
            editor={editor}
            value={initialValue}
            onChange={(v) => onHandleChange(v)}
          >
            <Editable placeholder={placeholder || placeholderText} />
          </Slate>
        )}
      />
    </Stack>
  );
}

ControlledSlateEditor.defaultProps = {
  placeholder: null,
  isRequired: false,
  insertOptions: null,
};
