import { learnApiClient } from "@/client/services/api/clients/learnApiClient";

const smartSearch = async (searchTerm: string) => {
  const response = await learnApiClient.get(`text-search/smart-search`, {
    params: {
      term: searchTerm,
      types:
        "articles,videos,files,assessments,tracks,scorm,workshops,users,categories",
    },
  });

  return [
    { title: "Articles", options: response.data.articles },
    { title: "Videos", options: response.data.videos },
    { title: "Files", options: response.data.files },
    { title: "Assessments", options: response.data.assessments },
    { title: "Tracks", options: response.data.tracks },
    { title: "Scorm", options: response.data.scorm },
    { title: "Workshops", options: response.data.workshops },
    { title: "Users", options: response.data.users },
  ];
};

const SmartSearchService = {
  smartSearch,
};

export default SmartSearchService;
