import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ContentTypes,
  type CategoryLineage,
  type Content,
} from "@/client/services/api/graphql/gql/graphql";

import type { AdminCategoriesData } from "@/client/types/admin/categories/AdminCategoriesData";
import { CloseIcon } from "@chakra-ui/icons";
import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "@/client/components/admin/create/shared/layout/CreateSwitchContainer";
import { FaPlusCircle } from "react-icons/fa";
import OutlineButton from "@/client/components/shared/buttons/OutlineButton";
import SolidButton from "@/client/components/shared/buttons/SolidButton";
import { useFormContext } from "react-hook-form";
import { useGetOrganizedCategories } from "@/client/services/hooks/admin/categories/useGetOrganizedCategories";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";
import { useTranslation } from "react-i18next";

// TODO: Remove AdminCategoriesData type

export default function ContentExploreSettingsForm() {
  const { trackState } = useTrackStateStore();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { watch, setValue } = useFormContext<Content>();

  const showOnExplore = watch("exploreHide");

  const selectedCategories = watch("linkedCategory") || [];
  const contentType = watch("contentType");

  const handleCategorySelection = (category: AdminCategoriesData) => {
    const formattedCategory: CategoryLineage = {
      id: category._id,
      name: category.name,
      parent: category.parent,
    };

    const alreadySelected = selectedCategories.some(
      (selectedCategory) => selectedCategory.id === formattedCategory.id
    );

    if (alreadySelected) {
      setValue(
        "linkedCategory",
        selectedCategories.filter(
          (selectedCategory) => selectedCategory.id !== formattedCategory.id
        )
      );
      return;
    }

    setValue("linkedCategory", [...selectedCategories, formattedCategory]);
  };

  const { data } = useGetOrganizedCategories();

  return (
    <CreateFormGroup>
      <Stack spacing={4}>
        <CreateSwitchContainer
          name="exploreHide"
          heading={t("authoring.audience.exploreSettings")}
          headingHelpText={t("authoring.audience.exploreSettings.helpText")}
        />

        {(!trackState || contentType === ContentTypes.Track) && (
          <Collapse in={showOnExplore} animateOpacity>
            <Stack>
              {selectedCategories.length > 0 && (
                <Stack>
                  {selectedCategories.map((category) => {
                    const { name, parent } = category;
                    const grandParent = parent?.parent;

                    return (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        paddingY={2}
                        paddingX={4}
                        borderRadius="md"
                        backgroundColor="warmNeutral.100"
                        border="1px solid"
                        borderColor="warmNeutral.300"
                      >
                        <Text as="span">
                          {grandParent?.name && `${grandParent.name} -> `}
                          {parent?.name && `${parent.name} -> `}
                          <Text as="b">{name}</Text>
                        </Text>

                        <IconButton
                          color="neutral.1200"
                          variant="ghost"
                          aria-label={t(
                            "authoring.audience.exploreSettings.categories.removeCategory"
                          )}
                          icon={<CloseIcon />}
                          _hover={{
                            backgroundColor: "transparent",
                          }}
                          onClick={() =>
                            setValue(
                              "linkedCategory",
                              selectedCategories.filter(
                                (selectedCategory) =>
                                  selectedCategory.id !== category.id
                              )
                            )
                          }
                        />
                      </Flex>
                    );
                  })}
                </Stack>
              )}

              <Button
                width="full"
                variant="outline"
                paddingY={6}
                paddingX={8}
                backgroundColor="transparent"
                borderColor="warmNeutral.200"
                color="baseBlack"
                fontWeight={500}
                leftIcon={<Icon as={FaPlusCircle} color="warmNeutral.600" />}
                _hover={{
                  borderColor: "warmNeutral.500",
                }}
                onClick={onOpen}
              >
                {t("authoring.audience.exploreSettings.categories.addCategory")}
              </Button>
            </Stack>
          </Collapse>
        )}
      </Stack>

      <Modal
        size="6xl"
        isOpen={isOpen && !!data?.organizedCategories.length}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent paddingTop={8}>
          <ModalHeader>
            <Text variant="createHeading">
              {t(
                "authoring.audience.exploreSettings.categories.selectCategories"
              )}
            </Text>

            <Text variant="createHelpText">
              {t(
                "authoring.audience.exploreSettings.categories.selectCategories.helpText"
              )}
            </Text>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            {data?.featuredCategory && (
              <Box paddingY={6}>
                <Checkbox
                  variant="createSimple"
                  isChecked={selectedCategories.some(
                    (selectedCategory) =>
                      selectedCategory.id === data.featuredCategory._id
                  )}
                  onChange={() =>
                    handleCategorySelection(data.featuredCategory)
                  }
                >
                  {data.featuredCategory.name}
                </Checkbox>
              </Box>
            )}

            <Accordion allowMultiple>
              {data?.organizedCategories.map((category) => (
                <AccordionItem key={category._id} paddingY={6}>
                  <Flex justifyContent="space-between">
                    <Checkbox
                      variant="createSimple"
                      isChecked={selectedCategories.some(
                        (selectedCategory) =>
                          selectedCategory.id === category._id
                      )}
                      onChange={() => handleCategorySelection(category)}
                    >
                      {category.name}
                    </Checkbox>

                    {category.fullChildren.length > 0 && (
                      <AccordionButton
                        display="flex"
                        justifyContent="flex-end"
                        width="50px"
                      >
                        <AccordionIcon />
                      </AccordionButton>
                    )}
                  </Flex>

                  <AccordionPanel paddingTop={6}>
                    <Accordion allowMultiple>
                      {category.fullChildren.map((subCategory) => (
                        <AccordionItem key={subCategory._id} paddingY={6}>
                          <Flex paddingLeft={6} justifyContent="space-between">
                            <Checkbox
                              variant="createSimple"
                              isChecked={selectedCategories.some(
                                (selectedCategory) =>
                                  selectedCategory.id === subCategory._id
                              )}
                              onChange={() =>
                                handleCategorySelection(subCategory)
                              }
                            >
                              {subCategory.name}
                            </Checkbox>

                            {subCategory.fullChildren.length > 0 && (
                              <AccordionButton
                                display="flex"
                                justifyContent="flex-end"
                                width="50px"
                              >
                                <AccordionIcon />
                              </AccordionButton>
                            )}
                          </Flex>

                          <AccordionPanel paddingLeft={6} paddingTop={6}>
                            <Stack paddingLeft={6}>
                              {subCategory.fullChildren.map(
                                (subSubCategory) => (
                                  <Checkbox
                                    variant="createSimple"
                                    isChecked={selectedCategories.some(
                                      (selectedCategory) =>
                                        selectedCategory.id ===
                                        subSubCategory._id
                                    )}
                                    key={subSubCategory._id}
                                    onChange={() =>
                                      handleCategorySelection(subSubCategory)
                                    }
                                  >
                                    {subSubCategory.name}
                                  </Checkbox>
                                )
                              )}
                            </Stack>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <OutlineButton onClick={onClose}>
                {t(
                  "authoring.audience.exploreSettings.categories.selectCategories.discard"
                )}
              </OutlineButton>

              <SolidButton onClick={onClose}>
                {t(
                  "authoring.audience.exploreSettings.categories.selectCategories.save"
                )}
              </SolidButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </CreateFormGroup>
  );
}
