import { Link } from "@chakra-ui/react";

export default function ChildAngularLink({ child }: { child: any }) {
  return (
    <Link
      href={child.route}
      key={child.name}
      fontSize="xs"
      paddingLeft={3}
      color="rgba(26, 26, 26, 0.8)"
      fontWeight="medium"
      _hover={{
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      {child.name}
    </Link>
  );
}
