import { ChoiceOption } from "@/client/types/content/assessments/Assessment";

export const shuffleArray = (array: ChoiceOption[] | undefined) => {
  if (!array) return array;

  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};
