import { colors } from "@/client/theme/colors";

export const hexToRGBA = (color: string, opacity: number) => {
  let c = color;

  if (color === "brand.primary") c = colors.brand.primary;
  if (color === "brand.secondary") c = colors.brand.secondary;

  let hex = c.replace("#", "");
  let o = opacity;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    o /= 100;
  }

  return `rgba(${r},${g},${b},${o})`;
};
