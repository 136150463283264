import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import { CustomField } from "@/client/types/AuthConfig";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ValidationRuleEditor from "./ValidationRuleEditor";

interface SingleCustomFieldsEditorProps {
  customField: CustomField;
  setCustomField: (field: CustomField) => void;
}

export default function SingleCustomFieldsEditor({
  customField,
  setCustomField,
}: SingleCustomFieldsEditorProps) {
  const { t } = useTranslation();

  const onChange = (fieldName: string, value: any) => {
    const newCustomFieldBody = {
      ...customField,
      [fieldName]: value,
    };
    setCustomField(newCustomFieldBody);
  };

  const validationChange = (rule: string, value: string | number | boolean) => {
    const newValidationRules = {
      ...customField.validationRules,
      [rule]: value,
    };
    onChange("validationRules", newValidationRules);
  };

  const appVisibilityChange = (
    appKey: "profile" | "reports" | "certificates",
    value: string
  ) => {
    const newAppVisibility = {
      ...customField.visibility,
    };
    newAppVisibility[appKey] = value;
    onChange("visibility", newAppVisibility);
  };

  return (
    <Box
      padding={6}
      marginX={3}
      backgroundColor="white"
      borderRadius="10px"
      width="100%"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      height="25%"
      flex={1}
    >
      <Flex direction="column" gap="5">
        <Flex direction="column">
          <FormLabelWithTooltip
            label={t("customFields.label.displayName")}
            isRequired
          />
          <InputGroup width="50%">
            <Input
              placeholder="Enter Display Name"
              variant="adminInput"
              size="sm"
              value={customField?.name || ""}
              onChange={(e) => onChange("name", e.target.value)}
            />
          </InputGroup>
        </Flex>
        <Flex direction="column">
          <FormLabelWithTooltip
            label={t("customFields.label.fieldDescription")}
            isRequired={false}
          />
          <InputGroup>
            <Input
              placeholder="Enter Description"
              variant="adminInput"
              size="sm"
              value={customField?.description || ""}
              onChange={(e) => onChange("description", e.target.value)}
            />
          </InputGroup>
        </Flex>
        <Flex direction="column">
          <FormLabelWithTooltip
            label={t("customFields.label.fieldType")}
            isRequired
          />
          <RadioGroup
            onChange={(e) => onChange("dataType", e)}
            value={customField?.dataType}
            marginLeft={2}
          >
            <Flex direction="row" justifyContent="space-between" width="37%">
              <Radio variant="admin" value="string">
                <Tooltip label={t("customFields.tip.variable")}>
                  {t("customFields.label.variable")}
                </Tooltip>
              </Radio>
              <Radio variant="admin" value="number">
                <Tooltip label={t("customFields.tip.number")}>
                  {t("globla.dataTypes.number")}
                </Tooltip>
              </Radio>
              <Radio variant="admin" value="boolean">
                <Tooltip label={t("customFields.tip.trueFalse")}>
                  {t("customFields.label.trueFalse")}
                </Tooltip>
              </Radio>
            </Flex>
          </RadioGroup>
        </Flex>
        <ValidationRuleEditor
          dataType={customField?.dataType}
          validationRules={customField.validationRules}
          validationRuleUpdater={validationChange}
        />
        <Flex direction="column">
          <FormLabelWithTooltip
            label={t("customFields.label.appVisibility")}
            isRequired
          />
          <Flex direction="row" gap="4">
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="start"
              gap="2"
            >
              <Text fontSize="sm" fontWeight="500" whiteSpace="nowrap">
                {t("customFields.label.userProfile")}
              </Text>
              <div>
                <Select
                  value={customField.visibility.profile}
                  onChange={(e) =>
                    appVisibilityChange("profile", e.target.value)
                  }
                >
                  <option value="none">
                    {t("customFields.selector.notVisible")}
                  </option>
                  <option value="readonly">
                    {t("customFields.selector.readOnly")}
                  </option>
                  <option value="editable">
                    {t("customFields.selector.editable")}
                  </option>
                </Select>
              </div>
            </Flex>
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="start"
              gap="2"
              marginLeft={2}
            >
              <Text fontSize="sm" fontWeight="500" whiteSpace="nowrap">
                {t("customFields.label.reports")}
              </Text>
              <div>
                <Select
                  value={customField.visibility.reports}
                  onChange={(e) =>
                    appVisibilityChange("reports", e.target.value)
                  }
                >
                  <option value="none">
                    {t("customFields.selector.notVisible")}
                  </option>
                  <option value="readonly">
                    {t("customFields.selector.readOnly")}
                  </option>
                </Select>
              </div>
            </Flex>
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="start"
              gap="2"
              marginLeft={2}
            >
              <Text fontSize="sm" fontWeight="500" whiteSpace="nowrap">
                {t("customFields.label.certificates")}
              </Text>
              <div>
                <Select
                  value={customField.visibility.certificates}
                  onChange={(e) =>
                    appVisibilityChange("certificates", e.target.value)
                  }
                >
                  <option value="none">
                    {t("customFields.selector.notVisible")}
                  </option>
                  <option value="readonly">
                    {t("customFields.selector.readOnly")}
                  </option>
                </Select>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
