/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Circle,
  Collapse,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";
import { IoGitNetworkOutline, IoLocationOutline } from "react-icons/io5";

import AuthoringSegmentationTagList from "@/client/components/admin/create/content/lists/AuthoringSegmentationTagList";
import type { Category } from "@/client/types/Category";
import {
  ContentTypes,
  type Content,
} from "@/client/services/api/graphql/gql/graphql";
import type { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "@/client/components/admin/create/shared/layout/CreateSwitchContainer";
import { GoOrganization } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IconType } from "react-icons/lib";
import { LiaUserGraduateSolid } from "react-icons/lia";
import NoSearchResultsListItem from "@/client/components/admin/create/content/lists/NoSearchResultsListItem";
import { useAuthoringSegmentationSearch } from "@/client/services/hooks/admin/authoring/useAuthoringSegmentationSearch";
import { useCombobox } from "downshift";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function AuthoringSegmentationSearch() {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm, isFetching, data } =
    useAuthoringSegmentationSearch();

  const { setValue, watch } = useFormContext<Content & ContentFormValues>();

  const contentType = watch("contentType");
  const enableSegmentation = watch("enable_segmentation");

  const selectedLocations = watch("privacyLocations") || [];
  const selectedDepartments = watch("privacyDepartments") || [];
  const selectedTeams = watch("privacyTeams") || [];
  const selectedOrgLevels = watch("privacyOrgLevels") || [];
  const selectedGrades = watch("privacyGrades") || [];
  const selectedGroups = watch("privacyGroups") || [];

  const selectedTypeMap: { [key: string]: any[] } = {
    location: selectedLocations,
    department: selectedDepartments,
    team: selectedTeams,
    orgLevel: selectedOrgLevels,
    grade: selectedGrades,
    group: selectedGroups,
  };

  const formNameMap: { [key: string]: string } = {
    location: "privacyLocations",
    department: "privacyDepartments",
    team: "privacyTeams",
    orgLevel: "privacyOrgLevels",
    grade: "privacyGrades",
    group: "privacyGroups",
  };

  const typeIconMap: { [key: string]: IconType } = {
    location: IoLocationOutline,
    department: IoGitNetworkOutline,
    team: HiOutlineUserGroup,
    orgLevel: GoOrganization,
    grade: LiaUserGraduateSolid,
    group: HiOutlineUserGroup,
  };

  const flattenGroupOptions = (
    options: { title: string; options: Category[] }[]
  ) => options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        setSearchTerm(inputValue);
      },
      items: data ? flattenGroupOptions(data) : [],
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }

        const existingMatch = selectedTypeMap[selectedItem.type].find(
          (item) => item._id === selectedItem._id
        );

        if (existingMatch) {
          setValue(
            // @ts-ignore
            formNameMap[selectedItem.type],

            selectedTypeMap[selectedItem.type].filter(
              (item: any) => item._id !== selectedItem._id
            )
          );
        } else {
          // @ts-ignore
          setValue(formNameMap[selectedItem.type], [
            ...selectedTypeMap[selectedItem.type],
            selectedItem,
          ]);
        }
      },
      selectedItem: null,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
              highlightedIndex: state.highlightedIndex,
              inputValue: "", // don't add the item string as input value at selection.
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: "", // don't add the item string as input value at selection.
            };
          default:
            return changes;
        }
      },
    });

  return (
    <CreateFormGroup renderBorder={contentType !== ContentTypes.Assessment}>
      <Stack spacing={4}>
        <CreateSwitchContainer
          name="enable_segmentation"
          heading={
            contentType !== ContentTypes.Assessment
              ? t("authoring.audience.segmentation")
              : ""
          }
          label={
            contentType === ContentTypes.Assessment
              ? t("authoring.assessment.audience.segmentation")
              : ""
          }
          helpText={
            contentType === ContentTypes.Assessment
              ? t("authoring.assessment.audience.segmentation.helpText")
              : t("authoring.audience.segmentation.helpText")
          }
        />

        <Collapse in={enableSegmentation} animateOpacity>
          <InputGroup>
            <Input
              variant="create"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={`${t(
                "authoring.audience.segmentation.search.placeholder"
              )}`}
              {...getInputProps()}
            />

            <InputRightElement>
              {isFetching && <Spinner size="sm" />}
            </InputRightElement>
          </InputGroup>

          <List
            marginTop={2}
            flexDirection="column"
            border={data ? "1px solid" : "none"}
            borderColor="warmNeutral.500"
            borderRadius="md"
            background="white"
            overflow="scroll"
            paddingX={6}
            zIndex={9999}
            maxH={400}
            width="inherit"
            {...getMenuProps()}
          >
            {data?.length === 0 && <NoSearchResultsListItem />}

            {data &&
              data.length > 0 &&
              data.reduce(
                (results: any, section: any, sectionIndex: any) => {
                  results.sections.push(
                    <Box
                      key={`results_section_${sectionIndex + 1}`}
                      paddingY={4}
                      borderBottom="1px solid"
                      borderColor="warmNeutral.100"
                    >
                      {section.options.length > 0 && (
                        <Text variant="createLabel" marginY={2}>
                          {section.title}
                        </Text>
                      )}

                      {section.options.length > 0 &&
                        section.options.map(
                          (option: Category, optionIndex: any) => {
                            // eslint-disable-next-line no-plusplus, no-param-reassign
                            const resultIndex = results.itemIndex++;

                            const isSelected = selectedTypeMap[
                              option.type
                            ].find((item: any) => item._id === option._id);

                            return (
                              <ListItem
                                key={`option_index_${optionIndex + 1}`}
                                paddingX={4}
                                paddingY={2}
                                _hover={{
                                  cursor: "pointer",
                                }}
                                {...getItemProps({
                                  item: option,
                                  index: resultIndex,
                                  "aria-selected": isSelected,
                                })}
                                backgroundColor={
                                  highlightedIndex === resultIndex
                                    ? "warmNeutral.0"
                                    : "white"
                                }
                                border={
                                  highlightedIndex === resultIndex
                                    ? "1px solid"
                                    : "none"
                                }
                                borderColor="warmNeutral.400"
                                borderRadius="6px"
                              >
                                <Flex
                                  alignItems="center"
                                  width="full"
                                  justifyContent="space-between"
                                >
                                  <Circle
                                    backgroundColor={
                                      isSelected ? "gray.50" : "gray.100"
                                    }
                                    size="30px"
                                  >
                                    <Icon
                                      color={
                                        isSelected
                                          ? "neutral.1000"
                                          : "neutral.1200"
                                      }
                                      as={typeIconMap[option.type]}
                                    />
                                  </Circle>

                                  <Box
                                    flexGrow={1}
                                    textAlign="left"
                                    paddingLeft={4}
                                  >
                                    <Text
                                      fontSize="sm"
                                      fontWeight={500}
                                      color={
                                        isSelected
                                          ? "neutral.1000"
                                          : "neutral.1200"
                                      }
                                    >
                                      {option.legacyName || option.name}
                                    </Text>
                                  </Box>

                                  <Icon
                                    boxSize={8}
                                    as={
                                      isSelected ? CiCircleCheck : CiCirclePlus
                                    }
                                    color={
                                      isSelected
                                        ? "warmNeutral.300"
                                        : "neutral.800"
                                    }
                                  />
                                </Flex>
                              </ListItem>
                            );
                          }
                        )}
                    </Box>
                  );

                  return results;
                },
                { sections: [], itemIndex: 0 }
              ).sections}
          </List>

          <Stack spacing={4} marginY={2}>
            <AuthoringSegmentationTagList
              formName="privacyLocations"
              label={t("authoring.segmentation.locations")}
              icon={typeIconMap.location}
            />

            <AuthoringSegmentationTagList
              formName="privacyDepartments"
              label={t("authoring.segmentation.departments")}
              icon={typeIconMap.department}
            />

            <AuthoringSegmentationTagList
              formName="privacyTeams"
              label={t("authoring.segmentation.teams")}
              icon={typeIconMap.team}
            />

            <AuthoringSegmentationTagList
              formName="privacyOrgLevels"
              label={t("authoring.segmentation.orgLevels")}
              icon={typeIconMap.orgLevel}
            />

            <AuthoringSegmentationTagList
              formName="privacyGrades"
              label={t("authoring.segmentation.grades")}
              icon={typeIconMap.grade}
            />

            <AuthoringSegmentationTagList
              formName="privacyGroups"
              label={t("authoring.segmentation.groups")}
              icon={typeIconMap.group}
            />
          </Stack>

          <CreateSwitchContainer
            name="private"
            label={
              contentType === ContentTypes.Assessment
                ? t("authoring.audience.segmentation.private")
                : ""
            }
            heading={
              contentType !== ContentTypes.Assessment
                ? t("authoring.audience.segmentation.private")
                : ""
            }
            helpText={t("authoring.audience.segmentation.private.helpText")}
          />
        </Collapse>
      </Stack>
    </CreateFormGroup>
  );
}
