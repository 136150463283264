import {
  Archive,
  Close,
  Copy,
  Download,
  EditSimple,
  UserAlt,
  VerticalEllipsis,
  View,
} from "../../icons/ContinuIcons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { useTranslation } from "react-i18next";

// TODO: Replace usage of this component with TableActionsMenuNew.tsx

interface TableActionsMenuProps {
  onEdit?: () => void;
  onDelete?: () => void;
  onDeactivate?: () => void;
  onActivate?: () => void;
  onCopy?: () => void;
  onArchive?: () => void;
  onUnArchive?: () => void;
  onEnableToggle?: () => void;
  onAssign?: () => void;
  onDownload?: () => void;
  onDuplicate?: () => void;
  enabledStatus?: any;
  additionalActions?: AdditionalAction[];
}

type AdditionalAction = {
  icon: any;
  label: string;
  onClick: () => void;
};

export default function TableActionsMenu({
  onEdit,
  onDelete,
  onDeactivate,
  onActivate,
  onCopy,
  onArchive,
  onUnArchive,
  onEnableToggle,
  onAssign,
  onDownload,
  onDuplicate,
  enabledStatus,
  additionalActions,
}: TableActionsMenuProps) {
  const { t } = useTranslation();

  return (
    <Menu variant="adminTableActions">
      <MenuButton
        variant="ghost"
        as={IconButton}
        aria-label="Options"
        icon={<VerticalEllipsis color="brand.button" />}
      />

      <MenuList>
        {onAssign && (
          <MenuItem icon={<UserAlt color="brand.button" />} onClick={onAssign}>
            {t("profiles.assignToUser")}
          </MenuItem>
        )}
        {onEdit && (
          <MenuItem icon={<EditSimple color="brand.button" />} onClick={onEdit}>
            {t("global.actions.edit")}
          </MenuItem>
        )}
        {onCopy && (
          <MenuItem icon={<Copy color="brand.button" />} onClick={onCopy}>
            {t("copybutton.copy")}
          </MenuItem>
        )}
        {onDownload && (
          <MenuItem
            icon={<Download color="brand.button" />}
            onClick={onDownload}
          >
            Download
          </MenuItem>
        )}
        {onDuplicate && (
          <MenuItem icon={<Copy color="brand.button" />} onClick={onDuplicate}>
            Duplicate
          </MenuItem>
        )}
        {onDelete && (
          <MenuItem icon={<Close color="red" />} onClick={onDelete}>
            {t("global.actions.delete")}
          </MenuItem>
        )}
        {onDeactivate && (
          <MenuItem
            icon={<ViewIcon color="brand.button" />}
            onClick={onDeactivate}
          >
            {t("overview.workflows.manager_deactivate")}
          </MenuItem>
        )}
        {onActivate && (
          <MenuItem
            icon={<ViewIcon color="brand.button" />}
            onClick={onActivate}
          >
            {t("overview.workflows.manager_activate")}
          </MenuItem>
        )}
        {onArchive && (
          <MenuItem icon={<Archive color="red" />} onClick={onArchive}>
            {t("global.actions.archive")}
          </MenuItem>
        )}
        {onUnArchive && (
          <MenuItem
            icon={<Archive color="brand.button" />}
            onClick={onUnArchive}
          >
            {t("archivedManager.unarchived")}
          </MenuItem>
        )}
        {additionalActions?.map((action) => (
          <MenuItem
            key={`menuitem_${action.label}`}
            icon={action.icon}
            onClick={action.onClick}
          >
            {action.label}
          </MenuItem>
        ))}
        {onEnableToggle && (
          <MenuItem
            icon={
              enabledStatus ? (
                <ViewOffIcon color="brand.button" />
              ) : (
                <View color="brand.button" />
              )
            }
            onClick={onEnableToggle}
          >
            {enabledStatus
              ? t("overview.workflows.manager_deactivate")
              : t("overview.workflows.manager_activate")}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

TableActionsMenu.defaultProps = {
  onEdit: undefined,
  onDelete: undefined,
  onDeactivate: undefined,
  onActivate: undefined,
  onCopy: null,
  onArchive: undefined,
  onUnArchive: undefined,
  onEnableToggle: undefined,
  onAssign: undefined,
  enabledStatus: undefined,
  onDownload: undefined,
  additionalActions: [],
  onDuplicate: undefined,
};
