import { ProfileForm } from "@/client/types/admin/profiles/ProfileForm";
import SwitchList from "@/client/routes/admin/profiles/forms/SwitchList";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ProfileContentAnalytics() {
  const { t } = useTranslation();
  const { watch } = useFormContext<ProfileForm>();

  const allContentAnalytics = watch("permissions.admin.content.all_analytics");

  const contentAnalyticsOptions = [
    {
      name: "permissions.admin.content.all_analytics",
      label: t("edit.profiles.analytics.all"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.my_content_analytics",
      label: t("edit.profiles.analytics.myContent"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.articles_analytics",
      label: t("edit.profiles.analytics.articles"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.files_analytics",
      label: t("edit.profiles.analytics.files"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.videos_analytics",
      label: t("edit.profiles.analytics.videos"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.scorm_analytics",
      label: t("edit.profiles.analytics.scorm"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.workshops_analytics",
      label: t("edit.profiles.analytics.workshops"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.tracks_analytics",
      label: t("edit.profiles.analytics.learningTracks"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.assessments_analytics",
      label: t("edit.profiles.analytics.assessments"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.presentations_analytics",
      label: t("edit.profiles.analytics.presentations"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.imported_content_analytics",
      label: t("edit.profiles.analytics.importedContent"),
      enabled: true,
    },
  ];

  return (
    <SwitchList
      allChecked={allContentAnalytics}
      options={contentAnalyticsOptions}
    />
  );
}
