/* eslint-disable react/no-unstable-nested-components */

import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Select,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  Download,
  PlusCircle,
  QuestionCircle,
  WarningTriangle,
} from "@/client/components/icons/ContinuIcons";
import { Navigate, useNavigate } from "react-router-dom";
import { endOfYear, format, startOfYear } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import DatePicker from "@/client/components/admin/input/datepicker/Datepicker";
import { Doc } from "@/client/types/admin/reports/ReportData";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import MultipleTagCombobox from "@/client/components/admin/input/MultipleTagCombobox";
import SmartSearchService from "@/client/services/api/admin/search/SmartSearchService";
import TableActionsMenu from "@/client/components/admin/menus/TableActionsMenu";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useGenerateReport } from "@/client/services/hooks/admin/reports/useGenerateReport";
import { useQuery } from "@tanstack/react-query";
import { useReportTypes } from "@/client/services/hooks/admin/reports/useReportTypes";
import { useReportsService } from "@/client/services/hooks/admin/reports/useReportsService";
import { useTranslation } from "react-i18next";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";

export default function Reports() {
  const { t } = useTranslation();

  useDocumentTitle(t("overviewReports.documentTitle.overview"));

  const { allowance } = useAccessHelper();

  if (!allowance.reports) return <Navigate to="/explore" replace />;

  const { config } = useConfigStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedReportFilters, setSelectedReportFilters] = useState<any[]>([]);
  const { reportTypes, getCsv } = useReportTypes();
  const { generateReport, generateReportIsLoading } = useGenerateReport();

  const navigate = useNavigate();
  const [dateOptionSelected, setDateOptionSelected] = useState<
    "preCooked" | "custom"
  >("preCooked");
  const [reportToDelete, setReportToDelete] = useState<{
    reportId: string;
    contentName: string;
    reportType: string;
  } | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const { getReports, deleteReport, duplicateReport } = useReportsService();
  const [activeTab, setActiveTab] = useState<
    "myReports" | "standardReports" | "savedReports"
  >("myReports");
  const [companyStartDate] = useState<string>(company.createdAt);
  const [standardReportList, setStandardReportList] = useState<{
    rows: any[];
    pageCount: number;
  }>({
    rows: [],
    pageCount: 1,
  });
  const [reportArguments, setReportArguments] = useState<any>({
    query: {
      locations: [],
      departments: [],
      teams: [],
      org_levels: [],
      grades: [],
      tracks: [],
      content: [],
      users: [],
    },
  });

  useEffect(() => {
    const query: any = {
      locations: [],
      departments: [],
      teams: [],
      org_levels: [],
      grades: [],
      tracks: [],
      content: [],
      users: [],
    };

    selectedReportFilters.forEach((item) => {
      if (item.userid) {
        query.users.push(item._id);
      }

      if (item.type === "location") {
        query.locations.push(item._id);
      }

      if (item.type === "department") {
        query.departments.push(item._id);
      }

      if (item.type === "team") {
        query.teams.push(item._id);
      }

      if (item.type === "org_level") {
        query.org_levels.push(item._id);
      }

      if (item.type === "grade") {
        query.grades.push(item._id);
      }

      if (item.type === "track") {
        query.tracks.push(item._id);
      }

      if (
        item.type === "article" ||
        item.type === "video" ||
        item.type === "file" ||
        item.type === "track" ||
        item.type === "scorm" ||
        item.type === "workshop"
      ) {
        query.content.push(item._id);
      }
    });

    setReportArguments({ query });
  }, [selectedReportFilters]);

  // Init standard reports
  useEffect(() => {
    const list: any[] = [];

    Object.keys(reportTypes).forEach((key) => {
      // @ts-ignore
      const reportData = reportTypes[key];

      const report = {
        key,
        name: reportData.name,
        report_type: key,
        first_date: company.createdAt,
        last_date: new Date(),
        start_of_last_year: startOfYear(
          new Date(new Date().setDate(new Date().getDate() - 365))
        ),
        end_of_last_year: endOfYear(
          new Date(new Date().setDate(new Date().getDate() - 365))
        ),
        fields: reportData.defaultFields.map(
          (field: { reportsServiceRequestKey: any; key: any }) =>
            field.reportsServiceRequestKey || field.key
        ),
        filters: [],
        isStandard: true,
        applyDateRange: reportData.applyDateRange,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        csv: (results: any) => getStandardCsv(results, key),
      };
      if (
        report.report_type !== "content-completion" ||
        config.features.content_completion_flag
      ) {
        list.push(report);
      }
    });

    setStandardReportList({ rows: list, pageCount: 1 });
  }, []);

  const getStandardCsv = (results: string | any[], reportType: string) =>
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getCsv(reportType, results, reportTypes[reportType].defaultFields);

  interface DateOption {
    label: string;
    value: number;
    startDate: Date;
    endDate: Date;
    pastDays: number;
  }

  const dateOptions: DateOption[] = [
    {
      label: "Last 7 days",
      value: 7,
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
      pastDays: 7,
    },
    {
      label: "Last 30 days",
      value: 30,
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
      pastDays: 30,
    },
    {
      label: "Last 90 days",
      value: 90,
      startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
      endDate: new Date(),
      pastDays: 90,
    },
    {
      label: "Last 180 days",
      value: 180,
      startDate: new Date(new Date().setDate(new Date().getDate() - 180)),
      endDate: new Date(),
      pastDays: 180,
    },
    {
      label: "Last 365 days",
      value: 365,
      startDate: new Date(new Date().setDate(new Date().getDate() - 365)),
      endDate: new Date(),
      pastDays: 365,
    },
    {
      label: "Last Year",
      value: 120,
      startDate: startOfYear(
        new Date(new Date().setDate(new Date().getDate() - 365))
      ),
      endDate: endOfYear(
        new Date(new Date().setDate(new Date().getDate() - 365))
      ),
      pastDays: 120,
    },
    {
      label: "All Time",
      value: 100,
      startDate: new Date(company.createdAt),
      endDate: new Date(),
      pastDays: 100,
    },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState<DateOption>(
    dateOptions[0]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data, refetch } = getReports({
    reportType: activeTab,
    fetchDataOptions,
    startDate: companyStartDate,
    userId: activeTab === "myReports" ? user?._id : undefined,
    searchTerm,
  });

  const { isFetching: smartSearchIsFetching, data: smartSearchData } = useQuery(
    {
      enabled: !!searchTerm && activeTab === "standardReports",
      queryKey: ["smart-search", searchTerm],
      queryFn: () => SmartSearchService.smartSearch(searchTerm),
    }
  );

  const handleSelectedItemsChange = (newSelectedItems: any[]) =>
    setSelectedReportFilters(newSelectedItems);

  const handleGenerateReport = (report: any) => {
    dateOptionSelected === "preCooked"
      ? generateReport(report, selectedDateRange.value, reportArguments)
      : generateReport(report, 110, reportArguments, startDate, endDate);
  };

  const columnHelper = createColumnHelper<Doc>();
  const columns = useMemo<ColumnDefBase<Doc, any>[]>(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Flex alignItems="center">
            <Text marginRight={4}>{info.getValue()}</Text>

            {(info.row.original.report_type === "workshop-status" ||
              info.row.original.report_type === "assessment-status") && (
              <Tooltip
                hasArrow
                label={t("overviewReports.noDateApplicable")}
                aria-label="Dates unavailable"
              >
                <QuestionCircle color="brand.grey.50" />
              </Tooltip>
            )}
          </Flex>
        ),
        header: () => <Text>{t("precooked.report_name")}</Text>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: "dateRange",
        header: () => (
          <Text>
            Date Range (
            {dateOptionSelected === "preCooked"
              ? selectedDateRange.label
              : "Custom"}
            )
          </Text>
        ),
        cell: (info) =>
          info.row.original.applyDateRange ? (
            <Text>
              {dateOptionSelected === "preCooked"
                ? `${format(
                    selectedDateRange.startDate,
                    "yyyy/MM/dd"
                  )} - ${format(selectedDateRange.endDate, "yyyy/MM/dd")}`
                : `${format(startDate, "yyyy/MM/dd")} - ${format(
                    endDate,
                    "yyyy/MM/dd"
                  )}`}
            </Text>
          ) : (
            <Text>N/A</Text>
          ),
      }),
      columnHelper.accessor("created_by_name", {
        id: "createdBy",
        header: () =>
          activeTab === "savedReports" ? (
            <Text>{t("precooked.report_created_by")}</Text>
          ) : null,
        cell: (info) =>
          activeTab === "savedReports" ? <Text>{info.getValue()}</Text> : null,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => (
          <Flex justifyContent="flex-end">
            {activeTab === "standardReports" ? (
              <IconButton
                variant="ghost"
                aria-label="Download report"
                icon={<Download />}
                isLoading={generateReportIsLoading}
                onClick={() => handleGenerateReport(info.row.original)}
              />
            ) : (
              <TableActionsMenu
                onEdit={() =>
                  navigate(`/admin/reports/${info.row.original.key}`)
                }
                onDownload={() => handleGenerateReport(info.row.original)}
                onDuplicate={() =>
                  duplicateReport
                    .mutateAsync(info.row.original.key)
                    .then(() => refetch())
                }
                onDelete={() =>
                  setReportToDelete({
                    reportId: info.row.original.key,
                    contentName: info.row.original.name,
                    reportType: info.row.original.report_type,
                  })
                }
              />
            )}
          </Flex>
        ),
      }),
    ],
    [
      selectedDateRange,
      dateOptionSelected,
      activeTab,
      startDate,
      endDate,
      reportArguments,
    ]
  );

  return (
    <>
      <AdminPrimaryHeader
        title={`${t("overviewReports.reports")}`}
        tooltipText="Search by report name"
        showSearch={activeTab !== "standardReports"}
        setSearchTerm={(value: string) => setSearchTerm(value)}
        setSortOrder={() => {}}
        rightElement={
          <Button
            variant="adminPrimary"
            size="xs"
            leftIcon={<PlusCircle />}
            onClick={() => navigate("/admin/reports/create")}
          >
            {t("overviewReports.addAReport")}
          </Button>
        }
      />

      <AdminElevatedBox>
        <Stack spacing={4}>
          <Tabs variant="soft-rounded" size="sm" color="brand.primary">
            <TabList>
              <Tab onClick={() => setActiveTab("myReports")}>
                {t("overviewReports.myReports")}
              </Tab>

              <Tab onClick={() => setActiveTab("standardReports")}>
                {t("overviewReports.standardReports")}
              </Tab>

              <Tab onClick={() => setActiveTab("savedReports")}>
                {t("overviewReports.savedReports")}
              </Tab>
            </TabList>
          </Tabs>

          <HStack spacing={4} maxWidth="50%">
            <Radio
              isChecked={dateOptionSelected === "preCooked"}
              onChange={() => setDateOptionSelected("preCooked")}
            />

            <Select
              isDisabled={dateOptionSelected === "custom"}
              maxWidth="25%"
              value={selectedDateRange.value}
              onChange={(e) =>
                setSelectedDateRange(
                  dateOptions.filter(
                    (option) => option.value === Number(e.target.value)
                  )[0]
                )
              }
            >
              {dateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>

            <HStack spacing={4}>
              <Radio
                isChecked={dateOptionSelected === "custom"}
                onChange={() => setDateOptionSelected("custom")}
              />

              <DatePicker
                isDisabled={dateOptionSelected === "preCooked"}
                selectedDate={startDate}
                onChange={setStartDate}
                maxDate={new Date()}
              />

              <DatePicker
                isDisabled={dateOptionSelected === "preCooked"}
                selectedDate={endDate}
                onChange={setEndDate}
                maxDate={new Date()}
                minDate={startDate}
              />
            </HStack>
          </HStack>

          {activeTab === "standardReports" && (
            <MultipleTagCombobox
              items={smartSearchData || []}
              selectedItems={selectedReportFilters}
              setSearchValue={setSearchTerm}
              isLoading={smartSearchIsFetching}
              renderSelectedItemTags
              onSelectedItemsChange={(changes: any) => {
                handleSelectedItemsChange(changes.selectedItems);
              }}
            />
          )}

          {data?.rows.length === 0 && (
            <VStack>
              <Text>No reports to show.</Text>

              <Text>
                <Link
                  isExternal
                  href="https://help.continu.com/hc/en-us/articles/4403935331479-Navigating-Admin-Reports"
                >
                  Click here
                </Link>{" "}
                to learn how to create reports.
              </Text>
            </VStack>
          )}
          <ManuallyPaginatedTable
            columns={columns}
            rowSelection={{}}
            setRowSelection={() => {}}
            queryData={
              activeTab === "standardReports" ? standardReportList : data
            }
            pagination={pagination}
            setPagination={setPagination}
          />
        </Stack>
      </AdminElevatedBox>

      <Modal
        size="xl"
        isOpen={reportToDelete !== null}
        onClose={() => setReportToDelete(null)}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            <WarningTriangle color="brand.warning" marginRight={4} />
            {t("admin.removingThis", { type: "Report" })}
          </ModalHeader>

          <ModalBody>
            <Text as="p">{t("admin.proceed")}</Text>
          </ModalBody>

          <ModalFooter
            width="full"
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="adminCancel"
              mr={3}
              onClick={() => setReportToDelete(null)}
            >
              {t("global.actions.cancel")}
            </Button>

            <Button
              variant="adminError"
              isLoading={deleteReport.isLoading}
              onClick={() => {
                if (reportToDelete) {
                  deleteReport
                    .mutateAsync(reportToDelete)
                    .then(() => refetch())
                    .then(() => setReportToDelete(null));
                }
              }}
            >
              {t("global.actions.delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
