import { learnApiClient } from "./clients/learnApiClient";

export const getUserContentRating = async (
  contentId: string | undefined,
  userId: string
) => {
  const response = await learnApiClient.get(
    `ratings?content_id=${contentId}&user=${userId}`
  );

  return response.data;
};

const postRating = async (
  userId: string,
  contentId: string,
  contentType: string,
  feedback: string,
  rating: number,
  total: number
) =>
  learnApiClient.post(`ratings`, {
    user: userId,
    content_id: contentId,
    content_type: contentType,
    feedback,
    rating,
    total,
  });

const RatingService = {
  getUserContentRating,
  postRating,
};

export default RatingService;
