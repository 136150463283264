import {
  Badge,
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { PlusCircle } from "@/client/components/icons/ContinuIcons";
import { useEffect, useMemo, useState } from "react";

import TableActionsMenu from "@/client/components/admin/menus/TableActionsMenu";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import {
  OrderingDirection,
  Product,
} from "@/client/services/api/graphql/gql/graphql";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useAuthStore } from "@/client/services/state/authStore";
import { useAdminListProducts } from "@/client/services/hooks/content/products/useListProducts";
import { useToastStore } from "@/client/services/state/toastStore";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import PurchasesList from "./PurchasesList";
import AdminTabHeader from "@/client/components/admin/layout/AdminTabHeader";
import AdminTableWrapper from "@/client/components/admin/layout/AdminTableWrapper";
import LandingPageList from "./LandingPageList";
import RevenueDashboard from "./RevenueDashboard";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import { capCase } from "@/client/utils/capCase";

export default function ProductOverview() {
  const { t } = useTranslation();
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { setToast } = useToastStore();
  const [selectedProductToArchive, setSelectedProductToArchive] =
    useState<Product | null>(null);

  if (!company.feature_flags.enable_ecommerce) {
    setToast({
      show: true,
      status: "error",
      title: "E-Commerce is unavailable for the company.",
    });
    navigate("/explore");
  }

  useDocumentTitle(t("overviewProducts.overview"));

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  const onArchiveProduct = useMutation({
    mutationFn: (product: Product) =>
      ProductsService.archiveProduct(product.id, !product.archived),
    onSuccess: () => {
      setSelectedProductToArchive(null);
      setToast({
        title: t("ecommerce.products.archiveSuccess"),
        status: "success",
        show: true,
      });
      refetch();
    },
    onError: () => {
      setSelectedProductToArchive(null);
      setToast({
        title: t("ecommerce.products.archiveFailure"),
        status: "error",
        show: true,
      });
    },
  });

  const {
    data,
    isError: productsError,
    refetch,
  } = useAdminListProducts({
    page: pageIndex,
    pageSize,
    includeTotalCount: true,
    title: { contains: searchTerm },
    ordering: [
      { field: "default", direction: OrderingDirection.Desc },
      { field: "updatedAt", direction: OrderingDirection.Desc },
    ],
  });

  const navButtons = [
    {
      key: "",
      text: "Products",
      allowance: true,
    },
    {
      key: "purchases",
      text: "Purchases",
      allowance: true,
    },

    {
      key: "revenue",
      text: "Revenue",
      allowance: true,
    },
    {
      key: "landing-pages",
      text: "Landing Pages",
      allowance: true,
    },
  ];

  const columnHelper = createColumnHelper<Product>();
  const columns = useMemo<ColumnDefBase<Product, any>[]>(
    () => [
      columnHelper.accessor("title", {
        cell: (info) =>
          info.row.original.default ? (
            <>
              <Badge
                borderRadius="5px"
                backgroundColor="brand.primary"
                width="fit-content"
                paddingY={0.5}
                paddingX={2}
                textAlign="center"
              >
                {t("ecommerce.productsList.baseProductTag")}
              </Badge>
              <span> {info.getValue()}</span>
            </>
          ) : (
            info.getValue()
          ),
        header: () => <span>{t("overviewProducts.title")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("price", {
        cell: (info) =>
          info.row.original.default ? "N/A" : `$${info.getValue()}`,
        header: () => <span>{t("overviewProducts.price")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("subscriptionDuration", {
        cell: (info) =>
          info.row.original.default
            ? "N/A"
            : capCase(info.getValue().toLowerCase()),
        header: () => <span>{t("overviewProducts.termLength")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("archived", {
        cell: (info) => (
          <Badge
            borderRadius="5px"
            variant={info.getValue() ? "inactive" : "active"}
            width="70%"
            padding={1}
            textAlign="center"
          >
            {info.getValue() ? "Inactive" : "Active"}
          </Badge>
        ),
        header: () => <span>{t("global.forms.labels_status")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) =>
          info.row.original.default ? (
            <TableActionsMenu
              onEdit={() =>
                navigate(`/admin/edit/products/${info.row.original.id}`)
              }
            />
          ) : (
            <TableActionsMenu
              onEdit={() =>
                navigate(`/admin/edit/products/${info.row.original.id}`)
              }
              onArchive={
                !info.row.original.archived
                  ? () => setSelectedProductToArchive(info.row.original)
                  : undefined
              }
              onActivate={
                info.row.original.archived
                  ? () => setSelectedProductToArchive(info.row.original)
                  : undefined
              }
            />
          ),
      }),
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    refetch();
  }, [pageIndex, searchTerm]);

  // if (productsLoading) return <Loading />;
  if (productsError) return <ErrorAlert title="Error getting products" />;

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <Modal
        isOpen={!!selectedProductToArchive}
        onClose={() => setSelectedProductToArchive(null)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            {selectedProductToArchive?.archived
              ? t("ecommerce.products.unarchiveHeader")
              : t("ecommerce.products.archiveHeader")}
          </ModalHeader>

          <ModalBody>
            <Stack spacing={4}>
              <Text as="p">
                {selectedProductToArchive?.archived
                  ? t("ecommerce.products.unarchiveProceed")
                  : t("ecommerce.products.archiveProceed")}
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter
            width="full"
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="adminCancel"
              mr={3}
              onClick={() => setSelectedProductToArchive(null)}
            >
              {t("global.actions.cancel")}
            </Button>

            {!!selectedProductToArchive && (
              <Button
                variant="adminPrimary"
                onClick={() =>
                  onArchiveProduct.mutate(selectedProductToArchive)
                }
              >
                {selectedProductToArchive.archived
                  ? t("global.actions.activate")
                  : t("global.actions.archive")}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AdminTabHeader
        title={`${t("overviewProducts.overview")}`}
        nav={navButtons}
        path="/overview/products"
        activeTab={tab}
      />

      {!tab && (
        <Box
          padding={6}
          backgroundColor="white"
          margin={2}
          marginX={4}
          borderRadius="10px"
          boxShadow="0 2px 2px rgba(0,0,0,0.1)"
        >
          <AdminTableWrapper
            setSearchTerm={(term) => setSearchTerm(term)}
            sortOrder="asc"
            setSortOrder={() => {}}
            isSearching={false}
            tooltipText="Get help"
            rightElement={
              <Button
                size="xs"
                variant="adminPrimary"
                leftIcon={<PlusCircle />}
                onClick={() => navigate("/admin/edit/products")} // TODO might change
              >
                {t("overviewProducts.addNewProduct")}
              </Button>
            }
          >
            <>
              {!data?.products?.length && (
                <Center marginY={6}>{t("overviewProducts.noProducts")}</Center>
              )}
              <ManuallyPaginatedTable
                columns={columns}
                queryData={{
                  rows: data ? data.products : [],
                  pageCount: data?.pageInfo?.totalCount
                    ? Math.ceil(!!data && data.pageInfo.totalCount / pageSize)
                    : null,
                }}
                rowSelection={{}}
                setRowSelection={() => {}}
                pagination={pagination}
                setPagination={setPagination}
              />
            </>
          </AdminTableWrapper>
        </Box>
      )}
      {tab === "purchases" && <PurchasesList />}
      {tab === "revenue" && <RevenueDashboard />}
      {tab === "landing-pages" && <LandingPageList />}
    </Box>
  );
}
