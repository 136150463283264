import BadgeService from "@/client/services/api/BadgeService";
import { useQuery } from "@tanstack/react-query";

export const useGetUserBadgesPaginated = (
  options: {
    pageIndex: number;
    pageSize: number;
  },
  userId: string | undefined,
  totalBadgeCount?: number | undefined
) =>
  useQuery({
    queryKey: ["query-user-badges-paginated", userId, options, totalBadgeCount],
    refetchOnWindowFocus: false,
    queryFn: () =>
      BadgeService.getUserBadgesPaginated(options, userId, totalBadgeCount),
  });
