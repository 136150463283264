import { Box, Button, Flex, Link, Text, Tooltip } from "@chakra-ui/react";
import { PlusCircle, QuestionCircle } from "../icons/ContinuIcons";
import { useTranslation } from "react-i18next";

type HeaderProps = {
  addForm?: () => void;
};

export default function RegistrationFormsHeader({ addForm }: HeaderProps) {
  const { t } = useTranslation();

  return (
    <Box
      padding={2}
      paddingLeft={6}
      paddingRight={6}
      backgroundColor="white"
      margin={2}
      marginX={4}
      borderRadius="10px"
      boxShadow="0 2px 2px rgba(0,0,0,0.1)"
    >
      <Flex
        direction={{ base: "column", lg: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Text fontSize="xl" lineHeight="1.2" fontWeight="500" margin={2}>
            {t("global.label.registrationForms")}
          </Text>
          <Link href="https://help.continu.com/hc/en-us" isExternal>
            <Tooltip hasArrow label={t("registrationForms.help")}>
              <QuestionCircle color="#1A1A1A80" />
            </Tooltip>
          </Link>
        </Flex>
        {addForm && (
          <Button
            variant="adminPrimary"
            size="xs"
            leftIcon={<PlusCircle />}
            onClick={addForm}
          >
            {t("registrationForms.createNewForm")}
          </Button>
        )}
      </Flex>
    </Box>
  );
}

RegistrationFormsHeader.defaultProps = {
  addForm: null,
};
