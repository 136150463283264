/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
  Tooltip,
  useToken,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { QuestionOutlineIcon, SearchIcon } from "@chakra-ui/icons";

import { Category } from "@/client/types/Category";
import { Check } from "@/client/components/icons/ContinuIcons";
import FormLabelWithTooltip from "../forms/FormLabelWithTooltip";
import Highligher from "react-highlight-words";
import { useCombobox } from "downshift";
import { useDebounce } from "usehooks-ts";

const defaultItemRenderer = (selected: Category | any) =>
  selected.legacyName ||
  selected.full_name ||
  selected.name ||
  selected.title ||
  selected.label;

interface ComboboxProps {
  label?: string;
  tooltipText?: string;
  items: any;
  selectedItems: any[];
  setSelectedItems: any;
  searchTerm: string;
  setSearchTerm: any;
  isLoading?: boolean;
  positionAbsolute?: boolean;
  isRequired?: boolean;
  width?: string;
  listWidth?: string;
  border?: string;
  borderColor?: string;
  borderRadius?: string;
  inputCssOptions?: any;
  inputGroupCssOptions?: any;
  showQuestionIcon?: boolean;
  showSearchIcon?: boolean;
  inputVariant?: string;
}

export default function Combobox({
  label = undefined,
  // TODO: Add default Tooltip Text
  tooltipText = "Get Help",
  items,
  selectedItems,
  setSelectedItems,
  searchTerm,
  setSearchTerm,
  isLoading = false,
  positionAbsolute = false,
  isRequired = false,
  width = "auto",
  listWidth = "inherit",
  border = "1px solid",
  borderColor = "brand.primary",
  borderRadius = "sm",
  inputCssOptions = {},
  inputGroupCssOptions = {},
  showQuestionIcon = false,
  showSearchIcon = false,
  inputVariant = "search",
}: ComboboxProps) {
  const [brandPrimaryColor] = useToken("colors", ["brand.primary"]);
  const [input, setInput] = useState<string | undefined>(undefined);
  const debouncedInput = useDebounce(input, 200);

  useEffect(() => {
    setSearchTerm(debouncedInput);
  }, [debouncedInput]);

  const flattenGroupOptions = (options: any) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setInput(inputValue);
    },
    items: flattenGroupOptions(items) || [],
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }

      const index = selectedItems.indexOf(selectedItem);

      if (index > 0) {
        setSelectedItems([
          ...selectedItems.slice(0, index),
          ...selectedItems.slice(index + 1),
        ]);
      } else if (index === 0) {
        setSelectedItems([...selectedItems.slice(1)]);
      } else {
        setSelectedItems([...selectedItems, selectedItem]);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: "", // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "", // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
  });

  return (
    <Box width={width}>
      <Flex direction="column">
        {!!label && (
          <FormLabelWithTooltip
            isRequired={isRequired}
            label={label}
            {...getLabelProps()}
          />
        )}

        <InputGroup {...inputGroupCssOptions}>
          {showSearchIcon && (
            <InputLeftElement>
              <SearchIcon color="brand.grey.50" />
            </InputLeftElement>
          )}
          <Input
            borderBottomRadius={items.length > 0 ? 0 : "md"}
            placeholder="Search"
            variant={inputVariant}
            {...getInputProps()}
            {...inputCssOptions}
          />

          <InputRightElement>
            {isLoading ? (
              <Spinner size="sm" />
            ) : showQuestionIcon ? (
              <Tooltip hasArrow label={tooltipText}>
                <QuestionOutlineIcon color="brand.grey.50" />
              </Tooltip>
            ) : null}
          </InputRightElement>
        </InputGroup>
      </Flex>

      <List
        flexDirection="column"
        border={border}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderTop="none"
        background="white"
        overflow="scroll"
        position={positionAbsolute ? "absolute" : "relative"}
        paddingX={6}
        zIndex={9999}
        maxH={250}
        width={listWidth}
        display={items.length === 0 ? "none" : "flex"}
        {...getMenuProps()}
      >
        {items &&
          items.length > 0 &&
          items.reduce(
            (results: any, section: any, sectionIndex: any) => {
              results.sections.push(
                <Box key={`results_section_${sectionIndex + 1}`}>
                  {section.options.length > 0 && (
                    <Text fontSize="lg" marginY={4}>
                      {section.title}
                    </Text>
                  )}

                  {section.options.length > 0 &&
                    section.options.map((option: any, optionIndex: any) => {
                      const isAlreadySelected = selectedItems.filter((i) => {
                        if (i.id) {
                          return i.id === option.id;
                        }
                        return i._id === option._id;
                      });

                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      const resultIndex = results.itemIndex++;

                      if (isAlreadySelected.length > 0) {
                        return null;
                      }

                      return (
                        <ListItem
                          key={`option_index_${optionIndex + 1}`}
                          paddingX={6}
                          paddingY={1}
                          {...getItemProps({
                            item: option,
                            index: resultIndex,
                            "aria-selected": selectedItems.includes(option),
                          })}
                          backgroundColor={
                            highlightedIndex === resultIndex
                              ? "brand.gray.50"
                              : "white"
                          }
                        >
                          <Box>
                            {selectedItems.includes(option._id) && (
                              <Check color="green.500" marginRight={2} />
                            )}

                            {option.type === "user" && (
                              <Avatar
                                size="xs"
                                marginRight={2}
                                name={option.full_name}
                                src={option.image}
                              />
                            )}

                            <Highligher
                              autoEscape
                              searchWords={[searchTerm || ""]}
                              textToHighlight={defaultItemRenderer(option)}
                              highlightStyle={{
                                fontWeight: "bold",
                                color: brandPrimaryColor,
                                backgroundColor: "transparent",
                              }}
                            />
                          </Box>
                        </ListItem>
                      );
                    })}
                </Box>
              );

              return results;
            },
            { sections: [], itemIndex: 0 }
          ).sections}
      </List>
    </Box>
  );
}
