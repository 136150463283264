/* eslint react/no-unstable-nested-components: 0 */

import { Box, Link, SkeletonText } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import EcommerceListTable from "./EcommerceListTable";
import { useMemo } from "react";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import { useQuery } from "@tanstack/react-query";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import { useTranslation } from "react-i18next";
import { getCurrencySign } from "@/client/services/api/graphql/gql-utils";
import {
  OrderingDirection,
  Purchase,
} from "@/client/services/api/graphql/gql/graphql";
import { useAuthStore } from "@/client/services/state/authStore";
import {
  DateFormatGroupKey,
  dateFormatTenant,
} from "@/client/utils/dateFormat";
import { ColumnDefBase, createColumnHelper } from "@tanstack/react-table";

export default function MostRecentPurchasesList() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { t } = useTranslation();

  const { data, isError: purchasesError } = useQuery({
    queryKey: ["recent-purchases"],
    queryFn: () => {
      const options: any = {
        includeTotalCount: false,
        page: 0,
        pageSize: 5,
        ordering: [
          { field: "purchaseConfirmedDate", direction: OrderingDirection.Desc },
        ],
      };

      return ProductsService.listPurchases(options);
    },
  });

  if (purchasesError)
    return <ErrorAlert title={t("products.errors.mostRecentPurchases")} />;

  const columnHelper = createColumnHelper<Purchase>();
  const columns = useMemo<ColumnDefBase<Purchase, any>[]>(
    () => [
      columnHelper.accessor("userName", {
        cell: (info) => (
          <Link
            as={RouterLink}
            to={`/admin/users/edit/single/${info.row.original.user}`}
          >
            {info.getValue()}
          </Link>
        ),
        header: () => <span>{t("global.contentTypes.user")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("productDetails.title", {
        cell: (info) => (
          <Link
            as={RouterLink}
            to={`/product/${info.row.original.productDetails.id}`}
          >
            {info.getValue()}
          </Link>
        ),
        header: () => <span>{t("global.contentTypes.product")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("productDetails.price", {
        cell: (info) => (
          <div>
            {getCurrencySign(info.row.original.productDetails.currency!) +
              info.getValue()}
          </div>
        ),
        header: () => <span>{t("overviewProducts.price")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("purchaseConfirmedDate", {
        cell: (info) => (
          <div>
            {dateFormatTenant(
              new Date(info.getValue()),
              company.date_display as DateFormatGroupKey,
              "base"
            )}
          </div>
        ),
        header: () => <span>{t("products.purchased")}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    []
  );

  if (!data || data.purchases.length === 0) {
    return (
      <Box marginX={4}>
        <SkeletonText
          startColor="brand.backgroundGrey"
          endColor="brand.backgroundGrey"
          speed={0}
          mt="4"
          noOfLines={10}
          spacing="4"
          skeletonHeight="10"
        />
      </Box>
    );
  }

  return (
    <EcommerceListTable
      data={{ rows: data.purchases }}
      columns={columns}
      title={t("overviewProducts.mostRecentPurchases")}
    />
  );
}
