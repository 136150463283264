/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */

import {
  Assignment,
  BarChart,
  Explore,
  Manager,
  Scorm,
  Track,
  UserFriends,
  Workshop,
} from "@/client/components/icons/ContinuIcons";
import { format, formatDuration, intervalToDuration } from "date-fns";

import { useAuthStore } from "@/client/services/state/authStore";
import { useTranslation } from "react-i18next";

export const useReportTypes = () => {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { t } = useTranslation();

  const flatSeparator = " | ";

  function formatDate(date: Date) {
    if (!date) {
      return "N/A";
    }

    return format(new Date(date), "yyy-MM-dd HH:mm");
  }

  function formatWorkshopDate(date: Date) {
    if (!date) {
      return t("reports.types.workshop.unregisteredOrRemoved");
    }
    return formatDate(date);
  }

  function formatSeconds(seconds: number) {
    if (!seconds) {
      return "00:00:00";
    }

    const duration = intervalToDuration({
      start: 0,
      end: seconds * 1000,
    });

    const zeroPad = (num: number) => String(num).padStart(2, "0");

    const formatted = formatDuration(duration, {
      format: ["hours", "minutes", "seconds"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => zeroPad(count),
      },
    });

    return formatted;
  }

  function formatNumber(value: number) {
    if (typeof value !== "number") {
      return "";
    }
    return value.toFixed(2);
  }

  function formatYesNo(bool: boolean) {
    return bool ? "Yes" : "No";
  }

  function formatList(separator: string) {
    return (list: any) => list.join(separator);
  }

  function attachNonInstanceFields(result: any, currentResult: any) {
    if (!currentResult) {
      currentResult = {};
    }

    const resultKeys = Object.keys(result);

    for (let j = 0; j < resultKeys.length; j += 1) {
      const currentKey = resultKeys[j];
      const currentValue = result[resultKeys[j]];

      if (currentKey === "id") {
        currentResult.workshop_id = currentValue;
        continue;
      }
      if (currentKey === "title") {
        currentResult.workshop_title = currentValue;
        continue;
      }
      currentResult[currentKey] = currentValue;
    }
    return currentResult;
  }

  function flattenInstancesWorkshopEngagement(results: any) {
    const newResults = [];

    function getResultsForInstances(result: any) {
      const instanceResults = [];

      for (const currentInstance of result.instances) {
        const currentInstanceResult = {};
        for (const [
          currentInstanceKey,
          currentInstanceFieldValue,
        ] of Object.entries(currentInstance)) {
          if (currentInstanceKey === "id") {
            // @ts-ignore
            currentInstanceResult.workshop_instance_id =
              currentInstanceFieldValue;
          } else {
            // @ts-ignore
            currentInstanceResult[currentInstanceKey] =
              currentInstanceFieldValue;
          }
        }
        instanceResults.push(
          attachNonInstanceFields(result, currentInstanceResult)
        );
      }
      return instanceResults;
    }

    function getResultsForWorkshop(result: any) {
      const workshopResults = [];
      if (result.instances) {
        workshopResults.push(...getResultsForInstances(result));
      } else {
        workshopResults.push(attachNonInstanceFields(result, {}));
      }
      return workshopResults;
    }

    for (const result of results) {
      if (result.children) {
        for (const childWorkshop of result.children) {
          const childWorkshopResults = getResultsForWorkshop(childWorkshop);
          childWorkshopResults.forEach((childWorkshopResults) => {
            childWorkshopResults.parent_workshop_id = result.id;
            childWorkshopResults.parent_workshop_title = result.title;
          });
          newResults.push(...childWorkshopResults);
        }
      } else {
        newResults.push(...getResultsForWorkshop(result));
      }
    }
    return newResults;
  }

  function flattenAssignmentSummaryResults(results: string | any[]) {
    const newResults = [];

    for (let i = 0, leni = results.length; i < leni; i += 1) {
      const result = results[i];

      if (!result.assignments) {
        continue;
      }
      for (let j = 0, lenj = result.assignments.length; j < lenj; j += 1) {
        if (result.assignments[j].manual_completed_by) {
          result.assignments[j] = flattenManualCompletedByResult(
            result.assignments[j]
          );
        }
        if (!result.assignments[j].assigned_content) {
          continue;
        }
        for (
          let k = 0, lenk = result.assignments[j].assigned_content.length;
          k < lenk;
          k += 1
        ) {
          const newResult = {};

          Object.keys(result).forEach((key) => {
            if (key === "assignments") {
              const assignment = result["assignments"][j];

              Object.keys(assignment).forEach((assignmentKey) => {
                if (assignmentKey === "assigner") {
                  const assigner = assignment["assigner"];

                  Object.keys(assigner).forEach((assignerKey) => {
                    // @ts-ignore
                    newResult["assigner_" + assignerKey] =
                      assigner[assignerKey];
                  });
                } else if (assignmentKey === "assigned_content") {
                  const assignedContent = assignment["assigned_content"][k];

                  Object.keys(assignedContent).forEach((contentKey) => {
                    // @ts-ignore
                    newResult["assigned_content_" + contentKey] =
                      assignedContent[contentKey];
                  });
                } else {
                  // @ts-ignore
                  newResult[assignmentKey] = assignment[assignmentKey];
                }
              });
            } else {
              // @ts-ignore
              newResult[key] = result[key];
            }
          });
          newResults.push(newResult);
        }
      }
    }
    return newResults;
  }

  function flattenContentEngagementResults(results: string | any[]) {
    if (results[0].creator) {
      for (let i = 0; i < results.length; i += 1) {
        const currentCreator = results[i].creator;
        const creatorKeys = Object.keys(currentCreator);

        for (let j = 0; j < creatorKeys.length; j += 1) {
          if (creatorKeys[j] === "_id") {
            continue;
          }
          results[i]["creator_" + creatorKeys[j]] =
            currentCreator[creatorKeys[j]];
        }
      }
    }
    return results;
  }

  function flattenContentCompletionResults(results: string | any[]) {
    for (const result of results) {
      if (result.contexts && result.contexts.length > 0) {
        for (const context of result.contexts) {
          if (context.context_type === "Assignment") {
            result["was_assigned"] = true;
          }
        }
      }
      if (!("was_assigned" in result)) {
        result["was_assigned"] = false;
      }
    }
    if (results[0].content) {
      for (const result of results) {
        const currentContent = result.content;
        for (const key in currentContent) {
          if (key === "id") {
            continue;
          }
          result[key] = currentContent[key];
        }
      }
    }
    if (results[0].user) {
      for (const result of results) {
        const currentUser = result.user;
        for (const key in currentUser) {
          if (key === "created_at") {
            continue;
          } else {
            result[key] = currentUser[key];
          }
        }
      }
    }
    if (results[0].createdAt) {
      for (const result of results) {
        const currentCreatedTime = result.createdAt;
        result.created_at = currentCreatedTime;
      }
    }
    if (results[0].updatedAt) {
      for (const result of results) {
        const currentUpdatedTime = result.updatedAt;
        result.updated_at = currentUpdatedTime;
      }
    }
    return results;
  }

  function flattenAllManualCompletedByResults(results: any) {
    const newResults = [];
    for (const result of results) {
      if (!result.manual_completed_by) {
        newResults.push(result);
      } else {
        newResults.push(flattenManualCompletedByResult(result));
      }
    }
    return newResults;
  }

  function flattenManualCompletedByResult(result: any) {
    const newResult = {};

    for (const [key, value] of Object.entries(result)) {
      if (key === "manual_completed_by") {
        for (const [manualKey, manualValue] of Object.entries(result[key])) {
          // @ts-ignore
          newResult["manual_completed_by_" + manualKey] = manualValue;
        }
      } else {
        // @ts-ignore
        newResult[key] = value;
      }
    }
    return newResult;
  }

  const fieldTypePreviewValues = {
    booleans: ["Yes", "No", "No", "Yes", "Yes"],
    internalUserIds: [
      "89b4d29dcaa8837f1d00002b",
      "89b4d29dcaa4437f1d11116c",
      "5581fa9cd03176234f000024",
      "558ef0d02c6768a06b00007f",
      "54ff32cd3a8820417f000025",
    ],
    externalUserIds: [123457, 243083, 128938, 139872, 136808],
    firstNames: ["John", "Jane", "Craig", "Michael", "Tammy"],
    lastNames: ["Doe", "Smith", "Rogers", "Gordon", "Myers"],
    emails: [
      "john@acme.com",
      "jane@acme.com",
      "craig@acme.com",
      "michael@acme.com",
      "tammy@acme.com",
    ],
    jobTitles: [
      "CEO",
      "Assistant",
      "Human Resources Manager",
      "Purchasing Manager",
      "Engineering Manager",
    ],
    contentIds: [
      "43b4d29dcaa8837f1d11116c",
      "43b4d29dcaa4437f1d00002b",
      "4381fa9cd03176234f3333ae",
      "438ef0d02c6768a06b44447a",
      "43ff32cd3a8820417f333325",
    ],
    contentTitles: [
      "Onboarding: Week 1",
      "Sales New Hires",
      "Welcome to ACME",
      "Guide to Mentoring",
      "Coaching 101",
    ],
    contentTypes: [
      "workshop",
      "article",
      "video",
      "article",
      "imported_content",
    ],
    otherIds: [
      "7ab4d29dcaa8837f1d5555ab",
      "7ab4d29dcaa4437f1d55555c",
      "7a81fa9cd03176234f5555f7",
      "7a8ef0d02c6768a06b55559b",
      "7aff32cd3a8820417f555500",
    ],
    provisioning_status: [
      "provisioning",
      "inviting",
      "provisioning",
      "provisioning",
      "inviting",
    ],
    pastDates: [
      "2001-10-10 00:00",
      "2009-03-21 07:30",
      "2005-10-06 13:00",
      "2002-08-31 00:00",
      "2019-12-07 23:59",
    ],
    recentDates: [
      "2019-01-10 00:00",
      "2020-05-10 08:30",
      "2020-09-18 18:00",
      "2021-08-30 00:00",
      "2019-11-24 23:59",
    ],
    futureDates: [
      "2022-11-31 00:00",
      "2022-04-12 06:30",
      "2022-07-22 14:00",
      "2022-08-22 00:00",
      "2022-12-31 23:59",
    ],
    endOfFutureDates: [
      "2022-11-31 03:00",
      "2022-04-12 08:30",
      "2022-07-22 19:00",
      "2022-08-22 02:30",
      "2022-12-31 01:00",
    ],
    fartherFutureDates: [
      "2022-12-25 00:00",
      "2022-05-01 07:30",
      "2022-08-15 16:00",
      "2022-08-22 00:00",
      "2023-01-01 00:00",
    ],
    duration: ["03:00:00", "02:00:00", "05:00:00", "02:30:00", "01:01:00"],
    statuses: ["complete", "complete", "incomplete", "complete", "incomplete"],
    images: [
      "https://ibb.co/image123.png",
      "https://ibb.co/imageABC.png",
      "https://ibb.co/imageXYZ.png",
      "https://ibb.co/image910.png",
      "https://ibb.co/image130.png",
    ],
  };

  const fields = {
    userInternalId: {
      key: "id",
      name: "User Internal ID",
      copy: "reports.fields.internal_id",
      previewValues: fieldTypePreviewValues.internalUserIds,
    },
    userExternalId: {
      key: "userid",
      name: "User External ID",
      copy: "reports.fields.userid",
      previewValues: fieldTypePreviewValues.externalUserIds,
    },
    firstName: {
      key: "first_name",
      name: "First Name",
      copy: "reports.fields.first_name",
      previewValues: fieldTypePreviewValues.firstNames,
    },
    lastName: {
      key: "last_name",
      name: "Last Name",
      copy: "reports.fields.last_name",
      previewValues: fieldTypePreviewValues.lastNames,
    },
    role: {
      key: "role",
      name: "Role",
      quote: true,
      copy: "reports.fields.role",
      previewValues: ["admin", "user", "creator", "admin", "user"],
    },
    partner: {
      key: "partner",
      name: "Extend Account ID",
      quote: true,
      copy: "reports.fields.partner",
      previewValues: fieldTypePreviewValues.otherIds,
    },
    partnerName: {
      key: "partner_name",
      name: "Extend Account Name",
      quote: true,
      copy: "reports.fields.partner_name",
      previewValues: fieldTypePreviewValues.firstNames,
    },
    email: {
      key: "email",
      name: "Email",
      copy: "reports.fields.email",
      previewValues: fieldTypePreviewValues.emails,
    },
    creatorUserId: {
      key: "creator_userid",
      name: "Creator User ID",
      copy: "reports.fields.creator_userid",
      previewValues: fieldTypePreviewValues.internalUserIds,
    },
    creatorFirstName: {
      key: "creator_first_name",
      name: "Creator First Name",
      copy: "reports.fields.creator_first_name",
      previewValues: fieldTypePreviewValues.firstNames,
    },
    creatorLastName: {
      key: "creator_last_name",
      name: "Creator Last Name",
      copy: "reports.fields.creator_last_name",
      previewValues: fieldTypePreviewValues.lastNames,
    },
    creatorEmail: {
      key: "creator_email",
      name: "Creator Email",
      copy: "reports.fields.creator_email",
      previewValues: fieldTypePreviewValues.emails,
    },
    creatorImage: {
      key: "creator_image",
      name: "Creator Image",
      copy: "reports.fields.creator_image",
      previewValues: fieldTypePreviewValues.images,
    },
    creatorIsManager: {
      key: "creator_is_manager",
      name: "Creator Is Manager",
      copy: "reports.fields.creator_is_manager",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    creatorJobTitle: {
      key: "creator_job_title",
      name: "Creator Job Title",
      copy: "reports.fields.creator_job_title",
      previewValues: fieldTypePreviewValues.jobTitles,
    },
    location_1: {
      key: "location_1",
      reportsServiceRequestKey: "locations",
      reportsServiceResponseKey: "resolved_locations",
      reportsServiceArrayIndex: 0,
      name: "Location 1",
      copy: "reports.fields.location_1",
      quote: true,
      previewValues: [
        "United States",
        "United States",
        "United States",
        "United States",
        "United States",
      ],
    },
    location_2: {
      key: "location_2",
      reportsServiceRequestKey: "locations",
      reportsServiceResponseKey: "resolved_locations",
      reportsServiceArrayIndex: 1,
      name: "Location 2",
      copy: "reports.fields.location_2",
      quote: true,
      previewValues: [
        "California",
        "New York",
        "Washington",
        "California",
        "Oregon",
      ],
    },
    location_3: {
      key: "location_3",
      reportsServiceRequestKey: "locations",
      reportsServiceResponseKey: "resolved_locations",
      reportsServiceArrayIndex: 2,
      name: "Location 3",
      copy: "reports.fields.location_3",
      quote: true,
      previewValues: [
        "San Francisco",
        "New York",
        "Seattle",
        "Santa Monica",
        "Portland",
      ],
    },
    department_1: {
      key: "department_1",
      reportsServiceRequestKey: "departments",
      reportsServiceResponseKey: "resolved_departments",
      reportsServiceArrayIndex: 0,
      name: "Department 1",
      copy: "reports.fields.department_1",
      quote: true,
      previewValues: [
        "Executive",
        "Corporate",
        "Executive",
        "Corporate",
        "Engineering",
      ],
    },
    department_2: {
      key: "department_2",
      reportsServiceRequestKey: "departments",
      reportsServiceResponseKey: "resolved_departments",
      reportsServiceArrayIndex: 1,
      name: "Department 2",
      copy: "reports.fields.department_2",
      quote: true,
      previewValues: [
        "Corporate",
        "Office",
        "Corporate",
        "Purchasing",
        "Full-Stack",
      ],
    },
    department_3: {
      key: "department_3",
      reportsServiceRequestKey: "departments",
      reportsServiceResponseKey: "resolved_departments",
      reportsServiceArrayIndex: 2,
      name: "Department 3",
      copy: "reports.fields.department_3",
      quote: true,
      previewValues: [
        "",
        "Admin",
        "Human Resources",
        "",
        "Javascript Specialists",
      ],
    },
    teams: {
      key: "teams",
      reportsServiceResponseKey: "resolved_teams",
      name: "Team",
      isList: true,
      formatter: formatList(flatSeparator),
      quote: true,
      copy: "reports.fields.team",
      previewValues: [
        "Corporate | Management",
        "Office",
        "Human Resources | Office",
        "Corporate | Logistics",
        "Engineering | Development",
      ],
    },
    orgLevel: {
      key: "org_level",
      reportsServiceResponseKey: "resolved_org_level",
      name: "Level",
      quote: true,
      copy: "reports.fields.org_level",
      previewValues: [12, 5, 7, 8, 10],
    },
    grade: {
      key: "grade",
      reportsServiceResponseKey: "resolved_grade",
      name: "Grade",
      quote: true,
      copy: "reports.fields.grade",
      previewValues: [
        "Executive",
        "Team",
        "Corporate",
        "Individual Contributor",
        "Executive",
      ],
    },
    hiredOn: {
      key: "hired_on",
      name: "Hired Date",
      formatter: formatDate,
      copy: "reports.fields.hired_on",
      previewValues: fieldTypePreviewValues.pastDates,
    },
    isManager: {
      key: "is_manager",
      name: "Is Manager",
      formatter: formatYesNo,
      copy: "reports.fields.is_manager",
      previewValues: ["Yes", "No", "Yes", "Yes", "No"],
    },
    managerEmail: {
      key: "manager_email",
      name: "Manager Email",
      copy: "reports.fields.manager_email",
      previewValues: [
        "",
        "craig@acme.com",
        "john@acme.com",
        "alex@acme.com",
        "john@acme.com",
      ],
    },
    buddyEmail: {
      key: "buddy_email",
      name: "Buddy Email",
      copy: "reports.fields.buddy_email",
      previewValues: [
        "",
        "alex@acme.com",
        "george@acme.com",
        "sarah@acme.com",
        "craig@acme.com",
      ],
    },
    views: {
      key: "views",
      name: "Total Views",
      copy: "reports.fields.total_views",
      previewValues: [10068, 0, 70, 851, 3524],
    },
    likes: {
      key: "likes",
      name: "Total Likes",
      copy: "reports.fields.total_likes",
      previewValues: [5034, 0, 10, 124, 777],
    },
    shares: {
      key: "shares",
      name: "Total Shares",
      copy: "reports.fields.total_shares",
      previewValues: [2014, 0, 4, 72, 552],
    },
    comments: {
      key: "comments",
      name: "Total Comments",
      copy: "reports.fields.total_comments",
      previewValues: [303, 0, 21, 3, 53],
    },
    ratings: {
      key: "ratings",
      name: "Ratings Submitted",
      copy: "reports.fields.ratings",
      previewValues: [1015, 0, 30, 154, 219],
    },
    tracksStarted: {
      key: "tracks_started",
      name: "Tracks Started",
      copy: "reports.fields.tracks_started",
      previewValues: [84, 0, 2, 96, 777],
    },
    tracksCompleted: {
      key: "tracks_completed",
      name: "Tracks Completed",
      copy: "reports.fields.tracks_completed",
      previewValues: [71, 0, 2, 45, 657],
    },
    workshopsAttended: {
      key: "workshops_attended",
      name: "Workshops Attended",
      copy: "reports.fields.workshops_attended",
      previewValues: [1000, 0, 16, 54, 288],
    },
    pendingAssignments: {
      key: "assignments_pending",
      name: "Pending Assignments",
      copy: "reports.fieldsText.assignments_pending",
      previewValues: [528, 0, 16, 96, 432],
    },
    completedAssignments: {
      key: "assignments_completed",
      name: "Completed Assignments",
      copy: "reports.fieldsText.assignments_completed",
      previewValues: [965, 0, 8, 334, 425],
    },
    distributor: {
      key: "distributor",
      name: "Distributor",
      defaultOverride: "distributor-missing",
      quote: true,
      copy: "reports.fieldsText.distributor",
      previewValues: [
        "ACME Distributors",
        "ABC Distributors",
        "123 Distributors",
        "XYZ Distributors",
        "Dynamic Distributors",
      ],
    },
    address: {
      key: "address",
      name: "Store Name",
      defaultOverride: "storename-missing",
      quote: true,
      copy: "reports.fieldsText.address",
      previewValues: [
        "ACME Stores",
        "ABC Stores",
        "123 Stores",
        "XYZ Stores",
        "Dynamic Stores",
      ],
    },
    sessions: {
      key: "sessions",
      name: "Unique Logins",
      copy: "reports.fields.sessions",
      previewValues: [124198, 10, 5234, 971, 3446],
    },
    secondsViewed: {
      key: "seconds_viewed",
      name: "Time Spent",
      formatter: formatSeconds,
      copy: "reports.fields.seconds_viewed",
      previewValues: [15892, 0, 365, 7890, 433],
    },
    contentId: {
      key: "id",
      name: "Content ID",
      copy: "reports.fields.content_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    contentTitle: {
      key: "title",
      name: "Content Title",
      quote: true,
      copy: "reports.fields.content_title",
      previewValues: fieldTypePreviewValues.contentTitles,
    },
    contentType: {
      key: "type",
      name: "Content Type",
      copy: "reports.fields.content_type",
      previewValues: fieldTypePreviewValues.contentTypes,
    },
    contentTags: {
      key: "tags",
      name: "Content Tags",
      isList: true,
      formatter: formatList(flatSeparator),
      quote: true,
      copy: "reports.fields.content_tags",
      previewValues: [
        "leadership",
        "management",
        "training",
        "new hires",
        "onboarding",
      ],
    },
    onExplore: {
      key: "on_explore",
      name: "On Explore",
      formatter: formatYesNo,
      copy: "reports.fields.on_explore",
      previewValues: fieldTypePreviewValues.booleans,
    },
    published: {
      key: "published",
      name: "Published",
      formatter: formatYesNo,
      copy: "reports.fields.published",
      previewValues: fieldTypePreviewValues.booleans,
    },
    archived: {
      key: "archived",
      name: "Archived",
      formatter: formatYesNo,
      copy: "reports.fields.archived",
      previewValues: fieldTypePreviewValues.booleans,
    },
    inTrackIds: {
      key: "in_track_id",
      name: "Containing Track IDs",
      isList: true,
      formatter: formatList(flatSeparator),
      copy: "reports.fields.containing_track_ids",
      previewValues: [
        "5ce091cf7891d4001595ae8f | 43b4d29dcaa8837f1d11116c",
        "43b4d29dcaa4437f1d00002b | 5cdc2059338cdc001583d07f",
        "4381fa9cd03176234f3333ae",
        "438ef0d02c6768a06b44447a",
        "43ff32cd3a8820417f333325",
      ],
    },
    inTrackTitles: {
      key: "in_track_title",
      name: "Containing Tracks",
      isList: true,
      formatter: formatList(flatSeparator),
      quote: true,
      copy: "reports.fields.containing_tracks",
      previewValues: [
        "Leadership 101 | Leadership 102",
        "Management Basics | Management Advanced",
        "Learning at ACME",
        "Sales 201",
        "Success Bootcamp",
      ],
    },
    contentUrl: {
      key: "url",
      name: "URL",
      quote: true,
      copy: "reports.fields.url",
      previewValues: [
        "https://ibb.co/123",
        "https://ibb.co/ABC",
        "https://ibb.co/XYZ",
        "https://ibb.co/910",
        "https://ibb.co/130",
      ],
    },
    createdAt: {
      key: "created_at",
      name: "Created On",
      formatter: formatDate,
      copy: "reports.fields.created_on",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    updatedAt: {
      key: "updated_at",
      name: "Last Updated",
      formatter: formatDate,
      copy: "reports.fields.last_update",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    ratingsAverage: {
      key: "ratings_average",
      name: "Average Rating",
      copy: "reports.fields.ratings_average",
      previewValues: [4.2, 0, 5, 3.9, 4.7],
    },
    managerInternalId: {
      key: "id",
      name: "Manager Internal ID",
      copy: "reports.fields.internal_id",
      previewValues: fieldTypePreviewValues.internalUserIds,
    },
    managerExternalId: {
      key: "userid",
      name: "Manager External ID",
      copy: "reports.fields.external_id",
      previewValues: fieldTypePreviewValues.externalUserIds,
    },
    managerFirstName: {
      key: "first_name",
      name: "Manager First Name",
      copy: "reports.fields.first_name",
      previewValues: fieldTypePreviewValues.firstNames,
    },
    managerLastName: {
      key: "last_name",
      name: "Manager Last Name",
      copy: "reports.fields.last_name",
      previewValues: fieldTypePreviewValues.lastNames,
    },
    directReports: {
      key: "direct_reports",
      name: "Number of Direct Reports",
      copy: "reports.fields.total_direct_reports",
      previewValues: [31, 0, 1, 5, 12],
    },
    newHires: {
      key: "new_hires",
      name: "Number of New Hires",
      copy: "reports.fields.total_new_hires",
      previewValues: [12, 0, 4, 3, 6],
    },
    trackId: {
      key: "track_id",
      name: "Track ID",
      copy: "reports.fields.track_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    trackTitle: {
      key: "track_title",
      name: "Track Title",
      quote: true,
      copy: "reports.fields.track_title",
      previewValues: [
        "Leadership 101",
        "Management Basics",
        "Learning at ACME",
        "Sales 201",
        "Success Bootcamp",
      ],
    },
    trackStatus: {
      key: "track_status",
      name: "Track Status",
      copy: "reports.fields.track_status",
      previewValues: fieldTypePreviewValues.statuses,
    },
    trackCompletedDate: {
      key: "track_completed_date",
      name: "Track Completed Date",
      formatter: formatDate,
      copy: "reports.fields.track_completed_date",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    trackProgress: {
      key: "track_progress",
      name: "Track Progress",
      defaultOverride: 0.0,
      formatter: formatNumber,
      copy: "reports.fields.track_progress",
      previewValues: ["21%", "0%", "100%", "90%", "75%"],
    },
    assignmentId: {
      key: "assignment_id",
      name: "Assignment ID",
      copy: "reports.fields.assignment_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    assignerExternalId: {
      key: "assigner_userid",
      name: "Assigner External ID",
      copy: "reports.fields.assigner_userid",
      previewValues: fieldTypePreviewValues.externalUserIds,
    },
    assignerFirstName: {
      key: "assigner_first_name",
      name: "Assigner First Name",
      copy: "reports.fields.assigner_first_name",
      previewValues: fieldTypePreviewValues.firstNames,
    },
    assignerLastName: {
      key: "assigner_last_name",
      name: "Assigner Last Name",
      copy: "reports.fields.assigner_last_name",
      previewValues: fieldTypePreviewValues.lastNames,
    },
    assignerEmail: {
      key: "assigner_email",
      name: "Assigner Email",
      copy: "reports.fields.assigner_email",
      previewValues: fieldTypePreviewValues.emails,
    },
    assignerJobTitle: {
      key: "assigner_job_title",
      name: "Assigner Job Title",
      quote: true,
      copy: "reports.fields.assigner_job_title",
      previewValues: fieldTypePreviewValues.jobTitles,
    },
    assignedDate: {
      key: "assigned_date",
      name: "Assigned Date",
      formatter: formatDate,
      copy: "reports.fields.assigned_date",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    assignmentCompletedDate: {
      key: "completed_date",
      name: "Assignment Completed Date",
      formatter: formatDate,
      copy: "reports.fields.completed_date",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    assignmentStatus: {
      key: "completion_status",
      name: "Assignment Status",
      copy: "reports.fields.assignment_status",
      previewValues: fieldTypePreviewValues.statuses,
    },
    assignmentDueDate: {
      key: "due_date",
      name: "Assignment Due Date",
      formatter: formatDate,
      copy: "reports.fields.due_date",
      previewValues: fieldTypePreviewValues.futureDates,
    },
    assignedContentId: {
      key: "assigned_content_id",
      name: "Content ID",
      copy: "reports.fields.content_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    assignedContentTitle: {
      key: "assigned_content_title",
      name: "Content Title",
      quote: true,
      copy: "reports.fields.content_title",
      previewValues: fieldTypePreviewValues.contentTitles,
    },
    assignedContentType: {
      key: "assigned_content_type",
      name: "Content Type",
      copy: "reports.fields.content_type",
      previewValues: fieldTypePreviewValues.contentTypes,
    },
    assignedContentCompletionDate: {
      key: "assigned_content_completed_date",
      name: "Content Completion Date",
      formatter: formatDate,
      copy: "reports.fields.content_completed_date",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    image: {
      key: "image",
      name: "Image",
      quote: true,
      copy: "reports.fields.image",
      previewValues: fieldTypePreviewValues.images,
    },
    language: {
      key: "language",
      name: "Language",
      copy: "reports.fields.language",
      previewValues: ["en", "en", "spa", "en", "spa"],
    },
    job_title: {
      key: "job_title",
      name: "Job Title",
      quote: true,
      copy: "reports.fields.job_title",
      previewValues: fieldTypePreviewValues.jobTitles,
    },
    isCollaborator: {
      key: "is_collaborator",
      name: "Is Collaborator",
      formatter: formatYesNo,
      copy: "reports.fields.is_collaborator",
      previewValues: fieldTypePreviewValues.booleans,
    },
    group: {
      key: "group",
      name: "Retailer",
      quote: true,
      copy: "reports.fields.group",
      defaultOverride: "retailer-missing",
      previewValues: [
        "Retail Group",
        "Marketing Group",
        "Sales Group",
        "Executive Group",
        "Admin Group",
      ],
    },
    groups: {
      key: "groups",
      reportsServiceResponseKey: "resolved_groups",
      name: "Groups",
      isList: true,
      formatter: formatList(flatSeparator),
      quote: true,
      copy: "reports.fields.groups",
      previewValues: [
        "Group 101",
        "Marketing & Sales",
        "Onboarding Cohort",
        "Sales 201",
        "XYZ Group",
      ],
    },
    firstLogin: {
      key: "first_login",
      name: "First Login",
      formatter: formatDate,
      copy: "reports.fields.first_login",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    lastLogin: {
      key: "last_login",
      name: "Last Login",
      formatter: formatDate,
      copy: "reports.fields.last_login",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    suspended: {
      key: "suspended",
      name: "Suspended",
      formatter: formatYesNo,
      copy: "reports.fields.suspended",
      previewValues: fieldTypePreviewValues.booleans,
    },
    suspendedOn: {
      key: "suspended_on",
      name: "Suspended On",
      formatter: formatDate,
      copy: "reports.fields.suspended_on",
      previewValues: fieldTypePreviewValues.recentDates,
    },
    workshopId: {
      key: "workshop_id",
      name: "Workshop ID",
      copy: "reports.fields.workshop_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    workshopTitle: {
      key: "workshop_title",
      name: "Workshop Title",
      quote: true,
      copy: "reports.fields.workshop_title",
      previewValues: [
        "Performance Management",
        "Sales Bootcamp",
        "Onboarding: Week 2",
        "New Manager Training",
        "Welcome to ACME!",
      ],
    },
    parentWorkshopId: {
      key: "parent_workshop_id",
      name: "Parent Workshop ID",
      copy: "reports.fields.parent_workshop_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    parentWorkshopTitle: {
      key: "parent_workshop_title",
      name: "Parent Workshop Title",
      quote: true,
      copy: "reports.fields.parent_workshop_title",
      previewValues: [
        "Performance Management Workshops",
        "Sales Bootcamp Conference",
        "Onboarding",
        "New Manager Workshops",
        "Acme Intro Workshops",
      ],
    },
    workshopStartDate: {
      key: "workshop_start_date",
      name: "Workshop Start Date",
      formatter: formatWorkshopDate,
      copy: "reports.fields.workshop_start_date",
      previewValues: fieldTypePreviewValues.futureDates,
    },
    workshopEndDate: {
      key: "workshop_end_date",
      name: "Workshop End Date",
      formatter: formatWorkshopDate,
      copy: "reports.fields.workshop_end_date",
      previewValues: fieldTypePreviewValues.endOfFutureDates,
    },
    workshopDuration: {
      key: "workshop_duration",
      name: "Training Hours",
      formatter: formatSeconds,
      copy: "reports.fields.training_hours",
      previewValues: fieldTypePreviewValues.duration,
    },
    workshopStatus: {
      key: "workshop_status",
      name: "Workshop Status",
      copy: "reports.fields.workshop_status",
      previewValues: [
        "Registered",
        "Attended",
        "Attended",
        "Registered",
        "Registered",
      ],
    },
    workshopInstanceId: {
      key: "workshop_instance_id",
      name: "Workshop Instance ID",
      copy: "reports.fields.workshop_instance_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    workshopHostName: {
      key: "host_name",
      name: "Host Name",
      copy: "reports.fields.host_name",
      previewValues: [
        "John Doe",
        "Jane Smith",
        "Craig Rogers",
        "Michael Gordon",
        "Tammy Myers",
      ],
      quote: true,
    },
    workshopInstanceDate: {
      key: "workshop_instance_date",
      name: "Workshop Instance Date",
      copy: "reports.fields.workshop_instance_date",
      formatter: formatDate,
      previewValues: fieldTypePreviewValues.recentDates,
    },
    registrationDisabled: {
      key: "registration_disabled",
      name: "Registration Disabled",
      copy: "reports.fields.registration_disabled",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    offeredSeats: {
      key: "offered_seats",
      name: "Offered Seats",
      copy: "reports.fields.offered_seats",
      previewValues: [100, 25, 50, 10, 50],
    },
    waitlistEnabled: {
      key: "waitlist_enabled",
      name: "Waitlist Enabled",
      copy: "reports.fields.waitlist_enabled",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    waitlistSeats: {
      key: "waitlist_seats",
      name: "Waitlist Seats",
      copy: "reports.fields.waitlist_seats",
      previewValues: [20, 0, 0, 2, 20],
    },
    registeredSeats: {
      key: "registered_seats",
      name: "Registered Seats",
      copy: "reports.fields.registered_seats",
      previewValues: [100, 22, 43, 10, 50],
    },
    totalWaiting: {
      key: "total_waiting",
      name: "Total Waiting",
      copy: "reports.fields.total_waiting",
      previewValues: [5, 0, 0, 0, 5],
    },
    canceledRegistrations: {
      key: "canceled_registrations",
      name: "Canceled Registrations",
      copy: "reports.fields.canceled_registrations", // update
      previewValues: [12, 5, 6, 1, 10],
    },
    totalAttendees: {
      key: "total_attendees",
      name: "Total Attendees",
      copy: "reports.fields.total_attendees",
      previewValues: [90, 25, 50, 10, 48],
    },
    totalAbsentees: {
      key: "total_absentees",
      name: "Total Absentees",
      copy: "reports.fields.total_absentees",
      previewValues: [10, 0, 0, 0, 2],
    },
    workshopArchived: {
      key: "archived",
      name: "Workshop Archived",
      copy: "reports.fields.workshop_archived",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    assessmentId: {
      key: "assessment_id",
      name: "Assessment ID",
      copy: "reports.fields.assessment_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    assessmentTitle: {
      key: "assessment_title",
      name: "Assessment Title",
      copy: "reports.fields.assessment_title",
      quote: true,
      previewValues: [
        "Knowledge Check",
        "Onboarding Check-In",
        "Sales Pitching",
        "Final Exam",
        "Fundamentals of Sales",
      ],
    },
    assessmentStatus: {
      key: "assessment_status",
      name: "Assessment Status",
      copy: "reports.fields.assessment_status",
      previewValues: ["Passed", "Passed", "Passed", "Not Passed", "Passed"],
    },
    assessmentAttempts: {
      key: "assessment_attempts",
      name: "Assessment Attempts",
      copy: "reports.fields.assessment_attempts",
      previewValues: [12, 0, 5, 3, 1],
    },
    assessmentScore: {
      key: "assessment_score",
      name: "Assessment Score",
      copy: "reports.fields.assessment_score",
      previewValues: [100, 95, 0, 83, 74],
    },
    assessmentCompletedDate: {
      key: "assessment_completed_date",
      name: "Assessment Completed Date",
      copy: "reports.fields.assessment_completed_date",
      formatter: formatDate,
      previewValues: fieldTypePreviewValues.recentDates,
    },
    scormId: {
      key: "scorm_id",
      name: "SCORM ID",
      copy: "reports.fields.scorm_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    scormTitle: {
      key: "scorm_title",
      name: "SCORM Title",
      copy: "reports.fields.scorm_title",
      quote: true,
      previewValues: [
        "Working Remotely",
        "Leading Teams",
        "Management 101",
        "Compliance Training",
        "Marketing for Teams",
      ],
    },
    scormStartedDate: {
      key: "scorm_started_date",
      name: "SCORM Started Date",
      copy: "reports.fields.scorm_started_date",
      formatter: formatDate,
      previewValues: fieldTypePreviewValues.recentDates,
    },
    scormLastUpdatedDate: {
      key: "scorm_last_updated_date",
      name: "SCORM Last Updated Date",
      copy: "reports.fields.scorm_last_updated_date",
      formatter: formatDate,
      previewValues: fieldTypePreviewValues.recentDates,
    },
    scormStatus: {
      key: "scorm_status",
      name: "SCORM Status",
      copy: "reports.fields.scorm_status",
      previewValues: ["Passed", "Passed", "Incomplete", "Incomplete", "Passed"],
    },
    manualCompleted: {
      key: "manual_completed_by",
      name: "Manual Completed",
      copy: "reports.fields.manual_completed",
      previewValues: fieldTypePreviewValues.booleans,
      reportsServiceResponseKey: "manual_completion",
      formatter: formatYesNo,
    },
    manualCompletedByFirstName: {
      key: "manual_completed_by_first_name",
      name: "Manual Completed By First Name",
      copy: "reports.fields.manual_completed_by_first_name",
      previewValues: fieldTypePreviewValues.firstNames,
    },
    manualCompletedByLastName: {
      key: "manual_completed_by_last_name",
      name: "Manual Completed By Last Name",
      copy: "reports.fields.manual_completed_by_last_name",
      previewValues: fieldTypePreviewValues.lastNames,
    },
    manualCompletedByEmail: {
      key: "manual_completed_by_email",
      name: "Manual Completed By Email",
      copy: "reports.fields.manual_completed_by_email",
      previewValues: fieldTypePreviewValues.emails,
    },
    manualCompletedByImage: {
      key: "manual_completed_by_image",
      name: "Manual Completed By Image",
      copy: "reports.fields.manual_completed_by_image",
      previewValues: fieldTypePreviewValues.images,
    },
    manualCompletedByUserId: {
      key: "manual_completed_by_userid",
      name: "Manual Completed By UserId",
      copy: "reports.fields.manual_completed_by_userid",
      previewValues: fieldTypePreviewValues.internalUserIds,
    },
    manualCompletedByJobTitle: {
      key: "manual_completed_by_job_title",
      name: "Manual Completed By Job Title",
      copy: "reports.fields.manual_completed_by_job_title",
      previewValues: fieldTypePreviewValues.jobTitles,
    },
    manualCompletedByIsManager: {
      key: "manual_completed_by_is_manager",
      name: "Manual Completed By Is Manager",
      copy: "reports.fields.manual_completed_by_is_manager",
      previewValues: fieldTypePreviewValues.booleans,
      formatter: formatYesNo,
    },
    trackCompletedWithinName: {
      key: "containing_track",
      name: "Containing Track",
      copy: "reports.fields.containing_track",
      previewValues: fieldTypePreviewValues.contentTitles,
    },
    trackCompletedWithinId: {
      key: "containing_track_id",
      name: "Containing Track ID",
      copy: "reports.fields.containing_track_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    journeyCompletedWithinName: {
      key: "containing_journey",
      name: "Containing Journey",
      copy: "reports.fields.containing_journey",
      previewValues: fieldTypePreviewValues.contentTitles,
    },
    journeyCompletedWithinId: {
      key: "containing_journey_id",
      name: "Containing Journey ID",
      copy: "reports.fields.containing_journey_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    contentCompletedOn: {
      key: "completed_date",
      name: "Content Completion Time",
      copy: "reports.fields.completed_on",
      previewValues: fieldTypePreviewValues.pastDates,
    },
    completedContentId: {
      key: "content_id",
      name: "Content ID",
      copy: "reports.fields.content_id",
      previewValues: fieldTypePreviewValues.contentIds,
    },
    wasAssigned: {
      key: "was_assigned",
      name: "Assigned to User",
      copy: "reports.fields.was_assigned",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    isPurchaser: {
      key: "is_purchaser",
      name: "Is Purchaser",
      copy: "reports.fields.is_purchaser",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    assignerIsPurchaser: {
      key: "assigner_is_purchaser",
      name: "Assigner Is Purchaser",
      copy: "reports.fields.assigner_is_purchaser",
      formatter: formatYesNo,
      previewValues: fieldTypePreviewValues.booleans,
    },
    contentStarted: {
      key: "start_date",
      name: "Content Started",
      copy: "reports.fields.start_date",
      previewValues: fieldTypePreviewValues.pastDates,
    },
  };

  const customFields: Record<string, any> = {};
  let partnerFields: any[] = [];
  if (company.feature_flags.enable_partners) {
    partnerFields = [fields.partner, fields.partnerName];
  }

  if (company.user_metadata_fields) {
    company.user_metadata_fields
      .filter((umf) => umf.visibility?.reports === "readonly")
      .forEach((field) => {
        const fieldBody = {
          key: "userMetadata_" + field.name,
          name: field.name,
          copy: field.name,
        };
        customFields[fieldBody.key] = fieldBody;
      });
  }

  const reportTypes = {
    "user-engagement": {
      formValue: "user-engagement",
      name: "User Engagement",
      labelId: "userEngagement",
      help: "Detailed breakdown of all user engagement",
      defaultFilters: {
        user_filter: {
          suspended: "false",
        },
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
      },
      contentFilter: null,
      userFilter: "all",
      applyDateRange: true,
      defaultFields: [
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.role,
        fields.email,
        ...partnerFields,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.hiredOn,
        fields.isManager,
        fields.managerEmail,
        fields.buddyEmail,
        fields.views,
        fields.likes,
        fields.shares,
        fields.comments,
        fields.ratings,
        fields.tracksStarted,
        fields.tracksCompleted,
        fields.workshopsAttended,
        fields.pendingAssignments,
        fields.completedAssignments,
        ...Object.values(customFields),
      ],
    },
    "content-engagement": {
      formValue: "content-engagement",
      name: "Content Engagement",
      labelId: "contentEngagement",
      help: "Detailed breakdown of all content engagement",
      defaultFilters: {
        content_filter: {},
      },
      filterTypeToReportFilterConfig: {
        Article: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        Media: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        ImportedContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        ScormContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        Track: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        Workshop: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        location: {
          reportsGroupKey: "locations",
          reportsGroup: "content_filter",
        },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "content_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "content_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "content_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "content_filter",
        },
      },
      contentFilter: "reportContent",
      userFilter: "categories",
      applyDateRange: true,
      defaultFields: [
        fields.contentId,
        fields.contentTitle,
        fields.contentType,
        fields.contentTags,
        fields.onExplore,
        fields.published,
        fields.archived,
        fields.inTrackIds,
        fields.inTrackTitles,
        fields.contentUrl,
        fields.creatorFirstName,
        fields.creatorLastName,
        fields.creatorEmail,
        fields.createdAt,
        fields.updatedAt,
        fields.views,
        fields.likes,
        fields.shares,
        fields.comments,
        fields.ratingsAverage,
        fields.pendingAssignments,
        fields.completedAssignments,
      ],
      resultsTransform: flattenContentEngagementResults,
    },
    "content-completion": {
      formValue: "content-completion",
      name: "Content Completion",
      labelId: "contentCompletion",
      help: "Detailed breakdown of content completion",
      defaultFilters: {
        content_filter: {},
        user_filter: {
          suspended: "false",
        },
      },
      filterTypeToReportFilterConfig: {
        Article: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        Media: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        ImportedContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        ScormContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        Track: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        Workshop: {
          reportsGroupKey: "content_id",
          reportsGroup: "content_filter",
        },
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
      },
      contentFilter: "reportContent",
      userFilter: "categories",
      applyDateRange: true,
      defaultFields: [
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.role,
        fields.email,
        ...partnerFields,
        fields.image,
        fields.language,
        fields.job_title,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.isManager,
        fields.managerEmail,
        fields.isCollaborator,
        fields.lastLogin,
        fields.hiredOn,
        fields.completedContentId,
        fields.contentTitle,
        fields.contentType,
        fields.contentUrl,
        fields.createdAt,
        fields.updatedAt,
        fields.wasAssigned,
        fields.contentStarted,
        fields.contentCompletedOn,
      ],
      resultsTransform: flattenContentCompletionResults,
    },
    "workshop-engagement": {
      formValue: "workshop-engagement",
      name: "Workshop Engagement",
      labelId: "workshopEngagement",
      help: "Detailed breakdown of all workshop engagement",
      defaultFilters: {
        workshop_filter: {},
      },
      filterTypeToReportFilterConfig: {
        Workshop: {
          reportsGroupKey: "content_id",
          reportsGroup: "workshop_filter",
        },
      },
      contentFilter: "workshop",
      userFilter: "all",
      applyDateRange: true,
      defaultFields: [
        fields.workshopId,
        fields.workshopTitle,
        fields.workshopHostName,
        fields.workshopInstanceId,
        fields.workshopStartDate,
        fields.workshopEndDate,
        fields.workshopDuration,
        fields.registrationDisabled,
        fields.offeredSeats,
        fields.waitlistEnabled,
        fields.waitlistSeats,
        fields.registeredSeats,
        fields.totalWaiting,
        fields.canceledRegistrations,
        fields.totalAttendees,
        fields.totalAbsentees,
        fields.workshopArchived,
      ],
      resultsTransform: flattenInstancesWorkshopEngagement,
    },
    "assignment-summary": {
      formValue: "assignment-summary",
      key: "assignment-summary",
      name: "Assignment Summary",
      labelId: "assignmentSummary",
      help: "Detailed breakdown of all assigned training",
      defaultFilters: {
        user_filter: {
          suspended: "false",
        },
        assignment_filter: {
          archived: "false"
        },
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
        Article: {
          reportsGroupKey: "content_id",
          reportsGroup: "assignment_filter",
        },
        Media: {
          reportsGroupKey: "content_id",
          reportsGroup: "assignment_filter",
        },
        ImportedContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "assignment_filter",
        },
        ScormContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "assignment_filter",
        },
        Track: {
          reportsGroupKey: "content_id",
          reportsGroup: "assignment_filter",
        },
        Workshop: {
          reportsGroupKey: "content_id",
          reportsGroup: "assignment_filter",
        },
      },
      contentFilter: "reportContent",
      userFilter: "all",
      applyDateRange: true,
      defaultFields: [
        fields.assignmentId,
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.role,
        fields.email,
        ...partnerFields,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.hiredOn,
        fields.isManager,
        fields.managerEmail,
        fields.buddyEmail,
        fields.assignerFirstName,
        fields.assignerLastName,
        fields.assignerEmail,
        fields.assignerJobTitle,
        fields.assignerExternalId,
        fields.assignedDate,
        fields.assignmentCompletedDate,
        fields.assignmentStatus,
        fields.assignmentDueDate,
        fields.assignedContentTitle,
        fields.assignedContentId,
        fields.assignedContentType,
        fields.assignedContentCompletionDate,
        fields.manualCompleted,
        fields.manualCompletedByFirstName,
        fields.manualCompletedByLastName,
        fields.manualCompletedByEmail,
        fields.manualCompletedByImage,
        fields.manualCompletedByUserId,
        fields.manualCompletedByJobTitle,
        fields.manualCompletedByIsManager,
        ...Object.values(customFields),
      ],
      resultsTransform: flattenAssignmentSummaryResults,
    },
    "manager-summary": {
      formValue: "manager-summary",
      name: "Manager Summary",
      labelId: "managerSummary",
      help: "High level Manager comparison report ",
      defaultFilters: {
        manager_filter: {
          suspended: "false",
        },
        user_filter: {
          suspended: "false",
        },
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "manager_filter" },
        location: {
          reportsGroupKey: "locations",
          reportsGroup: "manager_filter",
        },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "manager_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "manager_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "manager_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "manager_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
      },
      contentFilter: null,
      userFilter: "all",
      applyDateRange: true,
      defaultFields: [
        fields.managerInternalId,
        fields.managerExternalId,
        fields.managerFirstName,
        fields.managerLastName,
        fields.email,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.directReports,
        fields.newHires,
        fields.pendingAssignments,
        fields.completedAssignments,
        fields.tracksStarted,
        fields.tracksCompleted,
        fields.views,
        fields.likes,
        fields.shares,
        fields.comments,
        fields.ratings,
      ],
    },
    "track-status": {
      formValue: "track-status",
      name: "Track Status",
      labelId: "learningTracksStatus",
      help: "Aggregated activity report for Learning Tracks",
      defaultFilters: {
        user_filter: {
          suspended: "false",
        },
        track_filter: {},
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
        Track: { reportsGroupKey: "content_id", reportsGroup: "track_filter" },
      },
      contentFilter: "track",
      userFilter: "all",
      applyDateRange: true,
      defaultFields: [
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.role,
        fields.email,
        ...partnerFields,
        fields.image,
        fields.language,
        fields.job_title,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.isManager,
        fields.managerEmail,
        fields.buddyEmail,
        fields.isCollaborator,
        fields.firstLogin,
        fields.lastLogin,
        fields.hiredOn,
        fields.createdAt,
        fields.suspended,
        fields.suspendedOn,
        fields.trackId,
        fields.trackTitle,
        fields.trackStatus,
        fields.trackCompletedDate,
        fields.trackProgress,
        fields.manualCompleted,
        fields.manualCompletedByFirstName,
        fields.manualCompletedByLastName,
        fields.manualCompletedByEmail,
        fields.manualCompletedByImage,
        fields.manualCompletedByUserId,
        fields.manualCompletedByJobTitle,
        fields.manualCompletedByIsManager,
        fields.journeyCompletedWithinName,
        fields.journeyCompletedWithinId,
        ...Object.values(customFields),
      ],
      resultsTransform: flattenAllManualCompletedByResults,
    },
    "workshop-status": {
      formValue: "workshop-status",
      name: "Workshop Status",
      labelId: "workshopStatus",
      help: "Aggregated activity report for Workshops",
      defaultFilters: {
        user_filter: {
          suspended: "false",
        },
        workshop_filter: {
          archived: "false",
        },
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
        Workshop: {
          reportsGroupKey: "content_id",
          reportsGroup: "workshop_filter",
        },
      },
      contentFilter: "workshop",
      userFilter: "all",
      applyDateRange: false,
      defaultFields: [
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.email,
        fields.role,
        ...partnerFields,
        fields.image,
        fields.language,
        fields.job_title,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.isManager,
        fields.managerEmail,
        fields.buddyEmail,
        fields.isCollaborator,
        fields.firstLogin,
        fields.lastLogin,
        fields.hiredOn,
        fields.createdAt,
        fields.suspended,
        fields.suspendedOn,
        fields.workshopId,
        fields.workshopTitle,
        fields.workshopStartDate,
        fields.workshopEndDate,
        fields.workshopDuration,
        fields.workshopStatus,
        ...Object.values(customFields),
      ],
    },
    "assessment-status": {
      formValue: "assessment-status",
      name: "Assessment Status",
      labelId: "assessmentStatus",
      help: "Aggregated activity report for Assessments",
      defaultFilters: {
        user_filter: {
          suspended: "false",
        },
        assessment_filter: {
          archived: "false",
        },
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
        Assessment: {
          reportsGroupKey: "content_id",
          reportsGroup: "assessment_filter",
        },
      },
      contentFilter: "assessment",
      userFilter: "all",
      applyDateRange: false,
      defaultFields: [
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.email,
        fields.role,
        ...partnerFields,
        fields.image,
        fields.language,
        fields.job_title,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.isManager,
        fields.managerEmail,
        fields.buddyEmail,
        fields.isCollaborator,
        fields.firstLogin,
        fields.lastLogin,
        fields.hiredOn,
        fields.createdAt,
        fields.suspended,
        fields.suspendedOn,
        fields.assessmentId,
        fields.assessmentTitle,
        fields.assessmentStatus,
        fields.assessmentAttempts,
        fields.assessmentScore,
        fields.assessmentCompletedDate,
        fields.trackCompletedWithinName,
        fields.trackCompletedWithinId,
        fields.journeyCompletedWithinName,
        fields.journeyCompletedWithinId,
      ],
    },
    "scorm-status": {
      formValue: "scorm-status",
      name: "SCORM Status",
      labelId: "scormStatus",
      help: "Aggregated activity report for SCORM packages",
      defaultFilters: {
        user_filter: {
          suspended: "false",
        },
        scorm_filter: {
          archived: "false",
        },
      },
      filterTypeToReportFilterConfig: {
        User: { reportsGroupKey: "id", reportsGroup: "user_filter" },
        location: { reportsGroupKey: "locations", reportsGroup: "user_filter" },
        department: {
          reportsGroupKey: "departments",
          reportsGroup: "user_filter",
        },
        team: { reportsGroupKey: "teams", reportsGroup: "user_filter" },
        grade: { reportsGroupKey: "grades", reportsGroup: "user_filter" },
        org_level: {
          reportsGroupKey: "org_levels",
          reportsGroup: "user_filter",
        },
        Group: { reportsGroupKey: "groups", reportsGroup: "user_filter" },
        ScormContent: {
          reportsGroupKey: "content_id",
          reportsGroup: "scorm_filter",
        },
      },
      contentFilter: "scorm",
      userFilter: "all",
      applyDateRange: true,
      defaultFields: [
        fields.userInternalId,
        fields.userExternalId,
        fields.firstName,
        fields.lastName,
        fields.email,
        fields.role,
        ...partnerFields,
        fields.image,
        fields.language,
        fields.job_title,
        fields.location_1,
        fields.location_2,
        fields.location_3,
        fields.department_1,
        fields.department_2,
        fields.department_3,
        fields.teams,
        fields.orgLevel,
        fields.grade,
        fields.isManager,
        fields.managerEmail,
        fields.buddyEmail,
        fields.isCollaborator,
        fields.firstLogin,
        fields.lastLogin,
        fields.hiredOn,
        fields.createdAt,
        fields.suspended,
        fields.suspendedOn,
        fields.scormId,
        fields.scormTitle,
        fields.scormStartedDate,
        fields.scormLastUpdatedDate,
        fields.scormStatus,
        fields.manualCompleted,
        fields.manualCompletedByFirstName,
        fields.manualCompletedByLastName,
        fields.manualCompletedByEmail,
        fields.manualCompletedByImage,
        fields.manualCompletedByUserId,
        fields.manualCompletedByJobTitle,
        fields.manualCompletedByIsManager,
        fields.trackCompletedWithinName,
        fields.trackCompletedWithinId,
        fields.journeyCompletedWithinName,
        fields.journeyCompletedWithinId,
        ...Object.values(customFields),
      ],
      resultsTransform: flattenAllManualCompletedByResults,
    },
  };

  if (company.feature_flags.user_engagement_report_include_session_tracking) {
    reportTypes["user-engagement"].defaultFields.splice(15, 0, fields.sessions);
    reportTypes["user-engagement"].defaultFields.splice(
      16,
      0,
      fields.secondsViewed
    );
  }
  if (company.feature_flags.reports_include_physical_store_info) {
    reportTypes["user-engagement"].defaultFields.splice(
      6,
      0,
      fields.distributor
    );
    reportTypes["user-engagement"].defaultFields.splice(7, 0, fields.group);
    reportTypes["user-engagement"].defaultFields.splice(8, 0, fields.address);

    reportTypes["assignment-summary"].defaultFields.splice(
      6,
      0,
      fields.distributor
    );
    reportTypes["assignment-summary"].defaultFields.splice(7, 0, fields.group);
    reportTypes["assignment-summary"].defaultFields.splice(
      8,
      0,
      fields.address
    );

    reportTypes["manager-summary"].defaultFields.splice(
      6,
      0,
      fields.distributor
    );
    reportTypes["manager-summary"].defaultFields.splice(7, 0, fields.group);
    reportTypes["manager-summary"].defaultFields.splice(8, 0, fields.address);

    reportTypes["assessment-status"].defaultFields.splice(
      6,
      0,
      fields.distributor
    );
    reportTypes["assessment-status"].defaultFields.splice(7, 0, fields.group);
    reportTypes["assessment-status"].defaultFields.splice(8, 0, fields.address);

    reportTypes["workshop-status"].defaultFields.splice(
      6,
      0,
      fields.distributor
    );
    reportTypes["workshop-status"].defaultFields.splice(7, 0, fields.group);
    reportTypes["workshop-status"].defaultFields.splice(8, 0, fields.address);

    reportTypes["track-status"].defaultFields.splice(6, 0, fields.distributor);
    reportTypes["track-status"].defaultFields.splice(7, 0, fields.group);
    reportTypes["track-status"].defaultFields.splice(8, 0, fields.address);

    reportTypes["scorm-status"].defaultFields.splice(6, 0, fields.distributor);
    reportTypes["scorm-status"].defaultFields.splice(7, 0, fields.group);
    reportTypes["scorm-status"].defaultFields.splice(8, 0, fields.address);

    reportTypes["content-completion"].defaultFields.splice(
      6,
      0,
      fields.distributor
    );
    reportTypes["content-completion"].defaultFields.splice(7, 0, fields.group);
    reportTypes["content-completion"].defaultFields.splice(
      8,
      0,
      fields.address
    );
  }

  if (company.groups) {
    // splice based on defaultFields length
    reportTypes["user-engagement"].defaultFields.splice(15, 0, fields.groups);
    reportTypes["assignment-summary"].defaultFields.splice(
      16,
      0,
      fields.groups
    );
    reportTypes["manager-summary"].defaultFields.splice(14, 0, fields.groups);
    reportTypes["assessment-status"].defaultFields.splice(18, 0, fields.groups);
    reportTypes["workshop-status"].defaultFields.splice(18, 0, fields.groups);
    reportTypes["track-status"].defaultFields.splice(18, 0, fields.groups);
    reportTypes["scorm-status"].defaultFields.splice(18, 0, fields.groups);
    reportTypes["content-completion"].defaultFields.splice(
      18,
      0,
      fields.groups
    );
  }

  if (company.feature_flags.enable_multisession_workshops) {
    reportTypes["workshop-status"].defaultFields.splice(
      30,
      0,
      fields.parentWorkshopTitle
    );
    reportTypes["workshop-engagement"].defaultFields.splice(
      2,
      0,
      fields.parentWorkshopId
    );
    reportTypes["workshop-engagement"].defaultFields.splice(
      3,
      0,
      fields.parentWorkshopTitle
    );
  }

  if (company.feature_flags.enable_ecommerce) {
    reportTypes["user-engagement"].defaultFields.splice(
      6,
      0,
      fields.isPurchaser
    );
    reportTypes["content-completion"].defaultFields.splice(
      6,
      0,
      fields.isPurchaser
    );
    reportTypes["assignment-summary"].defaultFields.splice(
      6,
      0,
      fields.isPurchaser,
      fields.assignerIsPurchaser
    );
    reportTypes["manager-summary"].defaultFields.splice(
      5,
      0,
      fields.isPurchaser
    );
    reportTypes["track-status"].defaultFields.splice(6, 0, fields.isPurchaser);
    reportTypes["workshop-status"].defaultFields.splice(
      6,
      0,
      fields.isPurchaser
    );
    reportTypes["assessment-status"].defaultFields.splice(
      6,
      0,
      fields.isPurchaser
    );
    reportTypes["scorm-status"].defaultFields.splice(6, 0, fields.isPurchaser);
  }

  Object.keys(reportTypes).forEach((reportType) => {
    // @ts-ignore
    const fields = reportTypes[reportType].defaultFields;
    const reportFieldsByKey = {};
    Object.keys(fields).forEach((key) => {
      // @ts-ignore
      reportFieldsByKey[fields[key].key] = fields[key];
    });
    // @ts-ignore
    reportTypes[reportType].reportFieldsByKey = reportFieldsByKey;
  });

  const getCsv = (
    reportType: string | number,
    results: string | any[],
    fields: any[]
  ) => {
    // @ts-ignore
    const { resultsTransform } = reportTypes[reportType];

    if (resultsTransform) {
      // eslint-disable-next-line no-param-reassign
      results = resultsTransform(results);
    }

    let csv = [];

    for (let i = 0, leni = results.length; i < leni; i += 1) {
      let row = "";

      fields.forEach((field) => {
        const defaultValue = field.isList ? [] : "";

        const defaultForField =
          field.defaultValue !== undefined ? field.defaultValue : defaultValue;

        let result;

        if (field.key.includes("userMetadata")) {
          const metaDataResults =
            results[i]["user_metadata"] ?? results[i]["assignee_metadata"];

          if (metaDataResults) {
            metaDataResults.forEach((customFieldArr: string[]) => {
              if (customFieldArr[0] === field.name) {
                result = customFieldArr[1];
              }
            });
          }
        } else {
          result = results[i][field.reportsServiceResponseKey || field.key];
        }

        if (result && field.reportsServiceArrayIndex !== undefined) {
          result = result[field.reportsServiceArrayIndex];
        }

        if (result === undefined || result === null || result === "") {
          result = defaultForField;
        }
        if (field.formatter) {
          result = field.formatter(result);
        }
        if (field.quote) {
          // escape double quotes (https://en.wikipedia.org/wiki/Comma-separated_values#Example) and wrap in double quotes
          result = result ? '"' + result.replace(/"/g, '""') + '"' : "";
        }

        row += result;
        row += ",";
      });
      csv.push(row.slice(0, -1)); // remove last comma
    }

    const headers = fields.map((field) => field.name);
    csv.unshift(headers.join(",")); // add header column
    // @ts-ignore
    csv = csv.join("\n");

    return csv;
  };

  const engagementReports = [
    {
      icon: UserFriends,
      report: reportTypes["user-engagement"],
    },
    {
      icon: Explore,
      report: reportTypes["content-engagement"],
    },
    {
      icon: Workshop,
      report: reportTypes["workshop-engagement"],
    },
  ];

  const summaryReports = [
    {
      icon: Assignment,
      report: reportTypes["assignment-summary"],
    },
    {
      icon: Explore,
      report: reportTypes["content-completion"],
    },
    {
      icon: Manager,
      report: reportTypes["manager-summary"],
    },
  ];

  const statusReports = [
    {
      icon: Track,
      report: reportTypes["track-status"],
    },
    {
      icon: Workshop,
      report: reportTypes["workshop-status"],
    },
    {
      icon: BarChart,
      report: reportTypes["assessment-status"],
    },
    {
      icon: Scorm,
      report: reportTypes["scorm-status"],
    },
  ];

  return {
    reportTypes,
    getCsv,
    engagementReports,
    summaryReports,
    statusReports,
  };
};
