import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMemo, useState } from "react";

import PreAuthService from "../services/api/PreAuthService";
import { useConfigStore } from "@/client/services/state/configStore";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

type Inputs = {
  newPassword: string;
  confirmNewPassword: string;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ResetPassword() {
  useDocumentTitle("Password Reset");
  const query = useQuery();
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const { setToast } = useToastStore();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const domain = config.host;
  const [mismatch, setMismatch] = useState<boolean>(false);
  const [successfulReset, setSuccessfulReset] = useState<boolean>(false);
  const newPasswordPlaceholder = t("global.forms.labels_newPassword");
  const confirmNewPasswordPlaceholder = t(
    "preauth.resetPassword.confirmNewPassword"
  );

  interface ResetPasswordVariables {
    domain: string;
    email: string | null;
    key: string | null;
    newPassword: string;
  }

  const resetPassword = useMutation({
    mutationFn: ({ domain, email, key, newPassword }: ResetPasswordVariables) =>
      PreAuthService.resetPassword(domain, email, key, newPassword),
    onSuccess: () => setSuccessfulReset(true),
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("preauth.resetpassword.link_not_valid"),
      }),
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) =>
    resetPassword.mutate({
      domain,
      email: query.get("email"),
      key: query.get("key"),
      newPassword: data.newPassword,
    });

  if (successfulReset)
    return (
      <VStack spacing={4}>
        <Text as="h4" margin={0}>
          {t("preauth.resetPassword.resetSuccessful")}
        </Text>

        <Text>
          {t("preauth.resetPassword.please")}{" "}
          <Link as={RouterLink} to="/login">
            {t("preauth.resetPassword.signIn")}
          </Link>
          {t("preauth.resetPassword.toContinu")}
        </Text>
      </VStack>
    );

  return (
    <Stack spacing={4} width="100%">
      <Text as="h4">{t("preauth.resetPassword.passwordRecovery")}</Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <FormControl isInvalid={!!errors.newPassword}>
            <Input
              id="newPassword"
              type="password"
              placeholder={newPasswordPlaceholder}
              variant="flushed"
              {...register("newPassword", { required: true })}
            />

            <FormErrorMessage>
              {errors.newPassword && "Password is required"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.confirmNewPassword}>
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder={confirmNewPasswordPlaceholder}
              variant="flushed"
              {...register("confirmNewPassword", {
                required: true,
                validate: (value) => {
                  if (watch("newPassword") !== value) {
                    setMismatch(true);
                    return "";
                  } else {
                    setMismatch(false);
                  }
                },
              })}
            />

            <FormErrorMessage>
              {errors.newPassword && "Password confirmation is required"}
            </FormErrorMessage>

            {mismatch && (
              <FormErrorMessage>
                {t("preauth.resetpassword.password_match_error")}
              </FormErrorMessage>
            )}
          </FormControl>

          <Button type="submit" isLoading={isSubmitting}>
            {t("preauth.resetPassword.resetPassword")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
