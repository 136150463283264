/* eslint react/no-unstable-nested-components: 0 */

import { Line } from "@nivo/line";
import { PointPicker } from "./points/PointPicker";
import { TooltipBothAxes } from "./TooltipBothAxes";
import AutoSizer from "react-virtualized-auto-sizer";
import { IntegerAxis, SkipTicksAxis } from "@/client/utils/charts/getAxes";
import { ChartWrapper } from "./ChartWrapper";
import { Range } from "react-calendar/dist/cjs/shared/types";

type LineChartProps = {
  data: any;
  colSpan: number;
  rowSpan: number;
  dateRange: string | Date | Range<any>;
  setDateRange: (value: any) => void;
  dateLocale: {
    format: string;
  };
  title: string;
  tooltip: string;
};

export function LineChart({
  data,
  colSpan,
  rowSpan,
  dateRange,
  setDateRange,
  dateLocale,
  title,
  tooltip,
}: LineChartProps) {
  return (
    <ChartWrapper
      title={title}
      tooltip={tooltip}
      showDatePicker
      dateRange={dateRange}
      setDateRange={setDateRange}
      colSpan={colSpan}
      rowSpan={rowSpan}
      dateLocale={dateLocale}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Line
            data={data}
            height={height}
            width={width}
            // margin={{ left: 40, bottom: 50, top: 10, right: 20 }}
            animate
            enableSlices="x"
            enableArea
            yScale={{
              type: "linear",
              stacked: true,
            }}
            curve="linear"
            colors={["#4AD3DC", "#FEA58A", "#EA91AD", "#F8C98D", "#BEA7EC"]}
            fill={[{ match: "*", id: "color" }]}
            pointSymbol={PointPicker}
            pointSize={6}
            pointColor="#fff"
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            areaOpacity={0.5}
            axisLeft={IntegerAxis}
            axisBottom={SkipTicksAxis(110, 10, data[0]?.data)}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -40,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 8,
                symbolShape: "circle",
                // symbolShape: (props: any) => (
                //   <g transform={`translate(${props.size / 2} ${props.size / 2})`}>
                //     <PointPickerFlat
                //       size={props.size}
                //       borderColor={props.borderColor}
                //       borderWidth={props.borderWidth}
                //       color={props.fill}
                //       symbol={data.find((d: any) => d.id === props.id).symbol}
                //     />
                //   </g>
                // ),
                toggleSerie: true,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
            sliceTooltip={TooltipBothAxes}
            theme={{
              axis: {
                domain: {
                  // line: { stroke: '#77777777', strokeWidth: 0.5 }
                },
                legend: {
                  text: { fill: "#999", fontSize: 10, fontWeight: "bold" },
                },
                ticks: {
                  line: { stroke: "#77777722", strokeWidth: 0.5 },
                  text: { fill: "#999", fontSize: 10 },
                },
              },
              grid: {
                line: {
                  stroke: "#77777722",
                  strokeWidth: 0.5,
                },
              },
              labels: {
                text: { fontSize: 10, fontWeight: "bold" },
              },
              legends: {
                text: { fontSize: 10, fontWeight: "bold" },
              },
            }}
          />
        )}
      </AutoSizer>
    </ChartWrapper>
  );
}
