import {
  Box,
  Flex,
  Icon,
  Input,
  Progress,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef } from "react";

import { AuthoringVideo } from "@/client/components/icons/ContinuIcons";
import { CloseIcon } from "@chakra-ui/icons";
import type { DropTargetMonitor } from "react-dnd";
import IconButtonWithTooltip from "@/client/components/buttons/IconButtonWithTooltip";
import { IoCloudUploadOutline } from "react-icons/io5";
import { NativeTypes } from "react-dnd-html5-backend";
import { bytesToSize } from "@/client/utils/bytesToSize";
import { useAuthorVideo } from "@/client/services/hooks/admin/authoring/videos/useAuthorVideo";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

const acceptedFileTypes = [
  "video/mp4",
  "video/x-m4v",
  "video/quicktime",
  "video/*",
];

export default function VideoDropzone() {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { watch, setValue } = useFormContext();
  const { uploadVideoMutation, uploadProgress } = useAuthorVideo(undefined);
  const { setUploadStatus } = useCreateStore();

  const { status } = uploadVideoMutation;

  useEffect(() => {
    setUploadStatus(status);
  }, [status]);

  const videoId = watch("id");
  const videoDetails = watch("videoDetails");
  const isTranscoding = watch("transcoding");

  const videoInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({
        show: true,
        status: "error",
        title: t("authoring.video.upload.error"),
      });
      return;
    }

    if (fileObject.size > 2048000000) {
      setToast({
        show: true,
        status: "error",
        title: t("edit.videos.1024_limit"),
      });

      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = "";

    setValue("videoDetails", {
      name: fileObject.name,
      size: fileObject.size,
    });

    uploadVideoMutation.mutateAsync(fileObject);
  };

  const handleUploadClick = () =>
    videoInputRef.current ? videoInputRef.current.click() : null;

  const handleFileDrop = useCallback((item: { files: any[] }) => {
    if (item) {
      const { files } = item;

      if (!acceptedFileTypes.includes(files[0].type)) {
        setToast({
          show: true,
          status: "error",
          title: t("authoring.video.upload.invalidFileType"),
        });

        return;
      }

      if (files[0].size > 2048000000) {
        setToast({
          show: true,
          status: "error",
          title: t("edit.videos.1024_limit"),
        });

        return;
      }

      setValue("videoDetails", {
        name: files[0].name,
        size: files[0].size,
      });

      uploadVideoMutation.mutateAsync(files[0]);
    }
  }, []);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleFileDrop(item);
      },
      canDrop() {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [handleFileDrop]
  );

  const isActive = canDrop && isOver;

  return (
    <>
      {!videoDetails && (
        <Flex
          ref={drop}
          justifyContent="center"
          border="2px dashed"
          borderColor={isActive ? "warmNeutral.300" : "warmNeutral.200"}
          backgroundColor={isActive ? "warmNeutral.200" : "transparent"}
          borderRadius="md"
          padding={12}
          textAlign="center"
        >
          <Input
            display="none"
            ref={videoInputRef}
            type="file"
            accept={acceptedFileTypes.join(",")}
            onChange={(e) => handleFileChange(e)}
          />

          <VStack>
            <Icon as={IoCloudUploadOutline} boxSize={8} />

            <Text fontWeight={600} fontSize="16px" color="baseBlack">
              {t("authoring.file.dragDrop")}
              <Text
                as="span"
                color="warmNeutral.700"
                textDecoration="underline"
                _hover={{ cursor: "pointer" }}
                onClick={handleUploadClick}
              >
                {" "}
                {t("authoring.file.browse")}
              </Text>{" "}
            </Text>

            <Text color="neutral.700" fontSize="14px" fontWeight={600}>
              {t("authoring.video.maxSize")}
            </Text>
          </VStack>
        </Flex>
      )}

      {(status === "loading" || status === "success" || status === "idle") &&
        videoDetails && (
          <Box
            backgroundColor="warmNeutral.100"
            borderRadius="md"
            border="1px solid"
            borderColor="warmNeutral.200"
            padding={6}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Stack flex={1}>
                <AuthoringVideo boxSize={8} />

                <Text variant="createLabel">{videoDetails.name}</Text>

                <Text variant="createHelpText">
                  {bytesToSize(videoDetails.size)}
                </Text>

                {status === "loading" && (
                  <>
                    <Text variant="createHelpText">
                      {t("authoring.scorm.uploading")}
                    </Text>

                    <Progress
                      variant="create"
                      value={uploadProgress}
                      width="full"
                    />
                  </>
                )}

                {isTranscoding && (
                  <Stack>
                    <Text variant="createHelpText">
                      {t("authoring.video.upload.processing")}
                    </Text>

                    <Text variant="createHelpText">
                      {t("authoring.video.upload.processing.helpText")}
                    </Text>
                  </Stack>
                )}
              </Stack>

              {videoId && !isTranscoding && (
                <IconButtonWithTooltip
                  tooltipCopy={t("authoring.video.upload.replaceVideo")}
                  icon={<Icon as={CloseIcon} color="warmNeutral.600" />}
                  ariaLabel={t("authoring.video.upload.replaceVideo")}
                  onClick={() => {
                    setValue("link", "");
                    setValue("description", "");
                    setValue("title", "");
                    setValue("textGenerationStatus", undefined);
                    setValue("videoDetails", null);
                  }}
                />
              )}
            </Flex>
          </Box>
        )}
    </>
  );
}
