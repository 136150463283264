import AnalyzeService from "../api/AnalyzeService";
import { differenceInSeconds } from "date-fns";

export const useSessionTracking = (
  contentId: string | undefined,
  contentType: string
) => {
  const startTime = new Date();

  const sendSessionTime = () => {
    const endTime = new Date();

    let timeElapsed = differenceInSeconds(endTime, startTime);
    if (timeElapsed === 0) {
      timeElapsed = 1;
    }

    AnalyzeService.sendContentSession(contentId, contentType, timeElapsed);
  };

  return { sendSessionTime };
};
