import { PersistOptions, persist } from "zustand/middleware";
import { create, StateCreator } from "zustand";

interface PeopleState {
  isListView: boolean;
  setIsListView: (isListView: boolean) => void;
}

type PeoplePersist = (
  config: StateCreator<PeopleState>,
  options: PersistOptions<PeopleState>
) => StateCreator<PeopleState>;

export const usePeopleStore = create<PeopleState>(
  (persist as PeoplePersist)(
    (set) => ({
      isListView: false,
      setIsListView: (isListView) => set({ isListView }),
    }),
    { name: "peopleStore" }
  )
);
