/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box,
  Collapse,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Tag,
  TagCloseButton,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";

import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateSwitchContainer from "../../../shared/layout/CreateSwitchContainer";
import NoSearchResultsListItem from "../../lists/NoSearchResultsListItem";
import { Search2 } from "@/client/components/icons/ContinuIcons";
import { useCombobox } from "downshift";
import { useDebounceValue } from "usehooks-ts";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function AssessmentGraderSearch() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<
    AssessmentFormData & ContentFormValues
  >();
  const [debouncedSearchTerm, setSearchTerm] = useDebounceValue("", 200);

  const enableAdditionalGraders = watch("enable_additional_graders");
  const selectedGraders = watch("assignedGraders") || [];

  const { isFetching, data } = useQuery({
    enabled: !!debouncedSearchTerm,
    queryKey: ["graders", debouncedSearchTerm],
    queryFn: () =>
      AdminUsersService.searchAdminUsers(debouncedSearchTerm, false),
    select: (selectData) => [
      {
        title: t("authoring.assessment.gradingSettings.additionalGraders"),
        options: selectData.map((grader) => ({
          id: grader._id,
          name: grader.full_name,
          image: grader.image,
        })),
      },
    ],
  });

  const flattenGroupOptions = (options: { title: string; options: any[] }[]) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        if (!inputValue) {
          setSearchTerm("");
          return;
        }

        setSearchTerm(inputValue);
      },
      items: data ? flattenGroupOptions(data) : [],
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }

        const alreadySelected = selectedGraders.some(
          (grader) => grader.id === selectedItem.id
        );

        if (alreadySelected) {
          // TODO: Fix this circular type dependency
          // @ts-ignore
          setValue(
            "assignedGraders",
            selectedGraders.filter((i) => i.id !== selectedItem.id)
          );

          return;
        }

        setValue("assignedGraders", [...selectedGraders, selectedItem]);
      },
      selectedItem: null,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
              highlightedIndex: state.highlightedIndex,
              inputValue: "", // don't add the item string as input value at selection.
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: "", // don't add the item string as input value at selection.
            };
          default:
            return changes;
        }
      },
    });
  return (
    <Box>
      <Stack>
        <CreateSwitchContainer
          name="enable_additional_graders"
          label={t(
            "authoring.assessment.gradingSettings.additionalGraders.label"
          )}
          helpText={t(
            "authoring.assessment.gradingSettings.additionalGraders.helpText"
          )}
        />

        <Collapse in={enableAdditionalGraders} animateOpacity>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search2 color="neutral.500" />
            </InputLeftElement>

            <Input
              variant="create"
              placeholder={`${t(
                "authoring.assessment.gradingSettings.additionalGraders.search.placeholder"
              )}`}
              {...getInputProps()}
            />

            <InputRightElement>
              {isFetching && <Spinner size="sm" color="warmNeutral.400" />}
            </InputRightElement>
          </InputGroup>

          <List
            marginTop={2}
            flexDirection="column"
            border={data ? "1px solid" : "none"}
            borderColor="warmNeutral.400"
            borderRadius="md"
            background="white"
            overflow="scroll"
            paddingX={6}
            zIndex={9999}
            maxH={400}
            width="inherit"
            {...getMenuProps()}
          >
            {data && data[0].options.length === 0 && (
              <NoSearchResultsListItem />
            )}

            {data &&
              data[0].options.length > 0 &&
              data.reduce(
                (results: any, section: any, sectionIndex: any) => {
                  results.sections.push(
                    <Box
                      key={`results_section_${sectionIndex + 1}`}
                      paddingY={4}
                      borderBottom="1px solid"
                      borderColor="warmNeutral.400"
                    >
                      {section.options.length > 0 && (
                        <Text variant="createLabel" marginY={2}>
                          {section.title}
                        </Text>
                      )}

                      {section.options.length > 0 &&
                        section.options.map((option: any, optionIndex: any) => {
                          // eslint-disable-next-line no-plusplus, no-param-reassign
                          const resultIndex = results.itemIndex++;

                          const isSelected = selectedGraders.some(
                            (grader) => grader.id === option.id
                          );

                          return (
                            <ListItem
                              key={`option_index_${optionIndex + 1}`}
                              paddingX={6}
                              paddingY={2}
                              _hover={{
                                cursor: "pointer",
                              }}
                              {...getItemProps({
                                item: option,
                                index: resultIndex,
                                "aria-selected": isSelected,
                              })}
                              backgroundColor={
                                highlightedIndex === resultIndex
                                  ? "warmNeutral.0"
                                  : "white"
                              }
                              border={
                                highlightedIndex === resultIndex
                                  ? "1px solid"
                                  : "none"
                              }
                              borderColor="warmNeutral.400"
                              borderRadius="6px"
                            >
                              <Flex
                                alignItems="center"
                                width="full"
                                justifyContent="space-between"
                              >
                                <Avatar
                                  size="sm"
                                  name={option.name}
                                  src={option.image}
                                  opacity={isSelected ? 0.4 : 1}
                                />

                                <Box
                                  flexGrow={1}
                                  textAlign="left"
                                  paddingLeft={4}
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight={500}
                                    color={
                                      isSelected
                                        ? "neutral.500"
                                        : "neutral.1500"
                                    }
                                  >
                                    {option.name}
                                  </Text>
                                </Box>

                                <Icon
                                  boxSize={8}
                                  as={isSelected ? CiCircleCheck : CiCirclePlus}
                                  color={
                                    isSelected
                                      ? "warmNeutral.400"
                                      : "neutral.500"
                                  }
                                />
                              </Flex>
                            </ListItem>
                          );
                        })}
                    </Box>
                  );

                  return results;
                },
                { sections: [], itemIndex: 0 }
              ).sections}
          </List>

          {selectedGraders.length > 0 && (
            <HStack flexWrap="wrap">
              {selectedGraders.map((grader) => (
                <Tag key={grader.id} variant="create">
                  <Avatar
                    size="xs"
                    name={grader.name}
                    src={grader.image || ""}
                    opacity={1}
                    mr={2}
                  />
                  {grader.name}

                  <TagCloseButton
                    onClick={() =>
                      setValue(
                        "assignedGraders",
                        selectedGraders.filter((i) => i !== grader)
                      )
                    }
                  />
                </Tag>
              ))}
            </HStack>
          )}
        </Collapse>
      </Stack>
    </Box>
  );
}
