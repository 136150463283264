import { Box } from "@chakra-ui/react";
import type { ReactNode } from "react";

interface CreateFormGroupProps {
  children: ReactNode;
  renderBorder?: boolean;
}

export default function CreateFormGroup({
  children,
  renderBorder = true,
}: CreateFormGroupProps) {
  return (
    <Box
      borderBottom={renderBorder ? "1px solid" : "none"}
      borderBottomColor="warmNeutral.200"
      paddingBottom={6}
    >
      {children}
    </Box>
  );
}
