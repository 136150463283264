import { Avatar, Flex, Link } from "@chakra-ui/react";

interface UserCellProps {
  name: string;
  image: string;
  id: string;
  isSuspended?: boolean;
}

export default function UserCell({
  name,
  image,
  id,
  isSuspended,
}: UserCellProps) {
  const href = isSuspended
    ? `/admin/users/suspended/${id}`
    : `/admin/users/edit/single/${id}`;

  return (
    <Link variant="adminPrimary" href={href}>
      <Flex alignItems="center">
        <Avatar
          marginRight={2}
          backgroundColor="brand.link"
          color="white"
          size="xs"
          src={image}
          name={name}
        />

        {name}
      </Flex>
    </Link>
  );
}

UserCell.defaultProps = {
  isSuspended: false,
};
