/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Switch,
} from "@chakra-ui/react";

interface SwitchListProps {
  allChecked?: boolean;
  enableSelectAll?: boolean;
  options: { name: string; label: string; enabled: boolean }[];
}

export default function SwitchList({
  allChecked,
  enableSelectAll,
  options,
}: SwitchListProps) {
  const { control } = useFormContext();
  const optionsMap = enableSelectAll ? options.slice(1) : options;

  return (
    <>
      {enableSelectAll && (
        <Controller
          name={options[0].name}
          control={control}
          render={({ field }) => (
            <FormControl
              {...field}
              display="flex"
              alignItems="center"
              marginBottom={4}
            >
              <FormLabel htmlFor={options[0].name} fontSize="sm" mb="0">
                {options[0].label}
              </FormLabel>

              <Switch isChecked={field.value} />
            </FormControl>
          )}
        />
      )}

      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        {optionsMap.map((option) => {
          if (!option.enabled) return;

          return (
            <GridItem key={option.name}>
              <Controller
                name={option.name}
                control={control}
                render={({ field }) => (
                  <FormControl {...field} display="flex" alignItems="center">
                    <FormLabel
                      htmlFor={option.name}
                      mb="0"
                      fontSize="sm"
                      minWidth="50%"
                    >
                      {option.label}
                    </FormLabel>

                    <Switch
                      isDisabled={allChecked}
                      isChecked={allChecked || field.value}
                    />
                  </FormControl>
                )}
              />
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
}

SwitchList.defaultProps = {
  allChecked: false,
  enableSelectAll: true,
};
