import { DynamicMessage } from "@/client/types/admin/assignments/DynamicMessage";
import { learnApiClient } from "@/client/services/api/clients/learnApiClient";
import { v3ApiClient } from "@/client/services/api/clients/v3ApiClient";

const getCompanyIntegrations = async () => {
  const response = await v3ApiClient.get("companyintegrations");

  return response.data;
};

const updateNotification = async (
  notificationId: string | undefined,
  payload: DynamicMessage
) => {
  const response = await learnApiClient.post(
    `dynamic-messages/${notificationId}`,
    payload
  );

  return response.data;
};

const AssignmentNotificationsService = {
  getCompanyIntegrations,
  updateNotification,
};

export default AssignmentNotificationsService;
