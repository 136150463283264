import type {
  FileDraftData,
  FileSubmissionData,
} from "@/client/types/admin/content-authoring/files/FileSubmissionData";

import AnalyzeService from "../../AnalyzeService";
import type { RESTFileRetreivalData } from "@/client/types/admin/content-authoring/files/RESTFileRetreivalData";
import type { S3Policy } from "@/client/types/aws/s3Policy";
import axios from "axios";
import { learnApiClient } from "../../clients/learnApiClient";
import v3ApiService from "../../clients/v3ApiClient";

const saveInitialDraft = async (
  payload: FileDraftData
): Promise<RESTFileRetreivalData> => {
  const response = await learnApiClient.post("media/files", payload);

  return response.data;
};

const uploadFile = async (
  file: File,
  progressCallback: (percentCompleted: number) => void
) => {
  const response = await v3ApiService
    .getS3FilePolicy(file.type, file.name)
    .then(async (policy: S3Policy) => {
      const {
        S3Policy,
        S3Signature,
        CloudFrontLink,
        Bucket,
        Key,
        ContentType,
        AWSAccessKeyId,
      } = policy;

      const formData = new FormData();

      formData.append("key", Key);
      formData.append("Content-Type", ContentType);
      formData.append("AWSAccessKeyId", AWSAccessKeyId);
      formData.append("success_action_status", "201");
      formData.append("policy", S3Policy);
      formData.append("signature", S3Signature);
      formData.append("file", file);

      await axios.post(`https://${Bucket}.s3.amazonaws.com/`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          progressCallback(percentCompleted);
        },
      });

      return { cloudFrontLink: CloudFrontLink };
    });

  return response;
};

const submitFile = async (
  payload: FileSubmissionData
): Promise<RESTFileRetreivalData> => {
  const response = await learnApiClient.post(`media/${payload._id}`, payload, {
    params: {
      fields:
        "createdAt,updatedAt,title,description,type,author,duration,tags,images,link,source,source_id,video_type,type,user,linked_category,explore_hide,approval_required,approved,last_approved_by,last_approved_by,privacy_collaborators,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,privacy_groups,private,downloadable,downloadable,cover_justification,featured_justification,completion_percent,embed,embed_settings,rating_configuration,rating_configuration,allow_comments,surveys,draft",
    },
  });

  await AnalyzeService.recordContentModifications({
    document: payload._id,
    type: "file",
  });

  return response.data;
};

const boxSource = async (
  link: string
): Promise<{
  source_id: string;
  source: string;
  source_id_v2: string;
  source_v2: string;
}> => {
  const response = await learnApiClient.post("media/source", { link });

  return response.data;
};

const AuthoringFileService = {
  uploadFile,
  saveInitialDraft,
  submitFile,
  boxSource,
};

export default AuthoringFileService;
