import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import { WarningTriangle } from "../../icons/ContinuIcons";
import { useAdminStore } from "@/client/services/state/admin/adminStore";
import { useTranslation } from "react-i18next";

interface DeleteItemModalProps {
  deleteItem: any;
  subType?: string;
  subSubType?: string;
}

export default function DeleteItemModal({
  deleteItem,
  subType = undefined,
  subSubType = undefined,
}: DeleteItemModalProps) {
  const { t } = useTranslation();
  const { deleteModal, setDeleteModal } = useAdminStore();

  const setTypeTitle = (category: any | null) => {
    if (category) {
      if (category.category) return "Category";
      if (category.location) return "Location";
      if (category.department) return "Department";
      if (category.team) return "Team";
      if (category.org_level) return "Level";
      if (category.grade) return "Grade";
    }

    return "Item";
  };

  const onClose = () => setDeleteModal({ isOpen: false, selectedItem: null });

  const handleDelete = () =>
    deleteItem.mutateAsync(deleteModal.selectedItem).then(() => onClose());

  return (
    <Modal size="xl" isOpen={deleteModal.isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <WarningTriangle color="brand.warning" marginRight={4} />
          {t("admin.removingThis", {
            type: setTypeTitle(deleteModal.selectedItem),
          })}
        </ModalHeader>

        <ModalBody>
          <Stack spacing={4}>
            {subType && subSubType && (
              <Text as="p">
                {t("admin.connect.deleteSubAndSubSubItems", {
                  subType,
                  subSubType,
                })}
              </Text>
            )}

            <Text as="p">{t("admin.proceed")}</Text>
          </Stack>
        </ModalBody>

        <ModalFooter width="full" display="flex" justifyContent="space-between">
          <Button variant="adminCancel" mr={3} onClick={onClose}>
            {t("global.actions.cancel")}
          </Button>

          <Button variant="adminError" onClick={handleDelete}>
            {t("global.actions.delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
