import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface EditSectionButtonProps {
  onEdit: () => void;
}

export default function EditSectionButton({ onEdit }: EditSectionButtonProps) {
  const { t } = useTranslation();

  return (
    <Button variant="createGoldOutline" onClick={onEdit}>
      {t("authoring.publishPreview.editSection")}
    </Button>
  );
}
