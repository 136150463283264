/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Circle,
  Collapse,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Tag,
  TagCloseButton,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";

import type { Content } from "@/client/services/api/graphql/gql/graphql";
import type { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "@/client/components/admin/create/shared/layout/CreateSwitchContainer";
import NoSearchResultsListItem from "@/client/components/admin/create/content/lists/NoSearchResultsListItem";
import { RiSurveyLine } from "react-icons/ri";
import { useAuthoringSurveySearch } from "@/client/services/hooks/admin/authoring/useAuthoringSurveySearch";
import { useCombobox } from "downshift";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

// TODO: Refactor this combobox for use across all authoring searches

export default function AuthoringSurveySearch() {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<Content & ContentFormValues>();
  const { searchTerm, setSearchTerm, isFetching, data } =
    useAuthoringSurveySearch();

  const enableSurveys = watch("enable_surveys");
  const surveys = watch("surveys") || [];

  const selectedSurvey = surveys.length > 0 ? surveys[0] : null;

  const flattenGroupOptions = (options: { title: string; options: any[] }[]) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        setSearchTerm(inputValue);
      },
      items: data ? flattenGroupOptions(data) : [],
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }

        if (selectedSurvey?.id === selectedItem.id) {
          setValue("surveys", []);

          return;
        }

        setValue("surveys", [selectedItem]);
      },
      selectedItem: null,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
              highlightedIndex: state.highlightedIndex,
              inputValue: "", // don't add the item string as input value at selection.
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: "", // don't add the item string as input value at selection.
            };
          default:
            return changes;
        }
      },
    });

  return (
    <CreateFormGroup>
      <Stack>
        <CreateSwitchContainer
          name="enable_surveys"
          label={t("authoring.settings.enableSurveys")}
          helpText={t("authoring.settings.enableSurveys.helpText")}
        />

        <Collapse in={enableSurveys} animateOpacity>
          <InputGroup>
            <Input
              variant="create"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={`${t("authoring.surveys.searchSurveys")}`}
              {...getInputProps()}
            />

            <InputRightElement>
              {isFetching && <Spinner size="sm" />}
            </InputRightElement>
          </InputGroup>

          <List
            marginTop={2}
            flexDirection="column"
            border={data ? "1px solid" : "none"}
            borderColor="brand.gold.100"
            borderRadius="md"
            background="white"
            overflow="scroll"
            paddingX={6}
            zIndex={9999}
            maxH={400}
            width="inherit"
            {...getMenuProps()}
          >
            {data && data[0].options.length === 0 && (
              <NoSearchResultsListItem />
            )}

            {data &&
              data[0].options.length > 0 &&
              data.reduce(
                (results: any, section: any, sectionIndex: any) => {
                  results.sections.push(
                    <Box
                      key={`results_section_${sectionIndex + 1}`}
                      paddingY={4}
                      borderBottom="1px solid"
                      borderColor="brand.gold.100"
                    >
                      {section.options.length > 0 && (
                        <Text variant="createLabel" marginY={2}>
                          {section.title}
                        </Text>
                      )}

                      {section.options.length > 0 &&
                        section.options.map((option: any, optionIndex: any) => {
                          // eslint-disable-next-line no-plusplus, no-param-reassign
                          const resultIndex = results.itemIndex++;

                          const isSelected = selectedSurvey?.id === option.id;

                          return (
                            <ListItem
                              key={`option_index_${optionIndex + 1}`}
                              paddingX={6}
                              paddingY={2}
                              _hover={{
                                cursor: "pointer",
                              }}
                              {...getItemProps({
                                item: option,
                                index: resultIndex,
                                "aria-selected": isSelected,
                              })}
                              backgroundColor={
                                highlightedIndex === resultIndex
                                  ? "warmNeutral.0"
                                  : "white"
                              }
                              border={
                                highlightedIndex === resultIndex
                                  ? "1px solid"
                                  : "none"
                              }
                              borderColor="warmNeutral.400"
                              borderRadius="6px"
                            >
                              <Flex
                                alignItems="center"
                                width="full"
                                justifyContent="space-between"
                              >
                                <Circle
                                  backgroundColor={
                                    isSelected ? "gray.50" : "gray.100"
                                  }
                                  size="30px"
                                >
                                  <Icon
                                    color={
                                      isSelected
                                        ? "brand.legibleBlack.50"
                                        : "brand.legibleBlack.100"
                                    }
                                    as={RiSurveyLine}
                                  />
                                </Circle>

                                <Box
                                  flexGrow={1}
                                  textAlign="left"
                                  paddingLeft={4}
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight={500}
                                    color={
                                      isSelected
                                        ? "brand.legibleBlack.50"
                                        : "brand.legibleBlack.100"
                                    }
                                  >
                                    {option.title}
                                  </Text>
                                </Box>

                                <Icon
                                  boxSize={8}
                                  as={isSelected ? CiCircleCheck : CiCirclePlus}
                                  color={
                                    isSelected
                                      ? "brand.gold.100"
                                      : "brand.grey.40"
                                  }
                                />
                              </Flex>
                            </ListItem>
                          );
                        })}
                    </Box>
                  );

                  return results;
                },
                { sections: [], itemIndex: 0 }
              ).sections}
          </List>

          {selectedSurvey && (
            <Tag variant="create" width="fit-content">
              <TagLeftIcon as={RiSurveyLine} />

              {selectedSurvey.title}

              <TagCloseButton
                onClick={() => {
                  setValue("surveys", []);
                }}
              />
            </Tag>
          )}
        </Collapse>
      </Stack>
    </CreateFormGroup>
  );
}
