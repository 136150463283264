import type { Content } from "@/client/types/admin/content-authoring/tracks/imported-content/MasterclassSearchData";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEditInstructors } from "../useEditInstructors";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useAuthorMasterclass = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setValue, watch } = useFormContext();
  const { setTabIndex } = useCreateStore();
  const { createInstructorMutation } = useEditInstructors();

  const tags = watch("tags");

  const processMasterclassImport = async (item: Content) => {
    try {
      const link = item.content_page;

      if (!link) {
        setToast({
          show: true,
          status: "error",
          title: t("authoring.courses.import.error"),
        });

        return;
      }

      if (!id) setValue("creator", user._id);

      setValue("vendor", "masterclass");

      setValue("vendorLink", link);

      setValue("vendorFullLink", link);

      setValue("vendorContentId", item.id);

      setValue("title", item.title);

      setValue("description", item.summary);

      if (item.skills && item.skills.length) {
        item.skills.forEach((skill) => {
          setValue("tags", [...tags, skill.name]);
        });
      }
      if (item.instructors && item.instructors.length) {
        await createInstructorMutation
          .mutateAsync({
            name: item.instructors[0].name,
            bio: "",
            reusable: false,
          })
          .then((instructorId) => {
            setValue("instructors", [
              {
                id: instructorId,
                name: item.instructors[0].name,
              },
            ]);
          });
      }

      setValue("image", item.image);

      setValue("bannerImage", item.image);

      setValue("duration", item.duration_in_seconds);

      setToast({
        show: true,
        status: "success",
        title: t("authoring.courses.import.success"),
      });

      setTabIndex(1);
    } catch (error) {
      console.log("error", error);

      setToast({
        show: true,
        status: "error",
        title: t("authoring.courses.import.error"),
      });
    }
  };

  return {
    processMasterclassImport,
  };
};
