/* eslint react/jsx-props-no-spreading: 0 */

import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useListProducts } from "@/client/services/hooks/content/products/useListProducts";
import {
  OrderingDirection,
  Product,
} from "@/client/services/api/graphql/gql/graphql";
import { PaginationState } from "@tanstack/react-table";
import ProductCard from "@/client/components/data-display/cards/ProductCard";
import { useAuthStore } from "@/client/services/state/authStore";
import { useQuery, useMutation } from "@tanstack/react-query";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import EcommerceService from "@/client/services/api/graphql/EcommerceService";
import { useToastStore } from "@/client/services/state/toastStore";
import OutlineButton from "@/client/components/shared/buttons/OutlineButton";
import SolidButton from "@/client/components/shared/buttons/SolidButton";
import ChangeSubscriptionSummary from "./ChangeSubscriptionSummary";

type Pages = "change" | "finalize";

export default function ChangeSubscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { id: originalSubscriptionId, newProductId } = useParams();
  const [searchParams] = useSearchParams();
  const setupIntentId = searchParams.get("setupIntentId");
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [page, setPage] = useState<Pages>(newProductId ? "finalize" : "change");

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: originalSubscriptionData } = useQuery({
    queryKey: ["currently-active-purchase", user._id, originalSubscriptionId],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { in: [user._id] },
        id: { in: [originalSubscriptionId!] },
        subscriptionActive: true,
        page: 0,
        pageSize: 10,
        includeTotalCount: true,
      }),
  });

  const { data: newProduct } = useQuery({
    queryKey: ["newly-selected-product", newProductId],
    queryFn: () => ProductsService.getProduct(newProductId),
    enabled: !!newProductId,
  });

  const { data: newPaymentData } = useQuery({
    queryKey: ["new-card-data", setupIntentId],
    queryFn: () =>
      EcommerceService.getPaymentMethodDataWithSetupIntentId(setupIntentId!),
    enabled: !!setupIntentId,
  });

  useEffect(() => {
    if (newProduct) {
      setSelectedProduct(newProduct);
    }
  }, [newProduct]);

  const getAlternativeProductsAllowed = () =>
    originalSubscriptionData?.purchases[0]?.productDetails.allowRelatedProducts;

  const getAlternativeProducts = () => {
    if (!getAlternativeProductsAllowed()) {
      return [];
    }
    if (
      originalSubscriptionData?.purchases[0].productDetails.relatedProducts
        .length === 0
    ) {
      return null;
    }
    return originalSubscriptionData?.purchases[0].productDetails
      .relatedProducts;
  };

  const createSubscription = useMutation({
    mutationFn: async () =>
      EcommerceService.createSubscription(
        selectedProduct?.id!,
        setupIntentId || undefined
      ),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("ecommerce.subscriptionManagement.changeSubscriptionSuccess"),
      });
      navigate(`/explore`);
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: t("ecommerce.subscriptionManagement.changeSubscriptionError"),
      });
      navigate(`/dashboard`);
    },
  });

  const changeSubscriptionToBaseTier = useMutation({
    mutationFn: async (productId: string) =>
      EcommerceService.cancelSubscription(productId),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t(
          "ecommerce.subscriptionManagement.changeSubscriptionToBaseTierSuccess"
        ),
      });
      navigate(`/explore`);
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: t("ecommerce.subscriptionManagement.changeSubscriptionError"),
      });
      navigate(`/dashboard`);
    },
  });

  const handleSubscriptionChange = () => {
    if (selectedProduct?.default) {
      changeSubscriptionToBaseTier.mutate(
        originalSubscriptionData?.purchases[0].product!
      );
      return;
    }
    createSubscription.mutate();
  };

  // TODO: List current subscription first always?
  const { data } = useListProducts({
    page: pageIndex,
    pageSize,
    includeTotalCount: true,
    archived: { eq: false },
    id: { in: getAlternativeProducts() },
    ordering: [{ field: "createdAt", direction: OrderingDirection.Desc }],
  });

  return (
    <Box margin={12}>
      {page === "change" && (
        <>
          <OutlineButton
            onClick={() =>
              navigate(`/manage-subscription/${originalSubscriptionId}`)
            }
          >
            {t("ecommerce.subscriptionManagement.headline")}
          </OutlineButton>
          <Stack spacing={8} paddingX={20} paddingY={12}>
            <Text fontSize="xl" fontWeight="600">
              {t("ecommerce.subscriptionManagement.changeSubscription")}
            </Text>

            {data?.products?.map((product: Product) => {
              const isSelected =
                originalSubscriptionData?.purchases[0].productDetails.id ===
                product.id;
              return (
                <ProductCard
                  product={{
                    title: product.title,
                    description: product.description,
                    price: product.price,
                    subscription_duration: product.subscriptionDuration,
                    _id: product.id,
                    images: product.images,
                  }}
                  managementButton={
                    <SolidButton
                      onClick={() => {
                        setSelectedProduct(product);
                        setPage("finalize");
                      }}
                      width={200}
                      isDisabled={isSelected}
                    >
                      {isSelected
                        ? t(
                            "ecommerce.subscriptionManagement.currentSubscription"
                          )
                        : t(
                            "ecommerce.subscriptionManagement.changeSubscription"
                          )}
                    </SolidButton>
                  }
                />
              );
            })}
            {/* TODO: Add pagination after discussing with product */}
            <Flex justifyContent="space-between">
              <Button
                variant="adminBrandPrimary"
                borderRadius={6}
                isDisabled={!pageIndex}
                onClick={() =>
                  setPagination({
                    pageIndex: pageIndex - 1,
                    pageSize,
                  })
                }
              >
                {t("authoring.previous")}
              </Button>
              <Button
                variant="adminBrandPrimary"
                borderRadius={6}
                // isDisabled={isDisabled}
                onClick={() =>
                  setPagination({
                    pageIndex: pageIndex + 1,
                    pageSize,
                  })
                }
              >
                {t("authoring.next")}
              </Button>
            </Flex>
          </Stack>
        </>
      )}
      {page === "finalize" && (
        <>
          <OutlineButton onClick={() => setPage("change")}>
            {t("ecommerce.subscriptionManagement.changeSubscription")}
          </OutlineButton>
          {originalSubscriptionData?.purchases.length && selectedProduct && (
            <ChangeSubscriptionSummary
              originalSubscription={originalSubscriptionData?.purchases[0]}
              selectedProduct={selectedProduct}
              handleSubscriptionChange={handleSubscriptionChange}
              newPaymentData={newPaymentData}
            />
          )}
        </>
      )}
    </Box>
  );
}
