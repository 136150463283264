/* eslint-disable no-param-reassign */

import { Category } from "@/client/types/Category";

export const categoryLegacyName = (category: Category) => {
  if (!category.parent) {
    category.legacyName = category.name;
  }

  if (category.parent && category.parent.parent) {
    category.legacyName =
      category.parent.parent.name +
      " -> " +
      category.parent.name +
      " -> " +
      category.name;
  }

  if (category.parent && !category.parent.parent) {
    category.legacyName = category.parent.name + " -> " + category.name;
  }
};
