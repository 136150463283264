/* eslint-disable react/jsx-props-no-spreading */

import {
  ArticleExtras,
  Content,
  ContentTypes,
  PartnerPermissionTypes,
} from "@/client/services/api/graphql/gql/graphql";
import { FormProvider, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";

import AuthoringLivePreview from "@/client/components/admin/create/content/AuthoringLivePreview";
import type { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateLayout from "@/client/components/admin/create/shared/layout/CreateLayout";
import EditArticleForm from "@/client/components/admin/create/content/forms/articles/EditArticleForm";
import TrackStateAlert from "@/client/components/admin/create/content/overlay/TrackStateAlert";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export type ArticleFormData = Content & ArticleExtras & ContentFormValues;

const articleDefaultValues: ArticleFormData = {
  id: "",
  company: "",
  author: "",
  title: "",
  creator: "",
  contentType: ContentTypes.Article,
  archived: false,
  private: false,
  enable_collaborators: false,
  privacyCollaborators: [],
  privacyLocations: [],
  privacyDepartments: [],
  privacyTeams: [],
  privacyOrgLevels: [],
  privacyGrades: [],
  privacyGroups: [],
  linkedCategory: [],
  image: "",
  bannerImage: "",
  description: "",
  exploreHide: true,
  duration: 0,
  approvalRequired: false,
  approved: false,
  tags: [],
  ratingConfiguration: {
    messaging: {
      individuals: [],
      slackChannels: [],
    },
    allowRating: false,
    allowFeedback: false,
    allowEdits: false,
    notifyFeedbackOnly: false,
    showUsersRating: false,
    totalValue: 5,
  },
  allowComments: false,
  enable_surveys: false,
  surveys: [],
  partnerPermissions: PartnerPermissionTypes.Tenant,
  enable_segmentation: false,
};

export default function EditArticle() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();
  useDocumentTitle(t("authoring.documentTitle.editArticle"));

  const allowance = {
    create_article: isAllowed(
      ["admin", "content", "all_create"],
      [["admin", "content", "articles_create"]],
      ["admin", "creator", "collaborator"]
    ),
    edit_article: isAllowed(
      ["admin", "content", "all_edit"],
      [
        ["admin", "content", "articles_edit"],
        ["admin", "content", "my_content_edit"],
      ],
      ["admin", "creator", "collaborator"]
    ),
  };

  if ((id && !allowance.edit_article) || (!id && !allowance.create_article)) {
    return <Navigate to="/explore" replace />;
  }

  const methods = useForm<ArticleFormData>({
    defaultValues: articleDefaultValues,
    mode: "onBlur",
  });

  const { watch, formState, clearErrors } = methods;
  const title = watch("title");
  const author = watch("author");
  const description = watch("description");
  const { errors } = formState;

  useEffect(() => {
    if (errors.title && title !== "") clearErrors("title");

    if (errors.author && author !== "") clearErrors("author");

    if (errors.description && description !== "") clearErrors("description");
  }, [title, author, description]);

  return (
    <FormProvider {...methods}>
      <form>
        <CreateLayout
          previewElement={
            <AuthoringLivePreview
              contentType="article"
              titlePlaceholder={t("authoring.article.titlePlaceholder")}
            />
          }
          formElement={<EditArticleForm />}
        />
      </form>

      <TrackStateAlert contentType="article" />
    </FormProvider>
  );
}
