import { Alert, AlertIcon, AlertTitle, Center, Link } from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

interface ErrorAlertProps {
  title: string;
  backAction?: {
    to: string;
    label: string;
    isAngular?: boolean;
  };
}

export default function ErrorAlert({ title, backAction }: ErrorAlertProps) {
  return (
    <Center>
      <Alert status="error" maxWidth="container.sm" marginTop={8}>
        <AlertIcon />

        <AlertTitle mr={2}>{title}</AlertTitle>

        {backAction &&
          (backAction.isAngular ? (
            <Link href={backAction.to}>{backAction.label}</Link>
          ) : (
            <Link as={RouterLink} to={backAction.to}>
              {backAction.label}
            </Link>
          ))}

        {!backAction && (
          <Link as={RouterLink} to="/explore">
            Back to explore
          </Link>
        )}
      </Alert>
    </Center>
  );
}

ErrorAlert.defaultProps = {
  backAction: null,
};
