import { Flex, Select, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import AdminCategoryService from "@/client/services/api/AdminCategoryService";
import { Category } from "@/client/types/Category";
import { QuestionCircle } from "@/client/components/icons/ContinuIcons";
import { useQuery } from "@tanstack/react-query";

type SegmentationFormFieldProps = {
  helpLabel: string;
  fieldLabel: string;
  type: string;
  initialId: string;
  updateCategory: (category: string, categoryId: string) => void;
};

type SegmentationFormFieldLevelProps = {
  fieldLabel: string;
  level: number;
  initialCategory: Category | null;
  previousLevelCategory: Category | null;
  categoryChoices: Category[];
  isLoadingCategories: boolean;
  updateSelectedData: (value: Category | null, level: number) => void;
};

function shouldFetchSegmentation(
  level: number,
  previousLevelCategory: Category | null
) {
  return (
    level === 1 ||
    (!!previousLevelCategory && previousLevelCategory?.children?.length > 0)
  );
}

function SegmentationFormFieldLevel({
  fieldLabel,
  level,
  initialCategory,
  previousLevelCategory,
  categoryChoices,
  isLoadingCategories,
  updateSelectedData,
}: SegmentationFormFieldLevelProps) {
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    initialCategory
  );

  const selectCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      setSelectedCategory(null);
      updateSelectedData(null, level);
      return;
    }
    const foundCategory = categoryChoices.find(
      (category) => category._id === event.target.value
    );
    if (foundCategory) {
      setSelectedCategory(foundCategory);
      updateSelectedData(foundCategory, level);
    }
  };

  if (
    level > 1 &&
    (!previousLevelCategory ||
      !previousLevelCategory.children ||
      previousLevelCategory.children.length === 0)
  ) {
    return <div style={{ flex: 0.33 }} />;
  }

  if (isLoadingCategories) {
    return (
      <Select flex="0.33" disabled>
        <option value="">Loading...</option>
      </Select>
    );
  }

  return (
    <Select
      flex="0.33"
      placeholder={`Select a ${fieldLabel} or leave blank`}
      onChange={selectCategory}
      value={selectedCategory?._id}
    >
      {categoryChoices &&
        categoryChoices.map((segment) => (
          <option key={"option_" + segment._id} value={segment._id}>
            {segment.name}
          </option>
        ))}
    </Select>
  );
}

export default function SegmentationFormField({
  helpLabel,
  fieldLabel,
  type,
  initialId: defaultId,
  updateCategory,
}: SegmentationFormFieldProps) {
  const defaultHolder = useRef<string>(defaultId);
  const stateInitializedCorrectly = useRef<boolean>(!defaultId);

  const { isLoading, data: categoryAncestry } = useQuery({
    queryKey: ["category-ancestry", defaultHolder.current],
    queryFn: async () => {
      const categoryIds = await AdminCategoryService.getCategoryAncestry(
        defaultHolder.current
      );
      return categoryIds;
    },

    enabled: !!defaultHolder.current,
  });

  const makeSelectedCategoryStateFromAncestryIfAvailable = () => {
    const newSelectedData: (Category | null)[] = [null, null, null];
    if (categoryAncestry) {
      for (let i = 0; i < categoryAncestry.length; i += 1) {
        newSelectedData[i] = categoryAncestry[i];
      }
    }
    return newSelectedData;
  };

  // TODO: Cheating on depth control but this simplified the updateSelectedData logic because
  //   if selectedData[1] doesn't exist in the array, it doesn't force a re-render of the third dropdown.
  //   The select box would stay visible which we don't want if we're picking a new first level category.
  const [selectedData, setSelectedData] = useState<(Category | null)[]>(
    makeSelectedCategoryStateFromAncestryIfAvailable()
  );

  // Set up the initial category ancestry.
  useEffect(() => {
    if (categoryAncestry) {
      setSelectedData(makeSelectedCategoryStateFromAncestryIfAvailable());
      stateInitializedCorrectly.current = true;
    }
  }, [categoryAncestry]);

  // TODO: We need to refactor these components to actually search instead of front loading data
  // TODO Also cheating on depth and recursion here by not having this live in the component, but it simplifies
  // the component selection flow significantly (hydration isn't going up and down the tree).
  const { data: categoriesForLevel1, isLoading: isLoadingLevel1 } = useQuery({
    queryKey: [`registration-forms-segmentation-${type}-1`, 1, type],
    queryFn: () =>
      AdminCategoryService.getCategoriesByManagerSearch(type, 1, 1, 1000, ""),
    enabled: shouldFetchSegmentation(1, null),
  });

  // TODO: We need to refactor these components to actually search instead of front loading data
  const { data: categoriesForLevel2, isLoading: isLoadingLevel2 } = useQuery({
    queryKey: [
      `registration-forms-segmentation-${type}-2`,
      2,
      selectedData[0]?._id,
      type,
    ],
    queryFn: () =>
      AdminCategoryService.getCategoriesByManagerSearch(
        type,
        2,
        1,
        1000,
        "",
        selectedData[0]?._id
      ),
    enabled: shouldFetchSegmentation(2, selectedData[0]),
  });

  // TODO: We need to refactor these components to actually search instead of front loading data
  const { data: categoriesForLevel3, isLoading: isLoadingLevel3 } = useQuery({
    queryKey: [
      `registration-forms-segmentation-${type}-3`,
      3,
      selectedData[1]?._id,
      type,
    ],
    queryFn: () =>
      AdminCategoryService.getCategoriesByManagerSearch(
        type,
        3,
        1,
        1000,
        "",
        selectedData[1]?._id
      ),
    enabled: shouldFetchSegmentation(3, selectedData[1]),
  });

  const updateSelectedData = (value: Category | null, level: number) => {
    const newSelectedData = selectedData.slice(0);
    newSelectedData[level - 1] = value;
    for (let i = level; i < 3; i += 1) {
      newSelectedData[i] = null;
    }
    setSelectedData(newSelectedData);

    const lastRealCategory = newSelectedData
      .filter((category) => category !== null)
      .slice(-1)[0];
    if (lastRealCategory) {
      updateCategory(type, lastRealCategory._id);
    } else {
      updateCategory(type, "");
    }
  };

  // Don't block this if we don't have anything loading.
  if (
    !stateInitializedCorrectly.current ||
    (!!defaultHolder.current && isLoading) ||
    isLoadingLevel1
  ) {
    return (
      <Select flex="0.33" disabled>
        <option value="">Loadinggggggg...</option>
      </Select>
    );
  }

  const categoriesForLevel1Sorted =
    categoriesForLevel1?.data?.sort((a, b) => a.name.localeCompare(b.name)) ??
    [];
  const categoriesForLevel2Sorted =
    categoriesForLevel2?.data?.sort((a, b) => a.name.localeCompare(b.name)) ??
    [];
  const categoriesForLevel3Sorted =
    categoriesForLevel3?.data?.sort((a, b) => a.name.localeCompare(b.name)) ??
    [];

  return (
    <>
      <Text mb={1} fontSize="sm" fontWeight="500">
        {fieldLabel}
        <Tooltip hasArrow label={helpLabel}>
          <QuestionCircle color="#1A1A1A80" marginLeft={1} />
        </Tooltip>
      </Text>
      <Flex direction="row" justifyContent="space-between" gap={4} mb={4}>
        <SegmentationFormFieldLevel
          key={type + "1"}
          fieldLabel={fieldLabel}
          level={1}
          initialCategory={selectedData[0]}
          previousLevelCategory={null}
          categoryChoices={categoriesForLevel1Sorted ?? []}
          isLoadingCategories={isLoadingLevel1}
          updateSelectedData={updateSelectedData}
        />
        <SegmentationFormFieldLevel
          key={type + "2"}
          fieldLabel={fieldLabel}
          level={2}
          initialCategory={selectedData[1]}
          previousLevelCategory={selectedData[0]}
          categoryChoices={categoriesForLevel2Sorted ?? []}
          isLoadingCategories={isLoadingLevel2}
          updateSelectedData={updateSelectedData}
        />
        {/* <div style={{ flex: '0.25' }}>:{JSON.stringify(selectedData[1], null, 4)}:</div> */}
        <SegmentationFormFieldLevel
          key={type + "3"}
          fieldLabel={fieldLabel}
          level={3}
          initialCategory={selectedData[2]}
          previousLevelCategory={selectedData[1]}
          categoryChoices={categoriesForLevel3Sorted ?? []}
          isLoadingCategories={isLoadingLevel3}
          updateSelectedData={updateSelectedData}
        />
      </Flex>
    </>
  );
}
