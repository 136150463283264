import {
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useTranslation } from 'react-i18next';

interface NoImageModalProps {
  onProceed: () => void;
}

export default function NoImageModal({ onProceed }: NoImageModalProps) {
  const { t } = useTranslation();
  const { noImageModalIsOpen, setNoImageModalIsOpen, imagesContainerRef } = useCreateStore();

  const isOpen = noImageModalIsOpen;

  const onClose = () => setNoImageModalIsOpen(false);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />

      <ModalContent>
        <ModalBody paddingTop={12}>
          <VStack textAlign="center">
            <Image src="/public/images/noImages.svg" width={120} height="auto" />

            <Text fontSize="20px" fontWeight={600} color="baseBlack">
              {t('authoring.noImages1')}
            </Text>

            <Text fontSize="14px" fontWeight={500} color="neutral.1000">
              {t('authoring.noImages2')}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
          <ButtonGroup width="full" alignItems="center" justifyContent="center">
            <OutlineButton
              onClick={() => {
                imagesContainerRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                });

                onClose();
              }}
            >
              {t('authoring.returnToEdit')}
            </OutlineButton>

            <SolidButton onClick={onProceed}>{t('authoring.continueToNextSection')}</SolidButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
