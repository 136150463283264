import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToken,
} from '@chakra-ui/react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';

import Loading from '@/client/components/media/Loading';
import axios from 'axios';
import { useAuthStore } from '@/client/services/state/authStore';
import { useLazyLoadStylesheet } from '@/client/services/hooks/useLazyLoad';
import { useState } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import v3ApiService from '@/client/services/api/clients/v3ApiClient';

// TODO: Remove Toast UI image editor from project

/* 
Valid keys: [
  "txt-primary",
  "txt-secondary",
  "txt-secondary-invert",
  "txt-placeholder",
  "txt-warning",
  "txt-error",
  "txt-info",
  "accent-primary",
  "accent-primary-hover",
  "accent-primary-active",
  "accent-primary-disabled",
  "accent-secondary-disabled",
  "accent-stateless",
  "accent-stateless_0_4_opacity",
  "accent_0_5_opacity",
  "accent_1_2_opacity",
  "accent_1_8_opacity",
  "accent_2_8_opacity",
  "accent_4_0_opacity",
  "bg-grey",
  "bg-stateless",
  "bg-active",
  "bg-base-light",
  "bg-base-medium",
  "bg-primary",
  "bg-primary-light",
  "bg-primary-hover",
  "bg-primary-active",
  "bg-primary-stateless",
  "bg-primary-0-5-opacity",
  "bg-secondary",
  "bg-hover",
  "bg-green",
  "bg-green-medium",
  "bg-blue",
  "bg-red",
  "bg-red-light",
  "background-red-medium",
  "bg-orange",
  "bg-tooltip",
  "icon-primary",
  "icons-primary-opacity-0-6",
  "icons-secondary",
  "icons-placeholder",
  "icons-invert",
  "icons-muted",
  "icons-primary-hover",
  "icons-secondary-hover",
  "btn-primary-text",
  "btn-primary-text-0-6",
  "btn-primary-text-0-4",
  "btn-disabled-text",
  "btn-secondary-text",
  "link-primary",
  "link-stateless",
  "link-hover",
  "link-active",
  "link-muted",
  "link-pressed",
  "borders-primary",
  "borders-primary-hover",
  "borders-secondary",
  "borders-strong",
  "borders-invert",
  "border-hover-bottom",
  "border-active-bottom",
  "border-primary-stateless",
  "borders-disabled",
  "borders-button",
  "borders-item",
  "borders-base-light",
  "borders-base-medium",
  "borders-green",
  "borders-green-medium",
  "borders-red",
  "active-secondary",
  "active-secondary-hover",
  "tag",
  "states-error-disabled-text",
  "error",
  "error-0-28-opacity",
  "error-0-12-opacity",
  "error-hover",
  "error-active",
  "success",
  "success-hover",
  "success-Active",
  "warning",
  "warning-hover",
  "warning-active",
  "info",
  "modified",
  "red",
  "orange",
  "salad",
  "green",
  "blue",
  "indigo",
  "violet",
  "pink",
  "gradient-right",
  "extra-0-3-overlay",
  "gradient-right-active",
  "gradient-right-hover",
  "extra-0-5-overlay",
  "extra-0-7-overlay",
  "extra-0-9-overlay",
  "red-0-1-overlay",
  "orange-0-1-overlay",
  "accent-0-8-overlay",
  "link",
  "camera",
  "google-drive",
  "dropbox",
  "one-drive",
  "device",
  "instagram",
  "free-images",
  "free-icons",
  "canvas",
  "box",
  "screen-cast",
  "unsplash",
  "light-shadow",
  "medium-shadow",
  "large-shadow",
  "x-large-shadow"
]
*/

interface ContentImageEditorProps {
  image: string;
  type: 'cover' | 'banner' | 'assessmentQuestion';
  setImage: (image: string) => void;
  setFormValue: (cloudfrontLink: string) => void;
}

export default function AuthoringImageEditor({
  image,
  type,
  setImage,
  setFormValue,
}: ContentImageEditorProps) {
  useLazyLoadStylesheet('/public/styles/image-styles.css');
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const [bannerUploading, setBannerUploading] = useState(false);

  const [brandGold100] = useToken('colors', ['brand.gold.100']);

  const defaultAspectRatio =
    type === 'banner'
      ? 73 / 20
      : type === 'assessmentQuestion'
      ? 'original'
      : company.feature_flags.explore_in_list_view
      ? 38 / 15
      : 40 / 43;

  return (
    // <Box>*** FIX IMAGE EDITOR *** </Box>
    <Modal size="full" isOpen={image !== ''} onClose={() => setImage('')}>
      <ModalOverlay />
      <ModalContent height="full">
        <ModalCloseButton />

        <ModalHeader>
          <Text>{`Adjust ${
            type === 'cover' ? 'Cover' : type === 'banner' ? 'Banner' : ''
          } Image`}</Text>
        </ModalHeader>

        <ModalBody padding={12}>
          {bannerUploading && <Loading />}

          {!bannerUploading && (
            <FilerobotImageEditor
              source={image}
              onBeforeSave={() => false}
              theme={{
                palette: {
                  primary: brandGold100,
                  'icons-primary': brandGold100,
                  'icons-secondary': brandGold100,
                  'borders-primary': brandGold100,
                  'borders-secondary': brandGold100,
                  'borders-strong': brandGold100,
                  'accent-primary': brandGold100,
                  'accent-secondary': brandGold100,
                  'button-primary': brandGold100,
                  success: brandGold100,
                },
              }}
              onSave={(editedImageObject) => {
                const dataURItoBlob = (dataURI: string) => {
                  const binary = window.atob(dataURI.split(',')[1]);

                  const array = [];

                  for (let i = 0; i < binary.length; i += 1) {
                    array.push(binary.charCodeAt(i));
                  }

                  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
                };

                if (editedImageObject && editedImageObject.imageBase64) {
                  const file = new File(
                    [dataURItoBlob(editedImageObject.imageBase64)],
                    'uploaded-img.jpeg',
                  );

                  v3ApiService
                    .getS3ImagePolicy(file.type, file.name)
                    .then((policy) => {
                      const formData = new FormData();

                      formData.append('key', policy.Key);
                      formData.append('Content-Type', policy.ContentType);
                      formData.append('AWSAccessKeyId', policy.AWSAccessKeyId);
                      formData.append('success_action_status', '201');
                      formData.append('policy', policy.S3Policy);
                      formData.append('signature', policy.S3Signature);
                      formData.append('file', file);

                      setBannerUploading(true);

                      axios
                        .post('https://' + policy.Bucket + '.s3.amazonaws.com/', formData)
                        .then(() => {
                          if (!policy.CloudFrontLink) return;

                          setFormValue(policy.CloudFrontLink);
                          setImage('');
                          setBannerUploading(false);
                        })
                        .catch((err) => {
                          console.log(err);

                          setToast({
                            show: true,
                            status: 'error',
                            title: t('modules.notifications.canNotUpdateProfile'),
                          });
                        });
                    })
                    .catch((err) => {
                      console.log(err);

                      setToast({
                        show: true,
                        status: 'error',
                        title: t('modules.notifications.canNotUpdateProfile'),
                      });
                    });
                }
              }}
              onClose={() => setImage('')}
              annotationsCommon={{
                fill: '#ff0000',
              }}
              Text={{ text: 'Filerobot...' }}
              Rotate={{ angle: 90, componentType: 'slider' }}
              Crop={{
                noPresets: true,
                ratio: defaultAspectRatio,
              }}
              tabsIds={[TABS.ADJUST /* TABS.FILTERS, TABS.RESIZE */]}
              defaultTabId={TABS.ADJUST}
              defaultToolId={TOOLS.CROP}
              savingPixelRatio={4}
              previewPixelRatio={window.devicePixelRatio}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
