import { Center } from "@chakra-ui/react";
import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import { ProfileForm } from "@/client/types/admin/profiles/ProfileForm";
import SwitchList from "@/client/routes/admin/profiles/forms/SwitchList";
import { useConfigStore } from "@/client/services/state/configStore";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ProfileContentCreation() {
  const { t } = useTranslation();
  const { watch } = useFormContext<ProfileForm>();
  const { config } = useConfigStore();

  const allContentCreation = watch("permissions.admin.content.all_create");

  const contentCreationOptions = [
    {
      name: "permissions.admin.content.all_create",
      label: t("profiles.edit.createAny"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.workshops_create",
      label: t("profiles.edit.createWorkshops"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.articles_create",
      label: t("profiles.edit.createArticles"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.files_create",
      label: t("profiles.edit.createFiles"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.videos_create",
      label: t("profiles.edit.createVideos"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.scorm_create",
      label: t("profiles.edit.createScorm"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.assessments_create",
      label: t("profiles.edit.createAssessments"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.tracks_create",
      label: t("profiles.edit.createTracks"),
      enabled: true,
    },
    {
      name: "permissions.admin.content.imported_content_create",
      label: t("profiles.edit.createThirdParty"),
      enabled: true,
    },
    // {
    //   name: 'permissions.admin.content.surveys_create',
    //   label: t('profiles.edit.createSurveys'),
    //   enabled: config.features.enable_surveys,
    // },
  ];

  return (
    <>
      <Center>
        <FormLabelWithTooltip label={t("edit.profiles.createContent")} />
      </Center>

      <SwitchList
        allChecked={allContentCreation}
        options={contentCreationOptions}
      />
    </>
  );
}
