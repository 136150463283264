import { useState, useEffect } from "react";
import SurveyService from "../api/SurveyService";

export const useCheckSurveyCompletion = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [availableSurveys, setAvailableSurveys] = useState<{
    [key: string]: boolean;
  }>({});

  const checkShowPopupSurvey = async (
    content: any,
    completed?: boolean,
    hideInContext?: boolean
  ) => {
    const contentId = content._id || content.id;
    const survey = content.surveys[0];
    if (!survey || hideInContext || !completed) {
      setShowPopup(false);
      return;
    }

    const surveyCompleted = await SurveyService.userHasCompletedContentSurvey(
      survey.survey_id,
      contentId
    );
    setShowPopup(!surveyCompleted);
    return;
  };

  const handleSessionSurveys = async (
    sessions: any,
    attendedSessions: any,
    hideInContext?: boolean
  ) => {
    const availableSurveys: { [key: string]: boolean } = {};
    for (let i = 0; i < sessions.length; i++) {
      if (
        !hideInContext &&
        attendedSessions[sessions[i].id] &&
        sessions[i].details.surveys?.length
      ) {
        const completed = await SurveyService.userHasCompletedContentSurvey(
          sessions[i].details.surveys[0].survey_id,
          sessions[i].id
        );
        availableSurveys[sessions[i].id] = !completed;
      } else {
        availableSurveys[sessions[i].id] = false;
      }
    }
    setAvailableSurveys(availableSurveys);
  };

  return {
    showPopup,
    availableSurveys,
    checkShowPopupSurvey,
    handleSessionSurveys,
  };
};
