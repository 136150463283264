import { create } from "zustand";

interface SkillsState {
  items: any[];
  setItems: (items: any[]) => void;
  searchTerm: string | null;
  setSearchTerm: (searchTerm: string | null) => void;
  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;
  searchItems: any[];
  setSearchItems: (searchItems: any[]) => void;
  activeItemId: string | null;
  setActiveItemId: (activeItemId: string | null) => void;
  sortOrder: "asc" | "desc" | "oldest" | "newest";
  setSortOrder: (sortOrder: "asc" | "desc" | "oldest" | "newest") => void;
}

export const useSkillsStore = create<SkillsState>((set) => ({
  items: [],
  setItems: (items) => set({ items }),
  searchTerm: null,
  setSearchTerm: (searchTerm) => set({ searchTerm }),
  isSearching: false,
  setIsSearching: (isSearching) => set({ isSearching }),
  searchItems: [],
  setSearchItems: (searchItems) => set({ searchItems }),
  activeItemId: null,
  setActiveItemId: (activeItemId) => set({ activeItemId }),
  sortOrder: "newest",
  setSortOrder: (sortOrder) => set({ sortOrder }),
}));
