/* eslint react/jsx-props-no-spreading: 0 */

import {
  Box,
  Center,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { Close } from "@/client/components/icons/ContinuIcons";
import DynamicForm from "@/client/routes/registration-forms/DynamicForm";
import { FormField } from "@/client/types/RegistrationForms";
import Logo from "@/client/components/media/images/Logo";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface PreviewProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fields: FormField[];
}

export default function Preview({ isOpen, setIsOpen, fields }: PreviewProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const handlePreviewSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setToast({
      show: true,
      status: "error",
      title: t("registrationForms.preview.submit"),
    });
  };

  const methods = useForm();

  return (
    <Modal size="full" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />

      <ModalContent backgroundColor="brand.mist">
        <ModalHeader display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="Close"
            icon={<Close color="white" />}
            onClick={() => setIsOpen(false)}
          />
        </ModalHeader>

        <ModalBody>
          <Center
            minHeight="100vh"
            backgroundColor="brand.mist"
            overflow="auto"
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          >
            <Flex
              minHeight="80vh"
              align="center"
              justify="center"
              width="500px"
              maxWidth="100%"
              zIndex="2"
            >
              <Stack spacing={8} width="100%" paddingX={6}>
                <Box
                  rounded="lg"
                  boxShadow="lg"
                  backgroundColor="white"
                  padding={8}
                  width="100%"
                >
                  <Box marginTop={4} marginBottom={8} textAlign="center">
                    <Logo variant="login" />
                  </Box>

                  <Text as="h4">{t("preauth.invite.registerWithUserID")}</Text>

                  <FormProvider {...methods}>
                    <form onSubmit={handlePreviewSubmit}>
                      <DynamicForm fields={fields} />
                    </form>
                  </FormProvider>

                  <VStack marginTop={4}>
                    <Text fontSize="xs" color="gray.500" textAlign="center">
                      &copy; Continu, Inc. {t("preauth.all_rights_reserved")}
                    </Text>
                  </VStack>
                </Box>
              </Stack>
            </Flex>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
