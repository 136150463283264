import {
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import ContentAudienceForm from '@/client/components/admin/create/content/forms/audience/ContentAudienceForm';
import ContentAuthoringBox from '@/client/components/admin/layout/ContentAuthoringBox';
import ContentCreateTabControls from '@/client/components/admin/create/content/tabs/ContentCreateTabControls';
import ContentPublishForm from '@/client/components/admin/create/content/forms/ContentPublishForm';
import ContentSettingsForm from '@/client/components/admin/create/content/forms/settings/ContentSettingsForm';
import CourseAddContentForm from '@/client/components/admin/create/content/courses/forms/CourseAddContentForm';
import CourseDetailsForm from '@/client/components/admin/create/content/courses/forms/CourseDetailsForm';
import type { CourseFormData } from '@/client/routes/admin/create/content/tracks/EditCourse';
import CoursePreviewModal from '@/client/components/admin/create/content/courses/overlay/CoursePreviewModal';
import CreateTabs from '@/client/components/admin/create/shared/tabs/CreateTabs';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import ImportedContentView from '@/client/components/content/courses/ImportedContentView';
import LearningTrackPreview from '@/client/components/admin/create/content/courses/overlay/LearningTrackPreview';
import LinkedinSearch from '@/client/components/admin/create/content/forms/tracks/imported-content/LinkedinSearch';
import Loading from '@/client/components/media/Loading';
import MasterclassSearch from '@/client/components/admin/create/content/forms/tracks/imported-content/MasterclassSearch';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import UdemySearch from '@/client/components/admin/create/content/forms/tracks/imported-content/UdemySearch';
import { useAuthStore } from '@/client/services/state/authStore';
import { useAuthorImportedContent } from '@/client/services/hooks/admin/authoring/courses/imported-content/useAuthorImportedContent';
import { useAuthorTrack } from '@/client/services/hooks/admin/authoring/courses/useAuthorTrack';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export default function EditCourseForm() {
  const { id } = useParams();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const {
    tabs,
    setTabs,
    contentType,
    tabIndex,
    setTabIndex,
    setNoImageModalIsOpen,
    coursePublishModalIsOpen,
    setCoursePublishModalIsOpen,
  } = useCreateStore();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { submitTrackMutation, getTrackForEditor, trackPayload } = useAuthorTrack(id);
  const { getImportedContentForEditor, submitImportedContentMutation, importedContentPayload } =
    useAuthorImportedContent();

  const { formState, watch } = useFormContext<CourseFormData>();

  const { title, description, instructors, sections } = watch();

  const sharedValidations = title !== '' && description !== '' && instructors.length > 0;

  const trackValidations = sections && sections.length > 0 && sharedValidations;

  const renderCoursePreviewModalButtonTerms =
    contentType === 'track' ? trackValidations : sharedValidations;

  const { image, bannerImage } = watch();

  // TODO: We don't currently allow for the replacement of imported content packages, should we?
  const renderContentSearch = () => {
    switch (contentType) {
      case 'udemy':
        return <UdemySearch />;
      case 'linkedin':
        return <LinkedinSearch />;
      case 'masterclass':
        return <MasterclassSearch />;
      default:
        return <div>Search</div>;
    }
  };

  useEffect(() => {
    let tabOptions = [
      {
        label: t('authoring.courses.tabs.details'),
        content: <CourseDetailsForm />,
        isDisabled: contentType !== 'track',
      },
      {
        label: t('authoring.courses.tabs.audience'),
        content: (
          <ContentAuthoringBox>
            <ContentAudienceForm />
          </ContentAuthoringBox>
        ),
        isDisabled: true,
      },
      {
        label: t('authoring.courses.tabs.settings'),
        content: (
          <ContentAuthoringBox>
            <ContentSettingsForm isCourse />
          </ContentAuthoringBox>
        ),
        isDisabled: true,
      },
      {
        label: id ? t('authoring.courses.tabs.update') : t('authoring.courses.tabs.publish'),
        content: (
          <ContentAuthoringBox>
            <ContentPublishForm />
          </ContentAuthoringBox>
        ),
        isDisabled: true,
      },
    ];

    if (
      !id &&
      (contentType === 'udemy' || contentType === 'linkedin' || contentType === 'masterclass')
    ) {
      tabOptions = [
        {
          label: t('authoring.courses.tabs.importedContent.import'),
          content: renderContentSearch(),
          isDisabled: !!id,
        },
        ...tabOptions,
      ];
    }

    if (contentType === 'track') {
      const trackContentTab = {
        label: t('authoring.courses.tabs.addContent'),
        content: <CourseAddContentForm />,
        isDisabled: true,
      };

      tabOptions.splice(1, 0, trackContentTab);
    }

    setTabs(tabOptions);
  }, [contentType]);

  useEffect(() => {
    if (tabIndex > 1 && contentType === 'track') {
      submitTrackMutation.mutateAsync({
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
        // TODO: Fix this GraphQL circular type dependency
        // @ts-ignore
        draft: watch('draft'),
      });
    }
  }, [tabIndex]);

  const { isError: isTrackError, error: trackError, status: trackStatus } = getTrackForEditor;

  const {
    isError: isImportedContentError,
    error: importedContentError,
    status: importedContentStatus,
  } = getImportedContentForEditor;

  useEffect(() => {
    if (!id) return;

    if (contentType === 'track') {
      getTrackForEditor.mutateAsync();
    }
    if (contentType === 'udemy' || contentType === 'linkedin' || contentType === 'masterclass') {
      getImportedContentForEditor.mutateAsync();
    }
  }, [contentType]);

  const handleOnProceed = () => {
    if ((tabIndex === 0 && !image) || (tabIndex === 0 && !bannerImage)) {
      setNoImageModalIsOpen(true);

      return;
    }

    setTabIndex(tabIndex + 1);
  };

  const handleSave = (publish?: boolean) =>
    contentType === 'track'
      ? submitTrackMutation
          .mutateAsync({
            approval_required: user.is_collaborator,
            approved: !user.is_collaborator,
            draft: !publish || false,
          })
          .then((data) => {
            !publish
              ? window.location.replace(`/pvt/#/overview/content`)
              : navigate(`/track/${data._id}`);
          })
      : submitImportedContentMutation
          .mutateAsync({
            approval_required: user.is_collaborator,
            approved: !user.is_collaborator,
            published: publish || false,
          })
          .then((data) => {
            publish
              ? navigate(`/imported-content/${data._id}`)
              : window.location.replace(`/pvt/#/overview/content`);
          })
          .catch((e) => {
            console.error('Error Saving Imported Content', e);

            setToast({
              show: true,
              status: 'error',
              title: e.response?.data?.message || 'There was an error saving the course',
            });
          });

  if (contentType === 'track' && id && trackStatus === 'loading') return <Loading />;

  if (
    (contentType === 'udemy' || contentType === 'linkedin' || contentType === 'masterclass') &&
    id &&
    importedContentStatus === 'loading'
  )
    return <Loading />;

  if (isTrackError || isImportedContentError) {
    console.error('Error Fetching Course', trackError || importedContentError);

    return (
      <ErrorAlert
        title="There was an error fetching the course"
        backAction={{
          to: '/pvt/#/overview/content',
          label: 'Back to content overview',
          isAngular: true,
        }}
      />
    );
  }

  return (
    <>
      <CreateTabs
        isTrack
        tabOptions={tabs}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        controls={
          <ContentCreateTabControls
            canPublish={formState.isValid}
            tabOptionsLength={tabs.length}
            onProceed={handleOnProceed}
            handlePublish={() => handleSave(true)}
            renderCoursePreviewButton={renderCoursePreviewModalButtonTerms}
          />
        }
      />

      <Modal
        isCentered
        isOpen={coursePublishModalIsOpen}
        onClose={() => setCoursePublishModalIsOpen(false)}
        size="lg"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalBody paddingTop={12}>
            <VStack textAlign="center">
              <Image src="/public/images/readyToPublish.svg" width={120} height="auto" />

              <Text fontSize="20px" fontWeight={600} color="baseBlack">
                Would you like to publish now?
              </Text>

              <Text fontSize="14px" fontWeight={500} color="neutral.1000">
                Please note, you will have to publish this track before it becomes available to
                users in Explore.
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
            <ButtonGroup width="full" alignItems="center" justifyContent="center">
              <OutlineButton onClick={() => setCoursePublishModalIsOpen(false)}>
                {t('authoring.returnToEdit')}
              </OutlineButton>

              <SolidButton onClick={() => handleSave(false)}>Create Only</SolidButton>

              <SolidButton onClick={() => handleSave(true)}>Create and Publish</SolidButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CoursePreviewModal>
        {contentType === 'track' ? (
          <LearningTrackPreview
            data={{
              // @ts-ignore
              learningTrack: { ...trackPayload, instructor_ids: instructors },
            }}
          />
        ) : (
          <ImportedContentView
            isPreview
            data={{
              content: {
                ...importedContentPayload,
                // @ts-ignore
                instructor_ids: instructors,
              },
              registration: undefined,
              progressPercent: 0,
            }}
          />
        )}
      </CoursePreviewModal>
    </>
  );
}
