import { useEffect } from "react";
import { useGetReportData } from "@/client/services/hooks/admin/useGetReportData";
import { useTranslation } from "react-i18next";
import { StackedBarChart } from "../StackedBarChart";

export default function RevenueChart() {
  const {
    dateRange,
    setDateRange,
    getDataBasedOnDateRange,
    getEcommerceRevenueReportData,
  } = useGetReportData(1);
  const { data, refetch } = getEcommerceRevenueReportData;

  useEffect(() => {
    getDataBasedOnDateRange(refetch);
  }, [dateRange]);

  const { t } = useTranslation();

  const labels = {
    permanent: t("overviewProducts.licensing_permanent"),
    subscription: t("overviewProducts.licensing_subscription"),
  };

  return (
    <StackedBarChart
      data={data || []}
      labels={labels}
      colSpan={1}
      rowSpan={1}
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={t("dashboard.revenue")}
      tooltip={t("dashboard.revenue.tooltip")}
      leftLegend={t("dashboard.revenue")}
    />
  );
}
