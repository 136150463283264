import { Category } from "@/client/types/Category";
import { learnApiClient } from "./clients/learnApiClient";

export type CategoryWithParentId = Category & { parent: string };

export type CategoryWithParent = Category & { parent: Category };

const getCategoryAncestry = async (id: string): Promise<Category[]> => {
  const url = `categories/${id}?parent-populate&parent.parent-populate`;
  const response = await learnApiClient.get(url);
  if (response.data.parent) {
    if (response.data.parent.parent) {
      return [response.data.parent.parent, response.data.parent, response.data];
    }
    return [response.data.parent, response.data];
  }
  return [response.data];
};

const getCategoriesByManagerSearch = async (
  type: string,
  level: number = 1,
  page: number = 1,
  perPage: number = 100,
  searchTerm: string = "",
  parent: string = ""
): Promise<{ data: Category[]; nextPage: number | undefined }> => {
  let url = `categories/manager?level=${level}&type=${type}&search_term=${searchTerm}&page=${page}&per_page=${perPage}`;
  if (parent) {
    url += `&parent=${parent}`;
  }
  const response = await learnApiClient.get(url);

  // do we need to localize them for admin use?
  const localizedCategories = response.data;
  // const localizedCategories = localizeCategories(response.data);

  return localizedCategories;
};

const AdminCategoryService = {
  getCategoriesByManagerSearch,
  getCategoryAncestry,
};

export default AdminCategoryService;
