/* eslint-disable react/jsx-props-no-spreading */

import { HStack, Stack, Text, useRadioGroup } from "@chakra-ui/react";

import CreateRadio from "./CreateRadio";
import type { DefaultTFuncReturn } from "i18next";
import type { ReactElement } from "react";
import { useFormContext } from "react-hook-form";

// TODO: Disabled styles
// TODO: Refactor this component and AssessmentRadioGroup into a shared component at some point

interface CreateRadioGroupProps {
  name: string;
  defaultValue: string;
  label?: string | DefaultTFuncReturn;
  helpText?: string | DefaultTFuncReturn;
  labelVariant?: "createLabel" | "createHeading";
  options: { value: string; label: string; icon?: ReactElement }[];
  isDisabled?: boolean;
}

export default function CreateRadioGroup({
  name,
  defaultValue,
  label = null,
  helpText = null,
  labelVariant = "createLabel",
  options,
  isDisabled = false,
}: CreateRadioGroupProps) {
  const { setValue } = useFormContext();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value) => setValue(name, value),
  });

  const group = getRootProps();

  return (
    <Stack spacing={0.5}>
      {label && <Text variant={labelVariant}>{label}</Text>}

      {helpText && <Text variant="createHelpText">{helpText}</Text>}

      <HStack marginTop={4} width="full" {...group}>
        {options.map((option) => {
          const radio = getRadioProps({
            value: option.value,
            label: option.label,
            icon: option.icon,
            isDisabled,
          });

          return <CreateRadio {...radio} key={option.label} />;
        })}
      </HStack>
    </Stack>
  );
}
