import type { AdminCategoriesData } from "@/client/types/admin/categories/AdminCategoriesData";
import { learnApiClient } from "@/client/services/api/clients/learnApiClient";

const getCategoriesForAdmin = async (): Promise<AdminCategoriesData[]> => {
  const response = await learnApiClient.get("categories/find-for-admin", {
    params: {
      category: true,
      fields: "children,name,localized_names,level,parent",
    },
  });

  return response.data;
};

const AdminCategoriesService = {
  getCategoriesForAdmin,
};

export default AdminCategoriesService;
