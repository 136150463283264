/* eslint-disable react/no-unstable-nested-components */
import { ColumnDefBase, createColumnHelper } from "@tanstack/react-table";
import { useFormatDate } from "@/client/services/hooks/date-and-time/useFormatDate";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import { PaymentsGto } from "@/client/services/api/graphql/gql/graphql";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import { Box, Button, Flex, SkeletonText, Text } from "@chakra-ui/react";
import EcommerceListTable from "@/client/components/data-display/charts/ecommerce/EcommerceListTable";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "@/client/components/media/Loading";
import { cardBrandIcons } from "@/client/components/icons/getCardBrandIcon";

export default function PaymentsList() {
  const { t } = useTranslation();
  const { formatDate } = useFormatDate();
  const navigate = useNavigate();
  useDocumentTitle(t("ecommerce.user.paymentHistory"));

  const {
    data,
    isError: paymentsError,
    isFetching,
  } = useQuery({
    queryKey: ["list-payments"],
    queryFn: () => ProductsService.listPayments(),
  });

  const columnHelper = createColumnHelper<PaymentsGto>();
  const columns = useMemo<ColumnDefBase<PaymentsGto, any>[]>(
    () => [
      columnHelper.accessor("productTitle", {
        cell: (info) => info.getValue(),
        header: () => <span>{t("ecommerce.user.paymentHistory.product")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("paymentDate", {
        cell: (info) => (
          <Box>
            {info.getValue()
              ? formatDate(new Date(info.getValue()), "dateSlash")
              : "----"}
          </Box>
        ),
        header: () => (
          <span>{t("ecommerce.user.paymentHistory.paymentDate")}</span>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("paymentMethod", {
        cell: (info) => (
          <Box>
            <Flex align="center">
              {cardBrandIcons[info.row.original.paymentType] ||
                info.row.original.paymentType}
              <Text ml={2}>*{info.getValue()}</Text>
            </Flex>
          </Box>
        ),
        header: () => (
          <span>{t("ecommerce.user.paymentHistory.paymentMethod")}</span>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("paymentTotal", {
        cell: (info) => `$${(info.getValue() / 100).toFixed(2)}`,
        header: () => <span>{t("ecommerce.user.paymentHistory.amount")}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    []
  );

  if (isFetching) return <Loading />;

  if (paymentsError)
    return <ErrorAlert title="Error retrieving payment history" />;

  return (
    <Box marginX={20} marginY={10}>
      <Button
        variant="outline"
        leftIcon={<MdOutlineKeyboardBackspace />}
        fontWeight={450}
        onClick={() => navigate("/dashboard")}
      >
        {t("ecommerce.user.paymentHistory.backToContent")}
      </Button>
      <Box marginX={20} marginY={10}>
        {(!data || (data && data.length === 0)) && (
          <>
            <Text fontSize="18px" fontWeight="medium">
              {t("ecommerce.user.paymentHistory.noPaymentFound")}
            </Text>
            <SkeletonText
              startColor="brand.backgroundGrey"
              endColor="brand.backgroundGrey"
              speed={0}
              mt="4"
              noOfLines={10}
              spacing="4"
              skeletonHeight="10"
            />
          </>
        )}
        {data && data.length > 0 && (
          <EcommerceListTable
            data={{ rows: data }}
            columns={columns}
            title={t("ecommerce.user.paymentHistory.title")}
          />
        )}
      </Box>
    </Box>
  );
}
