import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  AngleCircleDown,
  AngleCircleRight,
  CheckCircle,
  Circle,
  Locked,
  PointerOutline,
} from "@/client/components/icons/ContinuIcons";

import HtmlRenderer from "@/client/components/html/HtmlRenderer";
import { capCase } from "@/client/utils/capCase";
import { useGetContentTypeIcon } from "@/client/services/hooks/shared/icons/useGetContentTypeIcon";
import { useTranslation } from "react-i18next";

type TrackContentListProps = {
  sections: any[];
  lastViewedContent: any;
  trackComplete?: boolean;
  handleGoToCourse: (course: any) => void;
};

export default function TrackContentList({
  sections,
  lastViewedContent,
  trackComplete,
  handleGoToCourse,
}: TrackContentListProps) {
  const { t } = useTranslation();

  return (
    <>
      <Text as="h4" fontSize="md" marginBottom={2}>
        {t("contentViews.tracks.view_contents")}:
      </Text>

      <Accordion defaultIndex={[0]} allowMultiple>
        {sections &&
          sections.map((section) => (
            <AccordionItem key={section._id} border="none">
              {({ isExpanded }) => (
                <>
                  <Text as="h3" margin="0">
                    <AccordionButton>
                      {isExpanded ? (
                        <AngleCircleDown
                          marginRight={4}
                          color={
                            section.complete ? "green.400" : "blackAlpha.500"
                          }
                        />
                      ) : (
                        <AngleCircleRight
                          marginRight={4}
                          color={
                            section.complete ? "green.400" : "blackAlpha.500"
                          }
                        />
                      )}

                      <Box flex="1" textAlign="left" fontWeight="bold">
                        {section.name}
                      </Box>
                    </AccordionButton>
                  </Text>

                  <AccordionPanel paddingBottom={4}>
                    <Box className="section-description" paddingLeft={10}>
                      <HtmlRenderer html={section.description} />
                    </Box>

                    <Stack>
                      {section.courses.map((course: any) => (
                        <Flex
                          key={course._id}
                          alignItems="center"
                          paddingLeft={10}
                        >
                          <Flex
                            style={{ minWidth: 0 }}
                            alignItems="center"
                            paddingY={1}
                            paddingX={2}
                            borderRadius="md"
                            backgroundColor={
                              !trackComplete &&
                              course.contentId === lastViewedContent?.contentId
                                ? "blackAlpha.100"
                                : "transparent"
                            }
                          >
                            {!trackComplete &&
                            course.contentId ===
                              lastViewedContent?.contentId ? (
                              <PointerOutline
                                color="brand.primary"
                                marginRight={4}
                              />
                            ) : (
                              <>
                                {course.completed && (
                                  <CheckCircle
                                    color="green.400"
                                    marginRight={4}
                                  />
                                )}

                                {!course.completed && course.available && (
                                  <Circle color="gray.300" marginRight={4} />
                                )}

                                {!course.available && (
                                  <Locked color="gray.300" marginRight={4} />
                                )}
                              </>
                            )}

                            <Tooltip
                              label={
                                course?.contentType
                                  ? capCase(course.contentType)
                                  : ""
                              }
                            >
                              <Box as="span">
                                {useGetContentTypeIcon(course.contentType)}
                              </Box>
                            </Tooltip>

                            {course.available ? (
                              <>
                                <Box
                                  key={course._id}
                                  fontWeight="semibold"
                                  onClick={() => handleGoToCourse(course)}
                                  color="brand.link"
                                  whiteSpace="nowrap"
                                  flex="1"
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                  _hover={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {course.contentTitle}
                                </Box>

                                {!trackComplete &&
                                  course.contentId ===
                                    lastViewedContent?.contentId && (
                                    <Box marginLeft={4}>
                                      <Button
                                        onClick={() => handleGoToCourse(course)}
                                        size="xs"
                                      >
                                        {t("trackViewer.resume")}
                                      </Button>
                                    </Box>
                                  )}
                              </>
                            ) : (
                              <Text
                                fontWeight="semibold"
                                color="gray.500"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                {course.contentTitle}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
      </Accordion>
    </>
  );
}

TrackContentList.defaultProps = {
  trackComplete: false,
};
