import { Category } from "@/client/types/Category";

export const buildQueryString = (
  selectedItems: Category[],
  groupId: string | undefined,
  activeGroupTab: "users" | "managers" | null
) => {
  let queryStr = "";

  if (groupId && activeGroupTab) {
    activeGroupTab === "managers"
      ? (queryStr += `managing_groups=$ne,${groupId}&`)
      : (queryStr += `groups=$ne,${groupId}&`);
  }

  const users = selectedItems.filter((item) => item.type === "user");

  if (users.length > 0) {
    return `_id=${users[0]._id}&`;
  }

  const text = selectedItems.filter((item) => item.type === "text");
  text.length > 0
    ? (queryStr += `$or=15,full_name,$regex,2,${text[0].value},i,job_title,$regex,2,${text[0].value},i,email,$regex,2,${text[0].value},i`)
    : null;

  const locations = selectedItems.filter((item) => item.type === "location");
  locations.length > 0
    ? (queryStr += `&linked_locations=${locations[0]._id}`)
    : null;

  const departments = selectedItems.filter(
    (item) => item.type === "department"
  );
  departments.length > 0
    ? (queryStr += `&linked_departments=${departments[0]._id}`)
    : null;

  const teams = selectedItems.filter((item) => item.type === "team");
  teams.length > 0 ? (queryStr += `&linked_teams=${teams[0]._id}`) : null;

  const orgLevels = selectedItems.filter((item) => item.type === "orgLevel");
  orgLevels.length > 0
    ? (queryStr += `&linked_org_levels=${orgLevels[0]._id}`)
    : null;

  const grades = selectedItems.filter((item) => item.type === "grade");
  grades.length > 0 ? (queryStr += `&linked_grades=${grades[0]._id}`) : null;

  const skills = selectedItems.filter((item) => item.type === "skill");
  skills.length > 0 ? (queryStr += `&skills=${skills[0]._id}`) : null;

  const roles = selectedItems.filter((item) => item.type === "role");
  roles.length > 0 ? (queryStr += `&role=${roles[0]._id}`) : null;

  const hiredOn = selectedItems.filter((item) => item.type === "hiredOn");
  hiredOn.length > 0 ? (queryStr += `&hired=${hiredOn[0]._id}`) : null;

  const createdOn = selectedItems.filter((item) => item.type === "createdOn");
  createdOn.length > 0 ? (queryStr += `&createdAt=${createdOn[0]._id}`) : null;

  const lastActive = selectedItems.filter((item) => item.type === "lastActive");
  lastActive.length > 0
    ? (queryStr += `&last_login=${lastActive[0]._id}`)
    : null;

  return queryStr;
};
