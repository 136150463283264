import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export default function SampleTable({
  overrideColor,
}: {
  overrideColor: string;
}) {
  return (
    <TableContainer borderRadius="xl">
      <Table variant="sample">
        <Thead backgroundColor={overrideColor || "brand.primary"}>
          <Tr>
            <Th>Convert</Th>
            <Th>Into</Th>
            <Th isNumeric>Multiply By</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>inches</Td>
            <Td>millimetres (mm)</Td>
            <Td isNumeric>25.4</Td>
          </Tr>
          <Tr>
            <Td>feet</Td>
            <Td>centimetres (cm)</Td>
            <Td isNumeric>30.48</Td>
          </Tr>
          <Tr>
            <Td>yards</Td>
            <Td>metres (m)</Td>
            <Td isNumeric>0.91444</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
