import { useTranslation } from "react-i18next";
import { Flex, HStack, Text, Button } from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";

import { CloseIcon } from "@chakra-ui/icons";
import { useFormContext } from "react-hook-form";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import invariant from "tiny-invariant";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  getCourseContentData,
  isCourseContentData,
} from "./course-content-data";
import {
  Hamburger,
  TrackArticle,
  TrackAssessment,
  TrackFile,
  TrackScorm,
  TrackVideo,
} from "@/client/components/icons/ContinuIcons";
import { useAuthStore } from "@/client/services/state/authStore";
import { useAuthorTrack } from "@/client/services/hooks/admin/authoring/courses/useAuthorTrack";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";
import { useNavigate } from "react-router-dom";

type ContentState =
  | {
      type: "idle";
    }
  | {
      type: "preview";
      container: HTMLElement;
    }
  | {
      type: "is-dragging";
    }
  | {
      type: "is-dragging-over";
      closestEdge: Edge | null;
    };

const idle: ContentState = { type: "idle" };

interface DraggableCourseContentItemProps {
  contentItem: any;
  sectionIndex: number;
}

export default function DraggableCourseContentItem({
  contentItem,
  sectionIndex,
}: DraggableCourseContentItemProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const ref = useRef<HTMLDivElement | null>(null);
  const [, setState] = useState<ContentState>(idle);
  const { setValue, watch } = useFormContext();
  const { submitTrackMutation } = useAuthorTrack();
  const { setTrackState } = useTrackStateStore();
  const navigate = useNavigate();
  const [renderDragIcon, setRenderDragIcon] = useState(false);

  const renderContentTypeIcon = (contentType: string) => {
    switch (contentType) {
      case "article":
        return <TrackArticle boxSize={6} />;

      case "assessment":
        return <TrackAssessment boxSize={6} />;

      case "file":
        return <TrackFile boxSize={6} />;

      case "scorm":
        return <TrackScorm boxSize={6} />;

      case "video":
        return <TrackVideo boxSize={6} />;

      default:
        return <TrackArticle boxSize={6} />;
    }
  };

  const sections = watch("sections");

  const handleRemoveCourse = (course: any, index: number) => {
    const targetSection = sections[index];

    targetSection.courses = targetSection.courses.filter(
      (c: any) => c._id !== course._id || c.id !== course.id
    );

    setValue("sections", sections);
  };

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      draggable({
        element,
        getInitialData() {
          return getCourseContentData(contentItem);
        },
        onDragStart() {
          setState({ type: "is-dragging" });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // * Don't allow items to drop on themselves
          if (source.element === element) {
            return false;
          }
          // * Only allow course section data to be dropped
          return isCourseContentData(source.data);
        },
        getData({ input }) {
          const data = getCourseContentData(contentItem);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: "is-dragging-over", closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // * Only need to update react state if nothing has changed.
          // * Prevents re-rendering.
          setState((current) => {
            if (
              current.type === "is-dragging-over" &&
              current.closestEdge === closestEdge
            ) {
              return current;
            }

            return { type: "is-dragging-over", closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      })
    );
  }, [contentItem]);

  const handleEditContent = async (content: any) => {
    await submitTrackMutation
      .mutateAsync({
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
        draft: watch("draft"),
      })
      .then(() => {
        setTrackState({
          contentId: null,
          contentPosition: null,
          contentTitle: null,
          contentType: content.type,
          section: sectionIndex,
          track: watch("id"),
          trackTitle: watch("title"),
          ttl: new Date().valueOf() + 5000,
          returningFromContent: true,
          returningFromEditContent: true,
        });

        navigate(`/admin/edit/${content.type}/${content.id || content._id}`);
      });
  };

  return (
    <Flex
      ref={ref}
      backgroundColor="warmNeutral.0"
      border="1px solid"
      borderColor="warmNeutral.200"
      borderRadius="6px"
      padding={3}
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      _hover={{ cursor: "grab" }}
      onMouseEnter={() => setRenderDragIcon(true)}
      onMouseLeave={() => setRenderDragIcon(false)}
    >
      <HStack>
        <Hamburger color={renderDragIcon ? "neutral.300" : "transparent"} />

        {renderContentTypeIcon(contentItem.type)}

        <Text fontSize="14px" fontWeight="500">
          {contentItem.title}
        </Text>
      </HStack>

      <HStack>
        <Button
          size="sm"
          backgroundColor="white"
          border="1px solid"
          borderColor="warmNeutral.200"
          borderRadius="8px"
          color="baseBlack"
          _hover={{ backgroundColor: "warmNeutral.100" }}
          onClick={() => handleEditContent(contentItem)}
        >
          {t("global.actions.edit")}
        </Button>

        <CloseIcon
          boxSize={3}
          color="baseBlack"
          _hover={{ cursor: "pointer" }}
          onClick={() => handleRemoveCourse(contentItem, sectionIndex)}
        />
      </HStack>
    </Flex>
  );
}
