import ExploreService from "../api/ExploreService";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useGetExploreContent = (
  isSearching: boolean,
  isFiltering: boolean,
  categoryId: string | null,
  restrictedTypes: string,
  userIsPurchaser: boolean
) =>
  useInfiniteQuery({
    enabled: (!!categoryId || userIsPurchaser) && !isSearching,
    queryKey: [`explore-content`, { isFiltering, categoryId, restrictedTypes }],
    queryFn: ({ pageParam = 1 }) =>
      ExploreService.getContent(categoryId, restrictedTypes, pageParam),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });
