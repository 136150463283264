import { Box, Text } from "@chakra-ui/react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import RegistrationFormsService from "@/client/services/api/RegistrationFormsService";
import {
  CreateFormBody,
  Form,
  FormField,
  RegistrationFormType,
  FormProducts,
  UserType,
} from "@/client/types/RegistrationForms";
import RegistrationFormsEdit from "./RegistrationFormEdit";
import ProductLandingPageDetails from "./ProductLandingPageDetails";

enum Page {
  Products = "products",
  FormData = "formData",
}

export default function ProductLandingPage() {
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const routeParams = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState<Page>(Page.Products);

  if (routeParams.id) {
    useDocumentTitle("Edit Landing Page");
  } else {
    useDocumentTitle("Create Landing Page");
  }

  const requiredFields: FormField[] = [
    {
      name: "first_name",
      title: "First Name",
      input_type: "text",
      description: "First Name",
      validations: [
        {
          validation: "required",
        },
      ],
      maps_to_write_type: "user_core",
      maps_to_field_name: "first_name",
    },
    {
      name: "last_name",
      title: "Last Name",
      input_type: "text",
      description: "Last Name",
      validations: [
        {
          validation: "required",
        },
      ],
      maps_to_write_type: "user_core",
      maps_to_field_name: "last_name",
    },
    {
      name: "email",
      title: "Email",
      input_type: "text",
      description: "Email",
      validations: [
        {
          validation: "required",
        },
      ],
      maps_to_write_type: "user_core",
      maps_to_field_name: "email",
    },
  ];

  const defaultFormValues: {
    type: RegistrationFormType | undefined;
    fields: FormField[];
    active: boolean;
    role: UserType;
  } = {
    type: "product_landing_page",
    fields: requiredFields,
    active: true,
    // Figure out how this will work without the UI
    role: "user",
  };

  const methods = useForm<CreateFormBody>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const { reset } = methods;

  const { data: landingPageData } = useQuery({
    queryKey: ["registration-forms", routeParams.id],
    queryFn: () => RegistrationFormsService.getForm(routeParams.id!),
    select: (data: Form) => {
      // This request populates the product and we only want to handle the ids
      // Alternative: We write a new endpoint that doesn't populate the products
      const productIds = data.products?.map(
        (product: FormProducts) => product._id
      );
      return {
        ...data,
        products: productIds,
      };
    },
    enabled: !!routeParams.id,
  });

  const nextPage = () => {
    setPage(Page.FormData);
  };

  const goBack = () => {
    setPage(Page.Products);
  };

  const updatePage = useMutation({
    mutationFn: (data: CreateFormBody) =>
      RegistrationFormsService.updateForm(routeParams.id!, data),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("registrationForms.publish_success"),
      });
      navigate("/overview/products/landing-pages");
    },
    onError: (error: Record<string, any>) => {
      console.error("error: ", error);

      const errorMessage = t("registrationForms.publish_error");

      setToast({
        show: true,
        status: "error",
        title: errorMessage,
      });
    },
  });

  const createPage = useMutation({
    mutationFn: (data: CreateFormBody) =>
      RegistrationFormsService.createForm(data),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("registrationForms.publish_success"),
      });
      navigate("/overview/products/landing-pages");
    },
    onError: (error: Record<string, any>) => {
      console.error("error: ", error);

      const errorMessage = t("registrationForms.publish_error");

      setToast({
        show: true,
        status: "error",
        title: errorMessage,
      });
    },
  });

  useEffect(() => {
    if (!landingPageData) return;

    reset(landingPageData);
  }, [landingPageData]);

  const onSubmit: SubmitHandler<CreateFormBody> = async (data) => {
    const pageBody = {
      ...data,
    };

    routeParams.id
      ? updatePage.mutateAsync(pageBody)
      : createPage.mutateAsync(pageBody);
  };

  return (
    <Box height="100%" marginX="5%" marginTop="3%" paddingBottom="5%">
      <Text fontSize="xl" lineHeight="1.2" fontWeight="500" marginBottom={2}>
        {t("ecommerce.landingPageCreate.header.createLandingPage")}
      </Text>
      <Text fontSize="sm" lineHeight="1.2" fontWeight="300" marginBottom={8}>
        {t("ecommerce.landingPageCreate.header.describeCreateLandingPage")}
      </Text>
      <FormProvider {...methods}>
        {page === Page.Products && (
          <ProductLandingPageDetails onNextButtonClick={nextPage} />
        )}
        {page === Page.FormData && (
          <RegistrationFormsEdit
            submit={methods.handleSubmit(onSubmit)}
            goBack={goBack}
          />
        )}
      </FormProvider>
    </Box>
  );
}
