import { Box, Button, useToken } from "@chakra-ui/react";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type TabButton = {
  key: string;
  text: string;
  allowance: boolean;
};

type ButtonTabsProps = {
  nav: TabButton[];
  path: string;
  tab: string | undefined;
};

export default function AdminButtonTabs({ nav, path, tab }: ButtonTabsProps) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(tab || nav[0].key);
  const [brandButton] = useToken("colors", ["brand.button"]);

  return (
    <Box flex="1">
      {nav.map((item) => {
        if (!item.allowance) return;

        return (
          <Button
            key={item.key}
            variant="adminTab"
            size="xs"
            mr={2}
            background={
              activeTab === item.key ? hexToRGBA(brandButton, 0.2) : ""
            }
            _hover={{
              background: hexToRGBA(brandButton, 0.2),
            }}
            onClick={() => {
              setActiveTab(item.key);
              navigate(!item.key ? path : path + "/" + item.key);
            }}
          >
            {item.text}
          </Button>
        );
      })}
    </Box>
  );
}
