/* eslint-disable react/jsx-props-no-spreading */

import {
  Content,
  ContentTypes,
  PartnerPermissionTypes,
  ScormExtras,
} from "@/client/services/api/graphql/gql/graphql";
import { FormProvider, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";

import AuthoringLivePreview from "@/client/components/admin/create/content/AuthoringLivePreview";
import type { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateLayout from "@/client/components/admin/create/shared/layout/CreateLayout";
import EditScormForm from "@/client/components/admin/create/content/forms/scorm/EditScormForm";
import TrackStateAlert from "@/client/components/admin/create/content/overlay/TrackStateAlert";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface ScormSpecificValues {
  scorm_file_details: {
    title: string;
    size: number;
  } | null;
}

export type ScormFormData = Content &
  ScormExtras &
  ContentFormValues &
  ScormSpecificValues;

const scormDefaultValues: ScormFormData = {
  id: "",
  company: "",
  title: "",
  creator: "",
  contentType: ContentTypes.Scorm,
  archived: false,
  private: false,
  author: "",
  enable_collaborators: false,
  privacyCollaborators: [],
  privacyLocations: [],
  privacyDepartments: [],
  privacyTeams: [],
  privacyOrgLevels: [],
  privacyGrades: [],
  privacyGroups: [],
  linkedCategory: [],
  image: "",
  bannerImage: "",
  description: "",
  exploreHide: true,
  duration: 0,
  approvalRequired: false,
  approved: false,
  tags: [],
  ratingConfiguration: {
    messaging: {
      individuals: [],
      slackChannels: [],
    },
    allowRating: false,
    allowFeedback: false,
    allowEdits: false,
    notifyFeedbackOnly: false,
    showUsersRating: false,
    totalValue: 5,
  },
  allowComments: false,
  enable_surveys: false,
  surveys: [],
  partnerPermissions: PartnerPermissionTypes.Tenant,
  enable_segmentation: false,
  sslaConfig: {
    openScoInNewWindow: false,
    scoWindowWidth: 1024,
    scoWindowHeight: 768,
    scoWindowTryFullscreen: false,
  },
  certificate: false,
  certificateData: [],
  scorm_file_details: null,
};

export default function EditScorm() {
  const { t } = useTranslation();
  useDocumentTitle(t("authoring.documentTitle.editScorm"));
  const { id } = useParams();
  const { isAllowed } = useAccessHelper();

  const allowance = {
    create_scorm: isAllowed(
      ["admin", "content", "all_create"],
      [["admin", "content", "scorm_create"]],
      ["admin", "creator", "collaborator"]
    ),
    edit_scorm: isAllowed(
      ["admin", "content", "all_edit"],
      [
        ["admin", "content", "scorm_edit"],
        ["admin", "content", "my_content_edit"],
      ],
      ["admin", "creator", "collaborator"]
    ),
  };

  if ((id && !allowance.edit_scorm) || (!id && !allowance.create_scorm)) {
    return <Navigate to="/explore" replace />;
  }

  const methods = useForm<ScormFormData>({
    defaultValues: scormDefaultValues,

    mode: "onBlur",
  });

  const { watch, formState, clearErrors } = methods;
  const title = watch("title");

  const description = watch("description");
  const { errors } = formState;

  useEffect(() => {
    if (errors.title && title !== "") clearErrors("title");

    if (errors.description && description !== "") clearErrors("description");
  }, [title, description]);

  return (
    <FormProvider {...methods}>
      <form>
        <CreateLayout
          previewElement={
            <AuthoringLivePreview
              contentType="scorm"
              titlePlaceholder={t(
                "authoring.scorm.livePreview.titlePlaceholder"
              )}
            />
          }
          formElement={<EditScormForm />}
        />
      </form>

      <TrackStateAlert contentType="scorm" />
    </FormProvider>
  );
}
