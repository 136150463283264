import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import AuthoringGraphqlService from "@/client/services/api/admin/content-authoring/AuthoringGraphqlService";
import { Box } from "@chakra-ui/react";
import Loading from "@/client/components/media/Loading";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useQuery } from "@tanstack/react-query";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";

export default function AdminCourseLayout() {
  const { id } = useParams();
  const { authConfig } = useAuthStore();
  const navigate = useNavigate();

  const { user, company } = authConfig;
  const location = useLocation();
  const { isAllowed } = useAccessHelper();
  const { contentType: type, setContentType, setTabIndex } = useCreateStore();
  const [angularTrackState, setAngularTrackState] = useLocalStorage(
    "trackState",
    null
  );
  const { setTrackState } = useTrackStateStore();

  const allowance = {
    create_imported_content: isAllowed(
      ["admin", "content", "all_create"],
      [["admin", "content", "imported_content_create"]],
      ["admin", "creator", "collaborator"]
    ),
    edit_imported_content: isAllowed(
      ["admin", "content", "all_edit"],
      [["admin", "content", "imported_content_edit"]],
      ["admin", "creator", "collaborator"]
    ),
    create_track: isAllowed(
      ["admin", "content", "all_create"],
      [["admin", "content", "tracks_create"]],
      ["admin", "creator", "collaborator"]
    ),
    edit_track: isAllowed(
      ["admin", "content", "all_edit"],
      [
        ["admin", "content", "tracks_edit"],
        ["admin", "content", "my_content_edit"],
      ],
      ["admin", "creator", "collaborator"]
    ),
  };

  const { pathname } = location;
  const contentType = pathname.split("/")[4];

  const { data, isFetching } = useQuery({
    enabled: !!id && contentType !== "track",
    queryKey: ["imported-content-type", id, user._id],
    queryFn: () => AuthoringGraphqlService.getImportedContentType(id, user._id),
  });

  // TODO: We need to re-write this logic once content overviews have been migrated and we're no longer routing from Angular.
  useEffect(() => {
    if (
      contentType &&
      (contentType === "udemy" ||
        contentType === "linkedin" ||
        contentType === "masterclass" ||
        contentType === "track")
    ) {
      setContentType(
        contentType as "udemy" | "linkedin" | "masterclass" | "track"
      );

      return;
    }

    if (id && !data) return;

    if (id && data) {
      const { currentVersion } = data.content;

      if (currentVersion) {
        const { vendor } = currentVersion;

        if (vendor === "linkedin_learning") {
          setContentType("linkedin");

          setTabIndex(0);

          navigate(`/admin/edit/course/linkedin/${id}`, { replace: true });
          return;
        }

        setContentType(vendor as "udemy" | "linkedin" | "masterclass");

        setTabIndex(0);

        navigate(`/admin/edit/course/${vendor}/${id}`, { replace: true });
      }
    }
  }, [data, contentType]);

  const isImportedContent =
    type === "udemy" || type === "linkedin" || type === "masterclass";
  const isTrack = type === "track";

  if (
    (isImportedContent && id && !allowance.edit_imported_content) ||
    (isImportedContent && !id && !allowance.create_imported_content)
  ) {
    return <Navigate to="/explore" replace />;
  }

  if (
    (isTrack && id && !allowance.edit_track) ||
    (isTrack && !id && !allowance.create_track)
  ) {
    return <Navigate to="/explore" replace />;
  }

  if (
    (type === "udemy" && !company.udemy) ||
    (type === "linkedin" && !company.linkedin_learning) ||
    (type === "masterclass" && !company.feature_flags.enable_masterclass)
  ) {
    return <Navigate to="/explore" replace />;
  }

  useEffect(() => {
    if (angularTrackState) {
      setTrackState(angularTrackState);
      setAngularTrackState(null);
    }
  }, [angularTrackState]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Box marginTop={-50} backgroundColor="neutral.0" paddingX={32}>
      <Outlet />
    </Box>
  );
}
