import { Grid, GridItem } from "@chakra-ui/react";
import { addDays, format, startOfWeek } from "date-fns";

export default function Weekdays({ activeDate }: { activeDate: Date }) {
  const weekStartDate = startOfWeek(activeDate);
  const weekdays: any[] = [];

  for (let day = 0; day < 7; day++) {
    weekdays.push(
      <GridItem key={day} textAlign="center" fontSize="15px">
        {format(addDays(weekStartDate, day), "E")}
      </GridItem>
    );
  }

  return (
    <Grid templateColumns="repeat(7, 1fr)" marginTop={2}>
      {weekdays}
    </Grid>
  );
}
