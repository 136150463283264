/* eslint-disable react/no-unstable-nested-components */
import { Avatar, Box, HStack, Link, Text } from "@chakra-ui/react";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { Badge } from "@/client/types/Badge";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import { truncateText } from "@/client/utils/text/truncateText";
import { useFormatDate } from "@/client/services/hooks/date-and-time/useFormatDate";
import { useGetUserBadgesPaginated } from "@/client/services/hooks/badges/useGetUserBadgesPaginated";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface UserBadgesProps {
  totalBadgeCount: number | undefined;
}

export default function UserBadges({ totalBadgeCount }: UserBadgesProps) {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data } = useGetUserBadgesPaginated(
    fetchDataOptions,
    id,
    totalBadgeCount
  );

  const columnHelper = createColumnHelper<Badge>();
  const columns = useMemo<ColumnDefBase<Badge, any>[]>(
    () => [
      columnHelper.accessor("badge.title", {
        cell: (info) => (
          <HStack>
            {info.row.original.badge.uploaded_image && (
              <Avatar size="sm" src={info.row.original.badge.uploaded_image} />
            )}

            <Link
              href={`/pvt/#/overview/badges/${info.row.original.badge._id}`}
            >
              {truncateText(info.getValue(), 42, 39)}
            </Link>
          </HStack>
        ),
        header: () => <span>{t("managerUser.title")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("badge.description", {
        cell: (info) => <Text>{truncateText(info.getValue(), 42, 39)}</Text>,
        header: () => <span>{t("managerUser.description")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("acquired_date", {
        cell: (info) => (
          <Text>
            {info.getValue() ? formatDate(new Date(info.getValue())) : "----"}
          </Text>
        ),
        header: () => <span>{t("managerUser.awarded_on")}</span>,
        footer: (info) => info.column.id,
      }),
    ],

    []
  );

  return (
    <Box
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Box>
  );
}
