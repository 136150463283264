import { Box } from "@chakra-ui/react";

type PlaceholdeImageTypes = {
  name: string;
};

export default function PlaceholderImage({ name }: PlaceholdeImageTypes) {
  if (name === "house")
    return (
      <Box width="180px" margin="0 auto" marginBottom={4}>
        <style type="text/css">{`
            .st0{fill:#E6E6E6;}  
            .st1{fill:#3F3D56;}  
            .st2{fill: orange;}  
            .st3{fill:#FFFFFF;} 
            .brandable {fill: var(--chakra-colors-brand-primary) }
        `}</style>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 840.5 427.8"
          xmlSpace="preserve"
        >
          <ellipse className="st0" cx="180.9" cy="328.7" rx="29.1" ry="59.4" />
          <path
            className="st1"
            d="M183.6,425.9c-11.7-69.9-0.1-139.6,0-140.3l2.3,0.4c-0.1,0.7-11.6,70,0,139.5L183.6,425.9z"
          />
          <rect
            x="179"
            y="322.8"
            transform="matrix(0.8818 -0.4717 0.4717 0.8818 -129.8721 129.7383)"
            className="st1"
            width="29.8"
            height="2.3"
          />
          <rect
            x="165"
            y="316.1"
            transform="matrix(0.4719 -0.8816 0.8816 0.4719 -204.1104 321.2468)"
            className="st1"
            width="2.3"
            height="29.8"
          />
          <rect x="720.1" y="4" className="st1" width="47.7" height="165.1" />
          <polygon
            className="st1"
            points="799.2,138 683.2,0 450.4,2.1 309.2,139.7 312,141.5 311,141.5 311,427.7 798.9,427.7 798.9,141.5 "
          />
          <polygon
            className="brandable"
            points="683.4,0.3 538.5,170.2 538.5,427.7 798.9,427.7 798.9,137.9 "
          />
          <rect x="643" y="285.3" className="st1" width="53.2" height="47" />
          <rect x="643" y="203.9" className="st1" width="53.2" height="46.3" />
          <rect x="643" y="285.3" className="st3" width="53.2" height="47" />
          <rect x="643" y="203.9" className="st3" width="53.2" height="46.3" />
          <path className="st0" d="M502.3,419.3c0,0,1.1-23.9,24.5-21.1" />
          <circle className="brandable" cx="495.7" cy="384.9" r="11.7" />
          <rect x="493.8" y="404.6" className="st0" width="3.3" height="23.1" />
          <ellipse
            className="brandable"
            cx="111"
            cy="237.1"
            rx="56.9"
            ry="116.1"
          />
          <path
            className="st1"
            d="M117.3,427c-22.8-136.6-0.2-272.7,0-274l2.3,0.4c-0.2,1.4-22.7,137.1,0,273.3L117.3,427z"
          />
          <rect
            x="107.2"
            y="226.7"
            transform="matrix(0.8818 -0.4717 0.4717 0.8818 -91.3529 91.2671)"
            className="st1"
            width="58.4"
            height="2.3"
          />
          <rect
            x="80.9"
            y="212.5"
            transform="matrix(0.4719 -0.8817 0.8817 0.4719 -169.7276 199.984)"
            className="st1"
            width="2.3"
            height="58.4"
          />
          <ellipse
            className="brandable"
            cx="245.8"
            cy="167.2"
            rx="77.9"
            ry="158.9"
          />
          <path
            className="st1"
            d="M254.8,427c-31.1-186.8-0.3-373,0-374.9l2.3,0.4c-0.3,1.9-31.1,187.6,0,374.1L254.8,427z"
          />
          <rect
            x="240.6"
            y="153.4"
            transform="matrix(0.8818 -0.4717 0.4717 0.8818 -39.7405 150.6005)"
            className="st1"
            width="79.9"
            height="2.3"
          />
          <rect
            x="205.1"
            y="133.5"
            transform="matrix(0.4719 -0.8817 0.8817 0.4719 -44.0204 273.4277)"
            className="st1"
            width="2.3"
            height="79.9"
          />
          <rect y="425.8" className="st1" width="840.5" height="2" />
          <rect x="406.6" y="272.8" className="st1" width="53.2" height="47" />
          <rect
            x="406.6"
            y="191.4"
            className="st1"
            width="53.2"
            height="46.3"
          />
          <rect x="406.6" y="272.8" className="st3" width="53.2" height="47" />
          <rect
            x="406.6"
            y="191.4"
            className="st3"
            width="53.2"
            height="46.3"
          />
        </svg>
      </Box>
    );

  if (name === "park")
    return (
      <Box width="180px" margin="0 auto" marginBottom={4}>
        <style type="text/css">{` .st0{fill:#3F3D56;}  .st2{opacity:0.2;enable-background:new    ;}  .st3{fill:#E6E6E6;}  .st4{fill:#FFFFFF;}  .st5{fill:#9E616A;}  .st6{fill:#2F2E41;} .brandable {fill: var(--chakra-colors-brand-primary) }`}</style>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 885.9 622.8"
          xmlSpace="preserve"
        >
          <polygon
            className="st0"
            points="461.6,348.4 400.4,348.4 400.4,350.3 410,350.3 410,369.1 411.9,369.1 411.9,350.3 449.1,350.3 449.1,369.1 451.1,369.1 451.1,350.3 461.6,350.3 "
          />
          <polygon
            className="st0"
            points="484.3,300 468.5,300 468.5,315.8 475.8,315.8 475.8,369 477.8,369 477.8,315.8 484.3,315.8 "
          />
          <rect x="400.5" y="342.5" className="st0" width="61.2" height="2" />
          <rect x="400.5" y="337.5" className="st0" width="61.2" height="2" />
          <rect x="400.5" y="332.6" className="st0" width="61.2" height="2" />
          <path
            className="st0"
            d="M618.5,374.8h-2c0-93.6-76.1-169.7-169.7-169.7c-93.6,0-169.7,76.1-169.7,169.7h-2c0-94.7,77-171.7,171.7-171.7 C541.5,203.1,618.5,280.1,618.5,374.8z"
          />
          <circle className="brandable" cx="161.4" cy="161.4" r="161.4" />
          <path
            className="st2"
            d="M281.4,53.6c35,81.9-3,176.8-84.9,211.8c-63.8,27.3-137.8,10.7-183.9-41c35,81.9,129.9,120,211.8,85 s120-129.9,85-211.8C302.4,81.5,293,66.6,281.4,53.6z"
          />
          <polygon
            className="st0"
            points="161.8,161.4 162.2,161.4 170.2,618.8 153.4,618.8 "
          />
          <rect
            x="160.9"
            y="410"
            transform="matrix(0.8849 -0.4659 0.4659 0.8849 -172.6117 129.6525)"
            className="st0"
            width="30.1"
            height="8"
          />
          <circle className="brandable" cx="755.3" cy="229.8" r="130.6" />
          <path
            className="st2"
            d="M852.4,142.6c28.4,66.3-2.4,143.1-68.8,171.4c-51.6,22.1-111.5,8.7-148.9-33.2c28.4,66.3,105.1,97.1,171.4,68.8 s97.1-105.1,68.8-171.4C869.4,165.1,861.8,153.1,852.4,142.6z"
          />
          <polygon
            className="st0"
            points="755.6,229.8 756,229.8 762.5,600.1 748.8,600.1 "
          />
          <rect
            x="754.9"
            y="431.1"
            transform="matrix(0.8849 -0.4659 0.4659 0.8849 -113.9994 407.3751)"
            className="st0"
            width="24.4"
            height="6.5"
          />
          <circle className="brandable" cx="369.9" cy="321.7" r="20.6" />
          <path
            className="st2"
            d="M385.2,308c4.5,10.4-0.4,22.5-10.8,27c-8.1,3.5-17.6,1.4-23.4-5.2c4.5,10.4,16.5,15.3,27,10.8 c10.4-4.5,15.3-16.5,10.8-27C387.9,311.5,386.7,309.6,385.2,308z"
          />
          <polygon
            className="st0"
            points="370,321.7 370,321.7 371,380 368.9,380 "
          />
          <rect
            x="369.9"
            y="353.4"
            transform="matrix(0.8849 -0.4659 0.4659 0.8849 -122.06 213.9413)"
            className="st0"
            width="3.8"
            height="1"
          />
          <ellipse
            transform="matrix(0.1602 -0.9871 0.9871 0.1602 218.0509 767.1741)"
            className="brandable"
            cx="559.9"
            cy="255.4"
            rx="52.6"
            ry="52.6"
          />
          <path
            className="st2"
            d="M599,220.3c11.4,26.7-1,57.7-27.7,69.1c-20.8,8.9-44.9,3.5-60-13.4c11.4,26.7,42.4,39.1,69.1,27.7 s39.1-42.4,27.7-69.1C605.9,229.4,602.8,224.5,599,220.3L599,220.3z"
          />
          <polygon
            className="st0"
            points="560,255.4 560.2,255.4 562.8,404.7 557.3,404.7 "
          />
          <rect
            x="559.7"
            y="336.6"
            transform="matrix(0.8849 -0.4659 0.4659 0.8849 -92.381 301.9491)"
            className="st0"
            width="9.8"
            height="2.6"
          />
          <path className="st0" d="M197.5,596c0,0,0.7-14.3,14.7-12.6" />
          <circle className="brandable" cx="193.6" cy="575.5" r="7" />
          <rect x="192.4" y="587.2" className="st0" width="2" height="13.8" />
          <path className="st0" d="M246.9,563.5c0,0,0.7-14.3,14.7-12.6" />
          <circle className="brandable" cx="242.9" cy="542.9" r="7" />
          <rect x="241.8" y="554.7" className="st0" width="2" height="13.8" />
          <path className="st0" d="M395.6,453c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="392.5" cy="436.6" r="5.6" />
          <rect x="391.6" y="446" className="st0" width="1.6" height="11" />
          <path className="st0" d="M612.7,417.5c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="609.6" cy="401.1" r="5.6" />
          <rect x="608.7" y="410.5" className="st0" width="1.6" height="11" />
          <path className="st0" d="M405.5,482.6c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="402.3" cy="466.2" r="5.6" />
          <rect x="401.4" y="475.6" className="st0" width="1.6" height="11" />
          <path className="st0" d="M381.8,504.3c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="378.7" cy="488" r="5.6" />
          <rect x="377.8" y="497.3" className="st0" width="1.6" height="11" />
          <path className="st0" d="M646.3,436.2c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="643.1" cy="419.9" r="5.6" />
          <rect x="642.2" y="429.2" className="st0" width="1.6" height="11" />
          <path className="st0" d="M326.5,460.9c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="323.4" cy="444.5" r="5.6" />
          <rect x="322.5" y="453.9" className="st0" width="1.6" height="11" />
          <path className="st0" d="M583.1,404.6c0,0,0.5-11.3,11.6-10" />
          <circle className="brandable" cx="580" cy="388.3" r="5.6" />
          <rect x="579.1" y="397.6" className="st0" width="1.6" height="11" />
          <path className="st0" d="M111.7,599c0,0,0.7-14.3,14.7-12.6" />
          <circle className="brandable" cx="107.7" cy="578.4" r="7" />
          <rect x="106.6" y="590.2" className="st0" width="2" height="13.8" />
          <path className="st0" d="M791.6,595.1c0,0,0.7-14.3,14.7-12.6" />
          <circle className="brandable" cx="787.7" cy="574.5" r="7" />
          <rect x="786.5" y="586.2" className="st0" width="2" height="13.8" />
          <path className="st0" d="M734.4,561.5c0,0,0.7-14.3,14.7-12.6" />
          <circle className="brandable" cx="730.4" cy="540.9" r="7" />
          <rect x="729.3" y="552.7" className="st0" width="2" height="13.8" />
          <path className="st0" d="M726.5,611.8c0,0,0.7-14.3,14.7-12.6" />
          <circle className="brandable" cx="722.5" cy="591.2" r="7" />
          <rect x="721.4" y="603" className="st0" width="2" height="13.8" />
          <path
            className="st3"
            d="M123,622.5c0,0,310.9-81.9,307.9-165.8c-5.4-23.2-42.9-29.1-42.9-29.1s-88.8-11.8-40.5-40.5 s180.6-21.7,180.6-21.7s-136.2,21.7-76,33.6c48.4,3,110.5,15.8,110.5,15.8s126.3,26.6,144.1,91.8c17.8,65.1,3,115.5,3,115.5 L123,622.5z"
          />
          <path
            className="st4"
            d="M461.6,622.8l-1.2-1.6c1.6-1.2,3.3-2.3,4.9-3.5l1.2,1.6C464.9,620.5,463.3,621.6,461.6,622.8z"
          />
          <path
            className="st4"
            d="M476.1,612.1l-1.2-1.6c3.2-2.4,6.4-4.9,9.5-7.3l1.2,1.6C482.6,607.2,479.4,609.7,476.1,612.1z M494.9,597.2 l-1.3-1.5c3.1-2.6,6.2-5.2,9.1-7.8l1.3,1.5C501.2,592,498.1,594.6,494.9,597.2z M513,581.4l-1.4-1.5c3-2.8,5.9-5.6,8.6-8.3l1.4,1.4 C518.9,575.7,516,578.5,513,581.4z M529.8,564.2l-1.5-1.3c2.7-3.1,5.3-6.1,7.7-9.1l1.6,1.2C535.2,558,532.6,561.1,529.8,564.2z M544.8,545.4l-1.7-1.1c2.3-3.4,4.4-6.8,6.2-10.1l1.8,1C549.3,538.4,547.1,541.9,544.8,545.4z M556.3,524.1l-1.9-0.7 c1.5-3.7,2.6-7.4,3.4-11.3l2,0.4C558.9,516.5,557.8,520.4,556.3,524.1L556.3,524.1z M559,500.4c-0.1-3.9-0.6-7.8-1.6-11.5l1.9-0.5 c1.1,3.9,1.6,7.9,1.7,12L559,500.4z M553,478.1c-1.9-3.4-4.1-6.7-6.7-9.7l1.5-1.3c2.6,3.1,4.9,6.4,6.9,10L553,478.1z M538.1,460 c-3-2.6-6.1-5.1-9.3-7.3l1.2-1.6c3.3,2.3,6.5,4.8,9.5,7.5L538.1,460z M518.7,446.3c-3.3-1.9-6.8-3.8-10.5-5.7l0.9-1.8 c3.7,1.9,7.3,3.8,10.6,5.8L518.7,446.3z M497.4,435.4c-3.5-1.5-7.2-3.1-11-4.6l0.7-1.9c3.9,1.5,7.6,3.1,11.1,4.7L497.4,435.4z M475.2,426.6c-3.6-1.3-7.4-2.6-11.3-3.9l0.6-1.9c4,1.3,7.8,2.7,11.4,4L475.2,426.6z M452.4,418.9c-3.5-1.1-7.2-2.3-11.5-3.5 l0.6-1.9c4.2,1.3,8,2.4,11.5,3.5L452.4,418.9z M429.5,411.9c-4-1.2-7.9-2.4-11.5-3.5l0.6-1.9c3.6,1.1,7.5,2.3,11.5,3.5L429.5,411.9z M406.5,404.7c-3.8-1.3-7.6-2.8-11.3-4.5l0.9-1.8c3.6,1.7,7.3,3.2,11,4.4L406.5,404.7z M391.4,391.6l-1.4-1.4 c1.8-1.9,5.4-3.7,11-5.5l0.6,1.9C396.4,388.3,392.9,390,391.4,391.6L391.4,391.6z M413,383.3l-0.5-1.9c3.5-0.9,7.4-1.8,11.7-2.7 l0.4,2C420.4,381.6,416.5,382.5,413,383.3L413,383.3z M436.4,378.3l-0.4-2c3.7-0.7,7.7-1.4,11.8-2.2l0.3,2 C444.1,376.9,440.1,377.6,436.4,378.3L436.4,378.3z M460,374.1l-0.3-2c3.8-0.6,7.7-1.3,11.8-1.9l0.3,2 C467.7,372.8,463.8,373.5,460,374.1z"
          />
          <path
            className="st4"
            d="M483.9,370.3l-0.3-2c1.9-0.3,3.9-0.6,5.9-0.9l0.3,2C487.8,369.7,485.8,370,483.9,370.3z"
          />
          <path
            className="st0"
            d="M528.1,167.1l9.1-7.3c-7.1-0.8-10,3.1-11.1,6.1c-5.5-2.3-11.5,0.7-11.5,0.7l18.2,6.6 C531.8,170.8,530.2,168.7,528.1,167.1z"
          />
          <path
            className="st0"
            d="M359.4,223.4l9.1-7.3c-7.1-0.8-10,3.1-11.1,6.1c-5.5-2.3-11.5,0.7-11.5,0.7l18.2,6.6 C363.1,227.1,361.5,224.9,359.4,223.4z"
          />
          <path
            className="st0"
            d="M403.8,106.9l9.1-7.3c-7.1-0.8-10,3.1-11.1,6.1c-5.5-2.3-11.5,0.7-11.5,0.7l18.2,6.6 C407.5,110.6,405.9,108.5,403.8,106.9z"
          />
          <path
            className="st5"
            d="M502.5,430.4c0,0-7.9,0-8.7,4.8s-2.2,57.3-1.3,63.8s0.9,5.2,0.9,5.2s-1.7,12.5,2.2,13s3.9-14.3,3.9-14.3 l3.1-53.3L502.5,430.4z"
          />
          <path
            className="st5"
            d="M536.6,430.4c0,0,7.9,0,8.7,4.8s2.2,57.3,1.3,63.8c-0.9,6.6-0.9,5.2-0.9,5.2s1.7,12.5-2.2,13 c-3.9,0.5-3.9-14.3-3.9-14.3l-3.1-53.3L536.6,430.4z"
          />
          <path
            className="st6"
            d="M501.2,613.2h-3.5c-4.3,0-7.9,3.5-7.9,7.9v0v0h21.4V611h-10.1V613.2z"
          />
          <path
            className="st6"
            d="M534.9,613.2h3.5c4.3,0,7.9,3.5,7.9,7.9l0,0v0h-21.4V611h10.1L534.9,613.2z"
          />
          <circle className="st5" cx="516.1" cy="409" r="11.4" />
          <path
            className="st5"
            d="M513.5,415.1c0,0-1.3,14.4-3.1,15.7c-1.7,1.3,18.4-0.9,18.4-0.9s-7-10.5-5.7-14 C524.4,412.5,513.5,415.1,513.5,415.1z"
          />
          <path
            className="st6"
            d="M539.3,430.4c0,0-24-10.1-39.8,0c0,0-3.9,10.1-0.9,15.7s7.9,19.2,3.1,28.4s0.4,5.2,0.4,5.2s-6.1,24.5-7,43.7 s2.6,88.3,5.2,88.7s10.9,1.3,11.8,0c0.9-1.3,7-92.7,7-92.7s2.6,93.6,5.2,93.6s9.6,0,10.9-2.2c1.3-2.2,12.2-86.6,8.3-104 c-3.9-17.5-6.6-26.2-6.6-26.2s4.8-3.1,3.5-4.8s-7.9-20.5-4.8-23.6S539.3,430.4,539.3,430.4z"
          />
          <circle className="st6" cx="523.3" cy="402.2" r="18.4" />
          <path
            className="st6"
            d="M534.8,393c-6.4-1.5-10.5-7.8-9.3-14.3c-0.1,0.2-0.1,0.4-0.1,0.6c-1.6,6.6,2.6,13.3,9.2,14.9 c6.6,1.6,13.3-2.6,14.9-9.2c0-0.2,0.1-0.4,0.1-0.6C547.7,390.7,541.2,394.5,534.8,393z"
          />
          <circle className="st6" cx="535.5" cy="386.4" r="9" />
        </svg>
      </Box>
    );

  if (name === "users")
    return (
      <Box width="180px" margin="0 auto" marginBottom={4}>
        <style>
          {`
                .st0{fill:#E6E6E6}
                .st1{fill:#FFFFFF}
                .st2{fill: orange}
                .st3{fill:#FFB9B9}
                .st4{fill:#2F2E41}
                .st5{fill:#9F616A}
                .st6{fill:#FBBEBE}
                .st7{fill:#FFB8B8}
                .st8{fill:#575A89}
                .st9{fill:#D0CDE1}
                .emptyworkshop {enable-background: 'new 0 0 817.2 523.6'}
                .brandable {fill: var(--chakra-colors-brand-primary) }
                `}
        </style>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 817.2 523.6"
          className="emptyworkshop"
        >
          <rect
            x="0.3"
            y="0.5"
            className="st0"
            width="816.9"
            height="523.2"
          ></rect>
          <rect
            x="23.7"
            y="66.1"
            className="st1"
            width="770.2"
            height="426.6"
          ></rect>
          <rect className="brandable" width="816.9" height="34.7"></rect>
          <circle className="st1" cx="25.8" cy="17.7" r="6.4"></circle>
          <circle className="st1" cx="50.2" cy="17.7" r="6.4"></circle>
          <circle className="st1" cx="74.6" cy="17.7" r="6.4"></circle>
          <rect
            x="118.5"
            y="101.2"
            className="st0"
            width="150.6"
            height="163.4"
          ></rect>
          <rect
            x="333.5"
            y="101.2"
            className="st0"
            width="150.6"
            height="163.4"
          ></rect>
          <rect
            x="548.5"
            y="101.2"
            className="st0"
            width="150.6"
            height="163.4"
          ></rect>
          <rect
            x="118.5"
            y="294.2"
            className="st0"
            width="150.6"
            height="163.4"
          ></rect>
          <rect
            x="333.5"
            y="294.2"
            className="st0"
            width="150.6"
            height="163.4"
          ></rect>
          <rect
            x="548.5"
            y="294.2"
            className="st0"
            width="150.6"
            height="163.4"
          ></rect>
          <circle className="st3" cx="177" cy="174.5" r="25.5"></circle>
          <path
            className="st3"
            d="M167,194c0,0,3.2,15.1,3.2,16.7s15.1,8.8,15.1,8.8l13.5-2.4l4.8-14.3c0,0-8-11.9-8-16.7L167,194z"
          ></path>
          <path
            className="st4"
            d="M154.9,154c0,0,8.1-17.5,23.6-13.5s24.3,10.1,24.9,16.2s-0.3,15.2-0.3,15.2s-1.7-12.5-12.5-9.8 s-27.6,0.7-27.6,0.7l-2.7,24.3c0,0-3-4.4-6.4-1.7S144.1,159.4,154.9,154z"
          ></path>
          <path
            className="brandable"
            d="M257.7,258.5l-6.4-28.6c-3.2-9.5-11.1-17.5-11.1-17.5L201,198.5h0c-0.2,7.1-4.9,13.3-11.7,15.4 c-11.9,4-20.2-9.4-20.2-9.4L136,229.8c0,0-10.3,8.8-9.5,25.5l-1.1,9.3h135.5L257.7,258.5z"
          ></path>
          <ellipse
            className="st4"
            cx="391.4"
            cy="176"
            rx="34.6"
            ry="36.7"
          ></ellipse>
          <circle className="st4" cx="422.1" cy="213.3" r="18.2"></circle>
          <polygon
            className="st5"
            points="409.4,189 419.1,214.3 394.5,224.7 392.3,219.8 384.7,202.6 "
          ></polygon>
          <circle className="st5" cx="389.9" cy="183.8" r="21.8"></circle>
          <path
            className="st4"
            d="M372.1,181.4c13.7-9.1,17.8-11.3,33.4-7.8l0.9-22.9l-40.5,8.5L372.1,181.4z"
          ></path>
          <path
            className="brandable"
            d="M460,233.1l-0.6,0.1c0,0,0,0,0-0.1c-2.4-2.4-13.7-6.7-22.2-9.7h0c-5.4-1.9-9.6-3.3-9.6-3.3l-10.2-10.5 c-2.6,2.9-8,5.1-13.2,6.7c-4,1.2-8,2.2-12.1,2.9h0l-2.1,6.8c0,0-24,18.2-26,20.1h0v0c-0.2,0.2-0.2,0.5-0.2,0.8v0 c-1.2,5.8-1.8,11.7-1.9,17.6h104.6v-6.1C466.5,250,460,233.1,460,233.1z"
          ></path>
          <path
            className="st4"
            d="M652.5,264.6h-51.7l-2.8-28.1l-0.2-1.7l-0.3-3.4l-1.4-14.2l-1.8,15.2l-3.8,32.2H581l0.3-10l0.3-10.1l0.2-4.1 l1.9-55.6c-0.1-8.8,2.6-17.4,7.9-24.5c6-7.8,15.2-12.2,25-12.1c10,0,19.4,4.6,25.4,12.5c5.5,7.1,8.4,15.9,8.3,24.9l0.9,33.7 L652.5,264.6z"
          ></path>
          <circle className="st6" cx="616.7" cy="181.8" r="22.8"></circle>
          <path
            className="st6"
            d="M605.3,198.1c0,0,6.5,21.9,0,28.4s-8.1,8.9-8.1,8.9l18.7,25.2l32.5-39.8c0,0-22.8-6.5-17.1-24.4L605.3,198.1z"
          ></path>
          <polygon
            className="st6"
            points="675.5,264.6 656.3,264.6 656,261.3 655.7,258.3 664.5,257.1 675.2,255.8 675.3,259.2 "
          ></polygon>
          <path
            className="brandable"
            d="M676.8,259.1c0,0-3.3-20.3-5.7-28.4s-4.9-8.1-4.9-8.1h0l0,0c-2.9-2.9-9.8-3.4-15-3.3c-3.6,0-6.4,0.4-6.4,0.4 l-29,30.6l-14.6-19.4v0c-1.3,0.1-2.6,0.3-3.9,0.7c-1.1,0.3-2.2,0.6-3.2,1c-4.5,1.9-8.7,4.6-12.3,7.9h0c-2.1,1.9-3.3,3.3-3.3,3.3 c-3.2,6.7-5.6,13.7-7.2,21h92.9l0.2-4.2v0L676.8,259.1z"
          ></path>
          <path
            className="st4"
            d="M597.6,178.2c14.7-7.9,20.7-7.6,39,0l4.9-11.9c0-4.7-3.8-8.4-8.4-8.4h-32.7c-4.7,0-8.4,3.8-8.4,8.4L597.6,178.2 z"
          ></path>
          <path
            className="st4"
            d="M191.8,333.7c-1.2-0.1-2.4,0.2-3.5,0.8c-1,0.9-1.8,1.9-2.4,3.1c-2.5,4.5-6,8.4-10.1,11.4c-3,2.2-6.8,4.9-6.3,9 c0.3,1.3,0.8,2.6,1.5,3.8c2.8,5.6,10.3,9.9,9.6,16.3c2.8-4.6-0.9-6.9,1.8-11.5c1.3-2.2,3.6-4.7,5.6-3.3c0.7,0.5,1.1,1.3,1.8,1.7 c1.6,1,3.4-0.9,4.9-2.2c5-4.5,12.2-3.3,18.4-1.9c3,0.7,6.2,1.5,7.9,4.4c2.3,3.7-2.2,7.7-3.3,11.9c-0.3,1.2,0.4,2.5,1.6,2.8 c0.3,0.1,0.5,0.1,0.8,0.1c1.9-0.2,4.1-0.3,4.2-1.2c2.4,0.1,5.3-0.2,6.3-2.6c0.3-1,0.5-2,0.5-3c0.4-4.1,2.1-7.9,3.3-11.8 s1.7-8.5-0.3-12c-0.7-1.2-1.6-2.2-2.6-3.1C220.4,335.5,205.8,333.7,191.8,333.7z"
          ></path>
          <path
            className="st5"
            d="M183,386.2c0,0,2.4,20.2-6.5,21.8s8.1,29,8.1,29l40.3,4.8l-9.7-32.3c0,0-6.5-2.4-2.4-18.6S183,386.2,183,386.2z"
          ></path>
          <circle className="st5" cx="201.2" cy="375.3" r="24.2"></circle>
          <path
            className="brandable"
            d="M159.5,457.6c4.3-16.8,10.2-39.5,10.2-39.5s2.3,0.5,5.9,1c5.3,0.8,10.6,1.3,16,1.5c6.8,0.2,14-0.3,19.6-2.2 c2.1-0.7,4.1-1.7,5.8-3.1c0.7-0.6,1.4-1.3,1.9-2c9.7-12.9,13.7,36.3,13.7,36.3l0.8,8H159.5z"
          ></path>
          <path
            className="st7"
            d="M236.8,509.6c-0.3,0-0.6,0-1,0l0.5-0.9C236.5,509,236.7,509.3,236.8,509.6z"
          ></path>
          <path
            className="st4"
            d="M193,340.1c-1-0.1-2,0.1-2.8,0.5c-0.8,0.6-1.4,1.3-1.9,2.2c-2.2,3.2-5,6-8.2,8.1c-2.4,1.6-5.6,3.5-5.1,6.4 c0.2,1,0.6,1.9,1.2,2.7c2.3,4,3.2,8.6,2.6,13.2l6.7-9.8c1.1-1.6,2.9-3.3,4.5-2.3c0.5,0.3,0.9,0.9,1.5,1.2c1.3,0.7,2.8-0.6,4-1.5 c4.1-3.2,9.9-2.4,15-1.4c2.4,0.5,5,1.1,6.5,3.1c2.4,3.3-0.1,8.1,1.3,11.9c0.8-0.5,1.3-1.4,1.5-2.3c1.9,0.1,4.3-0.1,5.1-1.9 c0.3-0.7,0.4-1.4,0.4-2.1c0.3-2.9,1.7-5.6,2.7-8.4s1.4-6-0.2-8.5c-0.6-0.8-1.3-1.6-2.1-2.2C216.3,341.3,204.4,340,193,340.1z"
          ></path>
          <path
            className="st4"
            d="M190.7,418.9c-5.5-6.3-8.8-15.5-9-15.9v0c0,0-8.8-5-11.2-0.2s-25,16.9-27.4,16.9c0,0,0,0-0.1,0 c-1,0.3-13,4.7-16.1,21c-0.8,5.6-1.3,11.2-1.3,16.9h65.8c0.7-7.1,1.2-13.8,1.4-19.6c0.3-8,0-14.3-1.2-17.5 C191.4,419.9,191.1,419.4,190.7,418.9z"
          ></path>
          <path
            className="st4"
            d="M247.2,423.4c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.3-0.8-0.5-1.2c0,0,0,0,0,0c-4.7-7.2-34.6-17.2-34.6-17.2 l-1.1,3.9c0,0,0.1,3.8,0.3,9.9c0.2,5.7,0.4,13.5,0.7,22c0.2,5.5,0.3,11.4,0.4,17.3h44.8C254.6,446,251.3,434.6,247.2,423.4z"
          ></path>
          <path
            className="st8"
            d="M666.7,457.6l3.4-16.8l3.2-1.3l0,0c0.3,0.1,3.8,1.6,3.8,7.2c0,1.1-0.2,5.2-0.5,11L666.7,457.6z"
          ></path>
          <circle className="st7" cx="630.7" cy="371.2" r="27.9"></circle>
          <polygon
            className="st7"
            points="648.1,387.4 645.7,435 609.8,414.1 614.4,387.4 "
          ></polygon>
          <polygon
            className="brandable"
            points="658.5,437.3 656.2,457.6 637.3,457.6 616.8,418.2 611,407.1 608.2,405.3 611.4,404.6 614,406.4 642.3,426.9 646.8,413.3 646.9,412.9 648.1,409.5 "
          ></polygon>
          <path
            className="st4"
            d="M613.8,343.9c0,0,3.5-17.4,23.2-13.9l-2.3,1.2c0,0,18.6-2.3,16.2,8.1c0,0,15.1,9.3,11.6,19.7 c-2,6.1-3.6,12.3-4.6,18.6l-3.5-3.5c0,0-3.5-23.2-24.4-20.9c-15.7,1.7-22.1,9.1-23.7,14.5c-0.8,2.5-1.7,4.9-2.9,7.3l-1.3,0.3 C602.2,375.3,595.3,349.7,613.8,343.9z"
          ></path>
          <path
            className="st4"
            d="M673.3,439.5L673.3,439.5l-14.8-13.8v-9.3l-11.4-9l-0.2,5.5l-1.2,37.2l-19-26.1L614,406.4l-2.3-3.2l0.6-3 l-7.1,8l-25.7,19.2l-2.2,1.7c-2.5,2.5-4.9,5.2-7,8.1c-0.7,1-3.8,9.2-7.9,20.2h114.2c0.3-5.8,0.5-9.8,0.5-11 C677.1,441.1,673.6,439.6,673.3,439.5z"
          ></path>
          <path
            className="st4"
            d="M406.5,340.6L406.5,340.6c-18.9,0-34.2,17-34.2,37.9v26.6h8.2l4.7-9.9l-1.2,9.9h52.6l4.3-9l-1.1,9h5.9v-20.9 C445.8,360.1,428.2,340.6,406.5,340.6z"
          ></path>
          <path
            className="st9"
            d="M378.9,457.6h-22.2l-0.2-18.1c0-0.2,0-0.4,0-0.6l4.5-5l0.1-0.1l17,10.8L378.9,457.6z"
          ></path>
          <circle className="st6" cx="403.5" cy="377.1" r="24.3"></circle>
          <path
            className="st6"
            d="M389.5,391.7c0,0,7.1,37,3.9,40.9s34.4-7.1,34.4-7.1s-12.3-25.3-5.8-39.6L389.5,391.7z"
          ></path>
          <path
            className="brandable"
            d="M455.5,416.7L455.5,416.7c-0.6-1.2-1.6-2.2-2.8-2.7c-4.8-2-8.9-3.5-12.3-4.7c-6-2.1-12.5-1.8-18.3,0.6 c-0.6,0.2-1.1,0.5-1.7,0.8c0,0-23.8,4.5-26.6,4c-0.2,0-0.4-0.1-0.6-0.1c-0.4-0.1-0.9-0.1-1.3-0.1c-5.5-0.3-11,1.1-15.8,3.9 c-7.8,4.5-19.4,12.6-19.6,20.4l-0.2,0.2l0.2,18.7h104.4c0.2-17.9,0.3-31.4,0.3-32.3C461.2,422.5,455.5,416.7,455.5,416.7z"
          ></path>
          <path
            className="st4"
            d="M416.2,351c-3.3-4.5-8.6-7.1-14.1-7.2h-0.7c-12.9,0-23.4,11.7-23.4,26.1v0h4.3l0.7-5.3l1,5.3h25.7l2.2-4.5 l-0.5,4.5h5.1c2.4,11.7-1,23.4-10.2,35.2h8.6l4.3-9l-1.1,9h16.4l3.2-20.7C437.7,368.9,428.7,355.7,416.2,351z"
          ></path>
          <circle className="st4" cx="420.9" cy="340.4" r="12.8"></circle>
        </svg>
      </Box>
    );

  if (name === "bench")
    return (
      <Box width="180px" margin="0 auto" marginBottom={4}>
        <style>{` .brandable {fill: var(--chakra-colors-brand-primary)} `}</style>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 997.86122 450.8081"
        >
          <title>bench and trees</title>
          <rect
            x="871.99152"
            y="181.55804"
            width="30.15944"
            height="104.39806"
            fill="#f2f2f2"
          />
          <polygon
            points="922.068 266.317 848.715 179.052 701.475 180.398 612.156 267.396 613.961 268.556 613.316 268.556 613.316 449.513 921.871 449.513 921.871 268.556 922.068 266.317"
            fill="#f2f2f2"
          />
          <polygon
            points="848.792 179.238 757.154 286.674 757.154 449.513 921.871 449.513 921.871 266.236 848.792 179.238"
            fill="#e6e6e6"
          />
          <rect
            x="823.27242"
            y="359.46083"
            width="33.6394"
            height="29.73333"
            fill="#3f3d56"
          />
          <rect
            x="823.27242"
            y="307.99568"
            width="33.6394"
            height="29.26181"
            fill="#3f3d56"
          />
          <rect
            x="823.27242"
            y="359.46083"
            width="33.6394"
            height="29.73333"
            fill="#fff"
          />
          <rect
            x="823.27242"
            y="307.99568"
            width="33.6394"
            height="29.26181"
            fill="#fff"
          />
          <rect
            x="673.77661"
            y="351.57128"
            width="33.6394"
            height="29.73333"
            fill="#fff"
          />
          <rect
            x="673.77661"
            y="300.10613"
            width="33.6394"
            height="29.26181"
            fill="#fff"
          />
          <rect
            x="633.99152"
            y="181.55804"
            width="30.15944"
            height="104.39806"
            fill="#f2f2f2"
          />
          <polygon
            points="684.068 266.317 610.715 179.052 463.475 180.398 374.156 267.396 375.961 268.556 375.316 268.556 375.316 449.513 683.871 449.513 683.871 268.556 684.068 266.317"
            fill="#f2f2f2"
          />
          <polygon
            points="610.792 179.238 519.154 286.674 519.154 449.513 683.871 449.513 683.871 266.236 610.792 179.238"
            fill="#e6e6e6"
          />
          <rect
            x="585.27242"
            y="359.46083"
            width="33.6394"
            height="29.73333"
            fill="#3f3d56"
          />
          <rect
            x="585.27242"
            y="307.99568"
            width="33.6394"
            height="29.26181"
            fill="#3f3d56"
          />
          <rect
            x="585.27242"
            y="359.46083"
            width="33.6394"
            height="29.73333"
            fill="#fff"
          />
          <rect
            x="585.27242"
            y="307.99568"
            width="33.6394"
            height="29.26181"
            fill="#fff"
          />
          <rect
            x="435.77661"
            y="351.57128"
            width="33.6394"
            height="29.73333"
            fill="#fff"
          />
          <rect
            x="435.77661"
            y="300.10613"
            width="33.6394"
            height="29.26181"
            fill="#fff"
          />
          <rect
            x="380.1536"
            y="91.46021"
            width="40.30032"
            height="139.50112"
            fill="#f2f2f2"
          />
          <polygon
            points="447.068 204.718 349.051 88.112 152.302 89.91 32.951 206.161 35.362 207.711 34.501 207.711 34.501 449.513 446.804 449.513 446.804 207.711 447.068 204.718"
            fill="#f2f2f2"
          />
          <polygon
            points="349.153 88.36 226.702 231.921 226.702 449.513 446.804 449.513 446.804 204.611 349.153 88.36"
            fill="#e6e6e6"
          />
          <rect
            x="315.05306"
            y="329.18147"
            width="44.95039"
            height="39.73094"
            fill="#3f3d56"
          />
          <rect
            x="315.05306"
            y="260.41156"
            width="44.95039"
            height="39.10088"
            fill="#3f3d56"
          />
          <rect
            x="315.05306"
            y="329.18147"
            width="44.95039"
            height="39.73094"
            fill="#fff"
          />
          <rect
            x="315.05306"
            y="260.41156"
            width="44.95039"
            height="39.10088"
            fill="#fff"
          />
          <rect
            x="115.29041"
            y="318.63912"
            width="44.95039"
            height="39.73094"
            fill="#fff"
          />
          <rect
            x="115.29041"
            y="249.8692"
            width="44.95039"
            height="39.10088"
            fill="#fff"
          />
          <rect y="448.61997" width="963.95079" height="2" fill="#3f3d56" />
          <ellipse
            cx="151.87223"
            cy="352.47204"
            rx="29.09932"
            ry="59.37437"
            fill="#3f3d56"
          />
          <path
            d="M255.62882,674.25425c-11.65458-69.92526-.11734-139.59789.00056-140.29293l2.267.384c-.11734.69167-11.58834,69.99825.00056,139.53164Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#6c63ff"
            className="brandable"
          />
          <rect
            x="251.0257"
            y="571.20214"
            width="29.84136"
            height="2.29972"
            transform="translate(-339.58156 -31.50095) rotate(-28.1416)"
            fill="#6c63ff"
            className="brandable"
          />
          <rect
            x="237.02319"
            y="564.48509"
            width="2.29972"
            height="29.84239"
            transform="translate(-486.12468 291.37147) rotate(-61.84204)"
            fill="#6c63ff"
            className="brandable"
          />
          <ellipse
            cx="81.9552"
            cy="260.90342"
            rx="56.91484"
            ry="116.12927"
            fill="#6c63ff"
            className="brandable"
          />
          <path
            d="M189.364,675.40405c-22.76459-136.58529-.22963-272.67316.00056-274.03181l2.267.384c-.22962,1.35528-22.69834,137.0771.00057,273.27052Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <rect
            x="179.27648"
            y="475.12522"
            width="58.36761"
            height="2.29972"
            transform="translate(-301.0624 -69.97216) rotate(-28.1416)"
            fill="#3f3d56"
          />
          <rect
            x="152.98936"
            y="460.88882"
            width="2.29972"
            height="58.36761"
            transform="translate(-451.74248 170.111) rotate(-61.84258)"
            fill="#3f3d56"
          />
          <ellipse
            cx="216.75351"
            cy="191.008"
            rx="77.88347"
            ry="158.91374"
            fill="#6c63ff"
            className="brandable"
          />
          <path
            d="M326.9161,675.40405c-31.1399-186.83717-.3144-372.9922.00056-374.85051l2.267.384c-.3144,1.85494-31.07366,187.64393.00056,374.08922Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <rect
            x="312.69421"
            y="401.83114"
            width="79.87126"
            height="2.29972"
            transform="translate(-249.45002 -10.63875) rotate(-28.1416)"
            fill="#3f3d56"
          />
          <rect
            x="277.14586"
            y="381.92603"
            width="2.29972"
            height="79.87126"
            transform="translate(-326.03583 243.55793) rotate(-61.84329)"
            fill="#3f3d56"
          />
          <ellipse
            cx="871.02934"
            cy="352.47204"
            rx="29.09932"
            ry="59.37437"
            fill="#3f3d56"
          />
          <path
            d="M969.41153,674.25425c11.65459-69.92526.11734-139.59789-.00056-140.29293l-2.267.384c.11733.69167,11.58833,69.99825-.00056,139.53164Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#6c63ff"
            className="brandable"
          />
          <rect
            x="957.94412"
            y="557.43132"
            width="2.29972"
            height="29.84136"
            transform="translate(-99.02545 923.51928) rotate(-61.8584)"
            fill="#6c63ff"
            className="brandable"
          />
          <rect
            x="971.94611"
            y="578.25643"
            width="29.84239"
            height="2.29972"
            transform="translate(-257.69773 309.6834) rotate(-28.15796)"
            fill="#6c63ff"
            className="brandable"
          />
          <ellipse
            cx="940.94638"
            cy="260.90342"
            rx="56.91484"
            ry="116.12927"
            fill="#6c63ff"
            className="brandable"
          />
          <path
            d="M1035.67632,675.40405c22.76459-136.58529.22962-272.67316-.00056-274.03181l-2.267.384c.22962,1.35528,22.69834,137.0771-.00056,273.27052Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <rect
            x="1015.43021"
            y="447.09128"
            width="2.29972"
            height="58.36761"
            transform="translate(16.06635 923.44761) rotate(-61.8584)"
            fill="#3f3d56"
          />
          <rect
            x="1041.71733"
            y="488.92276"
            width="58.36761"
            height="2.29972"
            transform="translate(-205.59609 338.75568) rotate(-28.15742)"
            fill="#3f3d56"
          />
          <ellipse
            cx="806.14806"
            cy="191.008"
            rx="77.88347"
            ry="158.91374"
            fill="#6c63ff"
            className="brandable"
          />
          <path
            d="M898.12426,675.40405c31.1399-186.83717.31439-372.9922-.00056-374.85051l-2.267.384c.3144,1.85494,31.07365,187.64393-.00056,374.08922Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <rect
            x="871.26065"
            y="363.04537"
            width="2.29972"
            height="79.87126"
            transform="translate(4.52428 757.59634) rotate(-61.8584)"
            fill="#3f3d56"
          />
          <rect
            x="906.809"
            y="420.7118"
            width="79.87126"
            height="2.29972"
            transform="translate(-188.10195 272.08136) rotate(-28.15671)"
            fill="#3f3d56"
          />
          <path
            d="M690.67376,326.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,690.67376,326.06186Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <path
            d="M465.67376,261.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,465.67376,261.06186Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <path
            d="M832.67376,232.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,832.67376,232.06186Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <path
            d="M851.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,819.26034,674.648h45.94952A13.99045,13.99045,0,0,0,851.26034,661.648Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <path
            d="M384.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,352.26034,674.648h45.94952A13.99045,13.99045,0,0,0,384.26034,661.648Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <path
            d="M623.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,591.26034,674.648h45.94952A13.99045,13.99045,0,0,0,623.26034,661.648Z"
            transform="translate(-101.06939 -224.59595)"
            fill="#3f3d56"
          />
          <polygon
            points="471.759 404.228 339.191 404.228 339.191 408.504 359.866 408.504 359.866 449.13 364.142 449.13 364.142 408.504 444.669 408.504 444.669 449.13 448.946 449.13 448.946 408.504 471.759 408.504 471.759 404.228"
            fill="#3f3d56"
          />
          <rect
            x="339.45191"
            y="391.43404"
            width="132.56808"
            height="4.27639"
            fill="#3f3d56"
          />
          <rect
            x="339.45191"
            y="380.74306"
            width="132.56808"
            height="4.27639"
            fill="#3f3d56"
          />
          <rect
            x="339.45191"
            y="370.05209"
            width="132.56808"
            height="4.27639"
            fill="#3f3d56"
          />
          <polygon
            points="678.759 404.228 546.191 404.228 546.191 408.504 566.866 408.504 566.866 449.13 571.142 449.13 571.142 408.504 651.669 408.504 651.669 449.13 655.946 449.13 655.946 408.504 678.759 408.504 678.759 404.228"
            fill="#3f3d56"
          />
          <rect
            x="546.45191"
            y="391.43404"
            width="132.56808"
            height="4.27639"
            fill="#3f3d56"
          />
          <rect
            x="546.45191"
            y="380.74306"
            width="132.56808"
            height="4.27639"
            fill="#3f3d56"
          />
          <rect
            x="546.45191"
            y="370.05209"
            width="132.56808"
            height="4.27639"
            fill="#3f3d56"
          />
        </svg>
      </Box>
    );

  return <>{name}</>;
}
