/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from "react-hook-form";
import { Select, Stack } from "@chakra-ui/react";

import FormLabelWithTooltip from "./FormLabelWithTooltip";

interface ControlledSelectProps {
  name: string;
  label: string;
  tooltipText?: string;
  isRequired?: boolean;
  options: { value: string; label: string }[];
  isDisabled?: boolean;
}

export default function ControlledSelect({
  name,
  label,
  tooltipText,
  isRequired,
  options,
  isDisabled,
}: ControlledSelectProps) {
  const { control } = useFormContext();

  return (
    <Stack spacing={2}>
      <FormLabelWithTooltip
        label={label}
        tooltipText={tooltipText}
        isRequired={isRequired}
      />

      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: isRequired || false,
            message: "This field is required",
          },
        }}
        render={({ field }) => (
          <Select {...field} isDisabled={isDisabled}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
    </Stack>
  );
}

ControlledSelect.defaultProps = {
  isDisabled: false,
  isRequired: false,
  tooltipText: null,
};
