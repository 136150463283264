import {
  Box,
  Button,
  Flex,
  Link,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useGetContentTypeIcon } from "@/client/services/hooks/shared/icons/useGetContentTypeIcon";
import { AccessList } from "@/client/services/api/graphql/gql-utils";
import { capCase } from "@/client/utils/capCase";
import { Fragment } from "react";

type ProductAccessListProps = {
  readonly access: AccessList;
  readonly isPreview?: boolean;
};

export default function ProductAccessList({
  access,
  isPreview = false,
}: ProductAccessListProps) {
  const { t } = useTranslation();

  const getLinkType = (type: string) => {
    if (type === "imported_content") {
      return "imported-content";
    }
    return type;
  };

  return (
    <>
      <Text as="h4" fontSize="md" fontWeight="bold" marginBottom={2}>
        {t("products.includedSegmentation")}:
      </Text>

      {Object.keys(access).map((key: string) => (
        <Fragment key={key}>
          <Text as="h4" fontSize="md" marginBottom={2}>
            {capCase(key)}:
          </Text>
          {access[key as keyof AccessList]?.map((item: any) => (
            <Stack key={item.id}>
              <Flex alignItems="center" paddingLeft={10}>
                <Flex
                  style={{ minWidth: 0 }}
                  alignItems="center"
                  paddingY={0}
                  paddingX={2}
                  borderRadius="md"
                  backgroundColor="transparent"
                >
                  {item?.displayName && (
                    <Tooltip label={item.type ? capCase(item.type) : ""}>
                      <Box as="span">{useGetContentTypeIcon(item.type)}</Box>
                    </Tooltip>
                  )}
                  <Box
                    key={item.id}
                    fontWeight="semibold"
                    // onClick={() => {}}
                    color="brand.link"
                    whiteSpace="nowrap"
                    flex="1"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    {item?.displayName ? (
                      isPreview ? (
                        <Button variant="ghost" height="auto">
                          {item.displayName}
                        </Button>
                      ) : (
                        <Link href={`/${getLinkType(item.type)}/${item.id}`}>
                          {item.displayName}
                        </Link>
                      )
                    ) : (
                      item.name
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Stack>
          ))}
        </Fragment>
      ))}
    </>
  );
}
