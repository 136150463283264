import { Box, Flex, Text } from "@chakra-ui/react";
import OutlineButton from "@/client/components/shared/buttons/OutlineButton";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { cardBrandIcons } from "@/client/components/icons/getCardBrandIcon";

type UpdatePaymentProps = {
  cardBrand: string;
  lastFour: string;
};

export default function UpdatePayment({
  cardBrand,
  lastFour,
}: UpdatePaymentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: subscriptionId } = useParams();

  return (
    <Box
      padding={8}
      borderRadius={10}
      border="1px solid"
      backgroundColor="brand.backgroundGrey"
      borderColor="lightgray"
    >
      <Text fontSize="lg" fontWeight="600">
        {t("ecommerce.subscriptionManagement.paymentMethod")}
      </Text>
      <Flex direction="row" justifyContent="space-between">
        <Flex align="center">
          {cardBrandIcons[cardBrand] || cardBrand}
          <Text ml={4}>*{lastFour}</Text>
        </Flex>
        <OutlineButton
          variant="createOutline"
          onClick={() =>
            navigate(`/subscription/${subscriptionId}/update-payment`)
          }
        >
          {t("ecommerce.subscriptionManagement.updatePaymentMethod")}
        </OutlineButton>
      </Flex>
    </Box>
  );
}
