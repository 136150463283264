import {
  CLIENT_CREATION_KEY_API,
  registerClientCreation,
} from "./clientRegister";
import axios, { AxiosInstance } from "axios";

// eslint-disable-next-line import/no-mutable-exports
export let learnApiClient: AxiosInstance;

const create = (baseURL: string) => {
  learnApiClient = axios.create({
    baseURL: baseURL + "/learn/v3/",
  });

  learnApiClient.interceptors.request.use(
    async (config) => {
      const value = localStorage.getItem("authStore");

      let keys;

      if (value) {
        keys = JSON.parse(value);

        // eslint-disable-next-line no-param-reassign
        config.headers = {
          authorization: `AuthToken token=${keys.state.authConfig.authtoken}`,
        };
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

registerClientCreation(CLIENT_CREATION_KEY_API, create);
