import { Flex, Grid, GridItem, Text, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "usehooks-ts";
import EcommerceService from "@/client/services/api/graphql/EcommerceService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";

export default function PaymentMethodUpdateSuccess() {
  useDocumentTitle("Update Payment Method");
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { id: subscriptionId } = useParams();
  const setupIntentId = searchParams.get("setupIntentId");
  const { t } = useTranslation();

  const updatePaymentMethod = useMutation({
    mutationFn: async () =>
      EcommerceService.updateSubscriptionPaymentMethod(
        setupIntentId!,
        subscriptionId!
      ),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: "Payment Method successfully updated",
      });
      navigate("/explore");
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: "There was an error updating your payment method",
      });
      navigate("/explore");
    },
  });

  useEffect(() => {
    if (setupIntentId) {
      updatePaymentMethod.mutate();
    }
  }, [setupIntentId]);

  return (
    <Flex direction="column" h="100%" paddingTop="100px" paddingX="170px">
      <Grid
        templateColumns="10% 1fr 1fr"
        templateRows="repeat(2, 1fr)"
        w="50%"
        margin="0 auto"
      >
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={2}>
          <Spinner size="xl" color="brand.primary" />
        </GridItem>
        <GridItem colStart={2} colSpan={2} rowStart={1} rowSpan={1}>
          <Text fontSize="xl">Payment Method Updating</Text>
        </GridItem>
        <GridItem colStart={2} colSpan={2} rowStart={2} rowSpan={1}>
          <Text fontSize="md">
            {t("ecommerce.submitPayment.dontClosePage")}
          </Text>
        </GridItem>
      </Grid>
    </Flex>
  );
}
