import SolidButton from "@/client/components/shared/buttons/SolidButton";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CreatePublishButtonProps {
  isDisabled?: boolean;
  onClick: () => void;
  onMouseOver?: () => void;
}

export default function CreatePublishButton({
  isDisabled = false,
  onClick,
  onMouseOver
}: CreatePublishButtonProps) {
  const { id } = useParams();
  const { t } = useTranslation();

  const isUpdating = !!id;

  return (
    <SolidButton
      isDisabled={isDisabled}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      {isUpdating ? t("authoring.update") : t("authoring.publish")}
    </SolidButton>
  );
}
