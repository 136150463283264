import {
  ArrowDown,
  Comment,
  Heart,
} from "@/client/components/icons/ContinuIcons";
import { Box, Divider, Flex, Link, Text, VStack } from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";
import { useAuthStore } from "@/client/services/state/authStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type FeedProps = {
  feed?: any[] | null;
  firstName?: string;
};

export default function Feed({ feed, firstName }: FeedProps) {
  const { authConfig } = useAuthStore();
  const user = authConfig?.user;
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState<number>(5);
  const [currentLoaded, setCurrentLoaded] = useState<number>(4);

  const showMoreHandler = () => {
    if (feed && feed.length > currentLoaded) {
      setCurrentLoaded(currentLoaded + perPage);
    }
  };

  const actionPhrase: { [key: string]: string } = {
    like: t("activityFeed.liked"),
    comment: t("activityFeed.commented_on"),
  };

  const availablePhrase: { [key: string]: string } = {
    article: t("activityFeed.the_article"),
    video: t("activityFeed.the_video"),
    file: t("activityFeed.the_file"),
    track: t("activityFeed.the_track"),
    presentation: t("activityFeed.the_presentation"),
    "scorm-content": t("activityFeed.the_scorm_content"),
    imported_content: t("activityFeed.the_vendor"),
    event: t("activityFeed.the_event"),
    assessment: t("activityFeed.the_assessment"),
    quiz: t("activityFeed.the_quiz"),
  };

  return (
    <Box>
      {feed?.length ? (
        feed.map((item, index) => (
          <Box key={index}>
            {index <= currentLoaded ? (
              <Flex marginBottom={3} minHeight="100px">
                <VStack paddingRight={8}>
                  {item.actionType === "like" ? (
                    <Heart color="gray.400" />
                  ) : (
                    <Comment color="gray.400" />
                  )}

                  <Divider orientation="vertical" color="gray.400" />
                </VStack>
                <Box paddingBottom={2}>
                  <Text fontSize="sm" marginBottom={1}>
                    {`${firstName} ${actionPhrase[item.actionType]} ${
                      availablePhrase[item.type]
                    } `}
                    <Link
                      color="brand.primary"
                      as={RouterLink}
                      to={`/${item.type}/${item._id}`}
                      isExternal
                    >
                      {item.title}
                    </Link>
                  </Text>
                  <Text fontSize="2xs" color="gray.500">
                    {item.timeSince} ago
                  </Text>
                </Box>
              </Flex>
            ) : (
              ""
            )}
          </Box>
        ))
      ) : (
        <Text
          textAlign="center"
          marginTop={12}
        >{`${firstName} doesn't have any public activity yet, please check back soon`}</Text>
      )}

      {feed && feed.length > currentLoaded ? (
        <Box
          onClick={showMoreHandler}
          width="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="sm"
          color="gray.500"
          transition="0.3s ease all"
          _hover={{
            color: "brand.primary",
            cursor: "pointer",
          }}
        >
          <ArrowDown />
          <Box marginX={4}>{t("global.actions.seeMore")}</Box>
          <ArrowDown />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

Feed.defaultProps = {};
