import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Navigate } from "react-router-dom";
import PreAuthService from "@/client/services/api/PreAuthService";
import { useConfigStore } from "@/client/services/state/configStore";
import { useGetAlternativeLogins } from "../services/hooks/preauth/useGetAlternativeLogins";
import { useLogin } from "@/client/services/hooks/preauth/useLogin";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../utils/useDocumentTitle";

interface UIDInputs {
  employeeId: string;
  password: string;
  confirmPassword: string;
}

export default function RegisterWithUserId() {
  useDocumentTitle("Register");
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const domain = config.host;
  const { setToast } = useToastStore();
  const { login, loginLoading } = useLogin();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
  } = useForm<UIDInputs>({ mode: "onChange" });

  const { isLoading, isError, data, error } = useGetAlternativeLogins();

  interface LoginVariables {
    userid: string;
    password: string;
  }

  const registerWithEmployeeId = useMutation({
    mutationFn: ({ userid, password }: LoginVariables) =>
      PreAuthService.registerWithEmployeeId(domain, password, userid),
    onSuccess: (res, { userid, password }) => {
      // TODO: check `validateAndUseAuthtoken`
      // TODO: Application.analyticsProvider.trackEvent('signin');
      login({ userid, password, loginType: "employeeId" });
    },
    onError: () =>
      setToast({ show: true, status: "error", title: "Invalid Credentials" }),
  });

  const onSubmit: SubmitHandler<UIDInputs> = async (data) =>
    registerWithEmployeeId.mutate({
      userid: data.employeeId,
      password: data.password,
    });

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error: {error}</div>;

  if (data.suspended) return <Navigate to="/suspended" replace />;

  return (
    <>
      <Text as="h4">{t("preauth.invite.registerWithUserID")}</Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={8}>
          <FormControl isInvalid={!!errors.employeeId}>
            <Input
              variant="flushed"
              placeholder={
                data.signin_userid_copy.field_name ||
                `${t("global.forms.labels_userid")}`
              }
              {...register("employeeId", {
                required: true,
                pattern: {
                  value: data.signin_userid_pattern as unknown as RegExp,
                  message: `${t("preauth.acceptinvite.invalid_userid")}`,
                },
                maxLength: data.signin_userid_limit,
              })}
            />
          </FormControl>

          <FormControl>
            <Input
              variant="flushed"
              type="password"
              placeholder={`${t("global.forms.labels_passwordNew")}`}
              {...register("password", { required: true })}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.confirmPassword}>
            <Input
              variant="flushed"
              type="password"
              placeholder={`${t("global.forms.labels_passwordConfirm")}`}
              {...register("confirmPassword", {
                required: true,
                validate: (value) => {
                  if (value !== watch("password")) {
                    return `${t("preauth.acceptinvite.passwords_dont_match")}`;
                  }
                },
              })}
            />

            {errors.confirmPassword && (
              <FormErrorMessage>
                {errors.confirmPassword.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <Button type="submit" isLoading={loginLoading} isDisabled={!isValid}>
            {t("preauth.invite.confirmRegistration")}
          </Button>
        </Stack>
      </form>
    </>
  );
}
