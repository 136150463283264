import { Button, ButtonGroup, Icon } from "@chakra-ui/react";
import { IoCameraOutline, IoTrashOutline } from "react-icons/io5";

import { useTranslation } from "react-i18next";

interface ImageActionButtonGroupProps {
  onRemove: () => void;
  onEdit: () => void;
}

export default function ImageActionButtonGroup({
  onRemove,
  onEdit,
}: ImageActionButtonGroupProps) {
  const { t } = useTranslation();

  return (
    <ButtonGroup size="xs" justifyContent="center">
      <Button
        variant="outline"
        border="2px solid"
        borderColor="#E4626F"
        borderRadius="lg"
        color="#E4626F"
        padding={4}
        _hover={{ backgroundColor: "white" }}
        onClick={onRemove}
      >
        {t("authoring.images.remove")}
      </Button>

      <Button
        border="2px solid"
        borderColor="brand.button"
        borderRadius="lg"
        padding={4}
        onClick={onEdit}
        _hover={{ backgroundColor: "brand.button", color: "white" }}
      >
        {t("authoring.images.edit")}
      </Button>
    </ButtonGroup>
  );
}
