import { Avatar, Flex, Heading, Link, Stack, Text, Tooltip } from '@chakra-ui/react';

import CircleStat from '../stats/CircleStat';
import CircleStatLabel from '../stats/CircleStatLabel';
import { Edit } from '@/client/components/icons/ContinuIcons';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type StatCounterProps = {
  count: number;
  label: string;
  counterSubtext?: string;
};

const StatCounter = ({ count, label, counterSubtext }: StatCounterProps) => (
  <Flex direction={{ base: 'column', md: 'row' }} justifyContent="center" alignItems="center">
    <CircleStat color="white" count={count} fontWeight="bold" borderWidth="4px" />
    <CircleStatLabel
      label={label}
      counterSubtext={counterSubtext}
      position="left"
      color="white"
      fontWeight="bold"
    />
  </Flex>
);

StatCounter.defaultProps = {
  counterSubtext: '',
};

type UserStatsSectionProps = {
  name: string;
  email?: string;
  userTitle?: string;
  stats: { statCount: number; counterLabel: string; counterSubtext?: string }[];
  avatarSrc?: string;
  currentUser?: boolean;
};

export default function UserStatsSection({
  name,
  email,
  userTitle,
  stats,
  avatarSrc,
  currentUser,
}: UserStatsSectionProps) {
  const { t } = useTranslation();
  return (
    <Flex
      justifyContent="center"
      direction={{ base: 'column', lg: 'row' }}
      width="full"
      maxWidth="1200px"
      margin="0 auto"
      marginBottom={10}
      padding={8}
      zIndex="1"
      position="relative"
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        width={{ base: 'full', lg: '50%' }}
        minWidth="0"
        maxWidth={{ base: 'full', lg: '480px' }}
        marginTop={{ base: 0, lg: 8 }}
        marginBottom={{ base: 8, lg: 0 }}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Avatar
          color="gray.500"
          size={{ base: 'xl', md: '2xl' }}
          name={name}
          src={avatarSrc}
          marginRight={{ base: 0, lg: 8 }}
          marginBottom={{ base: 4, lg: 0 }}
        />

        <Stack textAlign={{ base: 'center', lg: 'left' }}>
          <Heading
            margin="0"
            as="h3"
            fontSize={{ base: 'xl' }}
            color="white"
            display="flex"
            alignItems="center"
          >
            {name}

            {currentUser && (
              <Tooltip label={t('dashboard.edit_profile')} aria-label="A tooltip">
                <Link
                  to="/settings"
                  as={RouterLink}
                  color="brand.link"
                  _hover={{ color: 'brand.link' }}
                  marginLeft={2}
                  display="flex"
                  alignItems="center"
                >
                  <Edit />
                </Link>
              </Tooltip>
            )}
          </Heading>

          {userTitle && (
            <Text marginTop={8} fontSize="sm" color="white">
              {userTitle}
            </Text>
          )}

          {email && (
            <Text marginTop={8} fontSize="sm" color="white">
              {email}
            </Text>
          )}
        </Stack>
      </Flex>

      <Flex
        justifyContent={{ base: 'space-around' }}
        alignItems={{ base: 'center' }}
        width={{ base: 'full', lg: '50%' }}
        direction={{ base: 'row' }}
        marginTop={8}
        fontSize="lg"
      >
        {stats.map(({ statCount, counterLabel, counterSubtext }, i) => (
          <StatCounter
            key={i}
            count={statCount}
            label={counterLabel}
            counterSubtext={counterSubtext}
          />
        ))}
      </Flex>
    </Flex>
  );
}

UserStatsSection.defaultProps = {
  email: '',
  userTitle: '',
  avatarSrc: '',
  currentUser: false,
};
