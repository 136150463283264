import { graphql } from "../gql/gql";

export const getGenerativeKeywords: any = graphql(`
  query GenerativeKeywords($text: String!) {
    generativeKeywords(text: $text)
  }
`);

export const getGenerativeKeywordsById: any = graphql(`
  query GenerativeKeywordsById($id: String!) {
    generativeKeywordsById(id: $id)
  }
`);

export const getGenerativeDescriptionById: any = graphql(`
  query GenerativeDescriptionById($id: String!) {
    generativeDescriptionById(id: $id)
  }
`);

export const getTextGenerationStatus: any = graphql(`
  query GetTextGenerationStatus(
    $id: String!
    $type: String!
    $userid: String!
  ) {
    content(id: $id, type: $type, userid: $userid) {
      id
      company
      currentVersion {
        ... on VideoExtras {
          textGenerationStatus
          vttFiles {
            url
            locale
            kind
          }
        }
        ... on FileExtras {
          textGenerationStatus
        }
      }
    }
  }
`);

export const getGenerativeTrackTopics: any = graphql(`
  query GenerativeTopics($trackid: String!) {
    generativeTopics(trackid: $trackid)
  }
`);
