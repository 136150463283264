import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import v3ApiService from "@/client/services/api/clients/v3ApiClient";

export const useUploadImage = () => {
  const { setToast } = useToastStore();

  interface UploadImageMutationVariables {
    file: File;
    errorMessage: string;
    successActions: (cloudfrontLink: string) => void;
  }

  const uploadImageMutation = useMutation({
    mutationFn: async (variables: UploadImageMutationVariables) => {
      await v3ApiService
        .getS3ImagePolicy(variables.file.type, variables.file.name)
        .then((policy) => {
          const formData = new FormData();

          formData.append("key", policy.Key);
          formData.append("Content-Type", policy.ContentType);
          formData.append("AWSAccessKeyId", policy.AWSAccessKeyId);
          formData.append("success_action_status", "201");
          formData.append("policy", policy.S3Policy);
          formData.append("signature", policy.S3Signature);
          formData.append("file", variables.file);

          axios
            .post("https://" + policy.Bucket + ".s3.amazonaws.com/", formData)
            .then(() => {
              if (!policy.CloudFrontLink) return;

              variables.successActions(policy.CloudFrontLink);
            })
            .catch((err) => {
              console.log("Error uploading image: ", err);
              setToast({
                show: true,
                status: "error",
                title: variables.errorMessage,
              });
            });
        })
        .catch((err) => {
          console.log("Error getting S3 image policy: ", err);
          setToast({
            show: true,
            status: "error",
            title: variables.errorMessage,
          });
        });
    },
  });

  return { uploadImageMutation };
};
