import { Fade, Stack } from "@chakra-ui/react";

import AuthoringSegmentationSearch from "@/client/components/admin/create/content/forms/audience/AuthoringSegmentationSearch";
import ContentExploreSettingsForm from "@/client/components/admin/create/content/forms/audience/ContentExploreSettingsForm";
import ContentExtendAccessForm from "@/client/components/admin/create/content/forms/audience/ContentExtendAccessForm";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";

export default function ContentAudienceForm() {
  const { isAllowed } = useAccessHelper();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  const allowance = {
    add_partner:
      company.feature_flags.enable_partners &&
      isAllowed(["admin", "content"], null, ["admin"]),
  };

  return (
    <Fade in delay={0.1}>
      <Stack spacing={6}>
        <AuthoringSegmentationSearch />

        <ContentExploreSettingsForm />

        {allowance.add_partner && <ContentExtendAccessForm />}
      </Stack>
    </Fade>
  );
}
