/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps, useToken } from "@chakra-ui/react";

import { hexToRGBA } from "@/client/utils/hexToRGBA";

export default function SolidButton(props: ButtonProps) {
  const { children } = props;
  const [brandButton] = useToken("colors", ["brand.button"]);

  return (
    <Button
      {...props}
      backgroundColor="brand.button"
      color="white"
      borderRadius="md"
      paddingY={4}
      paddingX={8}
      _hover={{
        backgroundColor: hexToRGBA(brandButton, 0.9),
        _disabled: {
          backgroundColor: "brand.button",
        },
      }}
      _disabled={{
        cursor: "not-allowed",
        opacity: 0.5,
      }}
    >
      {children}
    </Button>
  );
}
