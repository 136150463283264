import { HStack } from "@chakra-ui/react";
import { Skill } from "../../types/Skill";
import SkillTag from "../data-display/tags/SkillTag";

interface SkillTagListProps {
  skills: Skill[];
  showRemove?: boolean;
  removeSkill: (name: string) => void;
}

export default function SkillTagList({
  skills,
  showRemove,
  removeSkill,
}: SkillTagListProps) {
  return (
    <HStack wrap="wrap" spacing={1} marginTop={4}>
      {skills.map((skill) => (
        <SkillTag
          key={skill.name}
          skill={skill}
          showRemove={showRemove}
          removeSkill={removeSkill}
        />
      ))}
    </HStack>
  );
}

SkillTagList.defaultProps = {
  showRemove: false,
};
