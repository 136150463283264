import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

interface ImportedContentSearchFilterMenuProps {
  label: string;
  selected: { name: string; value: string | null } | null;
  setSelected: (option: { name: string; value: string | null }) => void;
  options: { name: string; value: string | null }[];
}

export default function ImportedContentSearchFilterMenu({
  label,
  selected,
  setSelected,
  options,
}: ImportedContentSearchFilterMenuProps) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon color="baseBlack" />}
        backgroundColor="white"
        color="neutral.500"
        fontSize="14px"
        fontWeight={500}
        paddingY="14px"
        paddingX={3}
        border="1px solid"
        borderColor="warmNeutral.200"
        borderRadius="6px"
        _hover={{ backgroundColor: "warmNeutral.0" }}
        _active={{ backgroundColor: "warmNeutral.0" }}
      >
        {label}{" "}
        {selected && selected.value !== null ? `: ${selected.name}` : ""}
      </MenuButton>

      <MenuList
        border="1px solid"
        borderColor="warmNeutral.200"
        borderRadius="6px"
        boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.15)"
        paddingX={4}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            color="baseBlack"
            fontSize="14px"
            fontWeight={500}
            borderBottom={index !== options.length - 1 ? "1px solid" : "none"}
            borderBottomColor="neutral.100"
            paddingY={4}
            _hover={{ backgroundColor: "warmNeutral.0" }}
            onClick={() => setSelected(option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
