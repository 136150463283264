/* eslint react/jsx-props-no-spreading: 0 */
import {
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useToken,
} from "@chakra-ui/react";
import {
  Check,
  Close,
  Search,
  QuestionCircle,
} from "@/client/components/icons/ContinuIcons";
import { useCombobox, useMultipleSelection } from "downshift";
import { useRef, useState } from "react";

import ContentTypeIcon from "@/client/components/media/icons/ContentTypeIcon";
import Highlighter from "react-highlight-words";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";

interface WorkshopType {
  value: string;
  label: string;
  type: "textInput" | "workshopType";
}

const defaultOptionFilterFunc = (items: WorkshopType[], inputValue: string) =>
  matchSorter(items, inputValue, { keys: ["value", "label"] });

const defaultItemRenderer = (selected: WorkshopType) => selected.label;

export default function WorkshopsSearch(props: any) {
  const {
    items,
    optionFilterFunc = defaultOptionFilterFunc,
    itemRenderer = defaultItemRenderer,
    placeholder,
    onCreateItem,
    selectedItems = [],
    handleClearAll,
    ...downshiftProps
  } = props;
  const [brandPrimaryColor, inputGray] = useToken("colors", [
    "brand.primary",
    "gray.200",
  ]);
  const [inputItems, setInputItems] = useState(items);
  const [filteredItems, setFilteredItems] = useState([]);
  const disclosureRef = useRef(null);
  const popoverRef = useRef(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    activeIndex,
  } = useMultipleSelection({
    ...downshiftProps,
    selectedItems,
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: null,
          };
        default:
          return changes;
      }
    },
  });

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    inputValue,
    openMenu,
  } = useCombobox({
    selectedItem: null,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      if (inputValue === "") {
        setFilteredItems([]);
      }

      if (inputValue && inputValue.length >= 2) {
        const filteredItems = optionFilterFunc(items, inputValue || "");

        setInputItems(filteredItems);
        setFilteredItems(filteredItems);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            //@ts-ignore
            if (selectedItemValues.includes(selectedItem.value)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }

            selectItem(null);
          } else if (!selectedItem) {
            addSelectedItem({
              type: "textInput",
              value: inputValue,
              label: inputValue,
            });
          }
          break;
        default:
          break;
      }
    },
  });

  const selectedItemValues = selectedItems.map(
    (item: WorkshopType) => item.value
  );

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      position="relative"
      alignItems="center"
      marginLeft={0}
      marginBottom={{ base: 4, lg: 0 }}
      width={{ base: "100%", lg: "auto" }}
      flex={1}
      order={{
        base: "1",
        lg: "2",
      }}
      justifyContent="end"
    >
      <Box
        justifySelf="stretch"
        borderRadius={"lg"}
        background="#F9F9F9"
        display="flex"
        flexGrow="1"
        width={{ base: "100%", lg: "auto" }}
      >
        {!!selectedItems.length && (
          <HStack justifyContent="flex-end" flex={1} paddingX={2}>
            {selectedItems.map((selectedItem: WorkshopType, index: number) => (
              <Tag
                margin={0}
                key={index}
                {...getSelectedItemProps({ selectedItem, index })}
                background={
                  selectedItem.type === "workshopType"
                    ? "brand.primary"
                    : "blackAlpha.200"
                }
              >
                {selectedItem.type === "workshopType" && (
                  <ContentTypeIcon
                    contentType={selectedItem.value}
                    lightScheme
                  />
                )}

                <TagLabel
                  color={
                    selectedItem.type === "workshopType" ? "white" : undefined
                  }
                  display="inline-block"
                  whiteSpace="nowrap"
                >
                  {selectedItem.label}
                </TagLabel>

                <TagCloseButton
                  color={
                    selectedItem.type === "workshopType" ? "white" : undefined
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItem);
                  }}
                />
              </Tag>
            ))}
          </HStack>
        )}
        <InputGroup>
          {!selectedItems.length && (
            <InputLeftElement children={<Search color="#1A1A1A99" />} />
          )}

          <Input
            variant="workshops"
            placeholder={t("workshops.searchLabel")}
            border="none"
            {...getInputProps(
              getDropdownProps({
                onClick: () => {
                  if (!isOpen) openMenu;
                  setIsFocused(true);
                },
                onFocus: () => {
                  if (!isOpen) openMenu;
                  setIsFocused(true);
                },
                onBlur: () => {
                  setIsFocused(false);
                },
                ref: disclosureRef,
              })
            )}
          />

          {selectedItems.length && (
            <InputRightElement
              children={<Close color="#1A1A1A80" />}
              _hover={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleClearAll();
              }}
            />
          )}
          {!selectedItems.length && (
            <InputRightElement
              children={
                <Tooltip hasArrow label={t("workshops.searchHelp")}>
                  <QuestionCircle color="#1A1A1A80" />
                </Tooltip>
              }
            />
          )}
        </InputGroup>
      </Box>
      <Box
        zIndex={9999}
        position="absolute"
        top="100%"
        left={0}
        right={0}
        {...getMenuProps({ ref: popoverRef })}
      >
        {isOpen && filteredItems.length > 0 && (
          <List
            backgroundColor="white"
            border="1px"
            borderColor="brand.primary"
            padding={4}
          >
            {filteredItems.map((item: any, index: number) => (
              <ListItem
                key={`${item.value}${index}`}
                backgroundColor={
                  highlightedIndex === index ? "brand.gray.50" : "white"
                }
                paddingX={6}
                paddingY={1}
                {...getItemProps({ item, index })}
              >
                <Flex alignItems="center">
                  {selectedItemValues.includes(item.value) && (
                    <Check color="green.500" marginRight={2} />
                  )}

                  <ContentTypeIcon contentType={item.value} />

                  <Highlighter
                    autoEscape
                    searchWords={[inputValue || ""]}
                    textToHighlight={itemRenderer(item)}
                    highlightStyle={{
                      fontWeight: "bold",
                      backgroundColor: brandPrimaryColor,
                      color: "white",
                    }}
                  />
                </Flex>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Flex>
  );
}
