import { create } from "zustand";

interface Toast {
  show: boolean;
  status: "success" | "error" | "warning" | "info";
  title: string;
}

interface ToastState {
  toast: Toast;
  setToast: (toast: Toast) => void;
}

export const useToastStore = create<ToastState>((set) => ({
  toast: { show: false, status: "success", title: "" },
  setToast: (toast: Toast) => set(() => ({ toast })),
}));
