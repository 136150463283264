import { Button, Stack, Text } from "@chakra-ui/react";
import { Fragment, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { AngleDown } from "@/client/components/icons/ContinuIcons";
import Comment from "../data-display/comments/Comment";
import CommentInput from "@/client/components/data-display/comments/CommentInput";
import { CommentService } from "@/client/services/api/CommentService";
import { Comment as CommentType } from "@/client/types/Comment";
import Loading from "@/client/components/media/Loading";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useAuthStore } from "@/client/services/state/authStore";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface CommentListProps {
  contentId: string | undefined;
  contentType: string;
  contentTitle: string;
  inTrack?: boolean;
  trackId?: string;
  trackContentType?: string;
  trackContentId?: string;
}

export default function CommentList({
  contentId,
  contentType,
  contentTitle,
  inTrack,
  trackId,
  trackContentType,
  trackContentId,
}: CommentListProps) {
  const queryClient = useQueryClient();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { t } = useTranslation();
  const [commentValue, setCommentValue] = useState("");
  const { setToast } = useToastStore();
  const [newMentions, setNewMentions] = useState<string[]>([]);

  const perPage = 5;

  const invalidateComments = (successMessage: string, errorMessage: string) =>
    queryClient
      .invalidateQueries(["comments", { contentId }])
      .then(() =>
        setToast({ title: successMessage, status: "success", show: true })
      )
      .catch(() =>
        setToast({ title: errorMessage, status: "error", show: true })
      );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteQuery({
      queryKey: ["comments", { contentId }],
      queryFn: ({ pageParam = 1 }) =>
        CommentService.getComments(contentType, contentId, pageParam, perPage),
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === perPage ? pages.length + 1 : undefined,
      select: (data) => ({
        pages: [...data.pages].reverse(),
        pageParams: [...data.pageParams].reverse(),
      }),
    });

  //* Delete Comment
  const onDeleteComment = useMutation({
    mutationFn: (commentId: string) => CommentService.deleteComment(commentId),
    onSuccess: () =>
      invalidateComments("Comment deleted", "Could not delete comment"),
    onError: () => {
      setToast({
        title: "Could not delete comment",
        status: "error",
        show: true,
      });
    },
  });

  const addContentComment = () =>
    CommentService.addComment(
      commentValue,
      contentType,
      contentId,
      user._id,
      newMentions
    );

  const addTrackComment = () =>
    CommentService.addComment(
      commentValue,
      contentType,
      contentId,
      user._id,
      newMentions,
      inTrack,
      trackId,
      trackContentType,
      trackContentId
    );

  //* Add Comment
  const onAddComment = useMutation({
    mutationFn: inTrack ? addTrackComment : addContentComment,
    onSuccess: () => {
      trackEvent(contentType + "_commented", {
        content: contentId,
        content_type: contentType,
        title: contentTitle,
      });
      invalidateComments("Comment added", "Could not add comment").then(() =>
        setCommentValue("")
      );
    },
    onError: () => {
      setToast({ title: "Could not add comment", status: "error", show: true });
    },
  });

  type EditCommentParams = {
    commentId: string;
    editedValue: string;
  };

  //* Edit Comment
  const onEditComment = useMutation({
    mutationFn: ({ commentId, editedValue }: EditCommentParams) =>
      CommentService.editComment(commentId, editedValue, user._id, newMentions),
    onSuccess: () =>
      invalidateComments("Comment edited", t("singlecomment.editSaved")),
    onError: () => {
      setToast({
        title: t("singlecomment.issueSavingEdit"),
        status: "error",
        show: true,
      });
    },
  });

  type LikeCommentProps = {
    commentId: string;
    userId: string;
  };

  //* Like Comment
  const onLikeComment = useMutation({
    mutationFn: ({ commentId, userId }: LikeCommentProps) =>
      CommentService.likeComment(commentId, userId),
    onSuccess: () =>
      invalidateComments("Comment liked", "Could not like comment"),
    onError: (err: any) => {
      console.log(err);
    },
  });
  type UnLikeCommentProps = {
    commentId: string;
    userId: string;
  };

  //* UnLike Comment
  const onUnLikeComment = useMutation({
    mutationFn: ({ commentId, userId }: UnLikeCommentProps) =>
      CommentService.unLikeComment(commentId, userId),
    onSuccess: () =>
      invalidateComments("Comment unliked", "Could not unlike comment"),
    onError: (err: any) => {
      console.log(err);
    },
  });

  type ReplyToCommentParams = {
    replyValue: string;
    parentCommentId: string;
  };

  //* Reply to Comment
  const onReplyToComment = useMutation({
    mutationFn: ({ replyValue, parentCommentId }: ReplyToCommentParams) =>
      CommentService.replyToComment(
        contentId,
        replyValue,
        contentType,
        parentCommentId,
        user._id,
        newMentions
      ),
    onSuccess: () =>
      invalidateComments("Comment added", "Could not add comment").then(() =>
        setCommentValue("")
      ),
    onError: () => {
      setToast({ title: "Could not add comment", status: "error", show: true });
    },
  });

  if (status === "loading") return <Loading />;

  if (status === "error") return <Text>Error loading comments</Text>;

  return (
    <Stack id="comments" maxWidth="700px">
      {!inTrack && (
        <Text as="h3" fontSize="lg" mb={4}>
          {t("modules.comments.headline")}
        </Text>
      )}

      {hasNextPage && (
        <Button
          variant="ghost"
          size="sm"
          paddingLeft={0}
          rightIcon={<AngleDown />}
          maxWidth="min-content"
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          {t("mainComments.viewPrevious")}
        </Button>
      )}

      <>
        {data.pages.map((page, i) => (
          <Fragment key={i}>
            {inTrack && (
              <>
                {page.map((comment: CommentType) => {
                  if (comment.removed) return;

                  if (trackContentType && trackContentId) {
                    if (
                      comment[trackContentType as keyof typeof comment] !==
                      trackContentId
                    )
                      return;
                  }

                  if (!comment.replies)
                    return (
                      <Comment
                        key={comment._id}
                        comment={comment}
                        contentType={contentType}
                        onDelete={onDeleteComment}
                        onEdit={onEditComment}
                        onReply={onReplyToComment}
                        newMentions={newMentions}
                        setNewMentions={setNewMentions}
                        onLikeComment={onLikeComment}
                        onUnLikeComment={onUnLikeComment}
                      />
                    );

                  return (
                    <Fragment key={comment._id}>
                      <Comment
                        comment={comment}
                        contentType={contentType}
                        onDelete={onDeleteComment}
                        onEdit={onEditComment}
                        onReply={onReplyToComment}
                        newMentions={newMentions}
                        setNewMentions={setNewMentions}
                        onLikeComment={onLikeComment}
                        onUnLikeComment={onUnLikeComment}
                      />

                      <Stack paddingLeft={12}>
                        {comment.replies.map((reply) => {
                          if (reply?.removed) return;

                          return (
                            <Comment
                              key={reply?._id}
                              contentType={contentType}
                              comment={reply}
                              onDelete={onDeleteComment}
                              onEdit={onEditComment}
                              onReply={onReplyToComment}
                              newMentions={newMentions}
                              setNewMentions={setNewMentions}
                              parentId={comment._id}
                              onLikeComment={onLikeComment}
                              onUnLikeComment={onUnLikeComment}
                            />
                          );
                        })}
                      </Stack>
                    </Fragment>
                  );
                })}
              </>
            )}

            <>
              {inTrack && <Text>{t("mainComments.commentsOnOtherPart")}</Text>}

              {page.map((comment: CommentType) => {
                if (comment.removed) return;

                if (inTrack && trackContentId && trackContentType) {
                  if (
                    comment[trackContentType as keyof typeof comment] ===
                    trackContentId
                  )
                    return;
                }

                if (!comment.replies)
                  return (
                    <Comment
                      key={comment._id}
                      comment={comment}
                      contentType={contentType}
                      onDelete={onDeleteComment}
                      onEdit={onEditComment}
                      onReply={onReplyToComment}
                      newMentions={newMentions}
                      setNewMentions={setNewMentions}
                      onLikeComment={onLikeComment}
                      onUnLikeComment={onUnLikeComment}
                    />
                  );

                return (
                  <Fragment key={comment._id}>
                    <Comment
                      comment={comment}
                      onDelete={onDeleteComment}
                      contentType={contentType}
                      onEdit={onEditComment}
                      onReply={onReplyToComment}
                      newMentions={newMentions}
                      setNewMentions={setNewMentions}
                      onLikeComment={onLikeComment}
                      onUnLikeComment={onUnLikeComment}
                    />

                    <Stack paddingLeft={12}>
                      {comment.replies.map((reply) => {
                        if (reply?.removed) return;

                        return (
                          <Comment
                            key={reply?._id}
                            comment={reply}
                            contentType={contentType}
                            onDelete={onDeleteComment}
                            onEdit={onEditComment}
                            onReply={onReplyToComment}
                            newMentions={newMentions}
                            setNewMentions={setNewMentions}
                            parentId={comment._id}
                            onLikeComment={onLikeComment}
                            onUnLikeComment={onUnLikeComment}
                          />
                        );
                      })}
                    </Stack>
                  </Fragment>
                );
              })}
            </>
          </Fragment>
        ))}
      </>

      {!data.pages[0].length && (
        <Text fontSize="xs" textAlign="center">
          {inTrack
            ? t("modules.comments.track_copy")
            : t("mainComments.noComments")}
        </Text>
      )}

      <CommentInput
        value={commentValue}
        setValue={setCommentValue}
        onSubmit={() => onAddComment.mutate()}
        contentType={contentType}
        mentions={newMentions}
        setMentions={setNewMentions}
      />
    </Stack>
  );
}

CommentList.defaultProps = {
  inTrack: false,
  trackId: "",
  trackContentType: "",
  trackContentId: "",
};
