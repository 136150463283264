import TrackService from "@/client/services/api/TrackService";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useQuery } from "@tanstack/react-query";

export const useGetLearningTrack = (
  trackId: string | undefined,
  userId: string,
  contextId: string | undefined,
  needsContextSelection: boolean | undefined
) =>
  useQuery({
    enabled: !!trackId && !!contextId && !needsContextSelection,
    queryKey: ["learning-track", { trackId, userId, contextId }],
    cacheTime: 0,
    queryFn: () =>
      TrackService.getLearningTrackInUserContext(trackId, userId, contextId),
    onSuccess: (data) =>
      trackEvent("track_viewed", {
        content: data.learningTrack._id,
        content_type: "track",
        title: data.learningTrack.name,
      }),
  });
