/* eslint-disable react/jsx-props-no-spreading */

import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import { Check } from "@/client/components/icons/ContinuIcons";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { DefaultTFuncReturn } from "i18next";
import { Fragment } from "react";

interface AssessmentDropdownMenuProps {
  name: any;
  selections: number[];
  placeholder: string | DefaultTFuncReturn;
}

export default function AssessmentDropdownMenu({
  name,
  selections,
  placeholder,
}: AssessmentDropdownMenuProps) {
  const { control } = useFormContext<AssessmentFormData>();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon color="baseBlack" boxSize={6} />}
            backgroundColor="white"
            border="1px solid"
            borderColor="warmNeutral.200"
            color="baseBlack"
            fontSize="14px"
            fontWeight={500}
            _hover={{
              backgroundColor: "warmNeutral.0",
            }}
            _active={{
              backgroundColor: "warmNeutral.0",
            }}
          >
            {value === 1000
              ? "Unlimited"
              : value || value === 0
              ? name === "percentToPass"
                ? `${value}%`
                : value
              : placeholder}
          </MenuButton>

          <MenuList
            borderColor="warmNeutral.200"
            borderRadius="6px"
            maxHeight="200px"
            overflowY="auto"
          >
            {selections.map((option, index) => (
              <Fragment key={option}>
                <MenuItem
                  key={option}
                  value={option}
                  fontSize="14px"
                  fontWeight={500}
                  _hover={{
                    backgroundColor: "warmNeutral.0",
                  }}
                  onClick={() => onChange(option)}
                >
                  <HStack
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {option === value && <Icon as={Check} boxSize={3} />}
                    <Text>
                      {name === "percentToPass"
                        ? `${option}%`
                        : option === 1000
                        ? "Unlimited"
                        : option}
                    </Text>
                  </HStack>
                </MenuItem>

                {index !== selections.length - 1 && <MenuDivider />}
              </Fragment>
            ))}
          </MenuList>
        </Menu>
      )}
    />
  );
}
