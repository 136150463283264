import {
  Box,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import CountUp from "react-countup";

type CircleStatProps = {
  label: string;
  color: string;
  position: "center" | "left";
  counterSubtext?: string;
  fontWeight: "bold" | "medium" | "normal" | "light";
};

export default function CircleStatLabel({
  label,
  color,
  position,
  fontWeight,
  counterSubtext,
}: CircleStatProps) {
  return (
    <Stack paddingX={4}>
      <Text
        fontSize={{ base: "xs", md: "sm" }}
        fontWeight={fontWeight}
        color={color}
        textAlign={{ base: "center", md: position }}
      >
        {label}
      </Text>

      <Text
        fontSize={{ base: "xs", md: "sm" }}
        color={color}
        textAlign={{ base: "center", md: position }}
      >
        {counterSubtext}
      </Text>
    </Stack>
  );
}
