import { Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DueDateBadge({
  daysUntilDue,
}: {
  daysUntilDue: number;
}) {
  const { t } = useTranslation();

  let label;
  let variant;

  if (daysUntilDue <= 0) {
    label = t("dashboard.assignments.tag_overdue");
    variant = "error";
  } else if (daysUntilDue < 10) {
    label =
      daysUntilDue === 1
        ? "Due in 1 day"
        : t("dashboard.assignments.tag_label_days", { days: daysUntilDue });
    variant = "error";
  } else if (daysUntilDue > 10 && daysUntilDue < 19) {
    label = t("dashboard.assignments.tag_label_days", { days: daysUntilDue });
    variant = "warning";
  } else {
    label = t("dashboard.assignments.tag_label_days", { days: daysUntilDue });
    variant = "success";
  }

  return <Badge variant={variant}>{label}</Badge>;
}
