//@ts-nocheck
import { Box, Link } from "@chakra-ui/react";
import { Workshop, WorkshopInstance } from "@/client/types/content/Workshop";

import { ics } from "calendar-link";

interface CalendarLinkProps {
  workshop: Workshop;
  workshopInstance: WorkshopInstance;
  type: "iCal" | "outlook";
}

export default function CalendarLink({
  workshop,
  workshopInstance,
  type,
}: CalendarLinkProps) {
  let location = "Undetermined";

  if (workshopInstance.online) {
    location =
      workshopInstance.online_location ||
      workshopInstance.online_location_label ||
      "Online";
  }

  if (!workshopInstance.online && workshopInstance.location) {
    location =
      workshopInstance.location.venue ||
      workshopInstance.location.city_state ||
      workshopInstance.location.country ||
      "Undetermined";
  }

  const descriptionDiv = document.createElement("div");
  descriptionDiv.innerHTML = workshop.description;

  const event = {
    title: workshop.title,
    description:
      descriptionDiv.innerText || descriptionDiv.textContent || "No Summary",
    start: workshopInstance.start_date,
    end: workshopInstance.end_date,
    location: location,
    url: `https://${window.location.hostname}/workshop/${workshop._id}`,
  };

  const link = ics(event);

  let iconUrl;
  switch (type) {
    case "iCal":
      iconUrl =
        "url('https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/calendar-apple.png')";
      break;
    case "outlook":
      iconUrl =
        "url('https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/calendar-outlook.svg')";
      break;
    default:
      break;
  }

  return (
    <Link target="_blank" href={link}>
      <Box
        boxSize="24px"
        backgroundImage={iconUrl}
        backgroundSize="cover"
        backgroundPosition="center"
      />
    </Link>
  );
}
