import { Box, Link, Stack, Text, useToken } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Card from "./Card";
import { Link as RouterLink } from "react-router-dom";
import { getMSWIdFromSessionId } from "@/client/services/api/content/workshops/MultiSessionWorkshopService";
import { useRenderContentIcon } from "@/client/services/hooks/useRenderContentIcon";
import {
  setCoverImageJustification,
  getCardBackgroundUrl,
} from "@/client/utils/content/handleCardBackgroundImage";
import { useTranslation } from "react-i18next";
import { ExploreCardProductDetails } from "@/client/types/ExploreContent";

type ExploreCardProps = {
  id: string;
  title: string;
  type: string;
  imageUrl?: string;
  cardType: "card" | "list";
  vendor?: string;
  isFirst?: boolean;
  isResource?: boolean;
  imageAlignment: string;
  coverImageJustification?: string;
  isRecommendation?: boolean;
  productDetails?: ExploreCardProductDetails;
  isStatic?: boolean;
};

export default function ContentCard({
  id,
  title,
  type,
  imageUrl,
  cardType,
  vendor,
  isFirst,
  isResource,
  imageAlignment,
  coverImageJustification,
  isRecommendation,
  productDetails,
  isStatic,
}: ExploreCardProps) {
  const [hoverState, setHoverState] = useState(false);
  const { t } = useTranslation();
  const [contentUrl, setContentUrl] = useState<string>("");
  const [blackAlpha600] = useToken("colors", [
    "blackAlpha.600",
    "brand.primary",
  ]);

  const transition = isStatic ? "none" : "0.3s ease all";

  const getContentUrl = async (contentType: string = "") => {
    if (contentType === "multi_session_workshop_session") {
      const mswId = await getMSWIdFromSessionId(id);
      setContentUrl(`/multi-session-workshop/${mswId}`);
    } else if (contentType === "multi_session_workshop") {
      setContentUrl(`/multi-session-workshop/${id}`);
    } else if (contentType === "imported_content") {
      setContentUrl(`/imported-content/${id}`);
    } else if (contentType === "Badge") {
      setContentUrl("");
    } else {
      setContentUrl(`/${type.toLowerCase()}/${id}`);
    }
  };

  useEffect(() => {
    getContentUrl(type);
  }, [type]);

  const setContentTypeTitle = (
    string: string = "",
    incomingVendor?: string
  ) => {
    let contentTitle;
    if (string === "multi_session_workshop") {
      contentTitle = t("global.contentTypes.workshop");
    } else if (string === "track" && isResource) {
      contentTitle = t("global.contentTypes.resource");
    } else if (string === "multi_session_workshop_session") {
      contentTitle = t("global.contentTypes.multi_session_workshop");
    } else if (string === "presentation") {
      contentTitle = t("modules.contentPicker.presentation");
    } else if (string === "media") {
      contentTitle = t("modules.contentPicker.video");
    } else if (string === "assessment") {
      contentTitle = t("modules.contentPicker.assessment");
    } else if (string === "badge") {
      contentTitle = t("dashboard.badge");
    } else if (string === "imported_content") {
      if (incomingVendor && incomingVendor === "linkedin_learning") {
        contentTitle = t("global.contentTypes.linkedin_learning");
      } else if (incomingVendor && incomingVendor === "udemy") {
        contentTitle = t("global.contentTypes.udemy");
      } else if (incomingVendor && incomingVendor === "masterclass") {
        contentTitle = t("global.contentTypes.masterclass");
      } else {
        contentTitle = t("global.contentTypes.imported_content");
      }
    } else if (string === "product") {
      contentTitle = t("global.contentTypes.product");
    } else {
      contentTitle = t("global.contentTypes." + type);
    }
    return contentTitle;
  };

  return (
    <div
      onClick={() => {
        if (isRecommendation) {
          window.location.reload();
        }
      }}
    >
      {cardType === "card" && (
        <Link
          as={RouterLink}
          to={contentUrl}
          minHeight="400px"
          height="100%"
          display="flex"
          background="brand.primary"
          color="white"
          _hover={{ textDecoration: "none" }}
          borderRadius="10px"
          overflow="hidden"
          flexDirection="column"
          position="relative"
          textAlign="left"
          wordBreak="break-word"
          onMouseOver={() => {
            setHoverState(true);
          }}
          onMouseOut={() => {
            setHoverState(false);
          }}
        >
          <Box
            width={hoverState ? "110%" : "100%"}
            height={hoverState ? "110%" : "100%"}
            position="absolute"
            top={hoverState ? "-5%" : "0"}
            left={hoverState ? "-5%" : "0"}
            zIndex={1}
            background={`url('${getCardBackgroundUrl(imageUrl)}')`}
            backgroundSize="cover"
            backgroundPosition={`${setCoverImageJustification(
              coverImageJustification
            )} top`}
            transition={transition}
          />
          <Box
            width="100%"
            height="100%"
            position="absolute"
            top="0"
            left="0"
            backgroundColor={hoverState ? "blackAlpha.700" : blackAlpha600}
            zIndex={2}
            transition={transition}
          />

          {isFirst && (
            <Box
              position="absolute"
              top={4}
              left={0}
              backgroundColor="brand.primary"
              padding="2px 20px 2px 16px"
              fontSize="sm"
              borderRadius="0 4px 4px 0"
              zIndex="20"
            >
              {t("explore.general.featuredFlag")}
            </Box>
          )}

          <Stack padding={6} pt={20} flex="1" zIndex={2} spacing={0}>
            <Box
              width="40px"
              height="40px"
              borderRadius="60px"
              border="2px solid white"
              alignItems="center"
              display="inline-flex"
              justifyContent="center"
              marginBottom={2}
              color="white"
            >
              {useRenderContentIcon(type, vendor)}
            </Box>

            {type === "track" && (
              <Text
                fontSize="xs"
                fontFamily="serif"
                color="white"
                width="100%"
                as="i"
              >
                {setContentTypeTitle(type)}
              </Text>
            )}

            <Text fontWeight={600} fontSize="24px" mb={4} flex={1}>
              {title}
            </Text>
          </Stack>
        </Link>
      )}

      {cardType === "list" && (
        <Card
          isFirst={isFirst}
          contentLink={contentUrl}
          vendor={vendor}
          title={title}
          contentType={type}
          contentTypeTitle={setContentTypeTitle(type, vendor)}
          imageUrl={imageUrl}
          imageAlignment={imageAlignment}
          productDetails={productDetails}
        />
      )}
    </div>
  );
}

ContentCard.defaultProps = {
  imageUrl: "",
  vendor: "",
  isFirst: false,
  isResource: false,
  coverImageJustification: "center",
  isRecommendation: false,
  productDetails: null,
  isStatic: false,
};
