import {
  Box,
  ButtonGroup,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Input,
  Stack,
  Text,
  useToken,
} from "@chakra-ui/react";
import {
  Check,
  Close,
  Delete,
  EditSimple,
} from "@/client/components/icons/ContinuIcons";

import { InView } from "react-intersection-observer";
import Loading from "@/client/components/media/Loading";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useSkillService } from "@/client/services/hooks/admin/connect/useSkillService";
import { useSkillsStore } from "@/client/services/state/admin/create/skillsStore";
import { useState } from "react";

export interface Skill {
  _id: string;
  name: string;
  user: SkillUser;
  createdAt: string;
  blocked: boolean;
}

interface SkillUser {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  image: string;
  full_name: string;
}

interface SkillsListProps {
  skills: Skill[];
  selectedIds: string[];
  handleSelectItem: (id: string) => void;
  selectedEditItem: Skill | null;
  setSelectedEditItem: (skill: Skill | null) => void;
  setModalIsOpen: (isOpen: boolean) => void;
}

export default function SkillsList({
  skills,
  selectedIds,
  handleSelectItem,
  selectedEditItem,
  setSelectedEditItem,
  setModalIsOpen,
}: SkillsListProps) {
  const [value, setValue] = useState<string>("");
  const [brandPrimary] = useToken("colors", ["brand.primary"]);
  const { editSkill, getSkills, searchSkills } = useSkillService();
  const { isSearching } = useSkillsStore();

  const { fetchNextPage, status } = getSkills;
  const { fetchNextPage: searchFetchNextPage, status: searchStatus } =
    searchSkills;

  return (
    <Stack>
      {skills.map((skill) => (
        <Box
          key={skill._id}
          borderBottom="1px solid"
          borderBottomColor="rgba(178, 178, 178, .9)"
        >
          <Flex
            paddingY={1}
            paddingX={4}
            alignItems="center"
            backgroundColor={
              selectedIds.includes(skill._id)
                ? hexToRGBA(brandPrimary, 0.2)
                : "white"
            }
            _hover={{ backgroundColor: hexToRGBA(brandPrimary, 0.05) }}
          >
            <Checkbox
              marginRight={4}
              value={skill._id}
              isChecked={selectedIds?.includes(skill._id)}
              onChange={(e) => handleSelectItem(e.target.value)}
            />

            {selectedEditItem !== skill && (
              <>
                <Text fontWeight="medium" fontSize="14px">
                  {skill.name}
                </Text>

                <ButtonGroup
                  color="brand.button"
                  flex={1}
                  isAttached
                  display="flex"
                  justifyContent="end"
                >
                  <IconButton
                    variant="unstyled"
                    aria-label="Edit"
                    icon={<EditSimple />}
                    onClick={() => {
                      setSelectedEditItem(skill);
                      setValue(skill.name);
                    }}
                  />
                </ButtonGroup>
              </>
            )}

            {selectedEditItem === skill && (
              <>
                <Input
                  variant="flushed"
                  size="xs"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />

                <ButtonGroup isAttached>
                  <IconButton
                    variant="unstyled"
                    aria-label="Save"
                    icon={<Check color="green" />}
                    onClick={() =>
                      editSkill.mutateAsync({ skillId: skill._id, name: value })
                    }
                  />

                  <IconButton
                    variant="unstyled"
                    aria-label="Cancel"
                    icon={<Close color="red" />}
                    onClick={() => {
                      setSelectedEditItem(null);
                      setValue("");
                    }}
                  />

                  <IconButton
                    variant="unstyled"
                    aria-label="Delete"
                    icon={<Delete color="red" />}
                    onClick={() => {
                      handleSelectItem(skill._id);
                      setModalIsOpen(true);
                    }}
                  />
                </ButtonGroup>
              </>
            )}
          </Flex>
        </Box>
      ))}

      <InView
        as="div"
        onChange={(inView) => {
          if (inView) {
            isSearching ? searchFetchNextPage() : fetchNextPage();
          }
        }}
      />

      {isSearching ? (
        <Center>{searchStatus === "loading" && <Loading />}</Center>
      ) : (
        <Center>{status === "loading" && <Loading />}</Center>
      )}
    </Stack>
  );
}
