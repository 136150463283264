import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import HeadingWithTooltip from "../HeadingWithTooltip";
import InputWithToolTip from "../InputWithTooltip";
import { Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function UserExtras() {
  const { t } = useTranslation();

  return (
    <AdminElevatedBox>
      <HeadingWithTooltip title={t("userManage.extras")} />

      <Stack spacing={4}>
        <InputWithToolTip name="region" label={t("userManage.extras.region")} />

        <InputWithToolTip
          name="distributor"
          label={t("userManage.extras.distributor")}
        />

        <InputWithToolTip name="group" label={t("userManage.extras.group")} />

        <InputWithToolTip
          name="address"
          label={t("userManage.extras.address")}
        />
      </Stack>
    </AdminElevatedBox>
  );
}
