import { BaseEditor, Descendant, createEditor } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { useEffect, useState } from "react";

import { deserialize } from "../../utils/content/slate/deserialize";
import { serialize } from "../../utils/content/slate/serialize";
import { useTranslation } from "react-i18next";

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type SlateEditorProps = {
  value: string;
  setAbout: (value: string) => void;
  placeholder?: string;
};

export default function SlateEditor({
  value,
  setAbout,
  placeholder,
}: SlateEditorProps) {
  const [editor] = useState(() => withReact(createEditor()));
  const { t } = useTranslation();

  const formatInitialValue = (value: string) => {
    const document = new DOMParser().parseFromString(value, "text/html");

    return deserialize(document.body);
  };

  const initialValue: Descendant[] =
    value && value !== "<p><br /></p>"
      ? formatInitialValue(value)
      : [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ];

  const onHandleChange = (value: Descendant[]) => {
    const isAstChange = editor.operations.some(
      (operation) => "set_selection" !== operation.type
    );

    if (isAstChange) {
      setAbout(serialize(value));
    }
  };

  const placeholderText = t("global.forms.labels_typeYourText");

  return (
    <>
      <Slate
        editor={editor}
        value={initialValue}
        onChange={(value) => onHandleChange(value)}
      >
        <Editable placeholder={placeholder || placeholderText} />
      </Slate>
    </>
  );
}
