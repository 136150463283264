import { Box, Center, Image, Text, VStack } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

export default function ViewOnDesktop({ show }: { show: boolean }) {
  const { t } = useTranslation();

  return (
    <Box
      display={!show ? "none" : "flex"}
      backgroundColor="white"
      position="sticky"
      zIndex={900}
      top={50}
      left={{ base: 0, lg: 50 }}
      right={0}
    >
      <Center
        minHeight="100vh"
        maxWidth="container.sm"
        margin="0 auto"
        padding={{ base: 12, md: 0 }}
      >
        <VStack spacing={4}>
          <Image src="/public/images/view-on-desktop.png" />

          <Text>{t("admin.viewOnDesktop")}</Text>
        </VStack>
      </Center>
    </Box>
  );
}
