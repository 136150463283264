import {
  EntityDetails,
  Journey,
  UserJourney,
} from "@/client/types/content/Journey";
import { addDays, differenceInDays, format } from "date-fns";

const pathLibrary: { [key: string]: any } = {
  firstPath: { height: "4rem" },
  lastPath: { height: "4rem" },
  top: {
    completed: {
      "border-left": "solid 3px var(--chakra-colors-brand-primary)",
      height: "6rem",
      margin: "-2rem auto 0",
      width: "0",
    },
    locked: {
      "border-left": "dashed 3px gray",
      margin: "-2rem auto 0",
      height: "6rem",
      width: "0",
    },
    available: {
      "border-left": "dashed 3px var(--chakra-colors-brand-primary)",
      height: "4rem",
      margin: "0 auto",
      width: "0",
    },
  },
  bottom: {
    completed: {
      "border-left": "solid 3px var(--chakra-colors-brand-primary)",
      height: "6rem",
      margin: "0 auto 0",
      width: "0",
    },
    locked: {
      "border-left": "dashed 3px gray",
      margin: "0 auto 0",
      height: "6rem",
      width: "0",
    },
    available: {
      "border-left": "dashed 3px var(--chakra-colors-brand-primary)",
      margin: "0 auto",
      height: "4rem",
      width: "0",
    },
  },
};

export const formatJourney = (
  journey: Journey,
  userJourney: UserJourney
): any => {
  let progress = "0%";
  let userProgress = { width: 0 };
  let progressMap: { [key: string]: any } = {};
  let availableIndex = 0;

  const mapContentProgress = (): any => {
    availableIndex = 0;

    journey.satisfiable_entities.forEach((content, index) => {
      assignState(content.entity, content.availability_delay_in_days, index);
      pathStyle(content.entity, index);
      content.description = handleDescription(content.entity_details);
    });

    calculateOverallProgress();

    const obj = {
      //   journey: journey,
      progress: progress,
      userProgress: userProgress,
      progressMap: progressMap,
      availableIndex: availableIndex,
    };

    // console.log('Formatted journey: ', obj);
    return obj;
  };

  const assignState = (
    contentId: string,
    contentDelay: number,
    index: number
  ) => {
    progressMap[contentId] = {};
    progressMap[contentId].index = index;

    const selectedEntity =
      userJourney.satisfiable_entities &&
      userJourney.satisfiable_entities.find(
        (userEntity) => userEntity.entity === contentId
      );

    if (selectedEntity?.satisfied_status && index === availableIndex) {
      progressMap[contentId].state = "completed";
      availableIndex = index + 1;
      return;
    }

    if (index === availableIndex) {
      const dateAvailable = getDateAvailable(contentDelay, index);

      if (dateAvailable && dateAvailable.length) {
        progressMap[contentId].dateAvailable = dateAvailable;
        progressMap[contentId].state = "locked";
        return;
      }

      progressMap[contentId].state = "available";
      return;
    }

    progressMap[contentId].state = "locked";
  };

  const pathStyle = (contentId: string, index: number) => {
    const contentState = progressMap[contentId].state;
    progressMap[contentId].topPath = pathLibrary.top[contentState];
    progressMap[contentId].bottomPath = pathLibrary.bottom[contentState];

    const previousState =
      progressMap[journey.satisfiable_entities[index - 1]?.entity]?.state;

    if (contentState === "locked" && previousState !== "locked") {
      progressMap[contentId].topPath = pathLibrary.top.available;
    }

    if (previousState && previousState === "completed") {
      progressMap[contentId].topPath = pathLibrary.top.completed;
    }

    if (index === 0) {
      progressMap[contentId].topPath = pathLibrary.firstPath;
      progressMap[contentId].firstStep = true;
    }

    if (index === journey.satisfiable_entities.length - 1) {
      progressMap[contentId].bottomPath = pathLibrary.bottomPath;
      progressMap[contentId].lastStep = true;
    }
  };

  const handleDescription = (details: EntityDetails) => {
    const description = details.summary || details.description;
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = description;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  };

  const calculateOverallProgress = () => {
    if (!userJourney.satisfiable_entities?.length) {
      return;
    }

    const satisfied = userJourney.satisfiable_entities.reduce(
      (numberSatisfied, sE) => {
        if (sE.satisfied_status) {
          numberSatisfied++;
        }

        return numberSatisfied;
      },
      0
    );

    const percentComplete = Math.round(
      (satisfied / journey.satisfiable_entities.length) * 100
    );
    const progressStr = percentComplete.toString() + "%";
    progress = progressStr;
    userProgress.width = percentComplete;
  };

  const getDateAvailable = (contentDelay: number, index: number) => {
    if (!contentDelay) {
      return;
    }

    // We're getting the id of the step before and then finding that id
    // in the userJourney satisfiable entities because we can't guarantee that the userJourney
    // satisfiable entities will be in the right order
    const lastSatisfactionId = journey.satisfiable_entities[index - 1].entity;
    const lastSatisfaction = userJourney.satisfiable_entities?.find(
      (userEntity) => userEntity.entity === lastSatisfactionId
    );

    if (!lastSatisfaction) {
      return;
    }

    const today = new Date();
    const lastSatisfactionDate = new Date(lastSatisfaction.satisfied_date);
    // const daysSinceLastSatisfaction = moment(today).diff(lastSatisfactionDate, 'days');
    const daysSinceLastSatisfaction = differenceInDays(
      today,
      lastSatisfactionDate
    );

    if (daysSinceLastSatisfaction < contentDelay) {
      //   const dateAvailable = moment(lastSatisfactionDate).add(contentDelay, 'days').format('MM/DD/YYYY');
      const dateAvailable = addDays(
        lastSatisfactionDate,
        contentDelay
      ).toLocaleDateString();
      format(new Date(dateAvailable), "MM/dd/yyyy");
      return dateAvailable;
    }

    return;
  };

  return mapContentProgress();
};
