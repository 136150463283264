import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import NumberInputWithToolTip from "../forms/NumberInputWithTooltip";
import { Stack } from "@chakra-ui/react";
import SwitchWithTooltip from "../forms/SwitchWithTooltip";
import UserGroupSearch from "../input/search/UserGroupSearch";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function AssignmentModalDueDate() {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();
  const useDueDate = watch("due_date");
  const escalationContacts = watch("escalation_contacts");
  const setEscalationContacts = (value: any) =>
    setValue("escalation_contacts", value);

  return (
    <Stack spacing={6}>
      <SwitchWithTooltip
        name="due_date"
        label={t("assignment.settings.due_date")}
        tooltipText={t("assignment.settings.set_the_days")}
      />

      {useDueDate && (
        <NumberInputWithToolTip
          name="due_in"
          label={t("assignment.settings.assignment_due_in_date")}
          tooltipText={t("assignment.settings.set_the_days")}
          isRequired
        />
      )}

      <FormLabelWithTooltip
        label={t("assignment.settings.requireUniqueCompletions")}
        tooltipText={`${t(
          "assignment.settings.requireUniqueCompletions.tooltip"
        )}`}
      />

      <SwitchWithTooltip
        name="require_recompletion"
        label={t("assignment.settings.requireUniqueCompletions.label")}
        tooltipText=""
        isDisabled
      />

      <FormLabelWithTooltip
        label={t("assignmentSettings.escalation_to_manager")}
        tooltipText={`${t("assignmentSettings.escalate_manager_help_text")}`}
      />

      <SwitchWithTooltip
        name="escalate_manager"
        label={t("assignmentSettings.notiify_assignee_manager")}
        tooltipText=""
      />

      <FormLabelWithTooltip
        label={t("assignmentSettings.escalation_to_buddy")}
        tooltipText={`${t("assignmentSettings.escalate_buddy_help_text")}`}
      />

      <SwitchWithTooltip
        name="escalate_buddy"
        label={t("assignmentSettings.notiify_assignee_buddy")}
        tooltipText=""
      />

      <UserGroupSearch
        label={t("assignment.scheduler.escalation_contacts")}
        userFilter="all"
        selectedItems={escalationContacts}
        setSelectedItems={setEscalationContacts}
        includeSuspendedUsers
      />
    </Stack>
  );
}
