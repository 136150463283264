import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import CustomFieldsForm from "@/client/components/forms/settings/CustomFieldsForm";
import HeadingWithTooltip from "../HeadingWithTooltip";
import { useAuthStore } from "@/client/services/state/authStore";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function UserMetadata() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  const { watch, setValue, control } = useFormContext();

  const userId = watch("id");
  const userMetadata = watch("metadata");
  const companyCustomFields = watch("company.user_metadata_fields");

  const updateUserMetadata = (metadata: any) => setValue("metadata", metadata);

  return (
    <AdminElevatedBox>
      <HeadingWithTooltip title={t("userManage.customFields")} />

      <CustomFieldsForm
        userId={userId}
        metadata={userMetadata}
        companyCustomFields={
          companyCustomFields || company.user_metadata_fields
        }
        control={control}
        setValue={(payload) => updateUserMetadata(payload)}
      />
    </AdminElevatedBox>
  );
}
