import type {
  Content,
  Element,
  TimeToComplete,
} from "@/client/types/admin/content-authoring/tracks/imported-content/LinkedInSearchData";

import ImportedContentService from "@/client/services/api/ImportedContentService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEditInstructors } from "../useEditInstructors";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useAuthorLinkedInLearning = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { id } = useParams();
  const { setValue, watch } = useFormContext();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setTabIndex } = useCreateStore();
  const { createInstructorMutation } = useEditInstructors();

  const tags = watch("tags");

  const generateLinkedinDuration = (item: TimeToComplete) => {
    if (!item.duration) {
      return 0;
    }
    switch (item.unit) {
      case "SECOND":
        return item.duration;
      case "MINUTE":
        return item.duration * 60;
      case "HOUR":
        return item.duration * 3600;
      default:
        return item.duration;
    }
  };

  const generateLilStructure = (contents: Content[], list: any) => {
    if (!contents || !contents.length || !list) {
      return;
    }

    contents.forEach((item) => {
      const children: any = [];

      if (
        item &&
        item.asset &&
        item.asset.contents &&
        item.asset.contents.length
      ) {
        generateLilStructure(item.asset.contents, children);
      }

      const structureItem = {
        title:
          (item && item.asset && item.asset.title && item.asset.title.value) ||
          "==",
        type: (item && item.asset && item.asset.type) || "--",
        children,
        difficulty:
          (item &&
            item.asset &&
            item.asset.details &&
            item.asset.details.level) ||
          "--",
        duration: 0,
      };

      if (
        item &&
        item.asset &&
        item.asset.details &&
        item.asset.details.description &&
        item.asset.details.description.value
      ) {
        // @ts-ignore
        structureItem.description = item.asset.details.description.value;
      }

      let duration = 0;
      if (
        item &&
        item.asset &&
        item.asset.details &&
        item.asset.details.timeToComplete
      ) {
        duration = generateLinkedinDuration(item.asset.details.timeToComplete);
      }

      if (structureItem.type === "CHAPTER") {
        duration = 0;
        children.forEach((child: any) => {
          if (!child.duration) {
            return;
          }
          duration += child.duration;
        });
      }
      structureItem.duration = duration;

      list.push(structureItem);
    });
  };

  const processLinkedinImport = async (item: Element) => {
    try {
      const link =
        item &&
        item.details &&
        item.details.urls &&
        item.details.urls.webLaunch;

      if (!link) {
        setToast({
          show: true,
          status: "error",
          title: t("authoring.courses.import.error"),
        });

        return;
      }

      if (!id) setValue("creator", user._id);

      setValue("vendor", "linkedin_learning");

      setValue("vendorLink", link);

      setValue("vendorFullLink", link);

      setValue("vendorContentId", item.urn);

      setValue("title", item.title?.value || "");

      setValue("description", item.details?.description?.value || "");

      setValue("difficulty", item.details?.level || "--");

      if (
        item.details &&
        item.details.classifications &&
        item.details.classifications.length
      ) {
        item.details.classifications.forEach((classification) => {
          const name =
            (classification &&
              classification.associatedClassification &&
              classification.associatedClassification.name &&
              classification.associatedClassification.name.value) ||
            null;

          if (name) {
            setValue("tags", [...tags, name]);
          }
        });
      }

      if (
        item.details &&
        item.details.contributors &&
        item.details.contributors.length
      ) {
        await createInstructorMutation
          .mutateAsync({
            name: item.details.contributors[0].name.value,
            bio: "",
            reusable: false,
          })
          .then((instructorId) => {
            setValue("instructors", [
              {
                name: item.details.contributors[0].name.value,
                id: instructorId,
              },
            ]);
          });
      }

      if (item.details?.images && item.details.images.primary) {
        setValue("image", item.details.images.primary);

        setValue("bannerImage", item.details.images.primary);
      }
      if (item.details && item.details.timeToComplete) {
        setValue(
          "duration",
          generateLinkedinDuration(item.details.timeToComplete)
        );
      }

      const structure: any = [];
      // generateLinkedinStructure(item.contents);
      generateLilStructure(item.contents, structure);

      setValue("lilStructure", structure);

      setToast({
        show: true,
        status: "success",
        title: t("authoring.courses.import.success"),
      });

      setTabIndex(1);
    } catch (error) {
      console.log("error", error);

      setToast({
        show: true,
        status: "error",
        title: t("authoring.courses.import.error"),
      });
    }
  };

  const getLinkedinCourseByUrn = useMutation({
    mutationFn: (urn: string) =>
      ImportedContentService.getLinkedinCourseByUrn(urn),
    onSuccess: (data) => processLinkedinImport(data),
  });

  return {
    processLinkedinImport,
    getLinkedinCourseByUrn,
  };
};
