import { Box, Stack } from "@chakra-ui/react";

import InputWithToolTip from "@/client/components/admin/forms/InputWithTooltip";
import { useTranslation } from "react-i18next";

export default function ProfileGeneralDetails() {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={6}>
      <Box width="40%">
        <InputWithToolTip
          name="name"
          label={t("global.forms.labels_name")}
          tooltipText={t("profiles.edit.profileNameHelpText")}
          isRequired
        />
      </Box>

      <Box width="full">
        <InputWithToolTip
          name="description"
          label={t("global.forms.labels_description")}
          tooltipText={t("profiles.edit.profileDescriptionHelpText")}
          isRequired
        />
      </Box>
    </Stack>
  );
}
