import { Box, Divider, Text } from "@chakra-ui/react";

import { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import AssessmentGraderSearch from "./AssessmentGraderSearch";
import CreateSwitchContainer from "../../../shared/layout/CreateSwitchContainer";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function AssessmentGradingSettingsForm() {
  const { t } = useTranslation();
  const { watch } = useFormContext<AssessmentFormData>();
  const allowManagerGrading = watch("allowManagerGrading");
  const allowBuddyGrading = watch("allowBuddyGrading");

  return (
    <>
      <Box paddingY={4}>
        <Text variant="createHeading">
          {t("authoring.assessment.gradingSettings")}
        </Text>
      </Box>

      <CreateSwitchContainer
        name="allowManagerGrading"
        label={t(
          "authoring.assessment.gradingSettings.allowManagerGrading.label"
        )}
        helpText={t(
          "authoring.assessment.gradingSettings.allowManagerGrading.helpText"
        )}
      />

      {allowManagerGrading && (
        <Box paddingLeft={10}>
          <Divider marginY={4} borderColor="neutral.100" />

          <CreateSwitchContainer
            name="assignManagerGrading"
            label={t(
              "authoring.assessment.gradingSettings.assignManagerGrading.label"
            )}
            helpText={t(
              "authoring.assessment.gradingSettings.assignManagerGrading.helpText"
            )}
          />
        </Box>
      )}

      <Divider marginY={4} borderColor="neutral.100" />

      <CreateSwitchContainer
        name="allowBuddyGrading"
        label={t(
          "authoring.assessment.gradingSettings.allowBuddyGrading.label"
        )}
        helpText={t(
          "authoring.assessment.gradingSettings.allowBuddyGrading.helpText"
        )}
      />

      {allowBuddyGrading && (
        <Box paddingLeft={10}>
          <Divider marginY={4} borderColor="neutral.100" />

          <CreateSwitchContainer
            name="assignBuddyGrading"
            label={t(
              "authoring.assessment.gradingSettings.assignBuddyGrading.label"
            )}
            helpText={t(
              "authoring.assessment.gradingSettings.assignBuddyGrading.helpText"
            )}
          />
        </Box>
      )}
      <Divider marginY={4} borderColor="neutral.100" />

      <AssessmentGraderSearch />
    </>
  );
}
