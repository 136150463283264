import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import { Category } from "@/client/types/Category";
import { PaginationState } from "@tanstack/react-table";
import UserGroupSearchService from "@/client/services/api/admin/segmentation/UserGroupSearchService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useDebounce } from "usehooks-ts";
import { useMultipleSelection } from "downshift";

// TODO: Move admin user search from  `useUserSearch.ts` here

export const useAdminUsersSearch = (
  type: "confirmed" | "pending" | "suspended"
) => {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategories, setFilteredCategories] = useState<
    { title: string; options: Category[] }[]
  >([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const [{ pageIndex, pageSize }, setSearchPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const searchFetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const searchPagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (debouncedSearchTerm === "") {
      setFilteredCategories([]);
    }
  }, [debouncedSearchTerm]);

  const handleSelectedUsersChange = useCallback((data: any[]) => {
    setSelectedUsers(data);
  }, []);

  useEffect(() => {
    if (selectedItems.length > 0 || searchTerm !== "") {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  }, [selectedItems, searchTerm]);

  const handleCreateItem = (item: Category) => {
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (
    newSelectedItems: Category[],
    changeType: string
  ) => {
    if (newSelectedItems.length > 0) {
      if (
        changeType ===
          useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace ||
        changeType ===
          useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem
      ) {
        setSelectedItems(newSelectedItems);
        return;
      }

      const isUser =
        newSelectedItems[newSelectedItems.length - 1].type === "user";

      if (isUser) {
        setSelectedItems([newSelectedItems[newSelectedItems.length - 1]]);
        return;
      }

      const newItem = newSelectedItems[newSelectedItems.length - 1];

      const match = selectedItems.find((item) => item.type === newItem.type);

      if (match) {
        const existingIndex = selectedItems.findIndex(
          (item) => item.type === newItem.type
        );
        const newArr = [...selectedItems];

        newArr[existingIndex] = newItem;

        setSelectedItems(newArr);
      } else {
        setSelectedItems(newSelectedItems);
      }
    } else {
      setSelectedItems(newSelectedItems);
    }
  };

  const { data: comboboxOptions } = useQuery({
    enabled:
      type === "confirmed" ||
      (type === "pending" && debouncedSearchTerm !== ""),
    queryKey: [
      "admin-users",
      debouncedSearchTerm,
      "all, false",
      company.groups,
    ],
    queryFn: () =>
      UserGroupSearchService.search(
        debouncedSearchTerm,
        "all",
        false,
        company.groups
      ),
  });

  const { data: suspendedComboboxOptions } = useQuery({
    enabled: type === "suspended" && debouncedSearchTerm !== "",
    queryKey: [
      "admin-users-suspended",
      debouncedSearchTerm,
      "all, true",
      company.groups,
    ],
    queryFn: () =>
      UserGroupSearchService.search(
        debouncedSearchTerm,
        "all",
        true,
        company.groups,
        true
      ),
  });

  const searchFromQuery = async () => {
    if (!selectedItems.length) return;

    let query = {};

    const role = selectedItems.find((item) => item.type === "role");
    const hiredOn = selectedItems.find((item) => item.type === "hiredOn");
    const createdOn = selectedItems.find((item) => item.type === "createdOn");
    const lastLogin = selectedItems.find((item) => item.type === "lastActive");
    const user = selectedItems.find((item) => item.filterType === "User");
    const locations = selectedItems.filter((item) => item.location);
    const departments = selectedItems.filter((item) => item.department);
    const teams = selectedItems.filter((item) => item.team);
    const grades = selectedItems.filter((item) => item.grade);
    const orgLevels = selectedItems.filter((item) => item.org_level);
    const text = selectedItems.find((item) => item.type === "text");
    const groups = selectedItems.filter((item) => item.filterType === "Group");

    if (text) {
      query = { text: text.value };
    }

    if (role) {
      query = { role: role._id };
    }

    if (hiredOn) {
      query = { ...query, hired: hiredOn._id };
    }

    if (createdOn) {
      query = { ...query, createdAt: createdOn._id };
    }

    if (lastLogin) {
      query = { ...query, last_login: lastLogin._id };
    }

    if (user) {
      query = { ...query, users: [user._id] };
    }

    if (locations.length) {
      query = { ...query, locations: locations.map((item) => item._id) };
    }

    if (departments.length) {
      query = { ...query, departments: departments.map((item) => item._id) };
    }

    if (teams.length) {
      query = { ...query, teams: teams.map((item) => item._id) };
    }

    if (grades.length) {
      query = { ...query, grades: grades.map((item) => item._id) };
    }

    if (orgLevels.length) {
      query = { ...query, org_levels: orgLevels.map((item) => item._id) };
    }

    if (groups.length) {
      query = { ...query, groups: groups.map((item) => item._id) };
    }

    if (type === "pending") {
      query = { ...query, last_login: "$exists,false" };
    }

    if (type === "confirmed" && !lastLogin) {
      query = { ...query, last_login: "$exists,true" };
    }

    const response = await AdminUsersService.searchAdminUsersFromQuery(
      query,
      searchFetchDataOptions
    );

    return {
      rows: response.users,
      pageCount: Math.ceil(response.count / searchPagination.pageSize),
    };
  };

  const searchSuspended = async () => {
    if (!selectedItems.length) return;

    let queryStr = "";

    const user = selectedItems.find((item) => item.filterType === "User");
    const locations = selectedItems.filter((item) => item.location);
    const departments = selectedItems.filter((item) => item.department);
    const teams = selectedItems.filter((item) => item.team);
    const grades = selectedItems.filter((item) => item.grade);
    const orgLevels = selectedItems.filter((item) => item.org_level);
    const text = selectedItems.find((item) => item.type === "text");

    if (user) {
      queryStr += `_id=${user._id}`;
    }

    if (locations.length) {
      queryStr += `&linked_locations=${locations
        .map((item) => item._id)
        .join(",")}`;
    }

    if (departments.length) {
      queryStr += `&linked_departments=${departments
        .map((item) => item._id)
        .join(",")}`;
    }

    if (teams.length) {
      queryStr += `&linked_teams=${teams.map((item) => item._id).join(",")}`;
    }

    if (grades.length) {
      queryStr += `&linked_grade=${grades.map((item) => item._id).join(",")}`;
    }

    if (orgLevels.length) {
      queryStr += `&linked_org_level=${orgLevels
        .map((item) => item._id)
        .join(",")}`;
    }

    if (text) {
      queryStr += `&full_name=$regex,2,${text.value},i&sort=full_name`;
    }

    const response = await AdminUsersService.searchSuspendedUsers(queryStr);

    return {
      rows: response,
      pageCount: 1,
    };
  };

  const searchFromQueryMutation = useMutation({
    mutationFn: searchFromQuery,
  });

  const searchSuspendedMutation = useMutation({
    mutationFn: searchSuspended,
  });

  useEffect(() => {
    if (type === "confirmed" || type === "pending") {
      searchFromQueryMutation.mutate();
    }

    searchSuspendedMutation.mutate();
  }, [selectedItems, searchPagination]);

  return {
    selectedUsers,
    setSelectedUsers,
    selectedItems,
    setSelectedItems,
    searchTerm,
    setSearchTerm,
    filteredCategories,
    setFilteredCategories,
    handleSelectedUsersChange,
    handleCreateItem,
    handleSelectedItemsChange,
    comboboxOptions,
    suspendedComboboxOptions,
    searchFromQueryMutation,
    searchSuspendedMutation,
    searchActive,
    searchPagination,
    setSearchPagination,
    searchFetchDataOptions,
  };
};
