export const Progress = {
  baseStyle: {
    filledTrack: {
      bg: "brand.primary",
    },
  },
  variants: {
    hollow: {
      track: {
        bg: "transparent",
        borderRadius: 4,
        border: "2px solid white",
        width: "90px",
        height: "8px",
      },
      filledTrack: {
        bg: "white",
        borderRadius: 4,
      },
    },
    hollowPrimary: {
      track: {
        bg: "transparent",
        borderRadius: 4,
        borderColor: "brand.primary",
        borderWidth: "2px",
        width: "90px",
        height: "8px",
      },
      filledTrack: {
        bg: "brand.primary",
        borderRadius: 4,
      },
    },
    create: {
      track: {
        background: "warmNeutral.200",
        height: "2px",
      },
      filledTrack: {
        background: "warmNeutral.700",
      },
    },
    createDisabled: {
      track: {
        background: "warmNeutral.200",
        height: "2px",
      },
      filledTrack: {
        background: "warmNeutral.200",
      },
    },
  },
};
