import { Stack } from "@chakra-ui/react";
import AuthoringContentSearch from "../../create/content/forms/content/AuthoringContentSearch";
import { useFormContext } from "react-hook-form";
import {
  Product,
  ContentDetails,
} from "@/client/services/api/graphql/gql/graphql";
import { AdminContent } from "@/client/types/content/AdminContent";

export default function ProductAccessForm() {
  const { setValue, watch } = useFormContext<Product>();
  const formFieldName = "contentListDetails";
  const contentListDetails = watch(formFieldName, []);

  const selectContents = (items: (AdminContent | ContentDetails)[]) => {
    const formattedContentDetails: ContentDetails[] = items.map((item) => {
      // ContentDetails
      if ("id" in item) {
        return item;
      }
      const displayName = item.title || item.name || "";
      return {
        id: item._id,
        image: item.image,
        type: item.type,
        displayName,
      };
    });
    setValue(formFieldName, formattedContentDetails);
  };

  return (
    <Stack spacing={6} paddingBottom={20}>
      <AuthoringContentSearch
        selectedItems={contentListDetails || []}
        setSelectedItems={selectContents}
        formField={formFieldName}
        includeJourneys={false}
      />
    </Stack>
  );
}
