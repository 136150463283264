import AvatarEditorModal from "@/client/components/shared/overlay/AvatarEditorModal";

interface AvatarUploadFormProps {
  uploadElement: JSX.Element;
  handleUpload: (file: File) => void;
  isLoading: boolean;
}

export default function AvatarUploadForm({
  uploadElement,
  handleUpload,
  isLoading,
}: AvatarUploadFormProps) {
  return (
    <>
      {uploadElement}

      <AvatarEditorModal handleUpload={handleUpload} isLoading={isLoading} />
    </>
  );
}
