import { Navigate, Outlet } from "react-router-dom";

import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";

export default function AdminSettingsLayout() {
  const { allowance } = useAccessHelper();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  if (!company.groups || !allowance.connectGroups)
    return <Navigate to="/explore" replace />;

  return <Outlet />;
}
