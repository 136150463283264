import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { CheckCircle, Save } from "@/client/components/icons/ContinuIcons";

import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import ThemeService from "@/client/services/api/admin/theme/ThemeService";
import { useConfigStore } from "@/client/services/state/configStore";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";

export default function ExploreCardForm() {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { config, setConfig } = useConfigStore();
  const [isListView, setIsListView] = useState(
    config.features.explore_in_list_view
  );

  const setThemeCards = useMutation({
    mutationFn: () => ThemeService.setThemeCards(isListView ? "list" : "card"),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("admin.theme.updateSuccess"),
      });

      setConfig({
        ...config,
        features: { ...config.features, explore_in_list_view: isListView },
      });
    },
    onError: () => {
      setToast({
        show: true,
        status: "error",
        title: t("admin.theme.updateError"),
      });
    },
  });

  return (
    <AdminElevatedBox>
      <Flex justifyContent="space-between">
        <Text marginTop={0} as="h4">
          {t("global.vertNavbar.explore")}
        </Text>

        <Button
          size="xs"
          variant="adminPrimary"
          leftIcon={<Save />}
          isDisabled={isListView === config.features.explore_in_list_view}
          isLoading={setThemeCards.isLoading}
          onClick={() => setThemeCards.mutateAsync()}
        >
          {t("global.action.save")}
        </Button>
      </Flex>

      <Box paddingLeft={4}>
        <FormLabelWithTooltip
          label={t("admin.theme.exploreCardDesign")}
          tooltipText={`${t("admin.theme.exploreCardDesignHelp")}`}
        />

        <HStack spacing={4}>
          <Box
            backgroundColor="rgba(247, 247, 247, 1)"
            position="relative"
            padding="18px 32px 10px"
            width="194px"
            borderRadius="10px"
            marginRight="16px"
            _hover={{ cursor: "pointer" }}
            transition="0.3s ease all"
            boxShadow={isListView ? "0 2px 2px rgba(0,0,0,.25)" : "none"}
            onClick={() => setIsListView(true)}
          >
            <Box
              position="absolute"
              top="10px"
              right="10px"
              width="16px"
              height="16px"
              background="white"
              border="0px solid white"
              boxShadow="inset 2px 2px 2px rgba(0, 0, 0, 0.1)"
              borderRadius="20px"
              transition="0.3s ease all"
            >
              {isListView && <CheckCircle position="absolute" color="green" />}
            </Box>

            <Box
              height="149px"
              minHeight="149px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundSize="cover"
              backgroundImage="url('https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/new-card.png')"
            />

            <VStack spacing={0.5} marginTop={2} fontSize="10px">
              <Text as="b">{t("admin.theme.suggestedImageSize")}</Text>

              <Text>{t("admin.theme.16x9")}</Text>

              <Text>{t("admin.theme.1920x1080")}</Text>
            </VStack>
          </Box>

          <Box
            backgroundColor="rgba(247, 247, 247, 1)"
            position="relative"
            padding="18px 32px 10px"
            width="194px"
            borderRadius="10px"
            marginRight="16px"
            _hover={{ cursor: "pointer" }}
            transition="0.3s ease all"
            boxShadow={isListView ? "none" : "0 2px 2px rgba(0,0,0,.25)"}
            onClick={() => setIsListView(false)}
          >
            <Box
              position="absolute"
              top="10px"
              right="10px"
              width="16px"
              height="16px"
              background="white"
              border="0px solid white"
              boxShadow="inset 2px 2px 2px rgba(0, 0, 0, 0.1)"
              borderRadius="20px"
              transition="0.3s ease all"
            >
              {!isListView && <CheckCircle position="absolute" color="green" />}
            </Box>

            <Box
              height="149px"
              minHeight="149px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundSize="cover"
              backgroundImage="url('https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/old-card.png')"
            />

            <VStack spacing={0.5} marginTop={2} fontSize="10px">
              <Text as="b">{t("admin.theme.suggestedImageSize")}</Text>

              <Text>{t("admin.theme.2x3")}</Text>

              <Text>{t("admin.theme.1280x1920")}</Text>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </AdminElevatedBox>
  );
}
