import SegmentationService from "../../api/admin/segmentation/SegmentationService";
import { useQuery } from "@tanstack/react-query";

export const useSegmentationService = () => {
  const getSegmentationList = (
    isEnabled: boolean,
    options: { pageIndex: number; pageSize: number },
    userIds: string[]
  ) =>
    useQuery({
      enabled: isEnabled,
      queryKey: ["getSegmentationList", options, userIds],
      queryFn: () => SegmentationService.getSegmentationList(options, userIds),
    });

  return {
    getSegmentationList,
  };
};
