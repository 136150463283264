import { Center, Link, Text, VStack } from "@chakra-ui/react";

import { useConfigStore } from "@/client/services/state/configStore";

export default function Suspended() {
  const { config } = useConfigStore();

  return (
    <Center
      minHeight="100vh"
      backgroundColor="blue.400"
      backgroundImage={`url(${config.publicImageUrl}404.png)`}
    >
      <VStack spacing={6}>
        <Text as="h1" fontSize="5xl" color="white">
          There's a Problem with your Account
        </Text>

        <Text color="white" fontSize="2xl">
          Please contact your administrator for more information.
        </Text>

        <Text color="white">
          For Continu Support, please review our{" "}
          <Link
            isExternal
            href={config.supportCenterBrandLocation}
            color="white"
            _hover={{ textDecoration: "underline" }}
          >
            Support Center
          </Link>
        </Text>
      </VStack>
    </Center>
  );
}
