/* eslint-disable react/jsx-props-no-spreading */

import {
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  chakra,
  useCheckbox,
} from "@chakra-ui/react";

import { Check } from "@/client/components/icons/ContinuIcons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// ! Hacky workaround to get these to work without styling them via the theme

function CustomCheckbox(props: any) {
  const { t } = useTranslation();
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);
  const { color } = props;

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      gridColumnGap={2}
      maxW="36"
      py={1}
      cursor="pointer"
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor={state.isChecked ? color : "brand.gray.100"}
        w={4}
        h={4}
        {...getCheckboxProps()}
      >
        {state.isChecked && (
          <Flex
            justifyContent="center"
            alignItems="center"
            width="full"
            height="full"
            bg={color}
          >
            <Check boxSize={2} color="white" />
          </Flex>
        )}
      </Flex>
      <Text color="gray.700" {...getLabelProps()}>
        {t("admin.theme.samples.checkbox")}
      </Text>
    </chakra.label>
  );
}

export default function SampleFormInputs({
  overrideColor,
}: {
  overrideColor: string;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");

  return (
    <Stack spacing={4}>
      <RadioGroup onChange={setValue} value={value}>
        <Stack direction="row">
          <Radio
            _before={{ color: overrideColor || "brand.primary" }}
            value="1"
          >
            <Text color={overrideColor || "brand.primary"}>
              {t("admin.theme.samples.radio.first")}
            </Text>
          </Radio>

          <Radio
            _before={{ color: overrideColor || "brand.primary" }}
            value="2"
          >
            <Text color={overrideColor || "brand.primary"}>
              {t("admin.theme.samples.radio.second")}
            </Text>
          </Radio>

          <Radio
            _before={{ color: overrideColor || "brand.primary" }}
            value="3"
          >
            <Text color={overrideColor || "brand.primary"}>
              {t("admin.theme.samples.radio.third")}
            </Text>
          </Radio>
        </Stack>
      </RadioGroup>

      <CustomCheckbox color={overrideColor || "brand.primary"} />
    </Stack>
  );
}
