export const NumberInput = {
  variants: {
    adminInput: {
      field: {
        background: "rgba(249, 249, 249, 1)",
        fontSize: "14px",
        borderRadius: "5px",
        _placeholder: {
          color: "neutral.60",
        },
      },
    },
    create: {
      field: {
        border: "1px",
        borderColor: "warmNeutral.200",
        _focusWithin: {
          borderColor: "warmNeutral.600",
          boxShadow: "none",
        },
      },
    },
  },
};
