import {
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";

import AvatarEditor from "../media/images/AvatarEditor";
import { useAvatarStore } from "@/client/services/state/shared/avatarStore";

interface AvatarEditorModalProps {
  handleUpload: (file: File) => void;
  isLoading: boolean;
}

export default function AvatarEditorModal({
  handleUpload,
  isLoading,
}: AvatarEditorModalProps) {
  const { avatar, reset } = useAvatarStore();

  return (
    <Modal isCentered isOpen={avatar.editorModalOpen} onClose={reset}>
      <ModalOverlay />

      <ModalContent padding={4}>
        <Center>
          {isLoading && <Spinner />}

          {!isLoading && <AvatarEditor handleUpload={handleUpload} />}
        </Center>
      </ModalContent>
    </Modal>
  );
}
