import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { PlusCircle } from "../icons/ContinuIcons";
import { useTranslation } from "react-i18next";

type HeaderProps = {
  addPartner?: () => void;
};

export default function PartnersHeader({ addPartner }: HeaderProps) {
  const { t } = useTranslation();
  return (
    <Box
      backgroundColor="white"
      marginTop={2}
      marginX={4}
      padding={4}
      borderRadius="xl"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      <Flex
        direction={{ base: "column", lg: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="xl" lineHeight="1.2" fontWeight="500" margin={2}>
          {t("overview.label.partners")}
        </Text>
        {addPartner && (
          <Button
            variant="adminPrimary"
            size="xs"
            leftIcon={<PlusCircle />}
            onClick={addPartner}
          >
            {t("partners.admin.createNewPartner")}
          </Button>
        )}
      </Flex>
    </Box>
  );
}

PartnersHeader.defaultProps = {
  addPartner: null,
};
