import { Button, Center, Text, VStack } from "@chakra-ui/react";

import { ArrowRight } from "@/client/components/icons/ContinuIcons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssessmentAwaitingGrade() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Center
      minHeight="100vh"
      background="linear-gradient(135deg, #00BCF1 0, #0080a4 100%)"
    >
      <VStack>
        <Text as="h2" color="white">
          {t("assessViewer.answers_sent")}
        </Text>

        <Text as="h2" color="white">
          {t("assessViewer.youll_be_notified")}
        </Text>

        <Button
          variant="ghost"
          color="white"
          rightIcon={<ArrowRight />}
          onClick={() => navigate("/explore")}
        >
          {t("assessViewer.explore_content")}
        </Button>
      </VStack>
    </Center>
  );
}
