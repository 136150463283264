import {
  Avatar,
  Badge,
  Box,
  Link as ChakraLink,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { NotificationBell } from "@/client/components/icons/ContinuIcons";
import PeopleButton from "@/client/components/buttons/PeopleButton";
import QooperButton from "@/client/components/buttons/QooperButton";
import SupportModal from "../overlay/SupportModal";
import UserNotificationsDrawer from "../overlay/UserNotificationsDrawer";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import v3ApiService from "../../services/api/clients/v3ApiClient";

export default function UserMenu() {
  const { authConfig, logout } = useAuthStore();
  const { user, company } = authConfig;
  const navigate = useNavigate();
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
  const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { onOpen, onClose } = useDisclosure();
  const btnRef = useRef<any>();
  const [brandPrimary] = useToken("colors", ["brand.primary"]);
  const { config } = useConfigStore();
  const queryClient = useQueryClient();

  const { data: notifications } = useQuery({
    queryKey: ["notifications", user._id],
    queryFn: () => v3ApiService.getUserNotifications(user._id),
  });

  return (
    <Flex data-testid="user-menu">
      <HStack spacing={4}>
        {company.feature_flags.enable_qooper && <QooperButton />}
        {company.feature_flags.enable_explore_search && <PeopleButton />}

        <Box data-testid="notifications" position="relative">
          <IconButton
            backgroundColor={hexToRGBA(brandPrimary, 0.2)}
            zIndex={9999}
            aria-label="Notifications"
            borderRadius="full"
            size="sm"
            fontSize="xl"
            icon={<NotificationBell color={hexToRGBA(brandPrimary, 0.6)} />}
            onClick={() => setNotificationsOpen(true)}
            _hover={{ backgroundColor: hexToRGBA(brandPrimary, 0.1) }}
          />

          {notifications && notifications.length > 0 && (
            <Badge
              position="absolute"
              backgroundColor="red"
              color="white"
              zIndex={9999}
              left="50%"
              fontSize=".5em"
            >
              {notifications.length}
            </Badge>
          )}
        </Box>

        <Menu variant="userMenu">
          <MenuButton aria-label="User Menu">
            <Avatar
              size="sm"
              name={user.full_name}
              ref={btnRef}
              onClick={onOpen}
              cursor="pointer"
              src={user.image}
            />
          </MenuButton>

          <MenuList zIndex={9999} minWidth={0} width="135px">
            <MenuItem
              onClick={() => {
                navigate("/settings");
                onClose();
              }}
            >
              {t("global.topbar.contextmenu_profileSettings")}
            </MenuItem>

            <MenuItem onClick={() => setSupportModalOpen(true)}>
              {t("global.topbar.contextmenu_support")}
            </MenuItem>

            {company.feature_flags.show_whatsnew && (
              <MenuItem>
                <ChakraLink
                  href="https://portal.productboard.com/mvgjhnm6y7d6xi7jran8zfvs/tabs/1-launched"
                  onClick={() => {
                    onClose();
                  }}
                  color="black"
                  isExternal
                  _hover={{ color: "black" }}
                >
                  {t("global.topbar.whatsNew")}
                </ChakraLink>
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                localStorage.removeItem("redirectLocation");
                localStorage.removeItem("preRedirect");
                logout();
                queryClient.clear();
                onClose();
              }}
            >
              {t("global.topbar.contextmenu_logout")}
            </MenuItem>

            <Divider
              opacity={1}
              width="85%"
              margin="0 auto"
              color="rgba(247, 247, 247, 1)"
            />

            {config.hubLinks &&
              config.hubLinks.length > 0 &&
              config.hubLinks.map((link) => (
                <MenuItem key={link.text}>
                  <Link
                    href={link.url}
                    color="black"
                    isExternal
                    _hover={{ color: "black" }}
                  >
                    {link.text}
                  </Link>
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </HStack>

      <UserNotificationsDrawer
        onClose={() => {
          setNotificationsOpen(false);
          onClose();
        }}
        isOpen={notificationsOpen}
        notifications={notifications}
      />

      <SupportModal
        onClose={onClose}
        supportModalOpen={supportModalOpen}
        setSupportModalOpen={setSupportModalOpen}
      />
    </Flex>
  );
}
