import { Controller, useFormContext } from "react-hook-form";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

interface ControlledRadioGroupProps {
  options: { label: string; value: string }[];
  name: string;
}

export default function ControlledRadioGroup({
  options,
  name,
}: ControlledRadioGroupProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <RadioGroup variant="admin" value={value} onChange={(v) => onChange(v)}>
          <Stack spacing={4}>
            {options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )}
    />
  );
}
