import {
  Instance,
  MultiSessionWorkshop,
  UserRegistrationInfo,
} from "@/client/types/content/MultiSessionWorkshop";
import { differenceInCalendarDays, format } from "date-fns";

import { FullRelatedWorkshop } from "@/client/types/content/Workshop";
import { getRelatedWorkshops } from "./shared/getRelatedWorkshops";
import { learnApiClient } from "../../clients/learnApiClient";

const getMultiSessionWorkshop = async (
  workshopId: string | undefined,
  userId: string
): Promise<{
  workshop: MultiSessionWorkshop;
  userRegistrationInfo: UserRegistrationInfo;
  relatedWorkshops: FullRelatedWorkshop[];
}> => {
  const workshop = await learnApiClient.get<MultiSessionWorkshop>(
    `multi-session-workshops/${workshopId}`
  );

  const formattedWorkshop = attachInstanceTimeOptions(workshop.data);
  const formatUserRegistration = setUserRegistrationStatus(
    workshop.data,
    userId
  );

  let relatedWorkshops: FullRelatedWorkshop[] = [];
  if (
    workshop.data.relatedWorkshops &&
    workshop.data.relatedWorkshops.length > 0
  ) {
    relatedWorkshops = await getRelatedWorkshops(
      workshop.data.relatedWorkshops
    );
  }

  return {
    workshop: formattedWorkshop,
    userRegistrationInfo: formatUserRegistration,
    relatedWorkshops,
  };
};

const registerForCohortWorkshop = async (
  workshopId: string | undefined,
  cohortId: string | undefined,
  userId: string
) => {
  const response = await learnApiClient.post(
    `multi-session-workshops/${workshopId}/register`,
    {
      cohortId,
      userId,
    }
  );

  return response.data;
};

const registerForVariedWorkshop = async (
  workshopId: string | undefined,
  instanceIds: string[],
  userId: string
) => {
  const response = await learnApiClient.post(
    `multi-session-workshops/${workshopId}/register`,
    {
      instanceIds,
      userId,
    }
  );

  return response.data;
};

const unregister = async (workshopId: string | undefined, userId: string) => {
  const response = await learnApiClient.post(
    `multi-session-workshops/${workshopId}/unregister`,
    {
      userId,
    }
  );

  return response.data;
};

export const getMSWIdFromSessionId = async (sessionId: string | undefined) => {
  const response = await learnApiClient.get(
    `multi-session-workshops/findParent/${sessionId}`
  );
  return response.data.id;
};

const MultiSessionWorkshopService = {
  getMultiSessionWorkshop,
  registerForCohortWorkshop,
  registerForVariedWorkshop,
  unregister,
};

export default MultiSessionWorkshopService;

const getInstanceAvailableSeats = (instance: Instance) => {
  const total = instance.capacity;
  let registered = 0;

  for (let i = 0; i < instance.userRegistrations.length; i += 1) {
    if (instance.userRegistrations[i].status === "registered") {
      registered += 1;
    }
  }

  return total - registered;
};

// Format instances
const formatInstance = (instance: Instance) => {
  let isAvailable = false;

  // Verify instance has seats left
  const availableSeats = getInstanceAvailableSeats(instance);

  if (availableSeats > 0) {
    isAvailable = true;
  }

  const venue = instance.online ? instance.instanceUrlTitle : instance.venue;
  const differenceInDays = differenceInCalendarDays(
    new Date(instance.start),
    new Date()
  );

  if (differenceInDays >= 0) {
    isAvailable = true;
  }

  const dateString =
    format(new Date(instance.start), "MMM d, yyyy") +
    " @ " +
    format(new Date(instance.start), "h:mm a") +
    " | " +
    venue;

  return {
    ...instance,
    formattedDate: dateString,
    isAvailable,
  };
};

//* Set user registration status
const setUserRegistrationStatus = (
  workshop: MultiSessionWorkshop,
  userId: string
) => {
  let userRegistrationCount = 0;
  let userAttendedCount = 0;
  let sessionCount = 0;
  let registeredInstancesBySessionId: { [key: string]: Instance } = {};
  let selectedInstancesBySessionId: { [key: string]: Instance } = {};
  let attendedSessions: { [key: string]: string } = {};
  let fullAttendedSessions: any[] = [];
  let triggerRating = false;
  let attendedSome = false;
  let attendedAll = false;
  let isRegistered = false;

  for (let i = 0; i < workshop.sessions.length; i++) {
    let session = workshop.sessions[i];
    sessionCount++;

    for (let j = 0; j < session.instances.length; j++) {
      let instance = session.instances[j];
      let formattedInstance = formatInstance(instance);

      let registration = instance.userRegistrations.find((userRegistration) => {
        if (
          userRegistration.userId === userId &&
          userRegistration.status === "attended"
        ) {
          registeredInstancesBySessionId[session.id] = formattedInstance;
          attendedSessions[session.id] = instance.id;
          userAttendedCount++;

          // Attended workshops for ratings
          let newSessionObj = { ...workshop.sessions[i] };
          newSessionObj.instance = session.instances[j];
          newSessionObj.rating_configuration = {
            allow_rating: newSessionObj.contentSettings.enableRatings,
            allow_edits: newSessionObj.contentSettings.enableEdits,
            allow_feedback: newSessionObj.contentSettings.enableFeedback,
            total_value: 5,
          };

          fullAttendedSessions.push(newSessionObj);
          triggerRating = true;
        }

        return (
          userRegistration.userId === userId &&
          userRegistration.status === "registered"
        );
      });

      if (!!registration) {
        registeredInstancesBySessionId[session.id] = formattedInstance;
        selectedInstancesBySessionId[session.id] = formattedInstance;

        if (new Date().valueOf() > new Date(instance.end).valueOf()) {
          // Count past 'registered' dates as 'attended' until marked otherwise
          attendedSessions[session.id] = instance.id;
          userAttendedCount++;
        } else {
          userRegistrationCount++;
        }
      }
    }

    const totalSessionCount = sessionCount;
    attendedSome =
      userAttendedCount > 0 && userAttendedCount < totalSessionCount;
    attendedAll = userAttendedCount === totalSessionCount;
    isRegistered =
      userRegistrationCount === totalSessionCount ||
      userRegistrationCount + userAttendedCount === totalSessionCount;
  }

  let registeredCohort;
  if (workshop.type === "cohort" && isRegistered) {
    const sessionIdForGettingCohort = workshop.sessions[0].id;
    const registeredInstanceId =
      registeredInstancesBySessionId[sessionIdForGettingCohort].id;

    registeredCohort = workshop.cohorts.find((cohort) =>
      cohort.instanceIds.includes(registeredInstanceId)
    );
  }

  return {
    attendedAll,
    attendedSome,
    isRegistered,
    triggerRating,
    registeredInstancesBySessionId,
    selectedInstancesBySessionId,
    registeredCohort,
    attendedSessions,
  };
};

//* Format Instances - Date - Availability
const attachInstanceTimeOptions = (workshop: MultiSessionWorkshop) => {
  const allSessionIds: string[] = [];
  const allInstances: Instance[] = [];
  const currentTime = new Date();

  for (let i = 0; i < workshop.sessions.length; i += 1) {
    const session = workshop.sessions[i];
    const sessionInstances: Instance[] = [];

    allSessionIds.push(session.id);

    const sortedDates = session.instances.sort(
      (a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf()
    );

    for (let j = 0; j < sortedDates.length; j += 1) {
      const instance = sortedDates[j];

      const formattedInstance = formatInstance(instance);
      if (currentTime > new Date(formattedInstance.end)) {
        formattedInstance.isAvailable = false;
      }
      sessionInstances.push(formattedInstance);
      allInstances.push(formattedInstance);
    }

    session.instanceOptions = sessionInstances;
    session.availableInstances = sessionInstances.filter(
      (instance) => instance.isAvailable
    );
    session.unavailableInstances = sessionInstances.filter(
      (instance) => !instance.isAvailable
    );
  }

  if (workshop.type === "cohort") {
    let availableCohorts = 0;

    for (let i = 0; i < workshop.cohorts.length; i += 1) {
      const cohort = workshop.cohorts[i];
      const cohortInstanceOptions: Instance[] = [];
      let hasExpiredCohortClass = false;

      cohort.instanceIds.forEach((instanceId) => {
        const currentInstance = allInstances.filter(
          (instance) => instance.id === instanceId
        )[0];

        if (!currentInstance) return;

        cohortInstanceOptions.push(currentInstance);

        if (currentTime > new Date(currentInstance.end)) {
          hasExpiredCohortClass = true;
        }
      });

      cohort.instanceOptions = cohortInstanceOptions;
      cohort.isAvailable = !hasExpiredCohortClass;
      if (!hasExpiredCohortClass) availableCohorts += 1;
      workshop.availableCohorts = availableCohorts;
    }
  }

  const allSortedInstances = allInstances
    .filter((instance) => new Date(instance.end) > currentTime)
    .sort((a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf());

  if (allSortedInstances.length > 0) {
    const workshopStartDate = allSortedInstances[0].start;
    const workshopEndDate =
      allSortedInstances[allSortedInstances.length - 1].end;

    workshop.start = workshopStartDate;
    workshop.end = workshopEndDate;
    workshop.availableSeats = getAllAvailableSeats(workshop);
  }

  return workshop;
};

const getAllAvailableSeats = (workshop: MultiSessionWorkshop) => {
  // session with lowest amount of seats between all instances marks number of available seats in the MSW
  let lowestSessionSeats;

  for (let i = 0; i < workshop.sessions.length; i++) {
    let sessionSeatsTotal = 0;
    let sessionSeatsTaken = 0;

    for (let j = 0; j < workshop.sessions[i].instances.length; j++) {
      let instance = workshop.sessions[i].instances[j];
      sessionSeatsTotal += instance.capacity;

      for (let k = 0; k < instance.userRegistrations.length; k++) {
        if (
          instance.userRegistrations[k].status === "registered" ||
          instance.userRegistrations[k].status === "attended" ||
          instance.userRegistrations[k].status === "absent"
        ) {
          sessionSeatsTaken++;
        }
      }
    }

    let sessionSeatsAvailable = sessionSeatsTotal - sessionSeatsTaken;

    if (
      (!lowestSessionSeats && lowestSessionSeats !== 0) ||
      sessionSeatsAvailable < lowestSessionSeats
    ) {
      lowestSessionSeats = sessionSeatsAvailable;
    }
  }

  return lowestSessionSeats || 0;
};
