/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import AccountDetails from "@/client/components/admin/forms/users/AccountDetails";
import BasicUserInfo from "@/client/components/admin/forms/users/BasicUserInfo";
import { Box } from "@chakra-ui/react";
import ManagerConfiguration from "../forms/users/ManagerConfiguration";
import { NewUser } from "@/client/types/UserManagement";
import Segmentation from "@/client/components/admin/forms/users/Segmentation";
import User from "@/client/services/api/User";
import UserAvatar from "@/client/components/admin/forms/users/UserAvatar";
import UserDetails from "@/client/components/admin/forms/users/UserDetails";
import UserExtras from "@/client/components/admin/forms/users/UserExtras";
import UserMetadata from "../forms/users/UserMetadata";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface UserGeneralDetailsFormProps {
  userData: NewUser | undefined;
}

export default function UserGeneralDetailsForm({
  userData,
}: UserGeneralDetailsFormProps) {
  const { t } = useTranslation();
  const routeParams = useParams();
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { partner } = usePartnerStore();

  const defaultUserFormValues = {
    role: "user",
    partner: partner._id,
    provisioning_status: "inviting",
    muted: false,
  };

  const methods = useForm<NewUser>({
    mode: "onChange",
    defaultValues: userData || defaultUserFormValues,
  });

  const { reset, watch } = methods;

  const isManager = watch("is_manager");

  useEffect(() => {
    if (!userData && !routeParams.id) reset(defaultUserFormValues);

    reset(userData);
  }, [userData]);

  const goBackAction = () => {
    partner._id ? navigate("/extend/manager/users") : navigate(-1);
  };

  const updateUser = useMutation({
    mutationFn: (data: NewUser) => User.updateUser(routeParams.id!, data),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("edit.useradd.success_implement"),
      });
    },
    onError: (error: Record<string, any>) => {
      console.error("error: ", error);

      const errorMessage = t("userManage.update.error");

      setToast({
        show: true,
        status: "error",
        title: errorMessage,
      });
    },
  });

  const createUser = useMutation({
    mutationFn: (data: NewUser) => User.createUser(data),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("userManage.create.success"),
      });
    },
    onError: (error: Record<string, any>) => {
      console.error("error: ", error);

      const errorMessage = t("userManage.create.error");

      setToast({
        show: true,
        status: "error",
        title: errorMessage,
      });
    },
  });

  const onSubmit: SubmitHandler<NewUser> = async (data) => {
    const userBody = {
      ...data,
    };

    if (!routeParams.id) {
      const provisioningStatus = data.provisioning_status.split("_")[0];

      userBody.muted = data.provisioning_status.includes("muted");
      userBody.provisioning_status =
        provisioningStatus === "inviting" ? "" : provisioningStatus;
    }

    routeParams.id
      ? updateUser.mutateAsync(userBody)
      : createUser.mutateAsync(userBody);
  };

  return (
    <Box>
      {(!routeParams.id || methods.formState.defaultValues?.first_name) && (
        <FormProvider {...methods}>
          <BasicUserInfo
            goBackAction={goBackAction}
            saveAction={methods.handleSubmit(onSubmit)}
            isLoading={updateUser.isLoading}
          />

          <UserAvatar />

          <AccountDetails />

          <UserDetails />

          {!partner._id && <Segmentation />}

          {!partner._id && isManager && <ManagerConfiguration />}

          <UserExtras />

          <UserMetadata />
        </FormProvider>
      )}
    </Box>
  );
}
