import { Box, Center, Grid, GridItem, Icon, Image, Input, Text, VStack } from '@chakra-ui/react';
import { containsFiles, getFiles } from '@atlaskit/pragmatic-drag-and-drop/external/file';
import {
  dropTargetForExternal,
  monitorForExternal,
} from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import { useCallback, useEffect, useRef, useState } from 'react';

import AuthoringImageActionsButtonGroup from '@/client/components/admin/create/content/buttons/AuthoringImageActionsButtonGroup';
import AuthoringImageEditor from '@/client/components/admin/create/content/images/AuthoringImageEditor';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { bind } from 'bind-event-listener';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import invariant from 'tiny-invariant';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// TODO: Look into refactoring into file upload wrapper that accepts children/styles as props

export default function CourseBannerUpload() {
  const { t } = useTranslation();
  const [state, setState] = useState<'idle' | 'potential' | 'over'>('idle');
  const [editImage, setEditImage] = useState<string>('');
  const [renderImageEditActions, setRenderImageEditActions] = useState<boolean>(false);
  const { watch, setValue } = useFormContext();

  const bannerImage = watch('bannerImage');

  const dropzoneRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputTriggerClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const addUpload = useCallback((file: File | null) => {
    if (!file) {
      return;
    }

    if (!file.type.startsWith('image/')) {
      return;
    }

    setEditImage(URL.createObjectURL(file));
  }, []);

  const onFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.currentTarget.files ?? []);
      files.forEach(addUpload);
    },
    [addUpload],
  );

  useEffect(() => {
    if (bannerImage) return;

    const el = dropzoneRef.current;

    invariant(el);

    return combine(
      dropTargetForExternal({
        element: el,
        canDrop: containsFiles,
        onDragEnter: () => setState('over'),
        onDragLeave: () => setState('potential'),
        onDrop: async ({ source }) => {
          const files = await getFiles({ source });

          files.forEach((file) => {
            if (file == null) {
              return;
            }
            if (!file.type.startsWith('image/')) {
              return;
            }
            const reader = new FileReader();

            reader.readAsDataURL(file);

            bind(reader, {
              type: 'load',
              listener() {
                const { result } = reader;

                if (typeof result === 'string') {
                  setEditImage(result);
                }
              },
            });
          });
        },
      }),
      monitorForExternal({
        canMonitor: containsFiles,
        onDragStart: () => {
          setState('potential');
          preventUnhandled.start();
        },
        onDrop: () => {
          setState('idle');
          preventUnhandled.stop();
        },
      }),
    );
  });

  return (
    <>
      {bannerImage && (
        <Box
          height={180}
          width="full"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundImage={bannerImage}
          backgroundPosition="center"
          borderRadius={6}
        >
          <Center
            height="full"
            borderRadius={6}
            backgroundColor="blackAlpha.100"
            onMouseEnter={() => setRenderImageEditActions(true)}
            onMouseLeave={() => setRenderImageEditActions(false)}
          >
            {renderImageEditActions && (
              <AuthoringImageActionsButtonGroup
                onRemove={() => setValue('bannerImage', '')}
                onEdit={() => setEditImage(bannerImage)}
              />
            )}
          </Center>
        </Box>
      )}

      {!bannerImage && (
        <Box
          ref={dropzoneRef}
          border="2px dashed"
          borderColor="warmNeutral.400"
          borderRadius="6px"
          padding={8}
          backgroundColor={state === 'over' ? 'warmNeutral.100' : 'white'}
        >
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem colSpan={1}>
              <Center>
                <Box boxShadow="5px 5px 11px -6px rgba(0,0,0,0.2)">
                  <Image
                    src="/public/images/course-banner-preview.png"
                    alt="Course Banner Preview"
                  />
                </Box>
              </Center>
            </GridItem>

            <GridItem colSpan={1}>
              <VStack>
                <Icon
                  as={IoCloudUploadOutline}
                  color="warmNeutral.400"
                  boxSize={10}
                  onClick={onInputTriggerClick}
                />

                <Input
                  ref={inputRef}
                  display="none"
                  id="file-input"
                  onChange={onFileInputChange}
                  type="file"
                  accept="image/*"
                />

                <Text fontSize="12px" fontWeight={400} textAlign="center">
                  {t('authoring.courses.images.dragDropOr')}{' '}
                  <Text
                    as="span"
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    color="warmNeutral.1000"
                    onClick={onInputTriggerClick}
                  >
                    {t('authoring.courses.images.browse')}
                  </Text>{' '}
                  {t('authoring.courses.images.toChoose')}{' '}
                  <Text as="b">{t('authoring.courses.images.banner')}</Text>{' '}
                  {t('authoring.courses.images.image')}
                </Text>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      )}

      <AuthoringImageEditor
        image={editImage}
        type="banner"
        setImage={setEditImage}
        setFormValue={(cloudfrontLink: string) => setValue('bannerImage', cloudfrontLink)}
      />
    </>
  );
}
