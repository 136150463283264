import "froala-editor/css/froala_style.min.css";

import parse, { domToReact } from "html-react-parser";
import { useEffect, useState } from "react";

import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";

export default function HtmlRenderer({
  html,
  width = undefined,
  comment = false,
  color = undefined,
}: {
  html: string;
  width?: string | null;
  comment?: boolean;
  color?: string | undefined;
}) {
  const [returnSanitized, setReturnedSanitized] = useState<string>("");

  const options: any = {
    replace: (domNode: any) => {
      if (domNode && domNode.name) {
        if (
          ["h1", "h2", "h3", "h4", "h5", "h6"].includes(domNode.name) &&
          domNode.children[0]?.data
        ) {
          const newId = domNode.children[0].data
            .split(" ")
            .join("")
            .toLowerCase();
          const newAttribs = { ...domNode.attribs, id: newId };
          return domToReact([{ ...domNode, attribs: newAttribs }]);
        }
      }
    },
  };

  DOMPurify.addHook("uponSanitizeElement", (node, data) => {
    if (data.tagName === "iframe") {
      const src = node.getAttribute("src") || "";
      if (!src) {
        return node.parentNode?.removeChild(node);
      }
      node.innerHTML = "";
    }
  });

  useEffect(() => {
    const toReturn = DOMPurify.sanitize(html, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "src",
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "srcset",
        "class",
        "width",
        "height",
        "target",
      ],
    });

    setReturnedSanitized(toReturn);
  }, [html]);

  return (
    <Box
      className={`fr-view ${comment ? "fr-view--comment" : ""}`}
      width={{ base: "auto", lg: width || "auto" }}
      color={color}
    >
      {returnSanitized && parse(returnSanitized, options)}
    </Box>
  );
}
