import { Controller, useFormContext } from "react-hook-form";

/* eslint-disable react/jsx-props-no-spreading */
import { DefaultTFuncReturn } from "i18next";
import { Input } from "@chakra-ui/react";

interface ContentCreateInputProps {
  name: any;
  placeholder: string | DefaultTFuncReturn;
  onHandleChange: (value: string) => void;
}

export default function ContentCreateInput({
  name,
  placeholder,
  onHandleChange,
}: ContentCreateInputProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          height={12}
          variant="create"
          {...field}
          placeholder={placeholder as string}
          onChange={(e) => {
            onHandleChange(e.target.value);
            field.onChange(e);
          }}
          value={field.value || ""}
        />
      )}
    />
  );
}
