import { Flex, Link, Text } from "@chakra-ui/react";

import { hexToRGBA } from "@/client/utils/hexToRGBA";

interface LinkProps {
  link: any;
  pathname: string;
  brandColor: string;
  navOpen: boolean | undefined;
}

export default function ParentAngularLink({
  link,
  pathname,
  brandColor,
  navOpen,
}: LinkProps) {
  return (
    <Flex
      as={Link}
      href={link.route}
      alignItems="center"
      color="black"
      width="full"
      borderRadius="lg"
      paddingY={1}
      backgroundColor={
        link.route?.includes(pathname) && navOpen
          ? hexToRGBA(brandColor, 0.1)
          : "white"
      }
      fontWeight="medium"
      _hover={{
        backgroundColor: hexToRGBA(brandColor, 0.05),
        color: "black",
        cursor: "pointer",
        fontWeight: "bold",
      }}
    >
      <Flex
        color={hexToRGBA(brandColor, 0.6)}
        alignItems="center"
        justifyContent="center"
        paddingX={2}
        paddingY={1}
        borderRadius="lg"
        backgroundColor={
          link.route?.includes(pathname) && !navOpen
            ? hexToRGBA(brandColor, 0.1)
            : "transparent"
        }
      >
        {link.icon}
      </Flex>

      {navOpen && <Text fontSize="sm">{link.name}</Text>}
    </Flex>
  );
}
