import CategoriesService from "@/client/services/api/CategoriesService";
import { Category } from "@/client/types/Category";
import { PartnerPermissions } from "@/client/types/Partner";
import { learnApiClient } from "../../clients/learnApiClient";
import { sortBy } from "lodash";

const localizeNamesFromString = (names: string) => {
  if (!names || names === "") {
    return {};
  }

  const split = names.split(",");

  const localizedNamesObject: { [key: string]: string } = {};

  split.forEach((name) => {
    const splitName = name.split(":");

    const [key, value] = splitName;
    localizedNamesObject[key] = value;
  });

  return localizedNamesObject;
};

const localizeNames = (category: Category) => {
  const localizedNames = category.localized_names;

  if (!localizedNames) {
    category.localizedNamesObj = {};
    return;
  }

  const split = localizedNames.split(",");

  const localizedNamesObject: { [key: string]: string } = {};

  split.forEach((name) => {
    const splitName = name.split(":");

    localizedNamesObject[splitName[0]] = splitName[1];
  });

  category.localizedNamesObj = localizedNamesObject;
};

const setSortOrder = (sortOrder: string) => {
  let sort;

  switch (sortOrder) {
    case "newest":
      sort = `createdAt,-1`;
      break;
    case "oldest":
      sort = `createdAt,1`;
      break;
    case "asc":
      sort = `name,1`;
      break;
    case "desc":
      sort = `name,-1`;
      break;
    default:
      sort = `createdAt,-1`;
  }

  return sort;
};

const getItems = async (
  perPage: number,
  pageParam: number,
  type: string,
  sortOrder: "asc" | "desc" | "oldest" | "newest"
) => {
  const response = await learnApiClient.get(
    `categories/manager?level=1&page=${pageParam}&per_page=${perPage}&type=${type}&sort=${setSortOrder(
      sortOrder
    )}&fields=sortOrderIndex`
  );

  response.data.data.forEach((category: Category) => localizeNames(category));

  const sorted = sortBy(response.data.data, ["sortOrderIndex"]);

  // Ensure `featured` is at beginning of array
  const featured = sorted.filter((item) => item.level === 10)[0];

  if (featured) {
    const featuredIndex = sorted.findIndex((item) => item === featured);

    if (featuredIndex !== 0) {
      sorted.splice(featuredIndex, 1);
      sorted.unshift(featured);
    }
  }

  return {
    data: type === "category" ? sorted : response.data.data,
    next_available: response.data.next_available,
  };
};

const getSubItems = async (
  parentId: string | undefined,
  perPage: number,
  pageParam: number,
  type: string,
  sortOrder: "asc" | "desc" | "oldest" | "newest"
) => {
  const response = await learnApiClient.get(
    `categories/manager?level=2&page=${pageParam}&parent=${parentId}&per_page=${perPage}&type=${type}&sort=${setSortOrder(
      sortOrder
    )}`
  );

  response.data.data.forEach((category: Category) => localizeNames(category));

  return {
    data:
      type === "category"
        ? sortBy(response.data.data, ["sortOrderIndex"])
        : response.data.data,
    next_available: response.data.next_available,
  };
};

const getSubSubItems = async (
  parentId: string,
  perPage: number,
  pageParam: number,
  type: string
) => {
  const response = await learnApiClient.get(
    `categories/manager?level=3&page=${pageParam}&parent=${parentId}&per_page=${perPage}&type=${type}`
  );

  response.data.data.forEach((category: Category) => localizeNames(category));

  return {
    data:
      type === "category"
        ? sortBy(response.data.data, ["sortOrderIndex"])
        : response.data.data,
    next_available: response.data.next_available,
  };
};

const hideExternal = async (categoryId: string) => {
  const response = await learnApiClient.post(
    `categories/toggle-external/${categoryId}`,
    {
      apply_to_children: true,
    }
  );

  return response.data;
};

const setShowExternal = async (
  categoryId: string,
  name: string,
  showExternal: boolean
) => {
  const response = await learnApiClient.post(`categories/${categoryId}`, {
    name,
    show_external: showExternal,
  });

  return response.data;
};

const editItem = async (
  itemId: string,
  name: string,
  localizedNames?: string,
  showExternal?: boolean,
  partnerPermissions?: PartnerPermissions
) => {
  let payload: {
    name: string;
    localized_names?: string;
    showExternal?: boolean;
    partner_permissions?: PartnerPermissions;
  } = {
    name,
  };

  if (localizedNames) {
    payload = {
      ...payload,
      localized_names: localizedNames,
    };
  }

  if (showExternal) {
    payload = {
      ...payload,
      showExternal,
    };
  }

  if (partnerPermissions) {
    payload = {
      ...payload,
      partner_permissions: partnerPermissions,
    };
  }

  const response = await learnApiClient.post(`categories/${itemId}`, payload);

  return response.data;
};

const addItem = async (
  level: number,
  name: string,
  parentId: string | null | undefined,
  type:
    | "category"
    | "department"
    | "grade"
    | "location"
    | "org_level"
    | "team"
    | "skill",
  partnerPermissions?: PartnerPermissions
) => {
  const response = await learnApiClient.post(`categories`, {
    category: type === "category",
    children: [],
    department: type === "department",
    grade: type === "grade",
    level,
    localized_names: "",
    location: type === "location",
    name,
    org_level: type === "org_level",
    parent: parentId,
    team: type === "team",
    partner_permissions: partnerPermissions,
  });

  return response.data;
};

const searchItems = async (
  searchTerm: string,
  pageParam: number,
  type: string,
  sortOrder: "asc" | "desc" | "oldest" | "newest"
) => {
  const response = await learnApiClient.get(
    `categories/manager?level=1&page=${pageParam}&per_page=15&search_term=${searchTerm}&type=${type}&sort=${setSortOrder(
      sortOrder
    )}`
  );

  response.data.data.forEach((category: Category) => localizeNames(category));

  return response.data;
};

const deleteItem = async (itemId: string | undefined) => {
  const response = await learnApiClient.delete(`categories/${itemId}`);

  return response.data;
};

const setLineage = (item: any) => {
  if (item.level === 3 && item.parent)
    return {
      _id: item.parent.parent._id,
      name: item.parent.parent.name,
      subItems: [item.parent],
      subSubItems: [item],
    };

  if (item.level === 2 && item.parent)
    return {
      _id: item.parent._id,
      name: item.parent.name,
      subItems: [item],
    };

  return item;
};

const fullTextSearch = async (
  pageParam: number,
  perPage: number,
  searchTerm: string,
  type:
    | "category"
    | "location"
    | "department"
    | "team"
    | "org_level"
    | "grade"
    | "skill"
) => {
  const query: any = {
    page: pageParam,
    per_page: perPage,
    text: searchTerm,
  };

  query[type] = true;

  const response = await learnApiClient.get(
    `categories/full-text-search?parent-populate=localized_names`,
    {
      params: query,
    }
  );

  const filterNoLevel = response.data.filter((item: Category) => item.level);

  const withLineage = filterNoLevel.map((item: Category) => setLineage(item));

  // Filter/combine duplicate top level items
  const combinedTopLevelItems = withLineage.map((item: any) => {
    const duplicates = withLineage.filter((i: any) => i._id === item._id);

    let toKeep: any = {
      _id: item._id,
      name: item.name,
      subItems: [],
      subSubItems: [],
    };

    if (duplicates.length > 1) {
      toKeep = {
        _id: duplicates[0]._id,
        name: duplicates[0].name,
        subItems: [],
        subSubItems: [],
      };

      duplicates.forEach((duplicate: any) => {
        if (duplicate.subItems) {
          toKeep.subItems = [...toKeep.subItems, ...duplicate.subItems];
        }

        if (duplicate.subSubItems) {
          toKeep.subSubItems = [
            ...toKeep.subSubItems,
            ...duplicate.subSubItems,
          ];
        }
      });

      return toKeep;
    }

    toKeep = {
      _id: item._id,
      name: item.name,
      subItems: item.subItems || [],
      subSubItems: item.subSubItems || [],
    };

    return toKeep;
  });

  // Filter out unique top level items
  const uniqueTopLevelItems = Array.from(
    new Set(combinedTopLevelItems.map((a: any) => a._id))
  ).map((id) => combinedTopLevelItems.find((a: any) => a._id === id));

  // Filter/combine duplicate sub items
  const combinedSubItems = uniqueTopLevelItems.map((item: any) => {
    const uniqueSubItems = Array.from(
      new Set(item.subItems.map((a: any) => a._id))
    ).map((id) => item.subItems.find((a: any) => a._id === id));

    const withChildren = uniqueSubItems.map((subItem: any) => {
      const subItems = item.subSubItems.filter(
        (i: any) => i.parent._id === subItem._id
      );

      return {
        ...subItem,
        level: 2,
        subItems,
      };
    });

    return {
      ...item,
      subItems: withChildren,
    };
  });

  const categoryIdsResponse = await CategoriesService.getCategoriesById(
    combinedSubItems.map((item: any) => item._id)
  );

  const withLocalizedNames = combinedSubItems.map((item: Category) => {
    const category = categoryIdsResponse.find(
      (c: Category) => c._id === item._id
    );

    if (!category) return;

    return {
      ...item,
      level: 1,
      localizedNamesObj: localizeNamesFromString(category.localized_names),
      localized_names: category.localized_names,
      subItems: item.subItems.map((subItem: Category) => {
        return {
          ...subItem,
          localizedNamesObj: localizeNamesFromString(subItem.localized_names),
        };
      }),
      subSubItems: item.subSubItems.map((subSubItem: Category) => ({
        ...subSubItem,
        localizedNamesObj: localizeNamesFromString(subSubItem.localized_names),
      })),
    };
  });

  return withLocalizedNames;
};

const updateSortOrderIndex = async (
  ids: string[],
  parentId: string | null | undefined,
  level: number
) => {
  const response = await learnApiClient.post(`categories/update-sort-order`, {
    categories: ids,
    parentId,
    level,
  });

  return response.data;
};

const ConnectService = {
  getItems,
  getSubItems,
  getSubSubItems,
  hideExternal,
  setShowExternal,
  addItem,
  searchItems,
  editItem,
  deleteItem,
  fullTextSearch,
  updateSortOrderIndex,
};

export default ConnectService;
