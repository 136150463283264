import { Button, Flex, HStack, IconButton, Tooltip } from "@chakra-ui/react";
import { FaUserCheck, FaUserClock, FaUserTimes, FaUsers } from "react-icons/fa";

import { IoCloseSharp } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function AdminUserOverviewReporting() {
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const { authConfig } = useAuthStore();
  const [showButtonGroup, setShowButtonGroup] = useState(false);

  const reportingActions = [
    {
      label: t("global.actions.close"),
      icon: <IoCloseSharp />,
      onClick: () => setShowButtonGroup(false),
    },
    {
      label: t("admin.users.overview.confirmedUsers"),
      icon: <FaUserCheck />,
      link:
        config.apiUrl +
        "/learn/v3/users/report/csv_confirmed?authtoken=" +
        authConfig.authtoken,
    },
    {
      label: t("admin.users.overview.pendingUsers"),
      icon: <FaUserClock />,
      link:
        config.apiUrl +
        "/learn/v3/users/report/csv_pending?authtoken=" +
        authConfig.authtoken,
    },
    {
      label: t("admin.users.overview.suspendedUsers"),
      icon: <FaUserTimes />,
      link:
        config.apiUrl +
        "/learn/v3/users/report/csv_suspended?authtoken=" +
        authConfig.authtoken,
    },
    {
      label: t("admin.users.overview.allUsers"),
      icon: <FaUsers />,
      link:
        config.apiUrl +
        "/learn/v3/users/report/csv_all?authtoken=" +
        authConfig.authtoken,
    },
  ];

  return (
    <Flex justifyContent="flex-end">
      {!showButtonGroup && (
        <Button
          variant="adminPrimary"
          size="xs"
          leftIcon={<IoMdDownload />}
          onClick={() => setShowButtonGroup(true)}
        >
          {t("overviewUsers.downloadUserList")}
        </Button>
      )}

      {showButtonGroup && (
        <HStack>
          {reportingActions.map((action) => (
            <Tooltip key={action.label} label={action.label}>
              <IconButton
                as="a"
                href={action.link}
                borderRadius="full"
                size="sm"
                icon={action.icon}
                aria-label={action.label}
                onClick={action.onClick}
              />
            </Tooltip>
          ))}
        </HStack>
      )}
    </Flex>
  );
}
