import "./assessmentIntro.scss";

import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ArrowRight } from "@/client/components/icons/ContinuIcons";
import HtmlRenderer from "@/client/components/html/HtmlRenderer";
import { useTextAssessmentStore } from "@/client/services/state/content/textAssessmentStore";
import { useTranslation } from "react-i18next";
import { Assessment } from "@/client/types/content/assessments/Assessment";

// interface AssessmentIntroProps {
//   title: string;
//   creatorName: string;
//   questionCount: number;
//   description: string;
//   answeredQuestions: number;
//   setViewingAssessment: (value: boolean) => void;
// }

// {
//   title,
//   creatorName,
//   questionCount,
//   description,
//   answeredQuestions,
//   setViewingAssessment,
// }: AssessmentIntroProps

interface AssessmentIntroProps {
  previewData?: Assessment;
}

export default function AssessmentIntro({
  previewData = undefined,
}: AssessmentIntroProps) {
  const { t } = useTranslation();
  const {
    assessment: textAssessment,
    setViewingAssessment,
    answeredQuestions,
  } = useTextAssessmentStore();

  const assessment = previewData || textAssessment;
  const pageBreaks = previewData
    ? assessment.questions.filter((q) => q.format === "page_break").length
    : 0;

  return (
    <Center
      minHeight="100vh"
      bgGradient="linear-gradient(to-br, brand.primary, #006c8b)"
      paddingBottom={12}
    >
      <Container maxWidth="container.md">
        <VStack spacing={2} width="full">
          <Text as="h1" color="white">
            {assessment.title}
          </Text>

          <HStack width="full">
            <Divider />

            <Text color="white" fontSize="sm" textAlign="center" width="80%">
              {assessment.questions && (
                <>
                  {assessment.questions.length - pageBreaks}{" "}
                  {assessment.questions.length === 1
                    ? t("assessViewer.question")
                    : t("assessViewer.questions")}{" "}
                  &bull;{" "}
                  {t("assessViewer.by_name", { user: assessment.creator_name })}
                </>
              )}
            </Text>

            <Divider />
          </HStack>

          <Box id="assessmentIntro" padding={6}>
            <HtmlRenderer html={assessment.description} color="white" />
          </Box>

          <Button
            backgroundColor="white"
            color="brand.primary"
            width="sm"
            rightIcon={<ArrowRight />}
            opacity=".8"
            _hover={{ opacity: 1 }}
            onClick={() => setViewingAssessment(true)}
          >
            {answeredQuestions > 0
              ? t("assessViewer.resume")
              : t("assessViewer.start")}
          </Button>
        </VStack>
      </Container>
    </Center>
  );
}
