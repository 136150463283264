import {
  Button,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from "@chakra-ui/react";

import ReactAvatarEditor from "react-avatar-editor";
import { useAvatarStore } from "@/client/services/state/shared/avatarStore";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

type EditorProps = {
  handleUpload: (file: File) => void;
};

export default function AvatarEditor({ handleUpload }: EditorProps) {
  const { t } = useTranslation();
  const { avatar, setAvatar } = useAvatarStore();
  let editor: any = useRef(null);

  const setEditorRef = (ed: any) => {
    editor = ed;
  };

  const handleSlider = (value: number) =>
    setAvatar({
      ...avatar,
      scale: value * 0.075,
    });

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();

      canvasScaled.toBlob((blob: any) => {
        const file = new File([blob], "uploaded-img.jpeg", {
          type: "image/jpeg",
        });

        handleUpload(file);
      });
    }
  };

  return (
    <VStack>
      <ReactAvatarEditor
        ref={setEditorRef}
        image={avatar.img}
        width={250}
        height={250}
        border={50}
        color={[0, 0, 0, 0.6]} // RGBA
        scale={avatar.scale}
        rotate={0}
      />

      <Slider
        aria-label="slider-ex-1"
        defaultValue={20}
        min={1}
        max={100}
        onChange={(value: number) => handleSlider(value)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>

      <Button ref={editor} onClick={() => handleSave()}>
        {t("global.action.save")}
      </Button>
    </VStack>
  );
}
