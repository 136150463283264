import "survey-core/defaultV2.min.css";
import "./popupSurveyStyles.scss";
import "survey-core/survey.i18n";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import { PopupSurvey } from "survey-react-ui";
import SurveyService from "@/client/services/api/SurveyService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

type SurveyProps = {
  surveyId: string;
  contentId: string | undefined;
  contentType: string;
  onCompleteAction?: () => void;
};

export default function PopupSurveyLayout({
  surveyId,
  contentId,
  contentType,
  onCompleteAction,
}: SurveyProps) {
  const { authConfig } = useAuthStore();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const { user } = authConfig;

  const showCompleteMessage = () =>
    setToast({
      show: true,
      status: "success",
      title: t("overviewSurvey.submittedSurvey"),
    });

  const complete = () => {
    onCompleteAction && onCompleteAction();
    showCompleteMessage();
  };

  const { isLoading, isError, data } = useQuery({
    queryKey: ["surveys", surveyId],
    queryFn: () =>
      SurveyService.getSurvey(
        surveyId,
        contentId!,
        contentType,
        user.language,
        complete
      ),
  });

  // The div with the sv_window class has inline styles and this was a way to work around them
  // There's also no way to set the survey display copy to anything but the title of the survey and that's not what product wants.
  // This innerHTML hack was the original solution. The icon poses a problem, though. The only way I can think is to put the svg in this string but that feels gross

  useEffect(() => {
    if (document.getElementsByClassName("sv-string-viewer").length) {
      const title = document.querySelector<HTMLElement>(".sv-string-viewer")!;
      title.innerHTML = `<span>${t(
        "surveys.viewing.popupPrompt"
      )}&nbsp&nbsp&nbsp<i class="fa-regular fa-face-smile"></i></span>`;
    }
    if (document.getElementsByClassName("sv_window").length) {
      const window = document.querySelector<HTMLElement>(".sv_window")!;
      window.style.cssText = "min-width:20%;";
    }
  }, [data]);

  useEffect(() => {
    AnalyzeService.postViews(surveyId, "survey");
  }, []);

  if (isLoading) {
    return <div />;
  }

  if (isError) {
    return <div />;
  }

  return (
    <PopupSurvey
      model={data}
      style={{
        right: "40px",
      }}
    />
  );
}

PopupSurveyLayout.defaultProps = {
  onCompleteAction: null,
};
