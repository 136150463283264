import { Modal, ModalContent } from "@chakra-ui/react";

import { useCreateStore } from "@/client/services/state/admin/create/createStore";

interface CoursePreviewModalProps {
  children: React.ReactNode;
}

export default function CoursePreviewModal({
  children,
}: CoursePreviewModalProps) {
  const { coursePreviewModalIsOpen, setCoursePreviewModalIsOpen } =
    useCreateStore();

  const onClose = () => setCoursePreviewModalIsOpen(false);

  return (
    <Modal size="full" isOpen={coursePreviewModalIsOpen} onClose={onClose}>
      <ModalContent marginTop={50}>{children}</ModalContent>
    </Modal>
  );
}
