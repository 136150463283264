import { learnApiClient } from "./clients/learnApiClient";
import { setContentType } from "@/client/utils/content/setContentType";

export const shareContent = async (
  userId: string,
  contentType: string,
  contentId: string | undefined,
  message: string,
  recipients: string[]
) => {
  const type = setContentType(contentType);

  const share: { [key: string]: string | string[] | undefined } = {
    user: userId,
    content_type: type,
    comment: message,
  };

  if (type) {
    share[type] = contentId;
  }

  const response = await learnApiClient.post(`shares`, {
    share,
    recipients,
  });

  return response.data;
};

const ShareService = {
  shareContent,
};

export default ShareService;
