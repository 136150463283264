import { Divider, Stack, Text } from "@chakra-ui/react";

import type { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import ContentAuthoringBox from "@/client/components/admin/layout/ContentAuthoringBox";
import ContentTextAreaGroup from "@/client/components/admin/input/ContentTextAreaGroup";
import MediaResponseGradingForm from "@/client/components/admin/create/content/forms/assessments/MediaResponseGradingForm";
import VideoQuestionSettingsForm from "@/client/components/admin/create/content/forms/assessments/VideoQuestionSettingsForm";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function MediaResponseQuestionBuilder() {
  const { t } = useTranslation();
  const { watch } = useFormContext<AssessmentFormData>();

  const { assessmentType } = watch() as { assessmentType: "video" | "file" };

  const assessmentTypeTranslationMap = {
    video: t("assessment.videoResponseAssessment"),
    file: t("assessment.fileUploadAssessment"),
  };

  return (
    <Stack>
      <ContentAuthoringBox>
        <Text
          color="baseBlack"
          fontSize="20px"
          fontWeight={600}
          marginBottom={8}
        >
          {assessmentTypeTranslationMap[assessmentType]}
        </Text>

        <ContentTextAreaGroup
          isRequired
          label={t("authoring.assessment.mediaResponse.prompt.label")}
          helpText={`${t(
            "authoring.assessment.mediaResponse.prompt.helpText"
          )}${assessmentType === "video" ? "video" : "file"}`}
          placeholder={t(
            "authoring.assessment.mediaResponse.prompt.placeholder"
          )}
          formName="questions.0.question"
        />
      </ContentAuthoringBox>

      {assessmentType === "video" && (
        <ContentAuthoringBox>
          <VideoQuestionSettingsForm />
        </ContentAuthoringBox>
      )}

      <ContentAuthoringBox>
        <MediaResponseGradingForm />
      </ContentAuthoringBox>
    </Stack>
  );
}
