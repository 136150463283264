/* eslint-disable no-param-reassign */

import {
  Box,
  ButtonGroup,
  Flex,
  Input,
  Text,
  useToken,
} from "@chakra-ui/react";
import {
  Check,
  Close,
  Delete,
  EditSimple,
  People,
  ViewOutline,
  ViewSlash,
} from "@/client/components/icons/ContinuIcons";
import { useEffect, useRef, useState } from "react";

import { Category } from "@/client/types/Category";
import ConnectService from "@/client/services/api/admin/connect/ConnectService";
import { DragHandleIcon } from "@chakra-ui/icons";
import IconButtonWithTooltip from "../../buttons/IconButtonWithTooltip";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useAdminStore } from "@/client/services/state/admin/adminStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useConnectStore } from "@/client/services/state/admin/connectStore";
import { useLanguage } from "@/client/services/hooks/language/useLanguage";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface CardProps {
  isDragDisabled?: boolean;
  category: Category;
  showExternalOption?: boolean;
  editItem: any;
  handleSelectItem: any;
}

export default function ConnectCard({
  isDragDisabled = false,
  category,
  showExternalOption = false,
  editItem,
  handleSelectItem,
}: CardProps) {
  const { t } = useTranslation();
  const { setDeleteModal, setPartnerPermissionModal } = useAdminStore();
  const {
    selectedEditItem,
    setSelectedEditItem,
    activeMainCategory,
    activeSubCategory,
    activeSubSubCategory,
  } = useConnectStore();
  const { selectedLanguage } = useLanguage();
  const { setToast } = useToastStore();
  const ref = useRef<HTMLDivElement>(null);
  const [showExternal, setShowExternal] = useState<boolean>(
    category.show_external
  );
  const [name, setName] = useState<string>(category.name);
  const [value, setValue] = useState<string>(category.name);
  const [brandHighlight, brandButton] = useToken("colors", [
    "brand.highlight",
    "brand.button",
  ]);
  const { config } = useConfigStore();

  // Handle setting localized name
  useEffect(() => {
    if (selectedLanguage.abr === "en") {
      setName(category.name);
      setValue(category.name);
    } else if (category.localizedNamesObj[selectedLanguage.abr]) {
      setName(category.localizedNamesObj[selectedLanguage.abr]);
      setValue(category.localizedNamesObj[selectedLanguage.abr]);
    }
  }, [selectedLanguage]);

  const showSuccessToast = () =>
    setToast({
      show: true,
      status: "success",
      title: t("admin.connect.categoryUpdateSuccess"),
    });

  const showErrorToast = () =>
    setToast({
      show: true,
      status: "error",
      title: t("admin.connect.categoryUpdateError"),
    });

  const showExternalMutation = useMutation({
    mutationFn: () =>
      ConnectService.setShowExternal(category._id, category.name, true),
    onSuccess: () => {
      setShowExternal(!showExternal);
      showSuccessToast();
    },
    onError: () => showErrorToast(),
  });

  const hideExternalMutation = useMutation({
    mutationFn: () => ConnectService.hideExternal(category._id),
    onSuccess: () => {
      setShowExternal(!showExternal);
      showSuccessToast();
    },
    onError: () => showErrorToast(),
  });

  const partnerPermissionSave = async (item: Category) => {
    await editItem.mutateAsync({
      itemId: item?._id,
      name: item?.name,
      localizedNames: item?.localized_names,
      partnerPermissions: item?.partner_permissions,
      level: item?.level,
    });
  };

  const handleEditItem = () => {
    if (selectedLanguage.abr === "en") {
      editItem
        .mutateAsync({
          itemId: category._id,
          name: value,
          localizedNames: category.localized_names,
          showExternal,
          level: category.level,
        })
        .then(() => setName(value));
    } else {
      category.localizedNamesObj[selectedLanguage.abr] = value;

      const str = Object.entries(category.localizedNamesObj)
        .map(([k, v]) => `${k}:${v}`)
        .join(",");

      editItem
        .mutateAsync({
          itemId: category._id,
          name: category.name,
          localizedNames: str,
          showExternal,
          level: category.level,
        })
        .then(() => setName(value));
    }
  };

  const setBackgroundColor = (cat: Category) => {
    if (
      activeMainCategory?._id === cat._id ||
      activeSubCategory?._id === cat._id ||
      activeSubSubCategory?._id === cat._id
    ) {
      return hexToRGBA(brandHighlight, 0.2);
    }

    return "white";
  };

  return (
    <Box borderBottom="1px solid" borderBottomColor="rgba(178, 178, 178, .9)">
      <Flex
        ref={ref}
        paddingLeft={4}
        alignItems="center"
        backgroundColor={setBackgroundColor(category)}
        _hover={{ backgroundColor: hexToRGBA(brandHighlight, 0.05) }}
      >
        {!isDragDisabled && category.level !== 10 && (
          <DragHandleIcon marginRight={2} _hover={{ cursor: "grab" }} />
        )}

        {selectedEditItem !== category && (
          <Flex width="full">
            <Flex
              paddingY={3}
              alignItems="center"
              flex={1}
              flexGrow={1}
              _hover={{ cursor: "pointer" }}
              onClick={() => handleSelectItem(category)}
            >
              <Text fontWeight="medium" fontSize="14px">
                {name}
              </Text>
            </Flex>

            <ButtonGroup color={brandButton} alignItems="center" isAttached>
              {showExternalOption && (
                <IconButtonWithTooltip
                  tooltipCopy={
                    showExternal
                      ? t("categoryInput.hide_external")
                      : t("categoryInput.show_external")
                  }
                  icon={showExternal ? <ViewOutline /> : <ViewSlash />}
                  ariaLabel={
                    showExternal
                      ? t("categoryInput.hide_external")
                      : t("categoryInput.show_external")
                  }
                  onClick={() =>
                    showExternal
                      ? hideExternalMutation.mutateAsync()
                      : showExternalMutation.mutateAsync()
                  }
                />
              )}

              <IconButtonWithTooltip
                tooltipCopy={t("global.actions.edit")}
                ariaLabel={t("global.actions.edit")}
                icon={<EditSimple />}
                onClick={() => {
                  setSelectedEditItem(category);
                }}
              />
            </ButtonGroup>
          </Flex>
        )}

        {selectedEditItem === category && (
          <Flex width="full">
            <Flex paddingY={3} alignItems="center" flex={1} flexGrow={1}>
              <Input
                variant="flushed"
                size="xs"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Flex>

            <ButtonGroup isAttached alignItems="center">
              <IconButtonWithTooltip
                tooltipCopy={t("global.action.saveChange")}
                ariaLabel={t("global.action.saveChange")}
                icon={<Check color="green" />}
                onClick={() => {
                  handleEditItem();
                }}
              />

              <IconButtonWithTooltip
                tooltipCopy={t("global.actions.cancel")}
                ariaLabel={t("global.actions.cancel")}
                icon={<Close color="red" />}
                onClick={() => {
                  setSelectedEditItem(null);
                  setValue(name);
                }}
              />

              {config.features.enable_partners && (
                <IconButtonWithTooltip
                  tooltipCopy={t("content.edit.partners.label")}
                  ariaLabel={t("content.edit.partners.label")}
                  icon={<People color="blue" />}
                  onClick={() => {
                    setPartnerPermissionModal({
                      isOpen: true,
                      value: category.partner_permissions || "tenant",
                      selectedItem: category,
                      isControlled: false,
                      handleSubmit: partnerPermissionSave,
                    });
                  }}
                />
              )}

              {category.level !== 10 && (
                <IconButtonWithTooltip
                  tooltipCopy={t("global.action.remove")}
                  ariaLabel={t("global.action.remove")}
                  icon={<Delete color="red" />}
                  onClick={() => {
                    setDeleteModal({
                      isOpen: true,
                      selectedItem: category,
                    });
                  }}
                />
              )}
            </ButtonGroup>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
