import type {
  ChoiceOptionData,
  QuestionData,
} from "@/client/services/api/graphql/gql/graphql";
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
import { FormControl, FormErrorMessage, Stack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

// eslint-disable-next-line import/no-cycle
import DraggableChoiceOption from "./DraggableChoiceOption";
import { autoScrollWindowForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type TChoiceOption = ChoiceOptionData;

const choiceOptionDataKey = Symbol("choice-option");

export type TChoiceOptionData = {
  [choiceOptionDataKey]: true;
  id: TChoiceOption["id"];
};

export function getChoiceOptionData(
  choiceOption: TChoiceOption
): TChoiceOptionData {
  return { [choiceOptionDataKey]: true, id: choiceOption.id };
}

export function isChoiceOptionData(
  data: Record<string | symbol, unknown>
): data is TChoiceOptionData {
  return data[choiceOptionDataKey] === true;
}

interface DraggableChoiceOptionListProps {
  question: QuestionData;
}

export default function DraggableChoiceOptionList({
  question,
}: DraggableChoiceOptionListProps) {
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const dragContainerRef = useRef<HTMLDivElement>(null);

  const questions = watch("questions") as QuestionData[];

  const questionIndex = questions.findIndex((q) => q.id === question.id);

  const noSelectedAnswerError =
    questions[questionIndex]?.choiceOptions?.filter((option) => option.points)
      .length === 0;

  const noSelectedAnswerInFormErrorsObject = !!(errors.questions as any)?.[
    questionIndex
  ]?.choiceOptions;

  const renderNoSelectedAnswerError =
    noSelectedAnswerError && noSelectedAnswerInFormErrorsObject;

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isChoiceOptionData(source.data);
      },
      onDrop({ location, source }) {
        if (!question.choiceOptions) return;

        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (
          !isChoiceOptionData(sourceData) ||
          !isChoiceOptionData(targetData)
        ) {
          return;
        }

        const indexOfSource = question.choiceOptions.findIndex(
          (field: ChoiceOptionData) => field.id === sourceData.id
        );
        const indexOfTarget = question.choiceOptions.findIndex(
          (field: ChoiceOptionData) => field.id === targetData.id
        );

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        function swapArrayElements(
          array: any[],
          index1: number,
          index2: number
        ) {
          if (
            index1 < 0 ||
            index2 < 0 ||
            index1 >= array.length ||
            index2 >= array.length
          ) {
            console.error("Invalid index passed to swapArrayElements()");

            return array;
          }

          const newArray = [...array];
          [newArray[index1], newArray[index2]] = [
            newArray[index2],
            newArray[index1],
          ];

          return newArray;
        }

        const updatedQuestions = questions.map((q) => {
          if (q.id === question.id) {
            return {
              ...q,
              choiceOptions: swapArrayElements(
                q.choiceOptions!,
                indexOfSource,
                indexOfTarget
              ),
            };
          }

          return q;
        });

        setValue("questions", updatedQuestions);
      },
    });
  }, [question.choiceOptions]);

  useEffect(() => {
    return autoScrollWindowForElements();
  });

  return (
    <>
      <Stack ref={dragContainerRef} marginY={8} spacing={8}>
        {question?.choiceOptions?.map((choiceOption, index) => {
          const choiceOptionIndex = question?.choiceOptions?.findIndex(
            (option) => option.id === choiceOption.id
          );

          return (
            <DraggableChoiceOption
              key={choiceOption.id}
              question={question}
              choiceOption={choiceOption}
              choiceOptionIndex={choiceOptionIndex}
              index={index}
            />
          );
        })}
      </Stack>

      {renderNoSelectedAnswerError && (
        <FormControl isInvalid={!!renderNoSelectedAnswerError}>
          <FormErrorMessage>
            {t("assessment.error.specifyCorrectAnswer")}
          </FormErrorMessage>
        </FormControl>
      )}
    </>
  );
}
