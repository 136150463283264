import {
  Box,
  Flex,
  Icon,
  Input,
  Progress,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef } from "react";

import { AuthoringFile } from "@/client/components/icons/ContinuIcons";
import { CloseIcon } from "@chakra-ui/icons";
import type { DropTargetMonitor } from "react-dnd";
import IconButtonWithTooltip from "@/client/components/buttons/IconButtonWithTooltip";
import { IoCloudUploadOutline } from "react-icons/io5";
import { NativeTypes } from "react-dnd-html5-backend";
import { bytesToSize } from "@/client/utils/bytesToSize";
import { useAuthorFile } from "@/client/services/hooks/admin/authoring/UseAuthorFile";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

// TODO: Look into refactoring this for use with Files/Videos/SCORM

export default function FileDropzone() {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { setValue, watch } = useFormContext();
  const { uploadFileMutation, acceptedFileTypes, uploadProgress } =
    useAuthorFile();
  const { setUploadStatus } = useCreateStore();

  const { status } = uploadFileMutation;

  useEffect(() => {
    setUploadStatus(status);
  }, [status]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileId = watch("id");
  const fileDetails = watch("fileDetails");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({
        show: true,
        status: "error",
        title: t("authoring.file.uploadError"),
      });
      return;
    }

    if (fileObject.size > 500000000) {
      setToast({
        show: true,
        status: "error",
        title: t("edit.files.exceed_limit_500"),
      });

      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = "";

    setValue("fileDetails", {
      name: fileObject.name,
      size: fileObject.size,
    });

    uploadFileMutation.mutateAsync(fileObject);
  };

  const handleUploadClick = () =>
    fileInputRef.current ? fileInputRef.current.click() : null;

  const handleFileDrop = useCallback((item: { files: any[] }) => {
    if (item) {
      const { files } = item;

      if (!acceptedFileTypes.includes(files[0].type)) {
        setToast({
          show: true,
          status: "error",
          title: "Invalid file type",
        });

        return;
      }

      if (files[0].size > 500000000) {
        setToast({
          show: true,
          status: "error",
          title: t("edit.files.exceed_limit_500"),
        });

        return;
      }

      setValue("fileDetails", {
        name: files[0].name,
        size: files[0].size,
      });

      uploadFileMutation.mutateAsync(files[0]);
    }
  }, []);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleFileDrop(item);
      },
      canDrop() {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [handleFileDrop]
  );

  const isActive = canDrop && isOver;

  return (
    <>
      {!fileDetails && (
        <Flex
          ref={drop}
          justifyContent="center"
          border="2px dashed"
          borderColor={isActive ? "warmNeutral.300" : "warmNeutral.200"}
          backgroundColor={isActive ? "warmNeutral.200" : "transparent"}
          borderRadius="md"
          padding={12}
          textAlign="center"
        >
          <Input
            display="none"
            ref={fileInputRef}
            type="file"
            accept={acceptedFileTypes.join(",")}
            onChange={(e) => handleFileChange(e)}
          />

          <VStack>
            <Icon as={IoCloudUploadOutline} boxSize={8} />

            <Text fontWeight={600} fontSize="16px" color="baseBlack">
              {t("authoring.file.dragDrop")}

              <Text
                as="span"
                color="warmNeutral.700"
                textDecoration="underline"
                _hover={{ cursor: "pointer" }}
                onClick={handleUploadClick}
              >
                {" "}
                {t("authoring.file.browse")}
              </Text>
            </Text>

            <Text color="neutral.700" fontSize="14px" fontWeight={600}>
              {t("authoring.file.typeMaxSize")}
            </Text>
          </VStack>
        </Flex>
      )}

      {(status === "loading" || status === "success" || status === "idle") &&
        fileDetails && (
          <Box
            backgroundColor="warmNeutral.100"
            borderRadius="md"
            border="1px solid"
            borderColor="warmNeutral.200"
            padding={6}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Stack flex={1}>
                <AuthoringFile boxSize={10} />

                <Text variant="createLabel">{fileDetails.name}</Text>

                <Text variant="createHelpText">
                  {bytesToSize(fileDetails.size)}
                </Text>

                {status === "loading" && (
                  <>
                    <Text variant="createHelpText">
                      {t("authoring.scorm.uploading")}
                    </Text>

                    <Progress
                      variant="create"
                      value={uploadProgress}
                      width="full"
                    />
                  </>
                )}
              </Stack>

              {fileId && (
                <IconButtonWithTooltip
                  tooltipCopy="Replace File"
                  icon={<Icon as={CloseIcon} color="warmNeutral.600" />}
                  ariaLabel="Replace File"
                  onClick={() => {
                    setValue("description", "");
                    setValue("textGenerationStatus", undefined);
                    setValue("fileDetails", null);
                    setValue("link", "");
                  }}
                />
              )}
            </Flex>
          </Box>
        )}
    </>
  );
}
