import type { LearningTrackSubmissionData } from "@/client/types/admin/content-authoring/courses/tracks/LearningTrackSubmissionData";
import { learnApiClient } from "@/client/services/api/clients/learnApiClient";

const saveTrack = async (
  data: Omit<
    LearningTrackSubmissionData,
    | "_id"
    | "draft"
    | "title"
    | "author"
    | "duration"
    | "approval_required"
    | "approved"
  >,
  trackId?: string
) => {
  let url = "/tracks";

  if (trackId) {
    url = `/tracks/${trackId}`;
  }

  const response = await learnApiClient.post(url, data);

  return response.data;
};

const AdminTracksService = {
  saveTrack,
};

export default AdminTracksService;
