import {
  addDays,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from "date-fns";

import { CalendarData } from "@/client/types/Calendar";
import CalendarWeek from "./CalendarWeek";

interface DashboardDatesProps {
  data: CalendarData[];
  activeDate: Date;
  setCurrentCalendarData: (data: CalendarData[]) => void;
  setIsCalendarModalOpen: (isCalendarModalOpen: boolean) => void;
}

export default function DashboardDates({
  data,
  activeDate,
  setCurrentCalendarData,
  setIsCalendarModalOpen,
}: DashboardDatesProps) {
  const generateDatesForCurrentWeek = (
    date: Date,
    activeDate: Date,
    data: CalendarData[]
  ) => {
    let currentDate = date;
    const week: any[] = [];
    let daysUntilDue;

    for (let day = 0; day < 7; day++) {
      const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");
      let dayHasAssignment = false;

      const dataForThisDate = data.filter((content: CalendarData) => {
        if (
          content.dataType === "workshop" ||
          (content.dataType === "assignment" && content.startDate)
        ) {
          if (
            format(new Date(content.startDate), "yyyy-MM-dd") ===
            formattedCurrentDate
          ) {
            dayHasAssignment = content.dataType === "assignment";
            daysUntilDue = content.dueIn;
            return true;
          }
        }
        return false;
      });

      let backgroundColor = "white";
      let color;

      if (isSameDay(currentDate, new Date())) backgroundColor = "#E3F2FF";

      if (!isSameMonth(currentDate, activeDate)) color = "#8b0000";

      if (dataForThisDate.length) {
        backgroundColor = "brand.primary";
        if (dayHasAssignment && daysUntilDue) {
          if (daysUntilDue < 10) backgroundColor = "red.200";
          else if (daysUntilDue < 20 && daysUntilDue > 9)
            backgroundColor = "orange.200";
          else backgroundColor = "green.200";
        }
      }

      week.push({
        displayDate: format(currentDate, "d"),
        backgroundColor,
        color,
        data: dataForThisDate,
      });

      currentDate = addDays(currentDate, 1);
    }

    return week;
  };

  const startOfSelectedMonth = startOfMonth(activeDate);
  const endOfSelectedMonth = endOfMonth(activeDate);
  const startDate = startOfWeek(startOfSelectedMonth);
  const endDate = endOfMonth(endOfSelectedMonth);

  let currentDate = startDate;

  const allWeeks: any[] = [];

  while (currentDate <= endDate) {
    allWeeks.push(generateDatesForCurrentWeek(currentDate, activeDate, data));

    currentDate = addDays(currentDate, 7);
  }

  const handleModalOpen = (data: CalendarData[]) => {
    setCurrentCalendarData(data);
    setIsCalendarModalOpen(true);
  };

  return <CalendarWeek weekData={allWeeks} modalOpener={handleModalOpen} />;
}
