import {
  ArrowUp,
  Edit,
  ViewFilled,
} from "@/client/components/icons/ContinuIcons";
import { Box, Button, Link, useToken } from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";

type ViewActionsProps = {
  buttons?: string[];
  userid?: string;
  bottomOffset?: number;
};

export default function UserNotificationsDrawer({
  buttons,
  userid,
  bottomOffset,
}: ViewActionsProps) {
  const [showScroll, setShowScroll] = useState<boolean>(false);
  const brandPrimary = useToken("colors", "brand.primary");
  const buttonBaseStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    height: "50px",
    color: "white",
    borderRadius: "50px",
    marginBottom: useToken("spacings", 12),
    transition: "0.3s ease all",
  };
  const buttonBaseHoverStyles = {
    cursor: "pointer",
  };

  window.addEventListener("scroll", (event) => {
    if (window.scrollY > 10) {
      setShowScroll(true);
    } else if (window.scrollY < 10) {
      setShowScroll(false);
    }
  });

  const scrollToTopHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  if (!buttons || !buttons.length) {
    return (
      <Box position="fixed" bottom={0} right={2} zIndex="5">
        <Box
          onClick={scrollToTopHandler}
          style={buttonBaseStyle}
          _hover={buttonBaseHoverStyles}
          opacity={showScroll ? "1" : "0"}
          aria-label="Scroll To Top"
        >
          <ArrowUp color={brandPrimary} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box position="fixed" bottom={bottomOffset || 0} right={2} zIndex="5">
        {buttons.includes("gototop") && (
          <Button
            variant="unstyled"
            onClick={scrollToTopHandler}
            style={buttonBaseStyle}
            _hover={buttonBaseHoverStyles}
            opacity={showScroll ? "1" : "0"}
            backgroundColor="gray.100"
            aria-label="Scroll To Top"
          >
            <ArrowUp color={brandPrimary} />
          </Button>
        )}
        {buttons.includes("editprofile") && !!userid && (
          <Link
            as={RouterLink}
            to={"/settings/"}
            style={buttonBaseStyle}
            _hover={buttonBaseHoverStyles}
            backgroundColor="brand.primary"
            aria-label="Edit Profile"
          >
            <Edit
              style={{ position: "relative", top: "-2px", right: "-2px" }}
            />
          </Link>
        )}
        {buttons.includes("viewprofile") && !!userid && (
          <Link
            as={RouterLink}
            to={`/profile/${userid}`}
            style={buttonBaseStyle}
            _hover={buttonBaseHoverStyles}
            backgroundColor="brand.primary"
            aria-label="View Profile"
          >
            <ViewFilled />
          </Link>
        )}
      </Box>
    );
  }
}
