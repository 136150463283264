/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

interface CreateInputProps {
  name: string;
  variant: "createTitle" | "create" | "landingPage";
  placeholder: string;
  isRequired?: boolean;
  type?: "text" | "number";
}

export default function CreateInput({
  name,
  variant,
  placeholder,
  isRequired = false,
  type = "text",
}: CreateInputProps) {
  const titleRef = useRef<HTMLInputElement>(null);
  const { control, formState } = useFormContext();

  const { errors } = formState;
  const hasError = !!errors[name];
  const { t } = useTranslation();

  useEffect(() => {
    if (variant === "createTitle") {
      titleRef.current?.focus();
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: isRequired || false,
          message: t("authoring.fieldRequired"),
        },
      }}
      render={({ field }) => (
        <FormControl isInvalid={hasError}>
          <InputGroup>
            <Input
              {...field}
              type={type}
              ref={titleRef}
              variant={variant}
              placeholder={placeholder}
              isRequired={isRequired}
              wordBreak="break-word"
              size="lg"
            />

            {variant === "createTitle" && !field.value && !hasError && (
              <InputRightElement width="fit-content">
                <Text fontSize="xs" fontWeight="600" color="warmNeutral.400">
                  {t("authoring.generalDetails.titleRequiredDenotation")}
                </Text>
              </InputRightElement>
            )}
          </InputGroup>

          {hasError && errors[name]?.message && (
            <Flex width="full" justifyContent="right">
              <FormErrorMessage>
                {errors[name]?.message as string}
              </FormErrorMessage>
            </Flex>
          )}
        </FormControl>
      )}
    />
  );
}
