import { analyzeApiClient } from "./clients/analyzeApiClient";
import { learnApiClient } from "./clients/learnApiClient";
import { setContentType } from "@/client/utils/content/setContentType";

const getSuggestions = async (
  contentType: string,
  _id: string | undefined,
  userId: string
) => {
  const type = setContentType(contentType);

  const suggestionResponse = await learnApiClient.get(
    `suggestions?${type}=${_id}&fields=user,event,media,article,scorm,track,quiz,video,file,presentation,workshop,imported_content&page=1&per_page=6&sort=createdAt,-1&&user-populate=first_name,last_name,full_name,email`
  );

  let userHasSuggested = false;
  let userSuggestionId = "";
  let users = suggestionResponse.data.map((suggestion: any) => {
    if (!suggestion.user) {
      return "Anonymous";
    } else if (suggestion.user._id === userId) {
      userHasSuggested = true;
      userSuggestionId = suggestion._id;

      return "You";
    } else {
      return suggestion.user.full_name;
    }
  });

  return {
    count: suggestionResponse.data.length,
    userHasSuggested: userHasSuggested,
    userSuggestionId: userSuggestionId,
    suggestionsUsers: users,
  };
};

const addSuggestion = async (
  contentId: string | undefined,
  contentType: string,
  userId: string
) => {
  const type = setContentType(contentType);

  const response = await learnApiClient.post(`suggestions`, {
    contentId,
    contentType: type,
    user: userId,
  });

  await analyzeApiClient.post(`likes`, {
    content: contentId,
    content_type: contentType,
  });

  return response.data;
};

const removeSuggestion = async (
  suggestionId: string | undefined,
  contentId: string | undefined,
  contentType: string
) => {
  const response = await learnApiClient.delete(`suggestions/${suggestionId}`);

  await analyzeApiClient.post(`unlikes`, {
    content: contentId,
    content_type: contentType,
  });

  return response.data;
};

const SuggestionsService = {
  getSuggestions,
  addSuggestion,
  removeSuggestion,
};

export default SuggestionsService;
