import {
  CLIENT_CREATION_KEY_REPORTS,
  registerClientCreation,
} from "./clientRegister";
import axios, { AxiosInstance } from "axios";

export let reportsApiClient: AxiosInstance;

const create = (baseURL: string) => {
  reportsApiClient = axios.create({
    baseURL: baseURL + "/api/v1/",
  });

  reportsApiClient.interceptors.request.use(
    async (config) => {
      const value = localStorage.getItem("authStore");

      let keys;

      if (value) {
        keys = JSON.parse(value);

        config.headers = {
          authorization: `AuthToken token=${keys.state.authConfig.authtoken}`,
        };
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

registerClientCreation(CLIENT_CREATION_KEY_REPORTS, create);
