/* eslint react/no-unstable-nested-components: 0 */

import { Box, Link, SkeletonText } from "@chakra-ui/react";
import { ColumnDefBase, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrencySign } from "@/client/services/api/graphql/gql-utils";
import {
  OrderingDirection,
  Product,
} from "@/client/services/api/graphql/gql/graphql";
import { useAdminListProducts } from "@/client/services/hooks/content/products/useListProducts";
import EcommerceListTable from "./EcommerceListTable";

export default function MostPurchasedProductsList() {
  const { t } = useTranslation();

  const { data, isError: productsError } = useAdminListProducts({
    page: 0,
    pageSize: 5,
    includeTotalCount: false,
    ordering: [{ field: "purchaseCount", direction: OrderingDirection.Desc }],
  });

  const columnHelper = createColumnHelper<Product>();
  const columns = useMemo<ColumnDefBase<Product, any>[]>(
    () => [
      columnHelper.accessor("title", {
        cell: (info) => (
          <Link as={RouterLink} to={`/product/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => <span>{t("global.contentTypes.product")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("price", {
        cell: (info) => (
          <div>
            {getCurrencySign(info.row.original.currency!) + info.getValue()}
          </div>
        ),
        header: () => <span>{t("overviewProducts.price")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("purchaseCount", {
        cell: (info) => <div>{info.getValue()}</div>,
        header: () => <span>{t("products.timesPurchased")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: "revenue",
        cell: (info) => (
          <div>
            {getCurrencySign(info.row.original.currency!) +
              info.row.original.purchaseCount! * info.row.original.price!}
          </div>
        ),
        header: () => <span>{t("dashboard.revenue")}</span>,
      }),
      columnHelper.accessor("totalRefunded", {
        cell: (info) => (
          <div>
            {getCurrencySign(info.row.original.currency!) + info.getValue()}
          </div>
        ),
        header: () => <span>Total Refunded</span>,
        footer: (info) => info.column.id,
      }),
    ],
    []
  );

  if (productsError)
    return <ErrorAlert title={t("products.errors.mostPurchasedProducts")} />;
  if (!data || data.products.length === 0) {
    return (
      <Box marginX={4}>
        <SkeletonText
          startColor="brand.backgroundGrey"
          endColor="brand.backgroundGrey"
          speed={0}
          mt="4"
          noOfLines={10}
          spacing="4"
          skeletonHeight="10"
        />
      </Box>
    );
  }

  return (
    <EcommerceListTable
      data={{ rows: data.products }}
      columns={columns}
      title={t("overviewProducts.mostPurchasedProducts")}
    />
  );
}
