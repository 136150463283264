import { DownloadIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import CsvDownloadButton from "react-json-to-csv";
import { useTranslation } from "react-i18next";

interface FileDownloadButtonTypes {
  filename: string;
  content?: string;
  data?: object | object[];
  extension: string;
  disabled?: boolean;
}

export default function FileDownloadButton({
  filename,
  content,
  data,
  extension,
  disabled,
}: FileDownloadButtonTypes) {
  const { t } = useTranslation();
  const handleDownload = () => {
    if (content) {
      const asBlob = new Blob([content as BlobPart], {
        type: "application/text;charset=utf-8",
      });
      saveAs(asBlob, `${filename}.${extension}`);
    }
  };
  return (
    <>
      {extension !== "csv" && content && (
        <Button
          onClick={handleDownload}
          variant="ghost"
          fontSize="sm"
          disabled={disabled}
        >
          {t("global.actions.download")} <DownloadIcon marginLeft={2} />
        </Button>
      )}
      {extension === "csv" && data && (
        <CsvDownloadButton data={data as object | object[]} filename={filename}>
          <Button variant="ghost" fontSize="sm" disabled={disabled}>
            {t("global.actions.download")}
            <DownloadIcon marginLeft={2} />
          </Button>
        </CsvDownloadButton>
      )}
    </>
  );
}

FileDownloadButton.defaultProps = {
  disabled: false,
  content: "",
  data: [],
};
