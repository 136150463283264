import { Tag, TagCloseButton } from "@chakra-ui/react";

import { Skill } from "../../../types/Skill";

type SkillTagProps = {
  skill: Skill;
  showRemove?: boolean;
  removeSkill: (name: string) => void;
};

export default function SkillTag({
  skill,
  showRemove,
  removeSkill,
}: SkillTagProps) {
  return (
    <Tag variant="skill" marginY={2}>
      {skill.name}
      {showRemove && (
        <TagCloseButton
          fontSize="14px"
          onClick={() => removeSkill(skill.name)}
        />
      )}
    </Tag>
  );
}

SkillTag.defaultProps = {
  showRemove: false,
};
