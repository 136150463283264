import PreAuthService from "../../api/PreAuthService";
import { useConfigStore } from "@/client/services/state/configStore";
import { useQuery } from "@tanstack/react-query";

export const useGetAlternativeLogins = (partner?: string) => {
  const { config } = useConfigStore();

  return useQuery({
    queryKey: ["alt-logins", config.host, partner],
    queryFn: async () =>
      PreAuthService.checkAlternativeLogins(config.host, partner),
    refetchOnWindowFocus: false,
    onError: (err: any) => console.log("Error", err.response?.data || err),
  });
};
