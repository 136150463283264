import {
  Button,
  Grid,
  GridItem,
  Heading,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { Control, FieldValues } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CustomField } from "@/client/types/AuthConfig";
import User from "@/client/services/api/User";
import { capCase } from "@/client/utils/capCase";
import { isEqual } from "lodash";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

// TODO: Refactor this component and its uses for use within a react-hook-form context instead of passing control as prop
// TODO: Why isn't validaton handled in real time?

type CustomFields = {
  [key: string]: string;
};

type CustomFieldsFormProps = {
  userId: string;
  metadata: CustomFields;
  companyCustomFields: CustomField[];
  control?: Control<FieldValues, any>;
  setValue?: (payload: any) => void;
};

export default function CustomFieldsForm({
  userId,
  metadata,
  companyCustomFields,
  control,
  setValue,
}: CustomFieldsFormProps) {
  const { setToast } = useToastStore();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [initialCustomFields, setInitialCustomFields] = useState<CustomFields>(
    {}
  );
  const [updatedCustomFields, setUpdatedCustomFields] = useState<CustomFields>(
    {}
  );
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const handleUpdateAvailable = (diff: boolean) =>
    diff ? setUpdateAvailable(true) : setUpdateAvailable(false);

  useEffect(() => {
    setInitialCustomFields(metadata || {});
    setUpdatedCustomFields(metadata || {});
  }, []);

  useEffect(() => {
    const diff = !isEqual(initialCustomFields, updatedCustomFields);

    if (control && setValue) {
      setValue(updatedCustomFields);
      return;
    }

    handleUpdateAvailable(diff);
  }, [updatedCustomFields]);

  const updateCustomFields = useMutation({
    mutationFn: (customFields: CustomFields) =>
      User.updateCustomFields(userId, customFields),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.customField.updated"),
      });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: (error: Record<string, any>) => {
      const errMsg =
        error.response.data?.errors[0].code ||
        t("modules.notifications.canNotUpdateProfile");
      setToast({
        show: true,
        status: "error",
        title: errMsg,
      });
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name: key } = event.target;

    const updated = {
      ...updatedCustomFields,
      [key]: value,
    };

    setUpdatedCustomFields(updated);
  };

  const handleSubmit = () => {
    updateCustomFields.mutate(updatedCustomFields);
  };

  const renderInput = (type: string, ccf: CustomField) => {
    switch (type) {
      case "number":
        return (
          <NumberInput
            value={updatedCustomFields[ccf.name] || ""}
            variant="flushed"
            borderColor="blackAlpha.500"
          >
            <NumberInputField
              name={ccf.name}
              onChange={(event) => handleChange(event)}
              maxLength={8}
              placeholder="Enter a number"
            />
          </NumberInput>
        );
      case "boolean":
        return (
          <Select
            variant="flushed"
            onChange={(event: any) => handleChange(event)}
            value={updatedCustomFields[ccf.name] || ""}
            name={ccf.name}
            borderColor="blackAlpha.500"
          >
            <option hidden disabled value="" />
            <option key="true" value="true">
              {t("global.forms.labels_true")}
            </option>
            <option key="false" value="false">
              {t("global.forms.labels_false")}
            </option>
          </Select>
        );
      default:
        return (
          <Input
            name={ccf.name}
            variant="flushed"
            value={updatedCustomFields[ccf.name]}
            onChange={(event) => handleChange(event)}
          />
        );
    }
  };

  return (
    companyCustomFields &&
    companyCustomFields.length > 0 && (
      <>
        <Heading as="h3" fontSize="sm" textTransform="uppercase">
          {t("userProfileEdit.yourCustomFields")}
        </Heading>

        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={10}
          marginBottom={6}
        >
          {companyCustomFields
            .filter(
              (ccf) =>
                ccf.visibility.profile === "readonly" ||
                ccf.visibility.profile === "editable"
            )
            .map((ccf: any) => (
              <GridItem key={ccf.name}>
                <Text variant="formlabel">{capCase(ccf.name)}</Text>

                {ccf.visibility.profile === "editable" &&
                  renderInput(ccf.data_type, ccf)}

                {ccf.visibility.profile === "readonly" && (
                  <div>{updatedCustomFields[ccf.name]}</div>
                )}
              </GridItem>
            ))}
        </Grid>

        {updateAvailable && (
          <Button onClick={() => handleSubmit()}>
            {t("userProfileEdit.updateProfile")}
          </Button>
        )}
      </>
    )
  );
}

CustomFieldsForm.defaultProps = {
  control: null,
  setValue: null,
};
