/* eslint @typescript-eslint/no-unused-vars : 0 */
/* eslint arrow-body-style : 0 */
/* eslint @typescript-eslint/no-use-before-define : 0 */
/* eslint react/jsx-props-no-spreading : 0 */

import {
  Avatar,
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToken,
} from "@chakra-ui/react";
import { Check, Close, Search } from "@/client/components/icons/ContinuIcons";
import { useCombobox, useMultipleSelection } from "downshift";
import { useRef, useState } from "react";

import { Category } from "@/client/types/Category";
import Highligher from "react-highlight-words";
import UserSearchFilters from "../../admin/input/search/UserSearchFilters";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";

// TODO: Refactor this for use with Profiles/Groups only

const defaultOptionFilterFunc = (items: Category[], inputValue: string) =>
  matchSorter(items, inputValue, { keys: ["legacyName"] });

const defaultItemRenderer = (selected: Category | any) =>
  selected.legacyName || selected.full_name || selected.name;

export default function UserSearch(props: any) {
  const {
    showPredefinedFilters,
    variant,
    items,
    optionFilterFunc = defaultOptionFilterFunc,
    itemRenderer = defaultItemRenderer,
    placeholder,
    onCreateItem,
    selectedItems,
    setSearchValue,
    searchValue,
    handleClearAll,
    ...downshiftProps
  } = props;
  const [brandPrimaryColor, inputGray] = useToken("colors", [
    "brand.primary",
    "gray.200",
  ]);
  const [isCreating, setIsCreating] = useState(false);
  const disclosureRef = useRef(null);
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    activeIndex,
  } = useMultipleSelection({
    ...downshiftProps,
    selectedItems,
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: null,
          };
        default:
          return changes;
      }
    },
  });

  const flattenGroupOptions = (options: any) => {
    return options.reduce((prev: any, curr: any) => {
      return [...prev, ...curr.options];
    }, []);
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    // @ts-ignore
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectItem,
    setHighlightedIndex,
    inputValue,
  } = useCombobox({
    selectedItem: null,
    items: flattenGroupOptions(items),
    onInputValueChange: ({ inputValue }) => {
      setSearchValue(inputValue);
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            // @ts-ignore
            if (selectedItemValues.includes(selectedItem._id)) {
              removeSelectedItem(selectedItem);
            }

            if (onCreateItem && isCreating) {
              onCreateItem(selectedItem);
              setIsCreating(false);
            } else {
              addSelectedItem(selectedItem);
            }

            selectItem(null);
          } else if (!selectedItem) {
            addSelectedItem({
              type: "text",
              _id: inputValue,
              value: inputValue,
            });
          }
          break;
        default:
          break;
      }
    },
  });

  const selectedItemValues = selectedItems.map((item: Category) => item._id);

  return (
    <Box
      flex={{
        base: 1,
        lg: 0,
      }}
    >
      <Stack>
        <Box
          border={
            isFocused
              ? `1px solid ${brandPrimaryColor}`
              : `1px solid ${inputGray}`
          }
          borderRadius="lg"
          background="white"
          display="flex"
          flex="0"
          position="relative"
        >
          {!!selectedItems.length && (
            <HStack justifyContent="flex-end" flex={1} paddingX={2}>
              {selectedItems.map((selectedItem: any, index: number) => (
                <Tag
                  margin="0"
                  key={`selected_item_${index + 1}`}
                  {...getSelectedItemProps({ selectedItem, index })}
                  background={
                    selectedItem.name ? "brand.primary" : "blackAlpha.200"
                  }
                >
                  <TagLabel
                    display="inline-block"
                    whiteSpace="nowrap"
                    color={selectedItem.name ? "white" : undefined}
                  >
                    {selectedItem.value ||
                      selectedItem.full_name ||
                      selectedItem.name}
                  </TagLabel>

                  <TagCloseButton
                    color={selectedItem.name ? "white" : undefined}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelectedItem(selectedItem);
                    }}
                  />
                </Tag>
              ))}
            </HStack>
          )}

          <InputGroup>
            {!selectedItems.length && (
              <InputLeftElement>
                <Search color="gray.400" />
              </InputLeftElement>
            )}
            <Input
              variant={variant}
              placeholder={placeholder || t("global.forms.labels_search")}
              value={inputValue}
              {...getInputProps(
                getDropdownProps({
                  onClick: isOpen ? () => {} : openMenu,
                  onFocus: () => {
                    if (!isOpen) openMenu();
                    setIsFocused(true);
                  },
                  onBlur: () => {
                    setIsFocused(false);
                  },
                  ref: disclosureRef,
                })
              )}
            />
            {selectedItems.length && (
              <InputRightElement
                _hover={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClearAll();
                }}
              >
                <Close color="gray.300" />
              </InputRightElement>
            )}
          </InputGroup>

          <Box
            zIndex={999}
            minWidth="600px"
            maxWidth="100%"
            maxHeight="300px"
            overflowY="auto"
            position="absolute"
            top={45}
            left={variant === "explore" ? "-350px" : "0px"}
            pointerEvents={isOpen ? "auto" : "none"}
            {...getMenuProps({ ref: popoverRef })}
          >
            {isOpen && items.length > 0 && searchValue !== "" && (
              <List backgroundColor="white" padding={4}>
                {
                  items.reduce(
                    (results: any, section: any, sectionIndex: any) => {
                      results.sections.push(
                        <Box key={`results_section_${sectionIndex + 1}`}>
                          {section.options.length > 0 && (
                            <Text fontSize="lg" marginY={4}>
                              {section.title}
                            </Text>
                          )}

                          {section.options.length > 0 &&
                            section.options.map(
                              (option: any, optionIndex: any) => {
                                // eslint-disable-next-line no-plusplus, no-param-reassign
                                const resultIndex = results.itemIndex++;

                                return (
                                  <ListItem
                                    key={`option_index_${optionIndex + 1}`}
                                    paddingX={6}
                                    paddingY={1}
                                    {...getItemProps({
                                      item: option,
                                      index: resultIndex,
                                    })}
                                    backgroundColor={
                                      highlightedIndex === resultIndex
                                        ? "brand.gray.50"
                                        : "white"
                                    }
                                  >
                                    <Box>
                                      {selectedItemValues.includes(
                                        option._id
                                      ) && (
                                        <Check
                                          color="green.500"
                                          marginRight={2}
                                        />
                                      )}

                                      {option.type === "user" && (
                                        <Avatar
                                          size="xs"
                                          marginRight={2}
                                          name={option.full_name}
                                          src={option.image}
                                        />
                                      )}

                                      <Highligher
                                        autoEscape
                                        searchWords={[inputValue || ""]}
                                        textToHighlight={itemRenderer(option)}
                                        highlightStyle={{
                                          fontWeight: "bold",
                                          backgroundColor: brandPrimaryColor,
                                          color: "white",
                                        }}
                                      />
                                    </Box>
                                  </ListItem>
                                );
                              }
                            )}
                        </Box>
                      );

                      return results;
                    },
                    { sections: [], itemIndex: 0 }
                  ).sections
                }
              </List>
            )}
          </Box>
        </Box>

        {variant === "admin" && showPredefinedFilters && (
          <UserSearchFilters onFilterChange={addSelectedItem} />
        )}
      </Stack>
    </Box>
  );
}

UserSearch.defaultProps = {
  showPredefinedFilters: true,
};
