import { Category } from "@/client/types/Category";
import { create } from "zustand";

const defaultLanguage = {
  name: "language.english",
  key: "language.english",
  abr: "en",
  value: "en",
};

export interface Language {
  name: string;
  key: string;
  abr: string;
  value: string;
}

interface ConnectState {
  selectedEditItem: Category | null;
  setSelectedEditItem: (item: Category | null) => void;
  defaultLanguage: Language;
  selectedLanguage: Language;
  setSelectedLanguage: (language: Language) => void;
  activeMainCategory: Category | null;
  setActiveMainCategory: (category: Category | null) => void;
  activeSubCategory: Category | null;
  setActiveSubCategory: (category: Category | null) => void;
  activeSubSubCategory: Category | null;
  setActiveSubSubCategory: (category: Category | null) => void;
  type:
    | "category"
    | "location"
    | "department"
    | "team"
    | "org_level"
    | "grade"
    | "skill";
  setType: (
    type:
      | "category"
      | "location"
      | "department"
      | "team"
      | "org_level"
      | "grade"
      | "skill"
  ) => void;
  searchItems: Category[];
  setSearchItems: (items: Category[]) => void;
  items: Category[];
  setItems: (items: Category[]) => void;
  subItems: Category[];
  setSubItems: (items: Category[]) => void;
  subSubItems: Category[];
  setSubSubItems: (items: Category[]) => void;
  searchTerm: string | null;
  setSearchTerm: (term: string) => void;
  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;
  showExternalOption: boolean;
  setShowExternalOption: (showExternalOption: boolean) => void;
  columnCopy: { [key: string]: string };
  setColumnCopy: (columnCopy: { [key: string]: string }) => void;
  sortOrder: "asc" | "desc" | "oldest" | "newest";
  setSortOrder: (sortOrder: "asc" | "desc" | "oldest" | "newest") => void;
  clearStore: () => void;
}

export const useConnectStore = create<ConnectState>((set) => ({
  selectedEditItem: null,
  setSelectedEditItem: (item) => set({ selectedEditItem: item }),
  defaultLanguage,
  selectedLanguage: defaultLanguage,
  setSelectedLanguage: (language) => set({ selectedLanguage: language }),
  activeMainCategory: null,
  setActiveMainCategory: (category) => set({ activeMainCategory: category }),
  activeSubCategory: null,
  setActiveSubCategory: (category) => set({ activeSubCategory: category }),
  activeSubSubCategory: null,
  setActiveSubSubCategory: (category) =>
    set({ activeSubSubCategory: category }),
  type: "category",
  setType: (type) => set({ type }),
  searchItems: [],
  setSearchItems: (items) => set({ searchItems: items }),
  items: [],
  setItems: (items) => set({ items }),
  subItems: [],
  setSubItems: (items) => set({ subItems: items }),
  subSubItems: [],
  setSubSubItems: (items) => set({ subSubItems: items }),
  searchTerm: null,
  setSearchTerm: (term) => set({ searchTerm: term }),
  isSearching: false,
  setIsSearching: (isSearching) => set({ isSearching }),
  showExternalOption: false,
  setShowExternalOption: (showExternalOption) => set({ showExternalOption }),
  columnCopy: {
    columnOneTitle: "",
    columnTwoTitle: "",
    columnThreeTitle: "",
    noSubItemsText: "",
    noSubSubItemsText: "",
    noResultsMain: "",
    noResultsSub: "",
    noResultsSubSub: "",
  },
  setColumnCopy: (columnCopy) => set({ columnCopy }),
  sortOrder: "newest",
  setSortOrder: (sortOrder) => set({ sortOrder }),
  clearStore: () =>
    set({
      selectedEditItem: null,
      defaultLanguage,
      selectedLanguage: defaultLanguage,
      activeMainCategory: null,
      activeSubCategory: null,
      activeSubSubCategory: null,
      type: "category",
      searchItems: [],
      items: [],
      subItems: [],
      subSubItems: [],
      searchTerm: null,
      isSearching: false,
      showExternalOption: false,
      columnCopy: {
        columnOneTitle: "",
        columnTwoTitle: "",
        columnThreeTitle: "",
        noSubItemsText: "",
        noSubSubItemsText: "",
        noResultsMain: "",
        noResultsSub: "",
        noResultsSubSub: "",
      },
    }),
}));
