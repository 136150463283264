export const Text = {
  baseStyle: {},
  variants: {
    adminFormLabel: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    info: {
      fontSize: "sm",
      color: "grey",
    },
    label: {
      color: "grey",
      fontWeight: "bold",
    },
    formlabel: {
      fontWeight: "bold",
      color: "blackAlpha.500",
      fontSize: "sm",
      marginBottom: "0rem",
    },
    hyperlink: {
      color: "grey",
      textDecoration: "underline",
      _hover: {
        color: "brand.primary",
      },
    },
    contentheader: {
      margin: 0,
      fontSize: "34px",
      fontWeight: "400",
    },
    heroheader: {
      as: "h1",
      fontSize: "4xl",
      lineHeight: "1.2",
      fontWeight: "300",
    },
    pageheader: {
      as: "h2",
      fontSize: "2xl",
      lineHeight: "1.2",
      fontWeight: "500",
    },
    createHeading: {
      fontSize: "xl",
      color: "brand.legibleBlack.100",
      fontWeight: 600,
    },
    createLabel: {
      fontSize: "14px",
      color: "baseBlack",
      fontWeight: 600,
    },
    createHelpText: {
      fontSize: "sm",
      color: "brand.legibleBlack.60",
      fontWeight: 400,
    },
    aiDescriptionHelpText: {
      fontSize: "md",
      color: "white",
      fontWeight: 600,
    },
  },
};
