import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import ViewOnDesktop from "@/client/components/admin/ViewOnDesktop";
import { useMediaQuery } from "usehooks-ts";

export default function DesktopOnlyLayout() {
  const isMobileOrTablet = useMediaQuery("(max-width: 1100px)");

  return (
    <Box paddingTop={2} backgroundColor="neutral.0">
      <ViewOnDesktop show={isMobileOrTablet} />

      <Outlet />
    </Box>
  );
}
