import { Box, Divider, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { ContentDetails, Product } from '@/client/services/api/graphql/gql/graphql';

import HeaderCloseIcon from '@/client/components/icons/HeaderCloseIcon';
import typeMap from '@/client/utils/content/typeMap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const authoringDisplayTypeMap = {
  ...typeMap,
  video: 'Video',
  file: 'File',
  scorm: 'SCORM',
};

export default function AuthoringContentList() {
  const { watch, setValue } = useFormContext<Product>();
  const { t } = useTranslation();

  const formName = 'contentListDetails';
  const selectedItems = watch(formName);

  function replaceBrokenImage(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line no-param-reassign
    event.target.src = '/public/images/cardPlaceholder.png';
  }

  if (selectedItems?.length === 0)
    return (
      <Box margin={4} textAlign="center">
        <Image src="/public/images/contentFolder.svg" margin="auto" padding={4} />

        <Text variant="createLabel">{t('authoring.product.noSelectedContent')}</Text>
      </Box>
    );

  return (
    <Stack flexWrap="wrap" marginLeft={2}>
      {/* different read/write properties on selected/fetched items */}
      {selectedItems?.map((item: ContentDetails) => (
        <Stack>
          <HStack alignItems="top">
            <Image
              src={item.image ? item.image : '/public/images/cardPlaceholder.png'}
              minWidth={150}
              width={150}
              height={75}
              borderRadius={10}
              position="relative"
              top={0}
              paddingTop={0}
              marginTop={0}
              // @ts-ignore
              onError={(e: React.ChangeEvent<HTMLInputElement>) => replaceBrokenImage(e)}
            />

            <Stack paddingX={4}>
              <Text variant="createLabel" color="brand.gold.100">
                {/* @ts-ignore */}
                {authoringDisplayTypeMap[item.type]}
              </Text>
              <Text
                variant="createLabel"
                overflowWrap="normal"
                wordBreak="break-word"
                maxWidth="90%"
              >
                {item.displayName}
              </Text>
            </Stack>
            <Box right="4em" position="absolute" marginY="1em">
              <HeaderCloseIcon
                iconCssOptions={{
                  bgColor: 'transparent',
                  _hover: {
                    bgColor: 'transparent',
                  },
                }}
                closeColor="brand.gold.100"
                navigateAction={() =>
                  setValue(
                    formName,
                    // @ts-ignore
                    selectedItems.filter((i) => i.id !== item.id),
                  )
                }
              />
            </Box>
          </HStack>

          <Divider variant="create" />
        </Stack>
      ))}
    </Stack>
  );
}
