/* eslint react/jsx-props-no-spreading: 0 */

import { Stack, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { Form, FormProducts } from "../../types/RegistrationForms";
import ProductCard from "@/client/components/data-display/cards/ProductCard";

interface SelectionProps {
  form: Form;
  value: string | undefined;
  selectProduct: (id: string) => void;
}

export default function ProductSelection({
  form,
  value,
  selectProduct,
}: SelectionProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={8} margin="50px auto" width="800px">
      <Text fontSize="xx-large" fontWeight="600">
        {form.headline || t("ecommerce.checkout.selectProduct")}
      </Text>
      <Text fontSize="sm">{form.description}</Text>

      {form.products?.map((product: FormProducts) => (
        <ProductCard
          product={product}
          onSelect={selectProduct}
          selected={!!value && value === product._id}
        />
      ))}
    </Stack>
  );
}
