import { Controller, useFormContext } from "react-hook-form";
import SegmentationFormField from "./SegmentationFormField";

type FieldProps = {
  fieldName: string;
  fieldLabel: string;
  helpLabel: string;
  segmentationType: string;
};

export default function ControlledSegmentationFormField({
  fieldName,
  fieldLabel,
  helpLabel,
  segmentationType,
}: FieldProps) {
  const { control } = useFormContext();

  const parseValue = (value: string | string[] | undefined) => {
    if (Array.isArray(value)) {
      return value.length ? value[0] : "";
    }
    if (value?.length) {
      return value;
    }
    return "";
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <SegmentationFormField
          fieldLabel={fieldLabel}
          helpLabel={helpLabel}
          type={segmentationType}
          initialId={parseValue(value)}
          updateCategory={(type: string, id: string) => onChange(id || null)}
        />
      )}
    />
  );
}
