import { Flex, Grid, GridItem, Image, Stack } from "@chakra-ui/react";

import ControlledSlateEditor from "../../../forms/ControlledSlateEditor";
import HtmlRenderer from "@/client/components/html/HtmlRenderer";
import InputWithToolTip from "../../../forms/InputWithTooltip";
import { useFormContext } from "react-hook-form";
import { useNotificationEditor } from "@/client/services/hooks/admin/notifications/useNotificationEditor";
import { useTranslation } from "react-i18next";

// TODO: Pass content from parent assignment
// TODO: Add break after paragraphs in ControlledSlateEditor
// TODO: Style ControlledSlateEditor.tsx

interface NotificationEmailFormProps {
  content?: any;
}

export default function NotificationEmailForm({
  content,
}: NotificationEmailFormProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { insertOptions, parseTemplate } = useNotificationEditor();

  const emailBody = watch("email_body");
  const preview = emailBody;

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem padding={4}>
        <Stack spacing={4}>
          <InputWithToolTip
            name="email_from_name"
            label={t("assignments.notifications.edit.email.fromName.label")}
            tooltipText=""
            placeholder={`${t(
              "assignments.notifications.edit.email.fromName.placeholder"
            )}`}
          />

          <InputWithToolTip
            name="email_subject"
            label={t("assignments.notifications.edit.email.subject.label")}
            tooltipText=""
            placeholder={`${t(
              "assignments.notifications.edit.email.subject.placeholder"
            )}`}
          />

          <ControlledSlateEditor
            name="email_body"
            label={t("assignments.notifications.edit.email.body.label")}
            insertOptions={insertOptions}
            isRequired
          />
        </Stack>
      </GridItem>

      <GridItem padding={12} backgroundColor="brand.mist" borderRadius="lg">
        <Flex justifyContent="center" marginBottom={12}>
          <Image
            maxWidth="200px"
            height="56px"
            src="https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/logo-mark@2x.png"
            alt="Continu Logo"
          />
        </Flex>

        <HtmlRenderer html={parseTemplate(preview, "email")} />
      </GridItem>
    </Grid>
  );
}

NotificationEmailForm.defaultProps = {
  content: [],
};
