// @ts-nocheck suppress errors caused by zE - rm to check new code
import { useLazyLoadScript } from "@/client/services/hooks/useLazyLoad";
import { Company, User } from "@/client/types/AuthConfig";
import { useConfigStore } from "@/client/services/state/configStore";

interface ZendeskWidgetProps {
  user: User;
  company: Company;
  setZendeskOpen: (zendeskOpen: boolean) => void;
}

export default function ZendeskWidget({
  user,
  company,
  setZendeskOpen,
}: ZendeskWidgetProps) {
  const { config } = useConfigStore();
  const key = config.zdKey;
  let isInitialized = false;

  const configure = () => {
    const brandColor = "#00BCF1";
    zE("webWidget", "updateSettings", {
      webWidget: {
        color: {
          theme: brandColor,
          launcher: brandColor, // This will also update the badge
          launcherText: "#ffffff",
          button: brandColor,
          resultLists: brandColor,
          header: brandColor,
          articleLinks: brandColor,
        },
      },
    });

    if (user.email) {
      // Zendesk settings
      const name = user.first_name + " " + user.last_name;
      const { email } = user;
      const organization = (company && company.name) || "n/a";

      zE("webWidget", "identify", {
        name,
        email,
        organization,
      });

      zE("webWidget", "prefill", {
        name: {
          value: name,
          readOnly: true,
        },
        email: {
          value: email,
          readOnly: true,
        },
        organization: {
          value: organization,
          readOnly: true,
        },
      });
    }
  };

  const display = () => {
    zE("webWidget", "toggle");
    zE("webWidget", "show");
  };

  const load = () => {
    if (!isInitialized) {
      isInitialized = true;
      window.zESettings = {
        webWidget: {
          contactForm: {
            attachments: true,
          },
        },
      };
      const url = "https://static.zdassets.com/ekr/snippet.js?key=" + key;
      useLazyLoadScript(url, true, null, { name: "id", value: "ze-snippet" });
    }
    const zdOnLoad = window.setInterval(
      () => {
        if (typeof zE !== "undefined" && typeof zE.activate !== "undefined") {
          clearInterval(zdOnLoad);

          zE("webWidget", "hide");
          zE("webWidget:on", "close", () => {
            zE("webWidget", "hide");
            setZendeskOpen(false);
          });

          configure();
          display();
        }
      },
      30,
      null
    );
  };

  load();
  return <></>;
}
