import {
  Checkbox,
  CheckboxGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@chakra-ui/react";

import FormLabelWithTooltip from "../../../forms/FormLabelWithTooltip";
import InputWithToolTip from "../../../forms/InputWithTooltip";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

// TODO: Test this across ALL notification types
// { name: 'Reminder', value: 'assignment_reminder' },
// { name: 'Assignment Completion', value: 'assignment_passed' },
// { name: 'Assignment Failed', value: 'assignment_failed' },
// { name: 'Overdue Notifications', value: 'assignment_overdue' });

// TODO: Enable/disable overdue based on editing or not in addition to feature flag
// TODO: Wrap type select in controller and make required
// TODO: Wrap overdue cadence select in controller and make required
// TODO: Save edited notification
// TODO: Check slack is enabled for channel list

export default function NotificationScheduleForm() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { watch, setValue, formState, getValues } = useFormContext();

  const type = watch("type");
  const recipient = watch("recipient");
  const channels = watch("channels");
  const timeBeforeDue = watch("time_before_due");
  const cadence = watch("cadence");
  const totalMessages = watch("total_messages");

  const isReminder = type === "assignment_reminder";
  const isOverdue = type === "assignment_overdue";

  const handleCheckboxChange = (value: string) => {
    if (channels.includes(value)) {
      setValue(
        "channels",
        channels.filter((channel: string) => channel !== value)
      );
    } else {
      setValue("channels", [...channels, value]);
    }
  };

  const typeOptions = [
    {
      name: t("assignments.notifications.type.newAssignment"),
      value: "assignment_created",
    },
    {
      name: t("assignments.notifications.type.reminder"),
      value: "assignment_reminder",
    },
    {
      name: t("assignments.notifications.type.assignmentCompletion"),
      value: "assignment_passed",
    },
    {
      name: t("assignments.notifications.type.assignmentFailed"),
      value: "assignment_failed",
    },
  ];

  if (company.feature_flags.enable_overdue_notifications) {
    typeOptions.splice(3, 0, {
      name: t("assignments.notifications.type.overdueNotifications"),
      value: "assignment_overdue",
    });
  }

  const cadenceOptions = [
    { name: t("assignments.notifications.cadence.daily"), value: "daily" },
    { name: t("assignments.notifications.cadence.weekly"), value: "weekly" },
    { name: t("assignments.notifications.cadence.monthly"), value: "monthly" },
  ];

  const selectNotificationType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => setValue("type", event.target.value);

  return (
    <Stack>
      <InputWithToolTip
        name="name"
        label={t("assignments.notifications.edit.notificationName")}
        tooltipText={t(
          "assignments.notifications.edit.notificationName.tooltip"
        )}
        isRequired
      />

      <>
        <FormLabelWithTooltip
          label={t("assignments.notifications.edit.type")}
          tooltipText={`${t("assignments.notifications.edit.type.tooltip")}`}
        />

        <Select
          value={type}
          onChange={selectNotificationType}
          placeholder={`${t(
            "assignments.notifications.edit.type.selectOption"
          )}`}
        >
          {typeOptions.map((option) => (
            <option
              disabled={option.value === "assignment_overdue"}
              key={option.value}
              value={option.value}
            >
              {option.name}
            </option>
          ))}
        </Select>
      </>

      {isOverdue && (
        <>
          <FormLabelWithTooltip
            label={t("assignments.notifications.edit.cadence")}
            tooltipText={`${t(
              "assignments.notifications.edit.cadence.tooltip"
            )}`}
          />

          <Select
            value={cadence}
            onChange={(event) => setValue("cadence", event.target.value)}
          >
            {cadenceOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>

          <FormLabelWithTooltip
            label={t("assignments.notifications.edit.occurences")}
            tooltipText={`${t(
              "assignments.notifications.edit.occurences.tooltip"
            )}`}
          />

          <NumberInput
            defaultValue={totalMessages}
            min={1}
            max={30}
            onChange={(value) => setValue("total_messages", +value)}
          >
            <NumberInputField />

            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </>
      )}

      {isReminder && (
        <>
          <FormLabelWithTooltip
            label={t("assignments.notifications.edit.schedule")}
            tooltipText={`${t(
              "assignments.notifications.edit.schedule.tooltip"
            )}`}
          />

          <NumberInput
            defaultValue={timeBeforeDue}
            min={1}
            onChange={(value) => setValue("time_before_due", +value)}
          >
            <NumberInputField />

            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </>
      )}

      <>
        <FormLabelWithTooltip
          label={t("assignments.notifications.edit.audience")}
          tooltipText={`${t(
            "assignments.notifications.edit.audience.tooltip"
          )}`}
        />

        <RadioGroup
          onChange={(value) => setValue("recipient", value)}
          value={recipient}
        >
          <Stack direction="row">
            <Radio value="assignee">
              {t("assignments.notifications.edit.assignee")}
            </Radio>

            {!isOverdue && (
              <Radio value="escalator">
                {t("assignments.notifications.edit.escalationContacts")}
              </Radio>
            )}
          </Stack>
        </RadioGroup>
      </>

      <>
        <FormLabelWithTooltip
          label={t("assignments.notifications.edit.channels")}
          tooltipText={`${t(
            "assignments.notifications.edit.channels.tooltip"
          )}`}
        />

        <CheckboxGroup variant="admin" defaultValue={channels}>
          <Stack spacing={4} direction="row">
            <Checkbox
              value="email"
              onChange={(e) => handleCheckboxChange(e.target.value)}
            >
              {t("assignments.notifications.edit.channels.email")}
            </Checkbox>

            <Checkbox
              value="in_app"
              onChange={(e) => handleCheckboxChange(e.target.value)}
            >
              {t("assignments.notifications.edit.channels.inApp")}
            </Checkbox>

            <Checkbox
              value="slack"
              onChange={(e) => handleCheckboxChange(e.target.value)}
            >
              {t("assignments.notifications.edit.channels.slack")}
            </Checkbox>
          </Stack>
        </CheckboxGroup>
      </>
    </Stack>
  );
}
