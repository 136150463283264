export const Radio = {
  baseStyle: {
    label: {
      color: "brand.formInput",
      _checked: {
        color: "brand.formInput",
      },
    },
    control: {
      height: "20px",
      width: "20px",
      _checked: {
        color: "brand.formInput",
        backgroundColor: "white",
        borderColor: "gray.300",
        _hover: {
          backgroundColor: "white",
          borderColor: "gray.300",
        },
      },
    },
  },
  variants: {
    admin: {
      label: {
        color: "brand.formInput",
      },
      control: {
        borderWidth: "1px",
        borderColor: "gray.300",
        _checked: {
          color: "brand.formInput",
          borderColor: "brand.formInput",
          _hover: {
            color: "brand.formInput",
            borderColor: "brand.formInput",
          },
        },
      },
    },
  },
};
