import { useEffect, useState } from "react";

import CategoriesService from "@/client/services/api/CategoriesService";
import { useMutation } from "@tanstack/react-query";

export const useSegmentationSearch = (categoriesOnly: boolean = false) => {
  const [categorySearchTerm, setCategorySearchTerm] = useState<string | null>(
    null
  );

  const { mutate, data: categorySearchData } = useMutation({
    mutationFn: () =>
      CategoriesService.searchCategories(categorySearchTerm!, categoriesOnly),
  });

  useEffect(() => {
    if (categorySearchTerm) {
      mutate();
    }
  }, [categorySearchTerm]);

  const handleSearchTermChange = (searchTerm: string) =>
    searchTerm === ""
      ? setCategorySearchTerm(null)
      : setCategorySearchTerm(searchTerm);

  return {
    categorySearchTerm,
    setCategorySearchTerm,
    handleSearchTermChange,
    categorySearchData,
  };
};
