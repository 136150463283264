import AssessmentService from "@/client/services/api/AssessmentService";
import { useQuery } from "@tanstack/react-query";

export const useGetUserAssessment = (contextId: string | undefined) =>
  useQuery({
    enabled: !!contextId,
    queryKey: ["user-content-context", { contextId }],
    cacheTime: 0,
    queryFn: () => AssessmentService.getUserAssessment(contextId),
    refetchOnWindowFocus: false,
  });
