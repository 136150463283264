import { create } from "zustand";

interface Avatar {
  editorModalOpen: boolean;
  img: string | File;
  scale: number;
  croppedImg: null | File;
}

interface AvatarState {
  avatar: Avatar;
  setAvatar: (avatar: Avatar) => void;
  reset: () => void;
}

const defaultAvatar = {
  editorModalOpen: false,
  img: "",
  scale: 1.5,
  croppedImg: null,
};

export const useAvatarStore = create<AvatarState>((set) => ({
  avatar: defaultAvatar,
  setAvatar: (avatar) => set(() => ({ avatar })),
  reset: () => set(() => ({ avatar: defaultAvatar })),
}));
