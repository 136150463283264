import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

import { VerticalEllipsis } from "@/client/components/icons/ContinuIcons";

interface TableActionsMenuNewProps {
  actions: {
    icon: any;
    label: string;
    enabled: boolean;
    onClick: () => void;
  }[];
  isDisabled?: boolean;
}

export default function TableActionsMenuNew({
  actions,
  isDisabled,
}: TableActionsMenuNewProps) {
  return (
    <Menu variant="adminTableActions">
      <MenuButton
        isDisabled={isDisabled}
        variant="ghost"
        as={IconButton}
        aria-label="Actions"
        icon={<VerticalEllipsis color="brand.button" />}
      />

      <MenuList>
        {actions.map((action) => {
          if (!action.enabled) return null;

          return (
            <MenuItem
              key={action.label}
              icon={action.icon}
              onClick={action.onClick}
            >
              {action.label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

TableActionsMenuNew.defaultProps = {
  isDisabled: false,
};
