import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const create = defineStyle({
  borderWidth: "1px", // change the width of the border
  borderStyle: "solid", // change the style of the border
  borderRadius: 10, // set border radius to 10
  borderColor: "warmNeutral.200",
  marginY: 4,
});

export const dividerTheme = defineStyleConfig({
  variants: { create },
});
