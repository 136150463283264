import { Stack, Text } from "@chakra-ui/react";

import { Category } from "@/client/types/Category";
import ConnectCard from "@/client/components/admin/connect/ConnectCard";
import { InView } from "react-intersection-observer";
import { useConnect } from "@/client/services/hooks/admin/connect/useConnect";
import { useConnectStore } from "@/client/services/state/admin/connectStore";

interface SingleColumnListProps {
  items: Category[];
  noSearchResultsText: string;
}

export default function SingleColumnList({
  items,
  noSearchResultsText,
}: SingleColumnListProps) {
  const { isSearching, searchItems } = useConnectStore();
  const { levelOneQuery, searchQuery, editItem, handleSelectItem } =
    useConnect();

  const { fetchNextPage: levelOneFetchNextPage } = levelOneQuery;
  const { fetchNextPage: searchFetchNextPage } = searchQuery;

  return isSearching && searchItems.length === 0 ? (
    <Text align="center" padding={14} fontSize="14px">
      {noSearchResultsText}
    </Text>
  ) : (
    <Stack>
      {items.map((item) => (
        <ConnectCard
          editItem={editItem}
          handleSelectItem={handleSelectItem}
          isDragDisabled
          key={item._id}
          category={item}
        />
      ))}

      <InView
        as="div"
        onChange={(inView) => {
          if (inView) {
            isSearching ? searchFetchNextPage() : levelOneFetchNextPage();
          }
        }}
      />
    </Stack>
  );
}
