import SwitchList from "./SwitchList";
import { useTranslation } from "react-i18next";

export default function ProfileJourneys() {
  const { t } = useTranslation();

  const journeyOptions = [
    {
      name: "permissions.admin.journeys.view",
      label: t("edit.profiles.journeys.view"),
      enabled: true,
    },
    {
      name: "permissions.admin.journeys.edit",
      label: t("edit.profiles.journeys.edit"),
      enabled: true,
    },
    {
      name: "permissions.admin.journeys.create",
      label: t("edit.profiles.journeys.create"),
      enabled: true,
    },
    {
      name: "permissions.admin.journeys.archive",
      label: t("edit.profiles.journeys.archive"),
      enabled: true,
    },
  ];

  return <SwitchList enableSelectAll={false} options={journeyOptions} />;
}
