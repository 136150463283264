import { useEffect } from "react";

export const useLazyLoadScript = (
  url: string,
  canFetch: boolean,
  onload?: () => void,
  attributes?: { name: string; value: string }
) => {
  useEffect(() => {
    if (!canFetch) {
      return;
    }

    const script = document.createElement("script");

    if (attributes) {
      script.setAttribute(attributes.name, attributes.value);
    }
    script.src = url;
    script.async = true;

    if (onload) {
      script.onload = onload;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [canFetch]);
};

export const useLazyLoadStylesheet = (url: string) => {
  useEffect(() => {
    const link = document.createElement("link");

    link.rel = "stylesheet";
    link.href = url;

    document.body.appendChild(link);

    return () => {
      document.body.removeChild(link);
    };
  }, [url]);
};
