import { useAuthStore } from "../../state/authStore";
import { useIntercom } from "../useIntercom";
import { useNPS } from "../useNPS";
import { usePartnerStore } from "../../state/partnerStore";

export const useAccessHelper = () => {
  const { authConfig, logout } = useAuthStore();
  const { partner } = usePartnerStore();
  const { user, company, authtoken, createdOn, ttl } = authConfig;
  const { intercomShutdown } = useIntercom();
  const { removeNPS } = useNPS();

  const clearAuthToken = () => {
    localStorage.setItem("last_active", "");

    intercomShutdown();

    removeNPS();

    logout();
  };

  const hasValidAuthToken = (declineActivity?: any) => {
    const now = new Date();
    const lastActive = localStorage.last_active;

    if (authConfig === undefined || authConfig === null || !authtoken) {
      clearAuthToken();
      return false;
    }

    if (!declineActivity && (!lastActive || now.valueOf() > lastActive)) {
      clearAuthToken();
      return false;
    }

    const expiresOn = new Date(createdOn + ttl);

    if (expiresOn === undefined || expiresOn === null || now >= expiresOn) {
      clearAuthToken();
      return false;
    }

    return true;
  };

  const isManager = () => {
    if (!hasValidAuthToken()) {
      return false;
    }
    if (!user) {
      return false;
    }

    return user.is_manager;
  };

  const isPartnerPrincipal = (): boolean =>
    hasValidAuthToken() && user?.partner_role === "principal";

  const isGrader = () => {
    if (!hasValidAuthToken()) {
      return false;
    }
    if (!user) {
      return false;
    }
    return user.is_grader;
  };

  const isCollaborator = () => hasValidAuthToken() && user?.is_collaborator;

  const pathCheck = (accessPath: any) => {
    if (!accessPath || !accessPath.length) {
      return true;
    }

    const profile =
      (user.access_profile && user.access_profile.permissions) || {};

    const check = accessPath.reduce((acc: any, value: any) => {
      if (!acc) {
        return acc;
      }

      if (acc && acc[value]) {
        return acc[value];
      }

      return false;
    }, profile);

    return check === true;
  };

  const roleCheck = (roles: any) => {
    if (!roles || !roles.length) {
      return true;
    }

    const { role } = user;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === "collaborator" && isCollaborator()) {
        return true;
      }

      if (roles[i] === "manager" && isManager()) {
        return true;
      }

      if (roles[i] === role) {
        return true;
      }
    }

    return false;
  };

  const combinedCheck = (accessPath: any, multiPath: any, roles: any) => {
    // check role
    if (!roleCheck(roles)) {
      return false;
    }

    // if no profile allow based on role check
    if (!user.access_profile) {
      return true;
    }

    // check initial path
    if (pathCheck(accessPath)) {
      return true;
    }

    // check alternate path
    if (multiPath && multiPath.length) {
      for (let i = 0; i < multiPath.length; i += 1) {
        if (pathCheck(multiPath[i])) {
          return true;
        }
      }
    }

    return false;
  };

  const allowGeneralActivity = (type: any, roles: any) => {
    const rolesToCheck = roles || ["admin"];

    const types = [
      "users",
      "admin",
      "content",
      "createContent",
      "workshops",
      "assessments",
      "assignments",
      "journeys",
      "third-party",
    ];

    const checks: any = {
      users: [
        ["admin", "users", "view"],
        ["admin", "users", "add"],
      ],
      connect: [
        ["admin", "connect", "categories"],
        ["admin", "connect", "locations"],
        ["admin", "connect", "departments"],
        ["admin", "connect", "teams"],
        ["admin", "connect", "levels"],
        ["admin", "connect", "grades"],
        ["admin", "connect", "groups"],
        ["admin", "connect", "skills"],
      ],
      content: [
        ["admin", "content", "all_create"],
        ["admin", "content", "articles_create"],
        ["admin", "content", "files_create"],
        ["admin", "content", "videos_create"],
        ["admin", "content", "tracks_create"],
        ["admin", "content", "scorm_create"],
        ["admin", "content", "imported_content_create"],
        ["admin", "content", "all_edit"],
        ["admin", "content", "articles_edit"],
        ["admin", "content", "files_edit"],
        ["admin", "content", "videos_edit"],
        ["admin", "content", "tracks_edit"],
        ["admin", "content", "scorm_edit"],
        ["admin", "content", "imported_content_edit"],
        ["admin", "content", "all_analytics"],
        ["admin", "content", "articles_analytics"],
        ["admin", "content", "files_analytics"],
        ["admin", "content", "videos_analytics"],
        ["admin", "content", "tracks_analytics"],
        ["admin", "content", "scorm_analytics"],
        ["admin", "content", "imported_content_analytics"],
      ],
      createContent: [
        ["admin", "content", "all_create"],
        ["admin", "content", "articles_create"],
        ["admin", "content", "files_create"],
        ["admin", "content", "videos_create"],
        ["admin", "content", "tracks_create"],
        ["admin", "content", "scorm_create"],
        ["admin", "content", "imported_content_create"],
      ],
      workshops: [
        ["admin", "content", "all_create"],
        ["admin", "content", "workshops_create"],
        ["admin", "content", "all_edit"],
        ["admin", "content", "workshops_edit"],
        ["admin", "content", "workshops_analytics"],
      ],
      assessments: [
        ["admin", "content", "all_create"],
        ["admin", "content", "assessments_create"],
        ["admin", "content", "all_edit"],
        ["admin", "content", "assessments_edit"],
        ["admin", "content", "all_analytics"],
        ["admin", "content", "assessments_analytics"],
      ],
      assignments: [
        ["admin", "assignments", "view"],
        ["admin", "assignments", "edit"],
        ["admin", "assignments", "complete"],
        ["admin", "assignments", "create"],
        ["admin", "assignments", "archive"],
      ],
      journeys: [
        ["admin", "journeys", "view"],
        ["admin", "journeys", "edit"],
        ["admin", "journeys", "create"],
        ["admin", "journeys", "archive"],
      ],
    };

    if (types.indexOf(type) === -1) {
      return false;
    }

    for (let i = 0; i < checks[type].length; i += 1) {
      if (combinedCheck(checks[type][i], null, rolesToCheck)) {
        return true;
      }
    }

    return false;
  };

  const isAllowed = (accessPath: any, multiPath: any, roles: any) => {
    // If profile hasn't been checked or no user - deny access and signout
    if (!user || !user._id) {
      logout();
      return false;
    }

    return combinedCheck(accessPath, multiPath, roles);
  };

  const allowance = {
    advancedReports:
      company.feature_flags.enable_advanced_reports &&
      isAllowed(["admin", "reports", "advanced"], null, ["admin"]),
    overview: isAllowed(["admin", "overview"], null, ["admin"]),
    users: allowGeneralActivity("users", ["admin"]),
    content: allowGeneralActivity("content", [
      "admin",
      "creator",
      "collaborator",
    ]),
    workshops:
      company.workshops &&
      allowGeneralActivity("workshops", ["admin", "creator", "collaborator"]),
    assessments:
      company.assessments &&
      allowGeneralActivity("assessments", ["admin", "creator", "collaborator"]),
    assignments: allowGeneralActivity("assignments", ["admin", "creator"]),
    workflows: isAllowed(["admin", "workflows"], null, ["admin", "creator"]),
    reports:
      company.reports && isAllowed(["admin", "reports"], null, ["admin"]),
    registrationForms:
      company.feature_flags.enable_registration_forms &&
      isAllowed(["admin"], null, ["admin"]),
    badges:
      company.feature_flags.enable_badges &&
      isAllowed(["admin", "badges"], null, ["admin"]),
    journeys:
      company.feature_flags.journeys &&
      allowGeneralActivity("journeys", ["admin", "creator"]),
    surveys:
      company.feature_flags.enable_surveys &&
      isAllowed(["admin", "surveys"], null, ["admin"]),
    settings: isAllowed(["admin", "settings"], null, ["admin"]),
    apiApplications:
      (company.linkedin_learning ||
        company.feature_flags.enable_masterclass ||
        company.open_api) &&
      isAllowed(["admin", "settings"], null, ["admin"]), //! Uses allowance.settings
    profiles:
      company.profiles && isAllowed(["admin", "settings"], null, ["admin"]), //! Uses allowance.settings
    partners:
      company.feature_flags.enable_partners &&
      isAllowed(["admin"], null, ["admin"]),
    partnerManager:
      company.feature_flags.enable_partners &&
      !!partner._id &&
      isPartnerPrincipal(),
    inPartner: company.feature_flags.enable_partners && !!partner._id,
    webhooks:
      company.feature_flags.enable_webhooks &&
      isAllowed(["admin", "settings"], null, ["admin"]), //! Uses allowance.settings
    theme: isAllowed(["explore", "update_theme"], null, ["admin"]),
    recommendations: company.feature_flags.enable_recommendations,
    metadata:
      company.feature_flags.enable_metadata &&
      isAllowed(["admin", "settings"], null, ["admin"]), //! Uses allowance.settings
    ecommerce: company.feature_flags.enable_ecommerce,
    connectCategories: isAllowed(["admin", "connect", "categories"], null, [
      "admin",
    ]),
    connectLocations: isAllowed(["admin", "connect", "locations"], null, [
      "admin",
    ]),
    connectDepartments: isAllowed(["admin", "connect", "departments"], null, [
      "admin",
    ]),
    connectTeams: isAllowed(["admin", "connect", "teams"], null, ["admin"]),
    connectLevels: isAllowed(["admin", "connect", "levels"], null, ["admin"]),
    connectGrades: isAllowed(["admin", "connect", "grades"], null, ["admin"]),
    connectGroups: isAllowed(["admin", "connect", "groups"], null, ["admin"]),
    connectSkills: isAllowed(["admin", "connect", "skills"], null, ["admin"]),
  };

  return {
    isManager,
    isGrader,
    allowGeneralActivity,
    isAllowed,
    isPartnerPrincipal,
    allowance,
  };
};
