import {
  Article,
  Assessment,
  FileAlt,
  ImportedContent,
  Scorm,
  Track,
  Video,
} from "@/client/components/icons/ContinuIcons";

export const useGetContentTypeIcon = (type: string) => {
  if (type === "article") return <Article color="gray.400" marginRight={2} />;
  if (type === "video") return <Video color="gray.400" marginRight={2} />;
  if (type === "file") return <FileAlt color="gray.400" marginRight={2} />;
  if (type === "assessment")
    return <Assessment color="gray.400" marginRight={2} />;
  if (type === "scorm") return <Scorm color="gray.400" marginRight={2} />;
  if (type === "track") return <Track color="gray.400" marginRight={2} />;
  if (type === "imported_content")
    return <ImportedContent color="gray.400" marginRight={2} />;
};
