import { Flex, Checkbox, CheckboxGroup, Text } from "@chakra-ui/react";

import { ChoiceOption } from "@/client/types/content/assessments/Assessment";
import { Star } from "@/client/components/icons/ContinuIcons";

interface MultipleChoiceQuestionProps {
  selected: string[];
  answers: ChoiceOption[];
  handleSelect: (value: string[]) => void;
  multiSelectCount: number;
}

export default function MultipleChoiceMultiSelectQuestion({
  selected,
  answers,
  handleSelect,
  multiSelectCount,
}: MultipleChoiceQuestionProps) {
  return (
    <>
      <Flex alignItems="center" color="gray.400" fontSize={12} mt="-8px">
        <Star />
        <Text ml={2}> Choose {multiSelectCount}</Text>
      </Flex>
      <CheckboxGroup
        value={selected.length ? selected : []}
        onChange={handleSelect}
      >
        <Flex wrap="wrap">
          {answers.map(({ id, _id, copy }) => (
            <Flex minWidth="50%" key={id || _id}>
              <Checkbox
                value={id || _id}
                padding={4}
                isDisabled={
                  selected.length === multiSelectCount &&
                  !selected.includes(id || _id)
                }
              >
                {copy}
              </Checkbox>
            </Flex>
          ))}
        </Flex>
      </CheckboxGroup>
    </>
  );
}
