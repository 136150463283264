export const Switch = {
  baseStyle: {
    track: {
      marginRight: "4",
      marginLeft: "4",
      _checked: {
        bg: "brand.primary",
      },
    },
  },
  variants: {
    create: {
      track: {
        backgroundColor: "warmNeutral.200",
        border: "1px solid",
        borderColor: "warmNeutral.400",
        _checked: {
          backgroundColor: "warmNeutral.600",
          borderColor: "warmNeutral.600",
        },
      },
      thumb: {
        backgroundColor: "white",
        _checked: {
          backgroundColor: "white",
        },
      },
    },
  },
};
