import {
  addDays,
  endOfMonth,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";

import { CalendarData } from "@/client/types/Calendar";
import CalendarWeek from "./CalendarWeek";
import { WorkshopInstance } from "@/client/types/content/workshops/UserWorkshop";
import WorkshopService from "../../../services/api/content/workshops/WorkshopService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useToken } from "@chakra-ui/react";

interface DedicatedDatesProps {
  activeDate: Date;
  setCurrentCalendarData: (data: CalendarData[]) => void;
  setIsCalendarModalOpen: (isCalendarModalOpen: boolean) => void;
}

export default function Dates({
  activeDate,
  setCurrentCalendarData,
  setIsCalendarModalOpen,
}: DedicatedDatesProps) {
  const { authConfig } = useAuthStore();
  const [brandPrimary, gray700, gray200, gray50] = useToken("colors", [
    "brand.primary",
    "brand.gray.700",
    "brand.gray.200",
    "brand.gray.50",
  ]);
  const { user } = authConfig;
  const startOfSelectedMonth = startOfMonth(activeDate);
  const endOfSelectedMonth = endOfMonth(activeDate);
  const startDate = startOfWeek(startOfSelectedMonth);
  const endDate = endOfWeek(endOfSelectedMonth);

  const {
    isLoading: instancesLoading,
    isError: isInstancesError,
    data: workshopInstances,
    error: instancesError,
    refetch,
  } = useQuery(["workshop-instances"], async () =>
    WorkshopService.getWorkshopInstancesAndUserWorkshops(startDate, endDate)
  );

  useEffect(() => {
    refetch();
  }, [activeDate]);

  const generateDatesForCurrentWeek = (
    date: Date,
    activeDate: Date,
    workshops: WorkshopInstance[] = []
  ) => {
    let currentDate = date;
    const week: any[] = [];

    for (let day = 0; day < 7; day++) {
      const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");
      let backgroundColor = "#FFFFFF";
      let color = gray700;
      let registeredOntThisDate = false;
      let opacity = 0;
      let borderColor = "";

      const workshopsThisDate = workshops.filter(
        (workshop: Record<string, any>) => {
          if (
            format(new Date(workshop.start_date), "yyyy-MM-dd") ===
            formattedCurrentDate
          ) {
            if (workshop.registered) {
              registeredOntThisDate = true;
            }
            return true;
          }
        }
      );

      if (!isSameMonth(currentDate, activeDate)) {
        color = gray200;
        // future month displayed
        if (workshopsThisDate.length > 0 && currentDate > activeDate) {
          color = "#FFFFFF";
          backgroundColor = brandPrimary;
          opacity = 0.2;
        }
      } else if (isSameDay(currentDate, new Date())) {
        backgroundColor = brandPrimary;
        color = "#FFFFFF";
        opacity = 1;
      } else if (workshopsThisDate.length > 0) {
        if (registeredOntThisDate) {
          backgroundColor = brandPrimary;
          color = "#FFFFFF";
        } else if (!isBefore(currentDate, new Date())) {
          borderColor = brandPrimary;
          color = gray700;
        }
        opacity = 0.5;
      }

      week.push({
        displayDate: format(currentDate, "d"),
        data: workshopsThisDate,
        backgroundColor,
        color,
        opacity,
        borderColor,
      });

      currentDate = addDays(currentDate, 1);
    }

    return week;
  };

  let currentDate = startDate;

  const allWeeks: any[] = [];

  while (currentDate <= endDate) {
    allWeeks.push(
      generateDatesForCurrentWeek(currentDate, activeDate, workshopInstances)
    );
    currentDate = addDays(currentDate, 7);
  }

  const handleModalOpen = (workshops: WorkshopInstance[]) => {
    // format workshops for modal use
    const formattedWorkshops = workshops.map(
      (workshop: Record<string, any>) => {
        const newWorkshopObj = {
          dataType: "workshop",
          key: workshop.workshop._id,
          id: workshop.workshop._id,
          title: workshop.workshop.title,
          contentType: workshop.workshop.type,
          imageUrl: workshop.workshop.image,
          startDate: workshop.start_date,
          endDate: workshop.end_date,
          online: workshop.online,
          status: workshop.status || "available",
        };
        return newWorkshopObj;
      }
    );
    setCurrentCalendarData(formattedWorkshops);
    setIsCalendarModalOpen(true);
  };

  return <CalendarWeek weekData={allWeeks} modalOpener={handleModalOpen} />;
}
