import * as ContinuIcons from "@/client/components/icons/ContinuIcons";

import { Flex, Text, VStack } from "@chakra-ui/react";

const iconList = [
  { name: "AngleCircleDown", element: <ContinuIcons.AngleCircleDown /> },
  { name: "AngleCircleRight", element: <ContinuIcons.AngleCircleRight /> },
  { name: "AngleDown", element: <ContinuIcons.AngleDown /> },
  { name: "AngleLeft", element: <ContinuIcons.AngleLeft /> },
  { name: "AngleRight", element: <ContinuIcons.AngleRight /> },
  { name: "AngleUp", element: <ContinuIcons.AngleUp /> },
  { name: "ArrowDown", element: <ContinuIcons.ArrowDown /> },
  { name: "ArrowLeft", element: <ContinuIcons.ArrowLeft /> },
  { name: "ArrowRight", element: <ContinuIcons.ArrowRight /> },
  { name: "ArrowUp", element: <ContinuIcons.ArrowUp /> },
  { name: "Article", element: <ContinuIcons.Article /> },
  { name: "Assessment", element: <ContinuIcons.Assessment /> },
  { name: "Book", element: <ContinuIcons.Book /> },
  { name: "BorderAll", element: <ContinuIcons.BorderAll /> },
  {
    name: "CalendarEvent",
    element: <ContinuIcons.CalendarEvent />,
  },
  { name: "CalendarWeek", element: <ContinuIcons.CalendarWeek /> },
  { name: "Check", element: <ContinuIcons.Check /> },
  { name: "CheckCircle", element: <ContinuIcons.CheckCircle /> },
  { name: "Circle", element: <ContinuIcons.Circle /> },
  { name: "Clock", element: <ContinuIcons.Clock /> },
  { name: "ClockFilled", element: <ContinuIcons.ClockFilled /> },
  { name: "Close", element: <ContinuIcons.Close /> },
  { name: "Cog", element: <ContinuIcons.Cog /> },
  { name: "Columns", element: <ContinuIcons.Columns /> },
  { name: "Comment", element: <ContinuIcons.Comment /> },
  { name: "Course", element: <ContinuIcons.Course /> },
  { name: "Delete", element: <ContinuIcons.Delete /> },
  { name: "DeleteAlt", element: <ContinuIcons.DeleteAlt /> },
  { name: "Document", element: <ContinuIcons.Document /> },
  { name: "DotCircle", element: <ContinuIcons.DotCircle /> },
  { name: "Download", element: <ContinuIcons.Download /> },
  { name: "Dribbble", element: <ContinuIcons.Dribbble /> },
  { name: "Edit", element: <ContinuIcons.Edit /> },
  { name: "Expand", element: <ContinuIcons.Expand /> },
  { name: "Facebook", element: <ContinuIcons.Facebook /> },
  { name: "FileAlt", element: <ContinuIcons.FileAlt /> },
  { name: "Files", element: <ContinuIcons.Files /> },
  { name: "FileVideo", element: <ContinuIcons.FileVideo /> },
  { name: "GitHub", element: <ContinuIcons.GitHub /> },
  { name: "Globe", element: <ContinuIcons.Globe /> },
  { name: "Google", element: <ContinuIcons.Google /> },
  { name: "Hamburger", element: <ContinuIcons.Hamburger /> },
  { name: "Heart", element: <ContinuIcons.Heart /> },
  { name: "HeartFilled", element: <ContinuIcons.HeartFilled /> },
  { name: "Instagram", element: <ContinuIcons.Instagram /> },
  { name: "Journey", element: <ContinuIcons.Journey /> },
  { name: "Link", element: <ContinuIcons.Link /> },
  { name: "LinkedIn", element: <ContinuIcons.LinkedIn /> },
  { name: "Locked", element: <ContinuIcons.Locked /> },
  { name: "MicrosoftTeams", element: <ContinuIcons.MicrosoftTeams /> },
  { name: "PaperPlane", element: <ContinuIcons.PaperPlane /> },
  { name: "People", element: <ContinuIcons.People /> },
  { name: "Play", element: <ContinuIcons.Play /> },
  { name: "Plus", element: <ContinuIcons.Plus /> },
  { name: "PointerFilled", element: <ContinuIcons.PointerFilled /> },
  {
    name: "PointerOutline",
    element: <ContinuIcons.PointerOutline />,
  },
  { name: "QuestionCircle", element: <ContinuIcons.QuestionCircle /> },
  {
    name: "QuestionCircleFilled",
    element: <ContinuIcons.QuestionCircleFilled />,
  },
  { name: "Redo", element: <ContinuIcons.Redo /> },
  { name: "Scorm", element: <ContinuIcons.Scorm /> },
  { name: "Search", element: <ContinuIcons.Search /> },
  { name: "Share", element: <ContinuIcons.Share /> },
  { name: "Shrink", element: <ContinuIcons.Shrink /> },
  { name: "Star", element: <ContinuIcons.Star /> },
  { name: "StarFilled", element: <ContinuIcons.StarFilled /> },
  { name: "StarHalf", element: <ContinuIcons.StarHalf /> },
  { name: "Track", element: <ContinuIcons.Track /> },
  { name: "Twitter", element: <ContinuIcons.Twitter /> },
  { name: "Udemy", element: <ContinuIcons.Udemy /> },
  { name: "Unlocked", element: <ContinuIcons.Unlocked /> },
  { name: "Upload", element: <ContinuIcons.Upload /> },
  { name: "UserAlt", element: <ContinuIcons.UserAlt /> },
  { name: "UserFriends", element: <ContinuIcons.UserFriends /> },
  { name: "Users", element: <ContinuIcons.Users /> },
  { name: "Video", element: <ContinuIcons.Video /> },
  { name: "View", element: <ContinuIcons.View /> },
  { name: "ViewFilled", element: <ContinuIcons.ViewFilled /> },
  { name: "ViewOutline", element: <ContinuIcons.ViewOutline /> },
  { name: "ViewSlash", element: <ContinuIcons.ViewSlash /> },
  { name: "WarningTriangle", element: <ContinuIcons.WarningTriangle /> },
  { name: "Workshop", element: <ContinuIcons.Workshop /> },
  { name: "ZoomIn", element: <ContinuIcons.ZoomIn /> },
  { name: "ZoomOut", element: <ContinuIcons.ZoomOut /> },
  { name: "ImportedContent", element: <ContinuIcons.ImportedContent /> },
  { name: "Steps", element: <ContinuIcons.Steps /> },
  { name: "Minus", element: <ContinuIcons.Minus /> },
  { name: "Filter", element: <ContinuIcons.Filter /> },
  { name: "Admin", element: <ContinuIcons.Admin /> },
  { name: "Connect", element: <ContinuIcons.Connect /> },
  { name: "Create", element: <ContinuIcons.Create /> },
  { name: "Dashboard", element: <ContinuIcons.Dashboard /> },
  { name: "Explore", element: <ContinuIcons.Explore /> },
  { name: "Manager", element: <ContinuIcons.Manager /> },
  { name: "NavShare", element: <ContinuIcons.NavShare /> },
  { name: "VerticalEllipsis", element: <ContinuIcons.VerticalEllipsis /> },
  { name: "Assign", element: <ContinuIcons.Assign /> },
  { name: "Copy", element: <ContinuIcons.Copy /> },
  { name: "Assignment", element: <ContinuIcons.Assignment /> },
  {
    name: "BarChart",
    element: <ContinuIcons.BarChart />,
  },
  {
    name: "Bell",
    element: <ContinuIcons.Bell />,
  },
  {
    name: "AuthoringFile",
    element: <ContinuIcons.AuthoringFile />,
  },
  {
    name: "AuthoringGrayFile",
    element: <ContinuIcons.AuthoringGrayFile />,
  },
  {
    name: "AuthoringEmbedLink",
    element: <ContinuIcons.AuthoringEmbedLink />,
  },
  {
    name: "AuthoringVideo",
    element: <ContinuIcons.AuthoringVideo />,
  },
  {
    name: "AuthoringScorm",
    element: <ContinuIcons.AuthoringScorm />,
  },
  {
    name: "AuthoringGrayScorm",
    element: <ContinuIcons.AuthoringGrayScorm />,
  },
  {
    name: "TrackArticle",
    element: <ContinuIcons.TrackArticle />,
  },
  {
    name: "TrackAssessment",
    element: <ContinuIcons.TrackAssessment />,
  },
  {
    name: "TrackFile",
    element: <ContinuIcons.TrackFile />,
  },
  {
    name: "TrackScorm",
    element: <ContinuIcons.TrackScorm />,
  },
  {
    name: "TrackVideo",
    element: <ContinuIcons.TrackVideo />,
  },
  {
    name: "Masterclass",
    element: <ContinuIcons.Masterclass />,
  },
  {
    name: "MultipleChoice",
    element: <ContinuIcons.MultipleChoice />,
  },
  {
    name: "Dropdown",
    element: <ContinuIcons.Dropdown />,
  },
  {
    name: "ShortAnswer",
    element: <ContinuIcons.ShortAnswer />,
  },
  {
    name: "LongAnswer",
    element: <ContinuIcons.LongAnswer />,
  },
  {
    name: "DragHandle",
    element: <ContinuIcons.DragHandle />,
  },
  {
    name: "Trash",
    element: <ContinuIcons.Trash />,
  },
  {
    name: "Media",
    element: <ContinuIcons.Media />,
  },
  {
    name: "Randomize",
    element: <ContinuIcons.Randomize />,
  },
  {
    name: "CircleCheck",
    element: <ContinuIcons.CircleCheck />,
  },
  {
    name: "RubricGrading",
    element: <ContinuIcons.RubricGrading />,
  },
  {
    name: "OpenGrading",
    element: <ContinuIcons.OpenGrading />,
  },
  {
    name: "HamburgerDragHandle",
    element: <ContinuIcons.HamburgerDragHandle />,
  },
  {
    name: "Search2",
    element: <ContinuIcons.Search2 />,
  },
  {
    name: "MultipleChoiceSimple",
    element: <ContinuIcons.MultipleChoiceSimple />,
  },
  {
    name: "DropdownSimple",
    element: <ContinuIcons.DropdownSimple />,
  },
  {
    name: "ShortAnswerSimple",
    element: <ContinuIcons.ShortAnswerSimple />,
  },
  {
    name: "LongAnswerSimple",
    element: <ContinuIcons.LongAnswerSimple />,
  },
];

export default function IconGallery() {
  return (
    <Flex wrap="wrap" justifyContent="space-evenly">
      {iconList.map((icon) => (
        <VStack margin={4}>
          {icon.element}

          <Text>{icon.name}</Text>
        </VStack>
      ))}
    </Flex>
  );
}
