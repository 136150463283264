/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Divider, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AssessmentCheckbox from '../../input/AssessmentCheckbox';
import AssessmentDropdownMenu from '../../input/AssessmentDropdownMenu';
import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import Certificate from '@/client/components/data-display/certificates/Certificate';
import CreateSwitchContainer from '../../../shared/layout/CreateSwitchContainer';
import { useAuthStore } from '@/client/services/state/authStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContentCreateInput from '../../input/ContentCreateInput';

export default function AssessmentCompletionSettingsForm() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { watch, setValue } = useFormContext<AssessmentFormData>();
  const [viewingCertificate, setViewingCertificate] = useState(false);

  const percentChoices = Array.from({ length: 21 }, (_, i) => i * 5);
  const retakeChoices = [...Array.from({ length: 6 }, (_, i) => i), 1000];

  const contentTitle = watch('title');
  const certificate = watch('certificate');
  const certificateData = watch('certificateData');

  const hideCompletionDate =
    certificateData?.find((data) => data.name === 'hide_completion')?.value || undefined;

  const altTitleIndex = certificateData?.findIndex((data) => data.name === 'alt_title');

  const altTitleValue = certificateData?.find((data) => data.name === 'alt_title')?.value || '';

  useEffect(() => {
    if (!certificateData || certificateData.length === 0) {
      setValue('certificateData', [
        {
          name: 'alt_title',
          value: '',
        },
        {
          name: 'hide_completion',
          value: '',
        },
      ]);
    } else if (certificateData.length === 1 && certificateData[0].name === 'alt_title') {
      setValue('certificateData', [
        ...certificateData,
        {
          name: 'hide_completion',
          value: '',
        },
      ]);
    } else if (certificateData.length === 1 && certificateData[0].name === 'hide_completion') {
      setValue('certificateData', [
        ...certificateData,
        {
          name: 'alt_title',
          value: '',
        },
      ]);
    }
  }, []);

  const handleHideDateChange = (value: string, isChecked: boolean) => {
    let hideDate;
    if (isChecked) {
      hideDate = 'true';
    } else {
      hideDate = 'false';
    }
    const updatedCertificateData = certificateData?.map((data) =>
      data.name === 'hide_completion' ? { ...data, value: hideDate } : data,
    );
    setValue('certificateData', updatedCertificateData);
  };

  const handleAltTitleChange = (value: string) => {
    const updatedCertificateData = certificateData?.map((data) =>
      data.name === 'alt_title' ? { ...data, value } : data,
    );
    setValue('certificateData', updatedCertificateData);
  };

  return (
    <>
      <Box paddingY={4}>
        <Text variant="createHeading">{t('authoring.assessment.completionSettings')}</Text>
      </Box>

      <Flex width="full" justifyContent="space-between">
        <Stack spacing={2} minWidth="30%">
          <Text variant="createLabel">
            {t('authoring.assessment.completionSettings.minPassingGrade.label')}
          </Text>

          <Text variant="createHelpText">
            {t('authoring.assessment.completionSettings.minPassingGrade.helpText')}
          </Text>
        </Stack>

        <HStack>
          <AssessmentDropdownMenu
            name="percentToPass"
            selections={percentChoices}
            placeholder={t('authoring.assessment.completionSettings.minPassingGrade.placeholder')}
          />
        </HStack>
      </Flex>

      <Divider marginY={4} borderColor="neutral.100" />

      <CreateSwitchContainer
        name="showUserGrade"
        label={t('authoring.assessment.completionSettings.showGrade.label')}
        helpText={t('authoring.assessment.completionSettings.showGrade.helpText')}
      />

      <Divider marginY={4} borderColor="neutral.100" />

      <CreateSwitchContainer
        name="showUserCorrect"
        label={t('authoring.assessment.completionSettings.showCorrectAnswers.label')}
        helpText={t('authoring.assessment.completionSettings.showCorrectAnswers.helpText')}
      />

      <Divider marginY={4} borderColor="neutral.100" />

      <CreateSwitchContainer
        name="showUserCorrectAfterFail"
        label={t('authoring.assessment.completionSettings.showCorrectAfterFail.label')}
        helpText={t('authoring.assessment.completionSettings.showCorrectAfterFail.helpText')}
      />

      <Divider marginY={4} borderColor="neutral.100" />

      <Flex width="full" justifyContent="space-between">
        <Stack flex={1}>
          <Text variant="createLabel">
            {t('authoring.assessment.completionSettings.assessmentRetakes.label')}
          </Text>

          <Text variant="createHelpText">
            {t('authoring.assessment.completionSettings.assessmentRetakes.helpText')}
          </Text>
        </Stack>

        <HStack>
          <AssessmentDropdownMenu
            name="numberOfRetakes"
            selections={retakeChoices}
            placeholder={t('authoring.assessment.completionSettings.assessmentRetakes.placeholder')}
          />
        </HStack>
      </Flex>

      <Divider marginY={4} borderColor="neutral.100" />

      <CreateSwitchContainer
        name="certificate"
        label={t('authoring.assessment.completionSettings.enableCertificate.label')}
        helpText={t('authoring.assessment.completionSettings.enableCertificate.helpText')}
      />

      {certificate && (
        <Box paddingY={4}>
          <HStack spacing={4}>
            <Button
              size="lg"
              border="1px solid"
              borderColor="warmNeutral.200"
              borderRadius="6px"
              backgroundColor="white"
              paddingY={4}
              paddingX={4}
              fontSize="sm"
              fontWeight={500}
              color="baseBlack"
              _hover={{
                borderColor: 'warmNeutral.700',
              }}
              onClick={() => setViewingCertificate(true)}
            >
              {t('authoring.certificate.preview')}
            </Button>

            <HStack flex={1}>
              <AssessmentCheckbox
                name="certificateData"
                label={t('authoring.assessment.completionSettings.hideCertificateDate.label')}
                isChecked={hideCompletionDate === 'true'}
                value="true"
                onHandleChange={handleHideDateChange}
              />
            </HStack>

            <HStack flex={1}>
              <ContentCreateInput
                name={`certificateData.${altTitleIndex}.value`}
                placeholder={t(
                  'authoring.assessment.completionSettings.altCertificateTitle.placeholder',
                )}
                onHandleChange={handleAltTitleChange}
              />
            </HStack>
          </HStack>

          {viewingCertificate && (
            <Certificate
              contentTitle={altTitleValue! || contentTitle!}
              userFullName={user.full_name}
              completedDate={new Date()}
              userMetadata={user.metadata}
              contentMetadata={{}}
              certificateData={[
                {
                  name: 'hide_completion',
                  value: hideCompletionDate || 'false',
                },
              ]}
              onClose={() => setViewingCertificate(false)}
            />
          )}
        </Box>
      )}

      <Divider marginY={4} borderColor="neutral.100" />

      <CreateSwitchContainer
        name="hideCover"
        label={t('authoring.assessment.completionSettings.hideCover.label')}
        helpText={t('authoring.assessment.completionSettings.hideCover.helpText')}
      />
    </>
  );
}
