/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */

import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Link,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { Globe, UserFriends } from "@/client/components/icons/ContinuIcons";

import { Fragment } from "react";
import { InfiniteData } from "@tanstack/react-query";
import { Person } from "@/client/types/Person";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

type UserCardsProps = {
  people: InfiniteData<Person[]> | undefined;
};

export default function UserCards({ people }: UserCardsProps) {
  const { t } = useTranslation();

  if (!people || people.pages[0].length === 0)
    return (
      <Center marginTop={8}>{t("explore.peoplesearch.no_results")}</Center>
    );

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
        "2xl": "repeat(5, 1fr)",
      }}
      gap={4}
      paddingX={4}
      paddingTop={4}
      marginBottom={4}
    >
      {people?.pages.map((page, i) => (
        <Fragment key={i}>
          {page.map((person) => (
            <GridItem key={person._id} minWidth="100%">
              <Link
                as={RouterLink}
                to={`/profile/${person._id}`}
                flex="1 1 100%"
                opacity="0.8"
                _hover={{
                  opacity: "1",
                }}
              >
                <Box
                  key={person._id}
                  textAlign="center"
                  backgroundColor="blackAlpha.300"
                  paddingTop={10}
                  borderRadius={4}
                  overflow="hidden"
                  _hover={{ textDecoration: "none" }}
                  height="100%"
                  display="flex"
                  flexDirection="column"
                >
                  <Box flex="1">
                    <Avatar
                      name={person.full_name}
                      src={person.image}
                      showBorder
                      variant="usercard"
                    />

                    <Text marginTop={4} marginBottom={2} as="h3" color="black">
                      {person.full_name}
                    </Text>

                    <Text fontSize="sm" marginBottom={8} color="black">
                      {person.job_title}
                    </Text>

                    {person.skills && (
                      <Box
                        minHeight={20}
                        maxWidth="60%"
                        margin="0 auto"
                        marginBottom={10}
                      >
                        {person.skills.map((skill: any, index: number) => {
                          if (index < 2)
                            return (
                              <Tag key={skill._id} variant="explore">
                                <TagLabel>{skill.name}</TagLabel>
                              </Tag>
                            );
                        })}

                        {person.skills.length > 1 && (
                          <Tag variant="explore">
                            <TagLabel>+ {person.skills.length - 2}</TagLabel>
                          </Tag>
                        )}
                      </Box>
                    )}
                  </Box>

                  <Flex
                    background="brand.primary"
                    color="white"
                    minHeight="50px"
                    alignContent="center"
                    justifyContent="center"
                  >
                    {person.linked_locations?.length > 0 && (
                      <Box
                        flex={1}
                        maxWidth="50%"
                        display="flex"
                        padding={4}
                        alignItems="center"
                        justifyContent="center"
                        overflow="hidden"
                      >
                        <Box>
                          <Globe />
                        </Box>

                        <Text
                          as="b"
                          fontSize="xs"
                          marginLeft={2}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {person.linked_locations[0].name}
                        </Text>
                      </Box>
                    )}

                    {person.linked_departments.length > 0 && (
                      <Box
                        flex={1}
                        maxWidth="50%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding={4}
                      >
                        <UserFriends />

                        <Text
                          as="b"
                          fontSize="xs"
                          marginLeft={2}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {person.linked_departments[0].name}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Link>
            </GridItem>
          ))}
        </Fragment>
      ))}
    </Grid>
  );
}
