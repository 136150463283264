import { Config } from "@/client/types/Config";
import { create } from "zustand";
import { locales } from "@/client/utils/locales";

interface ConfigState {
  config: Config;
  setConfig: (config: Config) => void;
  locales: { name: string; value: string }[];
}

export const useConfigStore = create<ConfigState>((set) => ({
  config: {
    theme: {},
    features: {},
    customCopy: {},
    backgroundInfo: {},
    froalaConfig: {},
    ticketingBrand: {},
  } as Config,
  setConfig: (config) => set({ config }),
  locales,
}));
