/* eslint react/jsx-props-no-spreading: 0 */

import { Text } from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import RegistrationFormsService from "@/client/services/api/RegistrationFormsService";
import { useConfigStore } from "@/client/services/state/configStore";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../utils/useDocumentTitle";
import { RegisterFromFormBody } from "../types/RegistrationForms";
import DynamicForm from "./registration-forms/DynamicForm";
import RegisterWithProductSelection from "./RegisterWithProductSelection";

interface UIDInputs {
  first_name: string;
  last_name: string;
  email: string;
  job_title: string;
  userid: string;
  language: string;
  purchaseProductId?: string;
}

interface RegisterProps {
  companyDomain: string;
  registrationData: RegisterFromFormBody;
  selectedProduct?: string;
}

enum Pages {
  LandingPageCreation = "landingPageCreation",
  RegistrationForm = "registrationForm",
}

export default function RegisterWithCode() {
  useDocumentTitle("Register");
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const domain = config.host;
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const { code } = useParams();

  if (!config.features.enable_registration_forms) {
    return <Navigate to="/login" />;
  }

  const methods = useForm<UIDInputs>({ mode: "onChange" });
  const { handleSubmit } = methods;

  const {
    isLoading: isFormLoading,
    isError: isFormError,
    data: formData,
    error: formError,
  } = useQuery({
    enabled: !!code,
    queryKey: ["registration-forms", code],
    queryFn: () => RegistrationFormsService.getFormByCode(domain, code!),
  });

  const registerWithRegistrationForm = useMutation({
    mutationFn: ({ companyDomain, registrationData }: RegisterProps) =>
      RegistrationFormsService.registerWithRegistrationFormCode(
        formData._id,
        companyDomain,
        registrationData
      ),
    onSuccess: () => {
      navigate("/wait-for-invite");
    },
    onError: () =>
      setToast({ show: true, status: "error", title: "Invalid Credentials" }),
  });

  const onSubmit: SubmitHandler<UIDInputs> = async (registrationData) => {
    registerWithRegistrationForm.mutate({
      companyDomain: domain,
      registrationData,
    });
  };

  if (isFormLoading) return <div>Loading...</div>;

  if (isFormError) return <div>{"Error:" + formError}</div>;

  if (!formData.active) {
    return <Navigate to="/page-inactive" />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit!)}>
        {formData.type === "product_landing_page" ? (
          <RegisterWithProductSelection formData={formData} />
        ) : (
          <>
            <Text as="h4">{t("preauth.invite.registerWithUserID")}</Text>
            <DynamicForm fields={formData.fields} />
          </>
        )}
      </form>
    </FormProvider>
  );
}
