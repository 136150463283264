import { Button, FormControl, Input, Stack, Text } from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Country } from "@/client/types/preauth/Preauth";
import PreAuthService from "@/client/services/api/PreAuthService";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface RegionCodeInputs {
  regionCode: string;
}

interface RegionCodeFormProps {
  domain: string;
  setStep: (step: number) => void;
  setCountries: (countries: Country[]) => void;
}

// TODO: Add translation keys

export default function RegionCodeForm({
  domain,
  setStep,
  setCountries,
}: RegionCodeFormProps) {
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<RegionCodeInputs>({ mode: "onChange" });

  interface InitialDataVariables {
    regionCode: string;
  }

  const setInitialData = useMutation({
    mutationFn: ({ regionCode }: InitialDataVariables) =>
      PreAuthService.registerWithRegionCodeInitial(regionCode, domain),
    onSuccess: (res) => {
      setCountries(res.data.countries);
      setStep(1);
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.invalidCode"),
      }),
  });

  const onSubmit: SubmitHandler<InitialDataVariables> = async (data) =>
    setInitialData.mutate({ regionCode: data.regionCode.toLowerCase() });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Text as="h4">{t("global.forms.labels_addRegionCode")}</Text>

        <FormControl>
          <Input
            id="regionCode"
            type="text"
            placeholder={`${t("global.forms.labels_regioncode")}`}
            variant="flushed"
            {...register("regionCode", { required: true })}
          />
        </FormControl>

        <Button
          type="submit"
          width="full"
          marginTop={4}
          isDisabled={!isValid}
          isLoading={setInitialData.status === "loading"}
        >
          {t("global.forms.labels_submit")}
        </Button>
      </Stack>
    </form>
  );
}
