import { PersistOptions, persist } from "zustand/middleware";
import { create, StateCreator } from "zustand";

interface WorkshopType {
  value: string;
  label: string;
  type: "textInput" | "workshopType";
}

interface WorkshopsState {
  selectedRegistrationStatus: string | null;
  setSelectedRegistrationStatus: (status: string) => void;
  selectedLocation: string | null;
  setSelectedLocation: (location: string) => void;
  selectedTime: string | null;
  setSelectedTime: (location: string) => void;
  persistedSearchTerm: string;
  setPersistedSearchTerm: (search: string) => void;
  persistedTypeRestriction: string;
  setPersistedTypeRestriction: (type: string) => void;
  persistedSelectedItems: any[];
  setPersistedSelectedItems: (items: any[]) => void;
}

type WorkshopsPersist = (
  config: StateCreator<WorkshopsState>,
  options: PersistOptions<WorkshopsState>
) => StateCreator<WorkshopsState>;

export const useWorkshopsStore = create<WorkshopsState>(
  (persist as WorkshopsPersist)(
    (set) => ({
      selectedRegistrationStatus: null,
      setSelectedRegistrationStatus: (status: string) =>
        set(() => ({ selectedRegistrationStatus: status })),
      selectedLocation: null,
      setSelectedLocation: (location: string) =>
        set(() => ({ selectedLocation: location })),
      selectedTime: null,
      setSelectedTime: (time: string) => set(() => ({ selectedTime: time })),
      persistedSearchTerm: "",
      setPersistedSearchTerm: (search: string) =>
        set(() => ({ persistedSearchTerm: search })),
      persistedTypeRestriction: "",
      setPersistedTypeRestriction: (type: string) =>
        set(() => ({ persistedTypeRestriction: type })),
      persistedSelectedItems: [],
      setPersistedSelectedItems: (items: any[]) =>
        set(() => ({ persistedSelectedItems: items })),
    }),
    { name: "workshopsStore" }
  )
);
