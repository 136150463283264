/* eslint react/no-unstable-nested-components: 0 */

import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Badge,
  Flex,
  Progress,
  Text,
} from "@chakra-ui/react";

import { PaginationState, createColumnHelper } from "@tanstack/react-table";
import { Person } from "@/client/types/Person";

import Loading from "@/client/components/media/Loading";
import PartnersManagerHeader from "@/client/components/layout/PartnersManagerHeader";
import PartnersService from "@/client/services/api/admin/PartnersService";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import {
  DateFormatGroupKey,
  dateFormatTenant,
} from "@/client/utils/dateFormat";
import { useAuthStore } from "@/client/services/state/authStore";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import { PlusCircle } from "@/client/components/icons/ContinuIcons";
import { Link as RouterLink, useParams } from "react-router-dom";
import ContentService from "@/client/services/api/ContentService";
import { useToastStore } from "@/client/services/state/toastStore";
import AssignmentsService from "@/client/services/api/AssignmentsService";
import TrackService from "@/client/services/api/TrackService";
import { ChevronRightIcon } from "@chakra-ui/icons";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";

export default function PartnerManagerContentActivity() {
  const { partner } = usePartnerStore();
  const { authConfig } = useAuthStore();
  const { setToast } = useToastStore();
  const { id: contentId, contentType } = useParams();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  useDocumentTitle(t("partners.manager.contentActivityTitle"));

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const getStatusCell = (userContent: any, content: any) => {
    if (userContent.satisfied_status) {
      return (
        <Badge
          borderRadius="5px"
          variant="active"
          width="100%"
          padding={1}
          textAlign="center"
        >
          Completed
        </Badge>
      );
    }
    // Progress Bar For Track
    if (content.type === "track") {
      const finished = userContent.completed_articles.concat(
        userContent.completed_assessments,
        userContent.completed_media,
        userContent.completed_quizzes,
        userContent.completed_scorm
      ).length;
      const needToFinish = content.sections.reduce(
        (arr: number, section: any) => arr + section.courses.length,
        0
      );
      const progress = Math.ceil((finished / needToFinish) * 100);

      return (
        <Flex width={60} alignItems="center" paddingX={1}>
          <Text fontSize="sm" flex="1" fontWeight="medium">
            {progress}% {t("contentViews.tracks.complete")}
          </Text>

          <Progress
            flex="1"
            width="full"
            size="sm"
            value={progress}
            variant="hollowPrimary"
          />
        </Flex>
      );
    }
    return (
      <Badge
        borderRadius="5px"
        variant="pending"
        width="100%"
        padding={1}
        textAlign="center"
      >
        Pending Completion
      </Badge>
    );
  };

  const { data: content, isLoading: isContentLoading } = useQuery({
    queryKey: ["content", contentType, contentId],
    queryFn: () => {
      if (!contentId) {
        return null;
      }
      if (contentType === "article") {
        return ContentService.getArticle(contentId);
      }
      if (contentType === "file") {
        return ContentService.getFile(contentId);
      }
      if (contentType === "video") {
        return ContentService.getVideo(contentId);
      }
      if (contentType === "track") {
        return TrackService.getLearningTrack(contentId);
      }
    },
    enabled: !!contentId,
    keepPreviousData: true,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "content-activity",
      fetchDataOptions,
      partner._id,
      contentId,
      contentType,
    ],
    queryFn: () =>
      PartnersService.getUserActivityForContent(
        fetchDataOptions,
        partner._id,
        contentId!,
        contentType!
      ),
    enabled: !!partner._id,
    keepPreviousData: true,
  });

  const columnHelper = createColumnHelper<any>();
  const usersColumns = [
    columnHelper.accessor("user", {
      cell: (info) => (
        <Flex alignItems="center" marginLeft={8}>
          <Avatar
            marginRight={2}
            backgroundColor="brand.primary"
            color="white"
            size="xs"
            src={info.getValue()[0]?.image}
            name={info.getValue()[0]?.full_name}
          />
          {info.getValue()[0]?.full_name}
        </Flex>
      ),
      header: () => (
        <Box as="span" marginLeft={9}>
          {t("global.forms.labels_name")}
        </Box>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("satisfied_status", {
      cell: (info) => (
        <span>
          {getStatusCell(
            info.row.original.user_content[0],
            info.row.original.content[0]
          )}
        </span>
      ),
      header: () => <span>Status</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("start_date", {
      cell: (info) => (
        <span>
          {info.getValue()
            ? dateFormatTenant(
                new Date(info.getValue()),
                company.date_display as DateFormatGroupKey,
                "base"
              )
            : "-----"}
        </span>
      ),
      header: () => <span>Start Date</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("completed_date", {
      cell: (info) => (
        <span>
          {info.getValue()
            ? dateFormatTenant(
                new Date(info.getValue()),
                company.date_display as DateFormatGroupKey,
                "base"
              )
            : "-----"}
        </span>
      ),
      header: () => <span>Completion Date</span>,
      footer: (info) => info.column.id,
    }),
  ];

  if (isLoading || isContentLoading) return <Loading />;

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <PartnersManagerHeader currentTab="content" />

      <AdminElevatedBox>
        <Flex alignItems="left" gap={2}>
          <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/extend/manager">
                Partners
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/extend/manager/content">
                Content
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              {t("partners.manager.assignContentToUsers")} for{" "}
              <em>{content.name ?? content.title}</em>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>

        <ManuallyPaginatedTable
          columns={usersColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      </AdminElevatedBox>
    </Box>
  );
}
