/* eslint-ignore react/jsx-props-no-spreading */

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import {
  ArrowLeft,
  Minus,
  Plus,
  Save,
} from "@/client/components/icons/ContinuIcons";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  defaultProfileFormValuesFalse,
  defaultProfileFormValuesTrue,
} from "./profileDefaultValues";
import { useNavigate, useParams } from "react-router-dom";

import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import AdminSecondaryHeader from "@/client/components/admin/layout/AdminSecondaryHeader";
import ContentSegmentation from "./ContentSegmentation";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import HorizontalAnchorMenu from "@/client/components/admin/navigation/HorizontalAnchorMenu";
import Loading from "@/client/components/media/Loading";
import ProfileConnectAccess from "./ProfileConnectAccess";
import ProfileAdminAccess from "./ProfileAdminAccess";
import ProfileAssignments from "./ProfileAssignments";
import ProfileContentAnalytics from "./ProfileContentAnalytics";
import ProfileContentCreation from "./ProfileContentCreation";
import ProfileContentEdit from "./ProfileContentEdit";
import ProfileExploreSettings from "./ProfileExploreSettings";
import { ProfileForm as ProfileFormType } from "@/client/types/admin/profiles/ProfileForm";
import ProfileGeneralDetails from "./ProfileGeneralDetails";
import ProfileJourneys from "./ProfileJourneys";
import UserSegmentation from "./UserSegmentation";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import { useProfilesService } from "@/client/services/hooks/admin/profiles/useProfilesService";
import { useTranslation } from "react-i18next";

// TODO: Combine the accordion here with accordion from user work into reusable component

export default function ProfileForm() {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { createProfile, updateProfile, getProfile } =
    useProfilesService(profileId);

  const { refetch } = getProfile;

  const methods = useForm<ProfileFormType>({
    defaultValues: profileId
      ? defaultProfileFormValuesFalse
      : defaultProfileFormValuesTrue,
    mode: "onChange",
  });

  useEffect(() => {
    if (getProfile.data) {
      methods.reset(getProfile.data);
    }
  }, [getProfile.data]);

  const onSubmit: SubmitHandler<ProfileFormType> = (data) => {
    const formData = {
      ...data,
      permissions: {
        ...data.permissions,
        admin: {
          ...data.permissions.admin,
          users: {
            ...data.permissions.admin.users,
            user_group: {
              departments:
                data.permissions.admin.users.user_group.departments.map(
                  (d) => d._id
                ),
              locations: data.permissions.admin.users.user_group.locations.map(
                (l) => l._id
              ),
              teams: data.permissions.admin.users.user_group.teams.map(
                (te) => te._id
              ),
              org_levels:
                data.permissions.admin.users.user_group.org_levels.map(
                  (o) => o._id
                ),
              grades: data.permissions.admin.users.user_group.grades.map(
                (g) => g._id
              ),
            },
          },
          content: {
            ...data.permissions.admin.content,
            segmentation_group: {
              departments:
                data.permissions.admin.content.segmentation_group.departments.map(
                  (d) => d._id
                ),
              locations:
                data.permissions.admin.content.segmentation_group.locations.map(
                  (l) => l._id
                ),
              teams:
                data.permissions.admin.content.segmentation_group.teams.map(
                  (te) => te._id
                ),
              org_levels:
                data.permissions.admin.content.segmentation_group.org_levels.map(
                  (o) => o._id
                ),
              grades:
                data.permissions.admin.content.segmentation_group.grades.map(
                  (g) => g._id
                ),
            },
            allowed_categories:
              data.permissions.admin.content.allowed_categories.map(
                (c) => c._id
              ),
          },
        },
      },
    };

    profileId
      ? updateProfile
          .mutateAsync({
            profileData: { ...formData, company: company._id, _id: profileId! },
          })
          .then(() => refetch())
      : createProfile
          .mutateAsync({
            profileData: { ...formData, created_by: user._id, user: user._id },
          })
          .then(() => refetch());
  };

  const anchorLinks = [
    {
      name: t("profiles.edit.details"),
      linkName: "details",
    },
    {
      name: t("global.vertNavbar.explore"),
      linkName: "explore",
    },
    {
      name: "Content Permissions",
      linkName: "content-permissions",
    },
    {
      name: t("profiles.edit.contentAnalytics"),
      linkName: "content-analytics",
    },
    {
      name: t("profiles.edit.contentSegmentation"),
      linkName: "content-segmentation",
    },
    {
      name: t("global.contentTypes.journeys"),
      linkName: "journeys",
    },
    {
      name: t("global.contentTypes.assignments"),
      linkName: "assignments",
    },
    {
      name: t("global.roles.users"),
      linkName: "users",
    },
    {
      name: t("profiles.edit.connectAccess"),
      linkName: "connect-access",
    },
    {
      name: t("profiles.edit.adminAccess"),
      linkName: "admin-access",
    },
  ];

  const formSections = [
    {
      name: "details",
      label: t("profiles.edit.details"),
      tooltipText: t("profiles.edit.details.tooltip"),
      children: <ProfileGeneralDetails />,
    },
    {
      name: "explore",
      label: t("profiles.edit.exploreSettings"),
      tooltipText: t("profiles.edit.exploreSettingsHelpText"),
      children: <ProfileExploreSettings />,
    },
    {
      name: "content-permissions",
      label: t("profiles.edit.contentCreation"),
      tooltipText: t("profiles.edit.contentCreationHelpText"),
      children: (
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <GridItem backgroundColor="brand.mist" borderRadius="xl" padding={4}>
            <ProfileContentCreation />
          </GridItem>

          <GridItem backgroundColor="brand.mist" borderRadius="xl" padding={4}>
            <ProfileContentEdit />
          </GridItem>
        </Grid>
      ),
    },
    {
      name: "content-analytics",
      label: t("profiles.edit.contentAnalytics"),
      tooltipText: t("profiles.edit.contentAnalyticsHelpText"),
      children: <ProfileContentAnalytics />,
    },
    {
      name: "content-segmentation",
      label: t("profiles.edit.contentSegmentation"),
      tooltipText: t("profiles.edit.contentSegmentationHelp"),
      children: <ContentSegmentation />,
    },
    {
      name: "journeys",
      label: t("global.contentTypes.journeys"),
      tooltipText: t("edit.profiles.journeys.helpText"),
      children: <ProfileJourneys />,
    },
    {
      name: "assignments",
      label: t("profiles.edit.assignment"),
      tooltipText: t("profiles.edit.assignmentHelpText"),
      children: <ProfileAssignments />,
    },
    {
      name: "users",
      label: t("profiles.edit.user"),
      tooltipText: t("profiles.edit.usersHelpText"),
      children: <UserSegmentation />,
    },
    {
      name: "connect-access",
      label: t("profiles.edit.connectAccess"),
      tooltipText: t("profiles.edit.connectAccessHelpText"),
      children: <ProfileConnectAccess />,
    },
    {
      name: "admin-access",
      label: t("profiles.edit.adminAccess"),
      tooltipText: t("profiles.edit.adminAccessHelpText"),
      children: <ProfileAdminAccess />,
    },
  ];

  if (profileId && getProfile.isLoading) return <Loading />;

  if (profileId && getProfile.isError)
    return <ErrorAlert title={t("edit.profiles.edit.loadError")} />;

  return (
    <>
      <AdminPrimaryHeader
        title={`${t("overview.settings.access_profiles")}`}
        setSearchTerm={() => {}}
        sortOrder="newest"
        setSortOrder={() => {}}
        tooltipText=""
        isSearching={false}
        showSearch={false}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AdminSecondaryHeader>
            <Flex width="full" justifyContent="space-between">
              <Button
                variant="adminPrimary"
                size="xs"
                leftIcon={<ArrowLeft />}
                onClick={() => navigate("/admin/profiles")}
              >
                {t("global.actions.back")}
              </Button>

              <Button
                type="submit"
                isDisabled={!methods.formState.isValid}
                isLoading={createProfile.isLoading || updateProfile.isLoading}
                variant="adminPrimary"
                size="xs"
                leftIcon={<Save />}
              >
                {profileId
                  ? t("global.actions.update")
                  : t("global.action.save")}
              </Button>
            </Flex>
          </AdminSecondaryHeader>

          <HorizontalAnchorMenu tabs={anchorLinks} alignment="center" />

          <Accordion
            variant="adminForm"
            defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
            allowMultiple
          >
            {formSections.map((section) => (
              <AccordionItem key={section.name}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton name={section.name}>
                      <Box as="span" flex="1" textAlign="left">
                        <FormLabelWithTooltip
                          label={section.label}
                          tooltipText={`${section.tooltipText}`}
                        />
                      </Box>

                      {isExpanded ? <Minus /> : <Plus />}
                    </AccordionButton>

                    <AccordionPanel pb={4}>{section.children}</AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </form>
      </FormProvider>
    </>
  );
}
