import { Box, HStack, Progress, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { Check } from "../../icons/ContinuIcons";
import DueDateBadge from "../DueDateBadge";
import { format } from "date-fns";
import { getMSWIdFromSessionId } from "@/client/services/api/content/workshops/MultiSessionWorkshopService";
import { useTranslation } from "react-i18next";
import LinkCardWrapper from "./LinkCardWrapper";

interface DashboardCardProps {
  id: string;
  title: string;
  type: string;
  imageUrl: string;
  isAssignment?: boolean;
  dueIn?: number | null;
  isCompleted?: boolean;
  trackCompletedDate?: string;
  progress?: number;
  badgeDetails?: { acquired_date: string; badge: { description: string } };
  assingmentCompletedDate?: string;
  workshopStartDate?: string;
  workshopEndDate?: string;
  originRoute?: string;
  contextId?: string;
  showCompletedCheck?: boolean;
  workshopInstanceId?: string;
}

export default function DashboardCard({
  id,
  title,
  type,
  imageUrl,
  isAssignment,
  dueIn,
  isCompleted,
  trackCompletedDate,
  progress,
  badgeDetails,
  assingmentCompletedDate,
  workshopStartDate,
  workshopEndDate,
  originRoute,
  contextId,
  showCompletedCheck,
  workshopInstanceId,
}: DashboardCardProps) {
  const { t } = useTranslation();
  const [contentUrl, setContentUrl] = useState("");

  const getContentUrl = async (contentType: string = "") => {
    let contentRoute = "";

    if (contentType === "multi_session_workshop_session") {
      const mswId = await getMSWIdFromSessionId(id);

      contentRoute = `/multi-session-workshop/${mswId}`;
    } else if (contentType === "multi_session_workshop") {
      contentRoute = `/multi-session-workshop/${id}`;
    } else if (contentType === "imported_content") {
      contentRoute = `/imported-content/${id}`;
    } else if (contentType === "badge") {
      contentRoute = "";
    } else if (contentType === "workshop" && workshopInstanceId) {
      contentRoute = `/workshop/${id}?workshopInstanceId=${workshopInstanceId}`;
    } else {
      contentRoute = `/${contentType.toLowerCase()}/${id}`;
    }
    if (isAssignment && contextId && contentRoute.length) {
      contentRoute += `?contextId=${contextId}`;
    }
    setContentUrl(contentRoute);
  };

  useEffect(() => {
    getContentUrl(type);
  }, [type]);

  const setContentTypeTitle = (string: string = "", vendor?: string) => {
    let contentTitle;

    if (string === "multi_session_workshop") {
      contentTitle = t("global.contentTypes.workshop");
    } else if (string === "multi_session_workshop_session") {
      contentTitle = t("global.contentTypes.multi_session_workshop");
    } else if (string === "presentation") {
      contentTitle = t("modules.contentPicker.presentation");
    } else if (string === "media") {
      contentTitle = t("modules.contentPicker.video");
    } else if (string === "assessment") {
      contentTitle = t("modules.contentPicker.assessment");
    } else if (string === "badge") {
      contentTitle = t("dashboard.badge");
    } else if (string === "imported_content") {
      if (vendor && vendor === "linkedin_learning") {
        contentTitle = t("global.contentTypes.linkedin_learning");
      } else if (vendor && vendor === "udemy") {
        contentTitle = t("global.contentTypes.udemy");
      } else if (vendor && vendor === "masterclass") {
        contentTitle = t("global.contentTypes.masterclass");
      } else {
        contentTitle = t("global.contentTypes.imported_content");
      }
    } else {
      contentTitle = t("global.contentTypes." + type);
    }
    return contentTitle;
  };

  return (
    <LinkCardWrapper
      linkUrl={contentUrl}
      imageUrl={imageUrl}
      originRoute={originRoute}
    >
      <Stack padding={4} flex={1} flexDirection="column">
        <Text fontSize="2xs">{setContentTypeTitle(type)}</Text>

        <Box mb={4} flex={1}>
          <HStack>
            <Text fontWeight={500} size="sm">
              {title}
            </Text>

            {showCompletedCheck && <Check color="green" />}
          </HStack>

          <Text>
            {isAssignment && dueIn && !isCompleted && (
              <DueDateBadge daysUntilDue={dueIn} />
            )}
          </Text>

          {type === "Track" && (
            <>
              {trackCompletedDate && (
                <Box fontSize="xs" fontWeight={500}>
                  {t("dashboard.assignments.completedDate", {
                    value: format(
                      new Date(trackCompletedDate),
                      "MMMM do, yyyy"
                    ),
                  })}
                </Box>
              )}
              <Box fontSize="sm" marginTop={2}>
                {progress}% Complete{" "}
                <Progress
                  width="90px"
                  height="8px"
                  value={progress}
                  borderRadius={4}
                  display="inline-block"
                />
              </Box>
            </>
          )}
          {type === "badge" && badgeDetails && (
            <>
              <Box fontSize="xs" fontWeight={500}>
                {t("dashboard.badges.acquiredOn")}{" "}
                {format(new Date(badgeDetails.acquired_date), "MMMM do, yyyy")}
              </Box>
              <Box fontSize="xs" fontWeight={500}>
                {badgeDetails.badge.description}
              </Box>
            </>
          )}
          {assingmentCompletedDate && (
            <Box fontSize="xs" fontWeight={500}>
              {t("dashboard.assignments.completedDate", {
                value: format(
                  new Date(assingmentCompletedDate),
                  "MMMM do, yyyy"
                ),
              })}
            </Box>
          )}
          {!!workshopStartDate && (
            <Box fontSize="xs" fontWeight={300}>
              {format(new Date(workshopStartDate), "p, MMMM do, yyyy")}
            </Box>
          )}
          {!!workshopEndDate && (
            <Box fontSize="xs" fontWeight={300}>
              to {format(new Date(workshopEndDate), "p, MMMM do, yyyy")}
            </Box>
          )}
        </Box>
      </Stack>
    </LinkCardWrapper>
  );
}

DashboardCard.defaultProps = {
  isAssignment: false,
  dueIn: null,
  isCompleted: false,
  trackCompletedDate: null,
  progress: 0,
  badgeDetails: null,
  assingmentCompletedDate: null,
  workshopStartDate: null,
  workshopEndDate: null,
  originRoute: null,
  contextId: null,
  showCompletedCheck: false,
  workshopInstanceId: null,
};
