import {
  ButtonGroup,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import OutlineButton from "@/client/components/shared/buttons/OutlineButton";
import SolidButton from "@/client/components/shared/buttons/SolidButton";
import { useArchiveContent } from "@/client/services/hooks/admin/authoring/useArchiveContent";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useParams } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export default function ArchiveModal() {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    archiveModalIsOpen,
    setArchiveModalIsOpen,
    contentType,
    setCanLeave,
    contentTitle,
  } = useCreateStore();
  const { archiveMutation } = useArchiveContent();
  const { setToast } = useToastStore();
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  const { data } = archiveMutation;

  useEffect(() => {
    if (data) {
      const {
        in_track: inTrack,
        in_journey: inJourney,
        in_workflow: inWorkflow,
      } = data;

      if (inTrack.length > 0 || inJourney.length > 0 || inWorkflow.length > 0) {
        setArchiveModalIsOpen(false);
        setErrorModalIsOpen(true);

        return;
      }

      setToast({
        show: true,
        status: "success",
        title: t("authoring.archiveSuccess"),
      });

      window.location.replace("/pvt/#/overview/content");
    }
  }, [data]);

  const isOpen = archiveModalIsOpen;

  const onClose = () => setArchiveModalIsOpen(false);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />

        <ModalContent>
          <ModalBody paddingTop={12}>
            <VStack textAlign="center">
              <Text fontSize="20px" fontWeight={600} color="baseBlack">
                {t("authoring.confirmArchive")}
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
            <ButtonGroup
              width="full"
              alignItems="center"
              justifyContent="center"
            >
              <OutlineButton onClick={onClose}>
                {t("authoring.returnToEdit")}
              </OutlineButton>

              <SolidButton
                onClick={() => {
                  setCanLeave(true);

                  archiveMutation.mutateAsync({ id, type: contentType });
                }}
              >
                {t("authoring.archive")}
              </SolidButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        isOpen={!!data && errorModalIsOpen}
        onClose={() => setErrorModalIsOpen(false)}
        size="2xl"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalBody paddingTop={12} overflow="auto" maxHeight="80vh">
            <VStack textAlign="center" spacing={4}>
              <Text fontSize="20px" fontWeight={600} color="baseBlack">
                {t("contentManager.not_all_archived")}
              </Text>

              <Text>{t("contentManager.used_within_track")}</Text>

              {data && (
                <>
                  {data.containingJourneys.length > 0 && (
                    <Text>{t("archiveWarningModal.forJourneys")}</Text>
                  )}

                  {(data.containingTracks.length > 0 ||
                    data.containingWorkflows.length > 0) && (
                    <Text>{t("archiveWarningModal.forTracks")}</Text>
                  )}
                </>
              )}

              <Text width="full" textAlign="left" fontWeight={600}>
                {`${t("archiveWarningModal.contentTitle")}${contentTitle}`}
              </Text>

              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>{t("archiveWarningModal.table.contentType")}</Th>
                      <Th>{t("archiveWarningModal.table.contentTitle")}</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {data?.containingJourneys.map((journey) => (
                      <Tr key={journey.content}>
                        <Td>{t("global.contentTypes.journey")}</Td>

                        <Td>
                          <Link
                            isExternal
                            href={`/pvt/#/edit/journeys/${journey.journey._id}`}
                          >
                            {journey.journey.title}
                          </Link>
                        </Td>
                      </Tr>
                    ))}

                    {data?.containingTracks.map((track) => (
                      <Tr key={track.content}>
                        <Td>{t("global.contentTypes.track")}</Td>

                        <Td>
                          <Link
                            isExternal
                            href={`/pvt/#/edit/tracks/${track.track._id}`}
                          >
                            {track.track.name}
                          </Link>
                        </Td>
                      </Tr>
                    ))}

                    {data?.containingWorkflows.map((workflow) => (
                      <Tr key={workflow.content}>
                        <Td>{t("global.contentTypes.workflow")}</Td>

                        <Td>
                          <Link
                            isExternal
                            href={`/pvt/#/edit/workflows/${workflow.workflow._id}`}
                          >
                            {workflow.workflow.name}
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          </ModalBody>

          <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
            <SolidButton onClick={() => setErrorModalIsOpen(false)}>
              {t("archiveWarningModal.close")}
            </SolidButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
