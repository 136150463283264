import {
  CLIENT_CREATION_KEY_OPENAI,
  registerClientCreation,
} from "./clientRegister";
import axios, { AxiosInstance } from "axios";

// eslint-disable-next-line import/no-mutable-exports
export let openAIClient: AxiosInstance;

const create = (baseURL: string) => {
  openAIClient = axios.create({
    baseURL: baseURL + "/open-ai/",
  });
};

registerClientCreation(CLIENT_CREATION_KEY_OPENAI, create);
