import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import TeamsService from "@/client/services/api/integrations/TeamsService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useToastStore } from "@/client/services/state/toastStore";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function TeamsRedirect() {
  useDocumentTitle("You are being redirected");
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const navigate = useNavigate();

  const params: any = new URLSearchParams(window.location.search);
  let parsedParams: { [code: string]: string } = {};

  const createMutation = useMutation({
    mutationFn: async () => TeamsService.createIntegration(parsedParams.code),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: "Successfully integrated Microsoft Teams",
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: "Error integrating Microsoft Teams",
      }),
  });

  const featureFlags: {
    [key: string]: boolean;
  } = authConfig.company.feature_flags;

  parsedParams = {};
  params.forEach((a: any, b: string) => {
    parsedParams[b] = a;
  });

  const checkParams = function () {
    if (parsedParams.code === "undefined" || !parsedParams.code) {
      setToast({
        show: true,
        status: "error",
        title: "Microsoft Teams integration unsuccessful",
      });
      navigate("/settings");
    } else {
      createMutation.mutate();
      navigate("/settings");
    }
  };

  useEffect(() => {
    const initialize = () => {
      if (featureFlags && !featureFlags.enable_microsoft_teams_meetings) {
        navigate("/explore");
        return;
      }
      checkParams();
    };
    initialize();
  }, []);

  return <></>;
}
