import { Box, Grid } from "@chakra-ui/react";
import PartnersManagerHeader from "@/client/components/layout/PartnersManagerHeader";
import AssignmentsOnTime from "@/client/components/data-display/charts/partners/AssignmentsOnTime";
import AssignedCompletions from "@/client/components/data-display/charts/partners/AssignedCompletions";
import { AssignmentsFromWorkflows } from "@/client/components/data-display/charts/partners/AssignmentsFromWorkflows";

export default function PartnersManager() {
  return (
    <Box background="brand.mist" paddingTop={2} height="100vh">
      <PartnersManagerHeader currentTab="overview" />
      <Box>
        <Grid
          templateRows="300px 300px"
          templateColumns="repeat(2, 1fr)"
          justifyContent="space-between"
          gap={4}
          padding={4}
        >
          <AssignmentsOnTime />
          <AssignedCompletions />
          <AssignmentsFromWorkflows />
        </Grid>
      </Box>
    </Box>
  );
}
