import {
  Grid,
  GridItem,
  InputGroup,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ProductLicensingType,
  ProductSubscriptionDuration,
} from "@/client/services/api/graphql/gql/graphql";

export default function ProductSettingsForm() {
  const { setValue, control, watch } = useFormContext<any>();
  const { t } = useTranslation();
  const price = watch("price");

  const dropdownOptions = {
    licensing: [
      // {
      //   name: t('overviewProducts.licensing_permanent'),
      //   val: ProductLicensingType.Permanent,
      // },
      {
        name: t("overviewProducts.licensing_subscription"),
        val: ProductLicensingType.Subscription,
      },
    ],
    currency: [
      // TODO add more?
      {
        name: "USD",
        val: "USD",
      },
    ],
    subscriptionDuration: [
      {
        name: t("products.subscriptionDurationOptions.monthly"),
        val: ProductSubscriptionDuration.Monthly,
      },
      {
        name: t("products.subscriptionDurationOptions.yearly"),
        val: ProductSubscriptionDuration.Yearly,
      },
    ],
  };

  const format = (val: string) => {
    if (!val) return "$";
    return "$" + val;
  };
  const parse = (val: string) => {
    if (!val || parseInt(val, 10) < 0) return "";
    return val.replace(/^\$/, "");
  };

  return (
    <Stack>
      <Text variant="createHeading">{t("authoring.tabs.settings")}</Text>
      <Text variant="createHelpText">
        {t("authoring.product.settingsSubHeading")}
      </Text>
      <Stack spacing={2}>
        <Text variant="createLabel">
          {t("ecommerce.product.edit.pricingChanges")}
        </Text>
        <Text variant="createHelpText">
          {t("ecommerce.product.edit.pricingChangesHelpText")}
        </Text>
      </Stack>

      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <Select
            variant="create"
            name="subscriptionDuration"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setValue("subscriptionDuration", e.target.value)
            }
            value={watch("subscriptionDuration") || null}
          >
            <option selected disabled value="">
              {t("authoring.product.subscriptionTermLength")}
            </option>
            {dropdownOptions.subscriptionDuration.map((opt) => (
              <option key={"option_" + opt.name} value={opt.val}>
                {opt.name}
              </option>
            ))}
          </Select>
        </GridItem>

        <GridItem>
          <InputGroup>
            <Controller
              name="price"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("global.forms.field_required"),
                },
              }}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  background="#FFFFFF"
                  precision={2}
                  onChange={(valueString) =>
                    setValue("price", parse(valueString))
                  }
                  value={format(price)}
                  width="full"
                  variant="create"
                >
                  <NumberInputField />
                </NumberInput>
              )}
            />
          </InputGroup>
        </GridItem>
      </Grid>
    </Stack>
  );
}
