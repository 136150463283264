import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  useToken,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  VStack,
} from "@chakra-ui/react";
import { Close } from "@/client/components/icons/ContinuIcons";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import AdminSecondaryHeader from "@/client/components/admin/layout/AdminSecondaryHeader";
import SingleCustomFieldsEditor from "./SingleCustomFieldEditor";
import CompanyService from "@/client/services/api/CompanyService";
import NavigationBlocker from "@/client/components/navigation/NavigationBlocker";
import { useAdminStore } from "@/client/services/state/admin/adminStore";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import DeleteItemModal from "@/client/components/admin/overlay/DeleteItemModal";
import { useAuthStore } from "@/client/services/state/authStore";
import { useToastStore } from "@/client/services/state/toastStore";
import Loading from "@/client/components/media/Loading";
import { useTranslation } from "react-i18next";
import {
  PlusCircle,
  Save,
  Search,
} from "@/client/components/icons/ContinuIcons";
import { CustomField } from "@/client/types/AuthConfig";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import useDocumentTitle from "@/client/utils/useDocumentTitle";

interface ModalCondition {
  open: boolean;
  action?: string | number;
}

export default function CompanyCustomFieldsEditor() {
  const { setToast } = useToastStore();
  const queryClient = useQueryClient();
  const { authConfig } = useAuthStore();
  const [brandHighlight] = useToken("colors", ["brand.highlight"]);

  const { t } = useTranslation();
  const { setDeleteModal } = useAdminStore();
  useDocumentTitle(t("customFields.admin.title"));

  const {
    isError,
    isLoading,
    data: company,
    refetch,
  } = useQuery({
    queryKey: ["companies", authConfig.company._id],
    queryFn: async () => CompanyService.getCompany(authConfig.company._id),
    select: (data: Record<string, any>) => {
      // maps and shapes data when company data is available
      const mappedFields = data.user_metadata_fields.map(
        (field: Record<string, any>) => ({
          name: field.name,
          description: field.description,
          dataType: field.data_type,
          validationRules: field.validation_rules,
          visibility: field.visibility,
        })
      );
      const userMetadataFields = mappedFields as CustomField[];
      return {
        ...data,
        user_metadata_fields: userMetadataFields,
        _id: data._id,
      };
    },
  });

  const [customFields, setCustomFields] = useState<CustomField[]>(
    company?.user_metadata_fields || []
  );
  const [activeFieldTab, setActiveFieldTab] = useState<number>(0);
  const [currentCustomField, setCurrentCustomField] = useState<CustomField>();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [modalCondition, setModalCondition] = useState<ModalCondition>({
    open: false,
  });
  const [cfSaved, setCFSaved] = useState<boolean>(true);

  const handleSearch = (searchString: string) => {
    const regex = new RegExp(searchString, "i");
    const foundFields = customFields.filter((field) => regex.test(field.name));
    setActiveFieldTab(0);
    setCustomFields(foundFields);
    setCurrentCustomField(foundFields[0]!);
  };

  useEffect(() => {
    if (!company) {
      return;
    }
    const userMetadataFields = company.user_metadata_fields;
    setCustomFields(userMetadataFields);
    setCurrentCustomField(userMetadataFields[activeFieldTab]!);
  }, [company?._id]);

  useEffect(() => {
    if (searchTerm) {
      handleSearch(searchTerm);
    }
    if (!searchTerm?.length) {
      if (!company) {
        return;
      }
      const userMetadataFields = company.user_metadata_fields;
      setCustomFields(userMetadataFields);
      setCurrentCustomField(userMetadataFields[activeFieldTab]!);
    }
  }, [searchTerm]);

  useEffect(() => {
    const originalCF = JSON.stringify(company?.user_metadata_fields);
    const currentCF = JSON.stringify(customFields);
    if (originalCF !== currentCF) {
      setCFSaved(false);
    } else {
      setCFSaved(true);
    }
  }, [customFields]);

  const saveCustomFields = useMutation({
    mutationFn: (customFieldsBody: CustomField[]) => {
      setCFSaved(true);
      return CompanyService.updateCompanyCustomFields(
        company?._id,
        customFieldsBody
      );
    },
    onSuccess: () => {
      refetch();
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.customField.updated"),
      });
      queryClient.invalidateQueries({ queryKey: ["user-metada-fields"] });
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdateProfile"),
      }),
  });

  const deleteItem = useMutation({
    mutationFn: () => {
      customFields.splice(activeFieldTab, 1);
      const newTab = activeFieldTab ? activeFieldTab - 1 : activeFieldTab;
      setActiveFieldTab(newTab);
      setCurrentCustomField(customFields[newTab]);
      setCFSaved(true);
      return CompanyService.updateCompanyCustomFields(
        company?._id,
        customFields
      );
    },
    onSuccess: () => {
      refetch();
      setToast({
        show: true,
        status: "success",
        title: t("admin.connect.itemDeleteSuccessful"),
      });
    },
    onError: (error) => {
      console.error(error);
      setToast({
        show: true,
        status: "error",
        title: t("admin.connect.itemDeleteError"),
      });
    },
  });

  if (isError) return <ErrorAlert title="Unable to get assessment scores" />;
  if (
    isLoading ||
    (company?.user_metadata_fields.length &&
      !customFields.length &&
      !searchTerm)
  )
    return <Loading />;

  const addNewField = () => {
    const fieldBody = {
      name: "",
      dataType: "string",
      validationRules: {},
      visibility: {
        profile: "none",
        reports: "none",
        certificates: "none",
      },
    };

    const newCustomFields = customFields.slice();
    newCustomFields.unshift(fieldBody);
    setCustomFields(newCustomFields);
    setActiveFieldTab(0);
    setCurrentCustomField(newCustomFields[0]);
  };

  const handleFieldChange = (updatedField: any) => {
    const newCustomFields: CustomField[] = customFields.slice();
    if (currentCustomField) newCustomFields[activeFieldTab] = updatedField;
    setCustomFields(newCustomFields);
    setCurrentCustomField(updatedField);
  };

  // if type changes remove other rules
  const cleanValidationRules = (
    dataType: string,
    rules: Record<string, any> | null
  ) => {
    if (!rules) return null;

    switch (dataType) {
      case "number": {
        const { noSymbols, noNumbers, minLength, maxLength, ...numberFields } =
          rules;
        return numberFields;
      }
      case "string": {
        const { min, max, ...stringFields } = rules;
        return stringFields;
      }
      case "boolean":
        return null;
      default:
        return null;
    }
  };

  const checkRequired = (field: CustomField) => {
    let passed = true;
    if (!field.name.trim()) {
      passed = false;
    }
    if (!field.dataType) {
      passed = false;
    }
    if (
      !field.visibility ||
      !field.visibility.profile ||
      !field.visibility.reports ||
      !field.visibility.certificates
    ) {
      passed = false;
    }
    if (field.validationRules) {
      const vr = field.validationRules;
      Object.entries(vr).forEach((rule) => {
        if (!rule) {
          passed = false;
        }
      });
    }

    return passed;
  };

  const onSave = () => {
    let allowSave = true;
    const cleanedAndCheckedFields = customFields.map((field: CustomField) => {
      const cleanedFields = {
        ...field,
        validationRules: cleanValidationRules(
          field.dataType,
          field.validationRules
        ),
      };

      if (!checkRequired(cleanedFields)) {
        allowSave = false;
        setToast({
          show: true,
          status: "error",
          title: t("customFields.missingFields", {
            fieldName: field.name,
          }),
        });
      }
      return cleanedFields;
    });
    if (allowSave) {
      saveCustomFields.mutate(cleanedAndCheckedFields);
    }
  };

  const switchCF = (newTab: number, CF: CustomField) => {
    setActiveFieldTab(newTab);
    setCurrentCustomField(CF);
  };

  const notSaving = async () => {
    const companyCFs = company?.user_metadata_fields;
    //if change was a new field rm
    if (companyCFs.length !== customFields.length) {
      await deleteItem.mutateAsync();
    } else {
      setCustomFields(companyCFs);
    }
    setModalCondition({ open: false });

    if (modalCondition.action === "add") {
      addNewField();
    } else {
      const index: any = modalCondition.action;
      const cf = customFields[index];
      switchCF(index, cf);
    }
  };

  return (
    <>
      <NavigationBlocker enabled={!cfSaved} />

      <AdminPrimaryHeader
        isSearching={false}
        tooltipText=""
        showSortSelector={false}
        title={`${t("overview.settings.customFields")}`}
        setSearchTerm={() => {}}
        sortOrder="asc"
        setSortOrder={() => {}}
        showSearch={false}
      />
      <AdminSecondaryHeader>
        <Flex justifyContent="space-between" width="100%">
          <ButtonGroup alignItems="left" justifyContent="space-between">
            <Button
              variant="adminPrimary"
              size="xs"
              leftIcon={<PlusCircle />}
              onClick={() => {
                if (cfSaved) {
                  addNewField();
                } else {
                  setModalCondition({ open: true, action: "add" });
                }
              }}
            >
              {t("customFields.label.addNewField")}
            </Button>
            <Button
              variant="adminError"
              size="xs"
              onClick={() => {
                setDeleteModal({
                  isOpen: true,
                  selectedItem: currentCustomField,
                });
              }}
            >
              {t("global.actions.remove")}
            </Button>
          </ButtonGroup>
          <ButtonGroup alignItems="right">
            <Button
              variant="adminPrimary"
              size="xs"
              type="submit"
              onClick={onSave}
            >
              <Save marginRight={2} />
              {t("global.actions.save")}
            </Button>
          </ButtonGroup>
        </Flex>
      </AdminSecondaryHeader>
      <Flex direction={{ base: "column", sm: "row" }} justifyContent="start">
        <Flex
          direction="column"
          style={{
            top: "60px",
            position: "sticky",
            alignSelf: "flex-start",
            overflowY: "auto",
            height: "calc(100vh - 60px)",
          }}
        >
          <Box
            padding={6}
            backgroundColor="white"
            marginX={3}
            borderRadius="10px"
            height="85%"
            width="250px"
            overflow="auto"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          >
            <Text
              fontSize="md"
              lineHeight="1.2"
              fontWeight="600"
              marginBottom={3}
            >
              {t("customFields.label.userFields")}
            </Text>
            <InputGroup
              size="sm"
              backgroundColor="brand.backgroundGrey"
              borderRadius="5px"
            >
              <InputLeftElement>
                <Search color="brand.grey.60" />
              </InputLeftElement>

              <Input
                placeholder={`${t("global.forms.labels_search")}`}
                borderRadius="5px"
                color="brand.grey.100"
                border="none"
                _placeholder={{ color: "brand.grey.60" }}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <InputRightElement>
                <Tooltip
                  hasArrow
                  label={t("admin.connect.searchPlaceholder", {
                    type: "Custom Field",
                  })}
                >
                  <span>
                    <QuestionOutlineIcon color="brand.grey.50" />
                  </span>
                </Tooltip>
              </InputRightElement>
            </InputGroup>

            {customFields.map((item: CustomField, key) => (
              <Box
                borderBottom="1px solid"
                borderBottomColor="rgba(178, 178, 178, .9)"
                key={`item_${key + 1}`}
                background={
                  activeFieldTab === key ? hexToRGBA(brandHighlight, 0.1) : ""
                }
              >
                <Flex
                  paddingLeft={2}
                  _hover={{
                    cursor: "pointer",
                    background: hexToRGBA(brandHighlight, 0.07),
                  }}
                  paddingY={2}
                  flex={1}
                  flexGrow={1}
                  onClick={() => {
                    if (cfSaved) {
                      setActiveFieldTab(key);
                      setCurrentCustomField(item);
                    } else {
                      setModalCondition({ open: true, action: key });
                    }
                  }}
                >
                  <Flex flexDirection="column" width="full">
                    <Text fontWeight="600" fontSize="14px">
                      {item.name || t("customFields.label.newField")}
                    </Text>
                    <Text
                      fontSize="xs"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      {item.description}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Box>
        </Flex>
        {currentCustomField && (
          <SingleCustomFieldsEditor
            customField={currentCustomField}
            setCustomField={handleFieldChange}
          />
        )}
      </Flex>
      <Modal
        size="3xl"
        isOpen={modalCondition.open}
        onClose={() => setModalCondition({ open: false })}
      >
        <ModalOverlay
          onClick={() => () => setModalCondition({ open: false })}
        />
        <ModalContent>
          <ModalHeader display="flex" justifyContent="flex-end">
            <Close
              boxSize={4}
              onClick={() => setModalCondition({ open: false })}
              _hover={{ cursor: "pointer", color: "brand.primary" }}
            />
          </ModalHeader>
          <ModalBody>
            <VStack>
              <Text margin="2" as="h3">
                {t("customFields.modal.header")}
              </Text>

              <Text textAlign="center">
                {t("customFields.modal.bodyLine1")}
              </Text>
              <Text textAlign="center">
                {t("customFields.modal.bodyLine2")}
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent="center" marginBottom="3">
            <ButtonGroup alignItems="center">
              <Button
                variant="adminCancel"
                onClick={() => setModalCondition({ open: false })}
              >
                {t("customFields.modal.stay")}
              </Button>

              <Button variant="adminError" onClick={() => notSaving()}>
                {t("customFields.modal.leave")}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeleteItemModal deleteItem={deleteItem} />
    </>
  );
}
