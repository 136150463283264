import { ChatCompletion } from "@/client/types/OpenAI";
import { openAIClient } from "./clients/openAIClient";

const sendAiRequest = (request: any, respondWith: any) => {
  const openAiOptions = {
    model: "gpt-4o",
    temperature: 0.7,
    max_tokens: 800,
    messages: [{ role: "user", content: request.prompt }],
  };

  respondWith.string((signal: any) =>
    openAIClient
      .post(`v1/chat/completions`, {
        signal,
        openAiOptions,
      })
      .then(async (response) => {
        const data: ChatCompletion = await response.data;
        return data?.choices[0]?.message?.content.trim();
      })
      .catch((err) => {
        console.log("Error communicating with OpenAI api", err);
      })
  );
};

const OpenAiService = {
  sendAiRequest,
};

export default OpenAiService;
