import {
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface PublishModalProps {
  onPublish: () => void;
}

export default function PublishModal({ onPublish }: PublishModalProps) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { publishModalIsOpen, setPublishModalIsOpen, contentType } = useCreateStore();

  const isOpen = publishModalIsOpen;

  const onClose = () => setPublishModalIsOpen(false);

  const isUpdating = !!id;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />

      <ModalContent>
        <ModalBody paddingTop={12}>
          <VStack textAlign="center">
            <Image src="/public/images/readyToPublish.svg" width={120} height="auto" />

            <Text fontSize="20px" fontWeight={600} color="baseBlack">
              {isUpdating
                ? t('authoring.publishModal.updateHeader')
                : t('authoring.publishModal.publishHeader')}
            </Text>

            <Text fontSize="14px" fontWeight={500} color="neutral.1000">
              {isUpdating
                ? t('authoring.publishModal.updateHelpText')
                : contentType === 'product'
                ? t('authoring.product.publishHelpText')
                : t('authoring.publishModal.contentVisible')}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
          <ButtonGroup width="full" alignItems="center" justifyContent="center">
            <OutlineButton onClick={onClose}>{t('authoring.returnToEdit')}</OutlineButton>

            <SolidButton type="submit" onClick={onPublish}>
              {isUpdating
                ? t('authoring.publishModal.update')
                : t('authoring.publishModal.publish')}
            </SolidButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
