import { PersistOptions, persist } from "zustand/middleware";
import { create, StateCreator } from "zustand";

interface DashboardState {
  selectedTabIndex: number;
  setSelectedTabIndex: (index: number) => void;
}

type DashboardPersist = (
  config: StateCreator<DashboardState>,
  options: PersistOptions<DashboardState>
) => StateCreator<DashboardState>;

export const useDashboardStore = create<DashboardState>(
  (persist as DashboardPersist)(
    (set) => ({
      selectedTabIndex: 0,
      setSelectedTabIndex: (index: number) =>
        set(() => ({ selectedTabIndex: index })),
    }),
    { name: "dashboardStore" }
  )
);
