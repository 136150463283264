import {
  Center,
  Flex,
  Link,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Navigate, useParams, Link as RouterLink } from "react-router-dom";

import EmailLoginForm from "@/client/components/forms/authentication/EmailLoginForm";
import SSOLoginForm from "@/client/components/forms/authentication/SSOLoginForm";
import UIDLoginForm from "@/client/components/forms/authentication/UIDLoginForm";
import { useConfigStore } from "@/client/services/state/configStore";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useGetAlternativeLogins } from "../services/hooks/preauth/useGetAlternativeLogins";
import { useTranslation } from "react-i18next";
import { usePartnerStore } from "../services/state/partnerStore";
import { useEffect } from "react";
import { Partner } from "../types/Partner";

export default function Login() {
  useDocumentTitle("Sign In");
  const { config } = useConfigStore();
  const { t } = useTranslation();
  const { partner: partnerId, purchaseproductid: purchaseProductId } =
    useParams<{ partner?: string; purchaseproductid?: string }>();
  const { partner, setPartner } = usePartnerStore();
  const { isLoading, isError, data, error } =
    useGetAlternativeLogins(partnerId);

  useEffect(() => {
    setPartner(data?.partner || ({} as Partner));
  }, [data?.partner]);

  if (isLoading)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  if (isError)
    return (
      <div>
        Error: {error.message}: {error.response?.data?.message?.toString()}
      </div>
    );

  if (data.suspended) return <Navigate to="/suspended" replace />;

  if (data.partner && !partner._id) {
    return <div>waiting for partner to load</div>;
  }

  const ssoLogin = () => {
    let { link } = data;
    if (data.use_options) {
      link = "/saml/login";
    }

    if (config.signinUseNewWindow) {
      // Important to set child name as it is expected in the preauth-handler
      window.open(link, "childsso", "target=_blank");
    } else {
      window.location.href = link;
    }
  };

  const partnerLoginConfig = {
    checkForPartnerMatch: true,
    partner: partner?._id,
  };
  if (partner?._id && !partner.active) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        whiteSpace="nowrap"
        marginBottom={4}
      >
        <Text fontSize="sm" marginLeft={20} marginRight={20}>
          {t("preauth.signin.extendAccountInactive")}
        </Text>
      </Flex>
    );
  }

  return (
    <VStack>
      <Tabs isFitted>
        <Flex
          alignItems="center"
          justifyContent="center"
          whiteSpace="nowrap"
          marginBottom={4}
        >
          <Text fontSize="sm" marginRight={4}>
            {t("preauth.signin.signin_with")}
          </Text>

          <TabList _active={{ borderBottomColor: "transparent" }}>
            {data.status && data.link && (
              <Tab border="none">
                {t("preauth.signin.signin_with_sso_multiple")}
              </Tab>
            )}

            <Tab border="none">
              {t("preauth.signin.signin_with_email_multiple")}
            </Tab>

            {data.signin_userid && (
              <Tab border="none">
                {t("preauth.signin.signin_with_userid_multiple")}
              </Tab>
            )}
          </TabList>
        </Flex>

        <TabPanels>
          {data.status && data.link && (
            <TabPanel>
              <SSOLoginForm
                buttonCopy={data.sso_copy?.sso_button ?? "Sign in with SSO"}
                onLogin={ssoLogin}
              />
            </TabPanel>
          )}

          <TabPanel>
            <EmailLoginForm
              registerAllow={config.registerAllow}
              partnerLoginConfig={partnerLoginConfig}
              purchaseProductId={purchaseProductId}
            />
          </TabPanel>

          {data.signin_userid && (
            <TabPanel>
              <UIDLoginForm
                idPlaceholder={data.signin_userid_copy.field_name}
                registerButtonCopy={data.signin_userid_copy.register_button}
                idPattern={data.signin_userid_pattern}
                idLimit={data.signin_userid_limit}
                partnerLoginConfig={partnerLoginConfig}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      {config.features.enable_registration_forms && (
        <Link as={RouterLink} fontSize="xs" to="/registration">
          {t("preauth.signin.needToRegister")}
        </Link>
      )}
    </VStack>
  );
}
