import { Rect } from "./Rect";
import { Star } from "./Star";
import { Diamond } from "./Diamond";
import { EquilateralTriangle } from "./Triangle";
import { Circle } from "./Circle";
import { PointPickerFlatProps, PointPickerProps } from "@/client/types/Charts";

export function PointPickerFlat({
  size,
  color,
  borderWidth,
  borderColor,
  symbol,
}: PointPickerFlatProps) {
  if (symbol % 5 === 4) {
    return (
      <Rect
        {...{
          size,
          color,
          borderWidth,
          borderColor,
        }}
      />
    );
  }
  if (symbol % 5 === 3) {
    return (
      <Star
        {...{
          size,
          color,
          borderWidth,
          borderColor,
        }}
      />
    );
  }
  if (symbol % 5 === 2) {
    return (
      <EquilateralTriangle
        {...{
          size,
          color,
          borderWidth,
          borderColor,
        }}
      />
    );
  }
  if (symbol % 5 === 1) {
    return (
      <Diamond
        {...{
          size,
          color,
          borderWidth,
          borderColor,
        }}
      />
    );
  }
  return (
    <Circle
      {...{
        size,
        color,
        borderWidth,
        borderColor,
      }}
    />
  );
}

export function PointPicker({
  size,
  color,
  borderWidth,
  borderColor,
  datum,
}: PointPickerProps) {
  let symbol = 0;
  if (datum && datum.symbol) {
    symbol = datum.symbol;
  }
  return PointPickerFlat({ size, color, borderWidth, borderColor, symbol });
}
