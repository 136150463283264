import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import AcceptInvite from "./AcceptInvite";
import AdminConnectLayout from "../components/layout/admin/AdminConnectLayout";
import AdminContentAuthoringLayout from "@/client/components/admin/layout/AdminContentAuthoringLayout";
import AdminCourseLayout from "../components/admin/layout/route-wrappers/AdminCourseLayout";
import AdminGroupsLayout from "../components/layout/admin/AdminGroupsLayout";
import AdminProfilesLayout from "../components/layout/admin/AdminProfilesLayout";
import AdminReportsLayout from "../components/layout/admin/AdminReportsLayout";
import AdminUserAdd from "./admin/users/AdminUserAdd";
import AdminUserBulkAdd from "./admin/users/AdminUserBulkAdd";
import AdminUserOverview from "./admin/users/AdminUserOverview";
import AdminUsersLayout from "../components/admin/layout/route-wrappers/AdminUsersLayout";
import AdvancedReports from "./admin/reports/AdvancedReports";
import App from "../App";
import Article from "./content/Article";
import AssessmentView from "./content/assessments/AssessmentView";
import CancelSubscription from "./products/subscriptions/CancelSubscription";
import Categories from "./admin/connect/Categories";
import ChangeSubscription from "./products/subscriptions/ChangeSubscription";
import CompanyCustomFieldsEditor from "./CompanyCustomFields/CompanyCustomFieldsEditor";
import ContentFile from "./content/ContentFile";
import ContentWrapper from "../components/content/ContentWrapper";
import Dashboard from "./Dashboard";
import DedicatedWorkshops from "./DedicatedWorkshops";
import Departments from "./admin/connect/Departments";
import DesktopOnlyLayout from "../components/layout/admin/DesktopOnlyLayout";
import DeveloperTools from "./admin/developer-tools/DeveloperTools";
import EditArticle from "./admin/create/content/EditArticle";
import EditAssessment from "./admin/create/content/assessments/EditAssessment";
import EditCourse from "./admin/create/content/tracks/EditCourse";
import EditFile from "./admin/create/content/EditFile";
import EditScorm from "./admin/create/content/EditScorm";
import EditVideo from "./admin/create/content/EditVideo";
import ErrorScreen from "./ErrorScreen";
import Explore from "./Explore";
import FeedActivity from "./admin/users/sftp-feeds/FeedActivity";
import ForgotPassword from "./ForgotPassword";
import Grades from "./admin/connect/Grades";
import GroupForm from "./admin/connect/groups/GroupForm";
import GroupUsers from "./admin/connect/groups/GroupUsers";
import Groups from "./admin/connect/groups/Groups";
import IconGallery from "./IconGallery";
import ImportedContent from "./content/ImportedContent";
import InactiveRegistrationFormPage from "./InactiveRegistrationFormPage";
import Journey from "./content/journeys/Journey";
import LearningTrack from "./content/learning-tracks/LearningTrack";
import LegacyExploreRoute from "./LegacyExploreRoute";
import Levels from "./admin/connect/Levels";
import Locations from "./admin/connect/Locations";
import LoggedInLayout from "../components/layout/LoggedInLayout";
import LoggedOutFullScreenLayout from "../components/layout/LoggedOutFullScreenLayout";
import LoggedOutLayout from "../components/layout/LoggedOutLayout";
import Login from "./Login";
import MeetsRedirect from "./MeetsRedirect";
import MultiSessionWorkshop from "./content/MultiSessionWorkshop";
import OauthTransition from "./OauthTransition";
import PartnerManagerAssignmentsList from "./partners/PartnerManagerAssignmentsList";
import PartnerManagerContentActivity from "./partners/PartnerManagerContentActivity";
import PartnerManagerContentAssign from "./partners/PartnersManagerContentAssign";
import PartnerManagerContentList from "./partners/PartnerManagerContentList";
import PartnerUsersList from "./partners/PartnerUsersList";
import PartnersEditor from "./partners/PartnersEditor";
import PartnersFeatureWrapper from "./partners/PartnersFeatureWrapper";
import PartnersList from "./partners/PartnersList";
import PartnersManager from "./partners/PartnersManager";
import PartnersPrincipalWrapper from "./partners/PartnersPrincipalWrapper";
import PaymentMethodUpdateSuccess from "./content/ecommerce/PaymentMethodUpdateSuccess";
import PaymentSetupSuccess from "./content/ecommerce/PaymentSetupSuccess";
import PaymentWrapper from "./content/ecommerce/PaymentWrapper";
import PaymentsList from "./products/paymentsList";
import People from "./People";
import Product from "./content/ecommerce/Product";
import ProductEditor from "./admin/content-creation/ProductEditor";
import ProductLandingPage from "./registration-forms/ProductLandingPage";
import ProductOverview from "./products/ProductOverview";
import Profile from "./Profile";
import ProfileForm from "./admin/profiles/forms/ProfileForm";
import ProfileSettings from "./ProfileSettings";
import Profiles from "./admin/profiles/Profiles";
import Qooper from "./Qooper";
import Register from "./Register";
import RegisterAskForCode from "./RegisterAskForCode";
import RegisterWithCode from "./RegisterWithCode";
import RegisterWithUserId from "./RegisterWithUserId";
import RegistrationFormsEditor from "./registration-forms/RegistrationFormsEditor";
import RegistrationFormsTable from "./registration-forms/RegistrationFormsTable";
import Reports from "./admin/reports/Reports";
import ReportsForm from "./admin/reports/ReportsForm";
import ResetPassword from "./ResetPassword";
import SamlTransition from "./SamlTransition";
import Scorm from "./content/Scorm";
import SftpFeeds from "./admin/users/sftp-feeds/SftpFeeds";
import SiteRoot from "./SiteRoot";
import Skills from "./admin/connect/skills/Skills";
import StripeSetupSuccess from "./content/ecommerce/StripeSetupSuccess";
import SubscriptionManagement from "./products/subscriptions/SubscriptionManagement";
import Suspended from "./Suspended";
import Teams from "./admin/connect/Teams";
import TeamsRedirect from "./Teams-Redirect";
import Theme from "./admin/theme/Theme";
import TrackStart from "./admin/create/content/tracks/TrackStart";
import UserManagement from "./UserManagement";
import UserSeats from "./admin/users/UserSeats";
import Video from "./content/Video";
import WaitForInvite from "./WaitForInvite";
import Workshop from "./content/Workshop";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorScreen />} element={<App />}>
      <Route path="" element={<SiteRoot />} />
      <Route path="suspended" element={<Suspended />} />
      <Route path="saml-transition" element={<SamlTransition />} />
      <Route path="wait-for-invite" element={<WaitForInvite />} />
      <Route path="page-inactive" element={<InactiveRegistrationFormPage />} />
      <Route path="oauth-transition" element={<OauthTransition />} />

      <Route element={<LoggedOutLayout />}>
        <Route path="login/extend/:partner" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot" element={<ForgotPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="register-with-userid" element={<RegisterWithUserId />} />
        <Route path="registration/" element={<RegisterAskForCode />} />
        <Route path="registration/:code" element={<RegisterWithCode />} />
        <Route path="accept" element={<AcceptInvite />} />
        <Route path="reset" element={<ResetPassword />} />
      </Route>

      <Route element={<LoggedOutFullScreenLayout />}>
        <Route
          path="product/registration/:code"
          element={<RegisterWithCode />}
        />
      </Route>

      <Route element={<LoggedInLayout />}>
        <Route path="explore/:categoryId" element={<LegacyExploreRoute />} />
        <Route path="explore" element={<Explore />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="overview/products" element={<ProductOverview />} />
        <Route path="overview/products/:tab" element={<ProductOverview />} />
        <Route path="mentor" element={<Qooper />} />
        <Route path="people" element={<People />} />
        <Route path="workshops" element={<DedicatedWorkshops />} />
        <Route path="profile/:id" element={<Profile />} />
        <Route path="settings" element={<ProfileSettings />} />
        <Route path="meets-redirect" element={<MeetsRedirect />} />
        <Route path="teams-redirect" element={<TeamsRedirect />} />
        <Route path="stripe/success" element={<StripeSetupSuccess />} />
        <Route
          path="product/:id/payment-method"
          element={<PaymentWrapper context="create" />}
        />
        <Route
          path="product/:id/payment-setup-success"
          element={<PaymentSetupSuccess />}
        />
        <Route
          path="manage-subscription/:id"
          element={<SubscriptionManagement />}
        />
        <Route
          path="change-subscription/:id"
          element={<ChangeSubscription />}
        />
        <Route path="payment-history" element={<PaymentsList />} />;
        <Route
          path="subscription/:subscriptionId/update-payment"
          element={<PaymentWrapper context="update-existing" />}
        />
        <Route
          path="subscription/:subscriptionId/add-new-payment/:newProductId"
          element={<PaymentWrapper context="update-new" />}
        />
        <Route
          path="subscription/:id/payment-update-success"
          element={<PaymentMethodUpdateSuccess />}
        />
        <Route
          path="change-subscription/:id/product/:newProductId"
          element={<ChangeSubscription />}
        />
        <Route
          path="cancel-subscription/:subscriptionId"
          element={<CancelSubscription />}
        />
        <Route element={<DesktopOnlyLayout />}>
          <Route element={<PartnersPrincipalWrapper />}>
            <Route path="extend/manager" element={<PartnersManager />} />
            <Route
              path="extend/manager/content"
              element={<PartnerManagerContentList />}
            />
            <Route path="extend/manager/users" element={<PartnerUsersList />} />
            <Route
              path="extend/manager/users/create"
              element={<UserManagement />}
            />
            <Route
              path="extend/manager/users/:id"
              element={<UserManagement />}
            />
            <Route
              path="extend/manager/assignments"
              element={<PartnerManagerAssignmentsList />}
            />
            <Route
              path="extend/manager/content/:contentType/:id/assign"
              element={<PartnerManagerContentAssign />}
            />
            <Route
              path="extend/manager/content/:contentType/:id/activity"
              element={<PartnerManagerContentActivity />}
            />
          </Route>
          <Route element={<PartnersFeatureWrapper />}>
            <Route path="admin/extend" element={<PartnersList />} />
            <Route path="admin/extend/create" element={<PartnersEditor />} />
            <Route path="admin/extend/edit/:id" element={<PartnersEditor />} />
          </Route>

          <Route element={<AdminConnectLayout />}>
            <Route path="admin/connect/categories" element={<Categories />} />
            <Route path="admin/connect/locations" element={<Locations />} />
            <Route path="admin/connect/departments" element={<Departments />} />
            <Route path="admin/connect/teams" element={<Teams />} />
            <Route path="admin/connect/levels" element={<Levels />} />
            <Route path="admin/connect/grades" element={<Grades />} />
            <Route path="admin/connect/skills" element={<Skills />} />
          </Route>

          <Route path="admin/edit/products" element={<ProductEditor />} />
          <Route
            path="admin/edit/products/:productId"
            element={<ProductEditor />}
          />

          <Route path="admin/theme" element={<Theme />} />

          <Route element={<AdminGroupsLayout />}>
            <Route path="admin/connect/groups" element={<Groups />} />
            <Route path="admin/connect/groups/create" element={<GroupForm />} />
            <Route
              path="admin/connect/groups/:groupId/edit"
              element={<GroupForm />}
            />
            <Route
              path="admin/connect/groups/:groupId/users"
              element={<GroupUsers />}
            />
          </Route>

          <Route element={<AdminReportsLayout />}>
            <Route path="admin/reports" element={<Reports />} />
            <Route path="admin/reports/create" element={<ReportsForm />} />
            <Route
              path="admin/reports/advanced"
              element={<AdvancedReports />}
            />
            <Route path="admin/reports/:reportId" element={<ReportsForm />} />
          </Route>

          <Route element={<AdminProfilesLayout />}>
            <Route path="admin/profiles" element={<Profiles />} />
            <Route path="admin/profiles/create" element={<ProfileForm />} />
            <Route path="admin/profiles/:profileId" element={<ProfileForm />} />
          </Route>

          <Route
            path="admin/connect/registration-forms"
            element={<RegistrationFormsTable />}
          />
          <Route
            path="admin/connect/registration-forms/create"
            element={<RegistrationFormsEditor />}
          />
          <Route
            path="admin/connect/registration-forms/:id"
            element={<RegistrationFormsEditor />}
          />

          <Route
            path="admin/products/landing-page/create"
            element={<ProductLandingPage />}
          />
          <Route
            path="admin/products/landing-page/:id"
            element={<ProductLandingPage />}
          />

          <Route
            path="admin/users/custom-fields"
            element={<CompanyCustomFieldsEditor />}
          />
          <Route path="user-management" element={<UserManagement />} />
          <Route path="user-management/:id" element={<UserManagement />} />
          <Route path="admin/developer-tools" element={<DeveloperTools />} />
          <Route
            path="admin/developer-tools/:tab"
            element={<DeveloperTools />}
          />

          <Route element={<AdminContentAuthoringLayout />}>
            <Route path="admin/edit/article" element={<EditArticle />} />
            <Route path="admin/edit/article/:id" element={<EditArticle />} />

            <Route path="admin/edit/scorm" element={<EditScorm />} />
            <Route path="admin/edit/scorm/:id" element={<EditScorm />} />

            <Route path="admin/edit/file" element={<EditFile />} />
            <Route path="admin/edit/file/:id" element={<EditFile />} />

            <Route path="admin/edit/video" element={<EditVideo />} />
            <Route path="admin/edit/video/:id" element={<EditVideo />} />

            <Route
              path="admin/edit/assessment/:id?"
              element={<EditAssessment />}
            />
          </Route>

          <Route path="admin/users" element={<AdminUsersLayout />}>
            <Route index element={<AdminUserOverview />} />
            <Route path="add" element={<AdminUserAdd />} />
            <Route
              path="add/single"
              element={
                <UserManagement
                  showAdminPrimaryHeader={false}
                  renderUserDataTabs={false}
                />
              }
            />
            <Route
              path="edit/single/:id"
              element={<UserManagement showAdminPrimaryHeader={false} />}
            />
            <Route path="add/bulk" element={<AdminUserBulkAdd />} />
            <Route path="seats" element={<UserSeats />} />
            <Route path="feeds" element={<SftpFeeds />} />
            <Route path="feeds/:id" element={<FeedActivity />} />
            <Route path="suspended/:id" element={<UserManagement />} />
          </Route>

          <Route path="admin/edit/course/:id?" element={<AdminCourseLayout />}>
            <Route index element={<TrackStart />} />

            <Route path="udemy" element={<EditCourse />} />
            <Route path="udemy/:id" element={<EditCourse />} />
            <Route path="linkedin" element={<EditCourse />} />
            <Route path="linkedin/:id" element={<EditCourse />} />
            <Route path="masterclass" element={<EditCourse />} />
            <Route path="masterclass/:id" element={<EditCourse />} />
            <Route path="track" element={<EditCourse />} />
            <Route path="track/:id" element={<EditCourse />} />
          </Route>
        </Route>
        <Route path="3229" element={<IconGallery />} />
        <Route
          path="article/:id"
          element={<ContentWrapper content={Article} contentType="article" />}
        />
        <Route
          path="file/:id"
          element={<ContentWrapper content={ContentFile} contentType="file" />}
        />
        <Route
          path="video/:id"
          element={<ContentWrapper content={Video} contentType="video" />}
        />
        <Route
          path="scorm/:id"
          element={<ContentWrapper content={Scorm} contentType="scorm" />}
        />
        <Route path="workshop/:id" element={<Workshop />} />
        <Route
          path="multi-session-workshop/:id"
          element={<MultiSessionWorkshop />}
        />
        <Route
          path="track/:learningTrackId"
          element={
            <ContentWrapper content={LearningTrack} contentType="track" />
          }
        >
          <Route
            path=":contentType/:id"
            element={
              <ContentWrapper content={LearningTrack} contentType="track" />
            }
          />
        </Route>
        <Route path="imported-content/:id" element={<ImportedContent />} />
        <Route path="journey/:journeyId" element={<Journey />} />
        <Route
          path="assessment/:id"
          element={
            <ContentWrapper content={AssessmentView} contentType="assessment" />
          }
        />
        <Route path="product/:id" element={<Product />} />
      </Route>
    </Route>
  )
);
