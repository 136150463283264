/* eslint-disable import/extensions */
import { Feed } from "@/client/types/admin/user-manager/Feed";
import { FeedActivity } from "@/client/types/admin/user-manager/FeedActivity";
import { learnApiClient } from "@/client/services/api/clients/learnApiClient";

const getFeeds = async (options: {
  pageIndex: number;
  pageSize: number;
}): Promise<{ rows: Feed[]; pageCount: number }> => {
  const response = await learnApiClient.get(
    `feeds/registrations?page=${options.pageIndex}&per_page=${options.pageSize}`
  );

  return {
    rows: response.data.records,
    pageCount: Math.floor(response.data.count / options.pageSize) + 1,
  };
};

const getFeedById = async (id: string | undefined): Promise<Feed> => {
  const response = await learnApiClient.get(`feeds/registrations/${id}`);

  return response.data;
};

const getFeedActivity = async (
  id: string | undefined,
  options: {
    pageIndex: number;
    pageSize: number;
  }
): Promise<{ rows: FeedActivity[]; pageCount: number }> => {
  const response = await learnApiClient.get(
    `feeds/registrations/${id}/activity?page=${options.pageIndex}&per_page=${options.pageSize}`
  );

  return {
    rows: response.data.records,
    pageCount: Math.floor(response.data.count / options.pageSize) + 1,
  };
};

const AdminFeedsService = {
  getFeeds,
  getFeedById,
  getFeedActivity,
};

export default AdminFeedsService;
