import { Flex, Stack, Switch, Text } from '@chakra-ui/react';
import type { DefaultTFuncReturn } from 'i18next';
import { Controller, useFormContext } from 'react-hook-form';

interface CourseSwitchContainerProps {
  name: string;
  label?: string | DefaultTFuncReturn;
  onHandleChange: (isChecked: boolean) => void;
  isChecked: boolean;
}

export default function CourseSwitchContainer({
  name,
  label = null,
  onHandleChange,
  isChecked,
}: CourseSwitchContainerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Flex alignItems="center" justifyContent="space-between">
          <Stack spacing={0.5}>{label && <Text variant="createLabel">{label}</Text>}</Stack>

          <Switch
            {...field}
            variant="create"
            isChecked={isChecked}
            onChange={(e) => {
              onHandleChange(e.target.checked);
            }}
          />
        </Flex>
      )}
    />
  );
}
