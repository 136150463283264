/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import CircleStat from "@/client/components/data-display/stats/CircleStat";
import FormLabelWithTooltip from "@/client/components/admin/forms/FormLabelWithTooltip";
import { useAuthStore } from "@/client/services/state/authStore";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

interface UserSeatFormValues {
  seatCount: string;
}

export default function UserOverviewStatGroup() {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formRef = useRef<HTMLFormElement>(null);

  const methods = useForm<UserSeatFormValues>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<UserSeatFormValues> = () =>
    formRef.current?.submit();

  const seatsQuery = useQuery({
    queryKey: ["user-seats"],
    queryFn: () => AdminUsersService.getUserSeats(),
    select: (data) => ({
      stats: [
        {
          value: data.purchased,
          label: t("userLimits.purchasedSeats"),
          subLabel: "",
        },
        {
          value: data.used,
          label: t("userLimits.usedSeats"),
          subLabel: "",
        },
        {
          value: data.purchased - data.used,
          label: t("userLimits.availableSeats"),
          subLabel: "",
        },
      ],
    }),
  });

  const statsQuery = useQuery({
    queryKey: ["user-overview-stats"],
    queryFn: () => AdminUsersService.getUserOverviewStats(),
    select: (statData) => ({
      stats: [
        {
          value: statData.lastLoginThisMonth,
          label: t("admin.users.overview.stats.mau"),
          subLabel: t("admin.users.overview.stats.mau.subLabel"),
        },
        {
          value: statData.pendingUsers,
          label: t("admin.users.overview.stats.invitations"),
          subLabel: t("admin.users.overview.stats.invitations.subLabel"),
        },
        {
          value: statData.externalUsers,
          label: t("admin.users.overview.stats.external"),
        },
        {
          value: statData.adminUsers,
          label: t("admin.users.overview.stats.admins"),
        },
        {
          value: statData.creatorUsers,
          label: t("admin.users.overview.stats.creators"),
        },
        {
          value: statData.suspendedUsers,
          label: t("admin.users.overview.stats.suspended"),
        },
      ],
    }),
  });

  if (
    statsQuery.isLoading ||
    seatsQuery.isLoading ||
    statsQuery.isError ||
    seatsQuery.isError
  )
    return null;

  return (
    <AdminElevatedBox>
      <Flex justifyContent="space-between">
        <FormLabelWithTooltip
          label={t("admin.users.overview.stats.userActivity")}
        />

        <Button variant="adminPrimary" size="xs" onClick={onOpen}>
          {t("userLimits.contactUs")}
        </Button>
      </Flex>

      <Grid templateColumns="repeat(4, 1fr)" gap={4} marginTop={12}>
        {[...statsQuery.data.stats, ...seatsQuery.data.stats].map((stat) => (
          <GridItem key={stat.label}>
            <HStack>
              <CircleStat
                count={stat.value}
                color="brand.primary"
                fontWeight="medium"
                borderWidth="4px"
              />

              <Stack spacing={0.5}>
                <Text fontWeight="medium">{stat.label}</Text>

                {stat.subLabel && <Text fontSize="sm">{stat.subLabel}</Text>}
              </Stack>
            </HStack>
          </GridItem>
        ))}
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody paddingY={12}>
            <FormProvider {...methods}>
              <form
                ref={formRef}
                action="https://continu.us7.list-manage.com/subscribe/post?u=975cd5d2c1217d1b9b0849bdc&amp;id=336246cd6d"
                method="POST"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <VStack spacing={6}>
                  <Text fontWeight="semibold">{t("userLimits.howMany")}</Text>

                  <Input
                    display="none"
                    type="email"
                    value={user.email}
                    name="EMAIL"
                    id="mce-EMAIL"
                    isReadOnly
                  />

                  <Controller
                    name="seatCount"
                    control={methods.control}
                    rules={{
                      required: {
                        value: true,
                        message: t("global.forms.thisFieldRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        variant="adminInput"
                        type="number"
                        placeholder="100"
                        name="SEATS"
                        id="mce-SEATS"
                      />
                    )}
                  />

                  <Button
                    type="submit"
                    variant="adminPrimary"
                    size="sm"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    isDisabled={!methods.formState.isValid}
                  >
                    {t("userLimits.submitRequest")}
                  </Button>
                </VStack>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </AdminElevatedBox>
  );
}
