/* eslint-disable react/jsx-props-no-spreading */

import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import AdminElevatedBox from "../components/admin/layout/AdminElevatedBox";
import AdminPrimaryHeader from "@/client/components/admin/layout/AdminPrimaryHeader";
import SuspendedUserProfile from "./admin/users/SuspendedUserProfile";
import User from "@/client/services/api/User";
import UserAssignedContent from "../components/admin/user-management/UserAssignedContent";
import UserBadges from "@/client/components/admin/user-management/UserBadges";
import UserGeneralDetailsForm from "@/client/components/admin/user-management/UserGeneralDetailsForm";
import UserLearningTracks from "@/client/components/admin/user-management/UserLearningTracks";
import UserViewedContent from "@/client/components/admin/user-management/UserViewedContent";
import UserWorkshops from "@/client/components/admin/user-management/UserWorkshops";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useGetAdminUsersContentStats } from "../services/hooks/admin/users/useGetAdminUsersContentStats";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface UserManagementProps {
  showAdminPrimaryHeader?: boolean;
  renderUserDataTabs?: boolean;
}

export default function UserManagement({
  showAdminPrimaryHeader,
  renderUserDataTabs,
}: UserManagementProps) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { partner } = usePartnerStore();
  const location = useLocation();
  const { isAllowed } = useAccessHelper();
  const isSuspended = location.pathname.includes("suspended");

  const allowance = {
    add: isAllowed(["admin", "users", "add"], null, ["admin"]),
    edit: isAllowed(["admin", "users", "edit"], null, ["admin"]),
    view: isAllowed(["admin", "users", "view"], null, ["admin"]),
    view_user_activity: isAllowed(
      ["admin", "users", "view_user_activity"],
      null,
      ["admin"]
    ),
  };

  const showUserActivityTabs = id && allowance.view_user_activity;

  if (partner._id) {
    useDocumentTitle(t("partners.manager.manageUserTitle"));
  } else {
    useDocumentTitle(t("users.manageUserTitle"));
  }

  const { data: userContentStatsData } = useGetAdminUsersContentStats(id);

  const { data } = useQuery({
    enabled: !!id,
    queryKey: ["user-details", id],
    queryFn: () => User.getUserForEditor(id),
  });

  return (
    <>
      {showAdminPrimaryHeader && (
        <AdminPrimaryHeader
          title={`${t("overviewUsers.userManagement")}`}
          tooltipText=""
          showSearch={false}
          setSearchTerm={() => {}}
          setSortOrder={() => {}}
        />
      )}

      <Tabs isLazy variant="admin">
        {renderUserDataTabs && (
          <AdminElevatedBox>
            <Stack spacing={4}>
              {data?.full_name && (
                <Text fontWeight={500}>{`Edit User: ${data.full_name}`}</Text>
              )}

              <TabList>
                <Tab>{t("manage.users.tab_general")}</Tab>

                {showUserActivityTabs && (
                  <>
                    <Tab>{t("manage.users.tab_assignedContent")}</Tab>
                    <Tab>{t("manage.users.tab_viewedContent")}</Tab>
                    <Tab>{t("manage.users.tab_learningTracks")}</Tab>
                    <Tab>{t("manage.users.tab_workshops")}</Tab>
                    <Tab>{t("manage.users.tab_badges")}</Tab>
                  </>
                )}
              </TabList>
            </Stack>
          </AdminElevatedBox>
        )}

        <TabPanels>
          <TabPanel padding={0}>
            {isSuspended ? (
              <SuspendedUserProfile id={id} />
            ) : allowance.edit ? (
              <UserGeneralDetailsForm userData={data} />
            ) : (
              <UserViewedContent />
            )}
          </TabPanel>

          <TabPanel padding={0}>
            <UserAssignedContent
              totalAssignmentCount={userContentStatsData?.assignments}
            />
          </TabPanel>

          <TabPanel padding={0}>
            <UserViewedContent />
          </TabPanel>

          <TabPanel padding={0}>
            <UserLearningTracks
              totalLearningTracks={userContentStatsData?.learningTracks}
            />
          </TabPanel>

          <TabPanel padding={0}>
            <UserWorkshops />
          </TabPanel>

          <TabPanel padding={0}>
            <UserBadges totalBadgeCount={userContentStatsData?.awardedBadges} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

UserManagement.defaultProps = {
  showAdminPrimaryHeader: true,
  renderUserDataTabs: true,
};
