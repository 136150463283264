import { Box, Flex } from '@chakra-ui/react';

import CircleStat from './CircleStat';
import CircleStatLabel from './CircleStatLabel';
import { useTranslation } from 'react-i18next';

type StatCounterProps = {
  count: number;
  label: string;
};

const StatCounter = ({ count, label }: StatCounterProps) => (
  <Flex direction="column" justifyContent="center" alignItems="center">
    <CircleStat count={count} color="brand.primary" fontWeight="medium" borderWidth="2px" />
    <CircleStatLabel label={label} position="center" color="#1A1A1A" fontWeight="medium" />
  </Flex>
);

type CircleStatData = {
  label: string; // translation key
  value: number;
};

type CircleStatSectionProps = {
  stats: CircleStatData[];
};

export default function CircleStatSection({ stats }: CircleStatSectionProps) {
  const { t } = useTranslation();

  return (
    <Box
      padding={6}
      paddingTop={8}
      paddingLeft={8}
      backgroundColor="white"
      marginRight={6}
      marginLeft={{ base: 6, lg: 0 }}
      borderRadius="5px"
      width="auto"
      height="fit-content"
      display={{ base: 'none', lg: 'block' }}
    >
      <Flex direction="row" justifyContent="space-evenly">
        {stats.length > 0 &&
          stats.map((stat) => <StatCounter count={stat.value} label={t(stat.label)} />)}
      </Flex>
    </Box>
  );
}
