import CreateTabs from "../../create/shared/tabs/CreateTabs";
import EditProductForm from "./EditProductForm";
import EditRelatedProductsForm from "./EditRelatedProductsForm";
import TabControls from "../../create/shared/tabs/TabControls";
import { useCreateNavigationBlocker } from "@/client/services/hooks/admin/authoring/useCreateNavigationBlocker";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ProductFormTabWrapper({
  handlePublish,
}: {
  handlePublish: () => void;
}) {
  useCreateNavigationBlocker();
  const { t } = useTranslation();
  const { watch, formState } = useFormContext();
  const { isValid } = formState;
  const {
    setCanAdvance,
    tabIndex,
    setTabIndex,
    setNoImageModalIsOpen,
    setContentType,
    setContentTitle,
  } = useCreateStore();

  useEffect(() => {
    setContentType("product");
  }, []);

  const description = watch("description");
  const title = watch("title");
  const images = watch("images");
  const subscriptionDuration = watch("subscriptionDuration");
  const price = watch("price");
  const productIsDefault = watch("default");

  useEffect(() => {
    setContentTitle(title as string);
  }, [title]);

  const canAdvance =
    description !== "" &&
    title !== "" &&
    ((!productIsDefault &&
      subscriptionDuration !== "" &&
      subscriptionDuration !== null &&
      price !== 0) ||
      productIsDefault);

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  const tabOptions = [
    {
      label: t("global.contentTypes.product"),
      content: <EditProductForm />,
      isDisabled: false,
    },
    {
      label: t("products.edit.settings"),
      content: <EditRelatedProductsForm />,
      isDisabled: !isValid,
    },
  ];

  const handleProceed = () => {
    if (tabIndex === 0 && !images.length) {
      setNoImageModalIsOpen(true);
      return;
    }

    setTabIndex(tabIndex + 1);
  };

  return (
    <CreateTabs
      tabOptions={tabOptions}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      controls={
        <TabControls
          tabOptionsLength={tabOptions.length}
          onProceed={handleProceed}
          canPublish={formState.isValid}
          handlePublish={handlePublish}
          discardRoute="/overview/products"
        />
      }
    />
  );
}
