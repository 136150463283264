import { Button, Stack } from "@chakra-ui/react";

import Certificate from "@/client/components/data-display/certificates/Certificate";
import CreateFormGroup from "@/client/components/admin/create/shared/layout/CreateFormGroup";
import CreateSwitchContainer from "@/client/components/admin/create/shared/layout/CreateSwitchContainer";
import { useAuthStore } from "@/client/services/state/authStore";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentCreateInput from "../../input/ContentCreateInput";
import CourseSwitchContainer from "../../input/CourseSwitchContainer";

export default function ContentCertificateForm() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { watch, setValue } = useFormContext();
  const [viewingCertificate, setViewingCertificate] = useState(false);

  const certificateEnabled = watch("certificate");
  const contentTitle = watch("title");
  const certificateData = watch('certificateData');
  const altTitleIndex = certificateData?.findIndex(
    (data: { name: string; value: string }) => data.name === 'alt_title',
  );
  const altTitleValue =
    certificateData?.find((data: { name: string; value: string }) => data.name === 'alt_title')
      ?.value || '';
  const hideDateValue =
    certificateData?.find(
      (data: { name: string; value: string }) => data.name === 'hide_completion',
    )?.value || '';

  useEffect(() => {
    if (!certificateData || certificateData.length === 0) {
      setValue('certificateData', [
        {
          name: 'alt_title',
          value: '',
        },
        {
          name: 'hide_completion',
          value: '',
        },
      ]);
    } else if (certificateData.length === 1 && certificateData[0].name === 'alt_title') {
      setValue('certificateData', [
        ...certificateData,
        {
          name: 'hide_completion',
          value: '',
        },
      ]);
    } else if (certificateData.length === 1 && certificateData[0].name === 'hide_completion') {
      setValue('certificateData', [
        ...certificateData,
        {
          name: 'alt_title',
          value: '',
        },
      ]);
    }
  }, []);

  const handleAltTitleChange = (value: string) => {
    const updatedCertificateData = certificateData?.map((data: { name: string; value: string }) =>
      data.name === 'alt_title' ? { ...data, value } : data,
    );
    setValue('certificateData', updatedCertificateData);
  };

  const handleSwitchChange = (isChecked: boolean) => {
    const updatedCertificateData = [
      {
        name: 'alt_title',
        value: altTitleValue,
      },
      {
        name: 'hide_completion',
        value: isChecked ? 'true' : 'false',
      },
    ];
    setValue('certificateData', updatedCertificateData);
  };

  const userFullName = user.full_name;
  const completedDate = new Date();

  return (
    <CreateFormGroup>
      <Stack spacing={6}>
        <CreateSwitchContainer
          name="certificate"
          label={t("authoring.certificate.enableCertificate")}
          helpText={t("authoring.certificate.enableCertificate.helpText")}
        />

        {certificateEnabled && (
          <Stack spacing={6}>
            <CourseSwitchContainer
              name="certificateData"
              label={t('authoring.certificate.hideCompletionDate')}
              onHandleChange={handleSwitchChange}
              isChecked={hideDateValue === 'true'}
            />

            <Stack spacing={0}>
              <ContentCreateInput
                name={`certificateData.${altTitleIndex}.value`}
                placeholder={t('authoring.certificate.altTitlePlaceholder')}
                onHandleChange={handleAltTitleChange}
              />

              <Button
                variant="ghost"
                textAlign="left"
                justifyContent="start"
                padding={0}
                margin={0}
                color="brand.gold.100"
                _hover={{
                  textDecoration: "underline",
                  color: "brand.gold.100",
                }}
                onClick={() => setViewingCertificate(true)}
              >
                {t("authoring.certificate.preview")}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>

      {viewingCertificate && (
        <Certificate
          contentTitle={altTitleValue || contentTitle}
          userFullName={userFullName}
          completedDate={completedDate}
          userMetadata={user.metadata}
          contentMetadata={{}}
          certificateData={[{ name: 'hide_completion', value: hideDateValue.toString() }]}
          onClose={() => setViewingCertificate(false)}
        />
      )}
    </CreateFormGroup>
  );
}
