import { Container } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ContentContainerProps {
  children: ReactNode;
  inJourney?: boolean;
  inArticle?: boolean;
}

export default function ContentContainer({
  children,
  inJourney,
  inArticle,
}: ContentContainerProps) {
  const setTopMargin = () => {
    let margin = "120px";
    if (inJourney) margin = "0px";
    if (inArticle) margin = "0px";
    return margin;
  };
  return (
    <Container marginY={12} marginTop={setTopMargin()} maxWidth="container.md">
      {children}
    </Container>
  );
}

ContentContainer.defaultProps = {
  inJourney: false,
  inArticle: false,
};
