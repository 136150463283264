import {
  type Product,
  ProductSubscriptionDuration,
} from '@/client/services/api/graphql/gql/graphql';
import { Box, Container, HStack, Stack, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import AuthoringBannerUpload from '@/client/components/admin/create/content/images/AuthoringBannerUpload';
import { useComponentSize } from 'react-use-size';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ProductComponent from '@/client/routes/content/ecommerce/Product';

export default function ProductAuthoringLivePreview() {
  const { t } = useTranslation();
  const { previewFullscreen } = useCreateStore();
  const { watch, getValues } = useFormContext<Product>();

  const titlePlaceholder = t('authoring.product.titlePlaceholder');

  const { ref: previewRef, width } = useComponentSize();

  const heroHeight = useMemo(() => (width ? width / 2.5 : 0), [width]);

  const title = watch('title');
  const description = watch('description') || '';

  // products
  const price = watch('price');
  const subscriptionDuration = watch('subscriptionDuration');

  return previewFullscreen ? (
    <ProductComponent isPreview previewData={getValues()} />
  ) : (
    <Box
      width="100%"
      height="100%"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="neutral.100"
      borderRadius="6px"
      backgroundImage="url(/public/images/product-content-preview.png)"
    >
      <Box
        ref={previewRef}
        height="24em"
        width="17em"
        backgroundColor="white"
        margin="auto"
        borderRadius="xl"
        position="relative"
        top="20%"
        overflowY="hidden"
        boxShadow="0px 11.15px 18.583px 0px rgba(20, 27, 45, 0.10)"
        paddingTop={6}
      >
        <Box paddingX={6} borderRadius="40%">
          <AuthoringBannerUpload contentType="product" heroHeight={heroHeight} />
        </Box>

        <Container maxWidth="container.md" marginTop={4}>
          <Stack paddingLeft={3} spacing={0}>
            <Text
              lineHeight="120%"
              fontSize="xl"
              fontWeight={800}
              color={title ? 'baseBlack' : 'neutral.600'}
              marginBottom={4}
              noOfLines={2}
            >
              {title || titlePlaceholder}
            </Text>

            <Box marginRight="auto">
              <HStack
                border="1px"
                borderColor="neutral.200"
                borderRadius="8px"
                backgroundColor="neutral.50"
                padding={2}
                marginLeft={0}
                fontSize="xs"
              >
                <Text fontWeight={500}>{`$${Math.round(price)}`}</Text>
                <Text>{` / ${t('products.subscriptionDurationPreview.billed')} ${
                  subscriptionDuration === ProductSubscriptionDuration.Monthly
                    ? t('products.subscriptionDurationOptions.monthly')
                    : t('products.subscriptionDurationOptions.yearly')
                }`}</Text>
              </HStack>
            </Box>
          </Stack>
        </Container>

        <Box
          margin="0 auto"
          marginTop={4}
          maxWidth={previewFullscreen ? '700px' : '80%'}
          overflow="hidden"
          maxHeight="8em"
        >
          <Text fontSize="xs" noOfLines={5}>
            {description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
