/* eslint react/no-unstable-nested-components: 0 */

import { Box, Button, ButtonGroup, Checkbox, Flex } from "@chakra-ui/react";
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { IoBanOutline, IoKeyOutline } from "react-icons/io5";
import { useMemo, useState } from "react";

import AdminUsersService from "@/client/services/api/admin/users/AdminUsersService";
import { ConfirmedUser } from "@/client/types/admin/user-manager/AdminUsers";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";
import UserCell from "../../tables/cells/UserCell";
import UserSearch from "@/client/components/input/search/UserSearch";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAdminUsersSearch } from "@/client/services/hooks/admin/users/useAdminUsersSearch";
import { useFormatDate } from "@/client/services/hooks/date-and-time/useFormatDate";
import { useQuery } from "@tanstack/react-query";
import { useResetUserPasswords } from "@/client/services/hooks/admin/users/useResetUserPasswords";
import { useSuspendUsers } from "@/client/services/hooks/admin/users/useSuspendUsers";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/client/services/state/authStore";

export default function ConfirmedUsers() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();
  const { suspendUsersMutation } = useSuspendUsers();
  const { resetPasswordsMutation } = useResetUserPasswords();
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {}
  );

  const {
    handleCreateItem,
    selectedItems,
    setSelectedItems,
    setSearchTerm,
    searchTerm,
    handleSelectedItemsChange,
    comboboxOptions,
    searchFromQueryMutation,
    searchPagination,
    setSearchPagination,
  } = useAdminUsersSearch("confirmed");

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data, refetch } = useQuery({
    queryKey: ["confirmedUsers", fetchDataOptions],
    queryFn: () => AdminUsersService.getConfirmedUsers(fetchDataOptions),
  });

  const suspendUsers = () =>
    suspendUsersMutation
      .mutateAsync(rowSelection)
      .then(() => refetch())
      .finally(() => setRowSelection({}));

  const resetPasswords = () =>
    resetPasswordsMutation
      .mutateAsync(rowSelection)
      .then(() => refetch())
      .finally(() => setRowSelection({}));

  const columnHelper = createColumnHelper<ConfirmedUser>();
  const columns = useMemo<ColumnDefBase<ConfirmedUser, any>[]>(
    () => [
      columnHelper.display({
        id: "checkbox",
        cell: (info) => (
          <Flex alignItems="center">
            <Checkbox
              marginLeft={9}
              variant="admin"
              onChange={info.row.getToggleSelectedHandler()}
              isChecked={info.row.getIsSelected()}
            />
          </Flex>
        ),
        header: (info) => (
          <Checkbox
            marginLeft={9}
            variant="admin"
            onChange={info.table.getToggleAllRowsSelectedHandler()}
            isChecked={info.table.getIsAllRowsSelected()}
          />
        ),
      }),
      columnHelper.accessor("full_name", {
        cell: (info) => (
          <UserCell
            name={info.row.original.full_name}
            image={info.row.original.image}
            id={info.row.original._id}
          />
        ),
        header: () => <div>{t("global.forms.labels_name")}</div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: () => <span>{t("global.forms.labels_email")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("last_login", {
        cell: (info) => (
          <Box>
            {info.getValue()
              ? formatDate(new Date(info.getValue()), "baseWithTime")
              : "----"}
          </Box>
        ),
        header: () => <span>{t("manage.users.lastLogin")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("linked_departments", {
        cell: (info) => (
          <Box>{info.getValue()[0] ? info.getValue()[0].name : "----"}</Box>
        ),
        header: () => <span>{t("global.label.department")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("linked_locations", {
        cell: (info) => (
          <Box>{info.getValue()[0] ? info.getValue()[0].name : "----"}</Box>
        ),
        header: () => <span>{t("global.label.location")}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    []
  );

  return (
    <Box>
      <ButtonGroup
        isDisabled={!rowSelection || Object.keys(rowSelection).length === 0}
        size="xs"
        marginBottom={4}
      >
        {isAllowed(["admin", "users", "password_reset"], null, ["admin"]) && (
          <Button
            variant="adminPrimary"
            leftIcon={<IoKeyOutline />}
            onClick={resetPasswords}
          >
            {t("global.action.resetPasswords")}
          </Button>
        )}

        {isAllowed(["admin", "users", "suspend"], null, ["admin"]) && (
          <Button
            variant="adminWarning"
            leftIcon={<IoBanOutline />}
            onClick={suspendUsers}
          >
            {t("global.action.suspend")}
          </Button>
        )}
      </ButtonGroup>

      <Box marginBottom={4}>
        <UserSearch
          variant="admin"
          placeholder={
            company.groups
              ? t("modules.usersearch.placeholderWithGroup")
              : t("modules.usersearch.placeholder")
          }
          onCreateItem={handleCreateItem}
          items={comboboxOptions || []}
          selectedItems={selectedItems}
          setSearchValue={setSearchTerm}
          searchValue={searchTerm}
          handleClearAll={() => setSelectedItems([])}
          onSelectedItemsChange={(changes: any) => {
            handleSelectedItemsChange(changes.selectedItems, changes.type);
          }}
        />
      </Box>

      <ManuallyPaginatedTable
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        queryData={
          selectedItems.length > 0 ? searchFromQueryMutation.data : data
        }
        pagination={selectedItems.length > 0 ? searchPagination : pagination}
        setPagination={
          selectedItems.length > 0 ? setSearchPagination : setPagination
        }
        showCheckbox
      />
    </Box>
  );
}
