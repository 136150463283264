/* eslint-disable react/jsx-props-no-spreading */

import { Controller, useFormContext } from "react-hook-form";

import { Checkbox } from "@chakra-ui/react";
import type { Content } from "@/client/services/api/graphql/gql/graphql";

interface AssessmentCheckboxProps {
  name: any;
  label: string;
  isChecked: boolean;
  value: string;
  onHandleChange: (value: string, isChecked: boolean) => void;
}

export default function AssessmentCheckbox({
  name,
  label,
  isChecked,
  value,
  onHandleChange,
}: AssessmentCheckboxProps) {
  const { control } = useFormContext<Content>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...field}
          flex="1"
          isChecked={isChecked}
          variant="create"
          height={12}
          onChange={(e) => onHandleChange(value, e.target.checked)}
        >
          {label}
        </Checkbox>
      )}
    />
  );
}
