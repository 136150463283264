import ContentSegmentationSearchService from "@/client/services/api/admin/segmentation/ContentSegmentationSearchService";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const useAuthoringContentSearch = (includeJourneys: boolean = true) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  let types = "articles,videos,files,scorm,imported_content,workshops,tracks";
  if (includeJourneys) {
    types += ",journeys,";
  }
  const { isLoading, isFetching, isError, data } = useQuery({
    enabled: !!searchTerm,
    queryKey: ["user-group-search", searchTerm, types, true, includeJourneys],
    queryFn: () =>
      ContentSegmentationSearchService.search(
        searchTerm,
        types,
        true,
        includeJourneys
      ),
  });

  return {
    searchTerm,
    setSearchTerm,
    isLoading,
    isFetching,
    isError,
    data,
  };
};
