import { Assignment } from "@/client/types/admin/assignments/Assignment";
import { DynamicMessage } from "@/client/types/admin/assignments/DynamicMessage";
import { EscalationContact } from "@/client/types/admin/assignments/EscalationContact";
import { learnApiClient } from "../../clients/learnApiClient";

// TODO: Combine this with other AssignmentsService.ts

const getAssignmentEscalationContacts = async (
  contactIds: string[]
): Promise<EscalationContact[]> => {
  const response = await learnApiClient.get(
    `users?_id=$in,${contactIds.length},${contactIds.join(
      ","
    )}&fields=_id,first_name,last_name,email,full_name,role,image,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,job_title,last_login,createdAt,hired,manager_email&linked_departments-populate=name&linked_grade-populate=name&linked_locations-populate=name&linked_org_level-populate=name&linked_teams-populate=name&page=1&per_page=20`
  );

  return response.data;
};

const getAssignmentById = async (
  assignmentId: string | null
): Promise<Assignment> => {
  const response = await learnApiClient.get(
    `/assignments/${assignmentId}?article-populate=title,type&assessment-populate=title,type&assigned_content.content_id-populate=name,title,type&assignee-populate=first_name,last_name,full_name,email&fields=assignee,completed,incomplete,completed_date,due_date,assigned_content,completed_content,track,article,media,scorm,imported_content,assessment,createdAt,escalation_contacts,non_reminder_messages,reminder_messages,escalate_manager,escalate_buddy,require_recompletion,overdue_messages&imported_content-populate=title,type&media-populate=title,type&scorm-populate=title,type&track-populate=name,title,type`
  );

  if (response.data.escalation_contacts.length > 0) {
    response.data.escalation_contacts = await getAssignmentEscalationContacts(
      response.data.escalation_contacts
    );
  }

  return response.data;
};

interface UpdateAssignmentPayload {
  due_date?: string;
  require_recompletion: boolean;
  escalate_manager: boolean;
  escalate_buddy: boolean;
  non_reminder_messages: string[];
  reminder_messages: {
    message: string;
    time_before_due: number;
    _id: string;
  }[];
  overdue_messages: {
    message: string;
    cadence: "daily" | "weekly" | "monthly";
    total_messages: number;
    total_sent: number;
    satisfied: boolean;
    _id: string;
  }[];
  escalation_contacts: string[];
}

const updateAssignment = async (
  assignmentId: string | null,
  payload: UpdateAssignmentPayload
) => {
  const response = await learnApiClient.post(
    `/assignments/${assignmentId}`,
    payload
  );

  return response.data;
};

// TODO: Move this to dynamic messages service
const getDynamicMessages = async (
  messageIds: string[]
): Promise<{ rows: DynamicMessage[]; pageCount: number }> => {
  const response = await learnApiClient.get(`dynamic-messages`, {
    params: {
      _id: `$in,${messageIds.length},${messageIds.join(",")}`,
      fields:
        "_id,company,user,scheduled,type,content_type,channels,messaging,options,name",
      "user-populate": "first_name,last_name,full_name,email",
    },
  });

  return {
    rows: response.data,
    pageCount: 1,
  };
};

interface UpdateAssignmentNotificationsPayload {
  escalation_contacts: string[];
  non_reminder_messages: string[];
  reminder_messages: { message: string; time_before_due: 1; _id: string }[];
  remove_sent_messages: string[];
}

const updateAssignmentNotifications = async (
  assignmentId: string,
  payload: UpdateAssignmentNotificationsPayload
) => {
  const response = await learnApiClient.post(
    `/assignments/${assignmentId}`,
    payload
  );

  return response.data;
};

const AssignmentsService = {
  getAssignmentEscalationContacts,
  getAssignmentById,
  updateAssignment,
  getDynamicMessages,
  updateAssignmentNotifications,
};

export default AssignmentsService;
