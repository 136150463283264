/* eslint-disable arrow-body-style */
import { useFieldArray, useFormContext } from "react-hook-form";

import DraggableCourseContentItem from "./DraggableCourseContentItem";
import { Stack } from "@chakra-ui/react";
import { isCourseContentData } from "./course-content-data";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { useEffect } from "react";

interface DraggableCourseContentListProps {
  courseContent: any[];
  sectionIndex: number;
}

export default function DraggableCourseContentList({
  courseContent,
  sectionIndex,
}: DraggableCourseContentListProps) {
  const { watch, control } = useFormContext();

  const { update } = useFieldArray({
    control,
    name: "sections",
  });

  const sections = watch("sections");

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isCourseContentData(source.data);
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (
          !isCourseContentData(sourceData) ||
          !isCourseContentData(targetData)
        ) {
          return;
        }

        const indexOfSource = courseContent.findIndex(
          (content) => content.id === sourceData.id
        );
        const indexOfTarget = courseContent.findIndex(
          (content) => content.id === targetData.id
        );

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        const targetSection = sections[sectionIndex];

        const reordered = reorder({
          list: targetSection.courses,
          startIndex: indexOfSource,
          finishIndex: indexOfTarget,
        });

        targetSection.courses = reordered;

        update(sectionIndex, targetSection);
      },
    });
  }, [courseContent]);

  return (
    <Stack>
      {courseContent.map((course) => (
        <DraggableCourseContentItem
          key={course.id || course._id}
          contentItem={course}
          sectionIndex={sectionIndex}
        />
      ))}
    </Stack>
  );
}
