import { Partner } from "@/client/types/Partner";
import { create, StateCreator } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";

interface PartnerState {
  partner: Partner;
  setPartner: (partner: Partner) => void;
  clearPartnerStore: () => void;
}

type PartnerPersist = (
  config: StateCreator<PartnerState>,
  options: PersistOptions<PartnerState>
) => StateCreator<PartnerState>;

export const usePartnerStore = create<PartnerState>(
  (persist as PartnerPersist)(
    (set) => ({
      partner: {} as Partner,
      setPartner: (partner) => set({ partner }),
      clearPartnerStore: () => {
        set(() => ({
          partner: {} as Partner,
        }));
      },
    }),
    { name: "partnerStore" }
  )
);
