import ExploreService from "../api/ExploreService";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../state/authStore";

export const useGetExploreCategories = () => {
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;

  const isPurchaser =
    company.feature_flags.enable_ecommerce && user.is_purchaser;

  const userIsExternal = user.role === "external";

  const getCategories = useQuery({
    queryKey: ["explore-categories", isPurchaser, userIsExternal],
    queryFn: () => ExploreService.getCategories(isPurchaser, userIsExternal),
    onError: (err: any) => {
      console.log("Error", err.response?.data || err);
    },
    refetchOnWindowFocus: false,
  });

  return { getCategories };
};
