import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { isEqual } from "lodash";
import ColorOverridesForm from "./ColorOverridesForm";

interface ColorOverrides {
  anchors: string;
  buttons: string;
  headers: string;
  highLights: string;
  links: string;
  formInputs: string;
}

type PartnerColorOverrideProps = {
  originalColors: ColorOverrides;
  selectedColors: ColorOverrides;
  partnerPrimary: string;
  setSelectedColors: (colors: Dispatch<SetStateAction<ColorOverrides>>) => void;
  resetColors: () => void;
  isLoading: boolean;
};

export default function PartnerColorOverrides({
  originalColors,
  selectedColors,
  partnerPrimary,
  setSelectedColors,
  resetColors,
  isLoading,
}: PartnerColorOverrideProps) {
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    if (!isEqual(originalColors, selectedColors)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [selectedColors, originalColors]);

  return (
    <ColorOverridesForm
      isLoading={isLoading}
      resetOverrides={resetColors}
      setColors={setSelectedColors}
      colors={selectedColors}
      canSubmit={canSubmit}
      defaultColor={partnerPrimary}
    />
  );
}
