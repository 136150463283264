import { learnApiClient } from "@/client/services/api/clients/learnApiClient";
import {
  checkExistsType,
  createIntegrationType,
  removeIntegrationType,
} from "@/client/types/TeamsService";

const checkExists = async (userId: string): Promise<checkExistsType> => {
  const response = await learnApiClient.get<checkExistsType>(
    `microsoft-teams/meetings/exists?userId=${userId}`
  );

  return response.data;
};

const removeIntegration = async (): Promise<removeIntegrationType> => {
  const response = await learnApiClient.post<removeIntegrationType>(
    `microsoft-teams/meetings/remove-integration/`
  );

  return response.data;
};

const createIntegration = async (
  code?: string
): Promise<createIntegrationType> => {
  const response = await learnApiClient.post<createIntegrationType>(
    `microsoft-teams/meetings/integrate`,
    { authCode: code }
  );

  return response.data;
};

const TeamsService = {
  checkExists,
  removeIntegration,
  createIntegration,
};

export default TeamsService;
