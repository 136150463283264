import { graphql } from "../gql/gql";

export const initiateStripeAccountSetup: any = graphql(`
  mutation StripeInitiateAccount {
    stripeInitiateAccount {
      url
    }
  }
`);

export const initiatePaymentMethod: any = graphql(`
  mutation StripeInitiatePaymentMethod {
    stripeInitiatePaymentMethod {
      id
      clientSecret
    }
  }
`);

export const stripeLinkAccount: any = graphql(`
  mutation StripeCompleteAccount(
    $account: String!
    $verificationToken: String!
  ) {
    stripeCompleteAccount(
      accountData: { account: $account, verificationToken: $verificationToken }
    )
  }
`);

export const getStripeAccountNumber: any = graphql(`
  query CompanyIntegrations {
    companyIntegrations {
      stripe {
        account
      }
    }
  }
`);

export const stripeGetLastCustomerPaymentMethod: any = graphql(`
  query StripeGetLastCustomerPaymentMethod {
    stripeGetLastCustomerPaymentMethod {
      paymentId
    }
  }
`);

export const stripeSubmitOneTimePayment: any = graphql(`
  mutation StripeSubmitOneTimePayment(
    $paymentMethodId: String!
    $productId: String!
    $amount: Float!
  ) {
    stripeSubmitOneTimePayment(
      paymentData: {
        paymentMethodId: $paymentMethodId
        productId: $productId
        amount: $amount
      }
    ) {
      status
    }
  }
`);

export const createSubscriptionMutation: any = graphql(`
  mutation CreateSubscription($productId: String!) {
    createSubscription(subscriptionData: { productId: $productId }) {
      id
    }
  }
`);

export const stripeCancelSubscription: any = graphql(`
  mutation CancelSubscription($productId: String!) {
    cancelSubscription(subscriptionData: { productId: $productId }) {
      id
    }
  }
`);

export const updateSubscriptionPaymentMethod: any = graphql(`
  mutation UpdateSubscriptionPaymentMethod(
    $setupIntentId: String!
    $subscriptionId: String!
  ) {
    updateSubscriptionPaymentMethod(
      subscriptionUpdatePaymentData: {
        stripeSetupIntentId: $setupIntentId
        subscriptionId: $subscriptionId
      }
    ) {
      id
    }
  }
`);

export const paymentMethodDataFromSetupIntent: any = graphql(`
  query PaymentMethodDataFromSetupIntent($setupIntentId: String!) {
    paymentMethodDataFromSetupIntent(setupIntentId: $setupIntentId) {
      id
      brand
      lastFour
    }
  }
`);
