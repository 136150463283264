import {
  FullRelatedWorkshop,
  RelatedWorkshop,
  RelatedWorkshopInstance,
} from "@/client/types/content/Workshop";

import { learnApiClient } from "../../../clients/learnApiClient";

export const getRelatedWorkshops = async (
  relatedWorkshops: string[] | RelatedWorkshop[]
): Promise<FullRelatedWorkshop[]> => {
  const fullRelatedWorkshops: FullRelatedWorkshop[] = [];

  const relatedWorkshopIds = relatedWorkshops.map((relatedWorkshop) => {
    if (typeof relatedWorkshop === "string") {
      return relatedWorkshop;
    }

    if (relatedWorkshop._id) {
      return relatedWorkshop._id;
    }
  });

  if (!relatedWorkshopIds || !relatedWorkshopIds.length) {
    return fullRelatedWorkshops;
  }

  const workshopsQuery = `workshops?_id=$in,${
    relatedWorkshopIds.length
  },${relatedWorkshopIds.join(",")}&fields=_id,type,title,image,banner_image`;

  const instancesQuery = `workshop-instances?fields=workshop,start_date,location,online&workshop=$in,${
    relatedWorkshopIds.length
  },${relatedWorkshopIds.join(",")}`;

  const workshops = await learnApiClient.get(workshopsQuery);
  const instances = await learnApiClient.get(instancesQuery);

  workshops.data.forEach((workshop: RelatedWorkshop) => {
    const workshopInstances = instances.data.filter(
      (instance: RelatedWorkshopInstance) => instance.workshop === workshop._id
    );

    if (workshopInstances.length === 1) {
      fullRelatedWorkshops.push({ workshop, instance: workshopInstances[0] });
      return;
    }

    if (workshopInstances.length > 1) {
      fullRelatedWorkshops.push({ workshop, instance: "multiple" });
      return;
    }

    fullRelatedWorkshops.push({ workshop, instance: null });
  });

  return fullRelatedWorkshops;
};
