/* eslint-disable react/jsx-props-no-spreading */
import { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import { Switch } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

interface AssessmentSwitchContainerProps {
  name: any;
  isChecked: boolean;
  onHandleChange: (isChecked: boolean, value: string) => void;
  value: string;
}

export default function AssessmentSwitchContainer({
  name,
  isChecked,
  value,
  onHandleChange,
}: AssessmentSwitchContainerProps) {
  const { control } = useFormContext<AssessmentFormData>();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Switch
          {...field}
          variant="create"
          isChecked={isChecked}
          onChange={(e) => {
            onHandleChange(e.target.checked, value);
          }}
        />
      )}
    />
  );
}
