import { Box, Flex, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

import DashboardCard from "../../data-display/cards/DashboardCard";
import { DashboardWorkshop } from "@/client/types/DashboardWorkshop";
import PlaceholderImage from "@/client/components/media/images/PlaceholderImage";
import { Link as RouterLink } from "react-router-dom";
import ToggleButton from "@/client/components/buttons/ToggleButton";
import { useState } from "react";

interface DashboardWorkshopsListProps {
  attendedWorkshops: DashboardWorkshop[];
  registeredWorkshops: DashboardWorkshop[];
}

function ContentList({
  items,
  isChecked,
}: {
  items: DashboardWorkshop[];
  isChecked: boolean;
}) {
  const { t } = useTranslation();

  if (!items.length)
    return (
      <Box padding={10} textAlign="center" fontSize="sm" color="gray.400">
        <PlaceholderImage name="users" />

        {isChecked ? (
          <Text>{t("dashboard.emptyMessage.workshops_noAttended")}</Text>
        ) : (
          <Trans i18nKey="dashboard.emptyMessage.workshops_noRegistered">
            <Link as={RouterLink} to="/explore" />
          </Trans>
        )}
      </Box>
    );

  return (
    <>
      {items.map((item: DashboardWorkshop) => {
        if (!item.workshop || !item.workshop._id) return;

        return (
          <GridItem key={item.workshop._id}>
            <DashboardCard
              id={item.workshop._id}
              title={item.workshop.title}
              type={item.workshop.type}
              imageUrl={item.workshop.banner_image}
              workshopStartDate={item.workshop_instance.start_date}
              workshopEndDate={item.workshop_instance.end_date}
              originRoute="/dashboard"
              workshopInstanceId={
                item.workshop.type === "workshop"
                  ? item.workshop_instance?._id
                  : undefined
              }
            />
          </GridItem>
        );
      })}
    </>
  );
}

export default function DashboardWorkshopsList({
  attendedWorkshops,
  registeredWorkshops,
}: DashboardWorkshopsListProps) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <Flex alignItems="center" justifyContent="right">
        <ToggleButton
          leftLabel="Registered Workshops"
          rightLabel="Attended Workshops"
          checked={isChecked}
          setIsChecked={() => setIsChecked(!isChecked)}
        />
      </Flex>

      <Grid gap={4}>
        {isChecked ? (
          <ContentList items={attendedWorkshops} isChecked={isChecked} />
        ) : (
          <ContentList items={registeredWorkshops} isChecked={isChecked} />
        )}
      </Grid>
    </>
  );
}
