import {
  BulkImportUser,
  ConfirmedUser,
  PendingUser,
  SuspendedUser,
} from "@/client/types/admin/user-manager/AdminUsers";
import {
  CSVFinalData,
  CSVFinalDataFormatted,
} from "@/client/types/admin/user-manager/CsvFinalData";
import {
  CompanyUserStats,
  UserContentStats,
} from "@/client/services/api/graphql/gql/graphql";

import type { AdminSearchUser } from "@/client/types/admin/users/AdminSearchUser";
import { CSVValidationData } from "@/client/types/admin/user-manager/CsvValidationData";
import { getUserContentStatsQuery } from "@/client/services/api/graphql/queries/users/userContentStats";
import { getUserOverviewStats as getUserOverviewStatsQuery } from "../../graphql/queries/users/userOverviewStats";
import { graphQLClient } from "@/client/services/api/clients/graphqlClient";
import { learnApiClient } from "@/client/services/api/clients/learnApiClient";
import { learnV4ApiClient } from "@/client/services/api/clients/learnV4ApiClient";

const getUserContentStats = async (
  userId: string | undefined
): Promise<UserContentStats> => {
  const data: any = await graphQLClient.request(getUserContentStatsQuery, {
    id: userId,
  });

  return data.user.contentStats;
};

const getUserSeats = async () => {
  const response = await learnApiClient.get("/users/company-seats");

  return response.data;
};

const getUserOverviewStats = async (): Promise<CompanyUserStats> => {
  const data: any = await graphQLClient.request(getUserOverviewStatsQuery);

  return data.companyUserStats;
};

const searchAdminUsers = async (
  searchTerm: string,
  includeSuspendedUsers: boolean,
  suspended?: boolean
): Promise<AdminSearchUser[]> => {
  const params = {
    page: 1,
    per_page: 50,
    full_name: "$regex,2," + searchTerm + ",i",
    sort: "full_name,-1",
    fields:
      "_id,first_name,last_name,full_name,email,image,linked_location,linked_departments,job_title,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,groups",
    suspended: suspended || false,
  };

  if (includeSuspendedUsers) {
    params.suspended = true;
  }

  const response = await learnV4ApiClient.get(`admin-users`, {
    params,
  });

  return response.data;
};

const searchManagers = async (searchTerm: string) => {
  const params = {
    page: 1,
    per_page: 50,
    full_name: `$regex,2,${searchTerm},i`,
    is_manager: true,
    sort: "full_name,-1",
  };

  const response = await learnV4ApiClient.get(`admin-users`, {
    params,
  });

  return response.data;
};

const searchAdminUsersFromQuery = async (
  query: any,
  fetchDataOptions: { pageIndex: number; pageSize: number }
) => {
  const response = await learnV4ApiClient.post(`admin-users/from-query`, {
    "linked_departments-populate": "name",
    "linked_locations-populate": "name",
    page: fetchDataOptions.pageIndex + 1,
    per_page: fetchDataOptions.pageSize,
    queries: [query],
    subresource: "",
    include_count: true,
  });

  return response.data;
};

const searchSuspendedUsers = async (query: string) => {
  const response = await learnV4ApiClient.get(
    `admin-users?${query}&suspended=true`
  );

  return response.data;
};

const getConfirmedUsers = async (options: {
  pageIndex: number;
  pageSize: number;
}): Promise<{ rows: ConfirmedUser[]; pageCount: number }> => {
  const params = {
    "linked_departments-populate": "name",
    "linked_locations-populate": "name",
    page: options.pageIndex + 1,
    per_page: options.pageSize,
    last_login: "$exists,true",
  };

  const userResponse = await learnV4ApiClient.get(`admin-users`, {
    params,
  });

  const countResponse = await learnV4ApiClient.get(
    `admin-users/count?type=confirmed`
  );

  return {
    rows: userResponse.data,
    pageCount: Math.ceil(countResponse.data / options.pageSize),
  };
};

const getPendingUsers = async (options: {
  pageIndex: number;
  pageSize: number;
}): Promise<{ rows: PendingUser[]; pageCount: number }> => {
  const params = {
    last_login: "$exists,false",
    sort: "createdAt,-1",
    page: options.pageIndex + 1,
    per_page: options.pageSize,
  };

  const userResponse = await learnV4ApiClient.get(`admin-users`, {
    params,
  });

  const countResponse = await learnV4ApiClient.get(
    `admin-users/count?type=pending`
  );

  return {
    rows: userResponse.data,
    pageCount: Math.ceil(countResponse.data / options.pageSize),
  };
};

const getSuspendedUsers = async (options: {
  pageIndex: number;
  pageSize: number;
}): Promise<{ rows: SuspendedUser[]; pageCount: number }> => {
  const params = {
    sort: "suspended_on,-1",
    suspended: "true",
    page: options.pageIndex + 1,
    per_page: options.pageSize,
  };

  const userResponse = await learnV4ApiClient.get(`admin-users`, {
    params,
  });

  const countResponse = await learnV4ApiClient.get(
    `admin-users/count?type=suspended`
  );

  return {
    rows: userResponse.data,
    pageCount: Math.ceil(countResponse.data / options.pageSize),
  };
};

const resetUserPasswords = async (userIds: string[]) => {
  const payload = {
    users: userIds,
  };

  const response = await learnApiClient.post("users/resetting", payload);

  return response.data;
};

const suspendUsers = async (userIds: string[]) => {
  const payload = {
    users: userIds,
  };

  const response = await learnApiClient.post("users/suspend", payload);

  return response.data;
};

const unsuspendUsers = async (userIds: string[]) => {
  const payload = {
    users: userIds,
  };

  const response = await learnApiClient.post("users/unsuspend", payload);

  return response.data;
};

const csvInitial = async (
  csv: File | null,
  useProliferationNew: boolean
): Promise<BulkImportUser> => {
  if (!csv) return {} as BulkImportUser;

  const formData = new FormData();
  formData.append("file", csv);

  const response = await learnApiClient.post(
    `proliferation${useProliferationNew ? "-new" : ""}/initial`,
    formData
  );

  return response.data;
};

const csvValidation = async (
  csv: File | null,
  useProliferationNew: boolean
): Promise<CSVValidationData> => {
  if (!csv) return {} as CSVValidationData;

  const formData = new FormData();
  formData.append("file", csv);

  const response = await learnApiClient.post(
    `proliferation${useProliferationNew ? "-new" : ""}/validation`,
    formData
  );

  return response.data;
};

const csvFinal = async (
  payload: CSVValidationData,
  useProliferationNew: boolean
): Promise<CSVFinalDataFormatted> => {
  const response = await learnApiClient.post<CSVFinalData>(
    `proliferation${useProliferationNew ? "-new" : ""}/final`,
    { ...payload.results, getGroups: payload.getGroups }
  );

  const invitations: any[] = [];
  const preprovisions: any[] = [];

  response.data.createdUsers.forEach((user) => {
    if (user.provisioning_status && user.provisioning_status === "inviting") {
      invitations.push(user);
    }
    if (
      user.provisioning_status &&
      user.provisioning_status === "provisioning"
    ) {
      preprovisions.push(user);
    }
  });

  return {
    createdUsers: response.data.createdUsers,
    invitations,
    preprovisions,
    updatedUsers: response.data.createdUpdates,
    resettings: response.data.createdResettings.message
      ? response.data.createdResettings.message
      : null,
    reinvitations: response.data.createdReinvitings.message
      ? response.data.createdReinvitings.message
      : null,
    deletedUsers: response.data.createdDeletions.deleted
      ? response.data.createdDeletions.deleted
      : [],
    migrationsP2I: response.data.createdMigrationsP2I || [],
    migrationsI2P: response.data.createdMigrationsI2P || [],
    locations: response.data.createdCategories.locations || [],
    departments: response.data.createdCategories.departments || [],
  };
};

const getSuspendedUserById = async (
  userId: string | undefined
): Promise<SuspendedUser> => {
  const response = await learnV4ApiClient.get(`admin-users/${userId}`, {
    params: {
      suspended: true,
    },
  });

  return response.data;
};

const reinviteUsers = async (userIds: string[]) => {
  const payload = {
    users: userIds,
  };

  const response = await learnApiClient.post("users/reinvite", payload);

  return response.data;
};

const AdminUsersService = {
  getUserContentStats,
  getUserSeats,
  getUserOverviewStats,
  searchAdminUsers,
  searchManagers,
  searchAdminUsersFromQuery,
  searchSuspendedUsers,
  getConfirmedUsers,
  getPendingUsers,
  getSuspendedUsers,
  resetUserPasswords,
  suspendUsers,
  unsuspendUsers,
  csvInitial,
  csvValidation,
  csvFinal,
  getSuspendedUserById,
  reinviteUsers,
};

export default AdminUsersService;
