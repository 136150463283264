import { AuthConfig } from "../types/AuthConfig";

export const setUserInfo = (authConfig: AuthConfig) => {
  try {
    const { user, company } = authConfig;

    if (window.plantrack) {
      window.plantrack.identify(user._id, {
        email: user.email,
        name: user.first_name + " " + user.last_name,
        companyId: company._id,
      });
    }

    if (window.gtag) {
      window.gtag("set", { user_id: user.email });
    }
    if (window.ga) {
      window.ga("set", { user_id: user.email });
    }
  } catch (e) {
    console.error(e);
  }
};

export const trackEvent = (event: any, attributes?: any) => {
  try {
    // planhat
    if (window.plantrack) {
      window.plantrack.track(event);
    }
    // newrelic
    if (window.newrelic) {
      window.newrelic.addPageAction(event, attributes);
    }
  } catch (e) {
    console.error(e);
  }
};
