import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Category } from "@/client/types/Category";

interface SingleCategoryModalProps {
  title: string;
  items: Category[];
  isOpen: boolean;
  onClose: () => void;
}

export default function SingleCategoryModal({
  title,
  items,
  isOpen,
  onClose,
}: SingleCategoryModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack overflow="auto">
            {items.map((item) => (
              <Text key={item._id}>{item.name}</Text>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
