export const Avatar = {
  defaultProps: { size: "xl" },
  baseStyle: {
    container: {
      backgroundColor: "gray.300",
      color: "gray.900",
    },
    badge: {
      bg: "brand.primary",
      boxSize: "1rem",
    },
  },
  variants: {
    transparent: {
      container: {
        backgroundColor: "transparent",
        borderWidth: "3px",
      },
    },
    usercard: {
      container: {
        backgroundColor: "transparent",
        borderWidth: "3px",
        color: "white",
        width: "77px",
        height: "77px",
      },
      label: {
        fontSize: "2.25rem",
        fontWeight: "400",
      },
    },
  },
};
