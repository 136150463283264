import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useToken,
} from "@chakra-ui/react";
import { Close, Upload } from "@/client/components/icons/ContinuIcons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import AppSettingsForm from "../components/forms/settings/AppSettingsForm";
import AvatarUploadForm from "@/client/components/shared/forms/AvatarUploadForm";
import BannerEditor from "@/client/components/media/images/BannerEditor";
import CustomFieldsForm from "@/client/components/forms/settings/CustomFieldsForm";
import ErrorAlert from "../components/data-display/ErrorAlert";
import IntegrationsSettingsForm from "@/client/components/forms/settings/IntegrationsSettingsForm";
import Loading from "@/client/components/media/Loading";
import NotificationsForm from "../components/forms/settings/NotificationsForm";
import SocialProfilesForm from "@/client/components/forms/settings/SocialProfilesForm";
import User from "@/client/services/api/User";
import UserDetailsForm from "../components/forms/settings/UserDetailsForm";
import UserSkillsForm from "../components/forms/settings/UserSkillsForm";
import ViewActions from "@/client/components/overlay/ViewActions";
import { useAuthStore } from "@/client/services/state/authStore";
import { useAvatarStore } from "../services/state/shared/avatarStore";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import { useUploadImage } from "../services/hooks/shared/images/useUploadImage";

export default function ProfileSettings() {
  useDocumentTitle("Settings");
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;

  const defaultBannerImg =
    "https://d2277n3gvptnup.cloudfront.net/images/51d5f21b2f410a020b000002/cb4e0e69-b263-43a6-aa1d-f73d8325688c.jpg";
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const [bannerUploading, setBannerUploading] = useState(false);
  const { avatar, setAvatar, reset } = useAvatarStore();

  const [banner, setBanner] = useState<{
    bannerEditorModalOpen: boolean;
    img: File | null;
  }>({
    bannerEditorModalOpen: false,
    img: null,
  });

  const { uploadImageMutation } = useUploadImage();

  const { status } = uploadImageMutation;

  const brandPrimary = useToken("colors", "brand.primary");

  const removeUserAvatar = useMutation({
    mutationFn: (url: string) => User.updateAvatar(user._id, url),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile-settings-data"] });

      reset();

      setToast({
        show: true,
        status: "success",
        title: "Avatar updated successfully",
      });
    },
  });

  const updateUserAvatar = useMutation({
    mutationFn: (url: string) => User.updateAvatar(user._id, url),
    onSuccess: (image) => {
      queryClient.invalidateQueries({ queryKey: ["profile-settings-data"] });

      AnalyzeService.recordAvatars(image);

      reset();

      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.profile_updated"),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdateProfile"),
      }),
  });

  const handleAvatarUploadClick = () =>
    avatarInputRef.current ? avatarInputRef.current.click() : null;
  const handleBannerUploadClick = () =>
    bannerInputRef.current ? bannerInputRef.current.click() : null;

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: "avatar" | "banner"
  ) => {
    let fileObject;
    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: "error", title: "Error uploading image" });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = "";

    if (imageType === "avatar") {
      setAvatar({
        ...avatar,
        img: fileObject,
        editorModalOpen: true,
      });
    }

    if (imageType === "banner") {
      setBanner({
        ...banner,
        img: fileObject,
        bannerEditorModalOpen: true,
      });
    }
  };

  const {
    isLoading: isUserLoading,
    isError: isUserError,
    data: userData,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["profile-settings-data", user._id],
    queryFn: () => User.getUser(user._id),
  });

  if (isUserLoading) return <Loading />;

  if (isUserError)
    return <ErrorAlert title="Unable to load profile settings" />;

  return (
    <>
      <Flex
        height={{ base: "200px", md: "300px", lg: "400px" }}
        width="full"
        position="relative"
      >
        {" "}
        <Box
          position="absolute"
          top="0"
          left="0"
          width="full"
          height={{ base: "100vh", md: "600px" }}
          zIndex="0"
          overflow="hidden"
          background={userData.banner_image ? "black" : "brand.mist"}
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            width="full"
            height="100%"
            backgroundColor="black"
            backgroundImage={
              userData.banner_image
                ? "url(" + userData.banner_image + ")"
                : "url(" + defaultBannerImg + ")"
            }
            backgroundPosition="center"
            backgroundSize="cover"
            filter="blur(7px)"
            transform="scale(1.1)"
          />

          <Box
            position="absolute"
            top="0"
            left="0"
            width="full"
            height="100%"
            zIndex="1"
            background="blackAlpha.600"
          />

          <Box
            position="absolute"
            bottom="0"
            left="0"
            width="full"
            height="20%"
            bgGradient="linear(transparent 0%, white 100%)"
            zIndex="2"
          />
        </Box>
        <Box
          zIndex="2"
          width="full"
          height="full"
          maxWidth="1400px"
          margin=" 0 auto"
        >
          <Flex
            paddingTop={{ base: 20, md: 10 }}
            paddingLeft={{ base: 0, md: 32 }}
            paddingBottom={4}
            alignItems="center"
            direction={{ base: "column", md: "row" }}
          >
            <AvatarUploadForm
              isLoading={status === "loading"}
              handleUpload={(file) =>
                uploadImageMutation.mutateAsync({
                  file,
                  errorMessage: "Error uploading avatar",
                  successActions: (cloudfrontLink) =>
                    updateUserAvatar.mutateAsync(cloudfrontLink),
                })
              }
              uploadElement={
                <>
                  <Avatar
                    boxSize="115px"
                    marginRight={6}
                    name={userData.full_name}
                    src={userData.image ? `${userData.image}` : ""}
                  />

                  <Stack>
                    <Text
                      as="h4"
                      margin={0}
                      marginBottom={1}
                      color="white"
                      fontWeight="bold"
                    >
                      {userData.full_name}
                    </Text>

                    <>
                      <Input
                        display="none"
                        ref={avatarInputRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, "avatar")}
                      />

                      <Button
                        size="sm"
                        leftIcon={<Upload />}
                        onClick={handleAvatarUploadClick}
                        paddingX={6}
                        paddingY={5}
                      >
                        {userData.image! === ""
                          ? t("edit.general.avatar_uploadImage")
                          : t("edit.general.images_replaceImage")}
                      </Button>

                      {userData.image !== "" && (
                        <Button
                          size="sm"
                          leftIcon={<Close />}
                          variant="unstyled"
                          color="white"
                          onClick={() => removeUserAvatar.mutate("")}
                          display="flex"
                          _hover={{ cursor: "pointer", color: "brand.primary" }}
                        >
                          {t("global.action.remove_image")}
                        </Button>
                      )}
                    </>
                  </Stack>
                </>
              }
            />
          </Flex>

          <Box textAlign="right" paddingX={8} paddingBottom={1}>
            <Input
              display="none"
              ref={bannerInputRef}
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => handleFileChange(e, "banner")}
            />

            <Button
              variant="unstyled"
              leftIcon={<Upload color={brandPrimary} />}
              color="white"
              onClick={handleBannerUploadClick}
              opacity="0.8"
              _hover={{ opacity: "1" }}
              fontSize="sm"
            >
              {userData.banner_image
                ? t("userProfileEdit.replaceProfileBanner")
                : t("userProfileEdit.uploadProfileBanner")}
            </Button>
          </Box>

          <Container maxWidth="container.2xl" padding={2}>
            <Flex direction={{ base: "column", lg: "row" }}>
              <Flex
                direction="column"
                background="white"
                padding={6}
                margin={{ base: 0, lg: 2 }}
                width={{ base: "100%", lg: "70%" }}
              >
                <UserDetailsForm />

                <UserSkillsForm
                  userId={userData._id}
                  currentUserSkills={userData.skills}
                />

                {company.feature_flags.enable_social_profiles && (
                  <SocialProfilesForm
                    userId={userData._id}
                    socialProfiles={userData.social_links}
                  />
                )}

                <CustomFieldsForm
                  userId={userData._id}
                  metadata={userData.metadata}
                  companyCustomFields={userData.company.user_metadata_fields}
                />
              </Flex>

              <Flex
                direction="column"
                background="white"
                padding={6}
                margin={{ base: 0, lg: 2 }}
                width={{ base: "100%", lg: "30%" }}
              >
                <AppSettingsForm userId={userData._id} />
                {!company.feature_flags.disable_notification_preferences && (
                  <NotificationsForm />
                )}
                <IntegrationsSettingsForm />
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Flex>

      <Modal
        isCentered
        size="full"
        isOpen={banner.bannerEditorModalOpen}
        onClose={() => null}
      >
        <ModalOverlay />

        <ModalContent padding={4}>
          <Center>
            {bannerUploading && <Spinner />}

            {!bannerUploading && (
              <BannerEditor
                userId={user._id}
                banner={banner}
                setBanner={setBanner}
                setBannerUploading={setBannerUploading}
              />
            )}
          </Center>
        </ModalContent>
      </Modal>

      <ViewActions buttons={["gototop", "viewprofile"]} userid={user._id} />
    </>
  );
}
