/* eslint-disable react/jsx-props-no-spreading */

import { Controller, useFormContext } from "react-hook-form";
import {
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tooltip,
} from "@chakra-ui/react";

import { DefaultTFuncReturn } from "i18next";
import FormLabelWithTooltip from "./FormLabelWithTooltip";
import { QuestionCircle } from "../../icons/ContinuIcons";

interface InputWithToolTipProps {
  name: string;
  label: string;
  tooltipText?: string | DefaultTFuncReturn;
  isRequired?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

export default function InputWithToolTip({
  name,
  label,
  tooltipText,
  isRequired,
  placeholder,
  isDisabled,
}: InputWithToolTipProps) {
  const { control } = useFormContext();

  return (
    <Stack spacing={2}>
      <FormLabelWithTooltip label={label} isRequired={isRequired} />

      <InputGroup>
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: isRequired || false,
              message: "This field is required",
            },
          }}
          render={({ field }) => (
            <Input
              variant="adminInput"
              isDisabled={isDisabled}
              {...field}
              placeholder={placeholder}
            />
          )}
        />

        {tooltipText && (
          <InputRightElement>
            <Tooltip hasArrow label={tooltipText} aria-label="A tooltip">
              <QuestionCircle color="brand.grey.50" />
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
    </Stack>
  );
}

InputWithToolTip.defaultProps = {
  isRequired: false,
  placeholder: undefined,
  tooltipText: null,
  isDisabled: false,
};
