import { graphQLClient } from "@/client/services/api/clients/graphqlClient";
import {
  StripeInitiateAccount,
  StripeInitiatePaymentMethodCreation,
} from "@/client/services/api/graphql/gql/graphql";
import {
  initiatePaymentMethod,
  stripeLinkAccount,
  initiateStripeAccountSetup,
  getStripeAccountNumber,
  stripeGetLastCustomerPaymentMethod,
  stripeSubmitOneTimePayment,
  stripeCancelSubscription,
  createSubscriptionMutation,
  updateSubscriptionPaymentMethod as updateSubscriptionPaymentMethodMutation,
  paymentMethodDataFromSetupIntent,
} from "@/client/services/api/graphql/queries/stripe";

export async function initiateStripeAccount(): Promise<StripeInitiateAccount> {
  const data: any = await graphQLClient.request(initiateStripeAccountSetup, {});
  return data.stripeInitiateAccount;
}

export async function linkStripeAccount(
  account: string,
  verificationToken: string
): Promise<Boolean> {
  const data: any = await graphQLClient.request(stripeLinkAccount, {
    account,
    verificationToken,
  });
  return data.stripeLinkAccount;
}

export async function initiatePaymentMethodSetup(): Promise<StripeInitiatePaymentMethodCreation> {
  const data: any = await graphQLClient.request(initiatePaymentMethod, {});
  return data.stripeInitiatePaymentMethod;
}

export async function getStripeAccount() {
  const data: { companyIntegrations: any } = await graphQLClient.request(
    getStripeAccountNumber,
    {}
  );
  return data.companyIntegrations.stripe.account;
}

export async function getStripeAccountLastPaymentMethod() {
  const data: any = await graphQLClient.request(
    stripeGetLastCustomerPaymentMethod,
    {}
  );
  return data.stripeGetLastCustomerPaymentMethod.paymentId;
}

export async function submitOneTimePayment(
  paymentMethodId: string,
  amount: number,
  productId: string
) {
  await graphQLClient.request(stripeSubmitOneTimePayment, {
    paymentMethodId,
    amount,
    productId,
  });
}

export async function createSubscription(
  productId: string,
  setupIntentId?: string
) {
  await graphQLClient.request(createSubscriptionMutation, {
    productId,
    setupIntentId,
  });
}

export async function cancelSubscription(productId: string) {
  const data: any = await graphQLClient.request(stripeCancelSubscription, {
    productId,
  });
  return data.cancelSubscription.id;
}

export async function updateSubscriptionPaymentMethod(
  setupIntentId: string,
  subscriptionId: string
) {
  const data: any = await graphQLClient.request(
    updateSubscriptionPaymentMethodMutation,
    {
      setupIntentId,
      subscriptionId,
    }
  );
  return data.updateSubscriptionPaymentMethod.id;
}

export async function getPaymentMethodDataWithSetupIntentId(
  setupIntentId: string
) {
  const data: any = await graphQLClient.request(
    paymentMethodDataFromSetupIntent,
    {
      setupIntentId,
    }
  );
  return data.paymentMethodDataFromSetupIntent;
}

const EcommerceService = {
  initiatePaymentMethodSetup,
  initiateStripeAccount,
  linkStripeAccount,
  getStripeAccount,
  getStripeAccountLastPaymentMethod,
  submitOneTimePayment,
  createSubscription,
  cancelSubscription,
  updateSubscriptionPaymentMethod,
  getPaymentMethodDataWithSetupIntentId,
};

export default EcommerceService;
