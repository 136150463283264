import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Stack,
  VStack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
} from "@chakra-ui/react";
import { BiCollapse } from "react-icons/bi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RegistrationFormDetails from "./RegistrationFormDetails";
import HeadingWithTooltip from "@/client/components/admin/forms/HeadingWithTooltip";
import ControlledMultiSegmentationFormField from "@/client/components/forms/ControlledMultiSegmentationFormField";
import ControlledSegmentationFormField from "@/client/components/forms/ControlledSegmentationFormField";
import AdminUsersTeamsSearch from "@/client/components/admin/forms/users/AdminUsersTeamsSearch";
import AdminUsersGroupSearch from "@/client/components/admin/forms/users/AdminUsersGroupSearch";
import SolidButton from "@/client/components/shared/buttons/SolidButton";
import LandingPagePreview from "./LandingPagePreview";
import OutlineButton from "@/client/components/shared/buttons/OutlineButton";
import { ArrowLeft } from "@/client/components/icons/ContinuIcons";
import { useFormContext } from "react-hook-form";

export default function RegistrationFormsEdit({
  submit,
  goBack,
}: {
  submit: () => void;
  goBack: () => void;
}) {
  const { t } = useTranslation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <>
      <Modal isOpen={previewOpen} onClose={() => setPreviewOpen(false)}>
        <ModalOverlay />

        <ModalContent
          marginTop="0"
          minWidth="100%"
          minHeight="100%"
          paddingTop={14}
          paddingBottom={14}
        >
          <Button
            variant="createCancel"
            zIndex={9999}
            position="absolute"
            top={4}
            right={6}
            leftIcon={<Icon as={BiCollapse} />}
            onClick={() => setPreviewOpen(false)}
          >
            {t("authoring.livePreview.returnToEditing")}
          </Button>
          <ModalBody>
            <Box>
              <Flex
                minHeight="100vh"
                alignSelf="center"
                alignItems="self-start"
                justify="center"
                maxWidth="100%"
                zIndex="2"
              >
                <LandingPagePreview />
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box>
        <Text fontWeight="500" mb={4}>
          {t("registrationForms.label.registrationForm")}
        </Text>
        <VStack alignItems="start" width="100%">
          <Accordion width="100%" defaultIndex={[0, 1]} allowMultiple>
            <AccordionItem
              marginRight={4}
              backgroundColor="brand.highlight"
              borderRadius="10px"
              marginBottom={3}
            >
              <AccordionButton textAlign="left" color="white" fontWeight="600">
                {t("registrationForms.edit.pageURLAndFormFields")}
              </AccordionButton>
              <AccordionPanel
                pb={4}
                backgroundColor="white"
                borderBottomRadius="10px"
              >
                <RegistrationFormDetails />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem
              backgroundColor="brand.highlight"
              borderRadius="10px"
            >
              <AccordionButton textAlign="left" color="white" fontWeight="600">
                {t("registrationForms.edit.segmentation")}
              </AccordionButton>
              <AccordionPanel backgroundColor="white" borderBottomRadius="10px">
                <Stack spacing={2}>
                  <HeadingWithTooltip
                    title={t("edit.general.headlines_segmentation")}
                    helpText={t("registrationForms.tooltip.segmentation")}
                  />

                  <ControlledMultiSegmentationFormField
                    fieldName="segmentations.location"
                    fieldLabel={t("global.forms.labels_location")}
                    helpLabel={t("global.forms.labels_location")}
                    segmentationType="location"
                  />

                  <ControlledMultiSegmentationFormField
                    fieldName="segmentations.department"
                    fieldLabel={t("global.forms.labels_department")}
                    helpLabel={t("global.forms.labels_department")}
                    segmentationType="department"
                  />

                  <ControlledSegmentationFormField
                    fieldName="segmentations.grade"
                    fieldLabel={t("manage.label_grade")}
                    helpLabel={t("manage.label_grade")}
                    segmentationType="grade"
                  />

                  <ControlledSegmentationFormField
                    fieldName="segmentations.org_level"
                    fieldLabel={t("manage.label_org_level")}
                    helpLabel={t("manage.label_org_level")}
                    segmentationType="org_level"
                  />

                  <AdminUsersTeamsSearch fieldName="segmentations.team" />

                  <AdminUsersGroupSearch fieldName="segmentations.group" />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Flex
            alignSelf="flex-end"
            direction="row"
            width="400px"
            justifyContent="space-between"
          >
            <OutlineButton
              marginTop={6}
              alignSelf="flex-end"
              onClick={() => goBack()}
              leftIcon={<ArrowLeft />}
            >
              {t("global.actions.back")}
            </OutlineButton>
            <OutlineButton
              marginTop={6}
              alignSelf="flex-end"
              onClick={() => setPreviewOpen(true)}
            >
              {t("global.actions.preview")}
            </OutlineButton>
            <SolidButton
              marginTop={6}
              alignSelf="flex-end"
              onClick={() => submit()}
              isDisabled={!isValid}
            >
              {t("global.actions.publish")}
            </SolidButton>
          </Flex>
        </VStack>
      </Box>
    </>
  );
}
