import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { Cog } from "@/client/components/icons/ContinuIcons";
import ContentList from "../explore/ContentList";
import DraggableList from "../lists/DraggableList";
import ExploreSearch from "@/client/components/input/search/ExploreSearch";
import { IoSparklesSharp } from "react-icons/io5";
import Loading from "@/client/components/media/Loading";
import RecommendationsOverview from "../lists/explore/RecommendationsOverview";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useExploreStore } from "../../services/state/routeState/exploreStore";
import { useGetExploreCategories } from "@/client/services/hooks/useGetExploreCategories";
import { useMeasure } from "@reactuses/core";
import { usePersistedSearch } from "@/client/services/hooks/explore/usePersistedSearch";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ExploreTabs() {
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { allowance } = useAccessHelper();
  const isPurchaser =
    company.feature_flags.enable_ecommerce && user.is_purchaser;
  const recommendationsEnabled = !!allowance.recommendations;
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>(0);
  const [draggableModalOpen, setDraggableModalOpen] = useState<boolean>(false);
  const tabList = useRef<HTMLDivElement>(null);
  const [tabListRect] = useMeasure(tabList.current);

  const recommendationsCategoryName = "recommendations";

  const {
    isSearching,
    isFiltering,
    pickerItems,
    handleCreateItem,
    handleClearAll,
    handleRecordSearchTermAndContentSelected,
    handleSelectedItemsChange,
  } = usePersistedSearch();

  const {
    selectedTopic,
    setSelectedTopic,
    selectedSubTopic,
    setSelectedSubTopic,
    setSelectedCategory,
    setSelectedIndex,
    selectedIndex,
    selectedCategory,
    persistedSearchTerm,
    persistedSearchQuery,
    persistedTypeRestriction,
    persistedSelectedItems,
  } = useExploreStore();

  const { getCategories } = useGetExploreCategories();

  const { data: categories, isLoading, isError } = getCategories;

  const categoryUrlParam = searchParams.get("category");
  const topicURlParam = searchParams.get("topic");
  const subTopicUrlParam = searchParams.get("subTopic");

  useEffect(() => {
    if (
      !categories ||
      !categories.topLevelCategories ||
      categories.topLevelCategories.length === 0
    )
      return;

    // Handle setting recommendations as the active tab
    if (categoryUrlParam === recommendationsCategoryName) {
      setActiveTabIndex(categories?.topLevelCategories.length || 50);
      setSelectedIndex(categories?.topLevelCategories.length || 50);
      setSelectedCategory(recommendationsCategoryName);

      return;
    }

    const categoryIsAvailable = categories.topLevelCategories.some(
      (category) => category._id === categoryUrlParam
    );

    if (categoryUrlParam && categoryIsAvailable) {
      const categoryIndex = categories.topLevelCategories.findIndex(
        (c: any) => c._id === categoryUrlParam
      );

      const category = categories.topLevelCategories[categoryIndex];

      setActiveTabIndex(categoryIndex);
      setSelectedIndex(categoryIndex);

      if (topicURlParam) {
        const topic = categories.secondLevelCategories.find(
          (c: any) => c._id === topicURlParam
        );

        setSelectedTopic(topic);
        setSelectedCategory(topicURlParam);
      } else if (categoryUrlParam === recommendationsCategoryName) {
        setSelectedCategory(recommendationsCategoryName);
        setActiveTabIndex(categories?.topLevelCategories.length || 50);
        setSelectedIndex(categories?.topLevelCategories.length || 50);
      } else {
        setSelectedCategory(category._id);
      }

      if (subTopicUrlParam) {
        const subTopic = categories.thirdLevelCategories.find(
          (c: any) => c._id === subTopicUrlParam
        );

        setSelectedSubTopic(subTopic);
        setSelectedCategory(subTopicUrlParam);
      }
    } else {
      searchParams.delete("topic");
      searchParams.delete("subTopic");
      setActiveTabIndex(0);
      const category = categories.topLevelCategories[0];
      searchParams.set("category", category._id);
      setSearchParams(searchParams);
      setSelectedCategory(category._id);
    }

    /**
     * ! I believe the following code block is now redundant and is causing an infinite loop when
     * ! navigating directly to a category via a link. Leaving commented in case removal causes issues.
     */

    // if (selectedIndex) {
    //   const category = categories.topLevelCategories[selectedIndex];

    //   if (category && category._id) {
    //     searchParams.set('category', category._id);
    //     setSearchParams(searchParams);
    //     setActiveTabIndex(selectedIndex);
    //   } else {
    //     setActiveTabIndex(0);
    //     searchParams.set('category', categories.topLevelCategories[0]._id);
    //     setSearchParams(searchParams);
    //     setSelectedCategory(categories.topLevelCategories[0]._id);
    //   }
    // } else {
    //   setActiveTabIndex(0);
    //   searchParams.set('category', categories.topLevelCategories[0]._id);
    //   setSearchParams(searchParams);
    //   setSelectedCategory(categories.topLevelCategories[0]._id);
    // }
  }, [categories, categoryUrlParam]);

  const getTabLengthInPixels = (numberOfCharacters: number) => {
    // Each letter in a 14px font is around 5 to 7 pixels wide
    const wordLengthInPixelsEstimate = numberOfCharacters * 6;
    // We have a padding of 16px on either side
    const wordWithPadding = wordLengthInPixelsEstimate + 32;
    // Each tab has a right margin of 24px
    const tabWithMargin = wordWithPadding + 24;
    return tabWithMargin;
  }

  const tabCount = useMemo(() => {
    // This estimates the amount of space tabs will take up
    // based on their character count plus a little wiggle room for padding
    // and returns the index that surpasses the expected width
    // So we round down when getting the tabCategoryIds to make sure all tabs fit
    // 130 accounts for the button, 375 accounts for the button and recommendations
    let buttonWidth = 140;
    if (recommendationsEnabled) {
      buttonWidth = 285;
    }

    let pixelsUsed = 0;
    for (let i = 0; i < (categories?.topLevelCategories.length || 0); i += 1) {
      if (pixelsUsed + buttonWidth > tabListRect?.width) {
        return i === 0 ? i : i - 1;
      }
      pixelsUsed += categories?.topLevelCategories[i].name.length
        ? getTabLengthInPixels(categories?.topLevelCategories[i].name.length)
        : 0;
    }
    return categories?.topLevelCategories.length;
  }, [tabListRect.width, categories]);

  const tabCategoryIds = useMemo(
    () =>
      categories?.topLevelCategories
        ?.slice(0, tabCount || 0)
        .map((cat) => cat._id),
    [tabCount, categories]
  );

  const selectCategories = useMemo(
    () => categories?.topLevelCategories.slice(tabCount || 0),
    [tabCount, categories]
  );

  const handleTabChange = (
    tabIndex: number | undefined,
    categoryId: string
  ) => {
    setSelectedTopic(undefined);
    searchParams.delete("topic");
    setSearchParams(searchParams);
    setSelectedSubTopic(undefined);
    searchParams.delete("subTopic");
    setSearchParams(searchParams);
    searchParams.set("category", categoryId);
    setSearchParams(searchParams);
    setActiveTabIndex(tabIndex);
    setSelectedIndex(tabIndex);
    setSelectedCategory(categoryId);
  };

  const handleRecommendationsSelected = () => {
    // I also don't love this. But with the tabs and tab panels,
    // this was the most straightforward solution
    const tab = categories?.topLevelCategories.length
      ? categories?.topLevelCategories.length
      : isPurchaser
      ? 1
      : 0;
    handleTabChange(tab, recommendationsCategoryName);
  };

  useEffect(() => {
    if (!isLoading) {
      if (selectedTopic) {
        searchParams.set("topic", selectedTopic._id);
        setSearchParams(searchParams);
        setSelectedCategory(selectedTopic._id);
      }

      if (selectedSubTopic) {
        searchParams.set("subTopic", selectedSubTopic._id);
        setSearchParams(searchParams);
        setSelectedCategory(selectedSubTopic._id);
      }
    }
  }, [selectedTopic, selectedSubTopic]);

  if (isLoading) return <Loading />;

  if (isError) return <Box>Error:</Box>;

  return (
    <Tabs
      isLazy
      lazyBehavior="unmount"
      isFitted
      minHeight="100vh"
      index={activeTabIndex}
    >
      {draggableModalOpen && (
        <DraggableList
          isOpen={draggableModalOpen}
          setIsOpen={setDraggableModalOpen}
        />
      )}

      <Flex
        paddingY={4}
        paddingX={4}
        maxWidth="100%"
        alignItems="center"
        flexDirection={{
          base: "column",
          lg: "row",
        }}
      >
        <Box
          ref={tabList}
          flex={1}
          display="flex"
          marginBottom={{
            base: 0,
            lg: "0",
          }}
          marginRight="10px"
          justifyContent="space-between"
          marginLeft={{ base: 4, sm: "auto" }}
          maxWidth="100%"
          minWidth="300px"
          order={{
            base: "2",
            lg: "1",
          }}
          overflowX="hidden"
        >
          <Flex marginRight={3}>
            <TabList>
              {isPurchaser && (
                <Tab
                  key="purchaserTab"
                  id="purchases"
                  onClick={() => handleTabChange(0, "purchases")}
                  marginRight={6}
                  paddingBottom={2}
                  fontWeight="500"
                  color="gray.600"
                  whiteSpace="nowrap"
                  fontSize="14px"
                  _hover={{
                    textShadow: "0.75px 0",
                    color: "brand.primary",
                  }}
                  _selected={{
                    textShadow: "0.75px 0",
                    color: "brand.primary",
                  }}
                  pos="relative"
                >
                  {t("explore.general.purchased_content")}
                </Tab>
              )}
              {categories.topLevelCategories &&
                categories.topLevelCategories.map((item, index) => {
                  if (
                    user &&
                    user.role &&
                    user.role === "external" &&
                    !item.show_external
                  )
                    return;

                  if (
                    tabListRect.width &&
                    tabCategoryIds &&
                    !tabCategoryIds.includes(item._id)
                  )
                    return;

                  return (
                    <Tab
                      key={item._id}
                      id={item._id}
                      onClick={() => handleTabChange(index, item._id)}
                      marginRight={6}
                      paddingBottom={2}
                      fontWeight="500"
                      color="gray.600"
                      whiteSpace="nowrap"
                      fontSize="14px"
                      _hover={{
                        textShadow: "0.75px 0",
                        color: "brand.primary",
                      }}
                      _selected={{
                        textShadow: "0.75px 0",
                        color: "brand.primary",
                      }}
                      pos="relative"
                    >
                      {item.name === "Featured"
                        ? t("explore.general.categories_featured")
                        : t(item.name)}
                    </Tab>
                  );
                })}
            </TabList>
            {!!selectCategories?.length && (
              <Menu variant="userMenu">
                <MenuButton
                  as={Button}
                  variant="menu"
                  minW="130px"
                  alignSelf="end"
                  lineHeight={0}
                  paddingBottom={1}
                  rightIcon={<ChevronDownIcon />}
                >
                  More
                </MenuButton>
                <MenuList>
                  {selectCategories?.map((overflowCategory, index) => (
                    <MenuItem
                      key={`menuitem-${overflowCategory._id}`}
                      onClick={() =>
                        handleTabChange(
                          index +
                            ((!!tabCategoryIds && tabCategoryIds.length) || 0),
                          overflowCategory._id
                        )
                      }
                      _focus={{ color: "brand.primary" }}
                      color={
                        !!tabCategoryIds &&
                        selectedIndex === index + tabCategoryIds.length
                          ? "brand.primary"
                          : "auto"
                      }
                    >
                      {overflowCategory.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Flex>
          {recommendationsEnabled && (
            <Button
              variant={
                selectedCategory === recommendationsCategoryName
                  ? "adminPrimary"
                  : "outline"
              }
              borderRadius="30px"
              paddingX={6}
              marginRight={{ base: 4, sm: 0 }}
              size="sm"
              alignSelf="center"
              justifySelf="end"
              onClick={handleRecommendationsSelected}
              leftIcon={<IoSparklesSharp size={14} />}
            >
              {t("recommendations.explore.forYou")}
            </Button>
          )}
        </Box>

        <Flex order={{ base: 1, lg: 2 }} width={{ base: "100%", lg: "auto" }}>
          <ExploreSearch
            placeholder={t("assignmentsManager.search_assignments_content")}
            onCreateItem={handleCreateItem}
            items={pickerItems}
            selectedItems={persistedSelectedItems}
            onSelectedItemsChange={(changes: any) =>
              handleSelectedItemsChange(changes.selectedItems, changes.type)
            }
            handleClearAll={handleClearAll}
            order={2}
          />

          {user.role === "admin" && (
            <Box order={3}>
              <Tooltip label="Order Content" placement="bottom">
                <span>
                  <IconButton
                    display={{ base: "none", sm: "inline-block" }}
                    variant="unstyled"
                    color="gray.500"
                    aria-label={t("explore.general.order_content")}
                    icon={<Cog boxSize={6} />}
                    _hover={{ color: "brand.primary" }}
                    onClick={() => setDraggableModalOpen(true)}
                  />
                </span>
              </Tooltip>
            </Box>
          )}
        </Flex>
      </Flex>

      <TabPanels>
        {categories.topLevelCategories &&
          categories.topLevelCategories.map((category) => (
            <TabPanel key={category._id} paddingTop={0}>
              <ContentList
                category={category}
                secondLevelCategories={categories.secondLevelCategories}
                thirdLevelCategories={categories.thirdLevelCategories}
                cardType={
                  config.features.explore_in_list_view ? "list" : "card"
                }
                isFiltering={isFiltering}
                restrictedTypes={persistedTypeRestriction}
                isSearching={isSearching}
                searchQuery={persistedSearchQuery}
                searchTerm={persistedSearchTerm}
                recordSearch={handleRecordSearchTermAndContentSelected}
              />
            </TabPanel>
          ))}
        {isPurchaser && (
          <TabPanel key="tabpanelpurchaser" paddingTop={0}>
            <ContentList
              secondLevelCategories={[]}
              thirdLevelCategories={[]}
              cardType={config.features.explore_in_list_view ? "list" : "card"}
              isFiltering={isFiltering}
              restrictedTypes={persistedTypeRestriction}
              isSearching={isSearching}
              searchQuery={persistedSearchQuery}
              searchTerm={persistedSearchTerm}
              recordSearch={handleRecordSearchTermAndContentSelected}
            />
          </TabPanel>
        )}
        {recommendationsEnabled && (
          <TabPanel
            key="tabpanelrecommendations"
            backgroundColor="brand.backgroundGrey"
            height="100vh"
          >
            <RecommendationsOverview />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
