import { AdminCategoriesData } from '@/client/types/admin/categories/AdminCategoriesData';
import AdminCategoriesService from '@/client/services/api/admin/categories/AdminCategoriesService';
import { useQuery } from '@tanstack/react-query';

// TODO: Fix the type errors in here

export const useGetOrganizedCategories = () =>
  useQuery({
    queryKey: ['organized-admin-categories'],
    queryFn: () => AdminCategoriesService.getCategoriesForAdmin(),
    select: (data) => {
      const organizedCategories = data
        .filter((category: AdminCategoriesData) => category.level === 1)
        .map((category: AdminCategoriesData) => {
          const subCategories = data
            // @ts-ignore
            .filter((child: AdminCategoriesData) => child.parent === category._id)
            .map((subCategory: AdminCategoriesData) => ({
              ...subCategory,
              parent: category,
            }));

          const withSubSubCategories = subCategories.map((subCategory: AdminCategoriesData) => {
            const subSubCategories = data
              // @ts-ignore
              .filter((child: AdminCategoriesData) => child.parent === subCategory._id)
              .map((subSubCategory: AdminCategoriesData) => ({
                ...subSubCategory,
                parent: subCategory,
              }));

            return {
              ...subCategory,
              fullChildren: subSubCategories,
            };
          });

          return {
            ...category,
            fullChildren: withSubSubCategories,
          };
        });

      return {
        organizedCategories,
        featuredCategory: data.filter((category) => category.level === 10)[0] || null,
      };
    },
  });
