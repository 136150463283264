import { useQuery } from "@tanstack/react-query";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import { ShoppingBag } from "@/client/components/icons/ContinuIcons";
import { useFormContext } from "react-hook-form";
import { InputWithTagList } from "@/client/components/forms/registration-forms/InputWithTagList";
import {
  OrderingDirection,
  Product,
} from "@/client/services/api/graphql/gql/graphql";
import { useDebounceValue } from "usehooks-ts";

type ProductTagSearchProps = {
  fieldName: string;
  placeholder: string;
};

export default function ProductTagSearch({
  fieldName,
  placeholder,
}: ProductTagSearchProps) {
  const [searchTerm, setSearchTerm] = useDebounceValue<string | undefined>(
    undefined,
    300
  );
  const { setValue, watch } = useFormContext();
  const selectedProducts: string[] = watch(fieldName);

  // list products
  const { data: fetchedProducts } = useQuery({
    queryKey: ["selected-products", selectedProducts],
    queryFn: () =>
      ProductsService.adminListProducts({
        id: { in: selectedProducts },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        ordering: [
          { field: "purchaseCount", direction: OrderingDirection.Desc },
        ],
      }),
    enabled: !!selectedProducts?.length,
  });

  // search products
  const { data: searchedProducts } = useQuery({
    queryKey: ["searched-products", searchTerm],
    queryFn: () =>
      ProductsService.adminListProducts({
        title: { contains: searchTerm },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        archived: { eq: false },
        ordering: [{ field: "updatedAt", direction: OrderingDirection.Desc }],
      }),
    enabled: !!searchTerm?.length,
  });

  const productSelected = (selectedItem: Product) => {
    const currentProducts = selectedProducts || [];
    currentProducts.push(selectedItem.id);
    setValue(fieldName, currentProducts);
  };

  const productDelete = (product: Product) => {
    const newProducts = selectedProducts?.filter(
      (productId) => productId !== product.id
    );
    setValue(fieldName, newProducts);
  };

  const checkIfSelected = (item: Product, selectedItemIds: string[]) => {
    const itemSelected = selectedItemIds?.find(
      (selectedItemId: string) => selectedItemId === item.id
    );
    return !!itemSelected;
  };

  return (
    <InputWithTagList
      fieldName={fieldName}
      selectedItems={fetchedProducts?.products || []}
      selectItem={productSelected}
      deleteItem={productDelete}
      checkIfSelected={checkIfSelected}
      searchTerm={searchTerm}
      inputPlaceholder={placeholder}
      setSearchTerm={setSearchTerm}
      searchedItems={searchedProducts?.products || []}
      ItemsIcon={ShoppingBag}
    />
  );
}
