import CreateNavigationBlocker from "../create/content/overlay/CreateNavigationBlocker";
import { Outlet } from "react-router-dom";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";

export default function AdminContentAuthoringLayout() {
  const { canLeave } = useCreateStore();

  return (
    <>
      <Outlet />

      <CreateNavigationBlocker enabled={!canLeave} />
    </>
  );
}
