import { Box, Flex, Grid, GridItem, Link, Text } from "@chakra-ui/react";

import DashboardCard from "../../data-display/cards/DashboardCard";
import DashboardService from "@/client/services/api/DashboardService";
import Loading from "@/client/components/media/Loading";
import PlaceholderImage from "@/client/components/media/images/PlaceholderImage";
import { Link as RouterLink } from "react-router-dom";
import ToggleButton from "@/client/components/buttons/ToggleButton";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DashboardLearningTracksList({
  userId,
}: {
  userId: string;
}) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [completed, setCompleted] = useState<any>([]);
  const [open, setOpen] = useState<any>([]);

  const { isLoading, isError, data, error } = useQuery(
    ["query-user-learning-tracks"],
    async () => await DashboardService.getAllUserTracks(userId),
    {
      onSuccess: (res: any) => {
        setCompleted(res.filter((item: any) => item.completed_date));
        setOpen(res.filter((item: any) => !item.completed_date));
      },
      onError: (err: any) => {
        console.log("Error", err.response?.data || err);
      },
    }
  );

  const ContentList = ({ items }: any) => {
    if (!items.length)
      return (
        <Box
          padding={10}
          textAlign="center"
          fontSize="sm"
          color="blackAlpha.600"
        >
          <PlaceholderImage name="park" />

          {isChecked ? (
            <Text>{t("dashboard.tracks.completed_emptyPtOne")}</Text>
          ) : (
            <Text>
              {t("dashboard.tracks.active_empty")}{" "}
              <Link to="/explore" as={RouterLink}>
                {t("global.vertNavbar.explore")}
              </Link>
              .
            </Text>
          )}
        </Box>
      );

    return (
      <>
        {items.map((item: any, index: number) => {
          // Archived tracks shouldn't show in the learning track list.
          if (item.track.archived) {
            return null;
          }
          let calculateCompletePercent = () => {
            let percent = 0;
            let totalCount = 0;
            item.track.sections.map((item: any) => {
              totalCount = totalCount + item.courses.length;
            });
            if (totalCount > 0) {
              let completedCount =
                item.completed_articles.length +
                item.completed_assessments.length +
                item.completed_media.length +
                item.completed_quizzes.length +
                item.completed_scorm.length;
              percent = Math.floor((completedCount / totalCount) * 100);
            }
            if (percent > 100) {
              percent = 100;
            }
            return percent;
          };
          if (item.track) {
            return (
              <GridItem key={item.track._id}>
                <DashboardCard
                  id={item.track._id}
                  title={item.track.name}
                  type={"track"}
                  imageUrl={item.track.banner_image}
                  progress={calculateCompletePercent()}
                  trackCompletedDate={item.completed_date}
                  originRoute="/dashboard"
                />
              </GridItem>
            );
          }
          return (
            <GridItem key={index} paddingY={4}>
              This Item could not be displayed
            </GridItem>
          );
        })}
      </>
    );
  };

  if (isLoading) return <Loading />;

  if (isError) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="right">
        <ToggleButton
          leftLabel={t("dashboard.tracks.active_tabName")}
          rightLabel={t("dashboard.tracks.completed_tabName")}
          checked={isChecked}
          setIsChecked={setIsChecked}
        />
      </Flex>

      <Grid gap={4}>
        {isChecked ? (
          <ContentList items={completed} />
        ) : (
          <ContentList items={open} />
        )}
      </Grid>
    </>
  );
}
