import type { ArchiveContentParams } from "@/client/services/api/admin/content-authoring/AuthoringSharedService";
import AuthoringSharedService from "@/client/services/api/admin/content-authoring/AuthoringSharedService";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useArchiveContent = () => {
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  const archiveMutation = useMutation({
    mutationFn: (variables: ArchiveContentParams) =>
      AuthoringSharedService.archiveContent(variables),
    onError: (error) => {
      console.error("Error archiving content", error);

      setToast({
        show: true,
        status: "error",
        title: t("authoring.archiveError"),
      });
    },
  });

  return {
    archiveMutation,
  };
};
