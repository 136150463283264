import { Avatar, Button, Flex, Input } from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";

import AdminElevatedBox from "../../layout/AdminElevatedBox";
import AnalyzeService from "@/client/services/api/AnalyzeService";
import AvatarUploadForm from "@/client/components/shared/forms/AvatarUploadForm";
import HeadingWithTooltip from "../HeadingWithTooltip";
import InputWithToolTip from "../InputWithTooltip";
import { useAvatarStore } from "@/client/services/state/shared/avatarStore";
import { useRef } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import { useUploadImage } from "@/client/services/hooks/shared/images/useUploadImage";

export default function UserAvatar() {
  const { t } = useTranslation();
  const { setValue, control } = useFormContext();
  const { setToast } = useToastStore();
  const avatarRef = useRef<HTMLInputElement>(null);

  const { avatar, setAvatar, reset } = useAvatarStore();

  const formImage = useWatch({ control, name: "image" });

  const handleAvatarInputClick = () => {
    avatarRef.current!.click();
  };

  const handleAvatarRemove = () => {
    setValue("image", "");
    reset();
  };

  // TODO: Refactor into useUploadImage hook for use in other components
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({
        show: true,
        status: "error",
        title: t("global.imageUploadError"),
      });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = "";

    setAvatar({
      ...avatar,
      img: fileObject,
      editorModalOpen: true,
    });
  };

  const { uploadImageMutation } = useUploadImage();

  const { status } = uploadImageMutation;

  const createUserAvatar = (cloudfrontLink: string) => {
    setValue("image", cloudfrontLink);
    reset();
    AnalyzeService.recordAvatars(cloudfrontLink);
  };

  return (
    <AdminElevatedBox>
      <HeadingWithTooltip
        title={t("edit.useradd.tab.avatar")}
        helpText={t("edit.general.headlines_addImages")}
      />

      <Flex direction="row" gap={8} alignItems="center">
        <AvatarUploadForm
          isLoading={status === "loading"}
          handleUpload={(file) =>
            uploadImageMutation.mutate({
              file,
              errorMessage: t("global.avatarUploadError"),
              successActions: (cloudfrontLink) =>
                createUserAvatar(cloudfrontLink),
            })
          }
          uploadElement={
            <>
              <Avatar src={formImage} />

              <Flex direction="column">
                <Button variant="ghost" onClick={handleAvatarInputClick}>
                  {typeof avatar.img === "string" && !avatar.img.length
                    ? t("edit.general.avatar_upload")
                    : t("edit.general.avatar_replace")}
                </Button>

                <Input
                  type="file"
                  display="none"
                  ref={avatarRef}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e)}
                />

                <Button
                  variant="ghost"
                  color="red"
                  onClick={handleAvatarRemove}
                >
                  {t("edit.general.avatar_remove")}
                </Button>
              </Flex>
            </>
          }
        />

        <InputWithToolTip
          name="image"
          label={t("edit.useradd.imageUrl")}
          placeholder={`${t("global.forms.labels_avatarLink")}`}
          tooltipText={t("global.forms.labels_avatarLink")}
        />
      </Flex>
    </AdminElevatedBox>
  );
}
