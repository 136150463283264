import { Avatar, Stack, Text } from "@chakra-ui/react";

import HtmlRenderer from "@/client/components/html/HtmlRenderer";
import { WorkshopInstance } from "@/client/types/content/Workshop";

interface WorkshopInstanceHostDetailsProps {
  instance: WorkshopInstance;
}

export default function WorkshopInstanceHostDetails({
  instance,
}: WorkshopInstanceHostDetailsProps) {
  return (
    <Stack
      className="facilitator-details"
      alignItems="center"
      backgroundColor="blackAlpha.50"
      padding={8}
    >
      <Avatar
        size="xl"
        backgroundColor="blackAlpha.300"
        color="blackAlpha.500"
        name={instance.host.name}
        src={instance.host.image}
      />

      <Text fontWeight="semibold">{instance.host.name}</Text>

      <HtmlRenderer html={instance.host.bio} />
    </Stack>
  );
}
