import type { Content } from "@/client/types/admin/content-authoring/tracks/imported-content/MasterclassSearchData";
import ImportedContentSearch from "./ImportedContentSearch";
import ImportedContentSearchCard from "./ImportedContentSearchCard";
import ImportedContentService from "@/client/services/api/ImportedContentService";
import { Stack } from "@chakra-ui/react";
import { useAuthorMasterclass } from "@/client/services/hooks/admin/authoring/courses/imported-content/useAuthorMasterclass";
import { useDebounceValue } from "usehooks-ts";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function MasterclassSearch() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useDebounceValue("", 300);
  const { processMasterclassImport } = useAuthorMasterclass();

  const pageSize = 20;

  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: ["adminSearchMasterclassContent", pageSize, searchTerm],
    queryFn: ({ pageParam = 0 }) =>
      ImportedContentService.adminSearchMasterclassContent(
        pageSize,
        pageParam,
        searchTerm
      ),
    getNextPageParam: (lastPage) =>
      lastPage.data.content.length === pageSize
        ? lastPage.offset + pageSize
        : undefined,
  });

  const getDuration = (item: Content) => {
    if (!item || !item.duration_in_seconds) {
      return;
    }

    const time = item.duration_in_seconds;

    if (time && time < 60) {
      return t("fileviewer.less_than_a_minute");
    }

    let mins = Math.floor(time / 60);

    const hrs = Math.floor(time / 3600);
    mins = Math.floor((time % 3600) / 60);

    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + (hrs === 1 ? " hr" : " hrs") + " ";
    }

    ret += "" + mins + (mins === 1 ? " min" : " mins");

    return " - " + ret;

    return "";
  };

  return (
    <ImportedContentSearch
      heading={t("authoring.courses.importedContent.masterclass.importCourse")}
      searchPlaceholder={t(
        "authoring.courses.importedContent.masterclass.searchPlaceholder"
      )}
      setSearchTerm={setSearchTerm}
      status={status}
      isFetchingNextPage={isFetchingNextPage}
      canFetchNextPage={
        data?.pages[data.pages.length - 1].data.content.length === pageSize
      }
      fetchNextPage={fetchNextPage}
      renderNoResults={data?.pages && data.pages[0].data.content.length === 0}
      renderResults={data?.pages && data.pages[0].data.content.length > 0}
      renderEndOfResults={
        data?.pages[data.pages.length - 1].data.content.length !== pageSize
      }
      searchResultsElement={
        data?.pages && data.pages[0].data.content.length > 0 ? (
          <>
            {data.pages.map((page) => (
              <Stack key={page.data.content[0].title} spacing={4}>
                {page.data.content.map((item) => (
                  <ImportedContentSearchCard
                    onClick={() => processMasterclassImport(item)}
                    backgroundImage={item.image}
                    title={item.title}
                    author={
                      item.instructors.length > 0
                        ? item.instructors[0].name
                        : undefined
                    }
                    duration={getDuration(item)}
                    description={item.summary}
                  />
                ))}
              </Stack>
            ))}
          </>
        ) : undefined
      }
    />
  );
}
