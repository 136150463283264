import { Box } from "@chakra-ui/react";
import MeetsService from "@/client/services/api/integrations/MeetsService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function MeetsRedirect() {
  useDocumentTitle("You are being redirected");
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const user = authConfig.user;
  const navigate = useNavigate();

  const params: any = new URLSearchParams(window.location.search);
  let parsedParams: any = {};

  params.forEach((a: any, b: any) => {
    parsedParams[b] = a;
  });

  if (parsedParams.code && parsedParams.code !== "null") {
    const { isLoading, isError, data, error } = useQuery(
      ["meets-submit-code"],
      async () => await MeetsService.submitCode(parsedParams.code),
      {
        onSuccess: (data) => {
          setToast({
            show: true,
            status: "success",
            title:
              "Integration successfull! You can now create Google Meet links within workshops.",
          });
          navigate("/settings");
        },
        onError: (e) => {
          setToast({
            show: true,
            status: "error",
            title: "Unable to integrate with Google at this time.",
          });
          navigate("/settings");
        },
      }
    );
  }

  if (parsedParams.error && parsedParams.error !== "null") {
    if (params.error === "access_denied") {
      setToast({
        show: true,
        status: "error",
        title: "You have denied access to your Google Account.",
      });
      navigate("/settings");
    }
    setToast({
      show: true,
      status: "error",
      title: "Unable to integrate with Google at this time.",
    });
    navigate("/settings");
  }

  return <></>;
}
