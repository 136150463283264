import { IconButton, Tooltip, useToken } from "@chakra-ui/react";

import { Users } from "@/client/components/icons/ContinuIcons";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PeopleButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [brandPrimary] = useToken("colors", ["brand.primary"]);

  return (
    <Tooltip hasArrow zIndex={9999} label={t("explore.viewType.peopleSearch")}>
      <span>
        <IconButton
          backgroundColor={hexToRGBA(brandPrimary, 0.2)}
          zIndex={9999}
          aria-label={t("explore.viewType.peopleSearch")}
          borderRadius="full"
          size="sm"
          fontSize="xl"
          icon={<Users color={hexToRGBA(brandPrimary, 0.6)} />}
          onClick={() => navigate("/people")}
          _hover={{ backgroundColor: hexToRGBA(brandPrimary, 0.1) }}
        />
      </span>
    </Tooltip>
  );
}
