import SurveyService from "@/client/services/api/SurveyService";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const useAuthoringSurveySearch = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>("");

  const { isLoading, isFetching, isError, data } = useQuery({
    enabled: !!searchTerm,
    queryKey: ["surveys", searchTerm],
    queryFn: () => SurveyService.searchSurveys(searchTerm),
    select: (surveyData) => [
      {
        title: "",
        options: surveyData.map((survey) => ({
          id: survey._id,
          title: survey.title,
        })),
      },
    ],
  });

  return {
    searchTerm,
    setSearchTerm,
    isLoading,
    isFetching,
    isError,
    data,
  };
};
