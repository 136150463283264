import {
  Box,
  Button,
  Link as ChakraLink,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import ZendeskWidget from './ZendeskWidget';
import { useAuthStore } from '@/client/services/state/authStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SupportModalProps = {
  onClose: () => void;
  supportModalOpen: boolean;
  setSupportModalOpen: (supportModalOpen: boolean) => void;
};

export default function SupportModal({
  onClose,
  supportModalOpen,
  setSupportModalOpen,
}: SupportModalProps) {
  const [zendeskOpen, setZendeskOpen] = useState<boolean>(false);
  const { authConfig } = useAuthStore();
  const { config } = useConfigStore();
  const { t } = useTranslation();
  const { company, user } = authConfig;

  return (
    <>
      {zendeskOpen && (
        <ZendeskWidget user={user} company={company} setZendeskOpen={setZendeskOpen} />
      )}

      <Modal
        isOpen={supportModalOpen}
        isCentered
        size="3xl"
        onClose={() => setSupportModalOpen(false)}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <Flex alignItems="center">
              {company.feature_flags.enable_continu_support && (
                <Box flex={1} padding={4} textAlign="center">
                  <Image
                    src="/public/images/supportChat.svg"
                    width={100}
                    height={100}
                    margin="0 auto"
                    marginBottom={4}
                  />

                  <Text>{t('hmenu.viewTheSupportCenter')}</Text>

                  <ChakraLink
                    href={config.supportCenterBrandLocation}
                    onClick={() => {
                      setSupportModalOpen(false);
                      onClose();
                    }}
                    isExternal
                  >
                    <Button
                      marginY={4}
                      paddingY={4}
                      paddingX={8}
                      _hover={{
                        backgroundColor: 'brand.tertiary',
                        cursor: 'pointer',
                      }}
                    >
                      {t('hmenu.visitSupportCenter')}
                    </Button>
                  </ChakraLink>
                </Box>
              )}

              {config && config.ticketingBrand && config.ticketingBrand.customSupport ? (
                <Box flex={1} padding={10} textAlign="center">
                  <Image
                    src="/public/images/supportBook.svg"
                    width={100}
                    height={100}
                    margin="0 auto"
                    marginBottom={4}
                  />

                  <Text>{t(config.ticketingBrand.caption)}</Text>

                  <ChakraLink
                    href={config.ticketingBrand.location}
                    onClick={() => {
                      setSupportModalOpen(false);
                      onClose();
                    }}
                    isExternal
                  >
                    <Button
                      marginY={4}
                      paddingY={4}
                      paddingX={8}
                      _hover={{
                        backgroundColor: 'brand.tertiary',
                        cursor: 'pointer',
                      }}
                    >
                      {config.ticketingBrand.link}
                    </Button>
                  </ChakraLink>
                </Box>
              ) : (
                <Box flex={1} padding={10} textAlign="center">
                  <Image
                    src="/public/images/supportBook.svg"
                    width={100}
                    height={100}
                    margin="0 auto"
                    marginBottom={4}
                  />

                  <Text>{t('hmenu.contactOurSupportTeam')}</Text>

                  <Button
                    marginY={4}
                    paddingY={4}
                    paddingX={8}
                    _hover={{
                      backgroundColor: 'brand.tertiary',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSupportModalOpen(false);
                      onClose();
                      setZendeskOpen(true);
                    }}
                  >
                    {t('hmenu.openATicket')}
                  </Button>
                </Box>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
