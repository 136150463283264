export const calculateStars = (averageRating: number) => {
  let stars = [];
  let simpleRating = averageRating / 20;

  for (let i = 0; i < Math.ceil(simpleRating); i++) {
    if (!Number.isInteger(simpleRating) && i === Math.ceil(simpleRating - 1)) {
      stars.push({ filled: true, half: true });
    } else {
      stars.push({ filled: true });
    }
  }

  if (stars.length < 5) {
    const count = 5 - stars.length;

    for (let i = 0; i < count; i++) {
      stars.push({ filled: false });
    }
  }

  return stars;
};
