import { HStack, Tag, TagLabel, TagRightIcon } from "@chakra-ui/react";

import { Close } from "@/client/components/icons/ContinuIcons";
import Combobox from "../Combobox";
import UserGroupSearchService from "@/client/services/api/admin/segmentation/UserGroupSearchService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

/*
 * Used in the following:
 * assign-content.html
 * user-manager.html
 * users-selected.html
 * workshop-assign-wait.html
 * assignments-manager.html
 * distribute-assignment-template.html
 * manager-assignment.html
 * manager-users.html
 * edit-report.html
 *
 */

interface UserGroupSearchProps {
  label: string;
  userFilter: string;
  includeSuspendedUsers: boolean;
  selectedItems: any[];
  setSelectedItems: any;
}

export default function UserGroupSearch({
  label,
  userFilter,
  includeSuspendedUsers,
  selectedItems,
  setSelectedItems,
}: UserGroupSearchProps) {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { watch, setValue } = useFormContext();
  const searchTerm = watch("groupUserSearchTerm");

  const { data, isFetching } = useQuery({
    enabled: !!searchTerm,
    queryKey: [
      "user-group-search",
      searchTerm,
      userFilter,
      includeSuspendedUsers,
      company.groups,
    ],
    queryFn: () =>
      UserGroupSearchService.search(
        searchTerm,
        userFilter,
        includeSuspendedUsers,
        company.groups
      ),
  });

  return (
    <>
      <Combobox
        label={label}
        items={data || []}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isLoading={isFetching}
        searchTerm={searchTerm}
        setSearchTerm={(input: string) =>
          setValue("groupUserSearchTerm", input)
        }
      />

      {selectedItems.length > 0 && (
        <HStack>
          {selectedItems.map((item) => (
            <Tag variant="admin" key={item._id}>
              <TagLabel>
                {item.legacyName || item.name || item.full_name || item.label}
              </TagLabel>

              <TagRightIcon
                boxSize={3}
                as={Close}
                _hover={{ cursor: "pointer" }}
                onClick={() =>
                  setSelectedItems(
                    selectedItems.filter((i) => i._id !== item._id)
                  )
                }
              />
            </Tag>
          ))}
        </HStack>
      )}
    </>
  );
}
