import type {
  VideoDraftData,
  VideoSubmissionData,
} from "@/client/types/admin/content-authoring/video/VideoSubmissionData";
import v3ApiService, { v3ApiClient } from "../../clients/v3ApiClient";

import AnalyzeService from "../../AnalyzeService";
import type { RESTVideoRetreivalData } from "@/client/types/admin/content-authoring/video/RESTVideoRetreivalData";
import type { S3Policy } from "@/client/types/aws/s3Policy";
import type { VttFile } from "../../graphql/gql/graphql";
import axios from "axios";
import { learnApiClient } from "../../clients/learnApiClient";
import { learnV4ApiClient } from "../../clients/learnV4ApiClient";

const saveInitialDraft = async (
  payload: VideoDraftData
): Promise<RESTVideoRetreivalData> => {
  const response = await learnApiClient.post("media/videos", payload);

  return response.data;
};

const uploadVideo = async (
  file: File,
  progressCallback: (percentCompleted: number) => void
): Promise<{ cloudFrontLink: string; s3ObjectKey: string }> => {
  const response = await v3ApiService
    .getS3VideoPolicy(file.type, file.name)
    .then(async (policy: S3Policy) => {
      const {
        S3Policy,
        S3Signature,
        CloudFrontLink,
        Bucket,
        Key,
        ContentType,
        AWSAccessKeyId,
      } = policy;

      const formData = new FormData();

      formData.append("key", Key);
      formData.append("Content-Type", ContentType);
      formData.append("AWSAccessKeyId", AWSAccessKeyId);
      formData.append("success_action_status", "201");
      formData.append("policy", S3Policy);
      formData.append("signature", S3Signature);
      formData.append("file", file);

      await axios.post(`https://${Bucket}.s3.amazonaws.com/`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          progressCallback(percentCompleted);
        },
      });

      return { cloudFrontLink: CloudFrontLink, s3ObjectKey: Key };
    });

  return response;
};

const startVideoTranscodeJob = async (
  contentId: string,
  s3ObjectKey: string
) => {
  const response = await v3ApiClient.post("transcoding-job", {
    content_type: "video",
    contentid: contentId,
    s3ObjectKey,
    subcontentid: "", // TODO: If in track, this becomes video id and contentid becomes track id
  });

  return response.data;
};

const transcodingStatus = async (contentId: string) => {
  const response = await learnV4ApiClient.get<
    Pick<
      RESTVideoRetreivalData,
      | "transcoding"
      | "_id"
      | "privacy_locations"
      | "privacy_departments"
      | "privacy_teams"
      | "privacy_org_levels"
      | "privacy_grades"
      | "privacy_groups"
    >
  >(`admin-content/${contentId}`, {
    params: {
      types: "videos",
      fields: "transcoding",
    },
  });

  return {
    transcoding: response.data.transcoding,
    timeStamp: new Date(),
  };
};

const submitVideo = async (
  payload: Omit<VideoSubmissionData, "createdAt">
): Promise<RESTVideoRetreivalData> => {
  const response = await learnApiClient.post(
    `media/${payload._id}?fields=title,description,type,author,duration,tags,images,link,source,source_id,video_type,type,user,linked_category,explore_hide,draft,approval_required,approved,last_approved_by,last_approved_by,privacy_collaborators,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,private,cover_justification,featured_justification,vtt_files,rating_configuration,rating_configuration,allow_comments,use_generative_ai`,
    payload
  );

  await AnalyzeService.recordContentModifications({
    document: payload._id,
    type: "video",
  });

  return response.data;
};

const uploadVtt = async (
  file: File,
  progressCallback: (percentCompleted: number) => void
): Promise<{ cloudfrontLink: string }> => {
  const response = await v3ApiService
    .getS3FilePolicy(file.type, file.name)
    .then(async (policy) => {
      const {
        CloudFrontLink,
        S3Policy,
        S3Signature,
        Bucket,
        Key,
        ContentType,
        AWSAccessKeyId,
      } = policy;

      const formData = new FormData();

      formData.append("key", Key);
      formData.append("Content-Type", ContentType);
      formData.append("AWSAccessKeyId", AWSAccessKeyId);
      formData.append("success_action_status", "201");
      formData.append("policy", S3Policy);
      formData.append("signature", S3Signature);
      formData.append("file", file);

      await axios.post(`https://${Bucket}.s3.amazonaws.com/`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          progressCallback(percentCompleted);
        },
      });

      return { cloudfrontLink: CloudFrontLink };
    });

  return response;
};

const AuthoringVideoService = {
  uploadVideo,
  saveInitialDraft,
  startVideoTranscodeJob,
  transcodingStatus,
  submitVideo,
  uploadVtt,
};

export default AuthoringVideoService;
