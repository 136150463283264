import {
  Button,
  HStack,
  Text,
  Textarea,
  VStack,
  useToken,
} from "@chakra-ui/react";
import { Star, StarFilled } from "@/client/components/icons/ContinuIcons";
import { useMutation, useQuery } from "@tanstack/react-query";

import Loading from "@/client/components/media/Loading";
import { RatingConfiguration } from "@/client/types/content/RatingConfiguration";
import RatingService from "@/client/services/api/RatingService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface ContentRatingInputProps {
  contentId: string;
  contentType: string;
  ratingConfiguration: RatingConfiguration;
}

export default function ContentRatingInput({
  contentId,
  contentType,
  ratingConfiguration,
}: ContentRatingInputProps) {
  const { authConfig } = useAuthStore();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const { user } = authConfig;
  const [rating, setRating] = useState(0);
  const [hovered, setHovered] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [yellow, gray] = useToken("colors", ["yellow.400", "gray.400"]);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["rating", contentId, user._id],
    queryFn: async () =>
      RatingService.getUserContentRating(contentId, user._id),
  });

  const addRating = useMutation({
    mutationFn: async () =>
      RatingService.postRating(
        user._id,
        contentId,
        contentType,
        feedback,
        rating,
        ratingConfiguration.total_value
      ),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: "Thanks for your feedback",
      });
      refetch();
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: "There was an error submitting your feedback",
      }),
  });

  if (isLoading) return <Loading />;

  if (isError) return null;

  if (data && data.length > 0) {
    return (
      <VStack spacing={4} marginY={12}>
        <Text as="b">{t("rating.yourRating")}</Text>

        <HStack spacing={2}>
          {[...Array(5)].map((_, index) => {
            index += 1;

            return index <= data[0].rating ? (
              <StarFilled key={index} color={yellow} />
            ) : (
              <Star key={index} color={gray} />
            );
          })}
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack spacing={4} marginY={12}>
      <Text as="b">{t("rating.pleaseProvideFeedback")}</Text>

      <HStack spacing={2}>
        {[...Array(5)].map((_, index) => {
          index += 1;

          return index <= (hovered || rating) ? (
            <StarFilled
              key={index}
              color={yellow}
              _hover={{ cursor: "pointer" }}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(rating)}
            />
          ) : (
            <Star
              key={index}
              color={gray}
              _hover={{ cursor: "pointer" }}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(rating)}
            />
          );
        })}
      </HStack>

      {rating > 0 && (
        <>
          {ratingConfiguration.allow_feedback && (
            <Textarea
              resize="none"
              placeholder={`${t("rating.anythingElse")}`}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          )}

          <Button
            isLoading={addRating.isLoading}
            onClick={() => addRating.mutate()}
          >
            {t("global.forms.labels_submit")}
          </Button>
        </>
      )}
    </VStack>
  );
}
