import { useEffect, useState } from "react";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import ContentService from "@/client/services/api/ContentService";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import ImportedContentService from "@/client/services/api/ImportedContentService";
import ImportedContentView from "@/client/components/content/courses/ImportedContentView";
import Loading from "@/client/components/media/Loading";
import NotAuthorized from "@/client/components/data-display/content/NotAuthorized";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCheckContentAuth } from "@/client/services/hooks/content/useCheckContentAuth";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSessionTracking } from "@/client/services/hooks/useSessionTracking";

interface ImportedContentProps {
  inJourney?: boolean;
  journeyId?: string | null;
  importedContentId?: string | null;
}

export default function ImportedContent({
  inJourney = false,
  journeyId = null,
  importedContentId = null,
}: ImportedContentProps) {
  const { id } = useParams();
  const { sendSessionTime } = useSessionTracking(id, "imported_content");
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { allowView } = useCheckContentAuth();
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();

  const contentId = importedContentId || id;

  const { isError, isLoading, data } = useQuery({
    enabled: !!contentId,
    queryKey: ["imported-content", contentId, user._id],
    queryFn: () =>
      ImportedContentService.getImportedContent(contentId, user._id),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    return () => {
      sendSessionTime();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data.content);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  useEffect(() => {
    if (isAllowed && data) {
      AnalyzeService.postViews(contentId, "imported_content");
      ContentService.postViews(contentId, "imported_content");

      trackEvent("imported_content_viewed", {
        content: data.content._id,
        content_type: "imported_content",
        title: data.content.title,
      });

      if (inJourney && data.progressPercent === 100) {
        const contexts = [{ key: "journey", value: journeyId }];

        ContentService.completeContent(contentId, "imported_content", contexts);
      }
    }
  }, [isAllowed]);

  useDocumentTitle(data ? data.content.title : "Imported Content", true);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get imported content" />;

  if (isAllowed === false) return <NotAuthorized />;

  return <ImportedContentView data={data} contentId={contentId} />;
}
