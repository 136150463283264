import { Box, Container, Link, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import CommentList from "../../components/lists/CommentList";
import ContentContainer from "../../components/layout/ContentContainer";
import ContentHeader from "../../components/layout/ContentHeader";
import ContentRatingInput from "@/client/components/data-display/ratings/ContentRatingInput";
import ContentService from "@/client/services/api/ContentService";
import ContentStats from "../../components/data-display/content/ContentStats";
import { Download } from "@/client/components/icons/ContinuIcons";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import HtmlRenderer from "../../components/html/HtmlRenderer";
import { InView } from "react-intersection-observer";
import Loading from "@/client/components/media/Loading";
import NotAuthorized from "@/client/components/data-display/content/NotAuthorized";
import PdfView from "@/client/components/files/PdfView";
import PopupSurveyLayout from "@/client/components/overlay/PopupSurvey";
import Recommendations from "@/client/components/layout/Recommendations";
import ViewActions from "@/client/components/overlay/ViewActions";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useCheckContentAuth } from "@/client/services/hooks/content/useCheckContentAuth";
import { useCheckSurveyCompletion } from "@/client/services/hooks/useCheckSurveyCompletion";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { useFileStore } from "@/client/services/state/routeState/fileStore";
import { useQuery } from "@tanstack/react-query";
import { useSessionTracking } from "@/client/services/hooks/useSessionTracking";
import { useTranslation } from "react-i18next";
import { usePartnerStore } from "@/client/services/state/partnerStore";

interface ContentFileProps {
  trackId?: string;
  journeyId?: string;
  contentId: string;
  needsContextSelection: boolean;
  sendCompleteContent: Function;
}

export default function ContentFile({
  trackId,
  journeyId,
  contentId,
  needsContextSelection,
  sendCompleteContent,
}: ContentFileProps) {
  const { t } = useTranslation();
  const { allowView } = useCheckContentAuth();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const { partner } = usePartnerStore();
  const [isPDF, setIsPDF] = useState<boolean>(false);
  const [isBoxLink, setIsBoxLink] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [allowRecommendations, setAllowRecommendations] =
    useState<boolean>(false);
  const [renderRecommendations, setRenderRecommendations] =
    useState<boolean>(false);

  const pagePositions = useFileStore((state) => state.pagePositions);
  const setPagePositions = useFileStore((state) => state.setPagePositions);

  const { sendSessionTime } = useSessionTracking(contentId, "file");

  const { isLoading, isError, data } = useQuery({
    enabled: !!contentId,
    queryKey: ["file", contentId],
    queryFn: () => ContentService.getFile(contentId),
    onSuccess: (fileData) => {
      document.title = fileData.title;
      checkShowPopupSurvey(fileData, true, !!trackId || !!journeyId);
      if (fileData && fileData?.link?.indexOf(".pdf") !== -1) {
        setIsPDF(true);
      }
      if (
        data?.link?.indexOf("box.com") !== -1 ||
        (data?.source_v2 && data?.source_v2.indexOf("box.com") !== -1)
      ) {
        setIsBoxLink(true);
      }
      trackEvent("file_viewed", {
        content: fileData._id,
        content_type: "file",
        title: fileData.title,
      });
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (!trackId && !journeyId) {
      setAllowRecommendations(true);
    }

    if (!needsContextSelection && isAllowed) {
      sendCompleteContent();
      AnalyzeService.postViews(contentId, "file");
      ContentService.postViews(contentId, "file");
    }
    return () => {
      sendSessionTime();
    };
  }, [needsContextSelection, isAllowed]);

  useEffect(() => {
    const record = pagePositions?.filter((item) => item.id === contentId)[0];

    if (record) {
      setCurrentPage(record.page);
    }
  }, []);

  const recordPage = (page: number) => {
    if (contentId) {
      setPagePositions(contentId, page, pagePositions);
    }
  };

  useDocumentTitle(data ? data.title : "File", true);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get file" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <>
      {isAllowed && (
        <>
          {!trackId && !journeyId && (
            <ContentHeader
              contentType="file"
              contentTitle={data.title}
              contentId={data._id}
              withBorder
              allowComments={data.allow_comments}
            />
          )}

          <ContentContainer inJourney={!!journeyId}>
            <ContentStats
              author={data.author}
              duration={data.duration}
              averageRating={data.average_rating}
              ratingConfiguration={data.rating_configuration}
            />

            <Text variant="contentheader">{data.title}</Text>
          </ContentContainer>

          <Container maxWidth="container.lg" position="relative">
            {data.embed && (
              <iframe
                title="file-viewer"
                style={{ height: "100vh", width: "100%", minHeight: "500px" }}
                view-file-only="true"
                src={data.link}
                seamless
                allowFullScreen
              />
            )}

            {isBoxLink && (
              <>
                <Box
                  background="white"
                  width="100%"
                  height="48px"
                  zIndex="3"
                  position="absolute"
                  left={0}
                  top={0}
                />
                <iframe
                  title="box-viewer"
                  style={{
                    height: "100vh",
                    width: "100%",
                    zIndex: "2",
                    position: "relative",
                    minHeight: "500px",
                  }}
                  view-file-only="true"
                  src={data.source_v2}
                  seamless
                  allowFullScreen
                />
              </>
            )}

            {isPDF && !isBoxLink && !data.source_v2 && (
              <PdfView
                source={data.link}
                recordPage={recordPage}
                recoveredPage={currentPage}
                enableRecommendations={!trackId && !journeyId}
                setRenderRecommendations={setRenderRecommendations}
              />
            )}

            {data.downloadable && (
              <Box textAlign="right" paddingTop={4} fontSize="smaller">
                <Link href={data.link}>
                  <Download /> {t("global.actions.download")}
                </Link>
              </Box>
            )}
          </Container>

          <ContentContainer>
            <HtmlRenderer html={data.description} width="700px" />

            {!isPDF && allowRecommendations && (
              <InView
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    setRenderRecommendations(true);
                  }
                }}
              />
            )}

            {renderRecommendations && <Recommendations contentId={contentId} />}

            {data.rating_configuration &&
              data.rating_configuration.allow_rating && (
                <ContentRatingInput
                  contentId={data._id}
                  contentType="Media"
                  ratingConfiguration={data.rating_configuration}
                />
              )}

            {data.allow_comments && !journeyId && !partner._id && (
              <CommentList
                contentId={contentId}
                contentType="media"
                contentTitle={data.title}
              />
            )}
          </ContentContainer>

          {!trackId && !journeyId && (
            <ViewActions
              buttons={["gototop"]}
              bottomOffset={showPopup ? 10 : 0}
            />
          )}

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.surveys[0].survey_id}
              contentId={data._id}
              contentType="file"
            />
          )}
        </>
      )}
    </>
  );
}

ContentFile.defaultProps = {
  trackId: "",
  journeyId: "",
};
