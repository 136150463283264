import type { FullUdemyCourseData } from "@/client/services/api/ImportedContentService";
import ImportedContentService from "@/client/services/api/ImportedContentService";
import { useAuthStore } from "@/client/services/state/authStore";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEditInstructors } from "@/client/services/hooks/admin/authoring/courses/useEditInstructors";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const useAuthorUdemy = () => {
  const { id } = useParams();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setValue, watch } = useFormContext();
  const { setTabIndex } = useCreateStore();
  const { createInstructorMutation } = useEditInstructors();

  const { tags, learnItems } = watch();

  const processUdemyImport = async (data: FullUdemyCourseData) => {
    const { course, integrationParams } = data;

    if (!id) setValue("creator", user._id);

    setValue("vendor", "udemy");

    setValue("vendorLink", course.url);

    setValue(
      "vendorFullLink",
      `https://${integrationParams.portal_name}.udemy.com${course.url}`
    );

    setValue("vendorContentId", course.id);

    setValue("title", course.title);

    setValue("description", course.headline);

    if (course.context_info) {
      if (course.context_info.category && course.context_info.category.title) {
        setValue("tags", [...tags, course.context_info.category.title]);
      }

      if (course.context_info.label && course.context_info.label.display_name) {
        setValue("tags", [...tags, course.context_info.label.display_name]);
      }
    }

    if (course.visible_instructors && course.visible_instructors.length) {
      await createInstructorMutation
        .mutateAsync({
          name: course.visible_instructors[0].display_name,
          bio: "",
          image:
            course.visible_instructors[0].image_200_H ||
            course.visible_instructors[0].image_100x100 ||
            "",
          reusable: false,
        })
        .then((instructorId) => {
          setValue("instructors", [
            {
              id: instructorId,
              name: course.visible_instructors[0].display_name,
              image:
                course.visible_instructors[0].image_200_H ||
                course.visible_instructors[0].image_100x100 ||
                "",
            },
          ]);
        });
    }

    if (course.objectives && course.objectives.length) {
      setValue("learnItems", course.objectives.slice(0, 3));
    }

    if (
      !learnItems.length &&
      course.objectives_summary &&
      course.objectives_summary.length
    ) {
      setValue("learnItems", course.objectives_summary.slice(0, 3));
    }

    if (course.image_480x270) {
      setValue("image", course.image_480x270);
      setValue("bannerImage", course.image_480x270);
    }

    if (course.image_750x422) {
      setValue("image", course.image_750x422);
      setValue("bannerImage", course.image_750x422);
    }

    if (course.content_length_video) {
      setValue("duration", course.content_length_video);
    }

    setTabIndex(1);
  };

  const getUdemyCourseById = useMutation({
    mutationFn: (courseId: number) =>
      ImportedContentService.getUdemyCourseById(courseId),
    onSuccess: (data) => processUdemyImport(data),
  });

  return {
    getUdemyCourseById,
  };
};
