import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

import { AngleDown } from "@/client/components/icons/ContinuIcons";
import { ChoiceOption } from "@/client/types/content/assessments/Assessment";

interface SelectQuestionProps {
  selected: string[];
  answers: ChoiceOption[];
  handleSelect: (id: string) => void;
}

export default function SelectQuestion({
  selected,
  answers,
  handleSelect,
}: SelectQuestionProps) {
  return (
    <Box marginTop={4}>
      <Menu>
        <MenuButton as={Button} variant="menu" rightIcon={<AngleDown />}>
          {selected.length > 0 && selected[0] !== null
            ? answers.find(
                (item) => item.id === selected[0] || item._id === selected[0]
              )?.copy
            : "Select your answer"}
        </MenuButton>

        <MenuList>
          {answers.map(({ id, _id, copy }) => (
            <MenuItem
              key={id || _id}
              value={id || _id}
              onClick={() => handleSelect(id || _id)}
            >
              {copy}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
}
