import { useNavigate, useParams } from "react-router-dom";

import ContentAudienceForm from "@/client/components/admin/create/content/forms/audience/ContentAudienceForm";
import ContentPublishForm from "@/client/components/admin/create/content/forms/ContentPublishForm";
import ContentSettingsForm from "@/client/components/admin/create/content/forms/settings/ContentSettingsForm";
import CreateTabs from "@/client/components/admin/create/shared/tabs/CreateTabs";
import ErrorAlert from "@/client/components/data-display/ErrorAlert";
import Loading from "@/client/components/media/Loading";
import type { VideoFormData } from "@/client/routes/admin/create/content/EditVideo";
import VideoGeneralDetailsForm from "@/client/components/admin/create/content/forms/videos/VideoGeneralDetailsForm";
import { useAuthStore } from "@/client/services/state/authStore";
import { useAuthorVideo } from "@/client/services/hooks/admin/authoring/videos/useAuthorVideo";
import { useCheckContentEditable } from "@/client/services/hooks/admin/authoring/useCheckContentEditable";
import { useCreateNavigationBlocker } from "@/client/services/hooks/admin/authoring/useCreateNavigationBlocker";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";
import { useTranslation } from "react-i18next";
import ContentCreateTabControls from "@/client/components/admin/create/content/tabs/ContentCreateTabControls";

export default function EditVideoForm() {
  useCreateNavigationBlocker();
  const { id } = useParams();
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { watch, formState } = useFormContext<VideoFormData>();
  const { isValid } = formState;
  const { checkContentEditable } = useCheckContentEditable();
  const {
    setCanAdvance,
    tabIndex,
    setTabIndex,
    setContentType,
    setContentTitle,
  } = useCreateStore();
  const { trackState, setTrackState } = useTrackStateStore();
  const navigate = useNavigate();

  useEffect(() => {
    setContentType("video");
  }, []);

  const { submitVideoMutation, getVideoForEditor } = useAuthorVideo(id);

  const title = watch("title");
  const author = watch("author");
  const description = watch("description");
  const link = watch("link");
  const isDraft = watch("draft");
  const isTranscoding = watch("transcoding");

  useEffect(() => {
    setContentTitle(title as string);
    setTabIndex(0);
  }, [title]);

  const canAdvance =
    title !== "" && author !== "" && description !== "" && link !== "";
  const canPublish = formState.isValid && link !== "" && !isTranscoding;

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  const tabOptions = [
    {
      label: t("authoring.video.label"),
      content: <VideoGeneralDetailsForm canAdvance={canAdvance} />,
      isDisabled: false,
    },
    {
      label: t("authoring.tabs.audience"),
      content: <ContentAudienceForm />,
      isDisabled: !isValid || !canAdvance,
    },
    {
      label: t("authoring.tabs.settings"),
      content: <ContentSettingsForm isVideo />,
      isDisabled: !isValid || !canAdvance,
    },
    {
      label: id ? t("authoring.update") : t("authoring.tabs.publish"),
      content: <ContentPublishForm />,
      isDisabled: !isValid || !canAdvance,
    },
  ];

  const { data, isFetching, isError, error } = getVideoForEditor;

  useEffect(() => checkContentEditable(data), [data]);

  const onProceed = () => {
    submitVideoMutation.mutateAsync({
      approvalVariables: {
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
        draft: isDraft,
      },
    });

    // TODO: Fix this after images are being returned properly from getOne
    // if (tabIndex === 0 && !images.explore && !trackState) {
    //   setNoImageModalIsOpen(true);
    //   return;
    // }

    setTabIndex(tabIndex + 1);
  };

  const handlePublish = () => {
    if (isTranscoding) {
      setToast({
        show: true,
        status: "info",
        title: t("authoring.video.videoTranscoding"),
      });

      return;
    }

    submitVideoMutation
      .mutateAsync({
        approvalVariables: {
          approval_required: user.is_collaborator,
          approved: !user.is_collaborator,
          draft: false,
        },
      })
      .then((submissionData) => {
        setToast({
          show: true,
          status: "success",
          title: "Video Created Successfully",
        });

        if (trackState) {
          setTrackState({
            ...trackState,
            contentId: submissionData._id,
            contentTitle: submissionData.title,
            returningFromContent: true,
          });

          navigate(`/admin/edit/course/track/${trackState.track}`);

          return;
        }

        navigate(`/video/${submissionData._id}`);
      });
  };

  if (id && isFetching) return <Loading />;

  if (isError) {
    console.error("Error Fetching Video", error);

    return (
      <ErrorAlert
        title="There was an error fetching the video"
        backAction={{
          to: "/pvt/#/overview/content",
          label: "Back to content overview",
          isAngular: true,
        }}
      />
    );
  }

  return (
    <CreateTabs
      tabOptions={tabOptions}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      controls={
        <ContentCreateTabControls
          tabOptionsLength={tabOptions.length}
          onProceed={onProceed}
          canPublish={canPublish}
          handlePublish={handlePublish}
        />
      }
    />
  );
}
