import {
  ExploreCategory,
  ExploreContent,
  ExploreContentStats,
  ProductWithPurchased,
} from "@/client/types/ExploreContent";

import { analyzeApiClient } from "./clients/analyzeApiClient";
import { learnApiClient } from "./clients/learnApiClient";
import { localizeCategories } from "@/client/utils/explore/localizeCategories";
import { sortBy } from "lodash";

const getContentStats = async (ids: string | undefined) => {
  const response = await analyzeApiClient.get(
    `/explore/count-items?content=${ids}`
  );
  return response.data;
};

const registerSearchTerm = async (search_term: string) =>
  analyzeApiClient.post(`search`, {
    search_term,
  });

const productToExploreContent = (product: ProductWithPurchased) =>
  <ExploreContent>{
    archived: false,
    privacy_collaborators: [],
    privacy_locations: [],
    privacy_departments: [],
    privacy_teams: [],
    privacy_org_levels: [],
    privacy_grades: [],
    privacy_groups: [],
    linked_category: [],
    explore_hide: false,
    duration: 0,
    approval_required: false,
    approved: false,
    type: "product",
    _id: product.id,
    title: product.title,
    name: product.title,
    updatedAt: new Date(product.updatedAt).toISOString(),
    createdAt: new Date(product.createdAt).toISOString(),
    banner_image: product.images ? product.images[0] : "",
    image: product.images ? product.images[0] : "",
    cover_justification: "center",
    productDetails: {
      price: product.price,
      currency: product.currency,
      purchased: product.purchased || false,
      licensingType: product.licensingType,
      duration: product.subscriptionDuration || "",
    },
  };

const getContent = async (
  id: string | null,
  restrictedTypes: string | null,
  pageParam: number
): Promise<{ items: ExploreContent[]; nextPage: number | undefined }> => {
  let url = `explore?page=${pageParam}&per_page=15&sort=createdAt,-1`;
  if (restrictedTypes) {
    url += `&restricted_types=${restrictedTypes}`;
  }
  if (id && id !== "recommendations" && id !== "purchases") {
    url += `&linked_category=${id}`;
  }

  const response = await learnApiClient.get<{
    data: ExploreContent[] | ProductWithPurchased[];
    next_page: number;
  }>(url);

  const ids = response.data.data
    // @ts-ignore
    .map((item: ExploreContent | ProductWithPurchased) => item._id || item.id)
    .join(",");

  const contentStats = await getContentStats(ids);

  const contentWithStats: ExploreContent[] = response.data.data.map(
    (item: ExploreContent | ProductWithPurchased) => {
      let productItem: ExploreContent;
      if ("productType" in item) {
        productItem = productToExploreContent(item);
      }
      const stats = contentStats.data.filter(
        (filterItem: ExploreContentStats) =>
          // @ts-ignore
          filterItem.contentId === item._id || filterItem.contentId === item.id
      );
      // @ts-ignore
      let contentItem = productItem || item;
      // @ts-ignore
      if (productItem) {
        contentItem = productItem;
      }
      return {
        ...contentItem,
        views: stats[0].views,
        likes: stats[0].likes,
        shares: stats[0].shares,
        hasLiked: stats[0].hasLiked,
        hasViewed: stats[0].hasViewed,
        hasShared: stats[0].hasShared,
      };
    }
  );

  return {
    items: contentWithStats,
    nextPage: response.data.next_page || undefined,
  };
};

const getOrderedContent = async (id: string | null) => {
  const response = await learnApiClient.get(`explore/ordering/${id}`);

  return response.data;
};

const reorderContent = async (id: string | null, content: string[]) =>
  learnApiClient.post(`categories/${id}?fields=order`, { order: content });

const getCategories = async (
  userIsPurchaser: boolean,
  userIsExternal: boolean
): Promise<{
  allCategories: ExploreCategory[];
  topLevelCategories: ExploreCategory[];
  secondLevelCategories: ExploreCategory[];
  thirdLevelCategories: ExploreCategory[];
}> => {
  if (userIsPurchaser) {
    return {
      allCategories: [],
      topLevelCategories: [],
      secondLevelCategories: [],
      thirdLevelCategories: [],
    };
  }
  const response = await learnApiClient.get<ExploreCategory[]>(
    "categories?category=true&fields=sortOrderIndex,name,localized_names,location,department,category,company,level,parent,children,createdAt,show_external"
  );

  const localizedCategories = localizeCategories(response.data);

  const featuredCategory = localizedCategories.filter(
    (category) => category.level === 10
  )[0];

  const topLevelCategories = [
    ...localizedCategories
      .filter((category) => category.level === 1)
      .sort(
        (a, b) =>
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
      ),
  ];

  const sortedTopLevelCategories = sortBy(topLevelCategories, [
    "sortOrderIndex",
  ]);

  if (featuredCategory) {
    sortedTopLevelCategories.unshift(featuredCategory);
  }

  const secondLevelCategories = localizedCategories.filter(
    (category) => category.level === 2
  );

  const sortedSecondLevelCategories = sortBy(secondLevelCategories, [
    "sortOrderIndex",
  ]);

  const thirdLevelCategories = localizedCategories.filter(
    (category) => category.level === 3
  );

  const sortedThirdLevelCategoried = sortBy(thirdLevelCategories, [
    "sortOrderIndex",
  ]);

  if (userIsExternal) {
    return {
      allCategories: localizedCategories.filter(
        (category) => category.show_external
      ),
      topLevelCategories: sortedTopLevelCategories.filter(
        (category) => category.show_external
      ),
      secondLevelCategories: sortedSecondLevelCategories.filter(
        (category) => category.show_external
      ),
      thirdLevelCategories: sortedThirdLevelCategoried.filter(
        (category) => category.show_external
      ),
    };
  }

  return {
    allCategories: localizedCategories,
    topLevelCategories: sortedTopLevelCategories,
    secondLevelCategories: sortedSecondLevelCategories,
    thirdLevelCategories: sortedThirdLevelCategoried,
  };
};

const getFeaturedCategory = async () => {
  const response = await learnApiClient.get<ExploreCategory[]>(
    "categories?category=true&fields=sortOrderIndex,name,localized_names,location,department,category,company,level,parent,children,createdAt,show_external"
  );
  const localizedCategories = localizeCategories(response.data);
  const featuredCategory = localizedCategories.filter(
    (category) => category.level === 10
  )[0];

  return featuredCategory;
};

const searchContent = async (
  searchQuery: string,
  pageParam: number,
  searchTerm: string
): Promise<{ items: ExploreContent[]; nextPage: number | undefined }> => {
  if (!pageParam) return { items: [], nextPage: undefined };

  if (searchTerm) registerSearchTerm(searchTerm);

  const response = await learnApiClient.get(
    `explore/search?page=${pageParam}&per_page=15${searchQuery}`
  );

  const ids = response.data.data
    // @ts-ignore
    .map((item: ExploreContent | ProductWithPurchased) => item._id || item.id)
    .join(",");

  const contentStats = await getContentStats(ids);

  const contentWithStats = response.data.data.map(
    (item: ExploreContent | ProductWithPurchased) => {
      let contentItem = item;
      if ("productType" in item) {
        contentItem = productToExploreContent(item);
      }
      const stats = contentStats.data.filter(
        (filterItem: ExploreContentStats) =>
          // @ts-ignore
          filterItem.contentId === item._id || filterItem.contentId === item.id
      );
      return {
        ...contentItem,
        views: stats[0].views,
        likes: stats[0].likes,
        shares: stats[0].shares,
        hasLiked: stats[0].hasLiked,
        hasViewed: stats[0].hasViewed,
        hasShared: stats[0].hasShared,
      };
    }
  );

  return {
    items: contentWithStats,
    nextPage: response.data.next_page,
  };
};

const registerSearchTermAndSelectedContent = async (
  search_term: string,
  selected_content: string,
  content_type: string
) =>
  analyzeApiClient.post(`search`, {
    search_term,
    selected_content,
    content_type,
  });

const ExploreService = {
  getContent,
  getOrderedContent,
  reorderContent,
  getCategories,
  getFeaturedCategory,
  searchContent,
  registerSearchTerm,
  registerSearchTermAndSelectedContent,
};

export default ExploreService;
