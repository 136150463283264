import { Box, Flex, RadioGroup, Radio, Text, Tooltip } from "@chakra-ui/react";
import { QuestionCircle } from "@/client/components/icons/ContinuIcons";
import { useTranslation } from "react-i18next";
import { UserType } from "@/client/types/RegistrationForms";

type ExternaluserSelectionProps = {
  userType: UserType;
  updateUserType: (userType: UserType) => void;
};

export default function ExternalUserSelection({
  userType,
  updateUserType,
}: ExternaluserSelectionProps) {
  const { t } = useTranslation();

  return (
    <Box pb={6}>
      <Text pb={4} fontSize="md" fontWeight="500">
        {t("registrationForms.label.userType")}
        <Tooltip hasArrow label={t("registrationForms.tooltip.userType")}>
          <QuestionCircle color="#1A1A1A80" marginLeft={1} />
        </Tooltip>
      </Text>
      <RadioGroup onChange={updateUserType} value={userType}>
        <Flex direction="row" justifyContent="space-between" width="32%">
          <Radio variant="admin" value="user">
            {t("registrationForms.internalUser")}
          </Radio>
          <Radio variant="admin" value="external">
            {t("registrationForms.externalUser")}
          </Radio>
        </Flex>
      </RadioGroup>
    </Box>
  );
}
