import { Flex, Text } from "@chakra-ui/react";
import AdminButtonTabs from "../navigation/AdminButtonTabs";

type TabButton = {
  key: string;
  text: string;
  allowance: boolean;
};

type TabHeaderProps = {
  title: string;
  nav: TabButton[];
  path: string;
  activeTab: string | undefined;
};

export default function AdminTabHeader({
  title,
  nav,
  path,
  activeTab,
}: TabHeaderProps) {
  return (
    <Flex
      height="100px"
      alignContent="center"
      alignItems="center"
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <Flex width="80%" direction="column" justifyContent="space-between">
        <Text fontSize="18px" fontWeight="medium" color="black">
          {title}
        </Text>
        <AdminButtonTabs nav={nav} path={path} tab={activeTab} />
      </Flex>
    </Flex>
  );
}
