import { endOfYear, startOfYear } from 'date-fns';
import { useMutation, useQuery } from '@tanstack/react-query';

import ReportsService from '@/client/services/api/admin/reports/ReportsService';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useAuthStore } from '@/client/services/state/authStore';
import { useReportTypes } from './useReportTypes';
import { useToastStore } from '@/client/services/state/toastStore';

export const useReportsService = () => {
  const { setToast } = useToastStore();
  const { reportTypes, getCsv } = useReportTypes();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  interface GetReportsVariables {
    reportType: 'myReports' | 'standardReports' | 'savedReports';
    fetchDataOptions: {
      pageIndex: number;
      pageSize: number;
    };
    startDate: string;
    userId?: string;
    searchTerm?: string;
  }

  const getReports = (variables: GetReportsVariables) =>
    useQuery({
      enabled: variables.reportType !== 'standardReports',
      queryKey: ['reports', variables.reportType, variables.fetchDataOptions, variables.searchTerm],
      queryFn: () =>
        ReportsService.getReports(
          variables.fetchDataOptions,
          variables.userId,
          variables.searchTerm,
        ),
      select: (data) => {
        const customReports: any[] = [];

        data.rows.forEach((result) => {
          // @ts-ignore
          const reportData = reportTypes[result.report_type];

          const fields = result.report_fields.reduce((allowedFields, fieldKey) => {
            if (reportData.reportFieldsByKey[fieldKey]) {
              // @ts-ignore
              allowedFields.push(reportData.reportFieldsByKey[fieldKey]);
            }
            return allowedFields;
          }, []);

          const customReport = {
            key: result._id,
            name: result.name,
            report_type: result.report_type,
            created_by_name: result.created_by_name,
            first_date: company.createdAt,
            last_date: new Date(),
            start_of_last_year: startOfYear(
              new Date(new Date().setDate(new Date().getDate() - 365)),
            ),
            end_of_last_year: endOfYear(new Date(new Date().setDate(new Date().getDate() - 365))),
            // @ts-ignore
            fields: fields.map((field) => field.reportsServiceRequestKey || field.key),
            filters: result.report_filters || [],
            options: result.report_options,
            isStandard: false,
            applyDateRange: reportData.applyDateRange,
            csv: (csv_results: any) => getCsv(result.report_type, csv_results, fields),
          };

          // return customReport;
          customReports.push(customReport);
        });

        const newData = {
          rows: customReports,
          pageCount: data.pageCount,
        };

        return newData;
      },
    });

  interface GenerateReportVariables {
    reportDetails: any;
    reportRequest: any;
  }

  const generateReportMutation = useMutation({
    mutationFn: (variables: GenerateReportVariables) =>
      ReportsService.generateReport(variables.reportDetails, variables.reportRequest),
  });

  interface DeleteReportVariables {
    reportId: string;
    contentName: string;
    reportType: string;
  }

  const deleteReport = useMutation({
    mutationFn: (variables: DeleteReportVariables) =>
      ReportsService.deleteReport(variables.reportId),
    onSuccess: (data, variables) => {
      trackEvent('report_deleted', {
        content: variables.contentName,
        content_type: variables.reportType,
      });

      setToast({
        show: true,
        status: 'success',
        title: 'Successfully deleted report.',
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: 'Error deleting report.',
      }),
  });

  const duplicateReport = useMutation({
    mutationFn: (reportId: string) => ReportsService.duplicateReport(reportId),
    onSuccess: () =>
      setToast({
        show: true,
        status: 'success',
        title: 'Successfully duplicated report.',
      }),
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: 'Error duplicating report.',
      }),
  });

  return {
    getReports,
    generateReportMutation,
    deleteReport,
    duplicateReport,
  };
};
