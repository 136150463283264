/* eslint react/no-unstable-nested-components: 0 */

import { Avatar, Box, Flex } from "@chakra-ui/react";

import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { Person } from "@/client/types/Person";
import TableActionsMenu from "@/client/components/admin/menus/TableActionsMenu";

import Loading from "@/client/components/media/Loading";
import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import PartnersManagerHeader from "@/client/components/layout/PartnersManagerHeader";
import PartnersService from "@/client/services/api/admin/PartnersService";
import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import {
  DateFormatGroupKey,
  dateFormatTenant,
} from "@/client/utils/dateFormat";
import { useAuthStore } from "@/client/services/state/authStore";
import { usePartnerStore } from "@/client/services/state/partnerStore";
import ManuallyPaginatedTable from "@/client/components/admin/tables/ManuallyPaginatedTable";

export default function PartnerUsersList() {
  const { partner } = usePartnerStore();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t("partners.manager.manageUsersTitle"));

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data, isLoading } = useQuery({
    queryKey: ["partner-users", fetchDataOptions, partner._id, searchTerm],
    queryFn: () =>
      PartnersService.getAllPartnerUsers(fetchDataOptions, partner._id, {
        term: searchTerm,
      }),
    enabled: !!partner._id,
    keepPreviousData: true,
  });

  const columnHelper = createColumnHelper<Person>();
  const usersColumns = useMemo<ColumnDefBase<Person, any>[]>(
    () => [
      columnHelper.accessor("full_name", {
        cell: (info) => (
          <Flex alignItems="center" marginLeft={9}>
            <Avatar
              marginRight={2}
              backgroundColor="brand.primary"
              color="white"
              size="xs"
              src={info.row.original.image}
              name={info.getValue()}
            />
            {info.getValue()}
          </Flex>
        ),
        header: () => (
          <Box as="span" marginLeft={9}>
            {t("global.forms.labels_name")}
          </Box>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: () => <span>{t("global.forms.labels_email")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("last_login", {
        cell: (info) => (
          <span>
            {info.getValue()
              ? dateFormatTenant(
                  new Date(info.getValue()),
                  company.date_display as DateFormatGroupKey,
                  "base"
                )
              : "-----"}
          </span>
        ),
        header: () => <span>{t("manage.users.lastLogin")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("linked_departments", {
        cell: (info) => (
          <span>{info.getValue()[0] ? info.getValue()[0].name : "-----"}</span>
        ),

        header: () => <span>{t("manage.users.department")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("linked_locations", {
        cell: (info) => (
          <span>{info.getValue()[0] ? info.getValue()[0].name : "-----"}</span>
        ),
        header: () => <span>{t("manage.users.location")}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => (
          <TableActionsMenu
            onEdit={() =>
              navigate(`/extend/manager/users/${info.row.original._id}`)
            }
          />
        ),
      }),
    ],
    []
  );

  if (isLoading) return <Loading />;

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <PartnersManagerHeader
        currentTab="users"
        setSearchTerm={setSearchTerm}
        addUser={() => navigate("/extend/manager/users/create")}
      />
      <AdminElevatedBox>
        <ManuallyPaginatedTable
          columns={usersColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      </AdminElevatedBox>
    </Box>
  );
}
