/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Circle,
  Flex,
  Icon,
  Input,
  InputGroup,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";

import { useCombobox } from "downshift";
import { useFormContext } from "react-hook-form";

type TagListProps = {
  searchTerm: string | undefined;
  setSearchTerm: (searchTerm: string | undefined) => void;
  checkIfSelected?: (item: any, selectedItems: any[]) => boolean;
  data: any;
  selectedItemChange: (selectedItem: any) => void;
  placeholder: string;
  fieldName: string;
  ItemsIcon: any;
};

export default function BaseFormCreateTagList({
  searchTerm,
  setSearchTerm,
  checkIfSelected = undefined,
  data,
  selectedItemChange,
  placeholder,
  fieldName,
  ItemsIcon,
}: TagListProps) {
  const { watch } = useFormContext();
  const selectedItems = watch(fieldName);

  const { getMenuProps, getInputProps, getItemProps } = useCombobox({
    onInputValueChange({ inputValue }) {
      setSearchTerm(inputValue);
    },
    items: data || [],
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItemChange(selectedItem);
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: "", // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "", // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
  });
  return (
    <>
      <InputGroup>
        <Input
          variant="landingPage"
          width="100%"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={placeholder}
          {...getInputProps()}
        />
      </InputGroup>

      <List
        flexDirection="column"
        border={data && data.length > 0 ? "1px solid" : "none"}
        borderColor="brand.grey.20"
        borderRadius="md"
        background="white"
        overflow="scroll"
        zIndex={9999}
        maxH={400}
        width="inherit"
        display={data?.length === 0 ? "none" : "flex"}
        {...getMenuProps()}
      >
        {data?.map((option: any, optionIndex: any) => {
          const isSelected =
            checkIfSelected && checkIfSelected(option, selectedItems);

          return (
            <ListItem
              key={`option_index_${optionIndex + 1}`}
              paddingX={6}
              paddingY={2}
              _hover={{
                cursor: "pointer",
                backgroundColor: "brand.mist",
              }}
              {...getItemProps({
                item: option,
                index: optionIndex,
              })}
            >
              <Flex
                alignItems="center"
                width="full"
                justifyContent="space-between"
              >
                <Circle
                  backgroundColor={isSelected ? "gray.50" : "gray.100"}
                  size="30px"
                >
                  <Icon
                    color={
                      isSelected
                        ? "brand.legibleBlack.50"
                        : "brand.legibleBlack.100"
                    }
                    as={ItemsIcon}
                  />
                </Circle>

                <Box flexGrow={1} textAlign="left" paddingLeft={4}>
                  <Text
                    fontSize="sm"
                    fontWeight={500}
                    color={
                      isSelected
                        ? "brand.legibleBlack.50"
                        : "brand.legibleBlack.100"
                    }
                  >
                    {option.title || option.name}
                  </Text>
                </Box>

                <Icon
                  boxSize={8}
                  as={isSelected ? CiCircleCheck : CiCirclePlus}
                  color={isSelected ? "brand.primary" : "brand.grey.40"}
                />
              </Flex>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
