import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { View, ViewSlash } from "@/client/components/icons/ContinuIcons";

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import v3ApiService from "@/client/services/api/clients/v3ApiClient";

type AppSettingsFormProps = {
  userId: string;
};

export default function AppSettingsForm({ userId }: AppSettingsFormProps) {
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const updateUserPassword = useMutation({
    mutationFn: () => v3ApiService.updateUserPassword(userId, password),
    onSuccess: () =>
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.password.updated"),
      }),
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdatePassword"),
      }),
  });

  return (
    <>
      <Heading as="h3" fontSize="sm" textTransform="uppercase" marginTop="0">
        {t("userProfileEdit.appSettings")}
      </Heading>

      <Stack spacing={8}>
        <Box>
          <Text variant="formlabel">
            {t("global.forms.labels_newPassword")}
          </Text>
          <InputGroup>
            <Input
              variant="flushed"
              type={show ? "text" : "password"}
              onChange={(event) => setPassword(event.target.value)}
            />

            <InputRightElement width="4.5rem">
              {show ? (
                <ViewSlash
                  onClick={() => setShow(!show)}
                  _hover={{ cursor: "pointer", color: "brand.primary" }}
                />
              ) : (
                <View
                  onClick={() => setShow(!show)}
                  _hover={{ cursor: "pointer", color: "brand.primary" }}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </Box>

        <Box>
          <Text variant="formlabel">
            {t("global.forms.labels_confirmPassword")}
          </Text>
          <Input
            variant="flushed"
            type={show ? "text" : "password"}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </Box>

        {password.length > 0 &&
          confirmPassword.length > 0 &&
          password !== confirmPassword && (
            <Text color="red">
              {t("userPasscodeChange.passwords_dont_match")}
            </Text>
          )}

        {password.length > 0 &&
          confirmPassword.length > 0 &&
          password === confirmPassword && (
            <Button onClick={() => updateUserPassword.mutate()}>
              {t("dashboard.settings.profile_passwordButton")}
            </Button>
          )}
      </Stack>
    </>
  );
}
