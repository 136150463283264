import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { InviteAcceptanceCredentials } from "../types/preauth/Preauth";
import { Locked } from "@/client/components/icons/ContinuIcons";
import PreAuthService from "@/client/services/api/PreAuthService";
import { useConfigStore } from "@/client/services/state/configStore";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useLogin } from "@/client/services/hooks/preauth/useLogin";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import { useAuthInviteSetup } from "../services/hooks/preauth/useAuthInviteSetup";
import { usePartnerStore } from "../services/state/partnerStore";

interface Inputs extends InviteAcceptanceCredentials {
  confirmPassword: string;
}

export default function AcceptInvite() {
  useDocumentTitle("Accept Invite");
  const { config } = useConfigStore();
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { login, loginLoading } = useLogin();
  const domain = config.host;
  const [searchParams] = useSearchParams();
  const { partner, setPartner, clearPartnerStore } = usePartnerStore();
  const { isLoading, isError, data, error } = useAuthInviteSetup(
    searchParams.get("key") ?? "",
    searchParams.get("email") ?? ""
  );

  useEffect(() => {
    if (data?.partner) {
      setPartner(data?.partner);
    } else {
      clearPartnerStore();
    }
  }, [data?.partner]);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: searchParams.get("fname") || "",
      lastName: searchParams.get("lname") || "",
      key: searchParams.get("key") || "",
      email: searchParams.get("email") || "",
      domain,
    },
  });
  const [mismatch, setMismatch] = useState<boolean>(false);
  const createPasswordPlaceholder = t("global.forms.labels_passwordNew");
  const confirmPasswordPlaceholder = t("global.forms.labels_passwordConfirm");
  const firstNamePlaceholder = t("global.forms.labels_firstName");
  const lastNamePlaceholder = t("global.forms.labels_lastName");

  const acceptInvite = useMutation({
    mutationFn: ({
      firstName,
      lastName,
      domain: mutationDomain,
      key,
      email,
      password,
    }: InviteAcceptanceCredentials) =>
      PreAuthService.acceptInvite({
        firstName,
        lastName,
        domain: mutationDomain,
        key,
        email,
        password,
      }),
    onSuccess: (res, { email, password }) => {
      // TODO: check `validateAndUseAuthtoken`
      // TODO: Application.analyticsProvider.trackEvent('signin');
      const purchaseProductId =
        searchParams.get("purchaseproductid") || undefined;
      login({ userid: email, password, loginType: "email", purchaseProductId });
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("preauth.invite.errorConfirmingInvitation"),
      }),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    acceptInvite.mutate(data);
  };

  if (isLoading || !searchParams.get("key") || !searchParams.get("email")) {
    return (
      <>
        Please wait... if this screen persists, please request a new invitation.
      </>
    );
  }

  if (isError) {
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <Stack spacing={4} width="100%">
      <Text as="h4">{t("preauth.invite.completeInvite")}</Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <FormControl isInvalid={!!errors.firstName}>
            <Input
              id="firstName"
              type="text"
              placeholder={firstNamePlaceholder}
              variant="flushed"
              {...register("firstName", { required: true })}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.lastName}>
            <Input
              id="lastName"
              type="text"
              placeholder={lastNamePlaceholder}
              variant="flushed"
              {...register("lastName", { required: true })}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <InputGroup>
              <InputLeftElement children={<Locked color="brand.gray.500" />} />
              <Input
                id="password"
                type="password"
                placeholder={createPasswordPlaceholder}
                variant="flushed"
                {...register("password", { required: true })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.password && "Password is required"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.confirmPassword}>
            <InputGroup>
              <InputLeftElement children={<Locked color="brand.gray.500" />} />
              <Input
                id="confirmPassword"
                type="password"
                placeholder={confirmPasswordPlaceholder}
                variant="flushed"
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) => {
                    if (watch("password") !== value) {
                      setMismatch(true);
                      return "";
                    } else {
                      setMismatch(false);
                    }
                  },
                })}
              />
            </InputGroup>

            <FormErrorMessage>
              {errors.password && "Password confirmation is required"}
            </FormErrorMessage>

            {mismatch && (
              <FormErrorMessage>
                {t("preauth.acceptinvite.passwords_dont_match")}
              </FormErrorMessage>
            )}
          </FormControl>

          <Button type="submit" isDisabled={!isValid} isLoading={isSubmitting}>
            {t("preauth.invite.confirmInvitation")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
