import { NavigateFunction, useNavigate } from "react-router-dom";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import PreAuthService from "@/client/services/api/PreAuthService";
import { useIntercom } from "@/client/services/hooks/useIntercom";
import { useNPS } from "@/client/services/hooks/useNPS";
import { useAuthStore } from "@/client/services/state/authStore";
import { useConfigStore } from "@/client/services/state/configStore";
import { useToastStore } from "@/client/services/state/toastStore";
import { AuthConfig } from "@/client/types/AuthConfig";
import { PartnerLoginConfig } from "@/client/types/preauth/Preauth";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { usePartnerStore } from "../../state/partnerStore";

interface LoginVariables {
  userid: string;
  password: string;
  loginType: "employeeId" | "email";
  partnerLoginConfig?: PartnerLoginConfig;
  purchaseProductId?: string;
}
export const successfulLoginActions = async (
  res: any,
  authConfig: AuthConfig,
  setAuthConfig: (authConfig: AuthConfig) => Promise<void>,
  angularRedirectLocation: string | null,
  redirectLocation: string | null,
  navigate: NavigateFunction,
  purchaseProductId?: string
) => {
  await setAuthConfig(res);
  AnalyzeService.recordUserAgents(navigator.userAgent);
  AnalyzeService.recordAuthTokens("signin");

  if (purchaseProductId) {
    navigate(`/product/${purchaseProductId}/payment-method`);
    return;
  }

  if (angularRedirectLocation) {
    window.location.href = `/pvt/#${angularRedirectLocation}`;
  } else if (redirectLocation) {
    if (
      res.company.landing === "dashboard" &&
      redirectLocation.includes("explore")
    ) {
      navigate("/dashboard");
    } else if (
      res.company.landing === "explore" &&
      redirectLocation.includes("dashboard")
    ) {
      navigate("/explore");
    } else {
      navigate(redirectLocation);
    }
  } else if (res.company.landing === "dashboard") {
    navigate("/dashboard");
  } else {
    navigate("/explore");
  }
};

export const useLogin = () => {
  const { config } = useConfigStore();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const { authConfig, setAuthConfig } = useAuthStore();
  const [redirectLocation] = useLocalStorage("redirectLocation", null);
  const [angularRedirectLocation] = useLocalStorage("preRedirect", null);
  const { setPartner, clearPartnerStore } = usePartnerStore();
  const navigate = useNavigate();
  const { bootIntercomUser } = useIntercom();
  const { addNPS } = useNPS();

  const {
    mutate: login,
    isLoading: loginLoading,
    data,
    error,
  } = useMutation({
    mutationFn: ({
      userid,
      password,
      loginType,
      partnerLoginConfig,
      purchaseProductId,
    }: LoginVariables) =>
      PreAuthService.login(
        config.host,
        password,
        userid,
        loginType,
        partnerLoginConfig
      ),

    onSuccess: async (res, variables) => {
      bootIntercomUser(config, res);
      addNPS(res);
      // Forcibly update the partner store to whatever the valid login state is.
      if (res.user.partner) {
        setPartner(res.user.partner);
      } else {
        clearPartnerStore();
      }
      await successfulLoginActions(
        res,
        authConfig,
        setAuthConfig,
        angularRedirectLocation,
        redirectLocation,
        navigate,
        variables.purchaseProductId
      );
    },
    onError: (err: any, { loginType }) => {
      if (loginType === "email") {
        if (err && err.status === 401) {
          setToast({
            show: true,
            status: "error",
            title: t("preauth.signin.login_through_company_im"),
          });
        }
      }

      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.invalidCredentials"),
      });
    },
  });

  return { login, loginLoading, data, error };
};
