import type { AssessmentFormData } from "@/client/routes/admin/create/content/assessments/EditAssessment";
import type { QuestionData } from "@/client/services/api/graphql/gql/graphql";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useValidateTextAssessmentQuestionBuilder = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { setTabIndex, tabs, setTabs, uploadStatus } = useCreateStore();

  const { watch } = useFormContext<AssessmentFormData>();

  const { questions } = watch();

  const splitQuestionsByPageBreak = (): QuestionData[][] => {
    const result: QuestionData[][] = [];
    let currentPage: QuestionData[] = [];

    questions.forEach((question) => {
      if (question.format === "page_break") {
        result.push(currentPage);
        currentPage = [];
      } else {
        currentPage.push(question);
      }
    });

    result.push(currentPage);

    return result;
  };

  const validateTextAssessmentQuestionBuilder = (
    realtimeCheck: boolean = false,
    callback?: any
  ) => {
    const formattedQuestions = splitQuestionsByPageBreak();
    const erroredQuestions: QuestionData[] = [];
    const emptyPagesErrors: string[] = [];

    if (uploadStatus === "loading") {
      setToast({
        show: true,
        status: "error",
        title: t("assessment.validation.textResponse.videoProcessing"),
      });
      return;
    }

    questions.forEach((question) => {
      if (question.format === "page_break") return;

      const useManualGrade =
        ((question?.questionOptions?.find(
          (option) => option.name === "manual_grade"
        )?.value as boolean) &&
          question.format === "short_input") ??
        false;

      if (!question.question) {
        erroredQuestions.push(question);
      }

      if (
        question.format === "multiple_choice" ||
        question.format === "dropdown" ||
        (question.format === "short_input" && !useManualGrade)
      ) {
        const emptyAnswers = question.choiceOptions?.filter(
          (option) => option.copy === ""
        );

        if (emptyAnswers?.length) {
          erroredQuestions.push(question);
        }

        const correctAnswers = question.choiceOptions?.filter(
          (option) => option.points !== 0
        );

        if (correctAnswers?.length === 0) {
          erroredQuestions.push(question);
        }
      }
    });

    formattedQuestions.forEach((page, index) => {
      if (page.length === 0) {
        emptyPagesErrors.push(
          `${t("assessment.validation.textResponse.section")} ${index + 1} ${t(
            "assessment.validation.textResponse.oneQuestion"
          )}`
        );
      }
    });

    if (!realtimeCheck) {
      if (emptyPagesErrors.length) {
        setToast({
          show: true,
          status: "error",
          title: emptyPagesErrors.join(", "),
        });

        return;
      }

      if (erroredQuestions.length) {
        setToast({
          show: true,
          status: "error",
          title: t("assessment.validation.textResponse.invalidBuilder"),
        });

        return;
      }
    }

    if (!emptyPagesErrors.length && !erroredQuestions.length) {
      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        isDisabled: false,
      }));

      setTabs(updatedTabs);
    } else {
      const updatedTabs = tabs.map((tab, index) => ({
        ...tab,
        isDisabled: index !== 0 && index !== 1,
      }));

      setTabs(updatedTabs);
    }

    if (!realtimeCheck) {
      setTabIndex(2);
    }

    if (callback) {
      callback();
    }
  };

  return { validateTextAssessmentQuestionBuilder };
};
