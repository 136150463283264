import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaArrowLeft, FaUser, FaUsers } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";

import AdminElevatedBox from "@/client/components/admin/layout/AdminElevatedBox";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useTranslation } from "react-i18next";

export default function AdminUserAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAllowed } = useAccessHelper();

  const addAllowed =
    isAllowed(["admin", "users", "add"], null, ["admin"]) ||
    isAllowed(["admin", "users", "bulk_add"], null, ["admin"]);
  const singleAddAllowed = isAllowed(["admin", "users", "add"], null, [
    "admin",
  ]);
  const bulkAddAllowed = isAllowed(["admin", "users", "bulk_add"], null, [
    "admin",
  ]);

  const options = [
    {
      icon: FaUser,
      text: t("admin.users.add.single.pt1"),
      subText: t("admin.users.add.single.pt2"),
      enabled: singleAddAllowed,
      onClick: () => navigate("/admin/users/add/single"),
    },
    {
      icon: FaUsers,
      text: t("admin.users.add.bulk.pt1"),
      subText: t("admin.users.add.bulk.pt2"),
      enabled: bulkAddAllowed,
      onClick: () => navigate("/admin/users/add/bulk"),
    },
  ];

  if (!addAllowed) return <Navigate to="/explore" replace />;

  return (
    <AdminElevatedBox>
      <Flex>
        <Button
          variant="adminPrimary"
          size="xs"
          leftIcon={<FaArrowLeft />}
          onClick={() => navigate("/admin/users")}
        >
          {t("global.actions.back")}
        </Button>
      </Flex>

      <Center height="500px">
        <HStack alignItems="center" spacing={8}>
          {options.map((option) => {
            if (!option.enabled) return null;

            return (
              <Box
                key={option.text}
                height={300}
                width={300}
                backgroundColor="white"
                borderRadius="xl"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                padding={12}
                _hover={{
                  cursor: "pointer",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
                }}
                onClick={option.onClick}
              >
                <VStack textAlign="center" spacing={4}>
                  <Circle size="75px" backgroundColor="brand.primary">
                    <Icon as={option.icon} boxSize={10} color="white" />
                  </Circle>

                  <Text fontWeight="bold" fontSize="md">
                    {option.text}
                  </Text>

                  <Text fontSize="sm">{option.subText}</Text>
                </VStack>
              </Box>
            );
          })}
        </HStack>
      </Center>
    </AdminElevatedBox>
  );
}
