import { Box, Flex } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

export default function EmptyTable({ label }: { label: string }) {
  const baseArr = [1, 2, 3, 4, 5];
  const { t } = useTranslation();

  return (
    <Box
      padding={4}
      background="gray.200"
      margin={4}
      opacity="0.33"
      borderRadius={8}
      position="relative"
    >
      {baseArr.map((item, ind) => (
        <Flex
          key={item}
          marginBottom={ind === 4 ? 0 : 2}
          opacity="0.67"
          transition="0.3s ease all"
          _hover={{
            opacity: "1",
          }}
        >
          <Box
            width={8}
            height={8}
            marginRight={2}
            background="white"
            borderRadius={16}
          />

          <Box
            flex="1"
            height={8}
            marginRight={2}
            background="white"
            borderRadius={4}
          />

          <Box
            flex="0.5"
            height={8}
            marginRight={2}
            background="white"
            borderRadius={4}
          />

          <Box
            flex="2"
            height={8}
            marginRight={2}
            background="white"
            borderRadius={4}
          />

          <Box flex="2" height={8} background="white" borderRadius={4} />
        </Flex>
      ))}

      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
        background="gray.900"
        fontWeight="bold"
        color="white"
        padding={4}
        borderRadius={16}
        zIndex="3"
      >
        {label || t("emptytable.nodata")}
      </Box>
    </Box>
  );
}
