export const formatComment = (input: string) => {
  if (!input || typeof input !== "string") {
    return input;
  }
  var modified = input.slice(0);
  var items = [];
  var pair = [];
  for (var i = 0; i < modified.length; i++) {
    if (!pair.length) {
      if (
        modified[i] &&
        modified[i] === "<" &&
        modified[i + 1] &&
        modified[i + 1] === ":"
      ) {
        pair.push(i);
        continue;
      }
    }
    if (pair.length) {
      if (modified[i] === ">") {
        pair.push(i + 1);
        items.push(pair);
        pair = [];
      }
    }
  }
  if (items.length) {
    for (var j = items.length - 1; j >= 0; j--) {
      var user = modified.substring(items[j][0], items[j][1]);

      if (user && typeof user === "string") {
        var splitUser = user.split(":");

        if (splitUser[1] && splitUser[2]) {
          var newItem =
            '<span class="mention--item" data-user="' +
            splitUser[2].slice(0, -1) +
            '">@' +
            splitUser[1].split(".").join(" ") +
            "</span>";
          modified = modified.replace(user, newItem);

          return modified;
        }
      }
    }
  }

  return modified;
};
