import {
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
  useToken,
} from "@chakra-ui/react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { useCallback, useRef } from "react";

import type { DropTargetMonitor } from "react-dnd";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Navigate } from "react-router-dom";
import { QuestionCircle } from "@/client/components/icons/ContinuIcons";
import { hexToRGBA } from "@/client/utils/hexToRGBA";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

interface BulkAddImportTabProps {
  csv: File | null;
  setCsv: (csv: File) => void;
}

export default function BulkAddImportTab({
  csv,
  setCsv,
}: BulkAddImportTabProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { isAllowed } = useAccessHelper();
  const [brandHighlight] = useToken("colors", ["brand.highlight"]);

  const allowance = {
    bulk_add: isAllowed(["admin", "users", "bulk_add"], null, [
      "admin",
      "manager",
    ]),
  };

  if (!allowance.bulk_add) return <Navigate to="/explore" replace />;

  const csvRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;
    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: "error", title: "Error uploading image" });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = "";

    setCsv(fileObject);
  };

  const handleFileDrop = useCallback(
    (item: { files: any[] }) => {
      if (item) {
        const { files } = item;

        setCsv(files[0]);
      }
    },
    [setCsv]
  );

  const handleFileUploadClick = () =>
    csvRef.current ? csvRef.current.click() : null;

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleFileDrop(item);
      },
      canDrop() {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [handleFileDrop]
  );

  const isActive = canDrop && isOver;

  return (
    <Stack spacing={8}>
      <HStack alignItems="start" spacing={2}>
        <Text variant="adminFormLabel">{t("edit.useradd.import_users")}</Text>

        <Popover placement="bottom-start">
          <PopoverTrigger>
            <IconButton
              variant="ghost"
              aria-label="Help"
              icon={<QuestionCircle boxSize={4} />}
              size="xs"
            />
          </PopoverTrigger>

          <PopoverContent>
            <PopoverArrow />

            <PopoverCloseButton />

            <PopoverBody>
              <Link
                href="https://help.continu.com/hc/en-us/articles/360026308873-Uploading-Your-User-File-Bulk-Import"
                isExternal
                fontSize="sm"
              >
                {t("global.tooltips.text_helpwithCSV")}
              </Link>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>

      <VStack>
        <Text>
          {t("edit.useradd.csv_template.pt1")}

          <Link href="https://d2277n3gvptnup.cloudfront.net/files/51d5f21b2f410a020b000002/3f28cd08-d0e8-481f-a6a5-7e3292366557.csv">
            {t("edit.useradd.csv_template.pt2")}
          </Link>

          {t("edit.useradd.csv_template.pt3")}
        </Text>

        <Text>{t("edit.useradd.csv_requirements")}</Text>
      </VStack>

      <DndProvider backend={HTML5Backend}>
        <Box
          ref={drop}
          paddingX={6}
          paddingY={2}
          borderRadius="md"
          backgroundColor={
            isActive
              ? hexToRGBA(brandHighlight, 0.1)
              : hexToRGBA(brandHighlight, 0.2)
          }
          border="2px dashed"
          borderColor={brandHighlight}
        >
          <VStack
            spacing={1}
            fontSize="xs"
            fontWeight="semibold"
            color={brandHighlight}
          >
            <Icon as={IoCloudUploadOutline} boxSize={10} />

            <Input
              display="none"
              ref={csvRef}
              type="file"
              accept=".csv"
              onChange={(e) => handleFileChange(e)}
            />

            {!csv && (
              <Text>
                {t("edit.general.drapDrop")}{" "}
                <Text
                  as="span"
                  textDecoration="underline"
                  _hover={{ cursor: "pointer" }}
                  onClick={handleFileUploadClick}
                >
                  {t("edit.general.browse")}
                </Text>{" "}
                {t("edit.general.importFile")}
              </Text>
            )}

            {csv && (
              <VStack>
                <Text>{csv.name}</Text>

                <Text
                  as="span"
                  textDecoration="underline"
                  _hover={{ cursor: "pointer" }}
                  onClick={handleFileUploadClick}
                >
                  {t("global.actions.replace")}
                </Text>
              </VStack>
            )}
          </VStack>
        </Box>
      </DndProvider>
    </Stack>
  );
}
