import { useTranslation } from "react-i18next";

export const useNotificationEditor = (content?: any) => {
  const { t } = useTranslation();

  const insertOptions = {
    due_in: t("assignments.notifications.edit.email.insertOptions.dueIn"),
    days_overdue: t(
      "assignments.notifications.edit.email.insertOptions.daysOverdue"
    ),
    assigned_content: t(
      "assignments.notifications.edit.email.insertOptions.assignedContent"
    ),
    assigner_first_name: t(
      "assignments.notifications.edit.email.insertOptions.assignerFirstName"
    ),
    assigner_last_name: t(
      "assignments.notifications.edit.email.insertOptions.assignerLastName"
    ),
    assignee_first_name: t(
      "assignments.notifications.edit.email.insertOptions.assigneeFirstName"
    ),
    assignee_last_name: t(
      "assignments.notifications.edit.email.insertOptions.assigneeLastName"
    ),
    recipient_first_name: t(
      "assignments.notifications.edit.email.insertOptions.recipientFirstName"
    ),
    recipient_last_name: t(
      "assignments.notifications.edit.email.insertOptions.recipientLastName"
    ),
  };

  // This uses actual content from the parent assignment
  const makeContentList = () => {
    if (!content || content.length === 0) {
      return '<ul><li><a href="">Leadership 101 [Example]</a></li></ul>';
    }

    let list = "<ul>";

    content.forEach((item: any) => {
      list += '<li><a href="">' + (item.title || item.name) + "</a></li>";
    });

    list += "</ul>";
    return list;
  };

  const predefinedOptions = {
    due_in: "3",
    days_overdue: "X",
    assigner_first_name: "Jane",
    assigner_last_name: "Doe",
    assignee_first_name: "Joe",
    assignee_last_name: "Williams",
    recipient_first_name: "John",
    recipient_last_name: "Smith",
    assigned_content: () => makeContentList(),
  };

  // eslint-disable-next-line no-useless-escape
  const escapeRegExp = (str: string) =>
    str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

  const templateReplace = (str: any, find: any, replace: any) =>
    str.replace(new RegExp(escapeRegExp(find), "g"), replace);

  const parseTemplate = (template: string, contentToParse: string) => {
    const variables = {};
    const stack = [];
    let newString = template.slice(0);
    const options = { ...predefinedOptions };

    if (contentToParse !== "email") {
      // @ts-ignore
      delete options.assigned_content;
    }

    for (let i = 0; i < template.length; i += 1) {
      if (template[i] === "{") {
        stack.push(i);
      }

      if (template[i] === "}" && stack.length) {
        // @ts-ignore
        variables[template.substring(stack.pop() + 1, i)] = true;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in variables) {
      // @ts-ignore
      if (options[key]) {
        // @ts-ignore
        newString = templateReplace(newString, "{" + key + "}", options[key]);
      }
    }

    return newString;
  };

  const templateOptions: any = {
    email: {
      assignee: {
        assignment_created:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {assignee_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">You have been assigned the following content:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">Please complete these items within the next {due_in} days.</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_reminder:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {assignee_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">Your assignment of the following content is approaching the deadline for completion:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">Please complete these items within the next {due_in} days.</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_passed:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {assignee_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">Congratulations! You completed your assignment of the following content:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_failed:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {assignee_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">Unfortunately, you were unable to complete your assignment of the following content in time.</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_overdue: `<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {assignee_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">${t(
          "assignmentDynamicMess.overdue_notification_body"
        )}</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>`,
      },
      escalator: {
        assignment_created:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {recipient_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assignee_first_name} {assignee_last_name} has been assigned the following content:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">These items are due within the next {due_in} days.</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_reminder:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {recipient_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assignee_first_name} {assignee_last_name} has been assigned the following content and is approaching the deadline for completion:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">These items are due within the next {due_in} days.</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_passed:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {recipient_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assignee_first_name} {assignee_last_name} has completed their assignment of the following content:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
        assignment_failed:
          '<p dir="ltr" style="font-size:10.5pt;font-family:Arial;color:#000000;">Hi {recipient_first_name},</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">Unfortunately, {assignee_first_name} {assignee_last_name} has failed to complete the following content before the deadline for completion:</p><p style="font-size:10.5pt;font-family:Arial;color:#000000;">{assigned_content}</p><br><p style="font-size:10.5pt;font-family:Arial;color:#000000;">- {assigner_first_name} {assigner_last_name}</p>',
      },
    },
    slack: {
      assignee: {
        assignment_created: t(
          "assignmentDynamicMess.new_assignment_of_following"
        ),

        assignment_reminder: t(
          "assignmentDynamicMess.reminder_you_have_due_in"
        ),

        assignment_passed: t(
          "assignmentDynamicMess.congrats_completed_assignment_following"
        ),

        assignment_failed: t(
          "assignmentDynamicMess.failed_completed_assignments"
        ),

        assignment_overdue: t(
          "assignmentDynamicMess.overdue_notification_body"
        ),
      },
      escalator: {
        assignment_created: t(
          "assignmentDynamicMess.user_has_new_assignment_following"
        ),

        assignment_reminder: t(
          "assignmentDynamicMess.user_has_due_in_days_following"
        ),

        assignment_passed: t(
          "assignmentDynamicMess.user_has_completed_following"
        ),

        assignment_failed: t("assignmentDynamicMess.user_has_failed_following"),
      },
    },
    inApp: {
      assignee: {
        assignment_created: t("assignmentDynamicMess.you_have_new_assignment"),

        assignment_reminder: t(
          "assignmentDynamicMess.reminder_assignment_due_in_days"
        ),

        assignment_passed: t(
          "assignmentDynamicMess.congrats_completed_assignment"
        ),

        assignment_failed: t("assignmentDynamicMess.user_missed_deadline"),

        assignment_overdue: t(
          "assignmentDynamicMess.overdue_notification_body"
        ),
      },
      escalator: {
        assignment_created: t("assignmentDynamicMess.user_has_new_assignment"),

        assignment_reminder: t("assignmentDynamicMess.user_has_due_in_days"),

        assignment_passed: t("assignmentDynamicMess.user_has_completed"),

        assignment_failed: t("assignmentDynamicMess.user_has_failed"),
      },
    },
  };

  return { makeContentList, insertOptions, parseTemplate, templateOptions };
};
