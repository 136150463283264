import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

type LogoutTimerModalProps = {
  activate: () => void;
  getRemainingTime: () => number;
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
};

export default function LogoutTimerModal({
  activate,
  getRemainingTime,
  modalOpen,
  setModalOpen,
}: LogoutTimerModalProps) {
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    setModalOpen(false);
    activate();
  };

  return (
    <Modal
      isOpen={modalOpen}
      isCentered
      size="3xl"
      onClose={() => handleStillHere()}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <VStack spacing={4} marginY={12}>
            <Text as="b">Are you still here?</Text>

            <Text>Logging out in {remaining} seconds.</Text>
            <Button onClick={() => handleStillHere()}>I'm still here.</Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
