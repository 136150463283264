import {
  Assessment,
  UserAssessment,
} from "@/client/types/content/assessments/Assessment";

import { create } from "zustand";

interface TextAssessmentStore {
  assessment: Assessment;
  setAssessment: (assessment: Assessment) => void;
  userAssessment: UserAssessment;
  setUserAssessment: (userAssessment: UserAssessment) => void;
  viewingAssessment: boolean;
  setViewingAssessment: (viewing: boolean) => void;
  inProgressResponses: { questionId: string; answers: string[] }[];
  setInProgressResponses: (
    responses: { questionId: string; answers: string[] }[]
  ) => void;
  answeredQuestions: number;
  setAnsweredQuestions: (answeredQuestions: number) => void;
  progress: number;
  setProgress: (progress: number) => void;
  canSubmit: boolean;
  setCanSubmit: (canSubmit: boolean) => void;
  showIntro: boolean;
  setShowIntro: (showIntro: boolean) => void;
  inTrack: boolean | undefined;
  setInTrack: (inTrack: boolean | undefined) => void;
  inJourney: boolean | undefined;
  setInJourney: (inJourney: boolean | undefined) => void;
  reset: () => void;
}

export const useTextAssessmentStore = create<TextAssessmentStore>((set) => ({
  assessment: {} as Assessment,
  setAssessment: (assessment: Assessment) => set(() => ({ assessment })),
  userAssessment: {} as UserAssessment,
  setUserAssessment: (userAssessment: UserAssessment) =>
    set(() => ({ userAssessment })),
  viewingAssessment: false,
  setViewingAssessment: (viewing: boolean) =>
    set(() => ({ viewingAssessment: viewing })),
  inProgressResponses: [],
  setInProgressResponses: (
    responses: { questionId: string; answers: string[] }[]
  ) => set(() => ({ inProgressResponses: responses })),
  answeredQuestions: 0,
  setAnsweredQuestions: (answeredQuestions: number) =>
    set(() => ({ answeredQuestions })),
  progress: 0,
  setProgress: (progress: number) => set(() => ({ progress })),
  canSubmit: false,
  setCanSubmit: (canSubmit: boolean) => set(() => ({ canSubmit })),
  showIntro: false,
  setShowIntro: (showIntro: boolean) => set(() => ({ showIntro })),
  inTrack: false,
  setInTrack: (inTrack: boolean | undefined) => set(() => ({ inTrack })),
  inJourney: false,
  setInJourney: (inJourney: boolean | undefined) => set(() => ({ inJourney })),
  reset: () => {
    set(() => ({
      assessment: {} as Assessment,
      userAssessment: {} as UserAssessment,
      viewingAssessment: false,
      inProgressResponses: [],
      answeredQuestions: 0,
      progress: 0,
      canSubmit: false,
      showIntro: false,
      inTrack: false,
      inJourney: false,
    }));
  },
}));
