import { ImportedContentSubmissionData } from "@/client/types/admin/content-authoring/courses/imported-content/ImportedContentSubmissionData";
import { learnApiClient } from "../../clients/learnApiClient";

const saveImportedContent = async (
  data: ImportedContentSubmissionData,
  contentId?: string
) => {
  let url = "/imported-content";

  if (contentId) {
    url = `/imported-content/${contentId}`;
  }

  const response = await learnApiClient.post(url, data);

  return response.data;
};

const AdminImportedContentService = {
  saveImportedContent,
};

export default AdminImportedContentService;
