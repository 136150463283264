import PeopleService from "@/client/services/api/PeopleService";
import { useInfiniteQuery } from "@tanstack/react-query";

const perPage = 10;

export const useGetOrganizationMembers = () =>
  useInfiniteQuery({
    queryKey: ["people"],
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam = 1 }) =>
      PeopleService.getOrganizationMembers(pageParam, perPage),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === perPage ? pages.length + 1 : undefined,
  });
