import { addDays, addMonths, differenceInDays, format } from "date-fns";
import ReportsService from "@/client/services/api/admin/reports/ReportsService";
import { Range } from "react-calendar/dist/cjs/shared/types";
import {
  AssignmentsOnTimeRawData,
  AssignmentsFromWorkflowData,
  AssignedCompletionsRawData,
} from "@/client/types/Charts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useToastStore } from "@/client/services/state/toastStore";
import { useQuery } from "@tanstack/react-query";
import ProductsService from "../../api/graphql/ProductsService";
import { ProductReportDatum } from "../../api/graphql/gql/graphql";

export const useGetReportData = (defaultMonthsAgo: number = 3) => {
  const defaultDateRange: Range<any> = [
    addMonths(new Date().setHours(0, 0, 0, 0), -defaultMonthsAgo),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ];
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [data, setData] = useState<any[]>([]);
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  const getDataBasedOnDateRange = async (fetchDateRange: () => any) => {
    if (!dateRange) {
      // If the date range is empty, force it to the default date range and do it again.
      setDateRange(defaultDateRange);
      return;
    }
    const daysDiff = Array.isArray(dateRange)
      ? differenceInDays(dateRange[1], dateRange[0])
      : 0;
    if (daysDiff > 732) {
      setToast({
        show: true,
        status: "error",
        title: t("partners.manager.dashboard.dateRangeError"),
      });
      return;
    }
    await fetchDateRange();
  };

  const getAssignedCompletionsData = useQuery({
    queryKey: ["assigned-completions", dateRange],
    queryFn: () => ReportsService.getPartnerAssignedCompletions(dateRange),
    select: (returnedData: AssignedCompletionsRawData) => {
      if (returnedData && Object.keys(returnedData)) {
        return [
          {
            id: "Unassigned",
            label: t(
              "partners.manager.dashboard.assignedCompletions.unassigned"
            ),
            symbol: 0,
            data: Object.entries(returnedData).map((raw: any[]) => ({
              symbol: 0,
              x: raw[0],
              y: raw[1].unassigned,
            })),
          },
          {
            id: "Assigned",
            label: t("partners.manager.dashboard.assignedCompletions.assigned"),
            symbol: 0,
            data: Object.entries(returnedData).map((raw: any[]) => ({
              symbol: 0,
              x: raw[0],
              y: raw[1].assigned,
            })),
          },
        ];
      }
      return [];
    },
  });

  const getAssignmentsOnTimeData = useQuery({
    queryKey: ["assignments-on-time", dateRange],
    queryFn: () => ReportsService.getPartnerAssignmentsOnTime(dateRange),
    select: (returnedData: AssignmentsOnTimeRawData) => {
      if (returnedData && Object.keys(returnedData)) {
        return Object.keys(returnedData).map((returnedDates) => ({
          time_range: returnedDates,
          ...returnedData[returnedDates as keyof AssignmentsOnTimeRawData],
        }));
      }
      return [];
    },
  });

  const getAssignmentsFromWorkflowData = useQuery({
    queryKey: ["assignments-by-workflow", dateRange],
    queryFn: () => ReportsService.getPartnerAssignmentsByWorkflow(dateRange),
    select: (returnedData: AssignmentsFromWorkflowData[]) => {
      if (returnedData && returnedData.length > 0) {
        return [
          {
            id: "Workflow Assignments",
            label: t(
              "partners.manager.dashboard.assignmentsFromWorkflows.workflow"
            ),
            data: returnedData.map((d: AssignmentsFromWorkflowData) => ({
              x: d.date,
              y: d.count_triggered,
            })),
          },
          {
            id: "Manual Assignments",
            label: t(
              "partners.manager.dashboard.assignmentsFromWorkflows.manual"
            ),
            data: returnedData.map((d: AssignmentsFromWorkflowData) => ({
              x: d.date,
              y: d.count_untriggered,
            })),
          },
        ];
      }
      return [];
    },
  });

  const addDateKeys = (
    startDate: Date,
    endDate: Date,
    reportData: ProductReportDatum[],
    dateFormat: string = "yyyy-MM-dd"
  ) => {
    if (startDate > endDate) {
      console.error("Cannot add date keys; invalid dates");
      return reportData;
    }
    const dataWithKeys: ProductReportDatum[] = [];
    let counterDate = startDate;
    reportData.forEach((datum) => {
      dataWithKeys.push({
        ...datum,
        date: format(counterDate, dateFormat),
      });
      counterDate = addDays(counterDate, 1);
    });
    return dataWithKeys;
  };

  const convertToDollars = (reportData: ProductReportDatum[]) => {
    const dataInDollars: ProductReportDatum[] = [];
    reportData.forEach((datum) => {
      dataInDollars.push({
        ...datum,
        subscription: datum.subscription ? datum.subscription / 100 : 0,
        permanent: datum.permanent ? datum.permanent / 100 : 0,
      });
    });
    return dataInDollars;
  };

  const getEcommerceRevenueReportData = useQuery({
    queryKey: ["ecommerce-revenue-report-data", dateRange[0], dateRange[1]],
    queryFn: () =>
      ProductsService.getRevenueReportData({
        dateFilter: {
          after: dateRange[0],
          before: dateRange[1],
        },
        type: "revenue",
      }),
    select: (returnedData: ProductReportDatum[]) => {
      const dataWithKeys = addDateKeys(
        dateRange[0],
        dateRange[1],
        returnedData || []
      );
      return convertToDollars(dataWithKeys);
    },
  });

  const getEcommercePurchasesReportData = useQuery({
    queryKey: ["ecommerce-purchases-report-data", dateRange[0], dateRange[1]],
    queryFn: () =>
      ProductsService.getRevenueReportData({
        dateFilter: {
          after: dateRange[0],
          before: dateRange[1],
        },
        type: "purchases",
      }),
    select: (returnedData: ProductReportDatum[]) =>
      addDateKeys(dateRange[0], dateRange[1], returnedData || []),
  });

  return {
    defaultDateRange,
    data,
    setData,
    dateRange,
    setDateRange,
    getDataBasedOnDateRange,
    getAssignedCompletionsData,
    getAssignmentsOnTimeData,
    getAssignmentsFromWorkflowData,
    getEcommerceRevenueReportData,
    getEcommercePurchasesReportData,
  };
};
