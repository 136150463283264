export const Menu = {
  baseStyle: {
    list: {
      marginTop: -2,
      zIndex: "999",
      borderColor: "brand.primary",
    },
  },
  variants: {
    adminTableActions: {
      list: {
        borderRadius: "10px",
        minWidth: "90px",
        border: "none",
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
      },
      item: {
        fontWeight: 500,
      },
    },
    admin: {
      list: {
        borderRadius: "10px",
        border: "0 solid",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
      item: {
        fontSize: "sm",
        fontWeight: "500",
        _hover: {
          backgroundColor: "brand.backgroundGrey",
        },
      },
    },
    userMenu: {
      list: {
        borderRadius: "0px",
        border: "0 solid",
      },
      item: {
        fontSize: "sm",
        fontWeight: "500",
        _hover: {
          backgroundColor: "brand.mist",
        },
      },
    },
    workshopfilter: {
      list: {
        borderRadius: "10px",
        border: "0 solid",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
      item: {
        fontSize: "sm",
        fontWeight: "500",
        _hover: {
          backgroundColor: "brand.mist",
        },
      },
    },
    create: {
      list: {
        borderRadius: "md",
        border: "none",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        paddingX: 4,
      },
      item: {
        fontSize: "sm",
        fontWeight: "500",
        borderBottom: "1px solid",
        _hover: {
          backgroundColor: "brand.mist",
        },
      },
    },
  },
};
