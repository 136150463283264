import { Navigate, Outlet } from "react-router-dom";

import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";

export default function AdminConnectLayout() {
  const { allowance } = useAccessHelper();

  if (
    !(
      allowance.connectCategories ||
      allowance.connectLocations ||
      allowance.connectDepartments ||
      allowance.connectTeams ||
      allowance.connectLevels ||
      allowance.connectGrades ||
      allowance.connectGroups ||
      allowance.connectSkills
    )
  )
    return <Navigate to="/explore" replace />;

  return <Outlet />;
}
