import { useMutation, useQuery } from "@tanstack/react-query";

import AnalyzeService from "@/client/services/api/AnalyzeService";
import { ArticleFormData } from "@/client/routes/admin/create/content/EditArticle";
import type { ArticleSubmissionData } from "@/client/types/admin/content-authoring/article/ArticleSubmissionData";
import AuthoringArticleService from "@/client/services/api/admin/content-authoring/AuthoringArticleService";
import AuthoringGraphqlService from "@/client/services/api/admin/content-authoring/AuthoringGraphqlService";
import type { AxiosError } from "axios";
import { trackEvent } from "@/client/utils/AnalyticsProvider";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTrackStateStore } from "@/client/services/state/admin/create/trackStateStore";

export const useAuthorArticle = (articleId: string | undefined) => {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setToast } = useToastStore();
  const { trackState, setTrackState } = useTrackStateStore();
  const navigate = useNavigate();

  const { watch, setValue } = useFormContext<ArticleFormData>();

  const getArticleForEditor = useQuery({
    enabled: !!articleId,
    queryKey: ["article-for-editor", articleId, user._id],
    queryFn: () =>
      AuthoringGraphqlService.getArticleForEditor(articleId, user._id),
  });

  const description = watch("description");

  const readingTime = () => {
    if (typeof description !== "string") {
      return;
    }

    const words = description.trim().split(/\s+/g).length;
    const wordsPerSecond = 270 / 60;

    setValue("duration", Math.round(words / wordsPerSecond));
  };

  useEffect(() => {
    readingTime();
  }, [description]);

  const submitArticlePayload: Omit<
    ArticleSubmissionData,
    "approval_required" | "approved" | "draft" | "_id"
  > = {
    allow_comments: watch("allowComments"),
    author: watch("author")!,
    banner_image: watch("bannerImage"),
    content: watch("description"),
    duration: watch("duration"),
    explore_hide: !watch("exploreHide"),
    image: watch("image"),
    linked_category: watch("linkedCategory")?.map((category) => category.id),
    privacy_collaborators: watch("privacyCollaborators")?.map(
      (collaborator) => collaborator.id
    ),
    privacy_locations: watch("privacyLocations")?.map(
      (location) => location.id
    ),
    privacy_departments: watch("privacyDepartments")?.map(
      (department) => department.id
    ),
    privacy_teams: watch("privacyTeams")?.map((team) => team.id),
    privacy_org_levels: watch("privacyOrgLevels")?.map(
      (orgLevel) => orgLevel.id
    ),
    privacy_groups: watch("privacyGroups")?.map((group) => group.id),
    privacy_grades: watch("privacyGrades")?.map((grade) => grade.id),
    private: watch("private"),
    rating_configuration: {
      allow_edits: watch("ratingConfiguration.allowEdits"),
      allow_feedback: watch("ratingConfiguration.allowFeedback"),
      allow_rating: watch("ratingConfiguration.allowRating"),
      messaging: {
        individuals: watch("ratingConfiguration.messaging.individuals").map(
          (individual) => individual.id
        ),
        slack_channels: watch(
          "ratingConfiguration.messaging.slackChannels"
        ).map((channel) => channel.id),
      },
      notify_feedback_only: watch("ratingConfiguration.notifyFeedbackOnly"),
      show_users_rating: watch("ratingConfiguration.showUsersRating"),
      total_value: watch("ratingConfiguration.totalValue"),
    },
    summary: watch("description"),
    surveys: watch("surveys")?.map((survey) => ({ survey_id: survey.id })),
    // TODO: Fix this circular type dependency
    // @ts-ignore
    tags: watch("tags"),
    type: "article",
    title: watch("title"),
    user: watch("id") ? watch("creator") : user._id,
    partner_permissions: watch("partnerPermissions"),
  };

  type SubmitArticleMutationVariables = Pick<
    ArticleSubmissionData,
    "approval_required" | "approved" | "draft"
  >;

  const submitArticleMutation = useMutation({
    mutationFn: (variables: SubmitArticleMutationVariables) =>
      AuthoringArticleService.createArticle(
        { ...submitArticlePayload, ...variables },
        watch("id")
      ),
    onSuccess: (successData) => {
      if (watch("id")) {
        AnalyzeService.recordContentModifications({
          document: successData._id,
          type: "article",
        });

        setToast({
          show: true,
          status: "success",
          title: "Article Successfully Updated",
        });
      }

      AnalyzeService.recordContentCreation({
        document: successData._id,
        type: "article",
      });

      trackEvent("article_created", {
        content: successData._id,
        content_type: "article",
        title: successData.title,
      });

      setToast({
        show: true,
        status: "success",
        title: "Article Successfully Created",
      });

      if (trackState) {
        setTrackState({
          ...trackState,
          contentId: successData._id,
          contentTitle: successData.title,
          returningFromContent: true,
        });

        navigate(`/admin/edit/course/track/${trackState.track}`);

        return;
      }

      navigate(`/article/${successData._id}`);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      if (watch("id")) {
        console.error("Error Updating Article", error.response?.data?.message);

        setToast({
          show: true,
          status: "error",
          title: error.response?.data?.message || "Error Updating Article",
        });

        return;
      }

      console.error("Error Creating Article", error.response?.data?.message);

      setToast({
        show: true,
        status: "error",
        title: error.response?.data?.message || "Error Creating Article",
      });
    },
  });

  return {
    getArticleForEditor,
    submitArticleMutation,
  };
};
