import { learnApiClient } from "./clients/learnApiClient";

const searchAllUsers = async (
  query: string,
  partnerId?: string | undefined | null
) => {
  let partnerFilter;
  if (partnerId) {
    partnerFilter = `&partner=${partnerId}`;
  }
  if (partnerId === null) {
    partnerFilter = "&partner=$eq,null";
  }

  const response = await learnApiClient.get(
    `users?fields=first_name,last_name,email,full_name,role,image,office_location,last_login&full_name=$regex,2,${query},i&page=1&per_page=20&role=$ne,external${partnerFilter}`
  );

  return response.data;
};

export const SearchService = { searchAllUsers };
