import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export default function NotAuthorized() {
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  useEffect(() => {
    setToast({
      show: true,
      status: "error",
      title: t("fileviewer.error_permission"),
    });
  }, []);

  return <Navigate to="/explore" />;
}
