import type {
  InstructorCreateInput,
  InstructorUpdateInput,
} from "@/client/services/api/graphql/gql/graphql";

import AdminInstructorsService from "@/client/services/api/admin/content-authoring/AdminInstructorsService";
import { useMutation } from "@tanstack/react-query";
import { useToastStore } from "@/client/services/state/toastStore";

export const useEditInstructors = () => {
  const { setToast } = useToastStore();

  const createInstructorMutation = useMutation({
    mutationFn: (payload: InstructorCreateInput) =>
      AdminInstructorsService.createInstructor(payload),
    onError: (error: any) => {
      // TODO: We need to do a better job of handling GraphQL errors acrross the entire application.

      setToast({
        show: true,
        status: "error",
        title: error.response.errors[0].message || "Instructor Creation Failed",
      });
    },
  });

  interface UpdateInstructorMutationVariables {
    id: string;
    payload: InstructorUpdateInput;
  }

  const updateInstructorMutation = useMutation({
    mutationFn: ({ id, payload }: UpdateInstructorMutationVariables) =>
      AdminInstructorsService.updateInstructor(id, payload),
    onError: (error: any) => {
      // TODO: We need to do a better job of handling GraphQL errors acrross the entire application.

      setToast({
        show: true,
        status: "error",
        title: error.response.errors[0].message || "Instructor Update Failed",
      });
    },
  });

  return {
    createInstructorMutation,
    updateInstructorMutation,
  };
};
