import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";

import { Box } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { HTMLAttributes } from "react";
import ReactDatePicker from "react-datepicker";
import { isValid } from "date-fns";

// TODO: Leaving this for future troubleshooting reference
// TODO: When CustomInpit is used, the datepicker manual date input is not working properly
// const CustomInput = forwardRef(({ value, onClick, onChange, isDisabled }: any, ref: any) => (
//   <Input
//     autoComplete="off"
//     background="white"
//     value={value}
//     ref={ref}
//     onClick={onClick}
//     onChange={onChange}
//     isDisabled={isDisabled}
//   />
// ));

interface DatePickerProps {
  onChange: any;
  selectedDate: Date | undefined;
  isDisabled: boolean;
  isClearable?: boolean;
  showPopperArrow?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

export default function DatePicker({
  selectedDate,
  onChange,
  isDisabled,
  isClearable = false,
  showPopperArrow = false,
  maxDate,
  minDate,
}: DatePickerProps & HTMLAttributes<HTMLElement>) {
  return (
    <Box position="relative" zIndex={999}>
      {/* <InputGroup> */}
      <ReactDatePicker
        selected={selectedDate}
        onChange={(date: Date) => {
          if (!isValid(date)) return;

          onChange(date);
        }}
        showIcon
        icon={<CalendarIcon fontSize="sm" />}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className="react-datapicker__input-text"
        dateFormat="yyyy/MM/dd"
        // customInput={<CustomInput isDisabled={isDisabled} />}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={isDisabled}
      />

      {/* <InputRightElement color="gray.500">
          <CalendarIcon fontSize="sm" />
        </InputRightElement> */}
      {/* </InputGroup> */}
    </Box>
  );
}

DatePicker.defaultProps = {
  isClearable: false,
  showPopperArrow: false,
  maxDate: null,
  minDate: null,
};
