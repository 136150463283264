import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";
import { useEffect, useRef, useState } from "react";

import ImportedContentSearchFilterMenu from "@/client/components/admin/create/content/forms/tracks/imported-content/ImportedContentSearchFilterMenu";
import { SearchIcon } from "@chakra-ui/icons";
import { learnV4ApiClient } from "@/client/services/api/clients/learnV4ApiClient";
import { useCreateStore } from "@/client/services/state/admin/create/createStore";
import { useDebounceValue } from "usehooks-ts";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { CourseFormData } from "@/client/routes/admin/create/content/tracks/EditCourse";

const contentTypeOptions = [
  { name: "All Types", value: "articles,videos,files,scorm,assessments" },
  { name: "Articles", value: "articles" },
  { name: "Videos", value: "videos" },
  { name: "Files", value: "files" },
  { name: "SCORM", value: "scorm" },
  { name: "Assessments", value: "assessments" },
];

const contentTypeMap: { [key: string]: string } = {
  article: "Article",
  file: "Media",
  video: "Media",
  scorm: "ScormContent",
  assessment: "Assessment",
};

export default function ContentSearchDrawer() {
  const {
    instructorDrawerIsOpen,
    setInstructorDrawerIsOpen,
    selectedSectionIndex,
  } = useCreateStore();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedContentType, setSelectedContentType] = useState<{
    name: string;
    value: string | null;
  } | null>(contentTypeOptions[0]);
  const [searchTerm, setSearchTerm] = useDebounceValue("", 300);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchTerm("aa");
      setSelectedContentType(contentTypeOptions[0]);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!instructorDrawerIsOpen) {
      setSelectedContentType(contentTypeOptions[0]);
      setSearchTerm("");
    }
  }, [instructorDrawerIsOpen]);

  const { watch, setValue } = useFormContext<CourseFormData>();

  const sections = watch("sections");

  const handleItemClick = (item: any, isSelected: boolean) => {
    const section = sections?.[selectedSectionIndex] ?? { courses: [] };

    if (isSelected) {
      section.courses =
        section.courses?.filter((course: any) => course._id !== item._id) || [];
      // @ts-ignore
      setValue("sections", sections);
      return;
    }

    (section.courses || []).push(item);

    setValue("sections", sections);
  };

  const { data, isFetching } = useQuery({
    queryKey: [
      "track-existing-content-search",
      selectedContentType?.value,
      searchTerm,
    ],
    queryFn: async () => {
      const response = await learnV4ApiClient.get("admin-content/analytics", {
        params: {
          $or: `10,title,$regex,2,${searchTerm},i,name,$regex,2,${searchTerm},i`,
          page: "1",
          per_page: "20",
          types: selectedContentType?.value,
        },
      });

      return response.data.data;
    },
  });

  return (
    <Drawer
      isOpen={instructorDrawerIsOpen}
      placement="right"
      size="md"
      onClose={() => setInstructorDrawerIsOpen(false)}
      initialFocusRef={inputRef}
    >
      <DrawerOverlay />

      <DrawerContent margin={6} borderRadius="16px">
        <DrawerCloseButton />

        <DrawerBody paddingTop={10}>
          <Stack>
            <Text fontSize="24px" fontWeight={600}>
              Add Existing Content
            </Text>

            <InputGroup>
              <InputLeftElement>
                <SearchIcon color="neutral.500" />
              </InputLeftElement>

              <Input
                ref={inputRef}
                variant="create"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>

            <ImportedContentSearchFilterMenu
              label="Filter"
              options={contentTypeOptions}
              selected={selectedContentType}
              setSelected={setSelectedContentType}
            />
          </Stack>

          {!isFetching && data && data.length > 0 && (
            <Stack marginTop={10}>
              {data.map((item: any) => {
                const isSelected = sections?.[
                  selectedSectionIndex
                ]?.courses?.some((course: any) => course._id === item._id);

                return (
                  <Box
                    key={item._id}
                    borderBottom="1px solid"
                    borderBottomColor="neutral.100"
                    paddingY={2}
                  >
                    <Flex
                      padding={4}
                      border="1px solid"
                      borderColor="transparent"
                      borderRadius="6px"
                      alignItems="center"
                      justifyContent="space-between"
                      _hover={{
                        backgroundColor: "warmNeutral.0",
                        cursor: "pointer",
                        borderColor: "warmNeutral.200",
                      }}
                      onClick={() =>
                        handleItemClick(item, isSelected as boolean)
                      }
                    >
                      <Flex>
                        <Box
                          minHeight="60px"
                          minWidth="120px"
                          height="60px"
                          width="120px"
                          borderRadius="6px"
                          overflow="hidden"
                          backgroundColor="warmNeutral.200"
                          marginRight={6}
                        >
                          {item.image && <Image src={item.image} />}
                        </Box>

                        <Stack>
                          <Text
                            color="warmNeutral.1000"
                            fontWeight={600}
                            fontSize="14px"
                          >
                            {contentTypeMap[item.type]}
                          </Text>

                          <Text>{item.title}</Text>
                        </Stack>
                      </Flex>

                      <Icon
                        boxSize={8}
                        as={isSelected ? CiCircleCheck : CiCirclePlus}
                        color={isSelected ? "warmNeutral.500" : "neutral.600"}
                      />
                    </Flex>
                  </Box>
                );
              })}
            </Stack>
          )}

          {isFetching && (
            <Center marginTop={10}>
              <Spinner color="warmNeutral.500" />
            </Center>
          )}

          {data && data.length === 0 && (
            <Center marginTop={10}>
              <Text>No Results</Text>
            </Center>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
