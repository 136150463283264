import { format } from "date-fns";
import { useAuthStore } from "@/client/services/state/authStore";

export const useFormatDate = () => {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { date_display: dateDisplay } = company;

  const formatOptions = {
    usa: {
      base: "MM-dd-yyyy",
      baseWithTime: "MM-dd-yyyy p",
      dateSlash: "MM/dd/yyyy",
      time: "h:mmaa",
      long: "EEE, MMM do @ h:mmaa",
      formal: "MMMM do, yyyy",
    },
    australia: {
      base: "dd-MM-yyyy",
      baseWithTime: "dd-MM-yyyy p",
      dateSlash: "dd/MM/yyyy",
      time: "h:mmaa",
      long: "EEE, MMM do @ h:mmaa",
      formal: "MMMM do, yyyy",
    },
  };

  const formatDate = (
    date: Date,
    formatOptionKey?:
      | "base"
      | "dateSlash"
      | "time"
      | "long"
      | "formal"
      | "baseWithTime"
  ) => {
    const formatGroup =
      dateDisplay === "australia" ? formatOptions.australia : formatOptions.usa;
    const formatOption = formatOptionKey
      ? formatGroup[formatOptionKey]
      : formatGroup.base;

    return format(date, formatOption);
  };

  return {
    formatDate,
  };
};
