/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Flex, HStack, Input, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { AuthoringEmbedLink } from "@/client/components/icons/ContinuIcons";
import { CloseIcon } from "@chakra-ui/icons";
import { EmbedSettings } from "@/client/types/admin/content-authoring/files/FileSubmissionData";
import IconButtonWithTooltip from "@/client/components/buttons/IconButtonWithTooltip";
import { useAuthorFile } from "@/client/services/hooks/admin/authoring/UseAuthorFile";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function FileEmbedForm() {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const [embedLink, setEmbedLink] = useState("");
  const { saveInitialDraft, draftPayload } = useAuthorFile();

  const currentLink = watch("link");
  const fileId = watch("id");

  useEffect(() => {
    if (currentLink) {
      setEmbedLink(currentLink);
    }
  }, []);

  const linkMatch = (link: string) => {
    if (link.match("prezi.com")) {
      if (!link.match("/embed")) {
        link = link + "embed";
      }

      return {
        link,
        type: "prezi",
        ratio: ".5625",
      };
    }

    if (link.match("app.box.com")) {
      return {
        type: "box",
        ratio: "1.2941",
      };
    }

    if (link.match("wistia.com")) {
      return {
        type: "wistia",
        ratio: ".5625",
      };
    }

    if (link.match("soundcloud.com")) {
      return {
        type: "soundcloud",
        ratio: ".18",
      };
    }

    if (link.match("slideshare.net")) {
      return {
        type: "slideshare",
        ratio: ".75",
      };
    }

    if (link.match("spotify.com")) {
      return {
        type: "spotify",
        ratio: ".333",
      };
    }

    if (link.match("docs.google.com")) {
      if (link.match("presentation")) {
        return {
          type: "google-presentation",
          ratio: ".5625",
        };
      }

      return {
        type: "google-doc",
        ratio: "1.2941",
      };
    }

    return {
      type: "other",
      ratio: ".5625",
    };
  };

  const roundToXDigits = (value: number, digits: number) => {
    if (!digits) {
      digits = 2;
    }

    value = value * 10 ** digits;
    value = Math.round(value);
    value = value / 10 ** digits;

    return value;
  };

  const addEmbed = (embedText: string) => {
    if (!embedText) {
      return;
    }

    let embedSettings: EmbedSettings;
    let height;
    let width;
    let link;

    if (embedText.indexOf("<iframe") !== -1) {
      const srcSplit = embedText.split('src="');

      if (srcSplit && srcSplit.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        link = srcSplit[1].split('"')[0];
      }

      const heightSplit = embedText.split('height="');

      if (heightSplit && heightSplit.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        height = heightSplit[1].split('"')[0];
      }

      const widthSplit = embedText.split('width="');

      if (widthSplit && widthSplit.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        width = widthSplit[1].split('"')[0];
      }
    }

    const type = linkMatch(link || embedText);

    link = type.link || link || embedText;

    delete type.link;

    // @ts-ignore
    embedSettings = type;

    if (height && width) {
      const widthToSet = width;
      //   const widthToSet =
      //     width !== '100%' ? width : document.querySelector('.contentview__hero--file').clientWidth;

      const heightToSet = height;

      embedSettings.ratio = roundToXDigits(
        parseInt(heightToSet) / parseInt(widthToSet),
        2
      );
      embedSettings.width = parseInt(widthToSet);
      embedSettings.height = parseInt(heightToSet);
    }

    setValue("embedSettings", embedSettings);

    if (link.slice(0, 5) !== "https") {
      if (link.slice(0, 4) === "http") {
        link = link.replace("http", "https");
      } else if (link.slice(0, 2) === "//") {
        link = "https:" + link;
      } else {
        link = "https://" + link;
      }
    }

    setValue("link", link);

    if (!fileId) {
      saveInitialDraft.mutateAsync({ ...draftPayload, link });
    }
  };

  return (
    <>
      {currentLink && (
        <Flex
          backgroundColor="warmNeutral.100"
          border="1px solid"
          borderColor="warmNeutral.200"
          borderRadius="md"
          paddingY={4}
          paddingX={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <AuthoringEmbedLink boxSize={8} />

            <Text color="baseBlack" fontWeight={600} wordBreak="break-word">
              {currentLink}
            </Text>
          </Stack>

          <IconButtonWithTooltip
            tooltipCopy="Replace Link"
            icon={<CloseIcon color="brand.gold.100" />}
            ariaLabel="Replace Link"
            onClick={() => {
              setValue("link", "");
              setEmbedLink("");
            }}
          />
        </Flex>
      )}

      {!currentLink && (
        <Stack>
          <Text variant="createLabel">
            {t("authoring.file.embedExternalContent")}
          </Text>

          <HStack>
            <Input
              value={embedLink}
              variant="create"
              isRequired
              placeholder="https://www.example.com/12345678"
              onChange={(e) => setEmbedLink(e.target.value)}
            />

            <Button
              isDisabled={embedLink === "" || embedLink === currentLink}
              type="submit"
              paddingY={2}
              paddingX={3}
              variant="createGoldOutline"
              onClick={() => addEmbed(embedLink)}
            >
              {t("authoring.file.saveExternalLink")}
            </Button>
          </HStack>
        </Stack>
      )}
    </>
  );
}
