import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCirclePlus } from "react-icons/ci";
import {
  Instructor,
  InstructorReadModel,
} from "@/client/services/api/graphql/gql/graphql";

import AdminInstructorsService from "@/client/services/api/admin/content-authoring/AdminInstructorsService";
import type { CourseFormData } from "@/client/routes/admin/create/content/tracks/EditCourse";
import { SearchIcon } from "@chakra-ui/icons";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

interface InstructorSearchDrawerProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  drawerIsOpen: boolean;
  setDrawerIsOpen: (value: boolean) => void;
  onClose: () => void;
  setEditInstructor: (instructor: Omit<Instructor, "archived">) => void;
  setEditModalIsOpen: (value: boolean) => void;
}

export default function InstructorSearchDrawer({
  searchTerm,
  setSearchTerm,
  drawerIsOpen,
  setDrawerIsOpen,
  onClose,
  setEditInstructor,
  setEditModalIsOpen,
}: InstructorSearchDrawerProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { watch, setValue } = useFormContext<CourseFormData>();

  const { instructors } = watch();

  const { data, isFetching } = useQuery({
    queryKey: ["instructors", searchTerm],
    queryFn: () => AdminInstructorsService.searchInstructors(searchTerm),
  });

  const handleItemClick = (
    item: Omit<Instructor, "createdAt" | "updatedAt" | "archived">,
    isSelected: boolean
  ) => {
    if (!isSelected) {
      setValue("instructors", [...instructors, item as InstructorReadModel]);
    } else {
      setValue(
        "instructors",
        instructors.filter((instructor) => instructor.id !== item.id)
      );
    }
  };

  return (
    <Drawer
      isOpen={drawerIsOpen}
      placement="right"
      size="md"
      onClose={onClose}
      initialFocusRef={inputRef}
    >
      <DrawerOverlay />

      <DrawerContent margin={6} borderRadius="16px">
        <DrawerCloseButton />

        <DrawerBody paddingTop={10}>
          <Stack>
            <Text fontSize="24px" fontWeight={600} marginBottom={6}>
              {t("authoring.courses.instructors.addExisting")}
            </Text>

            <InputGroup>
              <InputLeftElement>
                <SearchIcon color="neutral.500" />
              </InputLeftElement>

              <Input
                ref={inputRef}
                variant="create"
                placeholder={`${t(
                  "authoring.courses.instructors.searchPlaceholder"
                )}`}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </Stack>

          {!isFetching && data && data.instructors.edges.length > 0 && (
            <Stack marginTop={10}>
              <Text variant="createLabel">
                {t("edit.importedcontent.instructors")}
              </Text>

              {data.instructors.edges.map((edge) => {
                const { node } = edge;
                const { id, name, image } = node;

                const isSelected = instructors.some(
                  (instructor) => instructor.id === id
                );

                return (
                  <Box key={id} paddingY={2}>
                    <Flex
                      padding={1}
                      border="1px solid"
                      borderColor="transparent"
                      borderRadius="6px"
                      alignItems="center"
                      justifyContent="space-between"
                      _hover={{
                        backgroundColor: "warmNeutral.0",
                        cursor: "pointer",
                        borderColor: "warmNeutral.200",
                      }}
                      onClick={() => handleItemClick(node, isSelected)}
                    >
                      <Flex alignItems="center">
                        <Avatar
                          size="sm"
                          marginRight={6}
                          name={name}
                          src={image as string}
                        />

                        <Text fontSize="14px" fontWeight={500}>
                          {name}
                        </Text>
                      </Flex>

                      <HStack>
                        <Box>
                          <Button
                            variant="createCancel"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();

                              setEditInstructor(node);
                              setEditModalIsOpen(true);
                              setDrawerIsOpen(false);
                            }}
                          >
                            Edit
                          </Button>
                        </Box>

                        <Icon
                          boxSize={8}
                          as={isSelected ? CiCircleCheck : CiCirclePlus}
                          color={isSelected ? "warmNeutral.500" : "neutral.600"}
                        />
                      </HStack>
                    </Flex>
                  </Box>
                );
              })}
            </Stack>
          )}

          {isFetching && (
            <Center marginTop={10}>
              <Spinner color="warmNeutral.500" />
            </Center>
          )}

          {data && data.instructors.edges.length === 0 && (
            <Center marginTop={10}>
              <Text>{t("global.forms.labels_noResults")}</Text>
            </Center>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
