import AdminUsersService from "../users/AdminUsersService";
import CategoriesService from "../../CategoriesService";
import GroupsService from "../connect/GroupsService";

export const search = async (
  searchTerm: string,
  userFilter: string,
  includeSuspendedUsers: boolean,
  enableGroups: boolean,
  onlySuspendedUsers?: boolean
) => {
  let usersWithFilterType = [];
  let categoriesWithFilterType = [];
  let groupsWithFilterType = [];

  if (!userFilter || userFilter === "users" || userFilter === "all") {
    const usersData = await AdminUsersService.searchAdminUsers(
      searchTerm,
      includeSuspendedUsers || false,
      onlySuspendedUsers || false
    );

    if (usersData && usersData.length > 0) {
      usersWithFilterType = usersData.map((user: any) => ({
        ...user,
        filterType: "User",
      }));
    }
  }

  if (!userFilter || userFilter === "categories" || userFilter === "all") {
    const categoriesData = await CategoriesService.searchCategories(
      searchTerm,
      userFilter === "locations,departments,teams,org_levels,grades"
    );

    let filteredCategories: any[] = [];

    if (categoriesData && categoriesData.length > 0) {
      filteredCategories = categoriesData.filter(
        (category: any) => category.options.length > 0
      );
    }

    if (filteredCategories.length > 0) {
      categoriesWithFilterType = filteredCategories.map((category: any) => ({
        ...category,
        options: category.options.map((option: any) => ({
          ...option,
          filterType: "Category",
        })),
      }));
    }
  }

  if (enableGroups) {
    const groupsData = await GroupsService.simpleSearch(searchTerm, {
      pageIndex: 1,
      pageSize: 40,
    });

    if (groupsData && groupsData.length > 0) {
      groupsWithFilterType = groupsData.map((group: any) => ({
        ...group,
        filterType: "Group",
      }));
    }
  }

  let data: any = [];

  if (usersWithFilterType.length > 0)
    data = [
      ...data,
      {
        title: "Users",
        options: usersWithFilterType,
      },
    ];

  if (categoriesWithFilterType.length > 0)
    data = [...data, ...categoriesWithFilterType];

  if (groupsWithFilterType.length > 0)
    data = [
      ...data,
      {
        title: "Groups",
        options: groupsWithFilterType,
      },
    ];

  return data;
};

const UserGroupSearchService = {
  search,
};

export default UserGroupSearchService;
