import {
  ArticleExtras,
  AssessmentExtras,
  Content,
  ContentTypes,
  FileExtras,
  ScormExtras,
  TrackExtras,
  VideoExtras,
} from "@/client/services/api/graphql/gql/graphql";
import {
  getImportedContentType as getImportedContentTypeQuery,
  getcontentForEditor as getcontentForEditorQuery,
} from "@/client/services/api/graphql/queries/create/content/contentForEditor";

import type { ArticleFormData } from "@/client/routes/admin/create/content/EditArticle";
import type { FileFormData } from "@/client/routes/admin/create/content/EditFile";
import type { ScormFormData } from "@/client/routes/admin/create/content/EditScorm";
import type { VideoFormData } from "@/client/routes/admin/create/content/EditVideo";
import { generatePageBreak } from "@/client/utils/admin/create/assessments/generatePageBreak";
import { graphQLClient } from "@/client/services/api/clients/graphqlClient";
import { makeId } from "@/client/utils/admin/create/makeId";

const filterContentData = (data: any) => {
  const privacyLocations =
    data.content.privacyLocations?.filter(
      (location: any) =>
        location.id !== "5a316142734d1d2932370349" &&
        location.id !== "5a316104734d1d293237031b"
    ) || [];

  const privacyDepartments =
    data.content.privacyDepartments?.filter(
      (department: any) =>
        department.id !== "5a316142734d1d2932370349" &&
        department.id !== "5a316104734d1d293237031b"
    ) || [];

  const privacyTeams =
    data.content.privacyTeams?.filter(
      (team: any) =>
        team.id !== "5a316142734d1d2932370349" &&
        team.id !== "5a316104734d1d293237031b"
    ) || [];

  const privacyOrgLevels =
    data.content.privacyOrgLevels?.filter(
      (orgLevel: any) =>
        orgLevel.id !== "5a316142734d1d2932370349" &&
        orgLevel.id !== "5a316104734d1d293237031b"
    ) || [];

  const privacyGrades =
    data.content.privacyGrades?.filter(
      (grade: any) =>
        grade.id !== "5a316142734d1d2932370349" &&
        grade.id !== "5a316104734d1d293237031b"
    ) || [];

  const privacyGroups =
    data.content.privacyGroups?.filter(
      (group: any) => group.id !== "606f5c8a89895023def821fa"
    ) || [];

  const segmentationEnabled =
    privacyLocations.length > 0 ||
    privacyDepartments.length > 0 ||
    privacyTeams.length > 0 ||
    privacyOrgLevels.length > 0 ||
    privacyGrades.length > 0 ||
    privacyGroups.length > 0;

  const surveysEnabled =
    data.content.surveys && data.content.surveys.length > 0;

  const collaboratorsEnabled =
    data.content.privacyCollaborators &&
    data.content.privacyCollaborators.length > 0;

  return {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  };
};

const getImportedContentType = async (
  id: string | undefined,
  userid: string
) => {
  const data: any = await graphQLClient.request(getImportedContentTypeQuery, {
    id,
    type: "imported-content",
    userid,
  });

  return data;
};

const getCourseForEditor = async (
  trackId: string | undefined,
  type: string,
  userid: string
): Promise<(Content & TrackExtras) | null> => {
  if (!trackId) return null;

  const data: any = await graphQLClient.request(getcontentForEditorQuery, {
    id: trackId,
    type,
    userid,
  });

  const {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  } = filterContentData(data);

  return {
    ...data.content,
    ...data.content.currentVersion,
    exploreHide: !data.content.exploreHide,
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    enable_segmentation: segmentationEnabled,
    enable_surveys: surveysEnabled,
    enable_collaborators: collaboratorsEnabled,
  };
};

const getAssessmentForEditor = async (
  id: string | undefined,
  userid: string
): Promise<(Content & AssessmentExtras) | null> => {
  if (!id) return null;

  const data: any = await graphQLClient.request(getcontentForEditorQuery, {
    id,
    type: ContentTypes.Assessment.toLowerCase(),
    userid,
  });

  // ! Keeping this commented for reference to what things looked like before adding check for page breaks
  // data.content.currentVersion.questions = data.content.currentVersion.questions.map((question: any) => ({
  //   ...question,
  //   questionOptions: question.questionOptions.map((option: any) => ({
  //     name: option.name,
  //     value: option.value1 ?? option.value2 ?? option.value,
  //   })),
  // }));

  let previousPageValue: number | null = null;
  let pageIndex = 0;
  let position = 0;

  const questionsWithPageBreaks = data.content.currentVersion.questions
    .sort((a: any, b: any) => a.page - b.page)
    .flatMap((question: any) => {
      const currentPageValue = question.page;
      const result = [];

      if (previousPageValue !== null && currentPageValue > previousPageValue) {
        result.push(generatePageBreak());
        pageIndex++;
        position = 0;
      }

      result.push({
        ...question,
        page: pageIndex,
        position: position++,
        questionOptions: question.questionOptions.map((option: any) => ({
          name: option.name,
          value: option.value1 ?? option.value2 ?? option.value,
        })),
      });

      previousPageValue = currentPageValue;
      return result;
    });

  data.content.currentVersion.questions = questionsWithPageBreaks;

  const {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  } = filterContentData(data);

  return {
    ...data.content,
    ...data.content.currentVersion,
    exploreHide: !data.content.exploreHide,
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    enable_segmentation: segmentationEnabled,
    enable_surveys: surveysEnabled,
    enable_collaborators: collaboratorsEnabled,
    enable_additional_graders:
      data.content.currentVersion.assignedGraders.length > 0,
  };
};

const getVideoForEditor = async (
  id: string | undefined,
  userid: string
): Promise<(Content & VideoExtras & VideoFormData) | null> => {
  if (!id) return null;

  const data: any = await graphQLClient.request(getcontentForEditorQuery, {
    id,
    type: ContentTypes.Video.toLowerCase(),
    userid,
  });

  const {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  } = filterContentData(data);

  return {
    ...data.content,
    ...data.content.currentVersion,
    exploreHide: !data.content.exploreHide,
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    enable_segmentation: segmentationEnabled,
    enable_surveys: surveysEnabled,
    enable_collaborators: collaboratorsEnabled,
  };
};

const getScormForEditor = async (
  id: string | undefined,
  userid: string
): Promise<(Content & ScormExtras & ScormFormData) | null> => {
  if (!id) return null;

  const data: any = await graphQLClient.request(getcontentForEditorQuery, {
    id,
    type: ContentTypes.Scorm.toLowerCase(),
    userid,
  });

  const {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  } = filterContentData(data);

  return {
    ...data.content,
    ...data.content.currentVersion,
    exploreHide: !data.content.exploreHide,
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    enable_segmentation: segmentationEnabled,
    enable_surveys: surveysEnabled,
    enable_collaborators: collaboratorsEnabled,
  };
};

const getArticleForEditor = async (
  id: string | undefined,
  userid: string
): Promise<(Content & ArticleExtras & ArticleFormData) | null> => {
  if (!id) return null;

  const data: any = await graphQLClient.request(getcontentForEditorQuery, {
    id,
    type: ContentTypes.Article.toLowerCase(),
    userid,
  });

  const {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  } = filterContentData(data);

  return {
    ...data.content,
    ...data.content.currentVersion,
    exploreHide: !data.content.exploreHide,
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    enable_segmentation: segmentationEnabled,
    enable_surveys: surveysEnabled,
    enable_collaborators: collaboratorsEnabled,
  };
};

const getFileForEditor = async (
  id: string | undefined,
  userid: string
): Promise<(Content & FileExtras & FileFormData) | null> => {
  if (!id) return null;

  const data: any = await graphQLClient.request(getcontentForEditorQuery, {
    id,
    type: ContentTypes.File.toLowerCase(),
    userid,
  });

  const {
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    segmentationEnabled,
    surveysEnabled,
    collaboratorsEnabled,
  } = filterContentData(data);

  return {
    ...data.content,
    ...data.content.currentVersion,
    exploreHide: !data.content.exploreHide,
    privacyLocations,
    privacyDepartments,
    privacyTeams,
    privacyOrgLevels,
    privacyGrades,
    privacyGroups,
    enable_segmentation: segmentationEnabled,
    enable_surveys: surveysEnabled,
    enable_collaborators: collaboratorsEnabled,
    fileUploadType: data.content.currentVersion.embed ? "embed" : "upload",
  };
};

const AuthoringGraphqlService = {
  getImportedContentType,
  getCourseForEditor,
  getAssessmentForEditor,
  getVideoForEditor,
  getScormForEditor,
  getArticleForEditor,
  getFileForEditor,
};

export default AuthoringGraphqlService;
