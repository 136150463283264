/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint @typescript-eslint/no-unused-vars : 0 */
/* eslint arrow-body-style : 0 */
/* eslint @typescript-eslint/no-use-before-define : 0 */
/* eslint react/jsx-props-no-spreading : 0 */

import {
  Avatar,
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToken,
} from "@chakra-ui/react";
import { Check, Close, Search } from "@/client/components/icons/ContinuIcons";
import { useCombobox, useMultipleSelection } from "downshift";
import { useEffect, useRef, useState } from "react";

import { Category } from "@/client/types/Category";
import Highligher from "react-highlight-words";
import { matchSorter } from "match-sorter";
import { useDebounce } from "usehooks-ts";
import { useTranslation } from "react-i18next";

const defaultOptionFilterFunc = (items: Category[], inputValue: string) =>
  matchSorter(items, inputValue, {
    keys: ["legacyName", "full_name", "name", "title"],
  });

const defaultItemRenderer = (selected: Category | any) =>
  selected.legacyName || selected.full_name || selected.name || selected.title;

export default function MultipleTagCombobox(props: any) {
  const {
    variant,
    items,
    optionFilterFunc = defaultOptionFilterFunc,
    itemRenderer = defaultItemRenderer,
    placeholder,
    onCreateItem,
    selectedItems,
    setSearchValue,
    handleClearAll,
    isLoading,
    renderSelectedItemTags,
    ...downshiftProps
  } = props;
  const [brandPrimaryColor, inputGray] = useToken("colors", [
    "brand.primary",
    "gray.200",
  ]);
  const [isCreating, setIsCreating] = useState(false);
  const disclosureRef = useRef(null);
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [input, setInput] = useState<string | undefined>(undefined);
  const debouncedInput = useDebounce(input, 200);

  useEffect(() => {
    setSearchValue(debouncedInput);
  }, [debouncedInput]);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    activeIndex,
  } = useMultipleSelection({
    ...downshiftProps,
    selectedItems,
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: null,
          };
        default:
          return changes;
      }
    },
  });

  const flattenGroupOptions = (options: any) => {
    return options.reduce((prev: any, curr: any) => {
      return [...prev, ...curr.options];
    }, []);
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    // @ts-ignore
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectItem,
    setHighlightedIndex,
    inputValue,
  } = useCombobox({
    selectedItem: null,
    items: flattenGroupOptions(items),
    onInputValueChange: ({ inputValue }) => {
      setInput(inputValue);
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            // @ts-ignore
            if (selectedItemValues.includes(selectedItem._id)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }

            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });

  const selectedItemValues = selectedItems.map((item: any) => item._id);

  return (
    <Box
      flex={{
        base: 1,
        lg: 0,
      }}
    >
      <Stack position="relative">
        {!!selectedItems.length && renderSelectedItemTags && (
          <HStack justifyContent="flex-start" flexWrap="wrap">
            {selectedItems.map((selectedItem: any, index: number) => (
              <Tag
                variant="admin"
                margin="0"
                key={`selected_item_${index + 1}`}
                {...getSelectedItemProps({ selectedItem, index })}
              >
                <TagLabel display="inline-block" whiteSpace="nowrap">
                  {selectedItem.value ||
                    selectedItem.full_name ||
                    selectedItem.name ||
                    selectedItem.title}
                </TagLabel>

                <TagCloseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItem);
                  }}
                />
              </Tag>
            ))}
          </HStack>
        )}

        <InputGroup
          size="sm"
          backgroundColor="brand.backgroundGrey"
          borderRadius="5px"
        >
          {!selectedItems.length && (
            <InputLeftElement>
              <Search color="brand.grey.60" />
            </InputLeftElement>
          )}
          <Input
            placeholder={t("global.forms.labels_search")}
            borderRadius="5px"
            color="brand.grey.100"
            border="none"
            _placeholder={{ color: "brand.grey.60" }}
            value={inputValue}
            {...getInputProps(
              getDropdownProps({
                onClick: isOpen ? () => {} : openMenu,
                onFocus: () => {
                  if (!isOpen) openMenu();
                  setIsFocused(true);
                },
                onBlur: () => {
                  setIsFocused(false);
                },
                ref: disclosureRef,
              })
            )}
          />
          {isLoading && (
            <InputRightElement>
              <Spinner />
            </InputRightElement>
          )}

          {selectedItems.length && !isLoading && (
            <InputRightElement
              _hover={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleClearAll();
              }}
            >
              <Close color="gray.300" />
            </InputRightElement>
          )}
        </InputGroup>

        <Box
          zIndex={999}
          minWidth="100%"
          maxWidth="100%"
          maxHeight="300px"
          overflowY="auto"
          position="absolute"
          border="1px solid"
          borderRadius="md"
          borderColor={isOpen && items.length > 0 ? inputGray : "transparent"}
          top={10}
          pointerEvents={isOpen ? "auto" : "none"}
          {...getMenuProps({ ref: popoverRef })}
        >
          {isOpen && items.length > 0 && (
            <List backgroundColor="white" padding={4}>
              {
                items.reduce(
                  (results: any, section: any, sectionIndex: any) => {
                    results.sections.push(
                      <Box key={`results_section_${sectionIndex + 1}`}>
                        {section.options.length > 0 && (
                          <Text fontSize="lg" marginY={4}>
                            {section.title}
                          </Text>
                        )}

                        {section.options.length > 0 &&
                          section.options.map(
                            (option: any, optionIndex: any) => {
                              const resultIndex = results.itemIndex++;

                              return (
                                <ListItem
                                  key={`option_index_${optionIndex + 1}`}
                                  paddingX={6}
                                  paddingY={1}
                                  {...getItemProps({
                                    item: option,
                                    index: resultIndex,
                                  })}
                                  backgroundColor={
                                    highlightedIndex === resultIndex
                                      ? "brand.gray.50"
                                      : "white"
                                  }
                                >
                                  <Box>
                                    {selectedItemValues.includes(
                                      option._id
                                    ) && (
                                      <Check
                                        color="green.500"
                                        marginRight={2}
                                      />
                                    )}

                                    {option.type === "user" && (
                                      <Avatar
                                        size="xs"
                                        marginRight={2}
                                        name={option.full_name}
                                        src={option.image}
                                      />
                                    )}

                                    <Highligher
                                      autoEscape
                                      searchWords={[inputValue || ""]}
                                      textToHighlight={itemRenderer(option)}
                                      highlightStyle={{
                                        fontWeight: "bold",
                                        backgroundColor: brandPrimaryColor,
                                        color: "white",
                                      }}
                                    />
                                  </Box>
                                </ListItem>
                              );
                            }
                          )}
                      </Box>
                    );

                    return results;
                  },
                  { sections: [], itemIndex: 0 }
                ).sections
              }
            </List>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
