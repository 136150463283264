/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  List,
  ListItem,
  Tag,
  Text,
} from "@chakra-ui/react";

import CheckboxWithTooltip from "@/client/components/admin/forms/CheckboxWithTooltip";
import TextareaWithTooltip from "@/client/components/admin/forms/TextareaWithTooltip";
import { useFormContext } from "react-hook-form";
import { useNotificationEditor } from "@/client/services/hooks/admin/notifications/useNotificationEditor";
import { useTranslation } from "react-i18next";

// TODO: List actual content in preview

export default function NotificationSlackForm() {
  const { t } = useTranslation();
  const { watch, control } = useFormContext();
  const { insertOptions, parseTemplate } = useNotificationEditor();

  const body = watch("slack_body");
  const includeTemplate = watch("slack_include_template");
  const preview = body;

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4} paddingTop={4}>
      <GridItem>
        <TextareaWithTooltip
          name="slack_body"
          placeholder={t("assignments.notifications.edit.slack.placeholder")}
          insertOptions={insertOptions}
        />

        <CheckboxWithTooltip
          name="slack_include_template"
          label={t("assignments.notifications.edit.slack.showAssignedContent")}
          tooltipText=""
          control={control}
        />
      </GridItem>

      <GridItem>
        <Box backgroundColor="brand.mist" borderRadius="lg" padding={4}>
          <HStack>
            <Text fontSize="xs" fontWeight="bold">
              {t("assignments.notifications.edit.slack.continubot")}
            </Text>

            <Tag size="sm" fontSize="2xs">
              {t("assignments.notifications.edit.slack.app")}
            </Tag>

            <Text fontSize="xs" color="brand.gray.300">
              12:00PM
            </Text>
          </HStack>

          <Text whiteSpace="pre-wrap">{parseTemplate(preview, "slack")}</Text>

          {includeTemplate && (
            <Flex paddingTop={12}>
              <Box>
                <List>
                  <ListItem>
                    <Link href="#">Leadership 101 [Example]</Link>
                  </ListItem>
                </List>
              </Box>

              <Flex flex={1} justifyContent="flex-end">
                <Image
                  width={100}
                  height={100}
                  src="https://d2277n3gvptnup.cloudfront.net/images/51d5f21b2f410a020b000002/43100c70-9d98-4b08-bb4f-8bdd70547f5f.jpeg"
                  alt="Continu Logo"
                />
              </Flex>
            </Flex>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
}
