import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Skill } from "../../../types/Skill";
import SkillService from "@/client/services/api/SkillService";
import SkillTagList from "../../lists/SkillTagList";
import User from "@/client/services/api/User";
import { difference } from "lodash";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

type UserSkillsFormProps = {
  userId: string;
  currentUserSkills: Skill[];
};

export default function UserSkillsForm({
  userId,
  currentUserSkills,
}: UserSkillsFormProps) {
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const [currentSkills, setCurrentSkills] =
    useState<Skill[]>(currentUserSkills);
  const [updatedSkills, setUpdatedSkills] =
    useState<Skill[]>(currentUserSkills);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Skill[] | null>(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const [skillAction, setSkillAction] = useState<"add" | "remove" | null>(null);

  const checkDifferenceOnAdd = () => {
    const diff = difference(updatedSkills, currentSkills);

    if (diff.length > 0) {
      setCanUpdate(true);
    }
  };

  const checkDifferenceOnRemove = () => {
    const diff = difference(currentSkills, updatedSkills);

    if (diff.length > 0) {
      setCanUpdate(true);
    }
  };

  useEffect(() => {
    if (skillAction) {
      if (skillAction === "add") {
        checkDifferenceOnAdd();
      } else if (skillAction === "remove") {
        checkDifferenceOnRemove();
      }
    }
  }, [updatedSkills]);

  // TODO: Debounce this
  const { isError, isLoading, data, error } = useQuery(
    ["skill-search", { searchTerm }],
    () => SkillService.searchSkills(searchTerm, "newest"),
    {
      enabled: searchTerm.length > 0,
      onSuccess: (res) => setSearchResults(res.data),
    }
  );

  const updateUserSkills = useMutation({
    mutationFn: ({ skills, userId }: { skills: Skill[]; userId: string }) =>
      User.updateSkills(skills, userId),
    onSuccess: () =>
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.profile_updated"),
      }),
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdateProfile"),
      }),
  });

  const onUpdateSkills = () => {
    const skillsToCreate = updatedSkills
      .filter((skill) => !skill._id)
      .map((skill) => skill.name);

    if (skillsToCreate.length > 0) {
      SkillService.createSkills(skillsToCreate, userId).then((res) => {
        const final = updatedSkills.map((skill) => {
          if (!skill._id) {
            skill._id = res.data.find(
              (createdSkill: Skill) => createdSkill.name === skill.name
            )?._id;
          }
          return skill;
        });

        updateUserSkills.mutate({ skills: final, userId });
      });

      return;
    }

    updateUserSkills.mutate({ skills: updatedSkills, userId });
  };

  const onAddNewSkill = (skill: Skill) => {
    const skills = Array.from(updatedSkills);
    skills.push(skill);
    setUpdatedSkills(skills);
    setSearchTerm("");
    setSkillAction("add");
  };

  const onAddExistingSkill = (skill: Skill) => {
    const checkSkill = updatedSkills.filter(
      (updatedSkill) => updatedSkill._id == skill._id
    );

    if (checkSkill.length) {
      setToast({
        show: true,
        status: "error",
        title: "You already have this skill",
      });
      return;
    }

    const skills = Array.from(updatedSkills);
    skills.push(skill);
    setUpdatedSkills(skills);
    setSearchResults(null);
    setSearchTerm("");
    setSkillAction("add");
  };

  const onRemoveSkill = (name: string) => {
    const skills = updatedSkills.filter((skill: Skill) => skill.name !== name);

    setUpdatedSkills(skills);

    setSkillAction("remove");
  };

  const onHandleSearch = (searchTerm: string) => {
    if (searchTerm.length > 0) {
      setSearchTerm(searchTerm);
    } else {
      setSearchResults(null);
      setSearchTerm("");
    }
  };

  const searchOrAddCopy = t("userProfileEdit.searchOrAddSkill");

  return (
    <Box marginTop={8} marginBottom={8}>
      <Heading as="h3" fontSize="sm" textTransform="uppercase">
        {t("userProfileEdit.yourSkills")}
      </Heading>

      <Flex>
        <Input
          value={searchTerm}
          onChange={(event) => onHandleSearch(event.target.value)}
          variant="flushed"
          placeholder={searchOrAddCopy}
          _placeholder={{ color: "blackAlpha.500" }}
        />

        {searchTerm && (
          <Button
            marginLeft={4}
            onClick={() => {
              onAddNewSkill({ _id: "", name: searchTerm });
              setSearchTerm("");
              setSearchResults(null);
            }}
          >
            {t("global.action.add")}
          </Button>
        )}
      </Flex>

      {searchResults && searchResults.length > 0 && (
        <Box
          position="absolute"
          width={{ base: "90%", md: "container.md" }}
          maxHeight="300px"
          backgroundColor="white"
          border="1px"
          borderColor="gray.100"
          zIndex={99999}
          overflow="scroll"
        >
          {searchResults.map((skill: Skill) => (
            <Box
              key={skill._id}
              padding={2}
              _hover={{ cursor: "pointer", backgroundColor: "blackAlpha.50" }}
              onClick={() => onAddExistingSkill(skill)}
            >
              <Text>{skill.name}</Text>
            </Box>
          ))}
        </Box>
      )}

      {updatedSkills && (
        <SkillTagList
          skills={updatedSkills}
          showRemove
          removeSkill={onRemoveSkill}
        />
      )}

      {canUpdate && (
        <Button onClick={() => onUpdateSkills()}>
          {t("userProfileEdit.updateSkills")}
        </Button>
      )}
    </Box>
  );
}
