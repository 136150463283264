export const Link = {
  baseStyle: {
    color: "brand.primary",
    _hover: {
      color: "brand.primary",
      opacity: 0.8,
      textDecoration: "none",
    },
  },
  variants: {
    navLink: {
      color: "black",
      _hover: {
        color: "brand.primary",
        textDecoration: "none",
      },
    },
    menuLink: {
      color: "black",
      _hover: {
        color: "brand.link",
        textDecoration: "none",
      },
    },
    adminPrimary: {
      color: "brand.link",
      _hover: {
        color: "brand.link",
      },
    },
  },
};
