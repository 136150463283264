type ClientCreationFn = (baseURL: string) => void;

type ClientCreationKey = string;

export const CLIENT_CREATION_KEY_ANALYZE: ClientCreationKey = "analyze";
export const CLIENT_CREATION_KEY_API: ClientCreationKey = "api";
export const CLIENT_CREATION_KEY_GRAPHQL: ClientCreationKey = "graphql";
export const CLIENT_CREATION_KEY_REPORTS: ClientCreationKey = "reports";
export const CLIENT_CREATION_KEY_OPENAI: ClientCreationKey = "openai";

const clientCreations: { [key: string]: ClientCreationFn[] } = {};

export const registerClientCreation = async (
  key: ClientCreationKey,
  cc: ClientCreationFn
) => {
  if (!clientCreations[key]) {
    clientCreations[key] = [];
  }
  clientCreations[key].push(cc);
};

export function updateClients(key: ClientCreationKey, baseURL: string) {
  clientCreations[key].forEach((cc) => cc(baseURL));
}
